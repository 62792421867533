/* eslint-disable max-len */
import {
  FunctionComponent, useEffect, useMemo, useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import DashboardHeader from '../../../components/DashboardHeader';
// import CardWrapper from '../../../components/CardWrapper';
// import ShortTable from '../../../components/ShortTable';
import PlusIcon from '../../../svgs/PlusIcon';
import { GET_USERS } from '../../../graphql/queries';
import useCustomQuery from '../../../network/useCustomQuery';
import { cleanUpTeams } from '../../../utils/helpers';
import AddTeam from './AddTeams';
import './Teams.scss';
import Table from '../../../components/Table';
import useGetQueryParams from '../../../customHooks/useGetQueryParams';
// import NewEditIcon from '../../../svgs/NewEditIcon';
import NewDeleteIcon from '../../../svgs/NewDeleteIcon';
import EditTeams from './EditTeam';
import { useGlobalState } from '../../../contexts';
import EmptyRecord from '../../../components/EmptyRecord';
import EmptyCustomerIcon from '../../../svgs/EmptyCustomerIcon';
import StyledButton from '../../../components/StyledButton';
import NewPlusIcon from '../../../svgs/NewPlusIcon';
import DeleteTeam from './DeleteTeam';

export interface ITeams {
  label?: string;
}
const limit = 10;
const Teams: FunctionComponent<ITeams> = () => {
  const [showAddTeam, setShowAddTeam] = useState(false);
  const [showEditTeam, setShowEditTeam] = useState(false);
  const [showDeleteTeam, setShowDeleteTeam] = useState(false);
  const [editPayload, setEditPayload]:any = useState('');
  const [checked, setChecked] = useState([]);
  const { push } = useHistory();
  const [getTeams, getTeamsLoading, teams] = useCustomQuery({
    query: GET_USERS,
    onError: () => {},
    onData: () => {},
  });
  const { profile } = useGlobalState();
  const isMerchantAdmin = profile?.role?.name === 'merchant-admin';
  const { getQuery } = useGetQueryParams();
  const page = Number(getQuery('page'));
  const setPage = (pageNum) => {
    push(`/settings/teams?page=${pageNum}`);
  };
  if (!page) {
    push('/settings/teams?page=1');
  }

  useEffect(() => {
    if (page) {
      getTeams({
        variables: {
          offset: (page - 1) * limit,
          limit,
        },
      });
    }
  }, [page]);

  const teamRecord = useMemo(() => cleanUpTeams(teams?.merchantUsers?.data), [teams]);

  const dropdownData = [
    // { id: 1, title: 'Edit', icon: <NewEditIcon /> },
    {
      id: 2, title: 'Delete', icon: <NewDeleteIcon />, color: '#B42318',
    }];
  const handleSelectDropdown = (title:any, details:any) => {
    switch (title) {
    case 'Edit':
      setShowEditTeam(true);
      setEditPayload(details);
      break;
    case 'Delete':
      setShowDeleteTeam(true);
      setEditPayload(details);
      break;
    default:
      setShowEditTeam(false);
      setEditPayload('');
      break;
    }
  };
  return (
    <div className="teams-cover-view">
      <DashboardHeader
        title="Team Members"
        smallSubTitle={`(${teamRecord.length || 0})`}
        hasButton={isMerchantAdmin}
        buttonName="Add Team Member"
        buttonIcon={<PlusIcon />}
        handleButtonAction={() => setShowAddTeam(true)}
        hideButtonClass="hide-button"
      />
      {teamRecord.length ? (
        <Table
          checked={checked}
          setChecked={setChecked}
          loading={getTeamsLoading}
          page={page}
          setPage={setPage}
          pageSize={limit}
          minHeight="calc(100vh - 400px)"
          count={teamRecord.length}
          contents={teamRecord}
          reference="id"
          hideDominantImage
          clickable
          allowKeyArr={[
            'image',
            'name',
            'email',
            'role',
            ['date', 'time'],
          ]}
          mobileAllowKeyArr={
            ['status',
              'image',
              ['name', 'role', 'email',
              ],
            ]
          }
          formatLabels={{
            date: 'Last Login',
            status: 'Status',
            role: 'Role',
            email: 'Email Address',
            name: 'Name',
          }}
          handleSelectDropdown={handleSelectDropdown}
          dropdownData={dropdownData}
          actionList
        />
      ) : (
        <EmptyRecord
          height="calc(100vh - 400px)"
          title="No Team Yet"
          main="Teams are a great idea to increase your Productivity. Try creating one"
          footer="Add Team Member"
          Icon={EmptyCustomerIcon}
          loading={getTeamsLoading}
        />
      )}
      {showAddTeam && (
        <AddTeam closeModal={() => setShowAddTeam(false)} refetch={getTeams} />
      )}
      {showEditTeam && (
      <EditTeams closeModal={() => setShowEditTeam(false)} refetch={getTeams} payload={editPayload} />
      )}
      {showDeleteTeam && (
        <DeleteTeam closeModal={() => setShowDeleteTeam(false)} refetch={getTeams} user={editPayload} />
      )}
      <div className="mobile-add-member">
        <StyledButton name="Add Team Member" icon={<NewPlusIcon />} border="none" fontSize="13px" backgroundColor="white" color="#523B9F" onClick={() => setShowAddTeam(true)} />
      </div>

    </div>
  );
};

export default Teams;
