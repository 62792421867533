import React from 'react';
import LightBulb from '../../svgs/LightBulb';
import ProductView from '../../svgs/ProductView';

const BlacklistCustomer = () => (
  <div className="blacklist-customer">
    <div className="blacklist-title">
      <LightBulb />
      <h5>Blacklist Customer</h5>
    </div>
    <span className="info">
      The customer will not be able to do the following once blacklisted
    </span>
    <div className="dotted-info">
      <div className="dot" />
      <span className="info"> The customer cannot make nor receive payments from you</span>
    </div>
    <button>
      <ProductView color="#130F26" />
      <span>Blacklist Customer</span>
    </button>
  </div>
);

export default BlacklistCustomer;
