import React from 'react';

const TrxFailedIcon = () => (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y="0.5" width="24" height="24" rx="12" fill="#FEF3F2" />
    <path fillRule="evenodd" clipRule="evenodd" d="M13.1783 13L16.7556 9.42265C17.0815 9.09683 17.0815 8.57018 16.7556 8.24436C16.4298 7.91855 15.9032 7.91855 15.5774 8.24436L12 11.8217L8.42265 8.24436C8.09683 7.91855 7.57018 7.91855 7.24436 8.24436C6.91855 8.57018 6.91855 9.09683 7.24436 9.42265L10.8217 13L7.24436 16.5774C6.91855 16.9032 6.91855 17.4298 7.24436 17.7556C7.40686 17.9181 7.62018 17.9998 7.83351 17.9998C8.04683 17.9998 8.26016 17.9181 8.42265 17.7556L12 14.1783L15.5774 17.7556C15.7398 17.9181 15.9532 17.9998 16.1665 17.9998C16.3798 17.9998 16.5931 17.9181 16.7556 17.7556C17.0815 17.4298 17.0815 16.9032 16.7556 16.5774L13.1783 13Z" fill="#F04438" />
  </svg>

);

export default TrxFailedIcon;
