import React from 'react';

const SettledAmountIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" rx="12" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M9.42525 5.33398H14.5689C16.7112 5.33398 17.9942 6.63494 18 8.80713V15.1942C18 17.3664 16.7228 18.6673 14.5747 18.6673H12.6705C12.4263 18.64 12.2415 18.4308 12.2415 18.1817C12.2415 17.9325 12.4263 17.7233 12.6705 17.696H14.5689C16.2061 17.696 17.0305 16.8542 17.0305 15.1942V8.80713C17.0305 7.14708 16.2061 6.30529 14.5689 6.30529H9.42525C7.7881 6.30529 6.95791 7.14708 6.95791 8.80713V15.1942C6.95791 16.8542 7.7881 17.696 9.42525 17.696C9.66946 17.7233 9.8543 17.9325 9.8543 18.1817C9.8543 18.4308 9.66946 18.64 9.42525 18.6673C7.28302 18.6673 6 17.3664 6 15.1942V8.80713C6 6.62905 7.28302 5.33398 9.42525 5.33398ZM9.59361 9.66658H11.4224C11.6666 9.63928 11.8514 9.43005 11.8514 9.18093C11.8514 8.93181 11.6666 8.72258 11.4224 8.69528H9.59361C9.34941 8.72258 9.16457 8.93181 9.16457 9.18093C9.16457 9.43005 9.34941 9.63928 9.59361 9.66658ZM14.3948 12.4863H9.59361H9.58781C9.3436 12.459 9.15876 12.2498 9.15876 12.0007C9.15876 11.7515 9.3436 11.5423 9.58781 11.515H14.3948C14.5785 11.4945 14.7576 11.5823 14.8558 11.741C14.954 11.8998 14.954 12.1015 14.8558 12.2603C14.7576 12.419 14.5785 12.5068 14.3948 12.4863ZM14.3948 15.306H9.59361H9.58781C9.32489 15.306 9.11176 15.0899 9.11176 14.8233C9.11176 14.5567 9.32489 14.3406 9.58781 14.3406H14.3948C14.6577 14.3406 14.8708 14.5567 14.8708 14.8233C14.8708 15.0899 14.6577 15.306 14.3948 15.306Z" fill="#523B9F" />
  </svg>

);

export default SettledAmountIcon;
