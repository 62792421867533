import React from 'react';

interface IDropdownEditIcon{
  color?:string;
}
const DropdownEditIcon = ({ color }:IDropdownEditIcon) => (
  <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.87305 10.7219H10.4993" stroke={color || '#121212'} strokeLinecap="round" strokeLinejoin="round" />
    <path fillRule="evenodd" clipRule="evenodd" d="M6.39 2.3974C6.77783 1.93389 7.47499 1.86593 7.94811 2.24587C7.97427 2.26648 8.81474 2.91939 8.81474 2.91939C9.33449 3.2336 9.49599 3.90155 9.1747 4.41129C9.15764 4.43859 4.40597 10.3822 4.40597 10.3822C4.24789 10.5794 4.00792 10.6959 3.75145 10.6987L1.93176 10.7215L1.52177 8.98616C1.46433 8.74215 1.52177 8.48589 1.67985 8.28867L6.39 2.3974Z" stroke={color || '#121212'} strokeLinecap="round" strokeLinejoin="round" />
    <path d="M5.50977 3.5L8.23589 5.59356" stroke={color || '#121212'} strokeLinecap="round" strokeLinejoin="round" />
  </svg>

);
export default DropdownEditIcon;
