/* eslint-disable max-len */
import { FunctionComponent, useEffect, useState } from 'react';
import {
  useGlobalDispatch,
  useGlobalState,
} from '../../../contexts';

import './APIWebhooks.scss';

import useToast from '../../../customHooks/useToast';
// import CardWrapper from '../../../components/CardWrapper';
// import ShortTable from '../../../components/ShortTable';
import { getKeys } from '../../../utils/helpers';
import useCustomQuery from '../../../network/useCustomQuery';
import { RESET_KEYS } from '../../../graphql/queries';
import APICopyComp from './APICopyComp';

export interface IAPI {
  label?: string;
}

const API: FunctionComponent<IAPI> = () => {
  const { get_profile }:any = useGlobalDispatch();
  const { profile }:any = useGlobalState();
  const [apiKeysObj, setApiKeysObj] = useState({
    publicKey: '',
    secretKey: '',
  });

  const Toast = useToast();

  useEffect(() => {
    if (profile?.merchant) {
      setApiKeysObj({
        publicKey: profile?.merchant?.publicKey,
        secretKey: profile?.merchant?.secretKey,
      });
    }
  }, [profile?.merchant]);

  const [resetKeys, resetKeysLoading, keys] = useCustomQuery({
    query: RESET_KEYS,
    onError: () => {
      Toast.error('Keys reset Failed.');
    },
    onData: () => {},
  });

  useEffect(() => {
    if (keys?.resetMechantApiKeys?.merchant?.publicKey) {
      Toast.success('Key reset was successful');
      get_profile();
      setApiKeysObj({
        ...keys?.data?.resetMechantApiKeys?.merchant,
      });
    }
  }, [keys]);

  const rawRecord = getKeys(apiKeysObj);
  const copyToClipboard = (key:any, name:string) => {
    if (!navigator.clipboard) {
      Toast.error('Browser does not support this feature');
      return;
    }
    navigator.clipboard.writeText(key).then(
      () => {
        Toast.success(`${name} copied successfully`);
      },
      () => {
        Toast.error('Browser does not support this feature');
      },
    );
  };
  return (
    <div className="webhook-view-cover-api-cover row p-0 m-0">
      <div className="col-md-6 col-12 p-0 m-0 webhook-view-cover-api-cover-col-left">
        <div className="webhook-view-cover-api-cover-col-left-title">API Keys</div>
        <div className="webhook-view-subtitle-inner-cover">

          <div className="webhook-view-cover-api-cover-col-left-subtitle">
            These keys help you integrate
            your business with Payfi

          </div>
          <div className="webhook-view-cover-api-cover-col-left-edit-profile">
            <span onClick={() => {
              window.open('https://payfi.readme.io/docs', '_blank');
            }}
            >
              View Docs
            </span>
          </div>
        </div>
      </div>
      <div className="col-md-6 col-12 p-0 m-0">
        {
          rawRecord?.map((list:any, index) => <div key={index}><APICopyComp title={list?.name} apiKey={list?.token} hasAction={index === 0 && true} actionTitle={resetKeysLoading ? 'Reseting...' : 'Reset Keys'} handleReset={() => resetKeys()} handleCopy={copyToClipboard} /></div>)
        }

      </div>
    </div>
  );
};

export default API;
