import React from 'react';

const LinkDeleteIcon = () => (
  <svg width="12" height="12" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M3.76924 2.29044H8.45252C8.6167 2.29044 8.75 2.42668 8.75 2.59501C8.75 2.76376 8.6167 2.90001 8.45252 2.90001H1.54707C1.38289 2.90001 1.25 2.76376 1.25 2.59501C1.25 2.42668 1.38289 2.29044 1.54707 2.29044H2.87801C3.0552 2.29044 3.20923 2.16085 3.24377 1.98336L3.34293 1.47503C3.44331 1.0892 3.76842 0.833374 4.13824 0.833374H5.86176C6.23158 0.833374 6.55669 1.0892 6.6526 1.45586C6.69527 1.61878 6.60099 1.78586 6.44249 1.82961C6.28441 1.87336 6.12104 1.77669 6.07837 1.61378C6.05195 1.51336 5.96295 1.44336 5.86176 1.44336H4.13824C4.03705 1.44336 3.94805 1.51336 3.92163 1.61378L3.82694 2.10252C3.81394 2.16835 3.79362 2.23002 3.76924 2.29044ZM3.39779 8.54034C3.01578 8.53201 2.79958 8.31951 2.75528 7.90827C2.62768 6.73204 2.40904 3.94666 2.407 3.91874C2.394 3.75083 2.25054 3.63125 2.08676 3.63875C1.92299 3.6525 1.80107 3.79958 1.81448 3.96708C1.81651 3.99541 2.03556 6.79037 2.16398 7.97618C2.24079 8.68576 2.70855 9.13533 3.3856 9.14992H3.3917C3.55303 9.14992 3.68552 9.01783 3.68918 8.85159C3.69243 8.68326 3.56198 8.54368 3.39779 8.54034ZM7.91315 3.63887C7.75141 3.63012 7.60592 3.75054 7.59292 3.91845C7.59088 3.94637 7.37062 6.73924 7.24423 7.90631C7.19912 8.3263 6.98007 8.53422 6.57409 8.54213L6.41262 8.54503C5.89719 8.5543 5.65725 8.55861 5.0814 8.55672H5.08059C4.9164 8.55672 4.78351 8.69213 4.78311 8.86005C4.78229 9.02879 4.91519 9.16546 5.07937 9.16629C5.18097 9.16671 5.28216 9.16671 5.38376 9.16671C5.80845 9.16671 5.97804 9.16331 6.29655 9.15692L6.58506 9.15129C7.28975 9.13837 7.7575 8.69796 7.83553 7.97298C7.96273 6.79758 8.18341 3.99553 8.18544 3.96762C8.19885 3.7997 8.07693 3.65262 7.91315 3.63887Z" fill="#B42318" />
  </svg>

);

export default LinkDeleteIcon;
