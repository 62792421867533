import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
// import moment from 'moment';
import moment from 'moment';
import TextInput from '../../../components/input';
import {
  useGlobalDispatch,
  useGlobalState,
} from '../../../contexts';
import useForm from '../../../customHooks/useForm';
import useToast from '../../../customHooks/useToast';
import { updateBusinessOwnerInformation } from '../../../graphql/mutations';
import CountrySelectOption from '../../../components/CountrySelectOption';
import {
  getLocalGovt, getPhoneNum, getStates, validateDateOfBirth,
} from '../../../utils/helpers';
import UploadInputKYC from '../../../components/UploadInputKYC';
import StyledButton from '../../../components/StyledButton';
import SelectSearch from '../../../components/SelectSearch';
// import DateInput from '../../../components/FilterComponents/DateInput';
import AppDatePicker from '../../../components/DatePicker';

const BusinessOwnerForm = () => {
  const { setStep, get_profile_in_app }:any = useGlobalDispatch();
  const { profile }:any = useGlobalState();
  const disableAllForm = profile?.merchant?.documentSentForActivation;

  const Toast = useToast();
  const {
    formValues,
    handleChange,
    checkAllRequired,
    setError,
    resetForm,
  } = useForm(
    {
      firstName: '',
      lastName: '',
      email: '',
      documentName: '',
      city: '',
      state: '',
      phone: '',
      country: '',
      street: '',
      documentId: '',
    },
  );

  const [updateBusinessDetails] = useMutation<
    { createSaving: any; updateBusinessOwner: any },
    any
  >(updateBusinessOwnerInformation);
  const [loadingRes, setLoadingRes] = useState(false);
  const [file, setFile] = useState(null);
  const [getDOB, setGetDOB]:any = useState(new Date());
  const handleFileUpload = (inp: any) => {
    if (inp) {
      setFile(inp);
    }
  };
  const handleSubmit = async () => {
    setLoadingRes(true);
    setError('');
    if (!checkAllRequired(['identityDoc'])) {
      setLoadingRes(false);

      if (formValues?.firstName === '') {
        Toast.error('firstname  is required');
      }
      if (formValues?.lastName === '') {
        Toast.error('lastname  is required');
      }
      if (formValues?.country === '') {
        Toast.error('country  is required');
      }
      if (formValues?.city === '') {
        Toast.error('city  is required');
      }
      if (formValues?.state === '') {
        Toast.error('state  is required');
      }
      if (formValues?.street === '') {
        Toast.error('street  is required');
      }
      if (formValues?.documentName === '') {
        Toast.error('select means of identification');
      }
      if (!getDOB || !validateDateOfBirth(getDOB)) {
        Toast.error('date of birth is required and must be greater than 21 years');
      }
      if (formValues?.documentId === '') {
        Toast.error('identification number is required');
      }

      return;
    }

    try {
      const resp = await updateBusinessDetails({
        variables: {
          ...formValues,
          dateOfBirth: getDOB,
          file,
        },
      });

      setLoadingRes(false);
      if (resp?.data?.updateBusinessOwner?.status === 200) {
        // set success message
        Toast.success('Successful');
        get_profile_in_app();
        setStep(3);
      } else {
        Toast.error(resp?.data?.updateBusinessOwner?.message);
      }
    } catch (e) {
      setLoadingRes(false);
      if (e && e.graphQLErrors && e.graphQLErrors.length > 0) {
        Toast.error(e.graphQLErrors[0].message);
      } else {
        Toast.error('Something went wrong');
      }
    }
  };

  useEffect(() => {
    if (profile?.merchant) {
      const thisMerchant = profile?.merchant;
      const businessOwner = thisMerchant?.businessOwner;
      const formUpdate = {
        firstName: businessOwner?.merchantUser?.firstName,
        lastName: businessOwner?.merchantUser?.lastName,
        email: businessOwner?.merchantUser?.email,
        phone: getPhoneNum(businessOwner?.merchantUser?.phone),
        documentName: businessOwner?.identityDocument?.description,
        city: businessOwner?.city,
        state: businessOwner?.state,
        country: businessOwner?.country,
        street: businessOwner?.street,
        documentId: businessOwner?.identityDocument?.documentId,
      };
      resetForm(formUpdate);
      setGetDOB(businessOwner?.dateOfBirth ? new Date(businessOwner?.dateOfBirth) : '');
    }
  }, [profile]);

  const handlePrev = () => {
    setStep(1);
  };

  const getCountry: any = [{ text: 'Nigeria', value: 'Nigeria', label: 'Nigeria' }];
  const getMeansOfIdentity: any = [
    { value: 'INTERNATIONAL_PASSPORT', label: 'International Passport' },
    { value: 'NATIONAL_ID', label: 'National ID Card' },
    { value: 'VOTER', label: 'Voters card' },
    { value: 'DRIVER_LICENCE', label: 'Driver\'s licence' },
  ];
  const [getStatesData, setgetStatesData] = useState([]);
  useEffect(() => {
    const stateList = [];
    getStates()?.map((list) => {
      const data = {
        value: list?.value,
        text: list?.text,
        label: list?.text,
      };
      return stateList.push(data);
    });
    setgetStatesData(stateList);
  }, []);
  const [getCityData, setgetCityData] = useState([]);
  useEffect(() => {
    const cityList = [];
    if (formValues?.state) {
      getLocalGovt(formValues?.state)?.map((list) => {
        const data = {
          value: list?.value,
          text: list?.text,
          label: list?.text,
        };
        return cityList.push(data);
      });
      setgetCityData(cityList);
    }
  }, [formValues?.state]);
  const handleDateChange = (e) => {
    setGetDOB(e);
  };

  return (
    <div className="business-owner-form-row-profile row m-0 p-0">
      <div className="col-md-6 col-12 m-0 p-0 business-owner-form-row-profile-col-left">
        <TextInput
          customOnChange={handleChange}
          name="firstName"
          value={formValues.firstName}
          label="First Name"
          width="100%"
          disabled={disableAllForm}
        />
      </div>
      <div className="col-md-6 col-12 m-0 p-0 business-owner-form-row-profile-col-right">
        <TextInput
          customOnChange={handleChange}
          name="lastName"
          value={formValues.lastName}
          label="Last Name"
          width="100%"
          disabled={disableAllForm}
        />
      </div>
      <div className="col-md-6 col-12 m-0 p-0 business-owner-form-row-profile-col-left">
        <TextInput
          customOnChange={handleChange}
          name="email"
          value={formValues.email}
          label="Email Address"
          width="100%"
          disabled={disableAllForm}
          viewOnly
        />
      </div>
      <div className="col-md-6 col-12 m-0 p-0 business-owner-form-row-profile-col-right">
        <div className="row p-0 m-0 col-right-inner-row">
          <div className="col-md-4 col-5 col-right-inner-row-inner-col-left p-0 m-0">
            <CountrySelectOption />
          </div>
          <div className="col-md-8 col-7 col-right-inner-row-inner-col-right p-0 m-0">
            <TextInput
              name="phone"
              value={formValues?.phone}
              customOnChange={handleChange}
              label="Phone Number"
              width="100%"
              disabled={disableAllForm}
              viewOnly
              type="tel"
            />
          </div>
        </div>
      </div>
      {/* Resident details form start */}
      <div className="col-12 p-0 m-0 resident-details-title">
        Residential Details
      </div>
      <div className="col-md-6 col-12 m-0 p-0 business-owner-form-row-profile-col-left">

        <div style={{ marginBottom: '20px' }}>
          <SelectSearch
            label=""
            options={getCountry}
            placeholder="Select country of residence"
            name="country"
            value={formValues.country}
            required={false}
            classes={false}
            hideLabel
            onChange={handleChange}
            disabled={disableAllForm}
          />
        </div>
      </div>
      <div className="col-md-6 col-12 m-0 p-0 business-owner-form-row-profile-col-right">
        <div style={{ marginBottom: '20px' }}>
          <SelectSearch
            label=""
            options={getStatesData}
            placeholder="State"
            name="state"
            value={formValues.state}
            required={false}
            classes={false}
            hideLabel
            onChange={handleChange}
            disabled={disableAllForm}
          />
        </div>
      </div>
      <div className="col-md-6 col-12 m-0 p-0 business-owner-form-row-profile-col-left">
        <TextInput
          name="street"
          value={formValues?.street}
          customOnChange={handleChange}
          label="Address"
          width="100%"
          disabled={disableAllForm}
        />
      </div>
      <div className="col-md-6 col-12 m-0 p-0 business-owner-form-row-profile-col-right">
        <div style={{ marginBottom: '20px' }}>
          <SelectSearch
            label=""
            options={getCityData}
            placeholder="City"
            name="city"
            value={formValues.city}
            required={false}
            classes={false}
            hideLabel
            onChange={handleChange}
            disabled={disableAllForm}
          />
        </div>
      </div>
      {/* Means of identification form start */}
      <div className="col-12 p-0 m-0 means-of-identification-title">
        Means of Identification
      </div>
      <div className="col-md-6 col-12 m-0 p-0 business-owner-form-row-profile-col-left">
        {/* <TextInput
          name="dateOfBirth"
          value={formValues?.dateOfBirth}
          customOnChange={handleChange}
          label="Date of birth"
          width="100%"
          type="date"
          disabled={disableAllForm}
          // date must be older than 18 years
          maxDate={moment().subtract(21, 'years').format('YYYY-MM-DD')}
        /> */}

        <AppDatePicker
          value={getDOB}
          placeholder="Date of birth"
          onChange={handleDateChange}
          maxDate={moment().subtract(21, 'years').format('YYYY/MM/DD')}
        />
      </div>
      <div className="col-md-6 col-12 m-0 p-0 business-owner-form-row-profile-col-right">
        <div style={{ marginBottom: '20px' }}>
          <SelectSearch
            label=""
            options={getMeansOfIdentity}
            placeholder="Select Means of Identification"
            name="documentName"
            value={formValues.documentName}
            required={false}
            classes={false}
            hideLabel
            onChange={handleChange}
            disabled={disableAllForm}
          />
        </div>
      </div>
      <div className="col-md-6 col-12 m-0 p-0 business-owner-form-row-profile-col-left">
        <TextInput
          name="documentId"
          value={formValues?.documentId}
          customOnChange={handleChange}
          label="Enter Identification Number"
          width="100%"
          disabled={disableAllForm}
        />
      </div>
      <div className="col-md-6 col-12 m-0 p-0 business-owner-form-row-profile-col-right">
        <UploadInputKYC
          name="identityDoc"
          label="Upload Valid Identity Document"
          filename={profile?.merchant?.businessOwner?.identityDocument?.documentType}
          fileInp={{ file: profile?.merchant?.businessOwner?.identityDocument }}
          customFileUploadhandler={handleFileUpload}
          disabled={disableAllForm}
        />
      </div>
      {/* {getErrorComp()} */}
      <div className="business-owner-border" />
      <div className="col-12 p-0 m-0 business-owner-button-div">
        <span>
          <StyledButton
            onClick={handlePrev}
            color="#523B9F"
            backgroundColor="white"
            fontSize="13px"
            name="Previous"
            width="130px"
            height="40px"
            borderRadius="4px"
            border="1px solid #DCD8EC"
          />
        </span>
        <StyledButton
          onClick={handleSubmit}
          disabled={loadingRes || disableAllForm}
          color="white"
          backgroundColor="#523B9F"
          fontSize="13px"
          name={loadingRes ? 'Loading...' : 'Save & Proceed'}
          width="130px"
          height="40px"
          borderRadius="4px"
          border="none"
        />
      </div>
    </div>
  );
};
export default BusinessOwnerForm;
