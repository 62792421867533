import React from 'react';
import ProductView from '../../svgs/ProductView';

type Props = {
  product: any;
}

const ProductCard = ({ product }: Props) => (
  <div className="trx-product-card">
    <h5>
      Product Details
    </h5>
    { product ? (
      <>
        <div className="image-and-name">
          <img src={product?.image} alt={product?.name} />
          <h6>{product?.name}</h6>
        </div>
        <div className="product-info">
          <div className="product-label-value">
            <span>Reference</span>
            <strong>{product?.reference}</strong>
          </div>
          <div className="product-label-value">
            <span>Quantity</span>
            <strong>{product?.quantity}</strong>
          </div>
        </div>
        <button>
          <ProductView />
          <span>View product</span>
        </button>
      </>
    )
      : (
        <div className="empty-text">
          <span>Update the SDK to view your product details</span>
        </div>
      )}
  </div>
);

export default ProductCard;
