/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import CheckIcon from '../../svgs/CheckIcon';
import DeactivateStaff from '../../svgs/DeactivateStaff';
import './MerchantDropdown.scss';

interface IMerchantDropdown{
    data?:any[];
    handleDropdown?:any;
    details?:any;
    fontSize?:any;
    hasClassName?:string;
    mouseEnterState?:any;
    checkmouseEvent?:boolean;
    hasActiveAndInactiveStatus?:boolean;
    dropdownActiveDetails?:any;
    dropdownDeActiveDetails?:any;
}
const MerchantDropdown = ({
  data, handleDropdown, details, fontSize, hasClassName, mouseEnterState, checkmouseEvent, hasActiveAndInactiveStatus,
  dropdownActiveDetails, dropdownDeActiveDetails,
}:IMerchantDropdown) => {
  const handleMouseEnter = (title) => {
    mouseEnterState({
      checkState: true, title,
    });
  };

  const handleMouseLeave = (title) => {
    mouseEnterState({
      checkState: false, title,
    });
  };
  const [newFormatedData, setNewFormatedData]:any = useState([]);
  useEffect(() => {
    if (hasActiveAndInactiveStatus && details?.status !== 'pending') {
      setNewFormatedData([...data, { title: details?.status === 'active' || details?.status === 'ACTIVE' ? dropdownActiveDetails?.name || 'Deactivate' : dropdownDeActiveDetails?.name || 'Activate', icon: details?.status === 'active' || details?.status === 'ACTIVE' ? dropdownActiveDetails?.icon || <DeactivateStaff /> : dropdownDeActiveDetails?.icon || <CheckIcon color="rgba(59, 221, 153, 1)" width="10px" height="10px" />, color: details?.status === 'active' || details?.status === 'ACTIVE' ? dropdownActiveDetails?.color || 'rgba(255, 89, 89, 1)' : dropdownDeActiveDetails?.color || 'rgba(71, 84, 103, 1)' }]);
      return;
    }
    setNewFormatedData([...data]);
  }, [hasActiveAndInactiveStatus]);

  return (
    <div className={hasClassName || 'dropdown-details-list'}>
      {
        newFormatedData?.map((list:any) => (checkmouseEvent
          ? (
            <div
              onMouseLeave={() => handleMouseLeave(list?.title)}
              onMouseEnter={() => handleMouseEnter(list?.title)}
              style={{
                color: list?.color,
                display: 'flex',
                fontSize: fontSize ? '9px' : '',
                cursor: list?.disabled ? 'not-allowed' : 'pointer',
                opacity: list?.disabled ? 0.4 : 1,
              }}
              onClick={() => !list?.disabled && handleDropdown(list?.title, details)}
              key={list?.title}
            >
              <span>{list?.icon}</span>
              {list?.title}
            </div>
          ) : (
            <div
              style={{
                color: list?.color,
                display: 'flex',
                fontSize: fontSize ? '9px' : '',
                cursor: list?.disabled ? 'not-allowed' : 'pointer',
                opacity: list?.disabled ? 0.4 : 1,
              }}
              onClick={() => !list?.disabled && handleDropdown(list?.title, details)}
              key={list?.title}
            >
              <span>{list?.icon}</span>
              {list?.title}
            </div>
          )
        ))
      }

    </div>
  );
};
export default MerchantDropdown;
