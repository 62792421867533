import React from 'react';
import CloseIcon from '../../svgs/CloseIcon';
import './ComingSoonOverlay.scss';

interface IComingSoonOverlay{
  children?:any;
  onClick?:any;
}
const ComingSoonOverlay = ({ children, onClick }:IComingSoonOverlay) => (
  <div className="comingsoon-overlay-cover" onClick={onClick}>
    <div className="container col-lg-4 col-md-5 col-12 overlay-modal-cover">
      <div className="comingsoon-overlay-modal">
        <div className="top-modal-cover-icon"><div className="modal-cover-icon" onClick={onClick}><CloseIcon width="8px" height="8px" color="#667085" /></div></div>
        {children}

      </div>
    </div>

  </div>
);
export default ComingSoonOverlay;
