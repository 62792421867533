/* eslint-disable max-len */
import React from 'react';
// import CheckboxNew from '../../../components/CheckboxNew';
import TextInput from '../../../components/input';
import PrefCheck from './prefCheck';
import './preferenceSection.scss';

interface IPreferenceSection {
  marginBottom?:string;
  placeholder?:string;
  leftTitle?:string;
  leftSubtitle?:string;
  rightTitle?:string;
  rightSubtitle?:string;
  hasInput?:boolean;
  hasSubinner?:boolean;
  innerRightTitle?:string;
  innerRightSubtitle?:string;
  onChange?:any;
  checkState?:boolean;
  subCheckState?:boolean;
  handleSubCheckChange?:any;
}
const PreferenceSection = ({
  marginBottom, placeholder, leftTitle, leftSubtitle, rightTitle, rightSubtitle, hasInput, hasSubinner, innerRightTitle, innerRightSubtitle, checkState, onChange, handleSubCheckChange, subCheckState,
}:IPreferenceSection) => (
  <div className="pref-section-cover row m-0 p-0">
    <div className="col-md-6 col-12 m-0 p-0 pref-section-cover-left-col">
      {' '}
      <div className="pref-section-cover-left-col-title">{leftTitle}</div>
      <div className="pref-section-cover-left-col-subtitle">
        {leftSubtitle}

      </div>

    </div>
    <div className="col-md-6 col-12 m-0 p-0 pref-section-cover-right-col">
      <div className="pref-section-cover-right-col-first-div-cover" style={{ marginBottom: marginBottom || '' }}>
        <div className="pref-section-cover-right-col-first-div-cover-content">
          <div className="pref-section-cover-right-col-first-div-cover-content-inner">
            <span><PrefCheck onChange={onChange} value={checkState} /></span>
            <div>
              <div className="title">{rightTitle}</div>
              <div className="subtitle">{rightSubtitle}</div>
            </div>
          </div>
          {
            hasSubinner && (
            <div className="pref-section-cover-right-col-first-div-cover-content-subinner">
              <span>
                <PrefCheck onChange={handleSubCheckChange} value={subCheckState} />
              </span>
              <div>
                <div className="title">{innerRightTitle}</div>
                <div className="subtitle">{innerRightSubtitle}</div>
              </div>
            </div>
            )
          }

        </div>
        {
          hasInput && <div className="input-div"><TextInput label={placeholder} width="100%" /></div>
        }
      </div>

    </div>
  </div>
);
export default PreferenceSection;
