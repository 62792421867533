import React from 'react';
import './SummaryDetailsCover.scss';

interface ISummaryDetailsCover{
  children:any;
  title?:string;
  width?:string;
  paddingBottom?:string;
  paddingTop?:string;
  maxWidth?:string;
}
const SummaryDetailsCover = ({
  children, title, width, paddingBottom, paddingTop, maxWidth,
}:ISummaryDetailsCover) => (
  <div
    className="summary-details-cover"
    style={{
      width, paddingBottom, paddingTop, maxWidth,
    }}
  >
    {
      title && <div className="summary-header">{title}</div>
    }

    {children}

  </div>
);
export default SummaryDetailsCover;
