import React from 'react';
import Cancelicon from '../../svgs/Cancelicon';

interface IRoundedCancle{
  closeModal?:any;
  backgroundColor?:any;
  border?:any;
}
const RoundedCancleIcon = ({ closeModal, backgroundColor, border }:IRoundedCancle) => (
  <div className="modal-cancel">
    <div className="modal-cancel-icon-cover" style={{ backgroundColor, border }} onClick={closeModal}>
      {' '}
      <Cancelicon color="#667085" width="8px" height="8px" />
    </div>
  </div>
);
export default RoundedCancleIcon;
