import React from 'react';

const LightShield = () => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M17.9768 32.409C17.9768 32.409 29.4848 28.9245 29.4848 19.3185C29.4848 9.711 29.9018 8.961 28.9793 8.037C28.0553 7.113 19.4858 4.125 17.9768 4.125C16.4678 4.125 7.89835 7.113 6.97585 8.037C6.05185 8.961 6.46885 9.711 6.46885 19.3185C6.46885 28.9245 17.9768 32.409 17.9768 32.409Z" stroke="#5941A9" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M14.0791 17.8119L16.9171 20.6544L22.7641 14.8044" stroke="#5941A9" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>

);
export default LightShield;
