import React, { FunctionComponent } from 'react';
import AvaterImage from '../../../components/AvaterImage';
import DragDrop from '../../../components/DragAndDropFileUpload';
// import DragDrop from './MerchantDregDropFIle';

interface IAvaterSection{
    logo?:any;
    title?:any;
    subTitle?:any;
    handleUploadFile?:any;
    alt?: string;
    loading?: boolean;
}
const AvaterSectionDetails:FunctionComponent<IAvaterSection> = ({
  logo, title, subTitle, handleUploadFile, alt, loading,
}) => {
  const handleGetFile = (data) => {
    handleUploadFile(data);
  };

  return (
    <div className="row m-0 p-0 merchant-profile-cover-avater-row">
      <div className="col-md-6 col-12 m-0 p-0 merchant-profile-cover-avater-row-content">
        <div className="merchant-profile-cover-avater-row-content-title">
          {title}
        </div>
        <div className="merchant-profile-cover-avater-row-content-subtitle">
          {subTitle}
        </div>
      </div>
      <div className="col-md-6 col-12 m-0 p-0 merchant-profile-cover-avater-row-avater-upload-cover">
        <div className="avater-image-component-cover"><AvaterImage width="80px" height="80px" image={logo} alt={alt} /></div>
        <div className="avater-drag-and-drop-comp"><DragDrop handleGetFileUpload={handleGetFile} loading={loading} /></div>
      </div>
    </div>
  );
};
export default AvaterSectionDetails;
