import React, { useState } from 'react';
import { cleanColumnArray } from '../../../utils/helpers';
import NameAndSwitch from '../../FilterComponents/FilterTableColumn/NameAndSwitch';
import RoundedCancleIcon from '../../ModalFormComp/RoundedCancleIcon';
import StyledButton from '../../StyledButton';

import './FilterColumn.scss';

interface IFilterTableColumn{
  selectedColumns?:string [];
  allColumns?: string [];
  applyChanges?:any;
  formatLabels?:any;
  showToggleDropdown?: boolean
  setShowToggleDropdown?: any
  showRoundedCancel?: boolean
}

const getName = (name) => (Array.isArray(name) ? name[0] : name);

const FilterColumn = ({
  selectedColumns,
  allColumns,
  formatLabels,
  applyChanges,
  showToggleDropdown,
  setShowToggleDropdown,
  showRoundedCancel,
}:IFilterTableColumn) => {
  // eslint-disable-next-line max-len
  const [localSelected, setLocalSelected]:any = useState(cleanColumnArray(selectedColumns));
  // eslint-disable-next-line max-len
  const allSelected = localSelected?.length === cleanColumnArray(allColumns)?.length;

  const isSelected = (name) => {
    // check if the name exists in the selected column array
    for (let i = 0; i < localSelected.length; i++) {
      const each = localSelected[i];
      if (getName(each) === name) {
        return true;
      }
    }
    return false;
  };

  const handleSelectChange = (name) => {
    setLocalSelected((prev) => {
      let newArr = [];
      if (localSelected.includes(name)) {
        newArr = prev.filter((item) => item !== name);
      } else {
        newArr = [...prev, name];
      }
      const cleaned: any = new Set(newArr);
      return [...cleaned];
    });
  };

  const handleSelectAll = () => {
    if (allSelected) {
      setLocalSelected([]);
    } else {
      setLocalSelected(cleanColumnArray(allColumns));
    }
  };
  return (
    <div className={`filter-column-cover-body ${showToggleDropdown ? '' : 'filter-body-hidden'}`}>
      <div className="filter-column-header">
        <div className="filter-column-header-title">Select Columns</div>
        {!showRoundedCancel
        && (
        <span className="filter-column-header-cancle">
          <RoundedCancleIcon border="1px solid #EAECF0" closeModal={() => setShowToggleDropdown((prev) => !prev)} />
        </span>
        )}
      </div>
      <div className="list-filter-contents">
        <NameAndSwitch
          name="select"
          label="Select All"
          onClick={handleSelectAll}
          checked={allSelected}
        />
        { cleanColumnArray(allColumns)?.map((name, index) => (
          <NameAndSwitch
            key={`${index}-${name}`}
            name={name}
            label={formatLabels[name] || name}
            onClick={handleSelectChange}
            checked={isSelected(name)}
          />
        ))}

      </div>
      <div className="filter-column-footer">
        <span onClick={() => setShowToggleDropdown(false)}>
          <StyledButton name="Apply Changes" height="40px" width="100%" borderRadius="4px" backgroundColor="#523B9F" color="white" fontSize="13px" border="1px solid #523B9F" onClick={() => applyChanges(localSelected)} />
        </span>
      </div>
    </div>
  );
};

export default FilterColumn;
