import React from 'react';

const TwitterIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" fill="white" fillOpacity="0.75" />
    <path d="M17.8812 9.40625C17.8812 9.5375 17.8812 9.66875 17.8812 9.78125C17.8812 13.6625 14.9187 18.1437 9.51875 18.1437C7.85 18.1437 6.3125 17.6562 5 16.8312C5.225 16.85 5.46875 16.8687 5.69375 16.8687C7.0625 16.8687 8.3375 16.4 9.35 15.6125C8.05625 15.5937 6.96875 14.7312 6.6125 13.5687C6.8 13.6062 6.96875 13.625 7.175 13.625C7.4375 13.625 7.7 13.5875 7.94375 13.5125C6.59375 13.25 5.58125 12.0687 5.58125 10.6437C5.58125 10.625 5.58125 10.625 5.58125 10.6062C5.975 10.8312 6.425 10.9625 6.9125 10.9812C6.125 10.4562 5.6 9.55625 5.6 8.525C5.6 7.98125 5.75 7.475 5.99375 7.04375C7.4375 8.825 9.6125 9.9875 12.05 10.1187C11.9937 9.9125 11.975 9.66875 11.975 9.44375C11.975 7.8125 13.2875 6.5 14.9187 6.5C15.7625 6.5 16.5312 6.85625 17.0562 7.41875C17.7312 7.2875 18.35 7.04375 18.9312 6.70625C18.7062 7.4 18.2375 7.9625 17.6375 8.3375C18.2375 8.2625 18.8 8.1125 19.325 7.86875C18.95 8.46875 18.4625 8.99375 17.8812 9.40625Z" fill="#312360" />
  </svg>

);
export default TwitterIcon;
