import { FunctionComponent } from 'react';
import BusinessForm from './BusinessForm';
import './BusInfo.scss';

export interface IBusInfo {
  label?: string;
}

const BusInfo: FunctionComponent<IBusInfo> = () => (
  <div className="business-info-cover">
    <div className="title">Business Profile</div>
    <div className="sub-title">Please tell us about your business</div>
    <div className="business-info-form-cover">
      <BusinessForm />
    </div>
  </div>
);

export default BusInfo;
