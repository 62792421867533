import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import {
  compareobject, mailRegex, getPhoneNum, getNameInitials,
} from '../../../utils/helpers';
import useForm from '../../../customHooks/useForm';
import useToast from '../../../customHooks/useToast';
import './MerchantProfilePage.scss';
import { useGlobalDispatch, useGlobalState } from '../../../contexts';
import TextInput from '../../../components/input';
import CountrySelectOption from '../../../components/CountrySelectOption';
import StyledButton from '../../../components/StyledButton';
import AvaterSectionDetails from './AvaterSectionDetails';
import { UPDATE_USER, UPLOAD_USER_AVATAR } from '../../../graphql/mutations';
import ChangeMyPassword from './ChangeMyPassword';

const MerchantProfilePage = () => {
  const Toast = useToast();
  const { profile } = useGlobalState();
  const { get_profile_in_app } = useGlobalDispatch();
  const [showSaveButton, setShowSaveButton] = useState(false);
  const [showChangePassword, setshowChangePassword] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);

  const [updateUserLogo] = useMutation<
  { updateMerchantUserAvatar: any },
  any
>(UPLOAD_USER_AVATAR);

  const {
    formValues,
    handleChange,
    resetForm,
    checkAllRequired,
    validateInputs,
  } = useForm(
    {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
    },
    {
      phone: {
        pattern: /^[6-9]\d{9}$/,
        errorMessage: 'Put a valid nigerian phonenumber. Format 7052648321',
        smallFormatter: (rec) => `${Number(rec)}`,
      },
      email: {
        pattern: mailRegex, // regex for email validation
        errorMessage: 'Enter a valid support Email',
      },
    },
    Toast,
  );

  useEffect(() => {
    if (profile?.email) {
      resetForm({
        firstName: profile?.firstName,
        lastName: profile?.lastName,
        email: profile?.email,
        phone: getPhoneNum(profile?.phone),
      });
    }
  }, [profile]);

  const handleBlur = () => {
    if (profile?.email) {
      const currentUserRec = {
        firstName: profile?.firstName,
        lastName: profile?.lastName,
        email: profile?.email,
        phone: getPhoneNum(profile?.phone),
      };

      const isEqual = compareobject(currentUserRec, formValues);
      if (!isEqual) {
        // show save button
        setShowSaveButton(true);
      } else {
        // hide save button
        setShowSaveButton(false);
      }
    }
  };

  const [merchantUpdateUser] = useMutation<
  { createSaving: any; merchantUpdateUser: any },
  any
>(UPDATE_USER);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    if (!checkAllRequired() || !validateInputs()) {
      setLoading(false);
      // Toast.error('Please complete all fields')
      return;
    }

    try {
      const payload: { email: string } = formValues;
      const resp = await merchantUpdateUser({
        variables: { ...payload, phone: `+234${Number(formValues.phone)}` },
      });

      setLoading(false);
      if (resp?.data?.merchantUpdateUser?.status === 200) {
        Toast.success('Personal information saved successfully');
        setShowSaveButton(false);
        get_profile_in_app();
      } else {
        Toast.error(resp?.data?.merchantUpdateUser?.message);
      }
    } catch (e) {
      setLoading(false);
      if (e && e.graphQLErrors && e.graphQLErrors.length > 0) {
        Toast.error(e.graphQLErrors[0].message);
      } else {
        Toast.error('Something went wrong');
      }
    }
  };

  const handleFile = async (file) => {
    setImageLoading(true);
    try {
      const payload = { file, resourceType: 'image' };
      const resp = await updateUserLogo({
        variables: payload,
      });

      if (resp?.data?.updateMerchantUserAvatar?.status === 200) {
        Toast.success('Successful');
        await get_profile_in_app();
      } else {
        Toast.error(resp?.data?.updateMerchantUserAvatar?.message);
      }
    } catch (e) {
      if (e && e.graphQLErrors && e.graphQLErrors.length > 0) {
        Toast.error(e.graphQLErrors[0].message);
      } else {
        Toast.error('Something went wrong');
      }
    }
    setImageLoading(false);
  };

  return (
    <div className="merchant-profile-cover container col-lg-10 col-md-12 m-0 p-0">
      {/* your profile content row */}
      <div className="row m-0 p-0 merchant-profile-cover-content-row">
        <div className="col-md-6 col-12 m-0 p-0 merchant-profile-cover-content-row-left-col">
          <div className="merchant-profile-cover-content-row-left-col-div">
            <div className="merchant-profile-cover-content-row-left-col-div-title">Your Profile</div>
            <div className="merchant-profile-cover-content-row-left-col-div-subtitle">
              Make adjustments to your personal
              information and save them.
            </div>
          </div>
          {/* eslint-disable-next-line max-len */}
          {/* <div className="merchant-profile-cover-content-row-left-col-edit-button">Edit Profile</div> */}
        </div>
        <div className="col-md-6 col-12 m-0 p-0 merchant-profile-cover-content-row-right-col">
          <div className="merchant-profile-cover-content-row-right-col-form-row m-0 p-0 row">
            <div className="col-md-6 col-6 m-0 p-0 merchant-profile-form-left-div">
              <TextInput
                customOnChange={handleChange}
                name="firstName"
                value={formValues.firstName}
                label="First Name"
                width="100%"
                onBlur={handleBlur}
              />
            </div>
            <div className="col-md-6 col-6 m-0 p-0 merchant-profile-form-right-div">
              <TextInput
                customOnChange={handleChange}
                name="lastName"
                value={formValues.lastName}
                label="Last Name"
                width="100%"
                onBlur={handleBlur}
              />
            </div>
            <div className="col-12 m-0 p-0">
              <TextInput
                customOnChange={handleChange}
                name="email"
                value={formValues.email}
                label="Email Address"
                width="100%"
                disabled
              />
            </div>
            <div className="col-md-4 col-4 m-0 p-0 merchant-profile-form-left-div">
              <CountrySelectOption />
            </div>
            <div className="col-md-8 col-8 m-0 p-0 merchant-profile-form-right-div">
              <TextInput
                name="phone"
                value={formValues?.phone}
                customOnChange={handleChange}
                label="Phone Number"
                width="100%"
                onBlur={handleBlur}
              />
            </div>
          </div>
        </div>
        {showSaveButton && (
        <div className="settings-btn-cover">
          <StyledButton
            // disabled={getBanksLoading || buttonValid()}
            onClick={handleSubmit}
            color="white"
            backgroundColor="#523B9F"
            fontSize="13px"
            name={loading ? 'Saving...' : 'Save Changes'}
            width="120px"
            height="40px"
            borderRadius="4px"
            border="none"
          />
        </div>
        )}
      </div>
      {/* change password row div */}
      <div className="row m-0 p-0 merchant-profile-cover-change-password-row">
        <div className="merchant-profile-cover-change-password-row-content col-md-6 col-12 m-0 p-0">
          <div className="change-password-row-content-title">Change Password</div>
          <div className="change-password-row-content-subtitle">
            This password can only be changed
            once in a month.
          </div>
        </div>
        <div className="merchant-profile-cover-change-password-row-button-div col-md-6 col-12 m-0 p-0">
          <div><StyledButton name="Change Password" border="1px solid #F2F4F7" borderRadius="4px" fontSize="13px" color="#475467" height="40px" width="136px" backgroundColor="white" onClick={() => setshowChangePassword(true)} /></div>
        </div>
      </div>

      {/* avater section  */}
      <AvaterSectionDetails
        logo={profile?.imageUrl}
        title="Avatar"
        subTitle="Select a beautiful picture for yourself"
        handleUploadFile={handleFile}
        alt={getNameInitials(profile?.firstName)}
        loading={imageLoading}
      />
      {
        showChangePassword && <ChangeMyPassword closeModal={() => setshowChangePassword(false)} />
      }
    </div>
  );
};
export default MerchantProfilePage;
