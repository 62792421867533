import React from 'react';

const ChevronRightIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M8.66829 3.16266C8.45593 3.37966 8.45593 3.73025 8.66975 3.94577L11.0967 6.39845L11.1527 6.448C11.367 6.61309 11.6729 6.59589 11.8676 6.39698C11.9737 6.28848 12.0268 6.1475 12.0268 6.00653C12.0268 5.86407 11.9737 5.72236 11.8661 5.61386L9.43993 3.16118L9.38388 3.11181C9.16946 2.94728 8.86296 2.96442 8.66829 3.16266ZM2.47706 7.45064C2.20822 7.48481 2 7.71782 2 7.99989C2 8.30546 2.24436 8.55346 2.54545 8.55346H12.1338L8.66982 12.0542L8.62082 12.1108C8.45747 12.3273 8.4737 12.6384 8.66836 12.8373C8.88073 13.0536 9.22618 13.0543 9.43927 12.8388L13.8393 8.39182L13.8878 8.33613C13.9616 8.23874 14 8.11983 14 7.99989C14 7.92829 13.9862 7.8567 13.9585 7.78879C13.8742 7.58139 13.6756 7.44632 13.4545 7.44632H2.54545L2.47706 7.45064Z" fill="#93370D" />
  </svg>

);

export default ChevronRightIcon;
