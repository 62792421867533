import React from 'react';
import './AppOverLay.scss';

interface IappOverLay {
    children?: any,
}
const AppOverLay = (props:IappOverLay) => (

  <div className="app-overlay app-max-width">
    <div className="app-overlay-cover">
      <div className="col-lg-4 col-md-6 col-12">{props?.children}</div>
    </div>
  </div>

);
export default AppOverLay;
