import { FunctionComponent } from 'react';

export interface ICountryPhone {
  className?: string;
}

const CountryPhone: FunctionComponent<ICountryPhone> = () => (
  <div
    style={{
      width: '35px',
      height: 50,
      display: 'flex',
      border: '1px solid rgba(234, 236, 240, 0.7',
      borderTopLeftRadius: 5,
      borderBottomLeftRadius: 5,
      backgroundColor: 'white',
      borderRight: 'none',
      paddingLeft: '0px',

    }}
  >
    <div className="phonenumber-icon">
      <svg width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="1.25" y="1.25" width="17.1" height="12.3" rx="1.75" fill="white" stroke="#F5F5F5" strokeWidth="0.5" />
        <mask id="mask0_960_41875" maskUnits="userSpaceOnUse" x="1" y="1" width="22" height="16">
          <rect x="1.25" y="1.25" width="21.5" height="15.5" rx="1.75" fill="white" stroke="white" strokeWidth="0.5" />
        </mask>
        <g mask="url(#mask0_960_41875)">
          <rect x="15.667" y="1" width="7.33333" height="16" fill="#189B62" />
          <path fillRule="evenodd" clipRule="evenodd" d="M1 17H8.33333V1H1V17Z" fill="#189B62" />
        </g>
      </svg>

    </div>
  </div>
);

export default CountryPhone;
