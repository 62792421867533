import React from 'react';

const VerifyEmailIcon = () => (
  <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle opacity="0.5" cx="30" cy="30" r="30" fill="#DCD8EC" />
    <path d="M20.27 19.5H40.43C41.816 19.5 42.95 20.6812 42.95 22.125V37.875C42.95 39.3188 41.816 40.5 40.43 40.5H20.27C18.884 40.5 17.75 39.3188 17.75 37.875V22.125C17.75 20.6812 18.884 19.5 20.27 19.5Z" stroke="#523B9F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M42.95 22.2125L30.35 31.7063L17.75 22.2125" stroke="#523B9F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>

);
export default VerifyEmailIcon;
