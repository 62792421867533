import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import './NewRoundLink.scss';

interface INewRoundLink{
  data?:any
  handleChangeTab?:any;
  activeTab?:string;
}
const NewRoundLink = ({ data, handleChangeTab, activeTab }:INewRoundLink) => {
  const { pathname } = useLocation();

  const { push } = useHistory();

  return (
    <div className="new_round_link_cover">
      {data?.map((list:any) => (list?.linkName
        ? (
          <div key={list?.id} className={list?.linkName === pathname ? 'new_active_round_link_list' : 'new_round_link_list'} onClick={() => push(list?.linkName)}>
            <div className="new_round_link_list_count">{list?.id}</div>
            {list?.name}
          </div>
        ) : (
          <div key={list?.id} className={list?.name === activeTab ? 'new_active_round_link_list' : 'new_round_link_list'} onClick={() => handleChangeTab(list?.name)}>
            <div className="new_round_link_list_count">{list?.id}</div>
            {list?.name}
          </div>
        )
      ))}
    </div>
  );
};
export default NewRoundLink;
