import React from 'react';

type Props = {
  header: string;
  value: string;
  footer: string;
  isCenter?: boolean;
}

const TransactionLabels = ({
  header, value, footer, isCenter,
}: Props) => (
  <div className={`transaction-labels ${isCenter ? 'center-text' : ''}`}>
    <span>{header}</span>
    <strong>{value}</strong>
    <span>{footer}</span>
  </div>
);

export default TransactionLabels;
