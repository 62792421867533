import React from 'react';

interface IEyeIcon{
  onClick?:any;
  width?:string;
  height?:string;
}
const EyeIcon = ({ onClick, width, height }:IEyeIcon) => (
  <svg width={width || '24'} height={height || '24'} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={onClick} style={{ cursor: 'pointer' }}>
    <path d="M12 3.75C19.5 3.75 24 12 24 12C24 12 19.5 20.25 12 20.25C4.5 20.25 0 12 0 12C0 12 4.5 3.75 12 3.75ZM12 5.25C6.72 5.25 2.997 10.1265 1.7625 12C2.9955 13.872 6.7185 18.75 12 18.75C17.28 18.75 21.003 13.8735 22.2375 12C21.0045 10.128 17.2815 5.25 12 5.25ZM12 6.75C13.3924 6.75 14.7277 7.30312 15.7123 8.28769C16.6969 9.27226 17.25 10.6076 17.25 12C17.25 13.3924 16.6969 14.7277 15.7123 15.7123C14.7277 16.6969 13.3924 17.25 12 17.25C10.6076 17.25 9.27226 16.6969 8.28769 15.7123C7.30312 14.7277 6.75 13.3924 6.75 12C6.75 10.6076 7.30312 9.27226 8.28769 8.28769C9.27226 7.30312 10.6076 6.75 12 6.75ZM12 8.25C11.0058 8.25119 10.0527 8.64666 9.34966 9.34966C8.64666 10.0527 8.25119 11.0058 8.25 12C8.25 14.067 9.9315 15.75 12 15.75C14.0685 15.75 15.75 14.067 15.75 12C15.75 9.933 14.0685 8.25 12 8.25Z" fill="#667085" />
  </svg>

);
export default EyeIcon;
