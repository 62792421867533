/* eslint-disable react/function-component-definition */
import React from 'react';

interface IRemovePointIcon{
  width?:string;
  height?:string;
}
export default function RemovePointIcon({ width, height }:IRemovePointIcon) {
  return (
    <svg width={width || '8'} height={height || '11'} viewBox="0 0 8 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.77191 4.23438C6.77191 4.23438 6.56645 7.60187 6.44726 9.02037C6.39051 9.69787 6.0738 10.0949 5.55505 10.1074C4.56786 10.1309 3.57953 10.1324 2.59272 10.1049C2.09364 10.0914 1.78224 9.68938 1.72661 9.02388C1.60667 7.59288 1.40234 4.23438 1.40234 4.23438" stroke="#F04438" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7.29545 2.61914H0.878906" stroke="#F04438" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6.05907 2.6195C5.76205 2.6195 5.50626 2.342 5.44799 1.9575L5.35605 1.3495C5.29929 1.069 5.10707 0.875 4.88799 0.875H3.28632C3.06723 0.875 2.87502 1.069 2.81826 1.3495L2.72632 1.9575C2.66805 2.342 2.41226 2.6195 2.11523 2.6195" stroke="#F04438" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

  );
}
