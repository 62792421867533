import { FunctionComponent, useState } from 'react';
import useToast from '../../customHooks/useToast';
import useSingleDocUpload from '../../network/useSingleDocUpload';
import FileIcon from '../../svgs/FileIcon';
import Gear from '../Loading/Gear';
import './UploadInputKYC.scss';

const MAX_FILE_SIZE = 2097152;

export interface IUploadInputKYC {
  label?: string;
  fileInp: any;
  width?: number;
  name: string;
  filename: string;
  externalLoading?: boolean;
  customFileUploadhandler?: any;
  isOptional?: boolean;
  disabled?: boolean;
}

const UploadInputKYC: FunctionComponent<IUploadInputKYC> = ({
  name,
  label,
  fileInp,
  externalLoading,
  filename,
  customFileUploadhandler,
  isOptional,
  disabled,
}) => {
  const {
    setFile: setDocFile,
    document,
    loading: internalUploadLoading,
  }: any = useSingleDocUpload();
  const [localFileSave, setLocalFileSave] = useState<any>(null);
  const Toast = useToast();

  const handleChange = (event: any) => {
    const [fileData] = event.target.files;
    if (fileData?.size > MAX_FILE_SIZE) {
      return Toast.error('File size should be less than 2MB');
    }
    setLocalFileSave(fileData);
    if (customFileUploadhandler) {
      customFileUploadhandler(fileData);
    } else {
      setDocFile({
        file: fileData,
        name: filename,
      });
    }
  };

  const loading = externalLoading || internalUploadLoading;
  const itHasFileOnDB = fileInp?.file?.uri || fileInp?.file?.docKey;

  return (
    <div className="upload-input-kyc-cov">
      <div
        style={{
          ...(disabled ? { backgroundColor: 'rgba(209, 209, 209, 0.1' } : {}),
        }}
        className="upload-input flexed-between"
      >
        <div className="flexed-row doc-name">
          <div className="icon">
            <FileIcon />
          </div>
          <span className="placeholder">{document?.fileName || localFileSave?.name || fileInp?.file?.fileName || label}</span>
          {isOptional && <small>(optional)</small>}
        </div>
        <label htmlFor={name}>
          {loading && !document ? <Gear /> : <span className="upload-text">{(disabled ? '' : document?.fileName || itHasFileOnDB || localFileSave?.name ? 'Change file' : 'Select File')}</span>}
        </label>
        <input
          onChange={handleChange}
          type="file"
          name={name}
          id={name}
          accept="image/*, application/pdf"
          max={548}
          disabled={disabled}
        />
      </div>
      <span className="upload-warning">JPEG, PNG, PDF. Max file size: 2mb</span>
    </div>
  );
};

export default UploadInputKYC;
