import React from 'react';
import DeactivateLinkIcon from '../../svgs/DeactivateLinkIcon';
import Buttons from '../Buttons';
import RoundedCancleIcon from '../ModalFormComp/RoundedCancleIcon';
import ModalWrapComp from '../ModalWrapComp';
import './PaymentLinkSmallModal.scss';

type Props = { closeModal: any; payload: any, onDeactivateClick: any}

const DeactivateType = ({ closeModal, payload, onDeactivateClick }: Props) => (
  <ModalWrapComp closeModal={closeModal}>
    <div>
      <RoundedCancleIcon closeModal={closeModal} />
      <div className="success-payment-link">
        <div style={{ textAlign: 'center' }}><DeactivateLinkIcon /></div>
        <div className="title">

          <h6>{payload?.title}</h6>
        </div>
        <span className="description">
          Are you sure you want to deactivate
          {' '}
          <strong>{` ${payload?.title} `}</strong>
          ?
          {' '}
          The link can be reactivated anytime.

          {/* Are you sure you want to deactivate
        <strong>{` ${payload?.title} `}</strong>
        ? The link can be reactivated anytime. */}
        </span>
        <div className="twin-buttons-cover">
          <Buttons
            name="Cancel"
            className="cancel-type"
            onClick={closeModal}
          />
          <Buttons
            name="Deactivate Link"
            className="save-type"
            type="submit"
            btnStyle="btn-md"
            onClick={onDeactivateClick}
          />
        </div>
      </div>
    </div>
  </ModalWrapComp>
);

export default DeactivateType;
