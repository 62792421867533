import React, { FunctionComponent } from 'react';

export interface IMerchantActivateAccount {
    label?: string;
    CustomComp?: any;
    CustomLabelElement?: any;
    CustomTextComp?: any;
  }
const MerchantActivationAccountComp: FunctionComponent<IMerchantActivateAccount> = ({
  label,
}) => (
  <div className="merchant-activate-account">
    <div className="merchant-activate-account-title">
      {' '}
      {label}
    </div>
    {/* {CustomComp && (
        <div className="custom-comp">
          <CustomComp />
        </div>
        )} */}
  </div>
);
export default MerchantActivationAccountComp;
