import { FunctionComponent, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import {
  useGlobalDispatch,
  useGlobalState,
} from '../../../contexts';
import TextInput from '../../../components/input';
import useForm from '../../../customHooks/useForm';
import useCustomQuery from '../../../network/useCustomQuery';
import { GET_BANKS } from '../../../graphql/queries';
import './BankInfo.scss';
import {
  UPDATE_BANK_INFO,
  BANK_VERIFICATION,
} from '../../../graphql/mutations';
import useToast from '../../../customHooks/useToast';
import SelectSearch from '../../../components/SelectSearch';
import StyledButton from '../../../components/StyledButton';
import Gear from '../../../components/Loading/Gear';

export interface IBankInfo {
  label?: string;
}

const BankInfo: FunctionComponent<IBankInfo> = () => {
  const { setStep, get_profile_in_app }:any = useGlobalDispatch();
  const { profile }:any = useGlobalState();
  const disableAllForm = profile?.merchant?.documentSentForActivation;
  const bankInfo = profile?.merchant?.bankDetails?.[0];
  const [updateBankDetails] = useMutation<
    { createSaving: any; updateBankInformation: any },
    any
  >(UPDATE_BANK_INFO);

  const Toast = useToast();

  const {
    formValues,
    handleChange,
    checkAllRequired,
    resetForm,
    validateInputs,
  } = useForm(
    {
      accountName: '',
      accountNumber: '',
      bankCode: '',
    },
    {
      accountNumber: {
        pattern: /^\d{10}$/,
        errorMessage: 'Enter a valid Account Number',
      },
    },
    Toast,
  );

  const [loading, setLoading] = useState(false);
  const [shouldResolveAccount, setShouldResolveAccount] = useState(false);
  const [accountNameLoading, setAccountNameLoading] = useState(false);
  const [getAccountName] = useMutation<
    { createSaving: any; getTransBen: any },
    any
  >(BANK_VERIFICATION);

  const [getBanks, getBanksLoading, banks] = useCustomQuery({
    query: GET_BANKS,
    onError: () => {},
    onData: () => {},
  });

  useEffect(() => {
    getBanks();
  }, []);

  useEffect(() => {
    if (profile?.merchant?.bankDetails?.[0]) {
      resetForm({
        accountName: bankInfo?.accountName,
        accountNumber: bankInfo?.accountNumber,
        bankCode: bankInfo?.bank?.nipCode,
      });
    }
  }, [profile?.merchant?.bankDetails]);

  const bankList = banks?.banks?.map?.((data: { name: string; nipCode: string }) => ({
    text: data.name,
    label: data.name,
    value: data.nipCode,
    ...data,
  })) || [];

  const handleSubmit = async () => {
    setLoading(true);
    if (!validateInputs()) {
      setLoading(false);
      return;
    }
    if (!checkAllRequired()) {
      setLoading(false);
      // setError('Please complete all fields');
      Toast.error('Complete all fields');
    } else {
      try {
        const payload: {
          accountName: string;
          accountNumber: string;
          bankCode: string;
        } = formValues;

        const resp = await updateBankDetails({
          variables: { ...payload, isOnboarding: true },
        });

        setLoading(false);
        if (resp?.data?.updateBankInformation?.status === 200) {
          // set success message
          Toast.success('Successful');
          setStep(5);
          get_profile_in_app();
        } else {
          Toast.error(resp?.data?.updateBankInformation?.message);
        }
      } catch (e) {
        setLoading(false);
        if (e && e.graphQLErrors && e.graphQLErrors.length > 0) {
          Toast.error(e.graphQLErrors[0].message);
        } else {
          Toast.error('Something went wrong');
        }
      }
    }
  };

  useEffect(() => {
    if (profile?.merchant) {
      const thisMerchant = profile?.merchant;
      resetForm({
        ...thisMerchant,
        bankCode: thisMerchant?.bank,
      });
    }
  }, [profile]);

  const resolveAccountDetails = async (
    accountNumber: string,
    bankCode: string,
  ) => {
    try {
      setShouldResolveAccount(false);
      setAccountNameLoading(true);
      const payload: {
        transferType: string;
        accountNo: string;
        bankCode: string;
      } = {
        accountNo: accountNumber,
        bankCode,
        transferType: bankCode === '090110' ? 'intra' : 'inter',
      };
      const resp = await getAccountName({ variables: payload });

      setAccountNameLoading(false);
      if (resp?.data?.getTransBen?.name) {
        // set success message
        Toast.success('Account Name Resolved');
        resetForm({
          accountName: resp?.data?.getTransBen?.name,
        });
      } else {
        Toast.error('Invalid bank account information');
        handleChange({ target: { value: '', name: 'accountName' } });
        resetForm({
          accountName: '',
        });
      }
    } catch (e) {
      setAccountNameLoading(false);
      handleChange({ target: { value: '', name: 'accountName' } });
    }
  };

  useEffect(() => {
    if (
      !Number.isNaN(Number(formValues?.accountNumber))
      && formValues?.accountNumber?.length === 10
      && formValues.bankCode?.length >= 3
      && shouldResolveAccount
      && !accountNameLoading
    ) {
      resolveAccountDetails(formValues?.accountNumber, formValues.bankCode);
    }
  }, [
    formValues?.accountNumber,
    formValues?.bankCode,
    bankList,
    shouldResolveAccount,
  ]);

  const buttonValid = () => {
    const disabled = !(
      formValues?.bankCode
      && formValues?.accountNumber?.length === 10
      && formValues?.accountName
    );
    return disabled;
  };
  const handlePrev = () => {
    setStep(3);
  };
  return (
    <div>
      <div className="bank-details-cover">
        <div className="bank-details-cover-title">
          Bank Information
        </div>
        <div className="bank-details-cover-subtitle">
          All payments received come to this account.
        </div>
        <div className="row m-0 p-0 bank-details-cover-row">
          <div className="col-md-6 col-12 m-0 p-0 bank-details-cover-row-col-left select-bank-input">
            <SelectSearch
              onChange={(e) => {
                setShouldResolveAccount(true);
                handleChange(e);
              }}
              placeholder="Bank name"
              options={bankList}
              name="bankCode"
              value={formValues?.bankCode}
              label={false}
              classes={false}
              hideLabel={false}
              required
              disabled={disableAllForm}
            />
          </div>
          <div className="col-md-6 col-12 m-0 p-0 bank-details-cover-row-col-right">
            <TextInput
              name="accountNumber"
              value={formValues?.accountNumber}
              customOnChange={(e) => {
                setShouldResolveAccount(true);
                handleChange(e);
              }}
              label="Account Number"
              width="100%"
              disabled={disableAllForm}
            />
          </div>
          <div className="col-md-6 col-12 m-0 p-0 bank-details-cover-row-col-left">
            <TextInput
              label="Account Name"
              name="accountName"
              value={accountNameLoading ? 'Getting Name...' : formValues?.accountName}
              customOnChange={handleChange}
              disabled={disableAllForm}
              required
              width="100%"
              Icon={accountNameLoading ? Gear : null}
              viewOnly
            />
            {/* <TextInput label='Bank Verification' /> */}
          </div>
          <div className="col-12 p-0 m-0 bank-details-view-cover-form-button-div">
            <span>
              <StyledButton onClick={handlePrev} color="#523B9F" backgroundColor="white" fontSize="13px" name="Previous" width="130px" height="40px" borderRadius="4px" border="1px solid #DCD8EC" />

            </span>
            <StyledButton
              disabled={disableAllForm || getBanksLoading || buttonValid()}
              onClick={handleSubmit}
              color="white"
              backgroundColor="#523B9F"
              fontSize="13px"
              name={getBanksLoading
                ? 'Fetching Banks...'
                : loading
                  ? 'Saving...'
                  : 'Save & Proceed'}
              width="130px"
              height="40px"
              borderRadius="4px"
              border="none"
            />
          </div>
        </div>

      </div>
    </div>
  );
};

export default BankInfo;
