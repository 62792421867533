import React from 'react';

const DemoIcon = () => (
  <svg width="81" height="55" viewBox="0 0 81 55" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_962_42016)">
      <path d="M80.0348 59.7244C79.4039 61.068 78.3275 61.4571 76.8834 61.4561C52.3437 61.4288 27.8035 61.4219 3.26311 61.4356C2.95133 61.4454 2.63925 61.4395 2.32805 61.418C1.04197 61.2884 0.114712 60.2402 0.164439 58.9961C0.185145 58.4041 0.432641 57.8428 0.855705 57.4283C1.27877 57.0137 1.84502 56.7777 2.43725 56.7691C3.78768 56.732 5.14104 56.7574 6.49244 56.7554H7.3846L7.3846 5.53759C6.19506 5.53759 5.03476 5.54636 3.87446 5.53759C3.25239 5.53076 2.62056 5.54734 2.01116 5.44691C0.895718 5.26068 0.113737 4.21251 0.164439 3.08732C0.186915 2.53584 0.407028 2.01085 0.784574 1.60824C1.16212 1.20563 1.6719 0.952279 2.2208 0.894454C2.53178 0.868769 2.84386 0.858681 3.15586 0.864227C27.7736 0.863577 52.3918 0.857077 77.0102 0.844727C78.4464 0.844727 79.477 1.26594 80.0328 2.62612V3.71817C79.5043 5.0998 78.4727 5.60682 77.0365 5.55026C75.6412 5.49469 74.2421 5.53759 72.8019 5.53759V56.7554C74.203 56.7554 75.5545 56.8178 76.8971 56.7388C78.3489 56.654 79.4195 57.1172 80.0328 58.4764L80.0348 59.7244ZM12.1574 56.7145L68.0272 56.7145V5.58829L12.1574 5.58829L12.1574 56.7145Z" fill="#DCD8EC" fillOpacity="0.5" />
      <path d="M34.3882 31.3956C34.0332 31.6179 33.77 31.7788 33.5106 31.9465C29.4155 34.6129 25.3245 37.2786 21.2378 39.9437C20.5368 40.402 19.8084 40.713 18.966 40.4712C18.1235 40.2294 17.5561 39.6912 17.3318 38.8449C17.0939 37.9468 17.3074 37.1415 18.0007 36.5048C18.236 36.3009 18.4882 36.1173 18.7544 35.9558C23.7817 32.6764 28.8083 29.401 33.8343 26.1294C35.7084 24.9096 37.1495 25.3757 37.9295 27.4554C38.8265 29.8287 39.7197 32.2039 40.6362 34.6366C40.9287 34.4591 41.1676 34.3178 41.4036 34.1647C46.1598 31.094 50.9151 28.0223 55.6693 24.9496C56.3402 24.5157 57.0256 24.1939 57.8622 24.4201C58.7446 24.66 59.3355 25.2099 59.5588 26.0777C59.7879 26.966 59.5851 27.783 58.8762 28.4178C58.6797 28.5872 58.47 28.7407 58.2493 28.877C52.5369 32.5711 46.8209 36.26 41.1013 39.9437C40.6957 40.2041 40.2238 40.4381 39.7587 40.5141C38.574 40.7091 37.6545 40.0559 37.1621 38.7591C36.3626 36.6491 35.5787 34.5323 34.7869 32.4213C34.669 32.1064 34.5422 31.7953 34.3882 31.3956Z" fill="#DCD8EC" fillOpacity="0.5" />
    </g>
    <defs>
      <clipPath id="clip0_962_42016">
        <rect width="79.8703" height="75" fill="white" transform="translate(0.164062 0.844727)" />
      </clipPath>
    </defs>
  </svg>

);
export default DemoIcon;
