import { FunctionComponent, useState } from 'react';
import { useMutation } from '@apollo/client';
import useForm from '../../../customHooks/useForm';
import useToast from '../../../customHooks/useToast';
import TextInput from '../../../components/input';
import ModalFormComp from '../../../components/ModalFormComp';

import { USER_CHANGE_PASSWORD } from '../../../graphql/mutations';

export interface IChangeMyPassword {
  label?: string;
  closeModal?: any;
  refetch?: any;
}

const ChangeMyPassword: FunctionComponent<IChangeMyPassword> = ({
  closeModal,
}) => {
  const [changePassword] = useMutation<
  { merchantChangePassword: any },
  any
>(USER_CHANGE_PASSWORD);
  const [loading, setLoading] = useState(false);

  const Toast = useToast();
  // regex pattern for password
  // (lower case, upper case, number, special character, minimum 8 characters)
  const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/;
  const errorMessage = 'Password must contain at least 8 characters, 1 uppercase, 1 lowercase, 1 number and 1 special character';
  const {
    formValues, handleChange, validateInputs,
  } = useForm(
    {
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
    {
      newPassword: {
        pattern,
        errorMessage,
      },
      confirmPassword: {
        pattern,
        errorMessage,
      },
    },
    Toast,
  );

  const handleSubmit = async () => {
    if (formValues?.newPassword === '' || formValues?.confirmPassword === '' || formValues?.newPassword === '') {
      Toast.error('Please fill out all fields');
      return;
    }
    if (!validateInputs()) {
      return;
    }
    if (formValues?.newPassword !== formValues?.confirmPassword) {
      Toast.error('Passwords must match');
      return;
    }
    if (formValues.newPassword === formValues.oldPassword) {
      return Toast.error('New password cannot be the same as old password');
    }
    setLoading(true);
    try {
      const payload = {
        oldPassword: formValues?.oldPassword,
        newPassword: formValues?.newPassword,
      };
      const { data } = await changePassword({
        variables: payload,
      });
      if (data?.merchantChangePassword?.status === 200) {
        Toast.success(data?.merchantChangePassword?.message);
        closeModal();
      } else {
        Toast.error(data?.merchantChangePassword?.message);
      }
    } catch (e) {
      if (e && e.graphQLErrors && e.graphQLErrors.length > 0) {
        Toast.error(e?.graphQLErrors?.[0]?.message);
      } else {
        Toast.error('Something went wrong');
      }
    }
    setLoading(false);
  };
  return (
    <ModalFormComp
      title="Change Password"
      subTitle="Change the password for your Payfi account?"
      btnAction={handleSubmit}
      btnName={loading ? 'Saving...' : 'Change Password'}
      closeModal={closeModal}
      hasCancel
      submitButtonWidth="145px"
      btnLoading={loading}
      btnDisabled={loading}
    >
      <div className="">

        <TextInput
          label="Current Password"
          isSecure
          width="100%"
          isOnModal
          name="oldPassword"
          value={formValues?.oldPassword}
          customOnChange={handleChange}

        />

        <TextInput
          label="New Password"
          isSecure
          width="100%"
          name="newPassword"
          value={formValues?.newPassword}
          customOnChange={handleChange}
          isOnModal
          disabled={loading}

        />
        <TextInput
          label="Confirm Password"
          isSecure
          width="100%"
          name="confirmPassword"
          value={formValues?.confirmPassword}
          customOnChange={handleChange}
          isOnModal
          disabled={loading}

        />
      </div>

    </ModalFormComp>
  );
};

export default ChangeMyPassword;
