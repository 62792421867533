import React from 'react';

const LogsIcon = ({ stroke }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M2.3331 9.29911C2.3331 9.56829 2.1091 9.78679 1.83315 9.78679C1.55721 9.78679 1.3332 9.56829 1.3332 9.29911V4.47608C1.3332 2.92689 2.62528 1.6665 4.21408 1.6665H7.37804C8.97008 1.6665 10.2654 2.93005 10.2654 4.48305V5.07271C10.2654 5.34125 10.0414 5.55976 9.76546 5.55976C9.48951 5.55976 9.26551 5.34125 9.26551 5.07271V4.48305C9.26551 3.46714 8.41884 2.64188 7.37804 2.64188H4.21408C3.17652 2.64188 2.3331 3.46524 2.3331 4.47608V9.29911ZM9.26531 10.9276C9.26531 10.6584 9.48932 10.4399 9.76526 10.4399C10.0412 10.4399 10.2652 10.6584 10.2652 10.9276V11.5236C10.2652 13.0728 8.97314 14.3332 7.38434 14.3332H4.21973C2.62833 14.3332 1.33301 13.0696 1.33301 11.5166C1.33301 11.2474 1.55701 11.0296 1.83296 11.0296C2.1089 11.0296 2.33291 11.2474 2.33291 11.5166C2.33291 12.5325 3.17957 13.3578 4.21973 13.3578H7.38434C8.42189 13.3578 9.26531 12.5351 9.26531 11.5236V10.9276ZM14.6285 7.81376C14.5513 7.63072 14.3688 7.51164 14.1662 7.51164H6.36574C6.08979 7.51164 5.86514 7.73079 5.86514 7.99996C5.86514 8.26914 6.08979 8.48765 6.36574 8.48765H12.956L11.9165 9.49722C11.7204 9.68723 11.7204 9.99568 11.9152 10.187C12.0126 10.2826 12.1411 10.3307 12.269 10.3307C12.3969 10.3307 12.5242 10.2826 12.6222 10.1882L14.5195 8.34578C14.6623 8.20644 14.7058 7.99616 14.6285 7.81376ZM12.7476 7.10699C12.6203 7.10699 12.493 7.05949 12.3956 6.96575L11.9171 6.50403C11.721 6.31466 11.7197 6.00558 11.9139 5.81494C12.1087 5.6224 12.4242 5.62113 12.6209 5.81114L13.0988 6.27222C13.2956 6.46223 13.2969 6.77068 13.1027 6.96195C13.0047 7.05822 12.8761 7.10699 12.7476 7.10699Z" fill={stroke} />
  </svg>

);

export default LogsIcon;
