import { CircularProgress, FormControl } from '@material-ui/core';
import { useState } from 'react';
import { useGlobalState } from '../../contexts';
import TextInput from '../../components/input';

const isDev = process.env.REACT_APP_ENV === 'development';

const SdkInstore = () => {
  const [loading, setLoading] = useState(false);
  const [error] = useState(false);
  const [amount, setAmount] = useState('');
  const { profile }:any = useGlobalState();

  const handleOnSubmit: (arg: any) => void = async (e) => {
    e.preventDefault();
    setLoading(true);

    const raw = {
      apiKey: profile?.merchant?.publicKey,
      ...(isDev ? { environment: 'staging' } : {}),
      callback: () => {
        setLoading(false);
        // console.log('callback called', reference);
      },
      onClose: () => {
        setLoading(false);
      },
    };
    // @ts-ignore
    // eslint-disable-next-line no-undef
    const payfi = new Payfi(raw);

    payfi.pay({ amount, reference: `${Date.now()}` });
  };
  return (
    <form
      onSubmit={handleOnSubmit}
      className="instore-form d-flex  flex-column align-items-center"
    >
      <div className="instore-container">
        <div className="amount-container">
          <h2 className="text-center font-weight-bold">In Store Payment</h2>
          <FormControl className=" d-flex flex-column">
            <TextInput label="Amount" width="100%" onChange={setAmount} />

            {!!error && (
              <span className="error-flag" style={{ width: 280 }}>
                {error}
              </span>
            )}
            <button className="p-3 mb-2 bg-primary text-white rounded submit-button">
              {loading ? <CircularProgress color="inherit" size={15} /> : 'Pay'}
            </button>
          </FormControl>
        </div>
      </div>
    </form>
  );
};

export default SdkInstore;
