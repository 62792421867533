import React from 'react';

const IncreaseIcon = () => (
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 8.66699C0 4.24871 3.58172 0.666992 8 0.666992C12.4183 0.666992 16 4.24871 16 8.66699C16 13.0853 12.4183 16.667 8 16.667C3.58172 16.667 0 13.0853 0 8.66699Z" fill="#F6FEF9" />
    <path d="M11.7185 5.29807L4.64746 12.3691" stroke="#039855" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M6.0261 5.31028L11.7178 5.29803L11.7061 10.9902" stroke="#039855" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>

);

export default IncreaseIcon;
