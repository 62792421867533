/* eslint-disable max-len */
/* eslint-disable react/jsx-no-bind */
import {
  Fragment, FunctionComponent, useState,
} from 'react';
import { getClassStyle } from '../../utils/tableHelpers';
import formatBodyTag from '../../utils/formatBody';
// import Checkbox from '../CheckboxNew';
import ImageComp from '../InitialsImage';
import ViewLink from '../../svgs/ViewLink';
import MerchantDropdown from '../MerchantDropdown';
import ListIcon from '../../svgs/ListIcon';
import './MobileTable.scss';

export interface ITableBody {
  content: any;
  clickable: any;
  handleClick: any;
  allowKeyArr: any;
  dominantName: any;
  imageToShow: any;
  hideDominantImage: any;
  action: any;
  checked?: any;
  handleChecked?: any;
  viewLinkFunc?: any;
  handleSelectDropdown?:any;
  dropdownData?:any;
  actionList?:any;
  viewLinkText?:any;
  hasPadding?:boolean;
  btnObj?: any;
}

const TableBody: FunctionComponent<ITableBody> = ({
  content,
  clickable,
  handleClick,
  allowKeyArr,
  dominantName,
  imageToShow,
  hideDominantImage,
  action,
  viewLinkFunc,
  handleSelectDropdown,
  dropdownData,
  actionList,
  viewLinkText,
  hasPadding,
}) => {
  const singleRec = (key_val: string) => (
    key_val === 'status' ? <div className={content?.status} /> : (
      <p
        className={`${
          [
            'status',
            'enabled',
            'quantity',
            'statusName',
            'transactionType',
            'type',
            'amount',
            'trxAmount',
            'charges',
            'fees',
            'commission',
          ].includes(key_val)
            ? getClassStyle(content, key_val)
            : ''
        }`}
      >
        {typeof content[key_val] === 'object'
          ? ''
          : formatBodyTag(content, key_val)}
      </p>
    )
  );

  const doubleRec = (ele: [string, string] | any[], hasThree:boolean) => (actionList ? (
    <div className="double-cell-list">
      <div className="double-rec">
        {singleRec(ele[0])}
        {singleRec(ele[1])}
      </div>
      <div className="single-rec">{singleRec(ele[2])}</div>
    </div>
  ) : (
    <div className="double-cell">
      {singleRec(ele[0])}

      {
        hasThree ? (
          <div>
            {' '}
            {singleRec(ele[1])}
            {singleRec(ele[2])}
          </div>
        ) : singleRec(ele[1])
      }

    </div>
  ));

  const [dropdownState, setdropdownState] = useState({
    status: false,
    id: '',
  });

  const handleShowDropdown = (id:any) => {
    if (dropdownState?.status) {
      setdropdownState({
        status: false,
        id: '',
      });
    } else {
      setdropdownState({
        id,
        status: true,

      });
    }
  };
  const handleAndClearDropdowState = (title:any, details:any) => {
    handleSelectDropdown(title, details);
    setdropdownState({
      status: false,
      id: '',
    });
  };
  return (
    <tbody className={hasPadding && 'add-top-bottom-padding'}>
      <tr className="" style={{ position: 'relative' }}>
        {/* <td style={{ background: 'green', width: '10px', padding: '0px' }}>w</td> */}
        {allowKeyArr?.map((ele: any, index: number): any => {
          const key = ele;
          const isDouble = Array.isArray(ele);
          const hasThree = isDouble && ele?.length === 3;
          const ImageNameColumn = () => (
            <td
              onClick={() => {
                handleClick?.(content);
              }}
              style={{ cursor: clickable ? 'pointer' : '' }}
              className="flexed-row "
              key={index}
            >
              {!hideDominantImage && (
                <img
                  className="table-img"
                  src={
                    content[imageToShow]
                      || 'https://publicpayfiimages.s3.amazonaws.com/randomAsset/dummyTableImage_imageBck_haw7f8.jpg'
                  }
                  alt="img url"
                />
              )}
              <p>
                {typeof content[key] === 'object'
                  ? ''
                  : formatBodyTag(content, key)}
              </p>
            </td>
          );

          const NormalColumns = () => (
            <td
              onClick={() => {
                handleClick?.(content);
              }}
              style={{ cursor: clickable ? 'pointer' : '' }}
              className="mobile-double-column"
              key={index}
            >
              {!isDouble ? singleRec(ele) : doubleRec(ele, hasThree)}
            </td>
          );

          if (imageToShow && dominantName && key === dominantName) {
            return (
              <Fragment key={index}>
                <ImageNameColumn />
              </Fragment>
            );
          }

          if (['img', 'imgUrl', 'image', 'photo'].includes(key)) {
            return (
              <Fragment key={index}>
                <td
                  onClick={() => {
                    handleClick?.(content);
                  }}
                  style={{ cursor: clickable ? 'pointer' : '' }}
                  className="td-image-cover"
                  key={index}
                >
                  <ImageComp content={content} key={key} index={key} />
                </td>
              </Fragment>
            );
          }

          if (['link'].includes(key)) {
            return (
              <Fragment key={index}>
                <td
                  onClick={() => {
                    handleClick?.(content);
                  }}
                  style={{ cursor: clickable ? 'pointer' : '' }}
                  className="link-view"
                  key={index}
                >
                  <ViewLink />
                  <span onClick={() => viewLinkFunc(content, key)}>
                    {viewLinkText || 'View Link'}
                  </span>
                </td>
              </Fragment>
            );
          }

          return (
            <Fragment key={index}>
              <NormalColumns />
            </Fragment>
          );
        })}
        {action && (
          <td className="action-key">
            <span onClick={() => action.fxn(content)}>{action?.name}</span>
          </td>
        )}
        {
          actionList && (
          <td className="mobile-list-icon-cover">
            <span onClick={() => handleShowDropdown(content?.id)} style={{ cursor: 'pointer' }}>
              <ListIcon />
            </span>
            {
              dropdownState?.status && dropdownState?.id === content?.id && (
              <div style={{
                position: 'absolute', background: 'white', right: '10%', width: '96px', zIndex: 1,
              }}
              >
                {' '}
                <MerchantDropdown data={dropdownData} details={content} handleDropdown={handleAndClearDropdowState} fontSize="9px" />
              </div>
              )
            }

          </td>
          )
        }

      </tr>

    </tbody>
  );
};

export default TableBody;
