import React from 'react';

const GifLoader = ({ height = '80px' }: { height?: string}) => (
  <img
    style={{
      objectFit: 'contain',
      height,
    }}
    src="https://publicpayfiimages.s3.amazonaws.com/randomAsset/gif_loader_Loader.gif"
    alt=""
  />
);

export default GifLoader;
