import React from 'react';

const ChevronLeft = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M5.10786 2.47561C5.2972 2.66317 5.29851 2.96722 5.10656 3.15477L2.26839 5.93416L2.21825 5.97698C2.02641 6.11966 1.7522 6.10475 1.57804 5.93224C1.4837 5.83878 1.43555 5.71588 1.43555 5.59361C1.43555 5.47071 1.4837 5.34717 1.57934 5.25371L4.41751 2.47369L4.46765 2.43087C4.65949 2.28819 4.9337 2.3031 5.10786 2.47561ZM5.15033 10.8973C5.29679 11.0855 5.28123 11.3553 5.10767 11.5273C4.91768 11.7148 4.60861 11.7155 4.41732 11.5279L0.143796 7.34155L0.0999333 7.2925C0.0334366 7.20711 0 7.1046 0 7.00165C0 6.87939 0.0481487 6.75649 0.142494 6.66303C0.332486 6.47484 0.641549 6.4742 0.832843 6.66175L5.10637 10.8481L5.15033 10.8973Z" fill="#523B9F" />
  </svg>

);

export default ChevronLeft;
