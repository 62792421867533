import React, { useEffect, useRef } from 'react';
import DropdownIcon from '../../svgs/DropdownIcon';
import Dropdown from '../Dropdown';
import './DashboardDateComp.scss';

const DashboardDateComp = ({ handleSelect, selected }: { handleSelect: any; selected: number;}) => {
  const [showDateList, setShowDateList] = React.useState(false);
  const dateSelectRef = useRef<HTMLDivElement>(null);
  const handleClick = (e) => {
    if (dateSelectRef?.current?.contains(e.target)) {
      return;
    }
    setShowDateList(false);
  };
  useEffect(() => {
    window.addEventListener('click', handleClick);
    return () => {
      window.removeEventListener('click', handleClick);
    };
  }, []);

  const handleSelectInternal = (arg: any) => {
    handleSelect(arg?.value);
    setShowDateList(false);
  };

  return (
    <div ref={dateSelectRef} className="dashboard-date-comp">
      <div
        onClick={() => setShowDateList((prev) => !prev)}
        className="flexed-row selected-title"
      >
        <span>{`Last ${selected} days`}</span>
        <DropdownIcon />
      </div>
      <div
        className={`date-select-dropdown ${showDateList ? '' : 'date-select-hidden'}`}
      >
        <Dropdown onClick={handleSelectInternal} options={[{ label: '7 Days', value: '7' }, { label: '1 Month', value: '30' }, { label: '1 Year', value: '365' }]} />
      </div>
    </div>
  );
};

export default DashboardDateComp;
