import React, { useEffect } from 'react';
import { VERIFY_URL_NAME } from '../../graphql/queries';
import useCustomQuery from '../../network/useCustomQuery';
import Loading from '../Loading';
import ActivityCheckIcon from '../../svgs/ActivityCheckIcon';
// import RejectedIcon from '../../svgs/RejectedIcon';
import ActivityStatusIcon from '../../svgs/ActivityStatusIcon';

type Props = {
  input: string;
}

const GetPaymentNameStatus = ({ input }: Props) => {
  const [verifyPaymentUrl, loading, data] = useCustomQuery({
    query: VERIFY_URL_NAME,
    onError: () => {},
    onData: () => {},
  });
  useEffect(() => {
    if (input) {
      verifyPaymentUrl({
        variables: {
          name: input,
        },
      });
    }
  }, [input]);

  const isSuccess = !data?.checkIfPaymentLinkNameExist;

  if (!input) {
    return (
      <ActivityStatusIcon color="#E4E4E4" />
    );
  }

  return (
    <div>
      {
        loading ? (
          <Loading />) : (
          isSuccess ? <ActivityCheckIcon /> : <ActivityStatusIcon color="red" />
        )
      }
    </div>
  );
};

// use react. memo to too much prevent rendering

// const myComparison = (prevProps: Props, nextProps: Props) => {
//   console.log(prevProps, nextProps);
//   if (prevProps.input === nextProps.input) {
//     return true;
//   }
//   return false;
// };

export default GetPaymentNameStatus;
