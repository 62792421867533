import React from 'react';

const ExportIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M3.58825 11.3683C3.4151 11.5767 3.42197 11.8892 3.6145 12.0953C3.82307 12.3186 4.17093 12.3331 4.39098 12.1276L11.3984 5.58289L11.2563 10.5057L11.2591 10.5805C11.2875 10.8502 11.5117 11.0666 11.7898 11.0791C12.0926 11.0922 12.3456 10.8569 12.3542 10.5539L12.5345 4.30068L12.5319 4.22686C12.5194 4.1053 12.4663 3.9922 12.3844 3.90454C12.3356 3.85222 12.2766 3.80933 12.21 3.77856C12.0068 3.68457 11.7695 3.72138 11.6079 3.87228L3.63529 11.3184L3.58825 11.3683ZM5.18615 4.00859C5.17906 4.31213 5.41837 4.56835 5.72174 4.57992L9.1695 4.71588L9.24426 4.71385C9.51358 4.68821 9.72539 4.46686 9.73189 4.18861C9.73543 4.03684 9.67801 3.89757 9.58178 3.79455C9.48455 3.69044 9.34902 3.62311 9.1963 3.61728L5.74907 3.48082L5.67441 3.48299C5.4054 3.50911 5.19311 3.73084 5.18615 4.00859Z" fill="#475467" />
  </svg>

);

export default ExportIcon;
