import React from 'react';
import { useMutation } from '@apollo/client';
import TextInput from '../../../components/input';
import StyledButton from '../../../components/StyledButton';
import { PREVIEW_MANUAL_LOAN_CREATE } from '../../../graphql/admin_mutations';
import useToast from '../../../customHooks/useToast';

type Props = {
  formValues: any;
  handleChange: any;
  setShowNext: any;
  loading: boolean;
  userId: string;
  setLoading: any;
  setShowPreview: any;
  setManualLoanPreviewDetails: any;
  merchantId: string;
  scheduleDate: any;
}

const ProductAndFeesView = ({
  formValues,
  handleChange,
  setShowNext,
  loading,
  userId,
  setLoading,
  setShowPreview,
  setManualLoanPreviewDetails,
  merchantId,
  scheduleDate,
}: Props) => {
  const [previewManualLoanCreate] = useMutation<
  { previewManualLoanCreate : any },
  any
  >(PREVIEW_MANUAL_LOAN_CREATE);
  console.log('formValues', formValues);
  const Toast = useToast();

  const handleProceedToPreviewScreen = async () => {
    if (!formValues?.productName || !formValues?.interestRate
       || !formValues?.processingFee
       || !formValues?.insurancePremium || !formValues?.productAmount) {
      Toast.error('all fields are required.');
      return;
    }
    if (Number(formValues?.interestRate) < 0 || Number(formValues?.interestRate) > 100) {
      Toast.error('interest rate should range from 0 to 100, inclusive.');
      return;
    }
    if (Number(formValues?.processingFee) < 0) {
      Toast.error('processing fee should not be less than 0');
      return;
    }
    if (Number(formValues?.insurancePremium) < 0 || Number(formValues?.insurancePremium) > 100) {
      Toast.error('insurance premium should range from 0 to 100, inclusive.');
      return;
    }
    if (Number(formValues?.productAmount) < 0 || Number(formValues?.productAmount) > 500000) {
      Toast.error('product amount should range from 0 to 500000, inclusive.');
      return;
    }
    setLoading(true);
    let input;
    try {
      input = {
        downpaymentPercentage: parseFloat(formValues?.downPaymentPercentage),
        merchantId,
        tenor: Number(formValues?.loanTenor),
        userId,
        purchaseAmount: Number(formValues?.productAmount),
        orderName: formValues?.productName,
        dateDisbursed: scheduleDate,
      };
      const resp = await previewManualLoanCreate({
        variables: { input },
      });
      const { data, status, message } = resp?.data?.previewManualLoanCreate;
      if (status === 200) {
        Toast.success(message);
        setLoading(false);
        setManualLoanPreviewDetails(data);
        setShowPreview(true);
      } else {
        Toast.error(message);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      if (e && e.graphQLErrors && e.graphQLErrors.length > 0) {
        Toast.error(e.graphQLErrors[0].message);
      } else {
        Toast.error('Something went wrong');
      }
    }
  };

  return (
    <div className="row">
      <TextInput name="productName" value={formValues?.productName} label="Product Name" customOnChange={handleChange} width="100%" />
      <TextInput
        type="percent"
        name="interestRate"
        value={formValues?.interestRate}
        label="Interest Rate"
        customOnChange={handleChange}
        width="100%"
        disabled
      />
      <TextInput
        name="processingFee"
        type="cash"
        value={formValues?.processingFee}
        label="Processing Fee"
        customOnChange={handleChange}
        width="100%"
        disabled
      />
      <TextInput
        name="insurancePremium"
        value={formValues?.insurancePremium}
        label="Insurance Premium"
        customOnChange={handleChange}
        width="100%"
        type="percent"
        disabled
      />
      <div style={{ marginBottom: '64px' }}>
        <TextInput name="productAmount" type="cash" value={formValues?.productAmount} label="Purchase Amount" customOnChange={handleChange} width="100%" />
      </div>
      <div className="create_loan_btn_cover">
        <div>
          <StyledButton name="Previous" width="60px" backgroundColor="white" color="#5941A9" border="1px solid #E6E7EC" fontSize="12px" borderRadius="4px" height="35px" onClick={() => setShowNext(false)} />
        </div>
        <div>
          <StyledButton name={loading ? 'Processing...' : 'Proceed'} width="90px" backgroundColor="#5941A9" color="white" border="none" fontSize="12px" borderRadius="4px" height="35px" onClick={handleProceedToPreviewScreen} disabled={loading} />
        </div>
      </div>
    </div>
  );
};

export default ProductAndFeesView;
