import React from 'react';
import './Loading.scss';

const Gear = () => (
  <div className="gear-loading">
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.6" d="M3.65118 7.24805H0.75188C0.336642 7.24805 0 7.58469 0 7.99993C0 8.41517 0.336642 8.75181 0.75188 8.75181H3.65118C4.06642 8.75181 4.40306 8.41517 4.40306 7.99993C4.40306 7.58469 4.06642 7.24805 3.65118 7.24805Z" fill="#523B9F" />
      <path d="M15.2478 7.24805H12.3486C11.9333 7.24805 11.5967 7.58469 11.5967 7.99993C11.5967 8.41517 11.9333 8.75181 12.3486 8.75181H15.2478C15.663 8.75181 15.9997 8.41517 15.9997 7.99993C15.9997 7.58469 15.663 7.24805 15.2478 7.24805Z" fill="#523B9F" />
      <path opacity="0.8" d="M7.99993 0C7.58469 0 7.24805 0.336642 7.24805 0.75188V3.65113C7.24805 4.06637 7.58469 4.40301 7.99993 4.40301C8.41517 4.40301 8.75181 4.06637 8.75181 3.65113V0.75188C8.75181 0.336642 8.41517 0 7.99993 0Z" fill="#523B9F" />
      <path opacity="0.8" d="M7.99993 11.5969C7.58469 11.5969 7.24805 11.9336 7.24805 12.3488V15.2481C7.24805 15.6633 7.58469 15.9999 7.99993 15.9999C8.41517 15.9999 8.75181 15.6633 8.75181 15.2481V12.3488C8.75181 11.9336 8.41517 11.5969 7.99993 11.5969Z" fill="#523B9F" />
      <path opacity="0.6" d="M5.45671 10.5435C5.16312 10.2498 4.68703 10.2498 4.3934 10.5435L2.34327 12.5935C2.04964 12.8871 2.04964 13.3632 2.34327 13.6569C2.49009 13.8037 2.68252 13.8771 2.87495 13.8771C3.06733 13.8771 3.25981 13.8037 3.40658 13.6569L5.45671 11.6068C5.75034 11.3132 5.75034 10.8372 5.45671 10.5435Z" fill="#523B9F" />
      <path d="M13.6568 2.34323C13.3632 2.04965 12.8871 2.04965 12.5935 2.34323L10.5435 4.39331C10.2498 4.68695 10.2498 5.16299 10.5435 5.45662C10.6902 5.60344 10.8827 5.67687 11.0751 5.67687C11.2675 5.67687 11.46 5.60344 11.6068 5.45662L13.6568 3.40654C13.9504 3.11296 13.9504 2.63687 13.6568 2.34323Z" fill="#523B9F" />
      <path opacity="0.6" d="M5.45666 4.39336L3.40658 2.34323C3.113 2.04965 2.63691 2.04965 2.34327 2.34323C2.04964 2.63682 2.04964 3.11296 2.34327 3.40654L4.3934 5.45667C4.54022 5.60349 4.73265 5.67687 4.92503 5.67687C5.11746 5.67687 5.30989 5.60344 5.45666 5.45667C5.75029 5.16309 5.75029 4.687 5.45666 4.39336Z" fill="#523B9F" />
      <path d="M13.6568 12.5934L11.6068 10.5434C11.3132 10.2498 10.8371 10.2498 10.5435 10.5434C10.2498 10.8371 10.2498 11.3131 10.5435 11.6067L12.5935 13.6567C12.7403 13.8035 12.9327 13.877 13.1252 13.877C13.3176 13.877 13.51 13.8035 13.6568 13.6567C13.9504 13.3631 13.9504 12.887 13.6568 12.5934Z" fill="#523B9F" />
    </svg>
  </div>
);

export default Gear;
