import moment from 'moment';
import React, { useEffect } from 'react';
import DashboardDateComp from '../../components/DashboardDateComp';
import { formatCash } from '../../utils/tableHelpers';
import TransactionLabels from './TransactionLabels';

const OtherBalances = ({ getOverview, payload, loading }:
  { getOverview: any; payload: any; loading: Boolean}) => {
  const daysInMonth = moment().daysInMonth();
  const [selected, setSelected] = React.useState(daysInMonth);
  const handleSelect = (arg: any) => {
    setSelected(Number(arg));
    getOverview({
      variables: {
        timePeriod: Number(arg),
      },
    });
  };
  useEffect(() => {
    getOverview({
      variables: {
        timePeriod: selected,
      },
    });
  }, []);
  return (
    <div className="balance-card overview-card">
      <div className="flexed-between balance-card-title">
        <h5 className="card-overview-title">{loading ? 'Loading...' : 'Other Balances'}</h5>
        <DashboardDateComp selected={selected} handleSelect={handleSelect} />
      </div>
      <div className="balance-record">
        <div className="border-b">
          <TransactionLabels
            header="Total Revenue"
            footer="This is the total amount of transactions you have processed through Payfi"
            value={formatCash(payload?.totalRevenue, '₦ ') as string}
            isCenter
          />
        </div>
        <div className="flexed-between trx-label-wrap">
          <TransactionLabels
            header="Total Paid Settlement"
            footer="This is the total amount of money
            settled into your account"
            value={formatCash(payload?.totalPaidSettlement, '₦ ') as string}
          />
          <TransactionLabels
            header="Unsettled Balance"
            footer="This is the total amount of money left to be settled into your account"
            value={formatCash(payload?.totalUnsettledBalance, '₦ ') as string}
          />
        </div>
      </div>
    </div>
  );
};

export default OtherBalances;
