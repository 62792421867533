import React from 'react';
import Buttons from '../Buttons';
// import RoundedCancleIcon from '../ModalFormComp/RoundedCancleIcon';
import StyledButton from '../StyledButton';
import FilterAmount from './FilterAmount';
import './FilterComponents.scss';
import FilterDate from './FilterDate';
import SelectComp from './SelectComp';

type Props = {
  className?: string;
  filterObj: any;
  onClose: any;
}

type IFilterDate = {
  from?: string;
  to?: string;
  setFrom?: any;
  setTo?: any;
  fromDate?: string;
  toDate?: string;
  setFromDate?: any;
  setToDate?: any;
  setFilters?: any;
  label?: string;
}
type IFilterAmount = {
  min: number | string;
  max: number | string;
  setMin: any;
  setMax: any;
  label?: string;
}

type IFilterSelect = {
  options: any;
  name?: string;
  label: string;
  selectedOptions: any;
  onChange: any;
  placeholder?: string;
}

type IFilterProps = IFilterDate & IFilterAmount & IFilterSelect

const FilterComponents = (props: Props) => {
  const renderComponents = (
    type: string,
    compProps: IFilterProps,
    index,
  ) => {
    switch (type) {
    case 'dateRange':
      return (
        <FilterDate
          {...compProps}
          key={index}
        />
      );
    case 'alternativeDateRange':
      return (
        <FilterDate
          type="alternativeDateRange"
          {...compProps}
          key={index}
        />
      );
    case 'amountRange':
      return (
        <FilterAmount
          {...compProps}
          key={index}
        />
      );
    case 'selectBox':
      return (
        <SelectComp
          {...compProps}
          key={index}
        />
      );
    default:
      return <div />;
    }
  };

  return (
    <div className={`filter-components ${props?.className || ''}`}>
      <div className="filter-header flexed-between">
        <h6>Filter</h6>
        <span
          className="filter-reset"
          onClick={props?.onClose}
        >
          {/* Clear all */}
          {/* <RoundedCancleIcon backgroundColor="#F8F7FB" border="1px solid #EAECF0" /> */}
        </span>
      </div>
      <div className="filter-content">
        {/* <FilterDate
          dateRange={dateRange}
          setDateRange={setDateRange}
        />
        <SelectComp
          selectedOptions={selectedOptions}
          onChange={setSelectedOptions}
          label="Status"
          placeholder="Select Status"
          options={[
            { label: 'Successful', value: 'success' },
            { label: 'Pending', value: 'pending' },
            { label: 'Failed', value: 'failed' },
          ]}
        />
        <FilterAmount
          amountRange={amountRange}
          setAmountRange={setAmountRange}
        /> */}
        {
          props?.filterObj?.filters?.map(
            ({
              type,
              ...props
            }: IFilterProps & {type: string}, index) => renderComponents(type, props, index),
          )
        }
      </div>
      <div className="filter-buttons flexed-between">
        <div>
          <StyledButton
            name="Clear"
            onClick={() => {
              props?.filterObj?.resetFilters();
            }}
            backgroundColor="white"
            color="#98A2B3"
            border="1px solid #EAECF0"
            width="97px"
            height="40px"
            fontSize="12px"
            borderRadius="4px"
          />
        </div>
        <div>
          {' '}
          <Buttons
            type="button"
            className="btn-sm bg-primary text-white"
            name="Filter"
            onClick={() => {
              props?.filterObj?.submitFilters(props?.onClose);
            }}
          />

        </div>

      </div>
    </div>
  );
};

export default FilterComponents;
