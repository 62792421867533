import React from 'react';
import LabelValueComp from '../../components/LabelValueComp';

type Props = {
  customer: any
}

const CustomerInfo = ({ customer }: Props) => {
  const records = {
    'Email Address': customer?.email,
    'No of transactions': customer?.transactionCount,
    'Total Transactions': customer?.transactionAmount,
    'Customer Ref': customer?.payfiId,
    'Phone Number': customer?.phone,
    'Date Created': customer?.createdAt,
  };
  return (
    <div className="customer-info">
      <div className="customer-info-header">
        <h5>Customer Details</h5>
      </div>
      <aside>
        {Object.keys(records).map((key, index) => (
          <LabelValueComp key={index} label={key} value={records[key]} />
        ))}
      </aside>
    </div>
  );
};

export default CustomerInfo;
