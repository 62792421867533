/* eslint-disable max-len */
import React from 'react';
import { useHistory } from 'react-router-dom';
import CloseIcon from '../../svgs/CloseIcon';
import VerifyEmailIcon from '../../svgs/VerifyEmailIcon';
import { useGlobalDispatch } from '../../contexts/globalContext';
import './ResetPasswordModal.scss';

interface IResetPasswordModal{
    email?:any,
    handleResend?:any,
    resendLoading?:boolean,
}
const ResetPasswordModal = (props:IResetPasswordModal) => {
  const { push } = useHistory();
  const { setShowResetPasswordModal } = useGlobalDispatch();
  const handleRoutePage = () => {
    push('/');
  };
  return (
    <div className="reset-password-modal">
      <div className="reset-password-modal-closeIcon"><span onClick={() => setShowResetPasswordModal(false)}><CloseIcon color="black" /></span></div>
      <div className="reset-password-modal-icon">
        <VerifyEmailIcon />
      </div>
      <div className="reset-password-modal-title">
        We’ve sent a mail
      </div>
      <div className="reset-password-modal-subtitle">
        A mail has been sent to
        {' '}
        <span>{props?.email}</span>
        {' '}
        Please follow the instructions to reset your password
      </div>
      <div className="reset-password-modal-button">
        <button className="okay-button mb-2 bg-primary text-white rounded" onClick={handleRoutePage}>

          Okay

        </button>
      </div>
      <div className="reset-password-modal-resend">
        Didn’t get any mail?
        {' '}
        <span onClick={!props?.resendLoading && props?.handleResend}>{props?.resendLoading ? 'Sending mail...' : 'Resend Mail'}</span>
      </div>
    </div>
  );
};
export default ResetPasswordModal;
