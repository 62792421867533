/* eslint-disable no-mixed-operators */
/* eslint-disable max-len */
import React, { FunctionComponent } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useGlobalState } from '../../contexts';
// import { useGlobalState } from '../../contexts/AdminGlobalContext';
import { setItemsToLocalStorage } from '../../utils/helpers';
import './SideBar.scss';

export interface ILinkComp {
  activate?: boolean;
  dashboard?: boolean;
  settlements?: boolean;
  transaction?: boolean;
  disputes?: boolean;
  name?: string;
  link: string;
  Icon?: any;
  disabled?: boolean;
  onClick?: () => void;
  shouldNotBeActive?: boolean;
  closeDropDownAndSideBar?: () => void;
  className?: string;
  subTabs?: any,
  subTabDropdown?: boolean,

  activeTabName?:string;
  setActiveTabName?:any;
  setSubTabDropdown?: (newValue: boolean) => void;

}

const LinkComp: FunctionComponent<ILinkComp> = ({
  name,
  link,
  Icon,
  disabled,
  onClick,
  shouldNotBeActive,
  closeDropDownAndSideBar,
  className,
  subTabs,
  subTabDropdown,
  setSubTabDropdown,
  setActiveTabName,
  activeTabName,

}) => {
  const { pathname } = useLocation();
  const { profile } = useGlobalState();
  const adminPermissions = profile?.role?.permissions?.map((permission) => permission.name);
  const handleClick = (e) => {
    closeDropDownAndSideBar?.();

    if (disabled) e.preventDefault();

    setItemsToLocalStorage('nav-title', name);
  };

  const permissionWithArray = (permissionData:any) => {
    const checkIfPermissionExist = permissionData?.find((list:any) => adminPermissions?.includes(list));
    if (checkIfPermissionExist) {
      return true;
    }
    return false;
  };

  const hasPermission = (permission: any) => {
    const permissionIsArray = Array.isArray(permission);
    if (permissionIsArray) {
      return permissionWithArray(permission);
    }
    return !permission
      || adminPermissions?.includes(permission);
  };

  return (
    <li>
      {subTabs && !disabled
        ? (
          <div
            className={`${(subTabDropdown && name === activeTabName) || pathname.startsWith(link) ? 'nav-sub-link-comp-active' : ''} nav-sub-link-comp ${disabled ? 'disabled-link' : ''} ${shouldNotBeActive ? 'inactive' : ''} ${className}`}
          >
            <div
              className={`${(subTabDropdown && name === activeTabName) || pathname.startsWith(link) ? 'nav-link-custom-active' : ''} nav-link-custom`}
              onClick={() => {
                setSubTabDropdown(!subTabDropdown);
                setActiveTabName(name);
              }}
            >
              <Icon stroke={(subTabDropdown && name === activeTabName) || pathname.startsWith(link) ? '#5941A9' : '#475467'} />

              <p className={`nav-link-custom-p ${((subTabDropdown && name === activeTabName) || pathname.startsWith(link)) && 'nav-link-custom-p-active'}`}>{name}</p>
            </div>
            {subTabDropdown && name === activeTabName ? (
              subTabs?.map((subTab) => (
                subTab?.disabled || hasPermission(subTab?.permission)
                && (
                <NavLink
                  onClick={onClick || handleClick}
                  to={subTab?.link}
                  className={`nav-sub-link ${subTab?.disabled ? 'disabled-link' : ''} ${shouldNotBeActive ? 'inactive' : ''} ${disabled ? 'disabled-link' : ''} ${className}`}
                  style={{ background: 'none' }}
                  activeClassName="active-sub-link"
                >
                  <p className="nav-sub-link-p">{subTab.name}</p>
                </NavLink>
                )
              ))
            ) : ''}
          </div>
        )
        : (!disabled
          && (
          <NavLink
            onClick={onClick || handleClick}
            to={name !== 'Logout' && link}
            className={`nav-link-comp d-flex ${shouldNotBeActive ? 'inactive' : ''} ${disabled ? 'disabled-link' : ''} ${className}`}
            activeClassName={`${shouldNotBeActive ? 'inactive' : 'active-link'}`}
          >
            <Icon />

            <p className="nav-p">{name}</p>
          </NavLink>
          )
        )}
    </li>
  );
};

export default LinkComp;
