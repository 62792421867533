/* eslint-disable max-len */
import React from 'react';
import AddNew from '../../svgs/AddNew';
import CloseButtonIcon from '../../svgs/CloseButtonIcon';
import RemoveOld from '../../svgs/RemoveOld';
import { capitalizeOnlyFirstLetter } from '../../utils/helpers';
import TextInput from '../input';
import StyledButton from '../StyledButton';
import './DoubleInputGenerator.scss';

interface IDoubleInputGenerator{
  doubleInputs?:any;
  setDoubleInputs?:any;
}
const DoubleInputGenerator = ({ doubleInputs, setDoubleInputs }:IDoubleInputGenerator) => {
  const handleChangeproductOptions = (e:any, attr:string) => {
    const { value } = e.target;
    setDoubleInputs((prev: any) => {
      const index = prev.findIndex((input) => input.attr === attr);
      const newInputs = [...prev];
      newInputs[index].name = value;
      return newInputs;
    });
  };

  const handleChangeproductOptionList = (e:any, attr:string) => {
    const { value } = e.target;
    setDoubleInputs((prev: any) => {
      const index = prev.findIndex((input) => input.attr === attr);
      const newInputs = [...prev];
      newInputs[index].optionName = value;
      return newInputs;
    });
  };

  const handleAddText = (attr:any, optionName:string) => {
    const getAllDoubleInputs = doubleInputs?.find((data:any) => data?.attr === attr);
    if (getAllDoubleInputs) {
      // eslint-disable-next-line no-unsafe-optional-chaining
      const setNewDoubleInput = doubleInputs?.map((list:any) => {
        const findExistingValue = list?.value?.find((val:any) => val === optionName);

        // eslint-disable-next-line no-unsafe-optional-chaining
        return (list?.attr === attr ? { ...list, value: findExistingValue ? [...list?.value] : [...list?.value, optionName], optionName: '' } : list);
      });
      setDoubleInputs(setNewDoubleInput);
    }
  };

  const handleRemove = (attr, removedValue) => {
    const getAllDoubleInputs = doubleInputs?.find((data:any) => data?.attr === attr);
    if (getAllDoubleInputs) {
      // eslint-disable-next-line no-unsafe-optional-chaining
      const setNewDoubleInput = doubleInputs?.map((list:any) => {
        const filterExistingValue = list?.value?.filter((val:any) => val !== removedValue);

        // eslint-disable-next-line no-unsafe-optional-chaining
        return (list?.attr === attr ? { ...list, value: filterExistingValue, optionName: '' } : list);
      });
      setDoubleInputs(setNewDoubleInput);
    }
  };

  const handleAddMoreInput = () => {
    if (doubleInputs?.length <= 3) {
      setDoubleInputs([...doubleInputs, {
        name: '',
        value: [],
        attr: `input${Math.random()}`,
        optionName: '',
      }]);
    }
  };

  const handleRemoveInput = () => {
    if (doubleInputs.length > 1) {
      // setInputCounter(inputCounter - 1);
      // remove last input
      setDoubleInputs((prev) => prev.slice(0, prev.length - 1));
    }
  };

  return (

    <div>
      {
        doubleInputs?.map((inputDetails:any, index:number) => (
          <div className="row color_and_color_input_cover">
            <div className={!inputDetails?.name ? 'col-12' : 'col'} key={index}>
              <TextInput
                name={inputDetails?.attr}
                value={inputDetails?.name}
                customOnChange={(e:any) => handleChangeproductOptions(e, inputDetails?.attr)}
                label="Input product preference"
                width="100%"
                marginBottom="0px"
              />

            </div>
            <div className="col ">
              {
                inputDetails?.name ? (
                  <div className="color_input_cover">

                    <input className="input_product_options" placeholder={`Input ${inputDetails?.name} option(s)`} onChange={(e:any) => handleChangeproductOptionList(e, inputDetails?.attr)} style={{ height: inputDetails?.value?.length ? '' : '40px' }} value={inputDetails?.optionName} />
                    {
                      inputDetails?.value?.map((list:any) => <span style={{ marginRight: '3px' }}><StyledButton rightIcon={<CloseButtonIcon color="white" />} marginBottom="2px" name={capitalizeOnlyFirstLetter(list)} fontSize="12px" border="none" backgroundColor="rgba(152, 162, 179, 1)" borderRadius="2px" color="white" onClick={() => handleRemove(inputDetails?.attr, list)} /></span>)
                    }
                  </div>
                ) : ''
              }
            </div>
            {
              inputDetails?.optionName && (
              <div className="col-2">

                <StyledButton name="Add" color="white" backgroundColor="rgba(82, 59, 159, 1)" fontSize="14px" border="none" borderRadius="3px" height="45px" onClick={() => handleAddText(inputDetails?.attr, inputDetails?.optionName)} padding="0px 10px 0px 10px" />
              </div>
              )
            }
          </div>
        ))
      }
      <div className="double_input_add_and_remove">
        <div className="add">
          <span onClick={handleAddMoreInput} style={{ cursor: 'pointer' }}>
            {' '}
            <AddNew />
          </span>
        </div>
        {
          doubleInputs?.length > 1 && (
          <div className="remove">
            <span onClick={handleRemoveInput} style={{ cursor: 'pointer' }}>
              <RemoveOld />
            </span>
          </div>
          )
        }

      </div>
    </div>

  );
};
export default DoubleInputGenerator;
