import React from 'react';

const NotFoundSVG = () => (
  <svg
    width="400"
    height="220"
    viewBox="0 0 149 97"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M39 59C54.464 59 67 45.7924 67 29.5C67 13.2076 54.464 0 39 0C23.536 0 11 13.2076 11 29.5C11 45.7924 23.536 59 39 59Z"
      fill="#F2F2F2"
    />
    <path d="M0 7C0 18.0547 6.26172 27 14 27Z" fill="#2F2E41" />
    <path d="M14 27C14 15.9453 21.6035 7 31 7Z" fill="#1292EE" />
    <path d="M5 8C5 18.502 9.02539 27 14 27Z" fill="#1292EE" />
    <path d="M14 27C14 12.6289 22.498 1 33 1Z" fill="#2F2E41" />
    <path
      d="M11 27.1439C11 27.1439 13.15 27.0787 13.7979 26.6244C14.4459 26.1701 17.1051 25.6276 17.2658 26.3562C17.4266 27.0848 20.4968 29.98 18.0695 29.9993C15.6422 30.0186 12.4296 29.627 11.7829 29.2391C11.1362 28.8513 11 27.1439 11 27.1439Z"
      fill="#A8A8A8"
    />
    <path
      opacity="0.2"
      d="M18.1133 29.7331C15.6859 29.7534 12.473 29.3425 11.8262 28.9354C11.3337 28.6255 11.1374 27.5132 11.0718 27C11.0263 27.002 11 27.0029 11 27.0029C11 27.0029 11.1362 28.7945 11.7829 29.2016C12.4297 29.6086 15.6426 30.0195 18.07 29.9993C18.7707 29.9934 19.0128 29.7359 18.9995 29.3544C18.9021 29.5849 18.6349 29.7288 18.1133 29.7331Z"
      fill="black"
    />
    <path
      d="M26.5 62C40.031 62 51 60.433 51 58.5C51 56.567 40.031 55 26.5 55C12.969 55 2 56.567 2 58.5C2 60.433 12.969 62 26.5 62Z"
      fill="#3F3D56"
    />
    <path
      opacity="0.1"
      d="M26.5 62C37.8218 62 47 60.6569 47 59C47 57.3431 37.8218 56 26.5 56C15.1782 56 6 57.3431 6 59C6 60.6569 15.1782 62 26.5 62Z"
      fill="black"
    />
    <path
      d="M111.5 97C132.211 97 149 94.5376 149 91.5C149 88.4624 132.211 86 111.5 86C90.7893 86 74 88.4624 74 91.5C74 94.5376 90.7893 97 111.5 97Z"
      fill="#3F3D56"
    />
    <path
      d="M41.5 93C53.9264 93 64 91.433 64 89.5C64 87.567 53.9264 86 41.5 86C29.0736 86 19 87.567 19 89.5C19 91.433 29.0736 93 41.5 93Z"
      fill="#3F3D56"
    />
    <path
      d="M62 87.1954C73.8085 90.5675 96.5071 82.7969 99 74"
      stroke="#2F2E41"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M41 35.7388C41 35.7388 58.8465 30.7015 59.9448 46.9329C61.043 63.1642 45.2557 67.7817 57.611 71"
      stroke="#2F2E41"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M24 74.2258C24 74.2258 29.6357 72.6866 29.9826 77.6461C30.3294 82.6057 25.3439 84.0166 29.2456 85"
      stroke="#2F2E41"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M103.866 78.405L102.82 85.1287C102.82 85.1287 97.669 87.883 101.29 87.964C104.912 88.045 121.973 87.964 121.973 87.964C121.973 87.964 125.273 87.964 120.042 85.0477L118.996 78L103.866 78.405Z"
      fill="#2F2E41"
    />
    <path
      opacity="0.1"
      d="M100.561 87.9838C101.383 87.2402 102.819 86.4655 102.819 86.4655L103.865 79.6787L118.995 79.6932L120.042 86.3837C121.262 87.0701 122.017 87.5964 122.459 88C123.131 87.8443 123.866 87.2655 120.042 85.1138L118.995 78L103.865 78.4088L102.819 85.1956C102.819 85.1956 98.4904 87.532 100.561 87.9838Z"
      fill="black"
    />
    <path
      d="M142.609 30H79.3908C78.0704 30 77 31.1073 77 32.4733V76.5267C77 77.8927 78.0704 79 79.3908 79H142.609C143.93 79 145 77.8927 145 76.5267V32.4733C145 31.1073 143.93 30 142.609 30Z"
      fill="#2F2E41"
    />
    <path d="M143 32H79V69H143V32Z" fill="#3F3D56" />
    <path
      d="M145 72V76.4422C145 76.7781 144.938 77.1107 144.818 77.4211C144.698 77.7314 144.522 78.0134 144.3 78.2509C144.078 78.4885 143.814 78.6769 143.524 78.8054C143.234 78.9339 142.923 79 142.609 79H79.3908C79.0769 79 78.766 78.9339 78.4759 78.8054C78.1858 78.6769 77.9222 78.4885 77.7002 78.2509C77.4782 78.0134 77.302 77.7314 77.1819 77.4211C77.0618 77.1107 77 76.7781 77 76.4422V72H145Z"
      fill="#2F2E41"
    />
    <path
      d="M129 92.3478V93H86V92.4782L86.0592 92.3478L87.119 90H128.121L129 92.3478Z"
      fill="#2F2E41"
    />
    <path
      d="M145.976 91.5992C145.891 91.9155 145.574 92.2491 144.855 92.5647C142.278 93.697 137.038 92.2627 137.038 92.2627C137.038 92.2627 133 91.6588 133 90.0736C133.113 90.0054 133.232 89.9436 133.354 89.8887C134.437 89.385 138.03 88.142 144.4 89.9413C144.87 90.0707 145.289 90.3121 145.611 90.6385C145.869 90.9041 146.072 91.2409 145.976 91.5992Z"
      fill="#2F2E41"
    />
    <path
      opacity="0.1"
      d="M145.977 92.0963C142.983 93.3615 140.315 93.4559 137.577 91.358C136.196 90.3005 134.942 90.0388 134 90.0586C135.028 89.4586 138.437 87.9779 144.482 90.1213C144.927 90.2755 145.325 90.5631 145.631 90.9519C145.876 91.2682 146.068 91.6694 145.977 92.0963Z"
      fill="black"
    />
    <path
      d="M142 91C142.552 91 143 90.7761 143 90.5C143 90.2239 142.552 90 142 90C141.448 90 141 90.2239 141 90.5C141 90.7761 141.448 91 142 91Z"
      fill="#F2F2F2"
    />
    <path
      d="M111.5 77C112.328 77 113 76.3284 113 75.5C113 74.6716 112.328 74 111.5 74C110.672 74 110 74.6716 110 75.5C110 76.3284 110.672 77 111.5 77Z"
      fill="#F2F2F2"
    />
    <path d="M42 22H14V56H42V22Z" fill="#2F2E41" />
    <path d="M45 19H12V31H45V19Z" fill="#3F3D56" />
    <path d="M45 33H12V45H45V33Z" fill="#3F3D56" />
    <path d="M45 47H12V59H45V47Z" fill="#3F3D56" />
    <path opacity="0.4" d="M38 21H36V23H38V21Z" fill="#1292EE" />
    <path opacity="0.8" d="M41 21H39V23H41V21Z" fill="#1292EE" />
    <path d="M44 21H42V23H44V21Z" fill="#1292EE" />
    <path opacity="0.4" d="M38 35H36V37H38V35Z" fill="#1292EE" />
    <path opacity="0.8" d="M41 35H39V37H41V35Z" fill="#1292EE" />
    <path d="M44 35H42V37H44V35Z" fill="#1292EE" />
    <path opacity="0.4" d="M38 49H36V51H38V49Z" fill="#1292EE" />
    <path opacity="0.8" d="M41 49H39V51H41V49Z" fill="#1292EE" />
    <path d="M44 49H42V51H44V49Z" fill="#1292EE" />
    <path
      d="M42.5 86C48.299 86 53 81.0751 53 75C53 68.9249 48.299 64 42.5 64C36.701 64 32 68.9249 32 75C32 81.0751 36.701 86 42.5 86Z"
      fill="#2F2E41"
    />
    <path d="M41 83H37V89H41V83Z" fill="#2F2E41" />
    <path d="M47 83H44V89H47V83Z" fill="#2F2E41" />
    <path
      d="M40 90C41.6569 90 43 89.5523 43 89C43 88.4477 41.6569 88 40 88C38.3431 88 37 88.4477 37 89C37 89.5523 38.3431 90 40 90Z"
      fill="#2F2E41"
    />
    <path
      d="M46.5 90C47.8807 90 49 89.5523 49 89C49 88.4477 47.8807 88 46.5 88C45.1193 88 44 88.4477 44 89C44 89.5523 45.1193 90 46.5 90Z"
      fill="#2F2E41"
    />
    <path
      d="M42.5 76C44.433 76 46 74.2091 46 72C46 69.7909 44.433 68 42.5 68C40.567 68 39 69.7909 39 72C39 74.2091 40.567 76 42.5 76Z"
      fill="white"
    />
    <path
      d="M42.5 73C43.3284 73 44 72.5523 44 72C44 71.4477 43.3284 71 42.5 71C41.6716 71 41 71.4477 41 72C41 72.5523 41.6716 73 42.5 73Z"
      fill="#3F3D56"
    />
    <path
      d="M32.1504 64.453C31.3291 60.3635 33.9544 56.2313 38.0143 55.2234C42.0741 54.2155 46.031 56.7136 46.8523 60.803C47.6736 64.8925 44.983 66.4007 40.9232 67.4086C36.8634 68.4166 32.9717 68.5424 32.1504 64.453Z"
      fill="#1292EE"
    />
    <path
      d="M51.9957 72.47C54.6647 71.2788 56.5377 69.605 56.179 68.7313C55.8204 67.8576 53.366 68.1149 50.697 69.3061C48.0279 70.4973 46.155 72.1712 46.5136 73.0448C46.8722 73.9185 49.3266 73.6612 51.9957 72.47Z"
      fill="#2F2E41"
    />
    <path
      d="M32.2994 79.8255C34.9684 78.6343 36.8413 76.9604 36.4827 76.0868C36.1241 75.2131 33.6697 75.4704 31.0007 76.6616C28.3316 77.8527 26.4587 79.5266 26.8173 80.4003C27.1759 81.274 29.6303 81.0166 32.2994 79.8255Z"
      fill="#2F2E41"
    />
    <path
      d="M48 77.7683C48 78.8547 45.4236 81 42.5647 81C39.7058 81 37 78.9953 37 77.9089C37 76.8225 39.7058 77.0658 42.5647 77.0658C45.4236 77.0658 48 76.6819 48 77.7683Z"
      fill="white"
    />
    <path
      d="M5.32134 87.0146C8.26024 87.0146 10.6427 84.7793 10.6427 82.0219C10.6427 79.2646 8.26024 77.0293 5.32134 77.0293C2.38245 77.0293 0 79.2646 0 82.0219C0 84.7793 2.38245 87.0146 5.32134 87.0146Z"
      fill="white"
      fillOpacity="0.5"
    />
    <path
      d="M18.6246 87.0146C21.5635 87.0146 23.946 84.7793 23.946 82.0219C23.946 79.2646 21.5635 77.0293 18.6246 77.0293C15.6857 77.0293 13.3032 79.2646 13.3032 82.0219C13.3032 84.7793 15.6857 87.0146 18.6246 87.0146Z"
      fill="white"
      fillOpacity="0.5"
    />
    <path
      d="M31.9288 87.0146C34.8677 87.0146 37.2501 84.7793 37.2501 82.0219C37.2501 79.2646 34.8677 77.0293 31.9288 77.0293C28.9899 77.0293 26.6074 79.2646 26.6074 82.0219C26.6074 84.7793 28.9899 87.0146 31.9288 87.0146Z"
      fill="white"
      fillOpacity="0.5"
    />
  </svg>
);

export default NotFoundSVG;
