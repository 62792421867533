import React, { useEffect } from 'react';
import FilterRemoveSelectedIcon from '../../svgs/FilterRemoveSelectedIcon';
import FilterSelectDropIcon from '../../svgs/FilterSelectDropIcon';
import FilterSelectTickIcon from '../../svgs/FilterSelectTickIcon';

type Props = {
  options: any;
  name?: string;
  label: string;
  selectedOptions: any;
  onChange: any;
  placeholder?: string;
};

const SelectComp = ({
  label, onChange, placeholder, options, selectedOptions,
}: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isOpen, setIsOpen] = React.useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleSelect = (selected: any) => {
    let newSelectedOptions = [];
    if (selectedOptions?.includes(selected)) {
      newSelectedOptions = selectedOptions.filter((option) => option !== selected);
    } else {
      newSelectedOptions = [...selectedOptions, selected];
    }
    onChange(newSelectedOptions);
  };
  const removeSelected = (selected: any, e) => {
    e?.persist?.();
    e?.stopPropagation?.();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const newSelectedOptions = selectedOptions.filter((option) => option !== selected);
    onChange(newSelectedOptions);
  };
  const getLabelByValue = (rec) => options?.find((option: any) => option.value === rec)?.label;

  const dropdownRef = React.useRef(null);
  const handleDropdown = (e) => {
    if (dropdownRef?.current?.contains(e.target)) {
      return;
    }
    setIsOpen(false);
  };

  useEffect(() => {
    window.addEventListener('click', handleDropdown);
    return () => {
      window.removeEventListener('click', handleDropdown);
    };
  }, []);
  return (
    <div className="filter-select-comp">
      <strong className="filter-input-label">{label}</strong>
      <div ref={dropdownRef}>
        <div className="filter-select-box">
          {selectedOptions?.length
            ? (
              <div onClick={() => setIsOpen(true)} className="filter-selected-chips">
                {selectedOptions?.map?.((selected, i) => (
                  <div className="select-chip" key={i}>
                    <span className="select-chip-text">{getLabelByValue(selected)}</span>
                    <span className="chip-close" onClick={(e) => removeSelected(selected, e)}>
                      <FilterRemoveSelectedIcon />
                    </span>
                  </div>
                ))}
              </div>
            )
            : <span onClick={() => setIsOpen(true)} className="filter-input-placeholder flex-grow">{placeholder || 'Select'}</span>}
          <div onClick={() => setIsOpen((prev) => !prev)}><FilterSelectDropIcon /></div>
        </div>
        <div className={`filter-select-dropdown ${isOpen ? '' : 'filter-select-hidden'}`}>
          <ul>
            {options.map(({ label, value }: any, i: number) => (
              <li key={i} onClick={() => handleSelect(value)} className={selectedOptions?.includes(value) ? 'selected' : ''}>
                <span>{label}</span>
                {selectedOptions?.includes(value) && <FilterSelectTickIcon />}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SelectComp;
