import React from 'react';
import EmptyIllustration from '../../svgs/EmptyIllustration';
import PlusIcon from '../../svgs/PlusIcon';
import Buttons from '../Buttons';
import './EmptyPaymentLinks.scss';

const EmptyPaymentLinks = ({ addNew }: {addNew: any}) => (
  <div className="empty-payment-link">
    <div className="empty-payment-link-icon">
      <EmptyIllustration />
    </div>
    <h6>Start by creating a payment link</h6>
    <span className="description">
      A payment link is an easier way to receive
      payments from your customers by
      sharing personalized link with them.
    </span>
    <div className="twin-buttons-cover">
      <Buttons
        name="Cancel"
        className="cancel-type"
        onClick={() => {}}
      />
      <Buttons
        name="Create payment Link"
        className="save-type"
        type="submit"
        btnStyle="btn-md"
        Icon={PlusIcon}
        onClick={addNew}
      />
    </div>
  </div>
);

export default EmptyPaymentLinks;
