import React from 'react';

interface IRoundDeleteIcon{
  onClick?:any
}
const RoundDeleteIcon = ({ onClick }:IRoundDeleteIcon) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={onClick}>
    <circle opacity="0.5" cx="10" cy="10" r="10" fill="#0C0C0C" />
    <path d="M13.6624 8.73413C13.6624 8.73413 13.3909 12.1016 13.2334 13.5201C13.1584 14.1976 12.7399 14.5946 12.0544 14.6071C10.7499 14.6306 9.44389 14.6321 8.13989 14.6046C7.48039 14.5911 7.06889 14.1891 6.99539 13.5236C6.83689 12.0926 6.56689 8.73413 6.56689 8.73413" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M14.354 7.11987H5.875" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12.7203 7.11987C12.3278 7.11987 11.9898 6.84237 11.9128 6.45787L11.7913 5.84987C11.7163 5.56937 11.4623 5.37537 11.1728 5.37537H9.05629C8.76679 5.37537 8.51279 5.56937 8.43779 5.84987L8.31629 6.45787C8.23929 6.84237 7.90129 7.11987 7.50879 7.11987" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
  </svg>

);
export default RoundDeleteIcon;
