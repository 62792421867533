import { FunctionComponent } from 'react';

import DashboardWrap from '../../components/DashboardWrap';

import GenPaymentLink from './GenPaymentLink';
import SdkInstore from './SdkInstore';
import './styles.scss';

const InStore: FunctionComponent = () => (
  <DashboardWrap>
    <SdkInstore />
    <GenPaymentLink />
  </DashboardWrap>
);

export default InStore;
