import React from 'react';

const AppBarNotificationIcon = () => (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M12 18.8476C17.6392 18.8476 20.2481 18.1242 20.5 15.2205C20.5 12.3188 18.6812 12.5054 18.6812 8.94511C18.6812 6.16414 16.0452 3 12 3C7.95477 3 5.31885 6.16414 5.31885 8.94511C5.31885 12.5054 3.5 12.3188 3.5 15.2205C3.75295 18.1352 6.36177 18.8476 12 18.8476Z" stroke="#667085" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M14.3889 21.8574C13.0247 23.3721 10.8967 23.3901 9.51953 21.8574" stroke="#667085" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
    <circle cx="18" cy="4" r="3.5" fill="#D92D20" stroke="white" />
  </svg>

);
export default AppBarNotificationIcon;
