import React from 'react';
import ClockIcon from '../../../svgs/ClockIcon';
import StyledButton from '../../StyledButton';
import './ComingsoonCont.scss';

interface IComingsoonCont{
  onClick?:any;
}
const ComingsoonCont = ({ onClick }:IComingsoonCont) => (
  <div className="coming-soon-cont-cover">
    <div className="clock-icon-cover"><ClockIcon /></div>
    <div className="coming-soon-title">Coming Soon</div>
    <div className="coming-soon-subtitle">
      This feature is unavailable as it is currently in development.
    </div>
    <div style={{ marginBottom: '32px' }}>
      <StyledButton name="Okay" width="100%" border="none" fontSize="12px" backgroundColor="#523B9F" color="white" height="31px" borderRadius="4px" onClick={onClick} />
    </div>

  </div>
);
export default ComingsoonCont;
