import React from 'react';

const PayfiLogo = () => (
  <svg width="126" height="40" viewBox="0 0 103 38" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M37.8346 18.8433C37.8346 29.2502 29.3651 37.6867 18.9173 37.6867C8.46957 37.6867 0 29.2502 0 18.8433C0 8.43645 8.46957 0 18.9173 0C29.3651 0 37.8346 8.43645 37.8346 18.8433Z" fill="#5941A9" />
    <path fillRule="evenodd" clipRule="evenodd" d="M18.1146 10.3689C18.9705 9.96055 19.9301 9.73187 20.9407 9.73187C24.559 9.73187 27.4925 12.6556 27.4925 16.2618C27.4925 19.0857 25.6934 21.4904 23.175 22.4014C22.4776 22.6519 21.7256 22.7898 20.9407 22.7898C19.9301 22.7898 18.9705 22.5611 18.1146 22.1528L18.1146 28.5502H14.3908L14.3908 18.9717L10.2969 14.8507L12.9508 12.1792L14.4148 13.6529L14.7842 14.0248C15.3737 12.416 16.5792 11.1024 18.1146 10.3689ZM19.2727 18.5122C19.7389 18.857 20.3161 19.0603 20.9407 19.0603C22.4904 19.0603 23.7486 17.8062 23.7486 16.2618C23.7486 14.7155 22.4904 13.4632 20.9407 13.4632C19.3893 13.4632 18.1328 14.7155 18.1328 16.2618C18.1328 16.8843 18.3368 17.4578 18.6809 17.9224C18.8448 18.1438 19.0397 18.3398 19.26 18.5031H19.2618L19.2691 18.5086L19.2727 18.5122Z" fill="white" />
    <g clipPath="url(#clip0_881_9384)">
      <path d="M54.8467 18.8005C54.8467 20.3967 53.546 21.6909 51.9419 21.6909C51.2934 21.6909 50.694 21.479 50.2095 21.1226L50.202 21.117C49.9796 20.95 49.7797 20.7531 49.612 20.5299L49.6063 20.5224C49.2482 20.0422 49.0352 19.4457 49.0352 18.8005C49.0352 17.2024 50.3358 15.9082 51.9419 15.9082C53.546 15.91 54.8467 17.2043 54.8467 18.8005Z" stroke="#E6E7E8" strokeWidth="0.5" strokeMiterlimit="10" />
      <path d="M54.8467 18.8005C54.8467 20.3967 53.546 21.6909 51.9419 21.6909C51.2934 21.6909 50.694 21.479 50.2095 21.1226L50.202 21.117C49.9796 20.95 49.7797 20.7531 49.612 20.5299L49.6063 20.5224C49.2482 20.0422 49.0352 19.4457 49.0352 18.8005C49.0352 17.2024 50.3358 15.9082 51.9419 15.9082C53.546 15.91 54.8467 17.2043 54.8467 18.8005Z" stroke="#E6E7E8" strokeWidth="0.5" strokeMiterlimit="10" />
      <path d="M50.2033 21.1188C49.9809 20.9519 49.781 20.7549 49.6133 20.5317L50.2033 21.1188Z" stroke="#E6E7E8" strokeWidth="0.5" strokeMiterlimit="10" />
      <path d="M51.9406 12.0536C50.8944 12.0536 49.901 12.2899 49.015 12.712C47.4222 13.4716 46.1724 14.8334 45.5636 16.5009C45.3034 17.2193 45.1602 17.9939 45.1602 18.8023V31.5027H49.015V24.8909C49.901 25.3129 50.8944 25.5492 51.9406 25.5492C52.753 25.5492 53.5316 25.4067 54.2535 25.1478C56.8605 24.2062 58.7229 21.7209 58.7229 18.8023C58.7229 15.0735 55.6862 12.0536 51.9406 12.0536ZM51.9425 21.6928C51.294 21.6928 50.6946 21.4808 50.2101 21.1245L50.2026 21.1188C49.9802 20.9519 49.7804 20.7549 49.6126 20.5317L49.6069 20.5242C49.2488 20.0441 49.0358 19.4476 49.0358 18.8023C49.0358 17.2042 50.3364 15.91 51.9425 15.91C53.5466 15.91 54.8473 17.2042 54.8473 18.8023C54.8473 20.3967 53.5466 21.6928 51.9425 21.6928Z" fill="#5941A9" />
      <path d="M69.5192 12.7045C68.6389 12.2862 67.653 12.0536 66.6125 12.0536C65.5663 12.0536 64.5729 12.2899 63.6869 12.712C62.0941 13.4716 60.8443 14.8334 60.2354 16.5009C59.9753 17.2193 59.832 17.9939 59.832 18.8023C59.832 21.4865 61.406 23.8048 63.6869 24.8909C64.5729 25.3129 65.5663 25.5492 66.6125 25.5492C67.4249 25.5492 68.2034 25.4067 68.9254 25.1478C69.129 25.0747 69.3269 24.9921 69.5192 24.8984C71.6568 23.8892 73.1761 21.8035 73.3741 19.3444V18.264C73.178 15.8012 71.6568 13.7117 69.5192 12.7045ZM66.6125 21.6928C65.0064 21.6928 63.7058 20.3986 63.7058 18.8023C63.7058 17.2042 65.0064 15.91 66.6125 15.91C68.2185 15.91 69.5192 17.2042 69.5192 18.8023C69.5192 20.3967 68.2185 21.6928 66.6125 21.6928Z" fill="#5941A9" />
      <path d="M69.5205 18.8005C69.5205 20.3967 68.2198 21.6909 66.6137 21.6909C65.0077 21.6909 63.707 20.3967 63.707 18.8005C63.707 17.2024 65.0077 15.9082 66.6137 15.9082C68.2198 15.9082 69.5205 17.2043 69.5205 18.8005Z" stroke="#E6E7E8" strokeWidth="0.5" strokeMiterlimit="10" />
      <path d="M73.3744 19.3425V26.5039H69.5195V24.8965C71.6572 23.8873 73.1765 21.8016 73.3744 19.3425Z" fill="#5941A9" />
      <path d="M88.3858 12.0555L84.3575 21.0344L82.2463 25.7387L79.66 31.5027H75.4395L80.1351 21.0344L82.2463 16.3283L84.1634 12.0555H88.3858Z" fill="#5941A9" stroke="#E6E7E8" strokeWidth="0.5" strokeMiterlimit="10" />
      <path d="M81.9129 20.9369L79.8016 25.643L77.6904 20.9369L73.6621 11.9598H77.8846L79.8016 16.2327L81.9129 20.9369Z" fill="#5941A9" />
      <path d="M96.7478 6.33987C96.0247 6.20507 95.2789 6.13766 94.5105 6.13766C93.3806 6.13766 92.4766 6.29494 91.3015 6.94649C90.1941 7.5531 89.4258 8.96854 89.4258 11.0131V11.5073L89.4258 11.679L89.4258 14.0859V14.9898V25.5045H93.7422V14.9898H96.7478V11.5073H93.7422V10.9906C93.7422 10.6311 93.7422 10.3166 94.2846 9.91217C94.8495 9.50776 95.8891 9.53023 96.7478 9.8223H96.7704V6.33987H96.7478ZM98.7139 11.5073V25.5045H102.985V11.5073H98.7139Z" fill="#5941A9" />
      <path d="M100.756 10.3916C101.933 10.3916 102.888 9.43936 102.888 8.26466C102.888 7.08995 101.933 6.13766 100.756 6.13766C99.5792 6.13766 98.625 7.08995 98.625 8.26466C98.625 9.43936 99.5792 10.3916 100.756 10.3916Z" fill="#5941A9" />
    </g>
    <defs>
      <clipPath id="clip0_881_9384">
        <rect width="57.8827" height="25.4119" fill="white" transform="translate(45.1172 6.13766)" />
      </clipPath>
    </defs>
  </svg>

);

export default PayfiLogo;
