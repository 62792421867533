import React from 'react';

interface INewPlusIcon{
    color?:string;
    height?:string;
    width?:string;
}
const NewPlusIcon = ({ color, height, width }:INewPlusIcon) => (
  <svg width={width || '10'} height={height || '10'} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9 4.99992H1" stroke={color || '#523B9F'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M5.00187 9V1" stroke={color || '#523B9F'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>

);

export default NewPlusIcon;
