import React from 'react';

interface IEmailIcon{
  width?:string;
  height?:string;
  color?:string;
}
const EmailIcon = ({ width, height, color }:IEmailIcon) => (
  <svg className="email-user-icon" width={width || '16'} height={height || '16'} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.9352 5.90076L8.97304 8.30945C8.41337 8.75345 7.62595 8.75345 7.06629 8.30945L4.0791 5.90076" stroke={color || '#98A2B3'} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
    <path fillRule="evenodd" clipRule="evenodd" d="M11.2723 14C13.2998 14.0056 14.6663 12.3397 14.6663 10.2922V5.71334C14.6663 3.66588 13.2998 2 11.2723 2H4.7271C2.69953 2 1.33301 3.66588 1.33301 5.71334V10.2922C1.33301 12.3397 2.69953 14.0056 4.7271 14H11.2723Z" stroke={color || '#98A2B3'} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>

);
export default EmailIcon;
