/* eslint-disable max-len */
import React, { useEffect, useMemo, useRef } from 'react';
import FilterInputs from '.';
import useGetQueryParams from '../../customHooks/useGetQueryParams';
import CloseIcon from '../../svgs/CloseIcon';
// import ExportIcon from '../../../svgs/ExportIcon';
import FilterIcon from '../../svgs/FilterIcon';
import { cleanColumnArray } from '../../utils/helpers';
import Buttons from '../Buttons';
import UseCustomResizer from '../CustomResizer';
import ExportPDF from '../ExportPDF';
import FilterTableColumn from './FilterTableColumn';

interface Props {
  filterObj?: any;
  exportObj?: any;
  hideExportButton?: boolean;
  columnObject?:any;
  hideFilterButton?:boolean;
  disableExport?:boolean
  disbleFilter?:boolean
  hasAlternativeDate?:boolean
}

const NewTableFilters = (props: Props) => {
  const [showTableFilterInputs, setShowTableFilterInputs] = React.useState(false);
  const { search, getFilterQueries } = useGetQueryParams();
  const filtersRef = useRef<HTMLDivElement>(null);
  const handleClick = (e) => {
    if (filtersRef?.current?.contains(e.target) || e?.target?.className?.includes?.('react-datepicker')) {
      return;
    }
    setShowTableFilterInputs(false);
  };

  useEffect(() => {
    window.addEventListener('click', handleClick);
    return () => {
      window.removeEventListener('click', handleClick);
    };
  }, []);

  const filtersNumber = useMemo(() => {
    const result = getFilterQueries();
    let filterCount = 0;
    if (result?.min && result?.max) {
      filterCount += 1;
    }
    if (result?.minCharge && result?.maxCharge) {
      filterCount += 1;
    }
    if (result?.from && result?.to && !props?.hasAlternativeDate) {
      filterCount += 1;
    }
    if (result?.fromDate && result?.toDate && props?.hasAlternativeDate) {
      filterCount += 1;
    }
    if (result?.status?.length) {
      filterCount += 1;
    }
    if (result?.tenor?.length) {
      filterCount += 1;
    }
    if (result?.product?.length) {
      filterCount += 1;
    }
    if (result?.partner?.length) {
      filterCount += 1;
    }
    if (result?.channel?.length) {
      filterCount += 1;
    }
    if (result?.stage?.length) {
      filterCount += 1;
    }
    if (result?.paymentType?.length) {
      filterCount += 1;
    }
    if (result?.paymentMethod?.length) {
      filterCount += 1;
    }
    if (result?.groupUsers?.length) {
      filterCount += 1;
    }
    return filterCount;
  }, [search]);

  const HasFilterComp = () => <div className="has-filter"><small>{filtersNumber}</small></div>;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { isMobile } = UseCustomResizer({ width: 654 });

  return (
    <div className="d-flex">
      {!props.hideFilterButton && props?.filterObj && (
        <div ref={filtersRef} className="button-filter-cover">
          <Buttons
            name="Filter"
            className="btn-md bg-white text-grey"
            Icon={showTableFilterInputs ? CloseIcon : FilterIcon}
            onClick={() => {
              if (props?.disbleFilter) return;
              setShowTableFilterInputs((prev) => !prev);
            }}
            EndIcon={filtersNumber ? HasFilterComp : undefined}
            optional
            disabled={props?.disbleFilter}
          />
          <FilterInputs
            className={`${showTableFilterInputs ? '' : 'filters-hidden'}`}
            filterObj={props?.filterObj}
            onClose={() => setShowTableFilterInputs(false)}
          />
        </div>
      )}
      {!props.hideExportButton && props?.exportObj && <ExportPDF exportObj={props?.exportObj} disableExport={props?.disableExport} />}
      {
        props?.columnObject && !isMobile && (
        <FilterTableColumn
          applyChanges={props?.columnObject?.applyChanges}
          selectedColumns={cleanColumnArray(props?.columnObject?.selectedColumns)}
          allColumns={cleanColumnArray(props?.columnObject?.allColumns)}
          formatLabels={props?.columnObject?.formatLabels}
        />
        )
      }

    </div>
  );
};

export default NewTableFilters;
