/* eslint-disable no-undef */
import React from 'react';

interface INewShareLinkIcon{
  width?:any;
  height?:any;
}
const NewShareLinkIcon = ({ width, height }:INewShareLinkIcon): JSX.Element => (
  <svg width={width || '12'} height={height || '13'} viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_6090_32693)">
      <path d="M9 8.54C8.62 8.54 8.28 8.69 8.02 8.925L4.455 6.85C4.48 6.735 4.5 6.62 4.5 6.5C4.5 6.38 4.48 6.265 4.455 6.15L7.98 4.095C8.25 4.345 8.605 4.5 9 4.5C9.83 4.5 10.5 3.83 10.5 3C10.5 2.17 9.83 1.5 9 1.5C8.17 1.5 7.5 2.17 7.5 3C7.5 3.12 7.52 3.235 7.545 3.35L4.02 5.405C3.75 5.155 3.395 5 3 5C2.17 5 1.5 5.67 1.5 6.5C1.5 7.33 2.17 8 3 8C3.395 8 3.75 7.845 4.02 7.595L7.58 9.675C7.555 9.78 7.54 9.89 7.54 10C7.54 10.805 8.195 11.46 9 11.46C9.805 11.46 10.46 10.805 10.46 10C10.46 9.195 9.805 8.54 9 8.54ZM9 2.5C9.275 2.5 9.5 2.725 9.5 3C9.5 3.275 9.275 3.5 9 3.5C8.725 3.5 8.5 3.275 8.5 3C8.5 2.725 8.725 2.5 9 2.5ZM3 7C2.725 7 2.5 6.775 2.5 6.5C2.5 6.225 2.725 6 3 6C3.275 6 3.5 6.225 3.5 6.5C3.5 6.775 3.275 7 3 7ZM9 10.51C8.725 10.51 8.5 10.285 8.5 10.01C8.5 9.735 8.725 9.51 9 9.51C9.275 9.51 9.5 9.735 9.5 10.01C9.5 10.285 9.275 10.51 9 10.51Z" fill="#101828" />
    </g>
    <defs>
      <clipPath id="clip0_6090_32693">
        <rect width="12" height="12" fill="white" transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </svg>

);
export default NewShareLinkIcon;
