import React from 'react';

interface IGoBackIcon{
  color?:string;
  onClick:any
}
const GoBackIcon = ({ color, onClick }:IGoBackIcon) => (
  <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={onClick} style={{ cursor: 'pointer' }}>
    <path fillRule="evenodd" clipRule="evenodd" d="M5.10786 2.97561C5.2972 3.16317 5.29851 3.46722 5.10656 3.65477L2.26839 6.43416L2.21825 6.47698C2.02641 6.61966 1.7522 6.60475 1.57804 6.43224C1.4837 6.33878 1.43555 6.21588 1.43555 6.09361C1.43555 5.97071 1.4837 5.84717 1.57934 5.75371L4.41751 2.97369L4.46765 2.93087C4.65949 2.78819 4.9337 2.8031 5.10786 2.97561ZM5.15033 11.3973C5.29679 11.5855 5.28123 11.8553 5.10767 12.0273C4.91768 12.2148 4.60861 12.2155 4.41732 12.0279L0.143796 7.84155L0.0999333 7.7925C0.0334366 7.70711 0 7.6046 0 7.50165C0 7.37939 0.0481487 7.25649 0.142494 7.16303C0.332486 6.97484 0.641549 6.9742 0.832843 7.16175L5.10637 11.3481L5.15033 11.3973Z" fill={color || '#5941A9'} />
  </svg>

);
export default GoBackIcon;
