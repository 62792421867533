import moment from 'moment';
import { formatCash } from './tableHelpers';

const formatBodyTag = (content: any, key: string) => {
  const cashArray = [
    'amount',
    'outstandingamount',
    'price',
    'initialprice',
    'total',
    'trxAmount',
    'fees',
    'commission',
  ];
  if (cashArray.includes(key)) return formatCash(content[key], 'N');
  switch (key?.toLowerCase()) {
  case 'status':
    return ['active', 'success', 'completed'].includes(
      content?.[key]?.toLowerCase?.(),
    )
      ? 'Active'
      : content?.[key]?.toLowerCase?.() || 'Inactive';
  case 'tenor':
    return `${content[key]} Months`;
  case 'interest':
    return `${content[key]} %`;
  case 'weight':
    return `${content[key]}Kg`;
  case 'createdat':
    return moment(new Date(content[key])).format('YYYY/MM/DD') || 'N/A';
  case 'duedate':
    return moment(new Date(content[key])).format('YYYY/MM/DD') || 'N/A';
  case 'startdate':
    return moment(new Date(content[key])).format('YYYY/MM/DD') || 'N/A';
  case 'scheduleddate':
    return moment(new Date(content[key])).format('YYYY/MM/DD') || 'N/A';
  case 'deliverydate':
    return moment(new Date(content[key])).format('YYYY/MM/DD') || 'N/A';
  case 'orderdate':
    return moment(new Date(content[key])).format('YYYY/MM/DD') || 'N/A';
  case 'enddate':
    return new Date(content[key])?.toString()?.toLowerCase()
        !== 'invalid date'
      ? moment(new Date(content[key])).format('YYYY-MM-DD')
      : 'till-date';
  case 'isactive':
    return content[key] ? 'Active' : 'Inactive';
  case 'statusname':
    return content[key]?.toLowerCase?.();
  case 'quantity':
    return content[key] ? content[key] : 'Out of stock';
  case 'isapproved':
    return ['active', 'success', 'completed'].includes(
      content[key]?.toLowerCase?.(),
    )
      ? 'Active'
      : 'Inactive';
  default:
    return content[key];
  }
};

export default formatBodyTag;
