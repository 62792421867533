import React, { useEffect, useRef, useState } from 'react';
import HideIcon from '../../../svgs/HideIcon';
import RoundedCancleIcon from '../../ModalFormComp/RoundedCancleIcon';
import StyledButton from '../../StyledButton';
import './FilterTableColumn.scss';
import NameAndSwitch from './NameAndSwitch';

interface IFilterTableColumn{
  selectedColumns?:string [];
  allColumns?: string [];
  applyChanges?:any;
  formatLabels?:any;
}
const getName = (name) => (Array.isArray(name) ? name[0] : name);
// eslint-disable-next-line max-len
const FilterTableColumn = ({
  selectedColumns, allColumns, formatLabels, applyChanges,
}:IFilterTableColumn) => {
  const [showToggleDropdown, setShowToggleDropdown] = React.useState(false);
  const toggleColumnRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    window.addEventListener('click', handleClick);
    return () => {
      window.removeEventListener('click', handleClick);
    };
  }, []);
  const handleClick = (e) => {
    if (toggleColumnRef?.current?.contains(e.target)) {
      return;
    }
    setShowToggleDropdown(false);
  };
  const handleFilterStateChange = () => {
    setShowToggleDropdown((prev) => !prev);
  };

  const [localSelected, setLocalSelected]:any = useState(selectedColumns);
  const allSelected = localSelected?.length === allColumns?.length;

  const isSelected = (name) => {
    // check if the name exists in the selected column array
    for (let i = 0; i < localSelected.length; i++) {
      const each = localSelected[i];
      if (getName(each) === name) {
        return true;
      }
    }
    return false;
  };

  const handleSelectChange = (name) => {
    setLocalSelected((prev) => {
      let newArr = [];
      if (localSelected.includes(name)) {
        newArr = prev.filter((item) => item !== name);
      } else {
        newArr = [...prev, name];
      }
      const cleaned: any = new Set(newArr);
      return [...cleaned];
    });
  };

  const handleSelectAll = () => {
    if (allSelected) {
      setLocalSelected([]);
    } else {
      setLocalSelected(allColumns);
    }
  };

  return (
    <div className="filter-column-cover" ref={toggleColumnRef}>
      <StyledButton icon={<HideIcon />} name="Hide Columns" height="40px" border="1px solid #F2F4F7" borderRadius="4px" backgroundColor="white" color="#475467" onClick={handleFilterStateChange} marginLeft="12px" fontSize="13px" />
      <div className={`filter-column-cover-body ${showToggleDropdown ? '' : 'filter-body-hidden'}`}>
        <div className="filter-column-header">
          <div className="filter-column-header-title">Select Columns</div>
          <span className="filter-column-header-cancle">
            <RoundedCancleIcon border="1px solid #EAECF0" closeModal={() => setShowToggleDropdown((prev) => !prev)} />
          </span>
        </div>
        <div className="list-filter-contents">
          <NameAndSwitch
            name="select"
            label="Select All"
            onClick={handleSelectAll}
            checked={allSelected}
          />
          { allColumns?.map((name, index) => (
            <NameAndSwitch
              key={`${index}-${name}`}
              name={name}
              label={formatLabels[name] || name}
              onClick={handleSelectChange}
              checked={isSelected(name)}
            />
          ))}

        </div>
        <div className="filter-column-footer">
          <span onClick={() => setShowToggleDropdown(false)}>
            <StyledButton name="Apply Changes" height="40px" width="100%" borderRadius="4px" backgroundColor="#523B9F" color="white" fontSize="13px" border="1px solid #523B9F" onClick={() => applyChanges(localSelected)} />
          </span>
        </div>
      </div>
    </div>
  );
};
export default FilterTableColumn;
