import React from 'react';
import './SomethingWrong.scss';
import NotFoundSVG from './NotFoundSVG';

const SomethingWrong = () => (
  <div className="notfound">
    <div className="not-found-wrapper">
      <h1>
        500
      </h1>
      <h3>
        Oops!. Something went wrong.
      </h3>
      <h4>
        Click to refresh the page.
      </h4>
      <button type="button" onClick={() => window.location.reload()}>
        Refresh
      </button>

    </div>
    <NotFoundSVG />
  </div>
);

export default SomethingWrong;
