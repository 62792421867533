/* eslint-disable react/jsx-props-no-multi-spaces */
import {
  FunctionComponent, useEffect, useState,
} from 'react';
import './PaymentLinks.scss';
import { useHistory } from 'react-router-dom';
import DashboardWrap from '../../components/DashboardWrap';
import { GET_PAYMENT_LINKS } from '../../graphql/queries';
// import Table from '../../components/Table';
import useCustomQuery from '../../network/useCustomQuery';
import useGetQueryParams from '../../customHooks/useGetQueryParams';
import Buttons from '../../components/Buttons';
import PlusIcon from '../../svgs/PlusIcon';
// import AddPaymentLinks from './AddNewLink';
import { cleanUpPaymentLinks } from '../../utils/helpers';
import EmptyPaymentLinks from '../../components/EmptyPaymentLinks';
import PaymentLinkSmallModal from '../../components/PaymentLinkSmallModal';
import DashboardHeader from '../../components/DashboardHeader';
import EmptyRecord from '../../components/EmptyRecord';
import { useGlobalState } from '../../contexts';
import StyledButton from '../../components/StyledButton';
import useFilterMethods from '../../customHooks/useFilterMethods';
import useToast from '../../customHooks/useToast';
import generateCSV from '../../utils/generateCSV';
import generatePDF from '../../utils/generatePdf';
import HorizontalListIcon from '../../svgs/HorizontalListIcon';
import EyeIcon from '../../svgs/EyeIcon';
import NewShareLinkIcon from '../../svgs/NewShareLinkIcon';
import DropdownEditIcon from '../../svgs/DropdownEditIcon';
// import CopyIcon from '../../svgs/CopyIcon';
import AdminTable from '../../components/AdminTable';
import useTableColumn from '../../customHooks/useTableColumn';
import DeactivateRolesIcon from '../../svgs/DeactivateRolesIcon';
import RefreshIcon from '../../svgs/RefreshIcon';
import ActivateType from '../../components/PaymentLinkSmallModal/ActivateType';
import DeactivateTypeAndComment from '../../components/PaymentLinkSmallModal/DeativateTypeAndComment';

const limit = 14;

// interface IMerchantTableRecord{

//   hasNameAndImage?:boolean;

//   }

const PaymentLinks: FunctionComponent = () => {
  const [getPaymentLinks, loading, rawData] = useCustomQuery({
    query: GET_PAYMENT_LINKS,
    onError: () => {},
    onData: () => {},
  });

  const Toast = useToast();
  const { profile }:any = useGlobalState();
  // const [payload, setPayload] = useState({});
  // const [showSuccess, setShowSuccess] = useState(false);
  const [disableCreateLinkBtn, setDisableCreateLinkBtn] = useState(true);
  // const triggerSuccessModal = (res: any) => {
  //   setPayload(res);
  //   setShowSuccess(true);
  // };
  const [data, setData] = useState(null);
  const { getQuery, getFilterQueries } = useGetQueryParams();
  const page = Number(getQuery('page'));
  const search = getQuery('search') || '';
  const { push } = useHistory();
  const setPage = (pageNum) => {
    push(`/payment-links?page=${pageNum}&search=${search}`);
  };
  if (!page) {
    push(`/payment-links?page=1&search=${search}`);
  }
  const allColumns = [
    ['image', 'title', 'link', 'hasBottomValue'],
    'type',
    'amount',
    ['date', 'time'],
    'status',
  ];
  const columnLabelFormat = {
    // txRef: 'Reference',
    title: 'Product Name',
    amount: 'Amount',
    date: 'Create On',
    type: 'Product Type',
  };

  const [actionDetails, setActionDetails] = useState(null);
  const [showSuccess, setShowSuccess] = useState<boolean | string>(false);
  const [showActivate, setShowActivate] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const [showDeactivateAndComment, setShowDeactivateAndComment] = useState(false);
  const { getColumns } = useTableColumn(allColumns, 'payment_link_toggle_storage');

  const dropdownData = [
    {
      id: 1, title: 'View', icon: <EyeIcon width="12px" height="12px" />, color: '#101828',
    },
    {
      id: 2, title: 'Share', icon: <NewShareLinkIcon />, color: '#101828',
    },
    {
      id: 3, title: 'Edit', icon: <DropdownEditIcon />, color: '#101828',
    },
    // {
    //   id: 4, title: 'Copy Link', icon: <CopyIcon />, color: '#101828',
    // },
  ];

  const getPaymentLinkApi = (searchInp: string = '') => {
    getPaymentLinks({
      variables: {
        offset: (page - 1) * limit,
        limit,
        search: searchInp,
      },
    });
  };

  useEffect(() => {
    if (page) {
      getPaymentLinkApi(search);
    }
  }, [page, search]);

  useEffect(() => {
    if (rawData?.getMerchantPaymentLinks?.paymentLinks) {
      setData(rawData);
    }
  }, [rawData]);

  const onSearchChange = (e) => {
    push(`/payment-links?page=1&search=${encodeURIComponent(e.target.value)}`);
  };

  // const [showModal, setShowModal] = useState(false);
  useEffect(() => {
    if (profile?.merchant?.isApproved) {
      setDisableCreateLinkBtn(false);
    } else {
      setDisableCreateLinkBtn(true);
    }
  }, [profile]);
  // const showCreatePaymentLink = () => {
  //   setShowModal(true);
  // };
  // !disableCreateLinkBtn && showCreatePaymentLink
  const AddPaymentLinkButton = () => (
    <div className="flexed-row">
      <Buttons
        onClick={() => push('/create-payment-link')}
        name="Create Payment Link"
        className="btn-md bg-primary text-white ml-2"
        Icon={PlusIcon}
        optional="filter-button"
        hasOptionalColor="#523B9F"
        disabled={disableCreateLinkBtn}
      />
    </div>
  );
  const onFilterSubmit = (result) => {
    getPaymentLinks({
      variables: {
        offset: 0,
        limit,
        cursor: search,
        filters: {
          from: result.from,
          to: result.to,
          paymentStatus: result.status,
          minAmount: result.min ? Number(result.min) : 0,
          maxAmount: result.max ? Number(result.max) : 0,
        },
      },
    });
  };
  const {
    filterObj,
  } = useFilterMethods({
    allowedFilters: ['dateRange', 'status', 'amountRange'],
    defaultUrl: '/payment-links?page=1',
    cb: onFilterSubmit,
    Toast,
    customFilterInformation: {
      status: {
        label: 'Status',
        options: [
          {
            label: 'Active',
            value: true,
          },
          {
            label: 'Deactivated',
            value: false,
          },
        ],
      },
    },
  });

  const [getExportRecord] = useCustomQuery({
    query: GET_PAYMENT_LINKS,
    onError: () => {},
    onData: () => {},
  });

  const handleExport = async (type: string) => {
    try {
      const result = getFilterQueries();
      const output = await getExportRecord({
        variables: {
          offset: 0,
          limit: 2000,
          search,
          filters: {
            from: result.from,
            to: result.to,
            paymentStatus: result.status,
            minAmount: result.min ? Number(result.min) : 0,
            maxAmount: result.max ? Number(result.max) : 0,
          },
        },
      });
      if (output?.data?.getMerchantPaymentLinks?.paymentLinks) {
        // eslint-disable-next-line max-len
        const rec = cleanUpPaymentLinks(data?.getMerchantPaymentLinks?.paymentLinks);
        const func = type === 'pdf' ? generatePDF : generateCSV;
        func({
          payload: rec,
          title: 'PAYMENT_LINKS',
          selectedHeaders: [
            'title',
            'type',
            'amount',
            'date',
            'status',
            'link',
          ],
          formatHeaders: {
            title: 'Page Name',
            amount: 'Amount',
            date: 'Create On',
            type: 'Page Type',
          },
          cb: () => Toast.success('Exported successfully'),
        });
      }
    } catch (error) {
      console.log(error?.message);
      Toast.error('Error while exporting');
    }
  };

  const handleSelectDropdown = (action:any, details:any) => {
    if (action === 'View') {
      push(`/payment-link/${details?.name}?page=1`);
    }
    if (action === 'Edit') {
      push(`/edit-payment-link/${details?.name}?page=1`);
    }
    if (action === 'Share') {
      setShowShareModal(true);
      setActionDetails(details);
    }
    if (action === 'Re-activate') {
      setActionDetails(details);
      setShowActivate(true);
    }
    if (action === 'Deactivate') {
      setShowDeactivateAndComment(true);
      setActionDetails(details);
    }

    // push(`/payment-link/${details?.name}?page=1`);
  };

  return (
    <div className="payment-links">
      <DashboardWrap>
        <DashboardHeader
          title="Payment Link"
          smallSubTitle={`(${data?.getMerchantPaymentLinks?.count || 0})`}
          searchPlaceholder="Search by page name"
          search={search}
          onSearchChange={onSearchChange}
          searchLoading={loading}
          filterObj={filterObj}
          exportObj={{
            handleExport,
          }}
        >
          <AddPaymentLinkButton />
        </DashboardHeader>
        {data?.getMerchantPaymentLinks?.count ? (
          <AdminTable
            refreshing={loading}
            page={page}
            setPage={setPage}
            pageSize={limit}
            minHeight="calc(100vh - 280px)"
            count={data?.getMerchantPaymentLinks?.count}
            contents={cleanUpPaymentLinks(data?.getMerchantPaymentLinks?.paymentLinks)}
            reference="id"
            hideDominantImage
            // clickable
            hasNameAndImage
            handleSelectDropdown={handleSelectDropdown}
            allowKeyArr={getColumns(true)}
            hasActiveAndInactiveStatus
            dropDownIcon={<HorizontalListIcon color="rgba(160, 163, 189, 1)" />}
            dropdownActiveDetails={{ name: 'Deactivate', icon: <DeactivateRolesIcon />, color: '#F04438' }}

            dropdownDeActiveDetails={{ name: 'Re-activate', icon: <RefreshIcon />, color: '#12B76A' }}
            // handleClick={(data) => push(`/payment-link/${data?.name}?page=1`)}
            hasSpecialMobileTable
            mobileAllowKeyArr={
              [
                'image',
                ['title',
                  'link'],
                ['listIcon'],

              ]

            }
            formatLabels={columnLabelFormat}
            actionList
            dropdownData={dropdownData}
            CustomEmptyComponent={() => <EmptyPaymentLinks addNew={() => push('/create-payment-link')} />}
          />
        ) : (
          <EmptyRecord
            height="calc(100vh - 240px)"
            title="Start by creating a payment link"
            main="A payment link is an easier way to receive payments from your customers by sharing personalized link with them. "
            footer={<StyledButton name="Create Payment Link" fontSize="16px" color="#523B9F" fontWeight="700" border="none" backgroundColor="white" onClick={() => push('/create-payment-link')} />}
            loading={loading}
          />
        ) }
        {/* { showModal && (

          <AddPaymentLinks
            closeModal={() => setShowModal(false)}
            refetch={getPaymentLinkApi}
            triggerSuccessModal={triggerSuccessModal}
          />
        )} */}
        {showActivate && (
        <ActivateType
          closeModal={() => setShowActivate(false)}
          payload={actionDetails}
          onActivateClick={() => {
            setShowSuccess('activate');
            setShowActivate(false);
            getPaymentLinkApi();
          }}
        />
        )}

        { showShareModal && (
        <PaymentLinkSmallModal
          activateShareScreen
          closeModal={() => setShowShareModal(false)}
          payload={actionDetails}
        />
        )}
        { showSuccess && (
        <PaymentLinkSmallModal
          closeModal={() => setShowSuccess(false)}
          payload={actionDetails}
          activateType={showSuccess === 'activate'}
        />
        )}

        {showDeactivateAndComment && (
        <DeactivateTypeAndComment
          closeModal={() => setShowDeactivateAndComment(false)}
          payload={actionDetails}
          refetch={getPaymentLinkApi}
        />
        )}
      </DashboardWrap>
    </div>
  );
};

export default PaymentLinks;
