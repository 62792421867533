/* eslint-disable react/jsx-props-no-multi-spaces */
import React, { useState } from 'react';
import './Preference.scss';
import PreferenceSection from './preferenceSection';

const PrefrenceView = () => {
  const [checkDetails, setcheckDetails] = useState({
    CSN: false,
    CEN: false,
    quicksShop: false,
    paymentLink: false,
    SdkNotification: false,
  });
  const handleNotificationCheck = (value:any, attr:string) => {
    setcheckDetails({
      ...checkDetails,
      [attr]: value,
    });
  };

  return (
    <div className="prefrence-page-view">
      <div className="container col-md-10 col-12 m-0 p-0 prefrence-page-view-cover-row">
        <div className="prefrence-page-view-cover-row-one">
          <PreferenceSection
            leftTitle="Notifications"
            leftSubtitle="Manage how Payfi comminucates
and who receives them"
            rightTitle="Customer SMS Notification"
            rightSubtitle="If you select this, customers will receive SMS notifications"
            hasSubinner
            innerRightTitle="Customer Email Notification"
            innerRightSubtitle="By selecting this, customers will receive email notifications"
            checkState={checkDetails?.CSN}
            onChange={(evt:any) => handleNotificationCheck(evt.target.checked, 'CSN')}
            handleSubCheckChange={(evt:any) => handleNotificationCheck(evt.target.checked, 'CEN')}
            subCheckState={checkDetails?.CEN}

          />
        </div>
        <div className="prefrence-page-view-cover-row-two">
          <PreferenceSection
            leftTitle="Quickshop"
            leftSubtitle="Provide your customers with the fastest and easiest checkout options"
            rightTitle="Enable Quickshop"
            rightSubtitle="Set-up and activate an online store"
            hasInput
            placeholder="https://payfi.ng/ input url"
            onChange={(evt:any) => handleNotificationCheck(evt.target.checked, 'quicksShop')}
            checkState={checkDetails?.quicksShop}
          />
        </div>
        <div className="prefrence-page-view-cover-row-two">
          <PreferenceSection
            leftTitle="Payment Links"
            leftSubtitle="Payment links are sent to customers
          so they can pay for products"
            rightTitle="Enable Payment Links"
            rightSubtitle="By enabling this, customers will be able to use
          payment links to facilitate payment"
            onChange={(evt:any) => handleNotificationCheck(evt.target.checked, 'paymentLink')}
            checkState={checkDetails?.paymentLink}
          />
        </div>
        <div className="prefrence-page-view-cover-row-last">
          <PreferenceSection
            leftTitle="SDK Onboarding"
            leftSubtitle="The onboarding takes every customer
          through the initial process for XYX"
            rightTitle="Enable SDK Onboarding"
            rightSubtitle="This will show the onboarding process during
          SDK integration"
            onChange={(evt:any) => handleNotificationCheck(evt.target.checked, 'SdkNotification')}
            checkState={checkDetails?.SdkNotification}
          />
        </div>
      </div>
    </div>
  );
};
export default PrefrenceView;
