import React from 'react';

const Next = ({ disabled }) => (
  <div className="next">
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" fillOpacity={disabled ? '0.32' : '0.9'} clipRule="evenodd" d="M7.21807 4.86674C7.00592 5.07952 7.00446 5.42448 7.21953 5.63727L10.3996 8.79056L10.4557 8.83914C10.6707 9.00102 10.9779 8.9841 11.1731 8.78838C11.2788 8.68235 11.3327 8.54292 11.3327 8.40421C11.3327 8.26477 11.2788 8.12461 11.1716 8.01858L7.99157 4.86456L7.93539 4.81597C7.72045 4.6541 7.41321 4.67102 7.21807 4.86674ZM7.17027 14.4205C7.00617 14.6341 7.0236 14.9402 7.21807 15.1353C7.43095 15.3481 7.77723 15.3488 7.99157 15.136L12.7798 10.3864L12.829 10.3308C12.9035 10.2339 12.9409 10.1176 12.9409 10.0008C12.9409 9.86211 12.887 9.72267 12.7813 9.61664C12.5684 9.40313 12.2221 9.40241 12.0078 9.61519L7.21953 14.3647L7.17027 14.4205Z" fill="#523B9F" />
      <rect x="0.5" y="0.5" width="19" height="19" rx="3.5" stroke="#F2F4F7" />
    </svg>

  </div>

);

export default Next;
