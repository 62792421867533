import { gql } from '@apollo/client';

export const CREATE_MERCHANT = gql`
  mutation createMerchant(
    $businessName: String!
    $fullName: String!
    $email: String!
    $password: String!
    $phone: String!
    $hasOnlineStore: Boolean
    $hasOfflineStore: Boolean
  ) {
    createMerchant(
      input: {
        businessName: $businessName
        fullName: $fullName
        email: $email
        password: $password
        phone: $phone
        hasOnlineStore: $hasOnlineStore
        hasOfflineStore: $hasOfflineStore
      }
    ) {
      token
      status
      message
    }
  }
`;

export const LOGIN_MERCHANT = gql`
  mutation merchantLogin($email: String!, $password: String!) {
    merchantLogin(email: $email, password: $password) {
      status
      message
      token
    }
  }
`;

export const VERIFY_EMAIL_MERCHANT = gql`
  mutation merchantValidateEmail($code: String!) {
    merchantValidateEmail(code: $code) {
      status
      message
    }
  }
`;

export const RESEND_EMAIL = gql`
  mutation merchantResendEmailInvite {
    merchantResendEmailInvite {
      message
      emailMessage
      status
    }
  }
`;

export const UPDATE_MERCHANT = gql`
mutation updateMerchant(
  $input: MerchantUpdateInput
) {
  updateMerchant(
    input: $input
  ) {
    status
    message
    merchant {
      isApproved
          onboardingStage
          callback
          webhook
          publicKey
          secretKey
          logo
          name
          longDesc
          shortDesc
          registrationType
          incorporatedBusinessName
          institutionType
          accountName
          accountNumber
          bank
          website
          street
          country
          supportEmail
          contactPhone
          contactEmail
          twitter
          facebook
          instagram
          city
          state
    }
  }
}
`;

export const UPDATE_BUSINESS_INFORMATION = gql`
  mutation updateBusinessInformation(
    $logo: String
    $institutionType: String
    $registrationType: String
    $shortDesc: String
    $longDesc: String
    $incorporatedBusinessName: String
    $website: String
    $name: String
    $isOnboarding: Boolean
    $categoryId: String
  ) {
    updateBusinessInformation(
      input: {
        logo: $logo
        institutionType: $institutionType
        registrationType: $registrationType
        incorporatedBusinessName: $incorporatedBusinessName
        shortDesc: $shortDesc
        longDesc: $longDesc
        website: $website
        name: $name
        categoryId: $categoryId
      }
      isOnboarding: $isOnboarding
    ) {
      status
      message
    }
  }
`;

export const UPDATE_BUSINESS_PROFILE = gql`
  mutation updateBusinessProfile(
    $institutionType: String!
    $businessType: String!
    $longDesc: String!
    $website: String!
    $name: String!
    $categoryId: String!
    $country: String!
    $city: String!
    $state: String!
    $street: String!
    $contactPhone: String!
    $file: Upload
    $facebook: String!,
    $twitter: String!,
    $instagram: String!

  ) {
    updateBusinessProfile(
      input: {
        institutionType: $institutionType,
        businessType: $businessType,
        longDesc: $longDesc,
        website: $website,
        name: $name,
        categoryId: $categoryId,
        country: $country,
        city: $city,
        state: $state,
        street: $street,
        contactPhone: $contactPhone,
        facebook: $facebook,
        twitter: $twitter,
        instagram: $instagram
      },
      file: $file
    ) {
      status
      message
    }
  }
`;

export const UPLOAD_BUSINESS_LOGO = gql`
   mutation updateMerchantLogo (
     $file: Upload!,
     $resourceType: ResourceType
     ){
   updateMerchantLogo(
     file: $file
     resourceType: $resourceType) {
   status
   message
  }
}
`;

export const UPLOAD_USER_AVATAR = gql`
   mutation updateMerchantUserAvatar (
     $file: Upload!,
     $resourceType: ResourceType
     ){
   updateMerchantUserAvatar(
     file: $file
     resourceType: $resourceType) {
   status
   message
  }
}
`;

export const UPDATE_BANK_INFO = gql`
  mutation updateBankInformation(
    $accountName: String!
    $accountNumber: String!
    $bankCode: String!
    $isOnboarding: Boolean
  ) {
    updateBankInformation(
      input: {
        accountName: $accountName
        accountNumber: $accountNumber
        bankCode: $bankCode
      }
      isOnboarding: $isOnboarding
    ) {
      status
      message
    }
  }
`;

export const updateContactInformation = gql`
  mutation updateContactInformation(
    $address: String
    $supportEmail: String
    $contactEmail: String
    $twitter: String
    $facebook: String
    $instagram: String
    $city: String
    $state: String
    $contactPhone: String
    $street: String
    $country: String
    $isOnboarding: Boolean
  ) {
    updateContactInformation(
      input: {
        street: $street
        country: $country
        address: $address
        supportEmail: $supportEmail
        contactPhone: $contactPhone
        contactEmail: $contactEmail
        twitter: $twitter
        facebook: $facebook
        instagram: $instagram
        city: $city
        state: $state
      }
      isOnboarding: $isOnboarding
    ) {
      status
      message
    }
  }
`;

export const updateBusinessOwnerInformation = gql`
  mutation updateBusinessOwner(
    $firstName: String!,
    $lastName: String!,
    $email: String!,
    $documentName: String!,
    $documentId: String!,
    $city: String!,
    $state: String!,
    $phone: String!,
    $country: String!,
    $street: String!,
    $dateOfBirth: String!
    $file: Upload
  ) {
    updateBusinessOwner(
      input: {
        firstName: $firstName,
        lastName: $lastName,
        email: $email,
        documentName: $documentName,
        documentId: $documentId,
        city: $city,
        state: $state,
        phone: $phone,
        country: $country,
        street: $street,
        dateOfBirth: $dateOfBirth
      },
      file: $file
    ) {
      status
      message
    }
  }
`;
export const MULTIPLE_FILE_UPLOAD = gql`
  mutation ($files: [Upload]!) {
    multipleUpload(file: $files) {
      url
      filename
    }
  }
`;

export const MULTIPLE_FILE_UPLOAD_WITH_TYPE = gql`
  mutation ($pdfFiles: [Files]!) {
    multipleUploadWithType(files: $pdfFiles) {
      url
      filename
    }
  }
`;

export const DOCUMENT_UPLOAD = gql`
  mutation addMerchantDocument(
    $docs: [MerchantDocument]!
    $isOnboarding: Boolean
  ) {
    addMerchantDocument(input: $docs, isOnboarding: $isOnboarding) {
      status
      message
    }
  }
`;

export const SAVE_BUSINESS_DOCUMENT = gql`
  mutation saveBusinessDocumentInformation(
    $input: BusinessDocumentInformation!
  ) {
    saveBusinessDocumentInformation(input: $input) {
      status
      message
    }
  }
`;

export const DOCUMENT_UPLOAD_AWS = gql`
  mutation addMerchantDocumentAWSWIthFile(
    $name: String!
    $file: Upload!
    $isOnboarding: Boolean
  ) {
    addMerchantDocumentAWSWIthFile(name: $name, file: $file, isOnboarding: $isOnboarding) {
      status
      message
      data {
        docKey
        uri
        fileName
        id
      }
    }
  }
`;

export const UPDATE_USER = gql`
  mutation merchantUpdateUser(
    $firstName: String!
    $lastName: String!
    $phone: String!
  ) {
    merchantUpdateUser(
      firstName: $firstName
      lastName: $lastName
      phone: $phone
    ) {
      message
      status
    }
  }
`;

export const ADD_BANK_DETAILS = gql`
  mutation addMerchantBankDetail(
    $bankId: ID!
    $accountName: String!
    $accountNumber: String!
    $swiftCode: String
    $alias: String
    $setAsPrimary: Boolean
  ) {
    addMerchantBankDetail(
      input: {
        bankId: $bankId
        accountName: $accountName
        accountNumber: $accountNumber
        swiftCode: $swiftCode
        alias: $alias
      },
      setAsPrimary: $setAsPrimary
    ) {
      accountName
    }
  }
`;

export const CREATE_PAYMENT_LINK = gql`
  mutation createMerchantPaymentLink(
    $name: String!
    $title: String!
    $description: String!
    $configurations: String!
    $isPublic: Boolean!
    $file: Upload
  ) {
  createMerchantPaymentLink(
    name: $name
    title: $title
    description:$description
    configurations: $configurations
    isPublic: $isPublic
    file: $file
  ) {
    id
    name
    title
    description
    configurations
    isPublic
    merchantId
  }
}
`;

export const SET_WEBHOOK = gql`
  mutation setWebhookEndpoint($callback: String!, $webhook: String!) {
    setWebhookEndpoint(input: { callback: $callback, webhook: $webhook }) {
      message
      merchant {
        callback
        webhook
      }
    }
  }
`;

export const ADD_PAYMENTLINK_IMAGE = gql`
mutation AddPaymentLinkImage($paymentLinkId: String!, $file: Upload!) {
  addPaymentLinkImage(paymentLinkId: $paymentLinkId, file: $file) {
    message
    status
  }
}
`;

export const DELETE_PAYMENTLINK_IMAGE = gql`
mutation DeletePaymentLinkImage($deletePaymentLinkImageId: String!) {
  deletePaymentLinkImage(id: $deletePaymentLinkImageId) {
    message
    status
  }
}
`;

export const ADD_USER = gql`
  mutation createMerchantUser(
    $email: String!
    $phone: String!
    $firstName: String!
    $lastName: String!
    $roleId: ID
  ) {
    createMerchantUser(
      input: {
        email: $email
        phone: $phone
        firstName: $firstName
        lastName: $lastName
      }
      roleId: $roleId
    ) {
      token
    }
  }
`;

export const REMOVE_MERCHANT_USER = gql`
  mutation removeMerchantUser(
    $id: ID!
  ) {
    removeMerchantUser(
      id: $id
    ) {
      status
      message
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation merchantForgotPassword($email: String!) {
    merchantForgotPassword(email: $email) {
      message
      status
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation merchantResetPassword(
    $resetToken: String!
    $newPassword: String!
  ) {
    merchantResetPassword(resetToken: $resetToken, newPassword: $newPassword) {
      message
      status
    }
  }
`;

export const USER_CHANGE_PASSWORD = gql`
  mutation merchantChangePassword(
    $newPassword: String!
    $oldPassword: String!
  ) {
    merchantChangePassword(newPassword: $newPassword, oldPassword: $oldPassword) {
      message
      status
    }
  }
`;

export const BANK_VERIFICATION = gql`
  mutation getTransBen(
    $transferType: String
    $accountNo: String
    $bankCode: String
  ) {
    getTransBen(
      transfer_type: $transferType
      accountNo: $accountNo
      bankCode: $bankCode
    ) {
      name
      clientId
      bvn
      account {
        id
        number
      }
      status
      currency
      bank
    }
  }
`;

export const SET_ACTIVE_BANK_ACCT = gql`
  mutation setAsActiveMerchantBankDetail($id: ID!) {
    setAsActiveMerchantBankDetail(id: $id) {
      message
      status
    }
  }
`;

export const UPDATE_BANK_DETAILS = gql`
  mutation updateMerchantBankDetail(
    $bankId: String!
    $accountName: String!
    $accountNumber: String!
    $swiftCode: String
    $alias: String
    $setAsPrimary: Boolean
    $id: ID!
  ) {
    updateMerchantBankDetail(
      input: {
        bankId: $bankId
        accountName: $accountName
        accountNumber: $accountNumber
        swiftCode: $swiftCode
        alias: $alias
      }
      id: $id,
      setAsPrimary: $setAsPrimary
    ) {
      message
      status
    }
  }
`;

export const DELETE_BANK_DETAILS = gql`
  mutation deleteMerchantBankDetail(
    $id: ID!
  ) {
    deleteMerchantBankDetail(
      id: $id
    ) {
      message
      status
    }
  }
`;

export const GEN_PAYMENT_LINK = gql`
  mutation generatePaymentLink(
    $amount: Float!
    $payfiId: String!
    $desc: String!
    $reference: String!
  ) {
    generatePaymentLink(
      amount: $amount
      payfiId: $payfiId
      desc: $desc
      reference: $reference
    ) {
      status
      link
      message
    }
  }
`;

export const UPDATE_PAYMENT_LINK = gql`
  mutation updateMerchantPaymentLink($input: UpdatePaymentLinkInput!, $name: String!) {
  updateMerchantPaymentLink(input: $input, name: $name) {
    id
    name
    title
    description
    configurations
    isPublic
    merchantId
    createdAt
    status
    reason
  }
}
`;

export const DEACTIVATE_PAYMENT_LINK = gql`
  mutation deactivateMerchantPaymentLink ($name: String!, $reason: String!) {
    deactivateMerchantPaymentLink(name: $name, reason: $reason) {
      id
      name
      title
      description
      configurations
      merchantId
      createdAt
      status
      reason
    }
  }
`;

export const ACTIVATE_PAYMENT_LINK = gql`
 mutation activateMerchantPaymentLink($name: String!) {
  activateMerchantPaymentLink(name: $name) {
    id
    name
    title
    description
    configurations
    merchantId
    createdAt
    status
    reason
  }
}
`;

export const DELETE_PAYMENT_LINK = gql`
 mutation deleteMerchantPaymentLink($name: String!) {
  deleteMerchantPaymentLink(name: $name) {
    status
    message
  }
}
`;

export const SEND_FOR_ACTIVATION = gql`
  mutation sendForActivation {
    sendForActivation {
      status
      message
    }
  }
`;

export const CREATE_RNPL_VOUCHER_CODES = gql`
  mutation createLoanVoucherCodes($voucherCodes: [String], $vendor: String, $amount: Int){
  createLoanVoucherCodes(voucherCodes: $voucherCodes, vendor: $vendor, amount: $amount) {
    status
    message
  }
}
`;
