import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Gear from '../../components/Loading/Gear';
import { useGlobalDispatch } from '../../contexts/globalContext';
import useToast from '../../customHooks/useToast';
import { UPLOAD_BUSINESS_LOGO } from '../../graphql/mutations';
import RightArrow from '../../svgs/RightArrow';

interface IgetStartedCard{
    title?:string;
    subtitle?:string;
    linkPage?:any;
    icon?:any;
    hasUpload?:boolean;
    handleModal?:any;
    link?:any;
    hasModal?:boolean;
}
const GetStartedCard = (props:IgetStartedCard) => {
  const Toast = useToast();
  const { get_profile_in_app } = useGlobalDispatch();
  const [updateBusinessDetailsLogo] = useMutation<
  { updateMerchantLogo: any },
  any
>(UPLOAD_BUSINESS_LOGO);
  const [loader, setLoader] = useState(false);
  const handleUploadLogo = async (evt:any) => {
    const file = evt.target.files[0];
    if (!file) return;
    setLoader(true);
    try {
      const payload = { file, resourceType: 'image' };
      const resp = await updateBusinessDetailsLogo({
        variables: payload,
      });
      if (resp?.data?.updateMerchantLogo?.status === 200) {
        Toast.success(resp?.data?.updateMerchantLogo?.message);
        await get_profile_in_app();
        setLoader(false);
      } else {
        setLoader(false);
        Toast.error(resp?.data?.updateMerchantLogo?.message);
      }
    } catch (e) {
      setLoader(false);
      if (e && e.graphQLErrors && e.graphQLErrors.length > 0) {
        Toast.error(e.graphQLErrors[0].message);
      } else {
        Toast.error('Something went wrong');
      }
    }
  };
  // const handleRouteToLink = () => {
  //   props?.link;
  // };
  return (
    <div className="get-started-card-cover">
      <div className="get-started-card-cover-title">{props?.title}</div>
      <div className="get-started-card-cover-subtitle">{props?.subtitle}</div>
      <div className="get-started-card-cover-link">
        {
          props?.hasUpload && (
          <label style={{ display: 'flex' }} htmlFor="file-input">
            <span>{loader ? 'Uploading...' : props?.linkPage}</span>
            <span style={{ marginLeft: '9px' }}>{loader ? <Gear /> : <RightArrow />}</span>
          </label>
          )
        }
        {
          props?.link && (
            <span>
              <Link className="span-link" to={{ pathname: props?.link }} target="_blank">

                {props?.linkPage}
                <span style={{ marginLeft: '9px' }}><RightArrow /></span>
              </Link>
            </span>

          )
        }
        {

          props?.hasModal && (
            <span onClick={props?.handleModal}>
              {props?.linkPage}
              <span style={{ marginLeft: '9px' }}><RightArrow /></span>
            </span>
          )
        }
        <input
          accept="image/*"
          type="file"
          id="file-input"
          style={{ display: 'none' }}
          onChange={(evt) => handleUploadLogo(evt)}
        />
      </div>
      <div className="get-started-card-cover-icon">{props?.icon}</div>
    </div>
  );
};
export default GetStartedCard;
