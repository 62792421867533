import React from 'react';
import './LinkBackComponent.scss';
import { Link } from 'react-router-dom';
import ChevronLeft from '../../svgs/ChevronLeft';

type Props = {
  link: string;
  text: string;
}

const LinkBackComponent = ({ link, text }: Props) => (
  <Link to={link} className="link-back">
    <ChevronLeft />
    <span>{text}</span>
  </Link>
);

export default LinkBackComponent;
