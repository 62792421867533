import { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { DOCUMENT_UPLOAD_AWS } from '../graphql/mutations';
import { useGlobalDispatch } from '../contexts/globalContext';

const useSingleDocUpload = () => {
  const [uploadDoc, { data, error, loading: graphqlLoading }] = useMutation(DOCUMENT_UPLOAD_AWS);
  const [file, setFile] = useState(null);
  const [document, setDocument] = useState({});

  const { get_profile_in_app } = useGlobalDispatch();

  const handleUpload = async () => {
    setDocument(null);
    try {
      await uploadDoc({
        variables: { file: file?.file, name: file.name },
      });
      await get_profile_in_app();
    } catch (error) {
      console.log('something went wrong');
    }
  };

  useEffect(() => {
    if (file) {
      handleUpload();
    }
  }, [file]);

  useEffect(() => {
    if (!error && data) {
      setFile(null);
      if (data?.addMerchantDocumentAWSWIthFile?.data) {
        setDocument(data?.addMerchantDocumentAWSWIthFile?.data);
      } else {
        console.log(error?.message || 'Document upload unsuccessful');
      }
    }
    if (error) {
      console.log(error?.message || 'Document upload unsuccessful');
    }
  }, [data, error]);

  return {
    data,
    document,
    error,
    loading: graphqlLoading,
    setFile,
  };
};

export default useSingleDocUpload;
