import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import ModalFormComp from '../../../components/ModalFormComp';
import useToast from '../../../customHooks/useToast';
import { REMOVE_MERCHANT_USER } from '../../../graphql/mutations';
import DeleteIcon from '../../../svgs/DeleteIcon';

type Props = {
  user: any;
  closeModal: any;
  refetch: any;
}

const DeleteTeam = ({ user, closeModal, refetch }: Props) => {
  const [removeTeam] = useMutation<
  { removeMerchantUser: any },
  any
>(REMOVE_MERCHANT_USER);
  const [loading, setLoading] = useState(false);
  const Toast = useToast();

  const handleSubmit = async () => {
    setLoading(true);

    try {
      const resp = await removeTeam({
        variables: {
          id: user.id,
        },
      });

      setLoading(false);
      if (resp?.data?.removeMerchantUser?.status === 200) {
      // set success message
        Toast.success('User Removed successfully');
        refetch();
        closeModal();
      } else {
        Toast.error(
          resp?.data?.removeMerchantUser?.message || 'Operation failed',
        );
      }
    } catch (e) {
      setLoading(false);
      if (e && e.graphQLErrors && e.graphQLErrors.length > 0) {
        Toast.error(e.graphQLErrors[0].message);
      } else {
        Toast.error('Something went wrong');
      }
    }
  };
  return (
    <div>
      <ModalFormComp cancelActionBtn={closeModal} confirmationBtnContent={loading ? 'Confirm...' : 'Confirm'} closeModal={closeModal} removeTitle modalType="admin-confirmation-modal" confirmActionBtn={handleSubmit} btnLoading={loading} confirmationIcon={<DeleteIcon />} confirmationSubTitle="Are you sure you want to proceed with this operation?" confirmationTitle={`Remove "${user?.firstName}"`} />
    </div>
  );
};

export default DeleteTeam;
