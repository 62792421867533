import { useMutation } from '@apollo/client';
import { FunctionComponent, useEffect, useState } from 'react';
import useForm from '../../../customHooks/useForm';
// import './AddNewAccount.scss';
import useToast from '../../../customHooks/useToast';
import {
  ADD_BANK_DETAILS,
  BANK_VERIFICATION,
} from '../../../graphql/mutations';
import { GET_BANKS } from '../../../graphql/queries';
import useCustomQuery from '../../../network/useCustomQuery';
import TextInput from '../../../components/input';
import ModalFormComp from '../../../components/ModalFormComp';
import SelectSearch from '../../../components/SelectSearch';
import CheckStateIcon from '../../../svgs/CheckStateIcon';
import './AddNewAccount.scss';

export interface IAddNewAccount {
  label?: string;
  closeModal?: any;
  refetch?: any;
}

const AddNewAccount: FunctionComponent<IAddNewAccount> = ({
  closeModal,
  refetch,
}) => {
  const Toast = useToast();
  const [ActiveCheck, setActiveCheck] = useState(false);
  const {
    formValues, handleChange, setError, checkAllRequired, resetForm,
  } = useForm(
    {
      accountName: '',
      accountNumber: '',
      bankId: '',
      alias: '',
    },
    {},
    Toast,
  );

  const [loading, setLoading] = useState(false);
  const [shouldResolveAccount, setShouldResolveAccount] = useState(false);

  const [getBanks, getBanksLoading, banks] = useCustomQuery({
    query: GET_BANKS,
    onError: () => {},
    onData: () => {},
  });
  const [accountNameLoading, setAccountNameLoading] = useState(false);
  const [getAccountName] = useMutation<
    { createSaving: any; getTransBen: any },
    any
  >(BANK_VERIFICATION);

  useEffect(() => {
    // setStep(5);
    getBanks();
  }, []);
  // convert string to sentence case
  const bankList = banks?.banks?.map?.((data: { name: string; nipCode: string }) => ({
    label: data.name,
    text: data.name,
    value: data.nipCode,
    ...data,
  })) || [];

  const [addAccountDetails] = useMutation<
    { createSaving: any; addMerchantBankDetail: any },
    any
  >(ADD_BANK_DETAILS);

  const handleSubmit = async () => {
    setLoading(true);
    setError('');
    const formDetails = {
      setAsPrimary: ActiveCheck,
      ...formValues,
    };
    if (!checkAllRequired(['alias'])) {
      setLoading(false);
      return;
    }

    try {
      const resp = await addAccountDetails({ variables: formDetails });

      setLoading(false);
      if (resp?.data?.addMerchantBankDetail) {
        // set success message
        Toast.success('Account Added successfully');
        resetForm();
        refetch();
        closeModal();
      } else {
        Toast.error(
          resp?.data?.addMerchantBankDetail?.message || 'Operation failed',
        );
      }
    } catch (e) {
      setLoading(false);
      if (e && e.graphQLErrors && e.graphQLErrors.length > 0) {
        Toast.error(e.graphQLErrors[0].message);
      } else {
        Toast.error('Something went wrong');
      }
    }
  };

  const resolveAccountDetails = async (
    accountNumber: string,
    bankCode: string,
  ) => {
    try {
      setShouldResolveAccount(false);
      setAccountNameLoading(true);
      const payload: {
        transferType: string;
        accountNo: string;
        bankCode: string;
      } = {
        accountNo: accountNumber,
        bankCode,
        transferType: bankCode === '090110' ? 'intra' : 'inter',
      };
      const resp = await getAccountName({ variables: payload });

      setAccountNameLoading(false);
      if (resp?.data?.getTransBen?.name) {
        // set success message
        Toast.success('Account Name Resolved');
        resetForm({
          accountName: resp?.data?.getTransBen?.name,
        });
      } else {
        Toast.error('Account name could not be resolved');
        handleChange({ target: { value: '', name: 'accountName' } });
      }
    } catch (e) {
      setAccountNameLoading(false);
      handleChange({ target: { value: '', name: 'accountName' } });
    }
  };

  useEffect(() => {
    if (
      !Number.isNaN(Number(formValues?.accountNumber))
      && formValues?.accountNumber?.length === 10
      && formValues?.bankId?.length >= 6
      && shouldResolveAccount
      && !accountNameLoading
    ) {
      resolveAccountDetails(formValues?.accountNumber, formValues?.bankId);
    }
  }, [
    formValues?.accountNumber,
    formValues?.bankId,
    bankList,
    shouldResolveAccount,
  ]);

  const buttonValid = () => {
    const disabled = !(
      formValues?.bankId
      && formValues?.accountNumber?.length === 10
      && formValues?.accountName
    );
    return disabled;
  };
  const handlestateChange = () => {
    if (ActiveCheck) {
      setActiveCheck(false);
    } else {
      setActiveCheck(true);
    }
  };
  return (
    <ModalFormComp
      title="Add Settlement Account"
      subTitle="Money gotten from your buisness can be paid here"
      btnAction={handleSubmit}
      btnLoading={loading || getBanksLoading}
      btnDisabled={buttonValid()}
      btnName={
        getBanksLoading
          ? 'Fetching Banks...'
          : loading
            ? 'Adding...'
            : 'Add Account'
      }
      closeModal={closeModal}
      hasCancel
    >
      <div className="">
        <div style={{ marginBottom: '20px' }}>
          <SelectSearch
            onChange={(e) => {
              setShouldResolveAccount(true);
              handleChange(e);
            }}
            placeholder="Select your bank here"
            name="bankId"
            value={formValues?.bankId}
            options={bankList}
            required={false}
            label={false}
            classes={false}
            hideLabel={false}
          />
        </div>
        <TextInput
          name="accountNumber"
          value={formValues?.accountNumber}
          customOnChange={(e) => {
            setShouldResolveAccount(true);
            handleChange(e);
          }}
          label="Account Number"
          width="100%"
        />
        <TextInput
          label="Alias"
          name="alias"
          value={formValues?.alias}
          customOnChange={handleChange}
          width="100%"
        />
        <TextInput
          label={
            accountNameLoading ? 'Resolving Account Name...' : 'Bank Account Name'
          }
          name="accountName"
          value={formValues?.accountName}
          customOnChange={handleChange}
          width="100%"
          disabled
        />
      </div>
      <div className="account-check-icon">
        <CheckStateIcon handleClick={handlestateChange} checkState={ActiveCheck} />
        <span onClick={handlestateChange}>Set as primary account </span>
      </div>
    </ModalFormComp>
  );
};

export default AddNewAccount;
