import React from 'react';
import { FileUploader } from 'react-drag-drop-files';
import DragImageIcon from '../../svgs/dragImageIcon';
import Gear from '../Loading/Gear';
import './DragAndDrop.scss';

const fileTypes = ['JPG', 'PNG', 'GIF', 'SVG', 'JPEG'];
// const MAX_FILE_SIZE = 2097152;
interface IDropdown{
  handleGetFileUpload?:any;
  loading?: boolean;
  title?:string;
  subTitle?:string;
}
const DragDrop = ({
  handleGetFileUpload, loading, title, subTitle,
}:IDropdown) => {
  const handleChange1 = (file) => {
    handleGetFileUpload(file);
  };

  return (
    <FileUploader disabled={loading} handleChange={handleChange1} name="file" types={fileTypes}>
      <div
        className="file-upload-cover"
        style={{
          paddingLeft: title ? '18px' : '35px', paddingRight: title ? '17px' : '36px',
        }}
      >

        <div className="inner-cover">
          <DragImageIcon />
          <br />
          {
            title ? <span>{title}</span> : (
              <span>
                <span>Click to replace </span>
                or drag and drop
              </span>
            )
          }

          <br />
          {
            subTitle || ' SVG, PNG, JPG or GIF (max 800 x 400px)'
          }

          <div style={{ width: 'fit-content', margin: '0 auto' }}>{loading && <Gear />}</div>
        </div>
      </div>
    </FileUploader>
  );
};

export default DragDrop;
