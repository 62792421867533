import {
  FunctionComponent, useEffect,
} from 'react';
import { useParams } from 'react-router';
import { GET_TRANSACTION_BY_ID } from '../../graphql/queries';
import DashboardWrap from '../../components/DashboardWrap';
import LinkBackComponent from '../../components/LinkBackComponent';
import './TransactionDetail.scss';
import { cleanUpSingleTransaction, cleanUpLoanSchedules } from '../../utils/helpers';
import useCustomQuery from '../../network/useCustomQuery';
import LoanSchedules from './LoanSchedules';
import TransactionInfo from './TransactionInfo';
import ProductCard from './ProductCard';
import ShippingComp from './ShippingComp';

const TransactionDetail: FunctionComponent = () => {
  const { transactionId } = useParams<{ transactionId: string }>();

  const [getTransaction, loading, transaction] = useCustomQuery({
    query: GET_TRANSACTION_BY_ID,
    onError: () => {},
    onData: () => {},
  });

  useEffect(() => {
    if (transactionId) {
      getTransaction({ variables: { id: transactionId } });
    }
  }, [transactionId]);

  const payload = transaction?.getMerchantTransactionById?.data
    && cleanUpSingleTransaction(transaction?.getMerchantTransactionById?.data);
  const loanSchedules = cleanUpLoanSchedules(
    transaction?.getMerchantTransactionById?.loanSchedules,
  );

  return (
    <DashboardWrap>
      <div className="single-transaction-detail">
        <header>
          <div className="title-cov">
            <LinkBackComponent
              link="/transactions"
              text="Transactions"
            />
            <div className="trx-title">
              <h3>
                Transaction Ref : :
              </h3>
              <h5>
                {loading ? '...' : payload?.txRef}
              </h5>
            </div>
          </div>
        </header>
        <section>
          <TransactionInfo transaction={payload} />
          <ProductCard product={payload?.productDetails} />
        </section>
        <section>
          <LoanSchedules loanSchedules={loanSchedules} />
          <ShippingComp address={payload?.shippingAddress} />
        </section>
      </div>
    </DashboardWrap>
  );
};

export default TransactionDetail;
