import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { useGlobalDispatch, useGlobalState } from '../../../contexts';
import useToast from '../../../customHooks/useToast';
import { SEND_FOR_ACTIVATION } from '../../../graphql/mutations';
import { setItemsToLocalStorage } from '../../../utils/helpers';

const SendForActivation = () => {
  const { get_profile_in_app } = useGlobalDispatch();
  const { profile }:any = useGlobalState();
  const thisMerchant = profile?.merchant;
  const Toast = useToast();
  const [sendForActivation] = useMutation<
  { sendForActivation: any },
  any
>(SEND_FOR_ACTIVATION);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    setItemsToLocalStorage('hasSend', false);
    try {
      const result = await sendForActivation();
      if (result?.data?.sendForActivation?.status === 200) {
        // set success message
        // result?.data?.sendForActivation?.message
        setItemsToLocalStorage('hasSend', true);
        Toast.success('Application has been sent for review and confirmation');
        await get_profile_in_app();
      } else {
        Toast.error(result?.data?.sendForActivation?.message);
        setItemsToLocalStorage('hasSend', false);
      }
    } catch (e) {
      setItemsToLocalStorage('hasSend', false);
      if (e && e.graphQLErrors && e.graphQLErrors.length > 0) {
        Toast.error(e.graphQLErrors[0].message);
      } else {
        Toast.error('Something went wrong');
      }
    }
    setLoading(false);
  };

  return (
    <div className="send-for-activation">
      <h5>Send for Activation?</h5>
      <span className="span-text">All your documents and information have been filled and saved.</span>
      <button
        onClick={handleSubmit}
        disabled={thisMerchant?.documentSentForActivation || loading}
        style={{
          opacity: thisMerchant?.documentSentForActivation ? '0.5' : '1',
        }}
      >
        <span>{loading ? 'Sending...' : 'Send for Activation'}</span>
      </button>
    </div>
  );
};

export default SendForActivation;
