import React from 'react';

const DashboardIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M1.33301 7.99967C1.33301 4.32301 4.32367 1.33301 7.99967 1.33301C11.6757 1.33301 14.6663 4.32301 14.6663 7.99967C14.6663 11.6757 11.6757 14.6663 7.99967 14.6663C5.91501 14.6663 3.98834 13.7177 2.71501 12.065C2.55167 11.8523 2.59167 11.5477 2.80367 11.385C3.01567 11.221 3.31901 11.2617 3.48301 11.473C4.57167 12.8863 6.21767 13.697 7.99967 13.697C11.141 13.697 13.697 11.1417 13.697 7.99967C13.697 4.85834 11.141 2.30234 7.99967 2.30234C4.85834 2.30234 2.30301 4.85834 2.30301 7.99967C2.30301 8.29167 2.32434 8.58167 2.36767 8.86567C2.40767 9.12967 2.22634 9.37701 1.96167 9.41768C1.69567 9.46167 1.44967 9.27701 1.40967 9.01234C1.35901 8.68034 1.33301 8.33967 1.33301 7.99967ZM7.28907 5.22461C7.28907 4.95661 7.50641 4.73927 7.77374 4.73927C8.04107 4.73927 8.25841 4.95661 8.25841 5.22461V8.18861L10.5411 9.54994C10.7711 9.68727 10.8464 9.98461 10.7091 10.2146C10.6191 10.3659 10.4577 10.4506 10.2931 10.4506C10.2084 10.4506 10.1224 10.4286 10.0451 10.3826L7.52507 8.87994C7.37907 8.79194 7.28907 8.63394 7.28907 8.46327V5.22461Z" fill="#475467" />
  </svg>

);

export default DashboardIcon;
