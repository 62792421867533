import React from 'react';
import NewCountryIcon from '../../svgs/NewCountryIcon';
import './CountrySelectOption.scss';

const CountrySelectOption = () => (
  <div className="country-select-icon">
    <div className="country-select-icon-inner">
      <div className="country-select-icon-inner-country"><NewCountryIcon /></div>
      <div>+234</div>
    </div>
  </div>
);
export default CountrySelectOption;
