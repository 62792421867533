import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import CalenderIcon from '../../svgs/CalenderIcon';
import './AppDatePicker.scss';

interface IAppDatePicker{
  onChange?:any;
  placeholder?:string;
  value?:any;
  maxDate?: string;
  dateFormat?:any;
  className?:string;
  hasIcon?:boolean;
  showTimeSelect?:boolean;
  minDate?: any;
}

const AppDatePicker = ({
  onChange, placeholder, value, maxDate, dateFormat, className, hasIcon, showTimeSelect, minDate,
}:IAppDatePicker) => (
  <div className={className || (value ? 'has-date-field' : 'empty-date-field')}>
    {
      value && <div className="date-label">{placeholder}</div>
    }

    <DatePicker
      onChange={onChange}
      placeholderText={placeholder}
      selected={value}
      dateFormat={dateFormat || 'dd/MM/yyyy'}
      maxDate={new Date(maxDate)}
      minDate={new Date(minDate)}
      showYearDropdown
      showMonthDropdown
      showTimeSelect={showTimeSelect}
    />
    {
      hasIcon && (
      <div className="calender_icon">
        {' '}
        <CalenderIcon />
      </div>
      )
    }

  </div>
);
export default AppDatePicker;
