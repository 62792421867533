import React from 'react';

const DecreaseIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16 8C16 12.4183 12.4183 16 8 16V16C3.58172 16 0 12.4183 0 8V8C0 3.58172 3.58172 0 8 0V0C12.4183 0 16 3.58172 16 8V8Z" fill="#FECDCA" fillOpacity="0.3" />
    <path d="M4.28245 11.3687L11.3535 4.29761" stroke="#912018" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M9.97487 11.3567L4.28314 11.369L4.29492 5.67676" stroke="#912018" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>

);

export default DecreaseIcon;
