/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react';
import RoundDeleteIcon from '../../svgs/RoundDeleteIcon';
import './ImageWithHover.scss';

interface IImageCoverWithHover{
  handleDelete?:any;
  width?:string;
  height?:string;
  url?:any;
  loading?:boolean;
}
const ImageCoverWithHover = ({
  handleDelete, width, height, url, loading,
}:IImageCoverWithHover) => (
  <div className="image_cover_with_hover" style={{ width: width || '104px', height: height || '104px', borderRadius: '4px' }}>
    <img src={url} alt="upload image" style={{ width: width || '100%', height: height || '104px', borderRadius: '4px' }} />
    <div className={loading ? 'image_del_icon_loader' : 'image_del_icon_cover'}>
      {
        loading ? 'Loading...' : <RoundDeleteIcon onClick={handleDelete} />
      }

    </div>
  </div>
);
export default ImageCoverWithHover;
