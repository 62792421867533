import React from 'react';

const DropdownIcon = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.5 4.25L6 7.75L2.5 4.25" stroke="#81899E" strokeLinecap="round" strokeLinejoin="round" />
  </svg>

);

export default DropdownIcon;
