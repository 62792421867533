import React from 'react';
import { useHistory } from 'react-router-dom';
import DashboardWrap from '../../components/DashboardWrap';

import GoBackIcon from '../../svgs/GoBackIcon';
import PaymentDetails from './PaymentDetails';
import './ViewPaymentLink.scss';

interface ICreatePaymentLink{
  type?:string;
}
const CreatePaymentLink = ({ type }:ICreatePaymentLink) => {
  const history = useHistory();

  return (
    <div className="view_payment_link_cover">
      <DashboardWrap>
        <div className="go_back_div_cover">
          <GoBackIcon onClick={() => history?.goBack()} />
          <span>Payment Links</span>
        </div>
        <div className="create_link_title_cover">{type ? 'Edit Payment Link' : 'Create Payment Link'}</div>

        <PaymentDetails type={type} />

      </DashboardWrap>
    </div>
  );
};
export default CreatePaymentLink;
