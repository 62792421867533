import React, { useRef } from 'react';
import IdealTImer from 'react-idle-timer';
import { useHistory } from 'react-router-dom';
import { useGlobalDispatch } from '../contexts/globalContext';

const MerchantIdealTimer = () => {
  const idleTimerRef:any = useRef(null);
  const { push, location } = useHistory();
  const { clearUser } = useGlobalDispatch();

  const handleOnIdeal = () => {
    if (location?.pathname !== '/') {
      clearUser();
      push('/');
    }
  };
  // const time = process.env.REACT_APP_ENV === 'development' ? 2 * 1000 : 10 * 60 * 1000;
  return (
    <div>
      <IdealTImer ref={idleTimerRef} timeout={10 * 60 * 1000} onIdle={handleOnIdeal} />
    </div>
  );
};
export default MerchantIdealTimer;
