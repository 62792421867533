import { FunctionComponent } from 'react';
import { NavLink } from 'react-router-dom';
import './NavRouter.scss';

export interface ILinkComp {
  name?: string;
  link: string;
  Icon?: any;
}

const LinkComp: FunctionComponent<ILinkComp> = ({ name, link }) => (
  <NavLink to={link} className="navrouter-link-comp">
    <p className="" style={{ fontFamily: 'Calibre_Regular' }}>{name}</p>
  </NavLink>
);

export default LinkComp;
