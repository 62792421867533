import React, { useEffect, useRef } from 'react';
import useToast from '../../customHooks/useToast';
import ExportIcon from '../../svgs/ExportIcon';
import Buttons from '../Buttons';
import Dropdown from '../Dropdown';
import './ExportPDF.scss';

const ExportPDF = ({ exportObj, disableExport }: { exportObj?: any, disableExport?:boolean }) => {
  const [showExportList, setShowExportList] = React.useState(false);
  const [exportIsLoading, setExportIsLoading] = React.useState('');
  const exportsRef = useRef<HTMLDivElement>(null);
  const Toast = useToast();
  const handleClick = (e) => {
    if (exportsRef?.current?.contains(e.target)) {
      return;
    }
    setShowExportList(false);
  };
  useEffect(() => {
    window.addEventListener('click', handleClick);
    return () => {
      window.removeEventListener('click', handleClick);
    };
  }, []);

  const handleExport = async ({ value }) => {
    if (exportIsLoading) {
      Toast.error('Please wait while the previous export is in progress');
      return;
    }
    setExportIsLoading(value);
    await exportObj?.handleExport?.(value);
    setExportIsLoading('');
  };

  return (
    <div ref={exportsRef} className="exports-pdf">
      <Buttons
        name="Export"
        className="btn-md ml-2"
        Icon={ExportIcon}
        onClick={() => {
          if (disableExport) return;
          setShowExportList((prev) => !prev);
        }}
        optional="filter-button"
        disabled={disableExport}
      />
      <div className={`exports-dropdown ${showExportList ? '' : 'exports-pdf-hidden'}`}>
        <Dropdown onClick={handleExport} options={[{ label: (exportIsLoading === 'pdf') ? 'Downloading...' : 'Download as .PDF', value: 'pdf' }, { label: exportIsLoading === 'csv' ? 'Downloading...' : 'Download as .CSV', value: 'csv' }]} />
      </div>
    </div>
  );
};

export default ExportPDF;
