import React from 'react';

const dragImageIcon = () => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle opacity="0.3" cx="14" cy="14" r="14" fill="#E6E7EC" />
    <path fillRule="evenodd" clipRule="evenodd" d="M11.0947 20.6673H16.906C19.1553 20.6673 20.6673 19.0887 20.6673 16.7387L20.6667 15.7953C20.6667 15.654 20.6026 15.5207 20.492 15.432C20.3459 15.3155 20.1959 15.1854 20.042 15.0518L20.036 15.0467L20.0314 15.0427C19.2082 14.3314 18.1849 13.4472 16.8993 13.7467C15.9676 13.9629 15.4897 14.6682 15.0682 15.29L15.066 15.2933L15.0651 15.2946C14.7854 15.7081 14.5216 16.0982 14.1533 16.3253C13.7913 16.5507 13.3607 16.39 12.7447 16.1207L12.64 16.074C11.516 15.5847 10.594 15.7253 9.81998 16.5053C9.63932 16.6873 9.64065 16.9827 9.82265 17.1633C10.0047 17.3453 10.2993 17.3427 10.48 17.1607C10.978 16.6587 11.4967 16.5907 12.2687 16.928L12.3713 16.9727L12.3723 16.9731C12.9922 17.2437 13.8398 17.6137 14.6427 17.1167C15.1807 16.7833 15.514 16.2913 15.836 15.8153L15.8362 15.815C16.2095 15.2638 16.5321 14.7873 17.11 14.6533C17.9369 14.4621 18.6932 15.1159 19.4261 15.7496L19.4273 15.7507C19.4381 15.76 19.4488 15.7693 19.4596 15.7786C19.5532 15.8598 19.6457 15.94 19.7367 16.0153V16.7387C19.7367 18.5607 18.6253 19.7367 16.906 19.7367H11.0947C9.37532 19.7367 8.26398 18.5607 8.26398 16.7387V11.2627C8.26398 9.44065 9.37532 8.26465 11.0947 8.26465H16.9067C18.626 8.26465 19.7367 9.44065 19.7367 11.2627V12.8427C19.7367 13.0993 19.9453 13.308 20.202 13.308C20.4587 13.308 20.6673 13.0993 20.6673 12.8427V11.2627C20.6673 8.91265 19.156 7.33398 16.9067 7.33398H11.0947C8.84532 7.33398 7.33398 8.91265 7.33398 11.2627V16.7387C7.33398 19.0887 8.84532 20.6673 11.0947 20.6673ZM11.9031 13.5601C10.9624 13.5601 10.1977 12.7947 10.1977 11.8541C10.1977 10.9141 10.9624 10.1487 11.9031 10.1487C12.8437 10.1487 13.6084 10.9141 13.6084 11.8541C13.6084 12.7947 12.8437 13.5601 11.9031 13.5601ZM11.9031 11.0794C11.4757 11.0794 11.1277 11.4267 11.1277 11.8541C11.1277 12.2821 11.4757 12.6294 11.9031 12.6294C12.3311 12.6294 12.6784 12.2821 12.6784 11.8541C12.6784 11.4267 12.3311 11.0794 11.9031 11.0794Z" fill="#667085" />
  </svg>

);
export default dragImageIcon;
