import React from 'react';

const FacebookIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" fill="white" fillOpacity="0.75" />
    <path d="M12.8015 19.9999V12.7016H15.2513L15.618 9.85726H12.8015V8.04126C12.8015 7.21779 13.0302 6.65655 14.2111 6.65655L15.7173 6.65585V4.11195C15.4567 4.07742 14.5627 4 13.5226 4C11.351 4 9.86432 5.32545 9.86432 7.75971V9.85733H7.4082V12.7016H9.86424V20L12.8015 19.9999Z" fill="#312360" />
  </svg>

);
export default FacebookIcon;
