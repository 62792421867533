import { Line } from 'react-chartjs-2';
import React, { useMemo } from 'react';
import moment from 'moment';
import { numberWithCommas } from '../../utils/tableHelpers';

// const MONTHS = [
//   'January',
//   'February',
//   'March',
//   'April',
//   'May',
//   'June',
//   'July',
//   'August',
//   'September',
//   'October',
//   'November',
//   'December',
// ];

// function months(config) {
//   const cfg = config || {};
//   const count = cfg.count || 12;
//   const { section } = cfg;
//   const values = [];
//   let i; let
//     value;

//   for (i = 0; i < count; ++i) {
//     value = MONTHS[Math.ceil(i) % 12];
//     values.push(value.substring(0, section));
//   }

//   return values;
// }

// const labels = months({ count: 12 });
// const data = {
//   labels,
//   datasets: [{
//     label: 'Loan dataset',
//     data: [20, 45, 48, 35, 55, 80, 45, 30, 40, 70, 60, 60],
//     fill: true,
//     backgroundColor: 'rgba(89, 65, 169, 0.11)',
//     borderColor: 'rgba(75, 192, 192, 0)',
//     pointHitRadius: 8,
//     pointBackgroundColor: '#523B9F00',
//     pointHoverBackgroundColor: '#523B9F',
//   },
//   ],
// };

const generateData = (loanArr) => {
  if (!loanArr?.length) return { labels: [], datasets: [] };
  const data = [];
  const labels = [];
  loanArr.forEach(({ trxCount, dateCreated }) => {
    data.push(Number(trxCount || 0));
    labels.push(moment(dateCreated).format('dddd'));
  });

  return {
    labels,
    datasets: [{
      label: 'Loans',
      data,
      fill: true,
      backgroundColor: 'rgba(89, 65, 169, 0.11)',
      borderColor: 'rgba(75, 192, 192, 0)',
      pointHitRadius: 8,
      pointBackgroundColor: '#523B9F00',
      pointHoverBackgroundColor: '#523B9F',
    },
    ],
  };
};

const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      backgroundColor: '#fff',
      titleColor: '#98A2B3',
      borderColor: 'rgba(220, 216, 236, 0.4)',
      borderWidth: 1,
      caretSize: 0,
      padding: {
        top: 10,
        left: 16,
        right: 16,
        bottom: 10,
      },
      cornerRadius: 4,
      displayColors: false,
      titleFont: {
        size: 10,
        weight: '400',
        family: 'Inter_Regular',
      },
      bodyColor: '#344054',
      bodyAlign: 'left',
      titleAlign: 'left',
      footerAlign: 'left',
      bodyFont: {
        size: 13,
        weight: '500',
        family: 'Inter_Medium',
      },
      callbacks: {
        label(context) {
          const label = context.dataset.label || '';
          if (context.parsed.y !== null) {
            return `${numberWithCommas(context.parsed.y)} Transaction(s)`;
          }
          return label;
        },
      },
    },
  },
  scales: {
    y: {
      grid: {
        display: false,
      },
      display: false,
    },
    x: {
      grid: {
        display: false,
      },
      display: false,
      ticks: {
        display: false,
      },
    },
  },
};

const LineGraph = ({ trxArr }) => {
  const data = useMemo(() => generateData(trxArr), [trxArr]);
  return (
    <div className="line-graph">
      <Line options={options} data={data} height="290px" />
    </div>
  );
};

export default LineGraph;
