/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable max-len */
import { useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
// import { useHistory } from 'react-router-dom';

import DoubleInputGenerator from '../../../components/DoubleInputGenerator';
import DragDrop from '../../../components/DragAndDropFileUpload';
import ImageCoverWithHover from '../../../components/ImageWithHover';
import TextInput from '../../../components/input';
import InputCreator from '../../../components/InputCreator';
import NewRoundLink from '../../../components/NewRoundLink';
import PaymentLinkSmallModal from '../../../components/PaymentLinkSmallModal';
import StyledButton from '../../../components/StyledButton';
import useForm from '../../../customHooks/useForm';

// import StyledButton from '../../../components/StyledButton';
import useToast from '../../../customHooks/useToast';
import {
  ADD_PAYMENTLINK_IMAGE, CREATE_PAYMENT_LINK, DELETE_PAYMENTLINK_IMAGE, UPDATE_PAYMENT_LINK,
} from '../../../graphql/mutations';
import { GET_PAYMENT_DETAILS } from '../../../graphql/queries';
import useCustomQuery from '../../../network/useCustomQuery';
import { generateRandomCustomString, parseJSON } from '../../../utils/helpers';
// import CloseButtonIcon from '../../../svgs/CloseButtonIcon';
import CheckboxNew from '../../../components/CheckboxNew';
import './PaymentDetails.scss';

interface IPaymentDetails{
  type?:string;
}
const PaymentDetails = ({ type }:IPaymentDetails) => {
  const Toast = useToast();
  const { name } = useParams<{ name: string }>();
  const { push } = useHistory();
  const [loader, setLoading] = useState(false);
  const [createPayload, setCreatePayload] = useState(null);
  const [activeTab, setActiveTab] = useState('Payment Details');
  const linkData = [
    { id: 1, name: 'Payment Details' },
    { id: 2, name: 'Additional Information' },

  ];

  const [paymentLinkImage, setPaymentLinkImages] = useState([]);
  const [activeDeleteId, setActiveDeleteId] = useState(null);
  const [imageCount, setImageCount] = useState(0);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [createPaymentLink, { loading: createPaymentLinkLoading }] = useMutation<
  { createMerchantPaymentLink: any },
  any
>(CREATE_PAYMENT_LINK);

  const [createPaymentLinkImage, { loading: createPaymentLinkImageLoading }] = useMutation<
{ addPaymentLinkImage: any },
any
>(ADD_PAYMENTLINK_IMAGE);

  const [triggerDeletePaymentLinkImage, { loading: deletePaymentLinkImageLoading }] = useMutation<
{ deletePaymentLinkImage: any },
any
>(DELETE_PAYMENTLINK_IMAGE);

  const [editPaymentLink, { loading: editLoading }] = useMutation<
{ updateMerchantPaymentLink: any },
any
>(UPDATE_PAYMENT_LINK);

  const [getPaymentDetails, , payment] = useCustomQuery({
    query: GET_PAYMENT_DETAILS,
    onError: () => { },
    onData: () => { },
  });

  const [uploadFile, setUploadFile]:any = useState(null);
  const [localImage, setLocalImage] = useState('');

  const [showSuccess, setShowSuccess] = useState(false);

  const doubleValueArray:any = [];

  const [doubleInputs, setDoubleInputs]:any = useState([
    {
      name: '',
      value: doubleValueArray,
      attr: 'input1',
      optionName: '',
    },
  ]);
  const [inputs, setInputs] = useState([
    {
      name: 'input1',
      value: '',
    },
  ]);

  const fetchSinglePaymentLink = () => {
    getPaymentDetails({
      variables: {
        name,
      },
    });
  };

  useEffect(() => {
    if (type === 'edit') {
      fetchSinglePaymentLink();
    }
  }, [type]);

  const {
    formValues, handleChange, resetForm,
  } = useForm(
    {
      customUrl: '',
      title: '',
      description: '',
      amount: '',
      successMessage: '',
      isPublic: false,
    },
    {},
    Toast,
  );

  useEffect(() => {
    if (payment?.getPaymentLink
    ) {
      const payConfigObj = parseJSON(payment?.getPaymentLink?.configurations) || {};
      resetForm({
        customUrl: payment?.getPaymentLink?.name,
        title: payment?.getPaymentLink?.title || '',
        description: payment?.getPaymentLink?.description || '',
        amount: payConfigObj?.amount,
        successMessage: payConfigObj?.successMessage,
        isPublic: payment?.getPaymentLink?.isPublic || false,
      });

      setDoubleInputs(payConfigObj?.productPreference || [
        {
          name: '',
          value: doubleValueArray,
          attr: 'input1',
          optionName: '',
        },
      ]);
      setInputs(payConfigObj?.inputsArray || [
        {
          name: 'input1',
          value: '',
        },
      ]);
      setPaymentLinkImages(payment?.getPaymentLink?.images);
    }
  }, [payment]);

  useEffect(() => {
    setImageCount(paymentLinkImage?.length);
  }, [paymentLinkImage]);

  const removeWhiteSpace = (value: string) => value.replace(/\s/g, '-')?.toLowerCase?.();

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (!formValues?.title) {
      Toast.error('Payment link title is required');
      return;
    }
    if (!formValues?.description) {
      Toast.error('Payment link description is required');
      return;
    }
    if (Number(formValues?.amount) < 1) {
      Toast.error('Amount should be greater than 0');
      return;
    }

    setLoading(true);

    const payloadObj = {
      name: formValues?.customUrl
        ? removeWhiteSpace(formValues?.customUrl) : generateRandomCustomString(),
      title: formValues?.title,
      description: formValues?.description,
      isPublic: formValues?.isPublic,
      file: uploadFile,
      configurations: JSON.stringify({
        amount: formValues?.amount,
        successMessage: formValues?.successMessage,
        inputsArray: inputs,
        productPreference: doubleInputs,
        showAmount: false,
      }),
    };

    if (!createPaymentLinkLoading) {
      try {
        const res = await createPaymentLink({
          variables: payloadObj,
        });
        const resField = 'createMerchantPaymentLink';
        if (res?.data?.[resField]) {
          Toast.success('Payment Link created successfully');
          setLoading(false);
          setDoubleInputs([
            {
              name: '',
              value: doubleValueArray,
              attr: 'input1',
              optionName: '',
            },
          ]);
          setInputs([
            {
              name: 'input1',
              value: '',
            },
          ]);
          setActiveTab('Payment Details');
          setCreatePayload(res?.data?.[resField]);
          setShowSuccess(true);
          setUploadFile('');
          setLocalImage('');
        } else {
          setLoading(false);
          Toast.error(res?.data?.createMerchantPaymentLink?.message || 'Operation failed');
        }
      } catch (e) {
        setLoading(false);
        if (e && e.graphQLErrors && e.graphQLErrors.length > 0) {
          Toast.error(e.graphQLErrors[0].message);
        } else {
          Toast.error('Something went wrong');
        }
      }
    }
  };

  const handleUpload = (e:any) => {
    const validFiles = ['image/jpeg', 'image/png', 'image/jpg'];
    if (!validFiles?.includes(e?.type)) {
      Toast.error('File not supported');
      return;
    }
    setLocalImage(URL.createObjectURL(e));
    setUploadFile(e);
  };

  const handleClearImage = () => {
    setLocalImage('');
    setUploadFile(null);
  };

  const handleAddNewImage = async (e:any) => {
    if (!createPaymentLinkImageLoading) {
      try {
        const res = await createPaymentLinkImage({
          variables: {
            paymentLinkId: payment?.getPaymentLink?.id,
            file: e,
          },
        });

        if (res?.data?.addPaymentLinkImage && res?.data?.addPaymentLinkImage?.status === 200) {
          Toast.success(res?.data?.addPaymentLinkImage?.message || 'Operation was successful');
          fetchSinglePaymentLink();
        } else {
          Toast.error(res?.data?.addPaymentLinkImage?.message || 'Operation failed');
        }
      } catch (e) {
        if (e && e.graphQLErrors && e.graphQLErrors.length > 0) {
          Toast.error(e.graphQLErrors[0].message);
        } else {
          Toast.error('Something went wrong');
        }
      }
    }
  };

  const handleDeleteImage = async (id:any) => {
    setActiveDeleteId(id);

    if (!deletePaymentLinkImageLoading) {
      try {
        const res = await triggerDeletePaymentLinkImage({
          variables: {
            deletePaymentLinkImageId: id,
          },
        });

        if (res?.data?.deletePaymentLinkImage && res?.data?.deletePaymentLinkImage?.status === 200) {
          Toast.success(res?.data?.deletePaymentLinkImage?.message || 'Operation was successful');
          fetchSinglePaymentLink();
        } else {
          Toast.error(res?.data?.deletePaymentLinkImage?.message || 'Operation failed');
        }
      } catch (e) {
        if (e && e.graphQLErrors && e.graphQLErrors.length > 0) {
          Toast.error(e.graphQLErrors[0].message);
        } else {
          Toast.error('Something went wrong');
        }
      }
    }
  };

  const handleSubmitEdit = async () => {
    if (!formValues?.title) {
      Toast.error('Payment link title is required');
      return;
    }
    if (!formValues?.description) {
      Toast.error('Payment link description is required');
      return;
    }
    if (Number(formValues?.amount) < 1) {
      Toast.error('Amount should be greater than 0');
      return;
    }

    setLoading(true);

    const payloadObj = {
      name: formValues?.customUrl
        ? removeWhiteSpace(formValues?.customUrl) : generateRandomCustomString(),
      title: formValues?.title,
      description: formValues?.description,
      isPublic: formValues?.isPublic,
      configurations: JSON.stringify({
        amount: formValues?.amount,
        successMessage: formValues?.successMessage,
        inputsArray: inputs,
        productPreference: doubleInputs,
        showAmount: false,
      }),
    };

    if (!editLoading) {
      try {
        const res = await editPaymentLink({
          variables: {
            input: {
              configurations: payloadObj?.configurations,
              description: payloadObj?.description,
              title: payloadObj?.title,
              isPublic: payloadObj?.isPublic,
            },
            name: payloadObj?.name,
          },
        });
        const resField = 'updateMerchantPaymentLink';
        if (res?.data?.[resField]) {
          Toast.success(res?.data?.updateMerchantPaymentLink?.message || 'Payment Link successfully updated.');
          setLoading(false);
          setActiveTab('Payment Details');
          setCreatePayload(res?.data?.[resField]);
          setShowSuccess(true);
          setUploadFile('');
          setLocalImage('');
        } else {
          setLoading(false);
          Toast.error(res?.data?.updateMerchantPaymentLink?.message || 'Operation failed');
        }
      } catch (e) {
        setLoading(false);
        if (e && e.graphQLErrors && e.graphQLErrors.length > 0) {
          Toast.error(e.graphQLErrors[0].message);
        } else {
          Toast.error('Something went wrong');
        }
      }
    }
  };

  const handleClose = () => {
    // if (data) {
    //   setShowSuccess(false);
    //   return;
    // }
    push('/payment-links?page=1');
  };
  const handleEditBtn = (name) => {
    push(`/edit-payment-link/${name}`);
    setShowSuccess(false);
  };

  return (
    <div className="view_payment_link_cover_page">
      <NewRoundLink data={linkData} activeTab={activeTab} handleChangeTab={(data) => setActiveTab(data)} />
      <div className="payment_details_cover">
        {
          activeTab === 'Additional Information' && (
          <div>
            <InputCreator inputs={inputs} setInputs={setInputs} />
          </div>
          )
        }
        {
          activeTab === 'Payment Details' && (
          <div>
            <TextInput
              name="title"
              value={formValues?.title}
              customOnChange={handleChange}
              label="Product Name"
              width="100%"
              required
            />
            <TextInput
              label="Product Description"
              name="description"
              value={formValues?.description}
              customOnChange={handleChange}
              width="100%"
              required
              rows={4}
              multiline
            />
            <TextInput
              label="Amount"
              name="amount"
              value={formValues?.amount}
              customOnChange={handleChange}
              width="100%"
              required
              type="cash"
            />
            <div className="payment_link_custom_url_cover">
              <div className="custom_url_prefix">{`${process.env.REACT_APP_PAYFI_WEBSITE_URL}/pay/`}</div>
              <div className="custom_url_input">
                <TextInput
                  name="customUrl"
                  value={formValues?.customUrl}
                  customOnChange={handleChange}
                  label="Input your custom url (optional)"
                  width="100%"
                  disabled={!!type}
                />
              </div>
            </div>
            <TextInput
              name="successMessage"
              value={formValues?.successMessage}
              customOnChange={handleChange}
              label="Success message (optional)"
              width="100%"
            />

            <div className="payment_link_check">
              <CheckboxNew
                id="isPublic"
                onChange={(e) => {
                  handleChange({
                    target: {
                      name: 'isPublic',
                      value: e.target.checked,
                    },
                  });
                }}
                name="isPublic"
                value={formValues?.isPublic}
              >
                Display this link on Quickshop page
              </CheckboxNew>
            </div>

            <div className="payment_link_product_options">
              <div className="payment_link_product_title">
                Product Options
              </div>
              <div className="payment_link_product_subtitle">
                You are to select or input your product preference here
              </div>
            </div>
            <DoubleInputGenerator doubleInputs={doubleInputs} setDoubleInputs={setDoubleInputs} />
            {/* upload images */}
            {
              !type && (
              <div className="payment_details_add_images row">
                <div className="payment_details_add_images_title">Add Image (optional)</div>
                <div className="payment_details_add_images_sub_title">
                  Upload an image
                </div>
                {
                  !localImage && (
                  <div className={uploadFile && 'col-lg-4'}>
                    <DragDrop
                      handleGetFileUpload={(e) => handleUpload(e)}
                      title={uploadFile && 'Click to add'}
                      subTitle={uploadFile && 'a picture'}
                    />
                  </div>
                  )
                }

                {
                  // eslint-disable-next-line jsx-a11y/img-redundant-alt
                  localImage && (
                  <div className="col-lg-4">
                    <ImageCoverWithHover url={localImage} width="100%" height="104px" handleDelete={handleClearImage} />
                    {/* <img src={localImage} alt="upload image" style={{ width: '100%', height: '104px' }} /> */}
                  </div>
                  )
                }

              </div>
              )
            }

            {
              type === 'edit' && (
              <div className="payment_details_add_images row">
                <div className="payment_details_add_images_title">Add Image (optional)</div>
                <div className="payment_details_add_images_sub_title">
                  Upload an image
                </div>
                {
                  imageCount < 4 && (
                  <div className={paymentLinkImage?.length ? 'col-lg-4' : ''} style={{ marginBottom: '10px' }}>
                    <DragDrop
                      handleGetFileUpload={(e) => handleAddNewImage(e)}
                      title={paymentLinkImage?.length && 'Click to add'}
                      subTitle={paymentLinkImage?.length && 'a picture'}
                      loading={createPaymentLinkImageLoading}
                    />
                  </div>
                  )
                }

                {
                  // eslint-disable-next-line jsx-a11y/img-redundant-alt
                  paymentLinkImage?.length ? (
                    paymentLinkImage?.map((list:any) => (
                      <div className="col-4" style={{ marginBottom: '10px' }}>
                        <ImageCoverWithHover url={list?.url} width="100%" height="104px" handleDelete={() => handleDeleteImage(list?.id)} loading={!!(activeDeleteId === list?.id && deletePaymentLinkImageLoading)} />
                        {/* <img src={localImage} alt="upload image" style={{ width: '100%', height: '104px' }} /> */}
                      </div>
                    ))

                  ) : ''
                }

              </div>
              )
            }

          </div>
          )
        }

        <div className="additional_btn_and_create_btn_cover">
          {
            activeTab === 'Additional Information' ? <StyledButton name="Previous" border="1px solid rgba(230, 231, 236, 1)" color="rgba(82, 59, 159, 1)" fontSize="14px" backgroundColor="white" borderRadius="4px" padding="10px 16px" onClick={() => setActiveTab('Payment Details')} disabled={!!loader} /> : <StyledButton name="Add Additional Information" border="1px solid rgba(230, 231, 236, 1)" color="rgba(82, 59, 159, 1)" fontSize="14px" backgroundColor="white" borderRadius="4px" padding="10px 16px" onClick={() => setActiveTab('Additional Information')} disabled={!!loader} />
          }

          <span style={{ marginLeft: '24px' }}>
            <StyledButton name={loader || editLoading ? 'Please wait...' : type ? 'Save changes' : 'Create Link'} border="none" color="white" fontSize="14px" backgroundColor="rgba(82, 59, 159, 1)" borderRadius="4px" padding="10px 16px" onClick={type ? handleSubmitEdit : handleSubmit} disabled={type ? !!editLoading : !!loader} />
          </span>
        </div>
        {showSuccess
      && createPayload?.name && (
      <PaymentLinkSmallModal
        closeModal={handleClose}
        payload={createPayload}
        type={type}
        handleCloseEditModal={() => setShowSuccess(false)}
        handleEditBtn={handleEditBtn}
      />
        )}
        {/* <PaymentLinkSmallModal
          closeModal={() => setShowSuccess(false)}
          payload={createPayload}
        /> */}
      </div>
    </div>

  );
};
export default PaymentDetails;
