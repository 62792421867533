import { useHistory, useLocation } from 'react-router-dom';
import { parseJSON } from '../utils/helpers';

const useGetQueryParams = (pageAndSearchIncluded = true) => {
  const { search } = useLocation();
  const history = useHistory();
  const getQuery = (name) => new URLSearchParams(search).get(name);
  const updateOrAddSearchParams = (obj) => {
    const searchParams = new URLSearchParams(window.location.search);
    Object.keys(obj).forEach((key) => {
      const value = obj[key];
      const result = ['status', 'channel', 'type', 'category', 'product', 'tenor', 'partner', 'stage', 'paymentType', 'paymentMethod', 'groupUsers', 'appType'].includes(key) ? JSON.stringify(value) : value;
      const encodedValue = encodeURIComponent(result);
      searchParams.set(key, encodedValue);
    });
    // remove refresh from search params
    searchParams.delete('refresh');
    // push new search params to history
    if (pageAndSearchIncluded) {
      searchParams.set('page', 1);
      searchParams.set('search', '');
    }
    history.push({
      search: searchParams.toString(),
    });
  };

  const getSearchParams = (key) => {
    const searchParams = new URLSearchParams(window.location.search);
    const result = searchParams.get(key);
    if (!result) return '';
    const decodedResult = decodeURIComponent(result);
    const output = ['status', 'channel', 'type', 'category', 'product', 'tenor', 'partner', 'stage', 'paymentType', 'paymentMethod', 'groupUsers', 'appType'].includes(key) ? parseJSON(decodedResult) : decodedResult;
    return output;
  };

  const getFilterQueries = () => {
    const result = {
      from: getSearchParams('from') || '',
      to: getSearchParams('to') || '',
      fromDate: getSearchParams('fromDate') || '',
      toDate: getSearchParams('toDate') || '',
      min: getSearchParams('min') || '',
      max: getSearchParams('max') || '',
      status: getSearchParams('status') || [],
      channel: getSearchParams('channel') || [],
      product: getSearchParams('product') || [],
      partner: getSearchParams('partner') || [],
      stage: getSearchParams('stage') || [],
      category: getSearchParams('category') || [],
      groupUsers: getSearchParams('groupUsers') || [],
      paymentType: getSearchParams('paymentType') || [],
      paymentMethod: getSearchParams('paymentMethod') || [],
      tenor: getSearchParams('tenor') || [],
      type: getSearchParams('type') || [],
      minCharge: getSearchParams('minCharge') || '',
      maxCharge: getSearchParams('maxCharge') || '',
      appType: getSearchParams('appType') || [],
    };
    return result;
  };

  return {
    getQuery, updateOrAddSearchParams, getSearchParams, search, getFilterQueries,
  };
};

export default useGetQueryParams;
