/* eslint-disable max-len */
import {
  FunctionComponent, useEffect, useMemo, useState,
} from 'react';
import { useMutation } from '@apollo/client';
import AddNewAccount from './AddNewAccount';
import useCustomQuery from '../../../network/useCustomQuery';
import { GET_BANK_DETAILS } from '../../../graphql/queries';
import EditNewAccount from './EditNewAccount';
import './SettlementAccount.scss';
import NewPlusIcon from '../../../svgs/NewPlusIcon';
import ListIcon from '../../../svgs/ListIcon';
import MerchantDropdown from '../../../components/MerchantDropdown';
import NewEditIcon from '../../../svgs/NewEditIcon';
import NewDeleteIcon from '../../../svgs/NewDeleteIcon';
import BankLogo from '../../../components/BankLogo';

import { SET_ACTIVE_BANK_ACCT } from '../../../graphql/mutations';
import useToast from '../../../customHooks/useToast';
import DeleteAccount from './DeleteAccount';

export interface ISettlementAccount {
  label?: string;
}

const SettlementAccount: FunctionComponent<ISettlementAccount> = () => {
  const [showAddAccount, setShowAddAccount] = useState(false);
  const [showEditAccount, setShowEditAccount] = useState(false);
  const [showDeleteAccount, setShowDeleteAccount] = useState(false);
  const [dropdownCheck, setdropdownCheck]:any = useState({ index: '', isOpen: false });
  const [payload, setPayload] = useState(null);
  const Toast = useToast();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [getBankDetails, getBankDetailsLoading, accounts] = useCustomQuery({
    query: GET_BANK_DETAILS,
    onError: () => {},
    onData: () => {},
  });

  useEffect(() => {
    getBankDetails();
  }, []);

  const [setActiveAcct] = useMutation<
  { createSaving: any; setAsActiveMerchantBankDetail: any },
  any
>(SET_ACTIVE_BANK_ACCT);

  const handleSetAsPrimary = async (id) => {
    const res = await setActiveAcct({
      variables: {
        id,
      },
    });
    if (res?.data?.setAsActiveMerchantBankDetail?.status !== 200) {
      throw new Error(
        res?.data?.setAsActiveMerchantBankDetail?.message || 'Operation failed',
      );
    }
    await getBankDetails();
    Toast.success('Account set as primary successfully');
  };

  const accountDetails = useMemo(() => accounts?.bankDetails?.bankDetails?.map?.((ele) => ({
    accountName: ele.accountName,
    accountNumber: ele.accountNumber,
    nipCode: ele?.bank?.nipCode,
    bankId: ele?.bank?.nipCode,
    isPrimary: !!ele.status,
    id: ele.id,
    alias: ele.alias,
  })), [accounts]);

  const itemList = [{
    title: 'Edit Details',
    icon: <NewEditIcon />,
    color: '#475467',
  }, {
    title: 'Set as Primary',
    icon: <NewEditIcon />,
    color: '#475467',
  }, {
    title: 'Delete',
    icon: <NewDeleteIcon />,
    color: '#B42318',
  }];
  const handleShowMenu = (index:any) => {
    if (dropdownCheck?.isOpen) {
      setdropdownCheck({ index, isOpen: false });
    } else {
      setdropdownCheck({ index, isOpen: true });
    }
  };
  const handleDropDown = (title:any, details:any) => {
    setdropdownCheck({ index: '', isOpen: false });
    switch (title) {
    case 'Edit Details':
      setShowEditAccount(true);
      setPayload(details);
      break;
    case 'Delete':
      setShowDeleteAccount(true);
      setPayload(details);
      break;
    case 'Set as Primary':
      handleSetAsPrimary(details?.id);
      break;
    default:
      setShowEditAccount(false);
      break;
    }
  };

  return (
    <div className="account-statement-cover container col-lg-10 col-md-12 m-0 p-0">
      <div className="account-statement-cover-row row m-0 p-0">
        <div className="col-md-4 col-12 m-0 p-0 account-statement-cover-row-col">
          <div className="account-statement-cover-row-col-inner-card add-account-card align-items-center justify-content-center" onClick={() => setShowAddAccount(true)}>
            <div className="add-account-card-inner-cover">
              <div className="add-account-card-icon-div"><NewPlusIcon /></div>
              <div className="add-account-card-content-div">Add new account</div>
            </div>
          </div>
        </div>
        {
          accountDetails?.map((list:any, index) => (
            <div className="col-md-4  col-12 m-0 p-0 account-statement-cover-row-col" key={index}>
              <div className="account-statement-cover-row-col-inner-card">
                <div className="col-inner-card-title">
                  <div className="col-inner-card-title-logo"><BankLogo nipCode={list?.nipCode} /></div>
                  <div className="col-inner-card-title-list-icon" onClick={() => handleShowMenu(index)}><ListIcon /></div>
                </div>
                {
                  dropdownCheck?.isOpen && dropdownCheck?.index === index && (
                  <div className="account-statement-dropdown">
                    <MerchantDropdown data={itemList} handleDropdown={handleDropDown} details={list} fontSize="9px" />

                  </div>
                  )
                }

                <div className="col-inner-card-tag">
                  {list?.accountName}
                </div>
                <div className="col-inner-card-account-number-tag">
                  {list?.accountNumber}
                  {
                    list?.isPrimary && <div className="primary-tag">Primary</div>
                  }
                </div>

              </div>

            </div>
          ))
        }
      </div>
      {showAddAccount && (
        <AddNewAccount
          refetch={getBankDetails}
          closeModal={() => setShowAddAccount(false)}
        />
      )}
      {showEditAccount && (
        <EditNewAccount
          refetch={getBankDetails}
          payload={payload}
          closeModal={() => setShowEditAccount(false)}
        />
      )}
      {showDeleteAccount && (
        <DeleteAccount
          refetch={getBankDetails}
          payload={payload}
          closeModal={() => setShowDeleteAccount(false)}
        />
      )}
    </div>
  );
};

export default SettlementAccount;
