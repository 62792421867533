import { useEffect, useMemo, useState } from 'react';
import './Settlement.scss';
import { useHistory } from 'react-router-dom';
import DashboardWrap from '../../components/DashboardWrap';
import useCustomQuery from '../../network/useCustomQuery';
import { GET_SETTLEMENTS } from '../../graphql/queries';
import Table from '../../components/Table';
import { cleanUpSettlements } from '../../utils/helpers';

import SettlementFlashCardNew from '../../components/SettlementFlashCardNew';
import useGetQueryParams from '../../customHooks/useGetQueryParams';
import DashboardHeader from '../../components/DashboardHeader';
import TotalSettlementIcon from '../../svgs/TotalSettlementIcon';
import TotalSalesIcon from '../../svgs/TotalSalesIcon';
import PendingSettlementIcon from '../../svgs/PendingSettlementIcon';
import TotalChargesIcon from '../../svgs/TotalChargesIcon';
import EmptyRecord from '../../components/EmptyRecord';
import EmptySettlementIcon from '../../svgs/EmptySettlementIcon';
import useToast from '../../customHooks/useToast';
import generateCSV from '../../utils/generateCSV';
import generatePDF from '../../utils/generatePdf';
import useFilterMethods from '../../customHooks/useFilterMethods';

const limit = 15;
const Settlements = () => {
  const [checked, setChecked] = useState([]);
  const [getSettlements, getSettlementsLoading, rawData] = useCustomQuery({
    query: GET_SETTLEMENTS,
    onError: () => {},
    onData: () => {},
  });

  const { getQuery, getFilterQueries } = useGetQueryParams();
  const page = Number(getQuery('page'));
  const search = getQuery('search') || '';
  const { push } = useHistory();

  const setPage = (pageNum) => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('page', pageNum);
    push({
      search: searchParams.toString(),
    });
  };

  if (!page) {
    push(`/settlements?page=1&search=${search}`);
  }

  const [settlements, setSettlements] = useState(null);

  useEffect(() => {
    if (rawData?.getSettlementsByMerchantId?.settlements) {
      setSettlements(rawData);
    }
  }, [rawData]);

  const onSearchChange = (e) => {
    push(`/settlements?page=1&search=${encodeURIComponent(e.target.value)}`);
  };

  const details = useMemo(
    () => [
      {
        name: 'total settlement',
        value: settlements?.getSettlementsByMerchantId?.totalSettled,
        Icon: TotalSettlementIcon,
        colored: true,
      },
      {
        name: 'total fees',
        value: settlements?.getSettlementsByMerchantId?.totalFees,
        Icon: TotalChargesIcon,
      },
      {
        name: 'total returns',
        value: settlements?.getSettlementsByMerchantId?.totalAmount,
        Icon: TotalSalesIcon,
      },
      {
        name: 'pending settlement',
        value: settlements?.getSettlementsByMerchantId?.pendingSettlement,
        Icon: PendingSettlementIcon,
      },
    ],
    [settlements?.getSettlementsByMerchantId],
  );
  useEffect(() => {
    if (page) {
      const result = getFilterQueries();
      getSettlements({
        variables: {
          offset: (page - 1) * limit,
          limit,
          search,
          filters: {
            from: result.from,
            to: result.to,
            status: result.status,
            minAmount: result.min ? Number(result.min) : 0,
            maxAmount: result.max ? Number(result.max) : 0,
            minChargeAmount: result.minCharge ? Number(result.minCharge) : 0,
            maxChargeAmount: result.maxCharge ? Number(result.maxCharge) : 0,
          },
        },
      });
    }
  }, [page, search]);

  const Toast = useToast();

  const [getExportRecord] = useCustomQuery({
    query: GET_SETTLEMENTS,
    onError: () => {},
    onData: () => {},
  });

  const handleExport = async (type: string) => {
    try {
      const result = getFilterQueries();
      const output = await getExportRecord({
        variables: {
          offset: 0,
          limit: 2000,
          search,
          filters: {
            from: result.from,
            to: result.to,
            status: result.status,
            minAmount: result.min ? Number(result.min) : 0,
            maxAmount: result.max ? Number(result.max) : 0,
            minChargeAmount: result.minCharge ? Number(result.minCharge) : 0,
            maxChargeAmount: result.maxCharge ? Number(result.maxCharge) : 0,
          },
        },
      });
      if (output?.data?.getSettlementsByMerchantId?.settlements) {
        // eslint-disable-next-line max-len
        const rec = cleanUpSettlements(output?.data?.getSettlementsByMerchantId?.settlements);
        const func = type === 'pdf' ? generatePDF : generateCSV;
        func({
          payload: rec,
          title: 'SETTLEMENTS',
          selectedHeaders: [
            'S/N',
            'settlementReference',
            'amount',
            'fees',
            'commission',
            'amountToBeSettled',
            'status',
          ],
          formatHeaders: {
            dateTime: 'Created On',
            settlementReference: 'Reference',
            amount: 'Sales',
            fees: 'Charges',
            amountToBeSettled: 'Amount Settled',
          },
          cb: () => Toast.success('Exported successfully'),
        });
      }
    } catch (error) {
      Toast.error('Error while exporting');
    }
  };

  const onFilterSubmit = (result) => {
    getSettlements({
      variables: {
        offset: 0,
        limit,
        search,
        filters: {
          from: result.from,
          to: result.to,
          status: result.status,
          minAmount: result.min ? Number(result.min) : 0,
          maxAmount: result.max ? Number(result.max) : 0,
          minChargeAmount: result.minCharge ? Number(result.minCharge) : 0,
          maxChargeAmount: result.maxCharge ? Number(result.maxCharge) : 0,
        },
      },
    });
  };

  const {
    filterObj,
  } = useFilterMethods({
    allowedFilters: ['dateRange', 'status', 'amountRange', 'charges'],
    defaultUrl: 'settlements?page=1&search=',
    cb: onFilterSubmit,
    Toast,
    customFilterInformation: {
      amountRange: {
        label: 'Sales Amount',
      },
      status: {
        label: 'Settlement Status',
        options: [
          {
            label: 'Settled',
            value: 'settled',
          },
          {
            label: 'Failed',
            value: 'failed',
          },
          {
            label: 'Pending',
            value: 'pending',
          },
        ],
      },
    },
  });

  return (
    <DashboardWrap>
      <div className="settlement-merchant">
        <DashboardHeader
          title="Settlements Overview"
          smallSubTitle=""
          filterObj={filterObj}
          exportObj={{
            handleExport,
          }}
        />
        <div className="settlement-card-covers">
          {details?.map?.(({
            name, value, Icon, colored,
          }, id) => (

            <SettlementFlashCardNew
              name={name}
              key={`${name}-${id}`}
              value={value}
              Icon={Icon}
              colored={colored}
            />
          ))}
        </div>
        <div className="settlement-table-cover">

          <DashboardHeader
            title="All Settlements"
            smallSubTitle={`(${settlements?.getSettlementsByMerchantId?.count})`}
            searchPlaceholder="Look for a settlement"
            search={search}
            onSearchChange={onSearchChange}
            searchLoading={getSettlementsLoading}
          />

          { settlements?.getSettlementsByMerchantId?.count ? (
            <Table
              checked={checked}
              setChecked={setChecked}
              refreshing={getSettlementsLoading}
              count={settlements?.getSettlementsByMerchantId?.count}
              contents={cleanUpSettlements(
                settlements?.getSettlementsByMerchantId?.settlements,
              )}
              reference="id"
              hideDominantImage
              clickable
              page={page}
              setPage={setPage}
              minHeight="calc(100vh - 470px)"
              handleClick={(data) => push(`/settlement/transactions/${data?.id}`)}
              allowKeyArr={[
                ['date', 'time'],
                'settlementReference',
                'amount',
                'fees',
                'commission',
                'amountToBeSettled',
                'status',
              ]}
              mobileAllowKeyArr={[
                'status',
                [
                  'amountToBeSettled', 'fees'],
                [
                  'settlementReference',
                  'date', 'time',
                ],
              ]}
              pageSize={limit}
              formatLabels={{
                date: 'Created On',
                settlementReference: 'Reference',
                amount: 'Sales',
                fees: 'Charges',
                amountToBeSettled: 'Amount Settled',
              }}
              emptyCompObj={{
                title: 'You have no settlement yet',
                text1: "We haven't paid any money to this account.",
                text2:
              "You'll be able to see your settlements and the transactions you were paid for",
              }}
            />
          ) : (
            <EmptyRecord
              height="calc(100vh - 470px)"
              title="No Settlements Yet"
              main="All your settlements will appear here T+1"
              // footer="Recieve Payments"
              Icon={EmptySettlementIcon}
              loading={getSettlementsLoading}
            />
          )}
        </div>

      </div>
    </DashboardWrap>
  );
};

export default Settlements;
