import React from 'react';

const EmptyImageIcon = () => (
  <svg width="40" height="40" viewBox="0 0 132 132" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M95.1808 3.58398H36.7073C16.3511 3.58398 3.58447 18.0064 3.58447 38.4167V93.4705C3.58447 113.881 16.297 128.303 36.7073 128.303H95.147C115.591 128.303 128.297 113.881 128.297 93.4705V38.4167C128.324 18.0064 115.611 3.58398 95.1808 3.58398Z" stroke="#9AA1B1" strokeWidth="6.75838" strokeLinecap="round" strokeLinejoin="round" />
    <path fillRule="evenodd" clipRule="evenodd" d="M57.3367 44.3725C57.3367 51.266 51.7543 56.8484 44.8607 56.8484C37.9739 56.8484 32.3848 51.266 32.3848 44.3725C32.3848 37.4789 37.9739 31.8965 44.8607 31.8965C51.7475 31.9032 57.3299 37.4857 57.3367 44.3725Z" stroke="#9AA1B1" strokeWidth="6.75838" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M128.325 86.0446C122.087 79.6241 110.091 66.6548 97.0475 66.6548C83.9971 66.6548 76.475 95.263 63.9247 95.263C51.3744 95.263 39.973 82.3275 29.9165 90.62C19.86 98.9058 10.3442 115.849 10.3442 115.849" stroke="#9AA1B1" strokeWidth="6.75838" strokeLinecap="round" strokeLinejoin="round" />
  </svg>

);

export default EmptyImageIcon;
