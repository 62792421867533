import React from 'react';
import { useHistory } from 'react-router-dom';
import GoBackIcon from '../../svgs/GoBackIcon';
import NewTableFilters from '../FilterComponents/NewTableFilters';
import CreateManualLoanAndMore from '../CreateManualLoanAndMore';
// import TableFilters from '../FilterInputs/TableFilters';
import SearchBox from '../SearchBox';
import StyledButton from '../StyledButton';
import './DashboardHeader.scss';

type Props = {
  title?: any;
  smallSubTitle?: string;
  searchPlaceholder?: string;
  filterObj?: any;
  exportObj?: any;
  children?:any
  subtitle?: any;
  hasButton?:boolean;
  handleButtonAction?:any;
  buttonName?:string;
  width?:string;
  buttonIcon?:any;
  search?: string;
  onSearchChange?:any;
  searchLoading?: boolean;
  specialClass?:string;
  hideButtonClass?:string;
  isDashboardChildren?:any;
  columnObject?:any;
  hasBackButton?:boolean;
  disableExport?:boolean;
  disableSearch?:boolean;
  disbleFilter?:boolean;
  createManualLoanAndMore?: boolean;
  payload?: any;
  refetch?: any;
  hasAlternativeDate?:boolean
}

const DashboardHeader = ({
  title,
  smallSubTitle,
  searchPlaceholder,
  exportObj,
  children,
  subtitle,
  hasButton,
  handleButtonAction,
  buttonName,
  width,
  buttonIcon,
  search,
  onSearchChange,
  // searchLoading,
  filterObj,
  specialClass,
  hideButtonClass,
  isDashboardChildren,
  columnObject,
  hasBackButton,
  disableExport,
  disableSearch,
  createManualLoanAndMore,
  disbleFilter,
  payload,
  refetch,
  hasAlternativeDate,
}: Props) => {
  // const name = '';
  // eslint-disable-next-line implicit-arrow-linebreak
  const { goBack } = useHistory();
  return (
    <div className={specialClass || 'dashboard-header'}>

      <div className="title-and-search-wrap">

        { title && (
        <div className="title-wrap">
          <div className="title-cover">

            <h5>
              {hasBackButton && (
              <span>
                <GoBackIcon color="black" onClick={() => goBack()} />
              </span>
              )}

              {title}

            </h5>
            <small>{smallSubTitle}</small>
          </div>
          {subtitle && <span className="subtitle">{subtitle}</span>}
        </div>
        )}
        {searchPlaceholder && (
        <div className={children ? 'input-with-export-button' : 'input-with-no-export-button'} style={{ width: '65%' }}>
          <SearchBox disabled={disableSearch} loading={false} name="search" placeholder={searchPlaceholder} value={search} handleChange={onSearchChange || (() => {})} />
        </div>
        )}
      </div>
      {
        !isDashboardChildren && (
        <div className={children ? 'children-and-export-button' : 'no-children-with-export-button'}>
          <div className="children-btn-on-mobile">
            {children}
          </div>
          <div className="export-and-filter">
            {createManualLoanAndMore ? (
              <CreateManualLoanAndMore
                filterObj={filterObj}
                exportObj={exportObj}
                columnObject={columnObject}
                disableExport={disableExport}
                disbleFilter={disbleFilter}
                payload={payload}
                refetch={refetch}
              />
            )
              : (
                <NewTableFilters
                  filterObj={filterObj}
                  exportObj={exportObj}
                  columnObject={columnObject}
                  disableExport={disableExport}
                  disbleFilter={disbleFilter}
                  hasAlternativeDate={hasAlternativeDate}
                />
              )}
          </div>
          <div className="children-btn-on-desktop">
            {' '}
            {children}
          </div>
        </div>
        )
      }

      {isDashboardChildren && children}
      {
        hasButton && <StyledButton hideButtonClass={hideButtonClass} name={buttonName} onClick={handleButtonAction} width={width || '161px'} borderRadius="4px" height="40px" border="none" backgroundColor="#523B9F" color="white" fontSize="13px" icon={buttonIcon} />
      }

    </div>

  );
};
export default DashboardHeader;
