import React from 'react';
import Loading from './Loading';
import './RefreshingSticker.scss';

const RefreshingSticker = () => (
  <div className="refreshingsticker flexed-row">
    <Loading color="#5941A9" />
    <span>Refreshing...</span>
  </div>
);

export default RefreshingSticker;
