import React from 'react';
import UseCustomResizer from '../CustomResizer';
import './Buttons.scss';

interface Props {
  onClick?: any;
  name?: string;
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset';
  className?: string;
  btnStyle?: string;
  Icon?: any;
  optional?:any;
  hasOptionalColor?:any;
  EndIcon?: any;
}

const Buttons = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  className, name, Icon, btnStyle, optional, hasOptionalColor, EndIcon, disabled, ...rest
}: Props) => {
  const { isMobile } = UseCustomResizer({ width: 500 });
  return (
    optional && isMobile ? (
      <button
        {...rest}
        className="ismobile-filter"
        style={{
          background: hasOptionalColor,
          opacity: disabled ? 0.7 : 1,
          cursor: disabled ? 'not-allowed' : 'pointer',
        }}
      >
        {' '}
        {Icon && (
        <div>
          <Icon />
        </div>
        )}
      </button>
    ) : (
      <button
        {...rest}
        className={`app-buttons flexed-center ${className || ''} ${optional}`}
        style={{
          opacity: disabled ? 0.7 : 1,
          cursor: disabled ? 'not-allowed' : 'pointer',
        }}
      >
        {Icon && (
        <div className="mr-2">
          <Icon />
        </div>
        )}
        <span>{name}</span>
        {
          EndIcon
          && (
            <div className="ml-2">
              <EndIcon />
            </div>
          )
        }
      </button>
    )
  );
};

export default Buttons;
