import React from 'react';

// type Props = {
//  color?: string;
// }

const LinkIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M13.0023 1.38503C13.459 1.25218 13.9537 1.38033 14.289 1.72185C14.6243 2.0627 14.7483 2.55988 14.6117 3.02016L13.779 5.82142C13.6996 6.08779 13.4223 6.23876 13.157 6.15958C12.8923 6.07974 12.7416 5.79928 12.8209 5.53358L13.6536 2.73164C13.7003 2.57464 13.617 2.46863 13.579 2.43038C13.541 2.39147 13.4343 2.30693 13.2803 2.35188L2.55259 5.47118C2.38192 5.52083 2.34458 5.66374 2.33658 5.72279C2.32925 5.78183 2.32658 5.92944 2.47859 6.02338L4.736 7.41227C4.97134 7.55719 5.04601 7.86718 4.90134 8.1047C4.80733 8.25902 4.64333 8.3449 4.47466 8.3449C4.38599 8.3449 4.29598 8.32142 4.21465 8.2711L1.95724 6.88154C1.50989 6.60644 1.27521 6.11127 1.34521 5.58859C1.41522 5.06525 1.7719 4.64992 2.27525 4.50365L13.0023 1.38503ZM12.0185 8.23299C12.0985 7.96594 12.3765 7.81431 12.6411 7.89482C12.9058 7.97467 13.0565 8.25513 12.9772 8.5215L11.4291 13.7308C11.2791 14.2347 10.8644 14.589 10.3464 14.6554C10.2884 14.6635 10.2311 14.6668 10.1737 14.6668C9.7217 14.6668 9.30836 14.4347 9.06768 14.0361L6.33425 9.50982C6.21492 9.31122 6.24492 9.05625 6.40826 8.89254L10.2891 4.98688C10.4844 4.79029 10.8004 4.79029 10.9957 4.98688C11.1911 5.18347 11.1911 5.50218 10.9957 5.69877L7.39296 9.32531L9.92238 13.5134C10.0144 13.6658 10.1591 13.6644 10.2191 13.6577C10.2777 13.6497 10.4197 13.6134 10.4704 13.443L12.0185 8.23299Z" fill="#475467" />
  </svg>

);

export default LinkIcon;
