import React, { useState } from 'react';
import CopyIcon from '../../../svgs/CopyIcon';
import './APICopyComp.scss';

interface IAPICopyComp{
  handleCopy?:any;
  title?:string;
  actionTitle?:string;
  hasAction?:boolean;
  apiKey?:any;
  handleReset?:any;
}
const APICopyComp = ({
  handleCopy, title, actionTitle, hasAction, apiKey, handleReset,
}:IAPICopyComp) => {
  const [showCopyIcon, setshowCopyIcon] = useState(false);
  return (
    <div className="api-copy-comp-cover">
      <div className="api-copy-comp-cover-title-and-action-cover">
        <div className="api-copy-comp-cover-title-and-action-cover-title">{title}</div>
        {
          hasAction && <div className="api-copy-comp-cover-title-and-action-cover-reset" onClick={handleReset}>{actionTitle}</div>
        }

      </div>
      <div className="api-copy-comp-cover-key-and-copy-cover" onMouseEnter={() => setshowCopyIcon(true)} onMouseLeave={() => setshowCopyIcon(false)}>
        <div className="api-copy-comp-cover-key-and-copy-cover-key-cover">{apiKey}</div>
        {showCopyIcon && (
        <div className="api-copy-comp-cover-key-and-copy-cover-copy-cover" onClick={() => handleCopy(apiKey, title)}>
          Copy
          {' '}
          <CopyIcon color="#523B9F" width="16px" height="16px" />
        </div>
        )}
      </div>
    </div>
  );
};
export default APICopyComp;
