import { CircularProgress, FormControl } from '@material-ui/core';
import { FunctionComponent, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './ResetPassword.scss';
import { useMutation } from '@apollo/client';
import TextInput from '../../components/input';
import { RESET_PASSWORD } from '../../graphql/mutations';
import useToast from '../../customHooks/useToast';
import LightShield from '../../svgs/LightShield';
import EmailIcon from '../../svgs/EmailIcon';
import AppOverLay from '../../components/AppOverLay';
import ResetPasswordModal from '../../components/ResetPasswordModal';
import { useGlobalDispatch, useGlobalState } from '../../contexts';

export interface ILogin {
  label?: string;
}

const ResetPassword: FunctionComponent<ILogin> = () => {
  // const { push } = useHistory();
  const { showResetPasswordModal }:any = useGlobalState();
  const { setShowResetPasswordModal }:any = useGlobalDispatch();
  const [merchantResetPassword] = useMutation<
    { merchantForgotPassword: any; resetPasswordInfo: any },
    any
  >(RESET_PASSWORD);
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [resendLoading, setResendLoading] = useState(false);
  const Toast = useToast();

  useEffect(() => {
    setError('');
  }, [email]);
  // useEffect(() => {
  //   setShowResetPasswordModal(false);
  // }, [showResetPasswordModal]);

  const handleOnSubmit: (arg: any) => void = async (e) => {
    e.preventDefault();
    setLoading(true);
    setResendLoading(true);
    try {
      const payload: { email: string } = {
        email,
      };

      const resp = await merchantResetPassword({ variables: payload });
      setLoading(false);
      setResendLoading(false);

      if (
        resp?.data?.merchantForgotPassword
        && resp?.data?.merchantForgotPassword?.status === 200
      ) {
        Toast.success('Password reset is successful');
        setShowResetPasswordModal(true);
        // push('/password-reset');
      } else {
        Toast.error(
          resp?.data?.merchantForgotPassword?.message
            || 'Something went wrong. Try again.',
        );
      }
    } catch (e) {
      setLoading(false);
      if (e && e.graphQLErrors && e.graphQLErrors.length > 0) {
        Toast.error(e.graphQLErrors[0].message);
      } else {
        Toast.error('Something went wrong');
      }
    }
  };

  return (
    <div className={showResetPasswordModal ? 'overlay-view-cover' : 'password-reset'}>
      {
        // eslint-disable-next-line max-len
        showResetPasswordModal && <AppOverLay><ResetPasswordModal email={email} handleResend={handleOnSubmit} resendLoading={resendLoading} /></AppOverLay>
      }
      <form
        onSubmit={handleOnSubmit}
        className="password-reset-view app-max-width"
      >
        <div className="password-reset-view-cover container col-lg-4 col-md-6 col-12 p-0 m-0">
          <div className="password-reset-view-cover-logo">
            <div className="password-reset-view-cover-logo-div">
              <LightShield />
            </div>
          </div>
          <div className="password-reset-view-cover-title">
            Forgot your password?
          </div>
          <div className="password-reset-view-cover-subtitle">
            <div>
              {' '}
              Please, enter the email address associated with your Payfi account.

            </div>
          </div>

          <FormControl className="password-reset-view-cover-form">
            <TextInput label="Email Address" width="100%" onChange={setEmail} preIcon={<EmailIcon />} paddingLeft="44px" />

            {!!error && (
            <span className="error-flag" style={{ width: 280 }}>
              {error}
            </span>
            )}
            <button className="form-btn bg-primary text-white rounded">
              {loading ? (
                <CircularProgress color="inherit" size={15} />
              ) : (
                'Request Reset Link'
              )}
            </button>
          </FormControl>

          <div className="password-reset-view-cover-account">
            Remeber your password?
            {' '}
            <Link to="/" className="password-reset-view-cover-account-link">Login</Link>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ResetPassword;
