import React from 'react';

const NewLogoutIcon = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.4314 4.42569V3.93562C8.4314 2.86669 7.5647 2 6.49578 2H3.93509C2.86669 2 2 2.86669 2 3.93562V9.78187C2 10.8508 2.86669 11.7175 3.93509 11.7175H6.50103C7.56681 11.7175 8.4314 10.8534 8.4314 9.78764V9.29232" stroke="#B42318" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M11.9996 6.85834H5.6748" stroke="#B42318" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M10.4619 5.32715L11.9999 6.85831L10.4619 8.38999" stroke="#B42318" strokeLinecap="round" strokeLinejoin="round" />
  </svg>

);
export default NewLogoutIcon;
