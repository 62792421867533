/* eslint-disable react/function-component-definition */
import React from 'react';

interface IFreezeIcon{
  width?:string;
  height?:string;
  color?:string;
}
export default function FreezeIcon({ width, height, color }:IFreezeIcon) {
  return (
    <svg width={width || '10'} height={height || '11'} viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M7.45266 8.66006C6.77534 9.18651 5.92428 9.5 5 9.5C2.79086 9.5 1 7.70914 1 5.5C1 4.57572 1.31349 3.72466 1.83994 3.04734L7.45266 8.66006ZM8.1598 7.95299L2.54701 2.3402C3.22438 1.81359 4.07557 1.5 5 1.5C7.20914 1.5 9 3.29086 9 5.5C9 6.42443 8.68641 7.27562 8.1598 7.95299ZM10 5.5C10 8.26142 7.76142 10.5 5 10.5C2.23858 10.5 0 8.26142 0 5.5C0 2.73858 2.23858 0.5 5 0.5C7.76142 0.5 10 2.73858 10 5.5Z" fill={color || '#121212'} />
    </svg>

  );
}
