import { useMutation } from '@apollo/client';
import { FunctionComponent, useState } from 'react';
// import './AddNewAccount.scss';
import useToast from '../../../customHooks/useToast';
import {
  DELETE_BANK_DETAILS,
} from '../../../graphql/mutations';

import ModalFormComp from '../../../components/ModalFormComp';

export interface IBank {
  accountName: string;
  accountNumber: string;
  bankId: string;
  alias: string;
  id: string;
  isPrimary: string;
}

export interface IDeleteAccount {
  label?: string;
  closeModal?: any;
  refetch?: any;
  payload?: IBank;
}

const DeleteAccount: FunctionComponent<IDeleteAccount> = ({
  closeModal,
  refetch,
  payload,
}) => {
  const Toast = useToast();

  const [loading, setLoading] = useState(false);

  const [deleteAccountDetails] = useMutation<
    { createSaving: any; deleteMerchantBankDetail: any },
    any
  >(DELETE_BANK_DETAILS);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const resp = await deleteAccountDetails({
        variables: { id: payload?.id },
      });
      if (resp?.data?.deleteMerchantBankDetail?.status !== 200) {
        throw new Error(
          resp?.data?.deleteMerchantBankDetail?.message || 'Operation failed',
        );
      }
      Toast.success('Account Deleted successfully');
      refetch();
      closeModal();
    } catch (e) {
      if (e && e.graphQLErrors && e.graphQLErrors.length > 0) {
        Toast.error(e.graphQLErrors[0].message);
      } else {
        Toast.error(e.message || 'Operation failed');
      }
    }
    setLoading(false);
  };

  return (
    <div className="delete-bank-details">
      <ModalFormComp
        title="Delete Bank Detail"
        btnAction={handleSubmit}
        btnLoading={loading}
        btnName={loading ? 'Confirming...' : 'Confirm'}
        closeModal={closeModal}
        cancleBtnName="Cancel"
        hasCancel
      >
        <div className="modal-message">
          <span>Are you sure you want to proceed?</span>
        </div>
      </ModalFormComp>
    </div>
  );
};

export default DeleteAccount;
