import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import DashboardHeader from '../../components/DashboardHeader';
import EmptyRecord from '../../components/EmptyRecord';
import Table from '../../components/Table';
import useGetQueryParams from '../../customHooks/useGetQueryParams';
import { GET_PAYMENT_HISTORY } from '../../graphql/queries';
import useCustomQuery from '../../network/useCustomQuery';
import { cleanUpPaymentLinkHistories } from '../../utils/helpers';
import AdditionalInfo from './AdditionalIfo';

const limit = 10;
const PaymentHistory = ({ paymentLinkName }) => {
  const { getQuery } = useGetQueryParams();
  const page = Number(getQuery('page'));
  const search = getQuery('search') || '';
  const [showAdditionalInfo, setShowAdditionalInfo] = useState(false);
  const [payload, setPayload] = useState({});
  const [getRecentPaymentHistory, paymentHistoryLoading, rawData] = useCustomQuery({
    query: GET_PAYMENT_HISTORY,
    onError: () => { },
    onData: () => { },
  });
  const [paymentHistory, setPaymentHistory] = useState(null);
  useEffect(() => {
    getRecentPaymentHistory({
      variables: {
        name: paymentLinkName,
        limit,
        offset: (page - 1) * limit,
        search,
      },
    });
  }, [page, search]);

  useEffect(() => {
    if (rawData?.getPaymentlinkHistory?.rows) {
      setPaymentHistory(rawData);
    }
  }, [rawData]);

  const { push } = useHistory();
  const setPage = (pageNum) => {
    push(`/payment-link/${paymentLinkName}?page=${pageNum}&search=${search}`);
  };
  if (!page) {
    push(`/payment-link/${paymentLinkName}?page=1&search=${search}`);
  }

  const onSearchChange = (e) => {
    push(`/payment-link/${paymentLinkName}?page=1&search=${encodeURIComponent(e.target.value)}`);
  };

  return (
    <div className="payment-link-history">
      <DashboardHeader
        title="Recent Payment"
        smallSubTitle={`(${paymentHistory?.getPaymentlinkHistory.count || 0})`}
        searchPlaceholder="search by name, email address and phone number"
        search={search}
        onSearchChange={onSearchChange}
        searchLoading={paymentHistoryLoading}
      />
      {paymentHistory?.getPaymentlinkHistory.count ? (
        <Table
          refreshing={paymentHistoryLoading}
          page={page}
          setPage={setPage}
          pageSize={limit}
          minHeight="calc(100vh - 450px)"
          count={paymentHistory?.getPaymentlinkHistory.count}
          contents={cleanUpPaymentLinkHistories(paymentHistory?.getPaymentlinkHistory.rows)}
          reference="id"
          hideDominantImage
          allowKeyArr={[
            'name',
            'email',
            'amount',
            'status',
            ['date', 'time'],
            'link',
          ]}
          mobileAllowKeyArr={
            [
              'status',
              ['name',
                'email'],
              ['amount', 'date', 'time'],
            ]
          }
          viewLinkFunc={(data: any) => {
            setPayload(data);
            setShowAdditionalInfo(true);
          }}
          viewLinkText="View Additional Information"
          formatLabels={{
            amount: 'Amount',
            date: 'Create On',
            link: 'Additional Information',
          }}
        />
      ) : (
        <EmptyRecord
          height="calc(100vh - 450px)"
          title="Start by activating your link"
          main="A payment link is an easier way to receive payments from your customers by sharing personalized link with them. "
          footer="payment history"
        />
      ) }
      {showAdditionalInfo && (
      <AdditionalInfo
        closeModal={() => setShowAdditionalInfo(false)}
        payload={payload}
      />
      )}
    </div>
  );
};

export default PaymentHistory;
