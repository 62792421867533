import { FunctionComponent, useState, useEffect } from 'react';
import { FormControl, CircularProgress } from '@material-ui/core';
import { useMutation } from '@apollo/client';
import { Link, useHistory } from 'react-router-dom';
import TextInput from '../../components/input';
import CountryPhone from '../../components/CountryPhone';
import { useGlobalDispatch } from '../../contexts/globalContext';
import EmailIcon from '../../svgs/EmailIcon';
import PasswordShield from '../../svgs/PasswordShield';
import { IMerchant, ICreateMerchantRequest } from '../../interfaces/merchant';
import { CREATE_MERCHANT, RESEND_EMAIL } from '../../graphql/mutations';
import useToast from '../../customHooks/useToast';
import CheckboxNew from '../../components/CheckboxNew';
import useForm from '../../customHooks/useForm';
import SelectSearch from '../../components/SelectSearch';
import { setItemsToLocalStorage } from '../../utils/helpers';

const RegistrationForm:FunctionComponent = () => {
  const [createMerchant] = useMutation<
        { createSaving: IMerchant },
        ICreateMerchantRequest
      >(CREATE_MERCHANT);
  const [resendEmail] = useMutation<
      { createSaving: any; merchantResendEmailInvite: any },
      { code: string }
    >(RESEND_EMAIL);
  const { clearUser } = useGlobalDispatch();
  const [loading, setLoading] = useState(false);
  const [storeType, setStoreType] = useState('');
  const [email, setEmail] = useState('');
  const [country] = useState('NGN');

  const { formValues, resetForm, handleChange } = useForm({
    firstName: '',
    lastName: '',
    businessName: '',
    email: '',
    phone: '',
    password: '',
    terms: false,
  });
  const Toast = useToast();
  const { push } = useHistory();

  async function handleResend() {
    if (loading) return;
    setLoading(true);
    try {
      const resp = await resendEmail();
      setLoading(false);
      if (resp?.data?.merchantResendEmailInvite?.status === 200) {
        // Toast.success("Account successfully created. Please Login with your details");
        Toast.success(
          `Verification code resent successfully to ${email}. Please verify`,
        );
        push('/verify-email');
        setItemsToLocalStorage('verifyEmail', email);

        setStoreType('');
        resetForm({
          firstName: '',
          lastName: '',
          businessName: '',
          email: '',
          phone: '',
          password: '',
          terms: false,
        });
      }
    } catch (e) {
      setLoading(false);
      if (e && e.graphQLErrors && e.graphQLErrors.length > 0) {
        Toast.error(e.graphQLErrors[0].message);
      } else {
        Toast.error('Something went wrong');
      }

      setStoreType('');
      resetForm({
        firstName: '',
        lastName: '',
        businessName: '',
        email: '',
        phone: '',
        password: '',
        terms: false,
      });
    }
  }

  useEffect(() => {
    clearUser();
  }, []);
  useEffect(() => {
    setEmail(formValues?.email);
  }, [formValues?.email]);
  const handleOnSubmit = async () => {
    setLoading(true);
    if (
      !formValues?.firstName
          || !formValues?.lastName
          || !formValues?.businessName
          || !formValues?.email
          || !formValues?.password
          || !formValues?.phone
          || !storeType
    ) {
      Toast.error('Please complete all fields');
      setLoading(false);

      setStoreType('');
      resetForm({
        firstName: '',
        lastName: '',
        businessName: '',
        email: '',
        phone: '',
        password: '',
        terms: false,
      });
      return;
    }

    const mail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!mail.test(formValues?.email)) {
      Toast.error('Not a valid email');
      setLoading(false);
      return;
    }

    // const numberPhone = /^(\s*-?\d+(\.\d+)?)(\s*,\s*-?\d+(\.\d+)?)*$/;
    const numberPhone = /^[6-9]\d{9}$/;
    if (!numberPhone.test(`${Number(formValues?.phone)}`) && formValues?.phone !== '') {
      Toast.error('Not a valid phone number');
      setLoading(false);
      return;
    }

    if (
      formValues?.password.length < 8
          || !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/.test(formValues?.password)
    ) {
      Toast.error(
        'Password must contain at least 8 characters, 1 uppercase, 1 lowercase, 1 number and 1 special character',
      );
      setLoading(false);
      return;
    }

    if (
      !formValues?.terms
    ) {
      Toast.error('You must agree to the Terms of Service and Privacy Policy of Payfi');
      setLoading(false);
      return;
    }

    try {
      const payload = {
        country,
        fullName: `${formValues?.firstName} ${formValues?.lastName}`,
        businessName: formValues?.businessName,
        email: formValues?.email,
        password: formValues?.password,
        phone: `+234${Number(formValues?.phone)}`,
        storeType,
      };

      const resp: any = await createMerchant({ variables: payload });
      setLoading(false);
      if (resp?.data?.createMerchant?.token) {
        localStorage.token = resp?.data?.createMerchant?.token;
        // set verification email
        handleResend();
      }
    } catch (e) {
      setLoading(false);
      if (e && e.graphQLErrors && e.graphQLErrors.length > 0) {
        Toast.error(e.graphQLErrors[0].message);
      } else {
        Toast.error('Something went wrong');
      }
      setStoreType('');
      resetForm({
        firstName: '',
        lastName: '',
        businessName: '',
        email: '',
        phone: '',
        password: '',
        terms: false,
      });
    }
  };
  return (

    <div>
      <FormControl className="register-page-cover-row-form-cover">
        <div className="register-page-cover-row-form-cover-title">Get Started with Payfi</div>
        <div className="register-page-cover-row-form-cover-subtitle">Create a Payfi account for your business.</div>
        <div className="col-12 mobile-phone-input-div">
          <div className="phone-input-comp" style={{ width: '100%' }}>
            <CountryPhone />
            <TextInput
              customClasses="country-phone-inp"
              label="Enter Phone Number"
              width="100%"
              customOnChange={handleChange}
              name="phone"
              value={formValues.phone?.trim?.()}
            />
          </div>

        </div>
        <div className="row register-page-cover-row-form-cover-row">
          <div className="col-12">
            {' '}
            <TextInput
              label="Business Name"
              width="100%"
              paddingLeft="16px"
              customOnChange={handleChange}
              name="businessName"
              value={formValues.businessName}
            />

          </div>
          <div className="col-12">
            <TextInput
              label="Business Email Address"
              width="100%"
              preIcon={<EmailIcon />}
              paddingLeft="44px"
              customOnChange={handleChange}
              name="email"
              value={formValues.email?.trim()}
            />
          </div>
          <div className="col-12" style={{ marginBottom: '20px' }}>
            <SelectSearch
              label=""
              options={[{ value: 'Online Store', text: 'Online Store', label: 'Online Store' }, { value: 'Offline Store', text: 'Offline Store', label: 'Offline Store' }]}
              placeholder="Store type"
              name="bankId"
              value={storeType}
              required={false}
              classes={false}
              hideLabel
              onChange={(evt) => setStoreType(evt?.target?.value)}
            />
          </div>
          <div className="col-6">
            <TextInput
              label="First Name"
              width="100%"
              paddingLeft="16px"
              customOnChange={handleChange}
              name="firstName"
              value={formValues.firstName}
            />

          </div>
          <div className="col-6">
            <TextInput
              label="Last Name"
              width="100%"
              customOnChange={handleChange}
              name="lastName"
              value={formValues.lastName}
            />

          </div>
        </div>
        <div className="col-12 desktop-phone-input-div">
          <div className="phone-input-comp" style={{ width: '100%' }}>
            <CountryPhone />
            <TextInput
              type="tel"
              customClasses="country-phone-inp"
              label="Enter Phone Number"
              width="100%"
              name="phone"
              customOnChange={handleChange}
              value={formValues.phone?.trim?.()}
            />
          </div>

        </div>
        <div className="col-12">
          <TextInput
            label="Password"
            isSecure
            width="100%"
            preIcon={<PasswordShield />}
            paddingLeft="44px"
            customOnChange={handleChange}
            name="password"
            value={formValues.password}
          />
        </div>
        <div className="register-page-cover-row-form-cover-terms">
          <span>
            <CheckboxNew
              id="terms"
              onChange={(e) => {
                handleChange({
                  target: {
                    name: 'terms',
                    value: e.target.checked,
                  },
                });
              }}
              name="terms"
              value={!!formValues?.terms}
              loading={false}
              onClick={() => {}}
            />
          </span>
          <span>
            {' '}
            I agree to the
            <Link to={{ pathname: 'https://payfi.ng/terms-of-use' }} target="_blank" className="register-page-cover-row-form-cover-terms-link">Terms of Service</Link>
            and
            <Link to={{ pathname: 'https://payfi.ng/privacy' }} target="_blank" className="register-page-cover-row-form-cover-terms-link">Privacy Policy</Link>
            of Payfi

          </span>
        </div>
        <div className="col-12 register-page-cover-row-form-cover-button">
          <button
            className="btn btn-primary"
            onClick={handleOnSubmit}
          >
            {loading ? (
              <CircularProgress color="inherit" size={15} />
            ) : (
              'Create Account'
            )}
          </button>
        </div>
        {/* <div className="mobile-terms-and-condition">
          By clicking on the “Create Account” button, you
          indicate that you agree to the
          {' '}
          <span><Link to={{ pathname: 'https://payfi.ng/terms-of-use' }} target="_blank" style={{ color: '#98a2b3' }}>Terms of Service</Link></span>
          {' '}
          and
          {' '}
          <span><Link to={{ pathname: 'https://payfi.ng/privacy' }} target="_blank" style={{ color: '#98a2b3' }}>Privacy Policy</Link></span>
        </div> */}
      </FormControl>
    </div>
  );
};
export default RegistrationForm;
