import { FunctionComponent, useEffect, useState } from 'react';
import { FormControl, CircularProgress } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import './Login.scss';
import TextInput from '../../components/input';
import EmailIcon from '../../svgs/EmailIcon';
import PasswordShield from '../../svgs/PasswordShield';
import { LOGIN_MERCHANT } from '../../graphql/mutations';

import {
  ILoginMerchantRequest,
  ILoginResponse,
} from '../../interfaces/merchant';
import {
  useGlobalDispatch,
} from '../../contexts/globalContext';
import useToast from '../../customHooks/useToast';
import LoginContComp from './LoginContComp';
import useCustomQuery from '../../network/useCustomQuery';
import { GET_PROFILE } from '../../graphql/queries';
import useForm from '../../customHooks/useForm';
import { setItemsToLocalStorage } from '../../utils/helpers';

export interface ILogin {
  label?: string;
}

const Login: FunctionComponent<ILogin> = () => {
  const { push } = useHistory();
  const {
    setToken, setUser, clearUser, clearStep,
  } = useGlobalDispatch();

  const [loginMerchant] = useMutation<
    { merchantLogin: ILoginResponse },
    ILoginMerchantRequest
  >(LOGIN_MERCHANT);
  const [getProfileQuery, profileData] = useCustomQuery({
    query: GET_PROFILE,
    onError: (err: any) => {
      Toast.error('Failed to get profile details');
      if (err) push('/');
    },
    onData: () => {},
  });
  const [loading, setLoading] = useState(false);
  const [hasToken, setHasToken] = useState(false);
  const Toast = useToast();

  useEffect(() => {
    clearUser();
    clearStep();
  }, []);
  const {
    formValues,
    handleChange,
    resetForm,
  } = useForm(
    {
      email: '',
      password: '',
    },
  );

  const handleOnSubmit: (arg: any) => void = async (e) => {
    e.preventDefault();
    setLoading(true);
    setItemsToLocalStorage('verifyEmail', formValues?.email);
    try {
      const payload = {
        email: formValues?.email,
        password: formValues?.password,
      };

      const resp = await loginMerchant({ variables: payload });
      setLoading(false);
      if (resp && resp.data && resp.data.merchantLogin) {
        resetForm({
          password: '',
          email: '',
        });
        if (parseInt(resp.data.merchantLogin.status, 10) !== 200) {
          // setError(resp.data.merchantLogin.message);
          Toast.error(resp.data.merchantLogin.message);

          return;
        }
        if (resp.data.merchantLogin.token) {
          setToken(resp.data.merchantLogin.token);
          setUser(resp.data.merchantLogin);
          Toast.success('Login successfully');
          getProfileQuery();
          setHasToken(true);
          return;
        }
        Toast.error('Something went wrong. Try again or contact Support');
      }
    } catch (e) {
      setLoading(false);
      if (e && e.graphQLErrors && e.graphQLErrors.length > 0) {
        const { message } = e.graphQLErrors[0];
        const newMessage = message || 'Invalid credentials';
        Toast.error(newMessage);
        // Toast.error(e.graphQLErrors[0].message)
      } else {
        Toast.error('Something went wrong. Try again or contact Support');
      }
      resetForm({
        password: '',
        email: '',
      });
    }
  };
  useEffect(() => {
    if (hasToken) {
      if (profileData?.getMerchantUserDetail?.data?.merchant?.isApproved) {
        push('/dashboard');
      } else {
        push('/home');
      }
    }
  }, [profileData]);

  return (
    <div className="login-form-view">
      <form className="login-form-view-cover app-max-width" onSubmit={handleOnSubmit}>
        <div className="login-form-view-cover-row row">
          <LoginContComp />
          <div className="col-md-6 col-12 login-form-view-cover-row-form">
            <FormControl className="login-form-view-cover-row-form-cover">
              <div className="login-form-view-cover-row-form-cover-title">Login to Payfi</div>
              <div className="login-form-view-cover-row-form-cover-subtitle">Login to access your account.</div>

              <TextInput
                customOnChange={handleChange}
                name="email"
                value={formValues.email}
                label="Email Address"
                width="100%"
                preIcon={<EmailIcon />}
                paddingLeft="44px"
              />
              <TextInput
                label="Password (8+ characters)"
                isSecure
                width="100%"
                customOnChange={handleChange}
                name="password"
                value={formValues.password}
                preIcon={<PasswordShield />}
                paddingLeft="44px"
              />
              <div className="login-form-view-cover-row-form-cover-forget">
                <span onClick={() => push('/forgot-password')} className="pointer">Forgot Password?</span>
              </div>
              <button className="mb-2 form-btn bg-primary text-white rounded">
                {loading ? (
                  <CircularProgress color="inherit" size={15} />
                ) : (
                  'Login'
                )}
              </button>
              <div className="login-form-view-cover-row-form-cover-create">
                Don’t have an account?
                {' '}
                <span onClick={() => push('/register')}>Create one</span>
              </div>
            </FormControl>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Login;
