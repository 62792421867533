import { FunctionComponent } from 'react';

const ActivityCheckIcon: FunctionComponent = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 2C6.486 2 2 6.486 2 12C2 17.514 6.486 22 12 22C17.514 22 22 17.514 22 12C22 6.486 17.514 2 12 2ZM10.001 16.413L6.288 12.708L7.7 11.292L9.999 13.587L15.293 8.293L16.707 9.707L10.001 16.413Z"
      fill="url(#paint0_linear_2076_4384)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_2076_4384"
        x1="22.643"
        y1="18.4364"
        x2="2"
        y2="2"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#37DEA5" />
        <stop offset="1" stopColor="#00C48C" />
      </linearGradient>
    </defs>
  </svg>
);

export default ActivityCheckIcon;
