import { IconButton, InputAdornment, TextField } from '@material-ui/core';
// @ts-ignore
import NumberFormat from 'react-number-format';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { FunctionComponent, useState, forwardRef } from 'react';
import { useStyles } from '../styles';
import './style.scss';
import CopyIcon from '../../svgs/CopyIcon';

interface IEventObj {
  target: { name: string; value: string };
}
export interface ITextInput {
  label?: string;
  isSecure?: boolean;
  width?: any;
  Icon?: any;
  name?: string;
  value?: string;
  type?: string;
  defaultValue?: string;
  handleClick?: () => void;
  onChange?: (text: string) => void;
  customOnChange?: (arg: IEventObj) => void;
  customClasses?: string;
  placeholder?: string;
  disabled?: boolean;
  multiline?: boolean;
  rows?: number;
  required?: boolean;
  pattern?: string;
  prefix?: string;
  CustomEndAdornment?: any;
  paddingLeft?:any;
  preIcon?:any;
  hasStyle?:any;
  position?:any;
  left?:any;
  top?:any;
  zIndex?:any;
  onBlur?:any;
  inputPaddingLeft?:string;
  isOnModal?:boolean;
  viewOnly?:boolean;
  maxDate?: string;
  copyIcon?:any;
  handleCopy?:any;
  fontSize?:string;
  customTextFieldStyles?:any;
  marginBottom?:string;
  onFocus?: any;
  numberComponentProps?: any;
}
interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  disabled: boolean;
  numberComponentProps?: any;
}

const NumberFormatCustom = forwardRef<any, CustomProps>(
  (props, ref) => {
    const { onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={ref}
        style={{
          ...(other?.disabled ? { backgroundColor: 'rgba(209, 209, 209, 0.1)', cursor: 'not-allowed' } : {}),
        }}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        isNumericString
        prefix="₦"
      />
    );
  },
);

const PercentFormatCustom = forwardRef<any, CustomProps>(
  (props, ref) => {
    const { onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={ref}
        style={{
          ...(other?.disabled ? { backgroundColor: 'rgba(209, 209, 209, 0.1)', cursor: 'not-allowed' } : {}),
        }}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        suffix="%"
      />
    );
  },
);

const Custom = (numberComponentProps:any = {}) => forwardRef<any, CustomProps>(
  (props, ref) => {
    const { onChange, ...other } = props;
    return (
      <NumberFormat
        {...other}
        getInputRef={ref}
        style={{
          ...(other?.disabled ? { backgroundColor: 'rgba(209, 209, 209, 0.1)', cursor: 'not-allowed' } : {}),
        }}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        {...numberComponentProps}
      />
    );
  },
);

const getNumberComponent = (type: string, numberComponentProps: any = {}) => {
  let result = {};

  if (type === 'cash') {
    result = { inputComponent: NumberFormatCustom as any };
  } else if (type === 'percent') {
    result = { inputComponent: PercentFormatCustom as any };
  } else if (type === 'number-custom') {
    result = { inputComponent: Custom(numberComponentProps) as any };
  }
  return result;
};

const TextInput: FunctionComponent<ITextInput> = ({
  label,
  isSecure,
  width,
  Icon,
  name,
  value,
  customClasses,
  type = 'text',
  onChange,
  customOnChange,
  disabled,
  multiline,
  placeholder,
  rows,
  required,
  pattern,
  prefix,
  CustomEndAdornment,
  preIcon,
  onBlur,
  isOnModal,
  viewOnly,
  copyIcon,
  handleCopy,
  fontSize,
  customTextFieldStyles,
  marginBottom,
  onFocus,
  numberComponentProps,
}) => {
  const classes = useStyles();

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    const showP = !showPassword;
    setShowPassword(showP);
  };

  // @ts-ignore
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleChange = (event: any) => {
    const { value } = event.target;
    if (onChange && value) onChange(value);
    if (customOnChange) customOnChange(event);
  };
  return (
    <div className="text-wrap">
      <div style={{
        display: 'flex',
      }}
      >
        {
          preIcon && (
          <div
            className="pre-icon-div"
            style={{
              ...(disabled ? { backgroundColor: 'rgba(209, 209, 209, 0.1)' } : {}),
            }}
          >
            {preIcon}
          </div>
          )
        }
        {
          copyIcon && value && (
          <div className="copy-icon-div" onClick={handleCopy}>
            <span>Copy</span>
            <CopyIcon color="#523B9F" />
          </div>
          )
        }
        <TextField
          placeholder={placeholder}
          multiline={multiline}
          rows={rows}
          variant="filled"
          color="secondary"
          label={label}
          name={name}
          value={value}
          disabled={disabled || viewOnly}
          onChange={handleChange}
          style={{
            width: width || '27.3rem',
            marginBottom: marginBottom || '20px',
            borderColor: 'rgba(234, 236, 240, 0.7',
            height: rows ? '' : '50px',
            borderLeft: preIcon && 'none',
            borderTopLeftRadius: preIcon && '0px',
            borderBottomLeftRadius: preIcon && '0px',
            ...(disabled ? { backgroundColor: 'rgba(209, 209, 209, 0.1)', cursor: 'not-allowed' } : {}),
            ...(customTextFieldStyles || {}),
          }}
          required={required}
          InputLabelProps={{
            ...(value ? { shrink: true } : {}),
            style: {
              fontFamily: 'Inter_Regular',
              color: '#A6AAB4',
              fontSize: '13px',
              height: '50px',
            },
            ...(pattern ? { pattern } : {}),
          }}
          InputProps={{
            disableUnderline: true,
            style: {
              backgroundColor: 'transparent',
              fontFamily: 'Inter_Regular',
              fontWeight: 500,
              fontSize: fontSize || '13px',
              // color: '#475467',
              color: disabled ? '#999999' : '#475467',
              height: rows ? '' : '50px',
              ...(disabled ? { backgroundColor: 'rgba(209, 209, 209, 0.1)', cursor: 'not-allowed' } : {}),
            },
            type: isSecure && !showPassword ? 'password' : type,
            ...(prefix ? { startAdornment: <InputAdornment position="start">{prefix}</InputAdornment> } : {}),
            ...(isSecure
              ? {
                endAdornment: (
                  <InputAdornment position="end">
                    {
                      isOnModal ? (
                        <div style={{ position: 'absolute', right: '0px', top: '7px' }}>
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? <Visibility style={{ fontSize: '16px', color: '#98A2B3' }} /> : <VisibilityOff style={{ fontSize: '16px', color: '#98A2B3' }} />}
                          </IconButton>
                        </div>
                      ) : (
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <Visibility style={{ fontSize: '16px', color: '#98A2B3' }} /> : <VisibilityOff style={{ fontSize: '16px', color: '#98A2B3' }} />}
                        </IconButton>
                      )
                    }

                  </InputAdornment>
                ),
              }
              : {}),
            ...(CustomEndAdornment ? { endAdornment: <InputAdornment position="start">{CustomEndAdornment}</InputAdornment> } : {}),
            ...(getNumberComponent(type, numberComponentProps)),
          }}
          className={`${classes.root} ${customClasses}`}
          onBlur={onBlur}
          onFocus={onFocus}
        />
      </div>
      {Icon && (
        <div className="icon-comp">
          <Icon />
        </div>
      )}
    </div>
  );
};

export default TextInput;
