import { FunctionComponent } from 'react';
import {
  BrowserRouter, Route, Switch,
} from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';
import { GlobalProvider } from '../contexts/globalContext';
import Login from './login/Login';
import Onboarding from './onboarding';
import Register from './register/Register';
import Transactions from './transactions';
import Settlements from './settlements';
import Dashboard from './dashboard';
import TransactionDetail from './transactionDetail';
import Customers from './customers';
import AuthComp from '../AuthComp';
import Settings from './Settings';
import InStore from './instore';
import VerifyEmail from './VerifyEmail';
import SettlementTransactions from './settlements/SettlementTransactions';
import ResetPassword from './ResetPassword/ResetPassword';
import PasswordChange from './ResetPassword/PasswordChange';
import PaymentLinks from './PaymentLinks';
import PaymentLinkDetail from './PaymentLinkDetail';
import './route.scss';
import VerifyEmailSuccessful from './VerifyEmailSuccessful';
import GetStarted from './getStarted';
import CustomerDetail from './customerDetail';
import MerchantIdealTimer from '../utils/MerchantIdealTimer';
import Orders from './Orders';
import OrderDetail from './OrderDetails';
import CreatePaymentLink from './ViewPaymentLink';

const Router: FunctionComponent = () => (
  <ToastProvider>
    <BrowserRouter>
      <GlobalProvider>
        <MerchantIdealTimer />
        <AuthComp>
          <div>
            <Switch>
              <Route path="/" exact component={Login} />
              <Route path="/register" component={Register} />
              <Route path="/dashboard" component={Dashboard} />
              <Route path="/home" exact component={GetStarted} />
              <Route path="/onboarding" component={Onboarding} />
              <Route path="/verify-email" component={VerifyEmail} />
              <Route path="/email-verify-successful" component={VerifyEmailSuccessful} />
              <Route path="/forgot-password" component={ResetPassword} />
              <Route path="/password-reset" component={PasswordChange} />
              <Route
                path="/transactions"
                component={() => (
                  <Switch>
                    <Route exact path="/transactions">
                      <Transactions />
                    </Route>
                    <Route path="/transactions/:transactionId">
                      <TransactionDetail />
                    </Route>
                  </Switch>
                )}
              />
              <Route
                path="/orders"
                component={() => (
                  <Switch>
                    <Route exact path="/orders">
                      <Orders />
                    </Route>
                    <Route path="/orders/:orderId">
                      <OrderDetail />
                    </Route>
                  </Switch>
                )}
              />
              <Route path="/payment-links" component={PaymentLinks} />
              <Route
                path="/payment-link/:paymentLinkName"
                component={PaymentLinkDetail}
              />
              <Route
                path="/create-payment-link"
                component={CreatePaymentLink}
              />
              <Route
                path="/edit-payment-link/:name"

              >
                <CreatePaymentLink type="edit" />
              </Route>

              <Route path="/settlements" component={Settlements} />
              <Route
                path="/settlement/transactions/:settlementId"
                component={SettlementTransactions}
              />
              <Route
                path="/customers"
                component={() => (
                  <Switch>
                    <Route exact path="/customers">
                      <Customers />
                    </Route>
                    <Route path="/customers/:customerId">
                      <CustomerDetail />
                    </Route>
                  </Switch>
                )}
              />
              <Route path="/in-store" component={InStore} />
              <Route path="/settings" component={Settings} />
            </Switch>
          </div>
        </AuthComp>
      </GlobalProvider>
    </BrowserRouter>
  </ToastProvider>
);

export default Router;
