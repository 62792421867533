import { FunctionComponent } from 'react';
import './ModalWrapComp.scss';

export interface IModalWrapComp {
  label?: string;
  children: any;
  closeModal: () => void;
  classes?: string;
}

const ModalWrapComp: FunctionComponent<IModalWrapComp> = ({
  children,
  closeModal,
  classes,
}) => (
  <div className={`app-modal modalwrapcomp ${classes || ''}`}>
    <div onClick={() => closeModal()} className="dark-env" />
    <div className="content-in-modal p-0 m-0">
      <div className="container">
        {children}
      </div>
    </div>
  </div>
);

export default ModalWrapComp;
