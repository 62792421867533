import React, { useEffect, useState } from 'react';
import ImageRight from '../../../svgs/ImageRight';
import LeftImageIcon from '../../../svgs/LeftImageIcon';
import './PaymentListImages.scss';

interface IPaymentLinkImageCarousel{
  images?:any;
}
const PaymentLinkImageCarousel = ({ images }:IPaymentLinkImageCarousel) => {
  const [imageList, setImageList] = useState([]);
  useEffect(() => {
    setImageList(images);
  }, [images]);
  const handleRightClick = (id) => {
    const checkClickValue = imageList?.filter((list:any) => list?.id !== id);
    const getSingleData = imageList?.find((list:any) => list?.id === id);
    setImageList([...checkClickValue, getSingleData]);
  };
  const handleLeftClick = () => {
    const getLastItem = imageList?.pop();
    const getListOfData = imageList?.filter((list:any) => list?.id !== getLastItem?.id);

    setImageList([getLastItem, ...getListOfData]);
  };
  return (
    <div>
      {
        imageList?.length ? (
          <div className="payment_link_image_list_cover">
            {
              imageList?.map((list:any, index) => (index === 0 ? (
                <div className="first_image_cover">
                  {
                    imageList.length > 1 ? (
                      <div className="image_icon_right">
                        <ImageRight onClick={() => handleRightClick(list?.id)} />
                      </div>
                    ) : ''
                  }
                  {
                    imageList.length > 1 ? (
                      <div className="image_icon_left">
                        <LeftImageIcon onClick={handleLeftClick} />
                      </div>
                    ) : ''
                  }

                  <img
                    src={list?.url}
                    alt="img"
                    style={{
                      width: '100%', height: '100%', objectFit: 'cover', borderRadius: '4px',
                    }}
                  />
                </div>
              ) : ''))
            }
            {
              imageList.length > 1 ? (
                <div className="row bottom_list_image_cover">
                  {
                    imageList?.map((list:any, index) => (index !== 0 ? (

                      <div className="col-4">
                        <div className="bottom_images">
                          <img
                            src={list?.url}
                            alt="img"
                            style={{
                              width: '80px', height: '80px', objectFit: 'cover', borderRadius: '4px',
                            }}
                          />
                        </div>

                      </div>

                    ) : ''))
                  }
                </div>
              ) : ''
            }

            {/* <div className="row bottom_list_image_cover">
                  <div className="col bottom_images">working</div>

                </div> */}
          </div>
        ) : ''
      }

    </div>
  );
};
export default PaymentLinkImageCarousel;
