/* eslint-disable max-len */
import React from 'react';
import { dateFormatter } from '../../utils/helpers';
import DateInput from './DateInput';

type IFilterDate = {
  from?: string;
  to?: string;
  setFrom?: any;
  setTo?: any;
  setFilters?: any;
  label?: string;
  type?:string;
  fromDate?: string;
  toDate?: string;
  setFromDate?: any;
  setToDate?: any;
}
const FilterDate = ({
  from, to, setFrom, setTo, fromDate, toDate, setFromDate, setToDate, type, setFilters, label: compLabel,
}: IFilterDate) => {
  const dateOptions = [
    {
      label: 'Today',
      to: dateFormatter(new Date(new Date().setDate(new Date().getDate() + 1)), 'YYYY-MM-DD'),
      from: dateFormatter(new Date(), 'YYYY-MM-DD'),
    },
    {
      label: 'Last 7 Days',
      to: dateFormatter(new Date(new Date().setDate(new Date().getDate())), 'YYYY-MM-DD'),
      from: dateFormatter(new Date(new Date().setDate(new Date().getDate() - 7)), 'YYYY-MM-DD'),
    },
    {
      label: 'Last 30 Days',
      to: dateFormatter(new Date(new Date().setDate(new Date().getDate())), 'YYYY-MM-DD'),
      from: dateFormatter(new Date(new Date().setDate(new Date().getDate() - 30)), 'YYYY-MM-DD'),
    },
    {
      label: '1 Year',
      to: dateFormatter(new Date(new Date().setDate(new Date().getDate())), 'YYYY-MM-DD'),
      from: dateFormatter(new Date(new Date().setDate(new Date().getDate() - 365)), 'YYYY-MM-DD'),
    },
    {
      label: 'All time',
      to: dateFormatter(new Date(new Date().setDate(new Date().getDate())), 'YYYY-MM-DD'),
      from: dateFormatter(new Date('2020-01-01'), 'YYYY-MM-DD'), // 2020-01-01
    },
  ];

  const handleClick = (rest:any) => {
    if (type === 'alternativeDateRange') {
      setFromDate(rest.fromDate);
      setToDate(rest.toDate);
      setFilters((prev) => ({
        ...prev,
        fromDate: rest?.from,
        toDate: rest?.to,
      }));
      return;
    }
    setFrom(rest.from);
    setTo(rest.to);
    setFilters((prev) => ({
      ...prev,
      from: rest?.from,
      to: rest?.to,
    }));
  };
  const handleChangeFrom = (e:any) => {
    if (type === 'alternativeDateRange') {
      setFromDate(dateFormatter(new Date(e.target.value), 'YYYY-MM-DD'));
      return;
    }
    setFrom(dateFormatter(new Date(e.target.value), 'YYYY-MM-DD'));
  };

  const handleChangeTo = (e:any) => {
    if (type === 'alternativeDateRange') {
      setToDate(dateFormatter(new Date(e.target.value), 'YYYY-MM-DD'));
      return;
    }
    setTo(dateFormatter(new Date(e.target.value), 'YYYY-MM-DD'));
  };

  return (
    <div className="filter-date">
      <div className="date-chips">
        {dateOptions.map(({ label, ...rest }: any) => (
          <span
            onClick={() => handleClick(rest)}
            className={`date-chip ${(from === rest.from || fromDate === rest?.from) && (to === rest.to || toDate === rest?.to) ? 'selected-chip' : ''}`}
            key={label}
          >
            {label}
          </span>
        ))}
      </div>
      <div className="period-input">
        <span className="filter-input-label">{compLabel || 'Period'}</span>
        <div className="twin-input">
          <DateInput
            name="from"
            value={from || fromDate}
            placeholder="Start Date"
            onChange={(e:any) => handleChangeFrom(e)}
            minDate={dateFormatter(new Date('2020-01-01'), 'YYYY-MM-DD')}
            maxDate={to || toDate || dateFormatter(new Date(new Date().setDate(new Date().getDate())), 'YYYY-MM-DD')}
          />
          <div className="delimiter" />
          <DateInput
            name="to"
            value={to || toDate}
            placeholder="End Date"
            onChange={(e) => handleChangeTo(e)}
            minDate={from || fromDate || dateFormatter(new Date('2020-01-01'), 'YYYY-MM-DD')}
            maxDate={dateFormatter(new Date(new Date().setDate(new Date().getDate())), 'YYYY-MM-DD')}
          />
        </div>
      </div>

    </div>
  );
};

export default FilterDate;
