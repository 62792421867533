import React from 'react';
import AddressIcon from '../../svgs/AddressIcon';

type Props = {
  address?: string
}

const ShippingComp = ({ address }: Props) => (
  <div className="shipping-card">
    <div className="card-title">
      <h5>
        Shipping Details
      </h5>
      {address && (
        <span className="card-status">
          captured
        </span>
      )}
    </div>
    {address ? (
      <div className="address-info">
        <AddressIcon />
        <span className="address-text">{address}</span>
      </div>
    )
      : (
        <div className="empty-text">
          <span>Update the SDK to receive shipping details from customers</span>
        </div>
      )}
  </div>
);

export default ShippingComp;
