/* eslint-disable max-len */
import React, {
  useEffect, useRef, useState,
} from 'react';
import UseCustomResizer from '../CustomResizer';
import { cleanColumnArray } from '../../utils/helpers';
import useToast from '../../customHooks/useToast';

import FilterColumn from './FilterColumn';
import FilterComponents from '../FilterComponents';
import MerchantDropdown from '../MerchantDropdown';
import Dropdown from '../Dropdown';
import CreateNewLoanModal from '../../admin_screens/customerDetail/CreateNewLoanModal';

import ExportIcon from '../../svgs/ExportIcon';
import FilterIcon from '../../svgs/FilterIcon';
import HideIcon from '../../svgs/HideIcon';
import ListIcon from '../../svgs/ListIcon';
import StyledButton from '../StyledButton';

import './createManualLoanAndMore.scss';

interface Props {
  filterObj?: any;
  exportObj?: any;
  hideExportButton?: boolean;
  columnObject?:any;
  hideFilterButton?:boolean;
  disableExport?:boolean
  disbleFilter?:boolean
  payload?: any;
  refetch?: any
}

const CreateManualLoanAndMore = (props: Props) => {
  const [showModal, setShowModal] = useState(false);
  const [showTableFilterInputs, setShowTableFilterInputs] = React.useState(false);
  const [toggleDropdown, setToggleDropdown] = useState(false);
  const [toggleColumnFilter, setToggleColumnFilter] = useState(false);
  const [showExportList, setShowExportList] = React.useState(false);
  const [exportIsLoading, setExportIsLoading] = React.useState('');
  const [selectedToggleTitle, setSelectedToggleTitle] = useState('');
  const { isMobile } = UseCustomResizer({ width: 654 });

  const Toast = useToast();

  const toggleRef = useRef<HTMLDivElement>(null);
  const exportRef = useRef<HTMLDivElement>(null);
  const filtersRef = useRef<HTMLDivElement>(null);
  const hideColumnRef = useRef<HTMLDivElement>(null);

  const handleClick = (e) => {
    if (toggleRef?.current?.contains(e.target)) {
      return;
    }

    setToggleDropdown(false);
  };

  useEffect(() => {
    window.addEventListener('click', handleClick);
    return () => {
      window.removeEventListener('click', handleClick);
    };
  }, []);

  const handleToggleClick = (e, ref, handler, state) => {
    if (
      (e?.target?.innerText === selectedToggleTitle)
      || (ref?.current?.contains(e.target))
      || (toggleRef?.current?.contains(e.target))
    ) {
      return;
    }
    handler(state);
  };

  useEffect(() => {
    const clickHandler = (e) => handleToggleClick(e, filtersRef, setShowTableFilterInputs, false);
    window.addEventListener('click', clickHandler);
    return () => {
      window.removeEventListener('click', clickHandler);
    };
  }, [showTableFilterInputs]);

  useEffect(() => {
    const clickHandler = (e) => handleToggleClick(e, exportRef, setShowExportList, false);
    window.addEventListener('click', clickHandler);
    return () => {
      window.removeEventListener('click', clickHandler);
    };
  }, [showExportList]);

  useEffect(() => {
    const clickHandler = (e) => handleToggleClick(e, hideColumnRef, setToggleColumnFilter, false);
    window.addEventListener('click', clickHandler);
    return () => {
      window.removeEventListener('click', clickHandler);
    };
  }, [toggleColumnFilter]);

  const handleShowMore = () => {
    setToggleDropdown((prev) => (!prev));
    setShowTableFilterInputs(false);
    setShowExportList(false);
    setToggleColumnFilter(false);
  };

  const handleExport = async ({ value }) => {
    if (exportIsLoading) {
      Toast.error('Please wait while the previous export is in progress');
      return;
    }
    setExportIsLoading(value);
    await props?.exportObj?.handleExport?.(value);
    setExportIsLoading('');
  };

  const handleFilter = () => {
    if (props?.disbleFilter) return;
    setShowTableFilterInputs((prev) => !prev);
  };

  const handleShowExport = () => {
    if (props?.disableExport) return;
    setShowExportList((prev) => !prev);
  };

  const handleFilterStateChange = () => {
    setToggleColumnFilter((prev) => !prev);
  };

  const dropDownData = [
    {
      title: 'Filter', icon: <FilterIcon />, color: '#475467',
    }, {
      title: 'Export', icon: <ExportIcon />, color: '#475467',
    }, {
      title: 'Hide Column', icon: <HideIcon />, color: '#475467',
    }];

  const handleDropDown = (title) => {
    setSelectedToggleTitle(title);
    setToggleDropdown((prev) => (!prev));
    if (title === 'Filter') {
      handleFilter();
    }
    if (title === 'Export') {
      handleShowExport();
    }
    if (title === 'Hide Column') {
      handleFilterStateChange();
    }
  };

  return (
    <div className="create-manual-loan-and-more">
      <StyledButton name="Create New Loan" border="none" fontSize="12px" height="40px" width="141px" backgroundColor="#5941A9" color="white" borderRadius="4px" onClick={() => setShowModal(true)} />
      <div className="loan-more-button-wrapper">
        <div ref={toggleRef}>
          <div className="loan-more-button" onClick={handleShowMore}>
            <StyledButton name="More" color="#667085" border="none" fontSize="14px" height="40px" backgroundColor="#fff" />
            <ListIcon color="#667085" />
          </div>
          {
            toggleDropdown && (
            <div className="admin-user-dropdown-cover">
              <MerchantDropdown data={dropDownData} handleDropdown={handleDropDown} />
            </div>
            )
          }
        </div>
        {!props.hideFilterButton && props?.filterObj && !isMobile && (
        <div ref={filtersRef} id="Filter" className="button-filter-cover">
          <div className={`${showTableFilterInputs ? '' : 'filters-hidden'}`}>
            <FilterComponents
              filterObj={props?.filterObj}
              onClose={() => setShowTableFilterInputs(false)}
            />
          </div>
        </div>
        )}
        <div ref={exportRef} id="Export" className="exports-pdf">
          <div className={`exports-dropdown ${showExportList ? '' : 'exports-pdf-hidden'}`}>
            <Dropdown onClick={handleExport} options={[{ label: (exportIsLoading === 'pdf') ? 'Downloading...' : 'Download as .PDF', value: 'pdf' }, { label: exportIsLoading === 'csv' ? 'Downloading...' : 'Download as .CSV', value: 'csv' }]} />
          </div>
        </div>
        {
          props?.columnObject && !isMobile && (
          <div ref={hideColumnRef} id="Hide Column">
            <div>
              <FilterColumn
                applyChanges={props?.columnObject?.applyChanges}
                selectedColumns={cleanColumnArray(props?.columnObject?.selectedColumns)}
                allColumns={cleanColumnArray(props?.columnObject?.allColumns)}
                formatLabels={props?.columnObject?.formatLabels}
                showToggleDropdown={toggleColumnFilter}
                showRoundedCancel
              />
            </div>
          </div>
          )
        }
      </div>
      {
        showModal && (
        <CreateNewLoanModal
          closeModal={() => setShowModal(false)}
          customerDetails={props.payload}
          refetch={props?.refetch}
        />
        )
      }

    </div>
  );
};

export default CreateManualLoanAndMore;
