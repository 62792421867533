import React from 'react';

const EditTitleIcon = () => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="2" y="2" width="24" height="24" rx="12" fill="#F4EBFF" />
    <path d="M16.5 11.5H14.5V12.5H16.5C17.325 12.5 18 13.175 18 14C18 14.825 17.325 15.5 16.5 15.5H14.5V16.5H16.5C17.88 16.5 19 15.38 19 14C19 12.62 17.88 11.5 16.5 11.5ZM13.5 15.5H11.5C10.675 15.5 10 14.825 10 14C10 13.175 10.675 12.5 11.5 12.5H13.5V11.5H11.5C10.12 11.5 9 12.62 9 14C9 15.38 10.12 16.5 11.5 16.5H13.5V15.5ZM12 13.5H16V14.5H12V13.5Z" fill="#5941A9" />
    <rect x="2" y="2" width="24" height="24" rx="12" stroke="#F9F5FF" strokeWidth="4" />
  </svg>

);
export default EditTitleIcon;
