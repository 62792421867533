import React from 'react';

interface IDeleteProps {
    handleClick?: any;
    color?:string;
}
const DeleteIcon = ({ handleClick, color }:IDeleteProps) => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ cursor: 'pointer' }} onClick={handleClick}>
    <path d="M12.5 14.9998H14.1667M14.1667 14.9998H27.5M14.1667 14.9998V26.6665C14.1667 27.1085 14.3423 27.5325 14.6548 27.845C14.9674 28.1576 15.3913 28.3332 15.8333 28.3332H24.1667C24.6087 28.3332 25.0326 28.1576 25.3452 27.845C25.6577 27.5325 25.8333 27.1085 25.8333 26.6665V14.9998H14.1667ZM16.6667 14.9998V13.3332C16.6667 12.8911 16.8423 12.4672 17.1548 12.1547C17.4674 11.8421 17.8913 11.6665 18.3333 11.6665H21.6667C22.1087 11.6665 22.5326 11.8421 22.8452 12.1547C23.1577 12.4672 23.3333 12.8911 23.3333 13.3332V14.9998M18.3333 19.1665V24.1665M21.6667 19.1665V24.1665" stroke={color || '#667085'} strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
  </svg>

);
export default DeleteIcon;
