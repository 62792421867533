import { isAdmin } from '../utils/helpers';
import { useGlobalDispatch as useGlobalMerchantDispatch, useGlobalState as useGlobalMerchantState } from './globalContext';
import { useGlobalDispatch as useAdminGlobalDispatch, useGlobalState as useAdminGlobalState } from './AdminGlobalContext';

const useGlobalDispatch = isAdmin() ? useAdminGlobalDispatch : useGlobalMerchantDispatch;
const useGlobalState = isAdmin() ? useAdminGlobalState : useGlobalMerchantState;

export {
  useGlobalDispatch,
  useGlobalState,
};
