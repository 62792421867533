/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import TrxSuccessIcon from '../../svgs/TrxSuccessIcon';
import LabelValueComp from '../../components/LabelValueComp';
// import { covertArrayToString } from '../../utils/helpers';

type Props = {
  payment: any
}

const PaymentInfo = ({ payment }: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [records, setRecords]:any = useState(
    {

    },
  );

  const [moreRecords, setMoreRecords]:any = useState(
    {

    },
  );

  useEffect(() => {
    setRecords({
      'Product Name': payment?.title,
      'Date created': payment?.createdAt,
      Description: payment?.description || 'N/A',

    });
  }, [payment]);

  // payment?.productPreference ? payment?.productPreference[0]?.value ? payment?.productPreference[0]?.value : payment?.type : 'N/A'

  useEffect(() => {
    setMoreRecords({
      [payment?.productPreference ? payment?.productPreference[0]?.name ? payment?.productPreference[0]?.name : 'Page Type' : 'Page Type']: payment?.productPreference ? payment?.productPreference[0]?.value?.length ? payment?.productPreference[0]?.value : payment?.type || 'N/A' : payment?.type ? payment?.type : 'N/A',
      [payment?.productPreference ? payment?.productPreference[1]?.name ? payment?.productPreference[1]?.name : 'Success Message' : 'Success Message']: payment?.productPreference ? payment?.productPreference[1]?.value?.length ? payment?.productPreference[1]?.value : payment?.successMessage || 'N/A' : payment?.successMessage ? payment?.successMessage : 'N/A',

      [payment?.productPreference ? payment?.productPreference[2]?.name ? payment?.productPreference[2]?.name : 'Amount' : 'Amount']: payment?.productPreference ? payment?.productPreference[2]?.value?.length ? payment?.productPreference[2]?.value : payment?.amount : payment?.amount || 'N/A',

    });
  }, [payment]);

  // console.log('payment', payment);

  return (
    <div className="payment-info">
      <div className="payment-info-header">
        {payment?.status ? <TrxSuccessIcon /> : <TrxSuccessIcon />}
        <h5>{payment?.amount}</h5>
      </div>
      <aside>
        <div className="flexed-comp">
          {Object.keys(records).map((key, index) => (key
              && <LabelValueComp key={index} label={key} value={records[key]} />
          ))}
        </div>
        <div className="flexed-comp">
          {Object.keys(moreRecords).map((key, index) => (key
              && <LabelValueComp key={index} label={key} value={moreRecords[key]} />
          ))}
        </div>
        <div className="label-value-comp additional-info">
          <div className="info-cover">
            <span>Additional Information</span>
            <div className="additional-info-cover">
              {
                payment?.inputsArray?.map((input, index) => (
                  <small className="label-comp" key={index}>
                    {input?.value || 'N/A'}
                  </small>
                ))
              }
            </div>
          </div>
        </div>
      </aside>
    </div>
  );
};

export default PaymentInfo;
