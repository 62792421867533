import {
  FunctionComponent, useEffect, useState,
} from 'react';
import './Transaction.scss';
// import moment from 'moment';
import { useHistory } from 'react-router-dom';
import DashboardWrap from '../../components/DashboardWrap';
import { GET_TRANSACTIONS } from '../../graphql/queries';
import Table from '../../components/Table';
import { cleanUpTransactions } from '../../utils/helpers';
import useCustomQuery from '../../network/useCustomQuery';
import useGetQueryParams from '../../customHooks/useGetQueryParams';
import DashboardHeader from '../../components/DashboardHeader';
import EmptyRecord from '../../components/EmptyRecord';
import useToast from '../../customHooks/useToast';
import generatePDF from '../../utils/generatePdf';
import generateCSV from '../../utils/generateCSV';
import useFilterMethods from '../../customHooks/useFilterMethods';

const limit = 14;
const Transactions: FunctionComponent = () => {
  const Toast = useToast();
  const [getTransactions, loading, rawData] = useCustomQuery({
    query: GET_TRANSACTIONS,
    onError: () => {},
    onData: () => {},
  });

  const [data, setData] = useState(null);
  const { getQuery, getFilterQueries } = useGetQueryParams();

  const search = getQuery('search') || '';
  const page = Number(getQuery('page'));
  const { push } = useHistory();
  const setPage = (pageNum) => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('page', pageNum);
    push({
      search: searchParams.toString(),
    });
  };
  if (!page) {
    push(`/transactions?page=1&search=${search}`);
  }

  const onSearchChange = (e) => {
    push(`/transactions?page=1&search=${encodeURIComponent(e.target.value)}`);
  };

  useEffect(() => {
    if (page) {
      const result = getFilterQueries();
      getTransactions({
        variables: {
          offset: (page - 1) * limit,
          limit,
          cursor: search,
          filters: {
            from: result.from,
            to: result.to,
            status: result.status,
            channel: result.channel,
            minAmount: result.min ? Number(result.min) : 0,
            maxAmount: result.max ? Number(result.max) : 0,
          },
        },
      });
    }
  }, [page, search]);

  const onFilterSubmit = (result) => {
    getTransactions({
      variables: {
        offset: 0,
        limit,
        cursor: search,
        filters: {
          from: result.from,
          to: result.to,
          status: result.status,
          channel: result.channel,
          minAmount: result.min ? Number(result.min) : 0,
          maxAmount: result.max ? Number(result.max) : 0,
        },
      },
    });
  };

  const {
    filterObj,
  } = useFilterMethods({
    allowedFilters: ['dateRange', 'status', 'channel', 'amountRange'],
    defaultUrl: '/transactions?page=1',
    cb: onFilterSubmit,
    Toast,
  });

  useEffect(() => {
    if (rawData?.getMerchantTransactions?.data) {
      setData(rawData);
    }
  }, [rawData]);

  const [getTransactionsExportRecord] = useCustomQuery({
    query: GET_TRANSACTIONS,
    onError: () => {},
    onData: () => {},
  });
  const handleExport = async (type: string) => {
    try {
      const result = getFilterQueries();
      const output = await getTransactionsExportRecord({
        variables: {
          offset: 0,
          limit: 2000,
          cursor: search,
          filters: {
            from: result.from,
            to: result.to,
            status: result.status,
            channel: result.channel,
            minAmount: result.min ? Number(result.min) : 0,
            maxAmount: result.max ? Number(result.max) : 0,
          },
        },
      });
      if (output?.data?.getMerchantTransactions?.data) {
        const rec = cleanUpTransactions(output?.data?.getMerchantTransactions?.data);
        const func = type === 'pdf' ? generatePDF : generateCSV;
        func({
          payload: rec,
          title: 'TRANSACTIONS',
          selectedHeaders: [
            'S/N',
            'txRef',
            'trxAmount',
            'customer',
            'channel',
            'status',
            'email',
            'dateTime',
          ],
          formatHeaders: {
            datetime: 'Date',
            txRef: 'Reference',
            trxAmount: 'Amount',
            email: 'Email Address',
          },
          cb: () => Toast.success('Exported successfully'),
        });
      }
    } catch (error) {
      Toast.error('Error while exporting');
    }
  };

  return (
    <div className="transaction-page">
      <DashboardWrap>
        <DashboardHeader
          title="Transactions"
          smallSubTitle={`(${data?.getMerchantTransactions?.totalCount || 0})`}
          searchPlaceholder="Search by email address, phone number, customer name and reference"
          filterObj={filterObj}
          exportObj={{
            handleExport,
          }}
          search={search}
          onSearchChange={onSearchChange}
          searchLoading={loading}
        />
        {data?.getMerchantTransactions?.totalCount ? (
          <Table
            refreshing={loading}
            page={page}
            setPage={setPage}
            pageSize={limit}
            minHeight="calc(100vh - 280px)"
            count={data?.getMerchantTransactions.totalCount}
            contents={cleanUpTransactions(data?.getMerchantTransactions.data)}
            reference="id"
            hideDominantImage
            clickable
            handleClick={(data) => push(`/transactions/${data?.id}`)}
            allowKeyArr={[
              'txRef',
              'trxAmount',
              'customer',
              'channel',
              'status',
              'email',
              ['date', 'time'],
            ]}
            mobileAllowKeyArr={
              [
                'status',
                ['txRef', 'date', 'time'],
                ['trxAmount', 'channel'],

              ]
            }
            formatLabels={{
              date: 'Date',
              txRef: 'Reference',
              trxAmount: 'Amount',
              email: 'Email Address',
            }}
          />
        ) : (
          <EmptyRecord
            height="calc(100vh - 240px)"
            title={loading ? 'Fetching transactions...' : 'No transactions yet'}
            main="All transactions from your customers through Payfi will appear here"
            // footer="Receive Payments"
            loading={loading}
          />
        )}
      </DashboardWrap>
    </div>
  );
};

export default Transactions;
