import React from 'react';
import { useHistory } from 'react-router-dom';
import SaveButton from '../../components/SaveButton';
import { useGlobalDispatch } from '../../contexts/globalContext';
import VerifyEmailSuccessfullIcon from '../../svgs/VerifyEmailSuccessfullIcon';
import './VerifyEmailSuccessful.scss';

const VerifyEmailSuccessful = () => {
  const { push } = useHistory();
  const { clearUser } = useGlobalDispatch();
  const handleRouteToLogin = () => {
    clearUser();
    push('/');
  };
  return (
    <div className="verify-email-successful-cover">
      <div className="verify-email-successful-cover-div container col-lg-4 col-md-5 col-12">
        <div className="verify-email-successful-cover-div-icon"><VerifyEmailSuccessfullIcon /></div>
        <div className="verify-email-successful-cover-div-title">Account Verified</div>
        <div className="verify-email-successful-cover-div-subtitle">
          Your account has been successfully verified. You can login
          into your Merchant Portal to start using Payfi

        </div>
        <div className="verify-email-successful-cover-div-button">
          <div className="verify-email-successful-cover-div-button-inner">
            <SaveButton
              name="Login"
              onClick={handleRouteToLogin}
            />
          </div>
        </div>
        <div className="verify-email-successful-cover-div-signin">
          Don’t have an account?
          {' '}
          <span onClick={() => push('/register')}>Sign Up</span>
        </div>
      </div>
    </div>
  );
};
export default VerifyEmailSuccessful;
