import React from 'react';

interface IHorizontalListIcon{
  onClick?:any;
  color?:string;
}
const HorizontalListIcon = ({ onClick, color }:IHorizontalListIcon) => (
  <svg width="25" height="20" viewBox="0 0 25 20" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ cursor: 'pointer' }} onClick={onClick}>
    <circle cx="6.5" cy="10" r="2" fill={color || '#121212'} />
    <circle cx="12.5" cy="10" r="2" fill={color || '#121212'} />
    <circle cx="18.5" cy="10" r="2" fill={color || '#121212'} />
  </svg>

);
export default HorizontalListIcon;
