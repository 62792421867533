import { FunctionComponent } from 'react';
import EmptyRecord from '../../components/EmptyRecord';
import ModalFormComp from '../../components/ModalFormComp';

export interface IAdditionalInfo {
  closeModal?: any;
  payload?: any;
}

const AdditionalInfo: FunctionComponent<IAdditionalInfo> = ({
  closeModal,
  payload,
}) => {
  const additionalInfoObj = payload.additionalInformation
    ? JSON.parse(payload.additionalInformation) : null;
  return (
    <ModalFormComp
      title="Additional Information"
      closeModal={closeModal}
      subTitle="The information were requested from your customers"
      hasCancel
      className="payment-link-modal"
      cancleBtnName="Close"
    >
      <header className="additional-info-header">
        <h6>{payload?.name}</h6>
        <span>{payload?.email}</span>
      </header>
      <div className="view-additional-info">
        {Object.keys(additionalInfoObj).length
          ? Object.keys(additionalInfoObj).map((key, index) => (
            <div key={index} className="additional-info-comp">
              <span>{key}</span>
              <strong style={{ ...(additionalInfoObj[key]?.length > 50 ? { height: '100px' } : {}) }}>{additionalInfoObj[key]}</strong>
            </div>
          ))
          : (
            <EmptyRecord
              height="300px"
              title="Additional information is not available"
              main="You can edit payment link to request additional information"
            />
          )}
      </div>
    </ModalFormComp>
  );
};

export default AdditionalInfo;
