import React from 'react';
import SomethingWrong from './SomethingWrong';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
    // You can also log the error to an error reporting service
    console.log(error, info);
  }

  render() {
    const {
      state: { hasError },
      props: { children },
    } = this;
    if (hasError) {
      // You can render any custom fallback UI
      return <SomethingWrong />;
    }
    return children;
  }
}

export default ErrorBoundary;
