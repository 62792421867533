import { FunctionComponent } from 'react';
import './SaveButton.scss';

export interface ISaveButton {
  name?: string;
  className?: string;
  disabled?: boolean;
  onClick?: () => void;
}

const SaveButton: FunctionComponent<ISaveButton> = ({
  name,
  className,
  onClick,
  disabled,
}) => (
  <div
    onClick={() => !disabled && onClick()}
    className={`save-button d-grid ${className || ''}`}
  >
    <button
      disabled={disabled}
      type="button"
      className="btn btn-primary p-2 mb-2 w-full text-white rounded btn-lg"
    >
      <span>{name || 'Save'}</span>
    </button>
  </div>
);

export default SaveButton;
