import { FunctionComponent } from 'react';
import './SideBar.scss';
import AdminSideBar from './AdminSideBar';
import MerchantSideBar from './MerchantSideBar';

export interface ISideBar {
  activate?: boolean;
  dashboard?: boolean;
  settlements?: boolean;
  transaction?: boolean;
  disputes?: boolean;
  disabled?: boolean;
  closeDropDownAndSideBar?: () => void;
}
const isAdmin = process.env.REACT_APP_TYPE === 'ADMIN';
const SideBarInUse = isAdmin ? AdminSideBar : MerchantSideBar;

const SideBar: FunctionComponent<ISideBar> = (props) => <SideBarInUse {...props} />;

export default SideBar;
