/* eslint-disable max-len */
import { FunctionComponent } from 'react';
// import { useGlobalDispatch, useGlobalState } from '../../../contexts/globalContext';
import './ModalFormComp.scss';

import ModalWrapComp from '../ModalWrapComp';
// import SaveButton from '../SaveButton';

import StyledButton from '../StyledButton';
import RoundedCancleIcon from './RoundedCancleIcon';

export interface IModalFormComp {
  label?: string;
  closeModal?: any;
  title?: any;
  btnName?: any;
  btnAction?:any;
  btnLoading?: boolean | undefined;
  btnDisabled?: boolean | undefined;
  subTitle?:string;
  hasCancel?:boolean;
  submitButtonWidth?:string;
  className?:any;
  titleIcon?:any;
  cancleBtnName?:string;
  removeTitle?:boolean;
  modalType?:any;
  confirmActionBtn?:any;
  confirmationIcon?:any;
  confirmationTitle?:string;
  confirmationSubTitle?:any;
  confirmationBtnContent?:any;
  hideFooter?:boolean;
  cancleDivWidth?:any;
  cancelActionBtn?:any;
  children?:any
  cancelCloseAction?: any;
}

const ModalFormComp: FunctionComponent<IModalFormComp> = ({
  closeModal,
  children,
  title,
  btnName,
  btnAction,
  btnLoading,
  btnDisabled,
  subTitle,
  hasCancel,
  submitButtonWidth,
  className,
  titleIcon,
  cancleBtnName,
  removeTitle,
  modalType,
  confirmActionBtn,
  confirmationIcon,
  confirmationTitle,
  confirmationSubTitle,
  confirmationBtnContent,
  cancleDivWidth,
  cancelActionBtn,
  cancelCloseAction,
}) => (
  <ModalWrapComp closeModal={closeModal}>
    {
      modalType ? (
        <div style={{ width: cancleDivWidth || '346px' }}>
          <RoundedCancleIcon closeModal={cancelCloseAction || closeModal} />
        </div>
      ) : <RoundedCancleIcon closeModal={cancelCloseAction || closeModal} />
    }

    <div className={modalType || 'modal-card'}>
      <h2>
        {titleIcon && <span style={{ marginRight: '10px' }}>{titleIcon}</span>}
        {
          !removeTitle && <span className="title-style">{title || 'Enter Title' }</span>
        }

      </h2>
      {
        subTitle && <div className="modal-card-subtitle">{subTitle}</div>
      }

      <div className={`form-content ${className || ''}`}>
        {
          confirmationIcon && (
          <div className="confirmation-icon">
            {confirmationIcon}
          </div>
          )

        }
        {
          confirmationTitle && <div className="confirmation-title">{confirmationTitle}</div>

        }
        {
          confirmationSubTitle && <div className="confirmation-subtitle">{confirmationSubTitle}</div>

        }

        {children}
        {
          confirmationBtnContent && (
          <div style={{ marginBottom: '32px' }}>
            <StyledButton name={confirmationBtnContent} onClick={confirmActionBtn} width="100%" height="31px" backgroundColor={btnLoading ? 'rgba(82, 59, 159, 0.5)' : '#523B9F'} color="white" fontSize="13px" borderRadius="4px" />
            {
              cancelActionBtn && (
              <div style={{ marginTop: '16px' }}>
                <StyledButton name="Cancel" onClick={cancelActionBtn} border="1px solid #98A2B3" width="100%" height="31px" backgroundColor="white" color="#98A2B3" fontSize="13px" borderRadius="4px" />

              </div>
              )
            }

          </div>
          )
        }

      </div>
      {
        !modalType && (
        <div className="modal-footer">
          <div className="form-content-button-div">
            {/* <SaveButton
            disabled={btnLoading || btnDisabled}
            name={btnName}
            onClick={btnAction}
          /> */}
            {
              hasCancel && <span><StyledButton name={cancleBtnName || 'Cancel'} onClick={closeModal} width="113px" height="40px" backgroundColor="white" color="#523B9F" fontSize="13px" borderRadius="4px" border="1px solid #DCD8EC" /></span>
            }
            {btnAction && (
            <StyledButton disabled={btnLoading || btnDisabled} name={btnName} onClick={btnAction} width={submitButtonWidth || '113px'} height="40px" backgroundColor="#523B9F" color="white" fontSize="13px" borderRadius="4px" border="none" />
            )}
          </div>
        </div>
        )
      }

    </div>
  </ModalWrapComp>
);

export default ModalFormComp;
