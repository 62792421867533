import React from 'react';

type Props = {
  nipCode: string
  borderRadius?:string;
};
const defaultIcon = 'https://publicpayfiimages.s3.amazonaws.com/randomAsset/bankLogo/default_bank-icon-5970.png';
const allLogoUrls = {
  100003: 'https://publicpayfiimages.s3.amazonaws.com/randomAsset/bankLogo/100003_ReadyCash-logo.svg',
  100004: 'https://publicpayfiimages.s3.amazonaws.com/randomAsset/bankLogo/100004_paycom.jpg',
  100026: 'https://publicpayfiimages.s3.amazonaws.com/randomAsset/bankLogo/100026_One-Finance-logo.png',
  100033: 'https://publicpayfiimages.s3.amazonaws.com/randomAsset/bankLogo/100033_PalmPay.png',
  400001: 'https://publicpayfiimages.s3.amazonaws.com/randomAsset/bankLogo/400001_FSDH-hOLDCO-Logo.png',
  '000014': 'https://publicpayfiimages.s3.amazonaws.com/randomAsset/bankLogo/000014_access_bank.webp',
  '000013': 'https://publicpayfiimages.s3.amazonaws.com/randomAsset/bankLogo/000013_gtbank.png',
  '000016': 'https://publicpayfiimages.s3.amazonaws.com/randomAsset/bankLogo/000016_firstbank.webp',
  '000003': 'https://publicpayfiimages.s3.amazonaws.com/randomAsset/bankLogo/000003_fcmb.png',
  '000002': 'https://publicpayfiimages.s3.amazonaws.com/randomAsset/bankLogo/000002_keystone_logo.jpg',
  '090001': 'https://publicpayfiimages.s3.amazonaws.com/randomAsset/bankLogo/090001_aso_logo.png',
  '090147': 'https://publicpayfiimages.s3.amazonaws.com/randomAsset/bankLogo/090147_hackman-logo.jpg',
  '090121': 'https://publicpayfiimages.s3.amazonaws.com/randomAsset/bankLogo/090121_HASAL-LOGO.jpg',
  '000009': 'https://publicpayfiimages.s3.amazonaws.com/randomAsset/bankLogo/000009_citi-logo.png',
  '090004': 'https://publicpayfiimages.s3.amazonaws.com/randomAsset/bankLogo/090004_parallex-logo.png',
  '000015': 'https://publicpayfiimages.s3.amazonaws.com/randomAsset/bankLogo/000015_zenith-logo.png',
  '090175': 'https://publicpayfiimages.s3.amazonaws.com/randomAsset/bankLogo/090175_rubies-logo.png',
  '000026': 'https://publicpayfiimages.s3.amazonaws.com/randomAsset/bankLogo/000026_tajbank.png',
  '000027': 'https://publicpayfiimages.s3.amazonaws.com/randomAsset/bankLogo/000027_globusbank.png',
  '090148': 'https://publicpayfiimages.s3.amazonaws.com/randomAsset/bankLogo/090148_bowen-logo.png',
  '000001': 'https://publicpayfiimages.s3.amazonaws.com/randomAsset/bankLogo/000001_Sterling-Logo.svg',
  '090097': 'https://publicpayfiimages.s3.amazonaws.com/randomAsset/bankLogo/090097_ekondo-logo.png',
  '000021': 'https://publicpayfiimages.s3.amazonaws.com/randomAsset/bankLogo/000021_STANDARD-CHARTERED.png',
  '000022': 'https://publicpayfiimages.s3.amazonaws.com/randomAsset/bankLogo/000022_SUNTRUST-LOGO.png',
  '000004': 'https://publicpayfiimages.s3.amazonaws.com/randomAsset/bankLogo/000004_uba-logo.png',
  '000012': 'https://publicpayfiimages.s3.amazonaws.com/randomAsset/bankLogo/000012_stanbic-logo.jpg',
  '000007': 'https://publicpayfiimages.s3.amazonaws.com/randomAsset/bankLogo/000007_FIDELITY-LOGO.png',
  '090154': 'https://publicpayfiimages.s3.amazonaws.com/randomAsset/bankLogo/090154_CEMCS-logo.png',
  '070012': 'https://publicpayfiimages.s3.amazonaws.com/randomAsset/bankLogo/070012_LBIC-logo.jpg',
  '090118': 'https://publicpayfiimages.s3.amazonaws.com/randomAsset/bankLogo/090118_ibile.png',
  '090115': 'https://publicpayfiimages.s3.amazonaws.com/randomAsset/bankLogo/090115_tcf-MFB-logo.png',
  '000010': 'https://publicpayfiimages.s3.amazonaws.com/randomAsset/bankLogo/000010_eco-logo-svg.png',
  '090157': 'https://publicpayfiimages.s3.amazonaws.com/randomAsset/bankLogo/090157_infinity-mfb-logo.jpg',
  '000025': 'https://publicpayfiimages.s3.amazonaws.com/randomAsset/bankLogo/000025_titan-logo-box.png',
  '000011': 'https://publicpayfiimages.s3.amazonaws.com/randomAsset/bankLogo/000011_unity-logo.jpg',
  '090110': 'https://publicpayfiimages.s3.amazonaws.com/randomAsset/bankLogo/090110_vfd-logo.jpg',
  '070010': 'https://publicpayfiimages.s3.amazonaws.com/randomAsset/bankLogo/070010_abbey-logo.png',
  '000018': 'https://publicpayfiimages.s3.amazonaws.com/randomAsset/bankLogo/000018_UNION-BANK-logo.jpg',
  '000024': 'https://publicpayfiimages.s3.amazonaws.com/randomAsset/bankLogo/000024_Rand-Merchant-Bank.png',
  '090165': 'https://publicpayfiimages.s3.amazonaws.com/randomAsset/bankLogo/090165_Petra-Mircofinance-logo.jpg',
  '000020': 'https://publicpayfiimages.s3.amazonaws.com/randomAsset/bankLogo/000020_Heritage-Bank-logo.png',
  '090325': 'https://publicpayfiimages.s3.amazonaws.com/randomAsset/bankLogo/090325_Sparkle-Microfinance-Bank-logo.jpg',
  '060001': 'https://publicpayfiimages.s3.amazonaws.com/randomAsset/bankLogo/060001_Coronation-Merchant-Bank-logo.png',
  '000017': 'https://publicpayfiimages.s3.amazonaws.com/randomAsset/bankLogo/000017_wema-logo.png',
  '000006': 'https://publicpayfiimages.s3.amazonaws.com/randomAsset/bankLogo/000006_jaiz-bank-logo.png',
  '000023': 'https://publicpayfiimages.s3.amazonaws.com/randomAsset/bankLogo/000023_Providus-Bank-logo.png',
  '090267': 'https://publicpayfiimages.s3.amazonaws.com/randomAsset/bankLogo/090267_kuda-logo.png',
  '000008': 'https://publicpayfiimages.s3.amazonaws.com/randomAsset/bankLogo/000008_polaris-logo.jpg',

};

const BankLogo = (props: Props) => {
  const { nipCode } = props;
  const logoUrl = allLogoUrls[nipCode] || defaultIcon;
  return (
    <img
      src={logoUrl}
      alt="logo"
      style={{
        objectFit: 'contain',
        width: '24px',
        borderRadius: props?.borderRadius,
      }}
    />
  );
};

export default BankLogo;
