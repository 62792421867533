import React from 'react';

const FilterIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M6.03792 11.4938C6.03792 11.7593 6.24551 11.9754 6.50111 11.9754C6.7567 11.9754 6.9643 11.7593 6.9643 11.4938V9.10362C6.9643 8.98118 6.9199 8.86373 6.8389 8.77377L3.02118 4.52884C2.96058 4.46137 2.92638 4.37204 2.92638 4.27958V3.33002C2.92638 3.12948 3.08418 2.96518 3.27737 2.96518H5.13074C5.38633 2.96518 5.59393 2.74903 5.59393 2.4829C5.59393 2.21678 5.38633 2 5.13074 2H3.27737C2.57299 2 2 2.5966 2 3.33002V4.27958C2 4.6188 2.1224 4.94115 2.34499 5.18916L6.03792 9.29416V11.4938ZM6.13356 13.7244C6.21216 13.898 6.37955 14 6.55415 14C6.61955 14 6.68615 13.9858 6.74915 13.9555L9.34169 12.7132C9.50429 12.6354 9.60929 12.4673 9.60929 12.2812V9.22749L13.6166 5.17376C13.8602 4.9284 14 4.586 14 4.23495V3.31593C14 2.59035 13.4264 2.00012 12.722 2.00012H7.38273C7.12714 2.00012 6.91954 2.21458 6.91954 2.47724C6.91954 2.74115 7.12714 2.95499 7.38273 2.95499H12.722C12.9158 2.95499 13.073 3.11754 13.073 3.31593V4.23495C13.073 4.33075 13.0352 4.42469 12.968 4.49206L8.8215 8.68732C8.73271 8.77694 8.68231 8.89993 8.68231 9.02786V11.9765L6.35855 13.0896C6.12636 13.2009 6.02616 13.4846 6.13356 13.7244Z" fill="#475467" />
  </svg>

);

export default FilterIcon;
