import React from 'react';
import TrxSuccessIcon from '../../svgs/TrxSuccessIcon';
import LabelValueComp from '../../components/LabelValueComp';
import TrxFailedIcon from '../../svgs/TrxFailedIcon';

type Props = {
  transaction: any
}

const TransactionInfo = ({ transaction }: Props) => {
  const records = {
    'Customer Name': transaction?.customerName,
    Fees: transaction?.fees,
    'Reference No': transaction?.txRef,
    'Phone Number': transaction?.phone,
    'Email Address': transaction?.email,
    'Capture Date': transaction?.createdDate,
  };

  return (
    <div className="transaction-info">
      <div className="trx-info-header">
        {transaction?.status?.toLowerCase?.() === 'success' ? <TrxSuccessIcon /> : <TrxFailedIcon />}
        <h5>{transaction?.trxAmount}</h5>
      </div>
      <aside>
        {Object.keys(records).map((key, index) => (
          <LabelValueComp key={index} label={key} value={records[key]} />
        ))}
      </aside>
    </div>
  );
};

export default TransactionInfo;
