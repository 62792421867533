export const AdminRouteName = {
  // LOGIN
  loginRoute: '/',
  // DASHBOARD
  dashboardRoute: '/dashboard',
  // CUSTOMERS
  customersRoute: '/customers',
  approvedCustomersRoute: '/customers/approved-customers',
  prospectiveCustomersRoute: '/customers/prospective-customers',
  deleteCustomersRoute: '/customers/delete-customers',
  declineCustomersRoute: '/customers/declined-customers',
  priorityCustomersRoute: '/customers/priority-customers',
  customerDetailsRoute: '/customers/customer-details/:customerId',
  customerDetailsProfileRoute: '/customers/customer-details/:customerId/profile',
  customerDetailsVerificationRoute: '/customers/customer-details/:customerId/verification',
  customerDetailsDocumentsRoute: '/customers/customer-details/:customerId/documents',
  customerDetailsTransactionsRoute: '/customers/customer-details/:customerId/transactions',
  customerDetailsLoanRoute: '/customers/customer-details/:customerId/loan',
  customerDetailsRepaymentRoute: '/customers/customer-details/:customerId/repayment',
  customerDetailsWalletRoute: '/customers/customer-details/:customerId/wallet',
  customerDetailsCommentRoute: '/customers/customer-details/:customerId/comments',
  customerDetailsNotificationsRoute: '/customers/customer-details/:customerId/notifications',
  customerDetailsSDKActivitiesRoute: '/customers/customer-details/:customerId/sdk-activities',
  customerDetailsTransactionBnplRoute: '/customers/customer-details/:customerId/transactions/bnpl',
  customerDetailsTransactionWalletRoute: '/customers/customer-details/:customerId/transactions/wallet',
  customerDetailsRewardHistoryRoute: '/customers/customer-details/:customerId/reward-history',
  customerDetailsEarningsRoute: '/customers/customer-details/:customerId/reward-history/earnings',
  customerDetailsRedemptionHistoryRoute: '/customers/customer-details/:customerId/reward-history/redemption',
  // ADMIN MERCHANT
  adminMerchantsRoute: '/admin-merchants',
  approvedAdminMerchantsRoute: '/admin-merchants/approved-merchants',
  awaitingApprovalAdminMerchantsRoute: '/admin-merchants/awaiting-approval',
  prospectiveAdminMerchantsRoute: '/admin-merchants/prospective-merchants',
  deactivateAdminMerchantsRoute: '/admin-merchants/deactivated-merchants',
  declineAdminMerchantsRoute: '/admin-merchants/declined-merchants',
  adminMerchantDetailsRoute: '/admin-merchants/merchant-details/:merchantId',
  adminMerchantDetailsKYCRoute: '/admin-merchants/merchant-details/:merchantId/kyc',
  adminMerchantDetailsOrderRoute: '/admin-merchants/merchant-details/:merchantId/order',
  adminMerchantDetailsSettingsRoute: '/admin-merchants/merchant-details/:merchantId/setting',
  adminMerchantDetailsCommentRoute: '/admin-merchants/merchant-details/:merchantId/Comment',
  adminMerchantDetailsSettlementRoute: '/admin-merchants/merchant-details/:merchantId/settlement',
  // TRANSACTIONS
  transactionRoute: '/transactions',
  bnplTransactionRoute: '/transactions/bnpl',
  pendingBnplTransactionRoute: '/transactions/bnpl/pending-transaction',
  successfulBnplTransactionRoute: '/transactions/bnpl/successful-transaction',
  failedBnplTransactionRoute: '/transactions/bnpl/failed-transaction',
  walletTransactionRoute: '/transactions/wallet',
  pendingWalletTransactionRoute: '/transactions/wallet/pending-transaction',
  successfulWalletTransactionRoute: '/transactions/wallet/successful-transaction',
  failedWalletTransactionRoute: '/transactions/wallet/failed-transaction',
  transactionDetailsRoute: '/transactions/transactions-details/:transactionId',

  // REWARDS
  rewardRoute: '/reward',
  rewardOverviewRoute: '/reward/overview',
  rewardOverviewActiveUsersRoute: '/reward/overview/active',
  rewardOverviewInActiveUsersRoute: '/reward/overview/Inactive',
  rewardOverviewSuspendedUsersRoute: '/reward/overview/suspended-user',
  redemptionHistory: '/reward/redemption-history',
  rewardLeaderboard: '/reward/leaderboard',
  // RNPL
  rnplRoute: '/rnpl',
  boltRnplRoute: '/rnpl/bolt',
  activeBoltRnplRoute: '/rnpl/bolt/active',
  paidBoltRnplRoute: '/rnpl/bolt/paid',
  dueBoltRnplRoute: '/rnpl/bolt/due',
  defaultingBoltRnplRoute: '/rnpl/bolt/defaulting',
  uberRnplRoute: '/rnpl/uber',
  activeUberRnplRoute: '/rnpl/uber/active',
  paidUberRnplRoute: '/rnpl/uber/paid',
  dueUberRnplRoute: '/rnpl/uber/due',
  defaultingUberRnplRoute: '/rnpl/uber/defaulting',
  // BILLS PAYMENT
  billsPaymentRoute: '/bills-payment',
  successfulBillsPaymentRoute: '/bills-payment/successful',
  pendingBillsPaymentRoute: '/bills-payment/pending',
  failedBillsPaymentRoute: '/bills-payment/failed',
  // LOANS
  loansRoute: '/loans',
  declinedLoansRoute: '/loans/declined',
  pendingLoansRoute: '/loans/pending',
  disbursedLoansRoute: '/loans/disbursed',
  awaitingApprovalLoansRoute: '/loans/awaiting-approval',
  loanDetailsRoute: '/loans/loans-details/:loanId',
  // REFUND LOANS
  refundLoansRoute: '/refund-loans',
  pendingRefundLoansRoute: '/refund-loans/pending',
  completedRefundLoansRoute: '/refund-loans/completed',

  // REPAYMENT
  repaymentsRoute: '/repayments',
  dueRepaymentRoute: '/repayments/due',
  upcomingRepaymentRoute: '/repayments/upcoming',
  defaultRepaymentRoute: '/repayments/default',
  paidRepaymentRoute: '/repayments/paid',
  repaymentDetailsRoute: '/repayments/repayment-details/:repaymentId',
  // ADMIN SETTLEMENT
  adminSettlementRoute: '/admin-settlement',
  pendingAdminSettlementRoute: '/admin-settlement/pending',
  failedAdminSettlementRoute: '/admin-settlement/failed',
  successfulAdminSettlementRoute: '/admin-settlement/successful',
  adminSettlementDetailsRoute: '/admin-settlement/settlement/transactions/:settlementId',
  // PUSH NOTIFICATION
  pushNotificationRoute: '/push-notification',
  pushNotificationUserGroupCustomersRoute: '/push-notification/push-notification-user-group/customers',
  pushNotificationUserGroupRepaymentRoute: '/push-notification/push-notification-user-group/repayment',
  pushNotificationUserGroupRoute: '/push-notification/push-notification-user-group',
  // SDK ACTIVITIES
  sdkActivitiesRoute: '/sdk-activities',
  sdkActivitiesDetailsRoute: '/sdk-activities/sdk-activities-details/:sdkActivitiesId',
  // CHECK ELIGIBILITY
  checkEligibilityRoute: '/check-eligibility',
  checkEligibilityDetailsRoute: '/check-eligibility/check-eligibility-details/:eligibilityId',
  // DISBURSEMENT
  disbursementRoute: '/disbursement',
  disbursementRenmoneyRoute: '/disbursement/renmoney',
  disbursementCdlRoute: '/disbursement/cdl',
  disbursementFcmbRoute: '/disbursement/fcmb',
  disbursementPayfiRoute: '/disbursement/payfi',
  disbursementDetailsRoute: '/disbursement/disbursement-details/:disbursementId',
  // WALLETS
  walletsRoute: '/wallets',
  // AUDIT LOGS
  auditLogsRoute: '/audit-logs',
  // CONFIGURATION
  configurationsRoute: '/configurations',
  repaymentConfigurationsRoute: '/configurations/repayment',
  smsConfigurationsRoute: '/configurations/sms-config',
  sdkConfigurationsRoute: '/configurations/sdk-config',
  // SETTINGS
  settingsRoute: '/settings',
  rolesAndPermissionSettingsRoute: '/settings/roles-and-permission',
  userManagementSettingsRoute: '/settings/user-management/all',
  activeUserManagementSettingsRoute: '/settings/user-management/active',
  inactiveUserManagementSettingsRoute: '/settings/user-management/inactive',
  settingsRoleDetailsRoute: '/settings/role-details/:roleId',
  settingsAssignPermissionRoute: '/settings/assign-permission/:roleId',
  // PASSWORD RESET
  passwordResetRoute: '/password-reset',
  // NOT ALLOWED
  notAllowedRoute: '/not-allowed',
  // 404 NOT FOUND
  notFoundRoute: '*',
};
