import React from 'react';

type Props = {
  disabled?: boolean;
}

const SelectSearchDropSvg = ({ disabled }: Props) => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M1 3L6 9L11 3H1Z" fill={disabled ? '#999999' : '#523B9F'} />
  </svg>

);

export default SelectSearchDropSvg;
