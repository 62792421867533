import React from 'react';

const EmptyPaymentLinkIMageIcon = () => (
  <svg width="254" height="211" viewBox="0 0 254 211" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ borderRadius: '8px' }}>
    <rect y="-20" width="254" height="254" rx="16.9333" fill="#E6E7EC" />
    <path fillRule="evenodd" clipRule="evenodd" d="M154.322 48.261H99.3816C80.2554 48.261 68.2603 61.8119 68.2603 80.9889V132.716C68.2603 151.893 80.2046 165.444 99.3816 165.444H154.29C173.499 165.444 185.437 151.893 185.437 132.716V80.9889C185.462 61.8119 173.518 48.261 154.322 48.261Z" stroke="#9AA1B1" strokeWidth="6.35" strokeLinecap="round" strokeLinejoin="round" />
    <path fillRule="evenodd" clipRule="evenodd" d="M118.765 86.5844C118.765 93.0614 113.519 98.3065 107.042 98.3065C100.572 98.3065 95.3203 93.0614 95.3203 86.5844C95.3203 80.1074 100.572 74.8623 107.042 74.8623C113.513 74.8687 118.758 80.1138 118.765 86.5844Z" stroke="#9AA1B1" strokeWidth="6.35" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M185.464 125.739C179.603 119.706 168.332 107.521 156.076 107.521C143.815 107.521 136.747 134.4 124.955 134.4C113.163 134.4 102.451 122.246 93.0019 130.038C83.5531 137.823 74.6123 153.742 74.6123 153.742" stroke="#9AA1B1" strokeWidth="6.35" strokeLinecap="round" strokeLinejoin="round" />
  </svg>

);
export default EmptyPaymentLinkIMageIcon;
