import { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';

const useCustomQuery = ({ query, onData, onError }) => {
  const [isSuccess, setIsSuccess] = useState(null);
  const [getData, {
    error, data, loading,
  }] = useLazyQuery(query, {
    // fetchPolicy: 'network-only',
    nextFetchPolicy: 'no-cache',
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  });
  const { push } = useHistory();

  useEffect(() => {
    if (loading && isSuccess) setIsSuccess(false);
  }, [loading]);

  useEffect(() => {
    if (data) {
      onData?.(data, setIsSuccess);
    }
    if (error) {
      if (
        error?.message?.toLowerCase?.()
        === 'sorry you have no access to this resource.'
      ) {
        push('/not-allowed');
      }
      onError?.(error, data);
    }
  }, [data, error]);

  return [getData, loading, data];
};

export default useCustomQuery;
