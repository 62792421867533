/* eslint-disable max-len */
import React from 'react';
import './StyledButton.scss';

interface IStyledButton{
    name?:any;
    width?:string;
    height?:string;
    backgroundColor?:string;
    color?:string;
    fontSize?:string;
    onClick?:any;
    borderRadius?:string;
    border?:string;
    disabled?:boolean;
    icon?:any;
    hideButtonClass?:any;
    fontWeight?:any;
    marginLeft?:string;
    padding?:string;
    marginBottom?:string;
    marginRight?:string;
    rightIcon?:any;
    opacity?:number;
}
const StyledButton = (props:IStyledButton) => (

  <button
    className={props?.hideButtonClass}
    style={{
      color: props?.color,
      backgroundColor: props?.backgroundColor,
      width: props?.width,
      height: props?.height,
      fontSize: props?.fontSize,
      borderRadius: props?.borderRadius,
      border: props?.border,
      opacity: props?.opacity || (props?.disabled ? 0.5 : 1),
      fontWeight: props?.fontWeight,
      marginLeft: props?.marginLeft,
      padding: props?.padding,
      marginBottom: props?.marginBottom,
      marginRight: props?.marginRight,
    }}
    disabled={props?.disabled}
    onClick={props?.onClick}
  >
    {
      props?.icon && <span>{props?.icon}</span>
    }

    {props?.name}
    {
      props?.rightIcon && <span style={{ marginLeft: '14px' }}>{props?.rightIcon}</span>
    }
  </button>
);
export default StyledButton;
