import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import PdfLeftIcon from '../../svgs/PdfLeftIcon';
import PdfRightIcon from '../../svgs/PdfRightIcon';
import Thumbpages from './Thumbpages';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

type Props = {
  src: string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const PdfViewer = ({ src }: Props) => {
  const [totalPages, setTotalPages] = useState(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    // maxPage allowed is 16
    setTotalPages(numPages > 16 ? 16 : numPages);
  };

  const goNext = () => {
    setPageNumber((page) => {
      if (page < totalPages) {
        return page + 1;
      }
      return page;
    });
  };

  const goPrev = () => {
    setPageNumber((page) => {
      if (page > 1) {
        return page - 1;
      }
      return page;
    });
  };
  return (
    <>
      <div className="pdf-cover">
        <div className="pdf-view">
          <Document file={src} onLoadSuccess={onDocumentLoadSuccess}>
            <Page pageNumber={pageNumber} width="400" height="250" />
          </Document>
        </div>
      </div>
      <Thumbpages
        currentPage={pageNumber}
        totalPages={totalPages}
        setPage={(num) => setPageNumber(num)}
      />
      {totalPages > 1 && (
      <div className="navigation">
        {pageNumber > 1 && (
        <div onClick={goPrev} className="left">
          <PdfLeftIcon />
        </div>
        )}
        {pageNumber < totalPages && (
        <div onClick={goNext} className="right">
          <PdfRightIcon />
        </div>
        )}
      </div>
      )}
    </>
  );
};

export default PdfViewer;
