import { FunctionComponent } from 'react';
import AdminActivateAccountComp from './AdminActivateAccountComp';
import MerchantActivationAccountComp from './MerchantActivationAccountComp';
import './OnboardingWrap.scss';

export interface IActivateAccount {
  label?: string;
  CustomComp?: any;
  CustomLabelElement?: any;
  CustomTextComp?: any;
}

const ActivateAccount: FunctionComponent<IActivateAccount> = ({
  label,
  CustomComp,
  CustomLabelElement,
  CustomTextComp,
}) => {
  const rootActivateAccount = process.env.REACT_APP_TYPE === 'ADMIN' ? (
    <AdminActivateAccountComp
      label={label}
      CustomComp={CustomComp}
      CustomLabelElement={CustomLabelElement}
      CustomTextComp={CustomTextComp}
    />
  ) : (
    <MerchantActivationAccountComp
      label={label}
      CustomComp={CustomComp}
      CustomLabelElement={CustomLabelElement}
      CustomTextComp={CustomTextComp}
    />
  );
  return (
    <div>{rootActivateAccount}</div>
  );
};

export default ActivateAccount;
