import { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import LoginContComp from '../login/LoginContComp';
import './Register.scss';
// import RegistrationContComp from './RegistrationContComp';
import RegistrationForm from './RegistrationForm';

export interface ILogin {
  label?: string;
}

const Register: FunctionComponent<ILogin> = () => (
  <div className="register-page-view">
    <div className="register-page-cover app-max-width">
      <div className="row register-page-cover-row">
        <LoginContComp />
        {/* <RegistrationContComp /> */}
        <div className="col-md-6 col-12 register-page-cover-row-form">
          <RegistrationForm />
          <p className="register-page-cover-row-form-login-link">
            Already have an account?
            <Link to="/" className="register-page-cover-row-form-login-link-tag">Sign In</Link>
          </p>
        </div>
      </div>
    </div>
  </div>

);

export default Register;
