import { FunctionComponent } from 'react';
import { useGlobalState } from '../../contexts';
import Steps from './Steps';
import './StepsCard.scss';

export interface IStepsCard {
  label?: string;
  name?: string;
}

const StepsCard: FunctionComponent<IStepsCard> = () => {
  const { profile }:any = useGlobalState();

  return (
    <div className="steps-card">
      <div className="merchant-onboarding-top">
        <div className="title">Verify your Business</div>
        <div className="subtitle">This helps us ensure you comply with regulations</div>
      </div>
      <Steps
        stepNumber={profile?.merchant?.onboardingStage || 1}
      />
    </div>
  );
};

export default StepsCard;
