import React from 'react';

interface ICopyIcon {
color?:any;
width?:any;
height?:any;
}
const CopyIcon = ({ color, height, width }:ICopyIcon) => (
  <svg width={width || '18'} height={height || '18'} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.5 5.99805H7.5C6.67157 5.99805 6 6.66962 6 7.49805V13.498C6 14.3265 6.67157 14.998 7.5 14.998H13.5C14.3284 14.998 15 14.3265 15 13.498V7.49805C15 6.66962 14.3284 5.99805 13.5 5.99805Z" stroke={color || '#121212'} strokeWidth="0.75" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12 6.00195V4.50195C12 4.10413 11.842 3.7226 11.5607 3.44129C11.2794 3.15999 10.8978 3.00195 10.5 3.00195H4.5C4.10218 3.00195 3.72064 3.15999 3.43934 3.44129C3.15804 3.7226 3 4.10413 3 4.50195V10.502C3 10.8998 3.15804 11.2813 3.43934 11.5626C3.72064 11.8439 4.10218 12.002 4.5 12.002H6" stroke={color || '#121212'} strokeWidth="0.75" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default CopyIcon;
