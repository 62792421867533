import React from 'react';
import './PreCheck.scss';

interface IprefCheck{
  value?:boolean;
  onChange?:any;
}
const PrefCheck = ({ value, onChange }:IprefCheck) => (
  <input type="checkbox" checked={value} onChange={onChange} className="pref-check-input" />
);
export default PrefCheck;
