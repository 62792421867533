import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import useForm from '../../../customHooks/useForm';
import { GET_BUSINESS_PROFILE_DATA } from '../../../graphql/queries';
import useCustomQuery from '../../../network/useCustomQuery';
import {
  addHttpsToLinks,
  getBusinessInfo, getDoc, getLocalGovt, getPhoneNum, getStates,
} from '../../../utils/helpers';
import TextInput from '../../../components/input';
import CountrySelectOption from '../../../components/CountrySelectOption';
import InstagramIcon from '../../../svgs/InstagramIcon';
import TwitterIcon from '../../../svgs/TwitterIcon';
import FacebookIcon from '../../../svgs/FacebookIcon';
import StyledButton from '../../../components/StyledButton';
import { useGlobalDispatch, useGlobalState } from '../../../contexts';
import useToast from '../../../customHooks/useToast';
import { UPDATE_BUSINESS_PROFILE } from '../../../graphql/mutations';
import UploadInputKYC from '../../../components/UploadInputKYC';
import SelectSearch from '../../../components/SelectSearch';

const BusinessForm = () => {
  const Toast = useToast();
  const { setStep, get_profile_in_app }:any = useGlobalDispatch();
  const [loadingDetails, setLoadingDetails] = useState(false);
  const [file, setFile] = useState(null);
  const [getIndustryRecords, getIndustryRecordsLoading, industryData] = useCustomQuery({
    query: GET_BUSINESS_PROFILE_DATA,
    onError: () => {},
    onData: () => {},
  });

  const { profile }:any = useGlobalState();
  const disableAllForm = profile?.merchant?.documentSentForActivation;
  const [updateBusinessProfile] = useMutation<
      { createSaving: any; updateBusinessProfile: any },
      any
    >(UPDATE_BUSINESS_PROFILE);

  useEffect(() => {
    getIndustryRecords();
  }, []);
  const {
    formValues,
    handleChange,
    checkAllRequired,
    resetForm,
  } = useForm(
    {
      institutionType: '',
      businessType: '',
      longDesc: '',
      website: '',
      contactPhone: '',
      name: '',
      categoryId: '',
      country: 'Nigeria',
      street: '',
      city: '',
      state: '',
      facebook: '',
      twitter: '',
      instagram: '',
    },
  );
  const data = industryData?.getBusinessProfileData
    && getBusinessInfo(industryData?.getBusinessProfileData);

  const handleFileUpload = (inp: any) => {
    if (inp) {
      setFile(inp);
    }
  };

  const handleSubmit = async () => {
    setLoadingDetails(true);
    const numberPhone = /^[6-9]\d{9}$/;
    const instagram = addHttpsToLinks(formValues?.instagram);
    const facebook = addHttpsToLinks(formValues?.facebook);
    const website = addHttpsToLinks(formValues?.website);
    const twitter = addHttpsToLinks(formValues?.twitter);
    if (!checkAllRequired(['website', 'facebook', 'twitter', 'instagram'])) {
      setLoadingDetails(false);

      if (formValues?.businessType === '') {
        Toast.error('business type is required');
      }
      if (formValues?.institutionType === '') {
        Toast.error('institution type is required');
      }
      if (formValues?.longDesc === '') {
        Toast.error('business description is required');
      }
      if (formValues?.contactPhone === '') {
        Toast.error('business phone number is required');
      }

      if (formValues?.name === '') {
        Toast.error('business name is required');
      }
      if (formValues?.categoryId === '') {
        Toast.error('business category is required');
      }
      if (formValues?.city === '') {
        Toast.error('city is required');
      }
      if (formValues?.state === '') {
        Toast.error('state is required');
      }
      if (formValues?.street === '') {
        Toast.error('street name is required');
      }

      return;
    }
    if (!numberPhone.test(`${Number(formValues?.contactPhone)}`) && formValues?.contactPhone !== '') {
      Toast.error('Not a valid phone number');
      setLoadingDetails(false);
      return;
    }
    try {
      const payload = {
        ...formValues, instagram, facebook, website, twitter, file,
      };
      const resp = await updateBusinessProfile({
        variables: payload,
      });

      setLoadingDetails(false);
      if (resp?.data?.updateBusinessProfile?.status === 200) {
        Toast.success('Successful');
        get_profile_in_app();
        setStep(2);
      } else {
        Toast.error(resp?.data?.updateBusinessProfile?.message);
      }
    } catch (e) {
      setLoadingDetails(false);
      if (e && e.graphQLErrors && e.graphQLErrors.length > 0) {
        Toast.error(e.graphQLErrors[0].message);
      } else {
        Toast.error('Something went wrong');
      }
    }
  };
  useEffect(() => {
    resetForm({
      institutionType: formValues?.categoryId,
    });
  }, [formValues?.categoryId]);

  useEffect(() => {
    if (profile?.merchant) {
      const thisMerchant = profile?.merchant;
      resetForm({
        institutionType: thisMerchant?.institutionType,
        businessType: thisMerchant?.businessType,
        longDesc: thisMerchant?.longDesc,
        website: thisMerchant?.website,
        contactPhone: getPhoneNum(thisMerchant?.contactPhone),
        name: thisMerchant?.name,
        categoryId: thisMerchant?.institutionType,
        country: thisMerchant?.country,
        city: thisMerchant?.city,
        state: thisMerchant?.state,
        street: thisMerchant?.street,
        facebook: thisMerchant?.facebook,
        twitter: thisMerchant?.twitter,
        instagram: thisMerchant?.instagram,
      });
    }
  }, [profile]);
  const [getBusinessData, setgetBusinessData] = useState([]);
  const [getCategoryData, setgetCategoryData] = useState([]);
  useEffect(() => {
    const businessArray = data?.businessTypes?.map((list) => {
      const data = {
        value: list?.value,
        text: list?.text,
        label: list?.name,
      };
      return data;
    });
    setgetBusinessData(businessArray);
    const categoryArray = data?.categories?.map((list) => {
      const data = {
        value: list?.value,
        text: list?.text,
        label: list?.name,
      };
      return data;
    });
    setgetCategoryData(categoryArray);
  }, [getIndustryRecordsLoading]);
  const [getStatesData, setgetStatesData] = useState([]);
  useEffect(() => {
    const stateList = [];
    getStates()?.map((list) => {
      const data = {
        value: list?.value,
        text: list?.text,
        label: list?.text,
      };
      return stateList.push(data);
    });
    setgetStatesData(stateList);
  }, []);
  const [getCityData, setgetCityData] = useState([]);
  useEffect(() => {
    const cityList = [];
    if (formValues?.state) {
      getLocalGovt(formValues?.state)?.map((list) => {
        const data = {
          value: list?.value,
          text: list?.text,
          label: list?.text,
        };
        return cityList.push(data);
      });
      setgetCityData(cityList);
    }
  }, [formValues?.state]);
  return (
    <div className="business-form-cover">
      <div className="business-form-type">
        <div className="business-form-type-row-profile row m-0 p-0">
          <div className="col-md-6 col-12 m-0 p-0 business-form-type-col-left select-business-type-div" style={{ marginBottom: '20px' }}>
            <SelectSearch
              options={getBusinessData}
              placeholder="Business Type"
              name="businessType"
              value={formValues.businessType}
              required={false}
              classes={false}
              hideLabel
              onChange={handleChange}
              disabled={disableAllForm}
            />
          </div>
          <div className="col-md-6 col-12 m-0 p-0 business-form-type-col-right">
            <TextInput
              customOnChange={handleChange}
              name="name"
              value={formValues.name}
              label="Registered Business Name"
              width="100%"
              disabled={disableAllForm}
            />
          </div>
          <div className="col-md-6 col-12 m-0 p-0 business-form-type-col-left">
            <TextInput
              name="longDesc"
              value={formValues.longDesc}
              customOnChange={handleChange}
              rows={3}
              multiline
              label="Business Description"
              placeholder="Tell us what you do"
              width="100%"
              disabled={disableAllForm}
            />
          </div>
          <div className="col-md-6 col-12 m-0 p-0 business-form-type-col-right">
            <SelectSearch
              label=""
              options={getCategoryData}
              placeholder="Category"
              name="categoryId"
              value={formValues.categoryId}
              required={false}
              classes={false}
              hideLabel
              onChange={handleChange}
              disabled={disableAllForm}
            />
          </div>
        </div>

        <div className="business-form-type-row-contact row m-0 p-0">
          <div className="title col-12 p-0 m-0">Business Contact</div>
          <div className="subtitle col-12 p-0 m-0">Let us know how to contact you</div>
          <div className="col-md-6 col-12 m-0 p-0 business-form-type-row-contact-left">
            <div className="row m-0 p-0 left-inner-form-row">
              <div className="col-md-12 col-12 m-0 p-0">
                <TextInput
                  name="street"
                  value={formValues?.street}
                  customOnChange={handleChange}
                  label="Street Name"
                  width="100%"
                  disabled={disableAllForm}
                />
              </div>
              <div className="col-md-4 col-sm-3 col-5 m-0 p-0 left-inner-form-row-left-col">
                <CountrySelectOption />
              </div>
              <div className="col-md-8 col-sm-9 col-7 m-0 p-0 left-inner-form-row-right-col">
                <TextInput
                  name="contactPhone"
                  value={formValues?.contactPhone?.trim?.()}
                  customOnChange={handleChange}
                  label="Phone Number"
                  width="100%"
                  disabled={disableAllForm}
                  type="tel"
                />
              </div>
            </div>
            <div className="col-12 p-0 m-0">
              <UploadInputKYC
                name="contactDoc"
                label="Upload contact proof"
                filename="PROOF_OF_ADDRESS_DOCUMENT"
                fileInp={getDoc('proofOfAddress', profile?.merchant?.documents)}
                customFileUploadhandler={handleFileUpload}
                disabled={disableAllForm}
              />
            </div>
          </div>
          <div className="col-md-6 col-12 m-0 p-0 business-form-type-row-contact-right">
            <div className="row m-0 p-0 right-inner-form-row">
              <div className="col-xl-6 col-lg-12 col-md-6 m-0 p-0 ">
                <div style={{ marginBottom: '20px' }}>
                  <SelectSearch
                    label=""
                    options={getStatesData}
                    placeholder="State"
                    name="state"
                    value={formValues.state}
                    required={false}
                    classes={false}
                    hideLabel
                    onChange={handleChange}
                    disabled={disableAllForm}
                  />
                </div>
              </div>
              <div className="col-xl-6 col-lg-12 col-md-6 m-0 p-0 right-inner-form-row-right-col">
                <div style={{ marginBottom: '20px' }}>
                  <SelectSearch
                    label=""
                    options={getCityData}
                    placeholder="City"
                    name="city"
                    value={formValues.city}
                    required={false}
                    classes={false}
                    hideLabel
                    onChange={handleChange}
                    disabled={disableAllForm}
                  />
                </div>
              </div>
              <div className="col-12 p-0 m-0">
                <TextInput
                  name="website"
                  value={formValues?.website}
                  customOnChange={handleChange}
                  label="Website URL"
                  width="100%"
                  disabled={disableAllForm}
                />
              </div>
            </div>
          </div>

        </div>
        {/* social media details form */}
        <div className="business-form-type-row-social-media row m-0 p-0">
          <div className="title col-12 p-0 m-0">Social Media Details</div>
          <div className="sub-title col-12 p-0 m-0">Kindly fill in your social media handles</div>
          <div className="col-md-6 col-12 m-0 p-0 business-form-type-row-social-media-left">
            <TextInput
              label="facebook"
              name="facebook"
              value={formValues?.facebook}
              customOnChange={handleChange}
              width="100%"
              preIcon={<FacebookIcon />}
              hasStyle
              paddingLeft="30px"
              position="absolute"
              zIndex="1000"
              left="10px"
              top="14px"
              disabled={disableAllForm}
            />
          </div>
          <div className="col-md-6 col-12 m-0 p-0 business-form-type-row-social-media-right">
            <TextInput
              label="twitter"
              name="twitter"
              value={formValues?.twitter}
              customOnChange={handleChange}
              width="100%"
              preIcon={<TwitterIcon />}
              hasStyle
              paddingLeft="30px"
              position="absolute"
              zIndex="1000"
              left="10px"
              top="14px"
              disabled={disableAllForm}
            />

          </div>
          <div className="col-md-6 col-12 m-0 p-0 business-form-type-row-social-media-left">
            <TextInput
              name="instagram"
              value={formValues?.instagram}
              customOnChange={handleChange}
              label="instagram"
              width="100%"
              preIcon={<InstagramIcon />}
              hasStyle
              paddingLeft="30px"
              position="absolute"
              zIndex="1000"
              left="10px"
              top="14px"
              disabled={disableAllForm}
            />
          </div>
        </div>
        {/* {getErrorComp()} */}
        {/* button div */}
        <div className="business-form-type-button">
          <div>
            <StyledButton disabled={getIndustryRecordsLoading || loadingDetails || disableAllForm} name={loadingDetails ? 'Loading...' : getIndustryRecordsLoading ? 'Fetching data...' : 'Save & Proceed'} width="100%" height="40px" backgroundColor="#523B9F" color="white" border="none" borderRadius="4px" fontSize="13px" onClick={handleSubmit} />
          </div>
        </div>
      </div>
    </div>
  );
};
export default BusinessForm;
