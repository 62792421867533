import { FunctionComponent } from 'react';
import './SettlementFlashCardNew.scss';
import { formatCash } from '../../utils/tableHelpers';

export interface ISettlementFlashCardNew {
  name?: string;
  value?: number;
  Icon?: any;
  colored?: boolean;
  notAmount?: boolean;
}

const getIntAndDecimal = (value: number): any => {
  const splitters = value?.toFixed?.(2)?.toString()?.split('.') || [];
  const int = formatCash(Number(splitters[0] || 0), 'N', false);
  const decimal = splitters[1] || '00';
  return { int, decimal };
};

const SettlementFlashCardNew: FunctionComponent<ISettlementFlashCardNew> = ({
  name,
  value,
  Icon,
  colored,
  notAmount,
}) => {
  const rec = notAmount ? { } : getIntAndDecimal(value || 0);

  return (
    <div className={`settlement-flashcard-new ${colored ? 'colored-flashcard' : ''}`}>
      {Icon && <Icon />}
      <div className="card-content">
        <h6>{name}</h6>
        <div className="card-amount">
          {notAmount ? (<strong>{value}</strong>) : (
            <>
              <strong>
                {rec?.int}
                .
              </strong>
              <small>{rec?.decimal}</small>
            </>
          )}
        </div>
      </div>

    </div>
  );
};

export default SettlementFlashCardNew;
