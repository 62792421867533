/* eslint-disable max-len */
import {
  useCallback, useEffect, useState,
} from 'react';
import useForm from '../../../customHooks/useForm';
import useToast from '../../../customHooks/useToast';
import { SuccessModal } from '../../../components/Modal';
import ModalFormComp from '../../../components/ModalFormComp';
import SuccessCheckIcon from '../../../svgs/SuccessCheckIcon';

import './CreateNewLoanModal.scss';
import PreviewLoan from './PreviewLoan';
import { returnNumber } from '../../../utils/helpers';
import ProductAndFeesView from './ProductAndFeesView';
import MerchantAndLoanConfigForm from './MerchantAndLoanConfigForm';
import useCustomQuery from '../../../network/useCustomQuery';
import { GET_MERCHANT_AND_LOAN_CONFIG } from '../../../graphql/admin_queries';

interface ICreateNewLoanModal{
  closeModal?:any;
  refetchData?:any;
  editDetails?:any;
  payload?: any;
  customerDetails?: any;
  defaultSdkConfiguration?: any;
  refetch?: any
}

const CreateNewLoanModal = ({
  closeModal, customerDetails, refetch,
}:ICreateNewLoanModal) => {
  const Toast = useToast();
  const [merchantId, setMerchantId] = useState('');
  const [scheduleDate, setScheduleDate] = useState(new Date());
  const [showNext, setShowNext] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [selected, setSelected] = useState<any>();
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [manualLoanPreviewDetails, setManualLoanPreviewDetails] = useState<any>();

  const [getMerchantsAndLoanConfig, isLoading, merchantsAndLoanConfigData] = useCustomQuery({
    query: GET_MERCHANT_AND_LOAN_CONFIG,
    onError: () => {},
    onData: () => {},
  });

  const fetchMerchantsAndLoanConfig = useCallback(
    () => getMerchantsAndLoanConfig(),
    [],
  );

  const setMerchantDetails = (merchantId) => {
    setMerchantId(merchantId);
    const selectedMerchant = merchantsArray?.find?.((ele) => ele.id === merchantId);
    setSelected(selectedMerchant);
  };

  useEffect(() => {
    fetchMerchantsAndLoanConfig();
  }, [fetchMerchantsAndLoanConfig]);

  const getMerchantResult = merchantsAndLoanConfigData?.getMerchantsAndLoanConfig?.data;
  const merchantsArray = getMerchantResult?.merchants || [];
  const defaultSdkConfiguration = getMerchantResult?.config;

  const {
    formValues, handleChange, resetForm,
  } = useForm(
    {
      downPaymentPercentage: '',
      loanTenor: '',
      productName: '',
      interestRate: '',
      processingFee: '',
      insurancePremium: '',
      productAmount: '',
    },
    {},
    Toast,
  );

  useEffect(() => {
    console.log('selected', selected);
    if (selected) {
      resetForm({
        interestRate: `${returnNumber(selected?.sdk_config?.interestRate, defaultSdkConfiguration?.interestRate)
        }`,
        processingFee: `${returnNumber(selected?.sdk_config?.processing_fee, defaultSdkConfiguration?.processing_fee)
        }`,
        insurancePremium: `${returnNumber(selected?.sdk_config?.insurance_premium, defaultSdkConfiguration?.insurance_premium)
        }`,

      });
    }
  }, [selected]);

  const renderForm = () => {
    // if its the preview loan screen
    if (showPreview) {
      return (
        <PreviewLoan
          manualLoanPreviewDetails={manualLoanPreviewDetails}
          loading={loading}
          setShowPreview={setShowPreview}
          customerDetails={customerDetails}
          formValues={formValues}
          merchantName={selected?.name}
          merchantId={merchantId}
          setLoading={setLoading}
          scheduleDate={scheduleDate}
          setShowSuccessModal={setShowSuccessModal}
          refetch={refetch}
        />
      );
    }

    // if its the product and fees screen
    if (showNext) {
      return (
        <ProductAndFeesView
          formValues={formValues}
          handleChange={handleChange}
          loading={loading}
          setShowNext={setShowNext}
          scheduleDate={scheduleDate}
          setShowPreview={setShowPreview}
          setManualLoanPreviewDetails={setManualLoanPreviewDetails}
          setLoading={setLoading}
          merchantId={merchantId}
          userId={customerDetails?.id}
        />
      );
    }

    // if its the merchant and loan config screen(first screen)
    return (
      <MerchantAndLoanConfigForm
        formValues={formValues}
        handleChange={handleChange}
        closeModal={closeModal}
        scheduleDate={scheduleDate}
        setScheduleDate={setScheduleDate}
        merchantId={merchantId}
        setMerchantDetails={setMerchantDetails}
        loading={isLoading}
        userName={`${customerDetails?.firstName} ${customerDetails?.lastName}`}
        setShowNext={setShowNext}
        merchants={merchantsArray}
      />
    );
  };

  return (
    <>
      {showSuccessModal ? (
        <SuccessModal
          title="Loan Created"
          subTitle="Loan has been successfully created."
          closeModal={() => {
            closeModal();
            // refetch();
          }}
          Icon={SuccessCheckIcon}
        />
      ) : (
        <ModalFormComp
          removeTitle
          modalType="create_loan_modal_cover"
          closeModal={() => {}}
          cancelCloseAction={closeModal}
          cancleDivWidth="100%"
        >
          <div className="create_loan_cover">
            <div className="create_loan_title">{showPreview ? 'Loan Page Preview' : 'Create New Loan'}</div>
            <div className="create_loan_subtitle">{showPreview ? 'Preview the details of the loan you are trying to create.' : 'Create a new loan by providing the information below'}</div>
            {
              renderForm()
            }
          </div>

        </ModalFormComp>
      )}
    </>
  );
};
export default CreateNewLoanModal;
