import { FunctionComponent, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import {

  useGlobalState,
} from '../../../contexts';
import TextInput from '../../../components/input';
import './APIWebhooks.scss';
import useForm from '../../../customHooks/useForm';
import useToast from '../../../customHooks/useToast';

import { SET_WEBHOOK } from '../../../graphql/mutations';
import StyledButton from '../../../components/StyledButton';

export interface IWebhooks {
  label?: string;
}

const Webhooks: FunctionComponent<IWebhooks> = () => {
  const { profile }:any = useGlobalState();

  const {
    formValues, handleChange, resetForm,
  } = useForm({
    callback: '',
    webhook: '',
  });
  const Toast = useToast();

  useEffect(() => {
    if (profile?.merchant) {
      resetForm({
        callback: profile?.merchant?.callback,
        webhook: profile?.merchant?.webhook,
      });
    }
  }, [profile]);

  const [loading, setLoading] = useState(false);

  const [updateWebhooksProfile] = useMutation<
    { createSaving: any; setWebhookEndpoint: any },
    any
  >(SET_WEBHOOK);

  const handleSubmit = async () => {
    setLoading(true);
    // if (!checkAllRequired(['logo'])) {
    //   setLoading(false);
    //   Toast.error('Please complete all fields');
    //   return;
    // }
    try {
      const payload: { callback: string } = formValues;
      const resp = await updateWebhooksProfile({ variables: payload });

      setLoading(false);
      if (resp?.data?.setWebhookEndpoint?.message) {
        Toast.success('Webhooks information saved successfully');
      } else {
        Toast.error(resp?.data?.setWebhookEndpoint?.message);
      }
    } catch (e) {
      setLoading(false);
      if (e && e.graphQLErrors && e.graphQLErrors.length > 0) {
        Toast.error(e.graphQLErrors[0].message);
      } else {
        Toast.error('Something went wrong');
      }
    }
  };

  return (
    <div className="webhook-form-cover row m-0 p-0">
      <div className="col-md-6 col-12 p-0 m-0 webhook-form-cover-col-left">
        <div className="webhook-form-cover-col-left-title">Webhooks</div>
        <div className="webhook-form-cover-subtitle-and-edit-cover">
          <div className="webhook-form-cover-col-left-subtitle">
            These keys help you integrate
            your business with Payfi
          </div>
          {/* <div className="webhook-form-cover-col-left-edit-profile">
            <span>Add Endpoints</span>
          </div> */}
        </div>
      </div>
      <div className="col-md-6 col-12 m-0 p-0 webhook-form-cover-col-right">
        <div>

          <div className="form-cover">
            <TextInput
              customOnChange={handleChange}
              name="callback"
              value={formValues.callback}
              label="Callback URL"
              width="100%"
            />
            <TextInput
              customOnChange={handleChange}
              name="webhook"
              value={formValues.webhook}
              label="Webhook URL"
              width="100%"
            />
          </div>
          <div className="button-div">
            <StyledButton name={loading ? 'Saving...' : 'Submit'} disabled={loading} onClick={handleSubmit} width="fit-content" borderRadius="4px" height="40px" color="white" border="none" backgroundColor="#523B9F" fontSize="13px" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Webhooks;
