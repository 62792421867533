import { useMutation } from '@apollo/client';
import { CircularProgress, FormControl } from '@material-ui/core';
import { useState } from 'react';

import useForm from '../../customHooks/useForm';
import useToast from '../../customHooks/useToast';
import { GEN_PAYMENT_LINK } from '../../graphql/mutations';
import Buttons from '../../components/Buttons';
import CountryPhone from '../../components/CountryPhone';
import TextInput from '../../components/input';

// const isDev = process.env.REACT_APP_ENV === 'development';

const GenPaymentLink = () => {
  const [loading, setLoading] = useState(false);
  const [error] = useState(false);
  const { handleChange, resetForm, formValues } = useForm({
    amount: '',
    desc: '',
    reference: '',
    payfiId: '',
  });
  const Toast = useToast();
  const [isCopied, setIsCopied] = useState(false);

  const [generatePaymentLink] = useMutation<{ generatePaymentLink: any }, any>(
    GEN_PAYMENT_LINK,
  );

  const [generatedLink, setGeneratedLink] = useState('');

  const copyToClipboard = () => {
    if (!navigator.clipboard) {
      Toast.error('Browser does not support this feature');
      return;
    }
    navigator.clipboard.writeText(generatedLink).then(
      () => {
        Toast.success('generated link copied successfully');
        setIsCopied(true);
      },
      () => {
        Toast.error('Browser does not support this feature');
      },
    );
  };

  const handleOnSubmit: (arg: any) => void = async (e) => {
    e.preventDefault();

    try {
      if (isCopied) setIsCopied(false);
      const numberPhone = /^[6-9]\d{9}$/;
      if (
        !numberPhone.test(`${Number(formValues?.payfiId)}`)
        && formValues?.payfiId !== ''
      ) {
        Toast.error('Not a valid phone number');
        return;
      }

      setLoading(true);
      const res = await generatePaymentLink({
        variables: {
          ...formValues,
          payfiId: `0${Number(formValues?.payfiId)}`,
          amount: Number(formValues?.amount),
        },
      });
      if (res?.data?.generatePaymentLink?.status !== 200) {
        Toast.error(res?.data?.generatePaymentLink?.message);
        return;
      }
      setGeneratedLink(res?.data?.generatePaymentLink?.link);
      resetForm();
    } catch (e) {
      if (e && e.graphQLErrors && e.graphQLErrors.length > 0) {
        Toast.error(e.graphQLErrors[0].message);
      } else {
        Toast.error('Something went wrong');
      }
    }
    setLoading(false);
  };
  return (
    <form
      onSubmit={handleOnSubmit}
      className="d-flex flex-column align-items-center instore-form"
    >
      <div className="instore-container">
        <div className="amount-container">
          <h2 className="text-center font-weight-bold">
            Generate Payment Link
          </h2>
          {generatedLink && (
            <div className="generated-link d-flex">
              <span>{generatedLink}</span>
              <Buttons
                className="doc-modal-buttons btn-sm text-grey bg-light-pri"
                name={isCopied ? 'Copied' : 'Copy'}
                onClick={copyToClipboard}
              />
            </div>
          )}
          <FormControl className=" d-flex flex-column">
            <div className="phone-input-comp">
              <CountryPhone />
              <TextInput
                customClasses="country-phone-inp"
                label="Customer Phone"
                name="payfiId"
                width="100%"
                customOnChange={handleChange}
                value={formValues?.payfiId}
                required
              />
            </div>
            <TextInput
              label="Amount"
              customOnChange={handleChange}
              name="amount"
              type="number"
              value={formValues?.amount}
              required
            />
            <TextInput
              label="Description"
              customOnChange={handleChange}
              name="desc"
              value={formValues?.desc}
              rows={3}
              required
              multiline
            />
            <TextInput
              label="Reference"
              customOnChange={handleChange}
              name="reference"
              value={formValues?.reference}
              required
            />

            {!!error && (
              <span className="error-flag" style={{ width: 280 }}>
                {error}
              </span>
            )}
            <button className="p-3 mb-2 bg-primary text-white rounded submit-button">
              {loading ? (
                <CircularProgress color="inherit" size={15} />
              ) : (
                'Generate Link'
              )}
            </button>
          </FormControl>
        </div>
      </div>
    </form>
  );
};

export default GenPaymentLink;
