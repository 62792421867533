/* eslint-disable array-callback-return */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable max-len */
/* eslint-disable no-plusplus */
import { useQuery } from '@apollo/client';
import moment from 'moment';
import { customAlphabet, urlAlphabet } from 'nanoid';
import Papa from 'papaparse';
import nigerianStates from './nigerian-states.json';
import { formatCash } from './tableHelpers';
import NewLevelUnlockedImage from '../assets/images/star-badge.svg';

export const getGroupItems = (array, property) => array.reduce((groups, item) => {
  const name = item[property];
  // eslint-disable-next-line no-param-reassign
  const group = groups[name] || (groups[name] = []);
  group.push(item);
  return groups;
}, { });

export const getTimeStampDaysAgo = (days: number) => {
  const now = new Date();
  const daysAgo = new Date(now);
  daysAgo.setDate(now.getDate() - days);
  return daysAgo.getTime();
};

export const getRepaymentStatus = (dueDate: string, isFullyPaid: boolean) => {
  if (isFullyPaid) return 'Paid';
  // if due date is less than 2 days ago, return defaulting
  const now = new Date();
  const twoDaysAgo = new Date(now);
  twoDaysAgo.setDate(now.getDate() - 2);
  const dueDateObj = new Date(dueDate);
  if (dueDateObj <= twoDaysAgo) {
    return 'Defaulting';
  }
  // if it is greater than 2 days ago and less than or equal today return due
  if (dueDateObj > twoDaysAgo && dueDateObj <= now) {
    return 'Due';
  }
  // if it is greater than today return not due
  return 'Not Due';
};

const validateUrl = (url) => {
  const regex = /(http(s?):)([/|.|\w|\s|-])*\.(?:jpg|gif|png)/g;
  if (regex.test(url)) return url;
  return null;
};

const airtimeImages = {
  glo: 'https://baxi-biller-pod-images.s3.eu-west-1.amazonaws.com/images/glo.jpg',
  airtel: 'https://baxi-biller-pod-images.s3.eu-west-1.amazonaws.com/images/airtel.png',
  mtn: 'https://baxi-biller-pod-images.s3.eu-west-1.amazonaws.com/images/mtn.png',
  '9mobile': 'https://baxi-biller-pod-images.s3.eu-west-1.amazonaws.com/images/9mobile.jpg',
  smile: 'https://baxi-biller-pod-images.s3.eu-west-1.amazonaws.com/images/smile.png',
  spectranet: 'https://baxi-biller-pod-images.s3.eu-west-1.amazonaws.com/images/spectranet.png',
};

export const toTitleCase = (str: string) => str.replace(
  /\w\S*/g,
  (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(),
);
export const truncateWords = (data) => (data?.length > 20 ? `${data?.substring(0, 20)}....` : data);

export const getBusinessInfo = ({
  businessTypes,
  industries,
  registrationTypes,
  categories,
}: {
  businessTypes?: [any];
  industries?: [any];
  registrationTypes?: [any];
  categories?: [any];
}) => {
  const out: {
    businessTypes?: any;
    industries?: any;
    registrationTypes?: any;
    categories?: any;
  } = {};
  if (businessTypes) {
    out.businessTypes = businessTypes?.map(
      ({ name, value }: { name: string; value: string }) => ({
        name,
        text: toTitleCase(name),
        value,
      }),
    );
  }

  if (industries) {
    out.industries = industries?.map(
      ({ name, code }: { name: string; code: string }) => ({
        name: toTitleCase(name),
        text: toTitleCase(name),
        value: code,
      }),
    );
  }

  if (registrationTypes) {
    out.registrationTypes = registrationTypes?.map(
      ({ name, code }: { name: string; code: string }) => ({
        name,
        text: toTitleCase(name),
        value: code,
      }),
    );
  }
  if (categories) {
    out.categories = categories?.map(
      ({ name, id }: { name: string; id: string }) => ({
        name,
        text: toTitleCase(name),
        value: id,
      }),
    );
  }
  return out;
};
export const capitalizeOnlyFirstLetter = (value) => value && (value?.charAt(0).toUpperCase() + value?.slice?.(1));

export const getStates = () => {
  const state = Object.keys(nigerianStates || [])?.map?.((key: string) => ({
    text: key,
    value: key,
  }));
  return state;
};

export const getLocalGovt = (state: string) => {
  const allStates: any = nigerianStates;
  if (!allStates) return [];
  const stateRec = allStates[state];
  const lga = stateRec?.map?.((record: string) => ({
    text: record,
    value: record,
  }));
  return lga || '';
};

export const getNameInitials = (name: string) => {
  const nameSplits = name?.trim?.()?.split?.(' ');
  if (!nameSplits?.length) return '';
  let initials = '';
  for (let index = 0; index < nameSplits.length; index++) {
    const splittedName = nameSplits[index];
    initials += `${splittedName?.[0]?.toUpperCase?.() || ''}`;
  }

  return initials.slice(0, 1);
};

export const getPhoneNum = (str: string | null | undefined): any => {
  const initials = str && str.slice(0, 4);
  if (initials === '+234' && str) {
    return `${str.slice(4, str.length)}`;
  }
  return str;
};

export const cleanUpBankDetails = (raw: any) => {
  if (!raw?.length) return [];
  const out = raw?.map(
    ({
      accountName,
      accountNumber,
      currency,
      bank,
      id,
      status,
      swiftCode,
    }: {
      accountName: string;
      status: any;
      accountNumber: string;
      currency: string;
      bank: any;
      id: string;
      swiftCode: string;
    }) => ({
      accountName,
      accountNumber,
      currency,
      bank: bank?.name,
      bankId: bank?.nipCode,
      swiftCode,
      id,
      enabled: status ? 'default' : 'inactive',
    }),
  );

  return out;
};

export const getKeys = (raw: any) => {
  const result = [];
  const publicKey = raw?.publicKey;
  const secretKey = raw?.secretKey;

  if (publicKey) {
    result.push({
      id: 1,
      name: 'Public Key',
      token: publicKey,
    });
  }
  if (secretKey) {
    result.push({
      id: 2,
      name: 'Secret Key',
      token: secretKey,
    });
  }
  return result;
};

export const cleanUpTeams = (raw: any) => {
  try {
    if (!raw?.length || !raw?.[0]?.firstName) {
      return [];
    }
    const out = raw?.map(
      ({
        firstName,
        lastName,
        email,
        role,
        id,
        createdAt,
        ...rest
      }: {
        firstName: string;
        status: any;
        lastName: string;
        email: string;
        role: any;
        id: string;
        createdAt: string;
        lastLogin: string;
        rest: any;
      }) => ({
        name: `${firstName} ${lastName}`,
        email,
        role,
        createdAt,
        id,
        firstName,
        lastName,
        ...rest,
        status: rest.status ? 'Active' : 'inactive',
        date: rest?.lastLogin ? moment(new Date(Number(rest?.lastLogin))).format('MMM DD, YYYY') : 'N/A',
        time: Number(rest?.lastLogin) ? moment(new Date(Number(rest?.lastLogin))).format('hh:mm a') : '',
        roleId: role?.id,
      }),
    );
    return out;
  } catch (error) {
    return [];
  }
};

export const getRawRecord = () => [
  {
    id: 1,
    name: 'Albert Okpala',
    email: 'albert.okpala@gmail.com',
    role: 'Administrator',
    type: 'Orders',
    amount: 450000,
    quantity: 100,
    currency: 'NGN',
    lastLogin: 'Wednesday March 24, 2021',
  },
  {
    id: 2,
    name: 'Igho Matthew',
    email: 'igho.matthew@gmail.com',
    role: 'User',
    lastLogin: 'Wednesday March 24, 2021',
    type: 'Orders',
    amount: 450000,
    quantity: 100,
    currency: 'NGN',
  },
  {
    id: 3,
    name: 'Kelvin Esegbna',
    email: 'kevoesegbona@gmail.com',
    role: 'User',
    lastLogin: 'Wednesday March 24, 2021',
    type: 'Orders',
    amount: 3050000,
    quantity: 900,
    currency: 'NGN',
  },
];

export const cleanUpCustomersOverview = (raw: any) => {
  if (!raw?.length) {
    return [];
  }

  const out = raw?.map(
    (
      {
        firstName,
        lastName,
        orders,
        createdAt,
        email,
        status,
        id,
        transactionAmount,
        payfiId,
        ...rest
      }: {
        id: string;
        firstName: string;
        email: string;
        lastName: string;
        orders: any;
        createdAt: string;
        status: string;
        transactionAmount: number;
        payfiId: string;
      },
      index,
    ) => ({
      id: id || index + 1,
      customerId: id,
      customerRef: payfiId,
      name: `${firstName} ${lastName}`,
      type: 'Total orders',
      image: null,
      quantity: orders,
      currency: 'NGN',
      email: email || 'name@test.com',
      date: moment(new Date(Number(createdAt))).format('MMM DD, YYYY'),
      time: moment(new Date(Number(createdAt))).format('hh:mm a'),
      dateTime: moment(new Date(Number(createdAt))).format('MMM DD, YYYY hh:mm a'),
      status: status ? 'Active' : 'Inactive',
      transactionAmount: formatCash(transactionAmount, 'N '),
      amountName: 'Total Amount',
      ...rest,
    }),
  );

  return out;
};

export const dateFormatter = (date: string | any, format: string, returnVal: string = '______') => {
  if (!date) return returnVal;
  if (new Date(date).toString() === 'Invalid Date') return returnVal;
  return moment(new Date(date)).format(format);
};

export const cleanSingleCustomerRecord = (customer: any) => {
  if (!customer?.firstName) return customer;
  return {
    ...customer,
    bank: customer?.bankDetail?.bank?.name,
    accountNumber: customer?.bankDetail?.accountNumber,
    createdAt: dateFormatter(customer?.createdAt, 'Do, MMM YYYY', ''),
    dob: dateFormatter(customer?.dob, 'Do, MMM YYYY', '') || customer?.dob || '',
    ...(customer?.primaryAddress || {}),
    ...(customer?.employmentdetail || {}),
    nextOfKinName: `${customer?.nextofkin?.firstName || ''} ${
      customer?.nextofkin?.lastName || ''
    }`.trim(),
    nextOfKinPhone: customer?.nextofkin?.phoneNumber,
    nextOfKinEmail: customer?.nextofkin?.email,
    nextOfKinRelationship: customer?.nextofkin?.relationship,
    nextOfKinAddress: customer?.nextofkin?.address,
    status: customer?.status ? 'ACTIVE' : (customer?.isDeleted ? 'DELETED' : 'INACTIVE'),
    headerStatus: customer?.status ? 'Active' : (customer?.isDeleted ? 'Deleted' : 'Inactive'),
    userId: customer?.id,
    isNysc:
      customer?.employmentdetail?.employmentStatus?.toLowerCase?.()
      === 'nysc member',
    company:
      customer?.employmentdetail?.employmentStatus?.toLowerCase?.()
      === 'nysc member'
        ? 'NYSC'
        : 'Payfi',
    name: `${customer?.firstName} ${customer?.lastName}`,
    promise: formatCash(customer?.promise, 'N '),
    offer: formatCash(customer?.offer, 'N '),
    id: customer?.id,
  };
};

export const cleanSingleMerchantCustomerRecord = (customer: any) => {
  if (!customer?.id) return customer;
  return ({
    ...customer,
    name: `${customer?.firstName || ''} ${customer?.lastName || ''}`.trim() || 'N/A',
    isActive: !!customer?.status,
    customerStatus: customer?.status ? 'Active' : 'InActive',
    transactionAmount: formatCash(customer?.transactionAmount, 'N '),
  });
};

export const getOnboardigStage = (stage: string | number, isNysc) => {
  const payfiTier = [
    'Incomplete',
    'Tier One',
    'Tier Two',
    'Tier Three',
    'Tier Four',
  ];

  const nyscTier = [
    'Incomplete',
    'Tier One',
    'Tier Two',
    'Tier Three',
    'Tier Four',
  ];
  const stages = isNysc ? nyscTier : payfiTier;
  return stages[stage];
};

export const getOnboardigStagesArr = (isNysc: boolean): string[] => {
  const payfiStage = [
    'Incomplete',
    'Employment Details',
    'Personal Details',
    'BVN Verification',
    'Debit Card',
    'Account Verification',
    'Identity Verification',
    'Completed',
  ];
  const nyscStage = [
    'Incomplete',
    'Employment Details',
    'Contact Details',
    'Identity Verification',
    'BVN Verification',
    'Bank Details',
    'Debit Card',
    'Account Verification',
    'Completed',
  ];
  const stages = isNysc ? nyscStage : payfiStage;
  return stages;
};

export const cleanUpAdminCustomers = (raw: any) => {
  if (!raw?.length) {
    return [];
  }

  const out = raw?.map(
    ({
      firstName,
      lastName,
      orders,
      createdAt,
      email,
      status,
      lastLoginAt,
      payfiProduct,
      imageUrl,
      onboardingLevel,
      onboardingRoute,
      payfiId,
      ...rest
    }: {
      firstName: string;
      email: string;
      lastName: string;
      orders: any;
      createdAt: string;
      status: string;
      lastLoginAt: string;
      payfiProduct: any;
      source: string;
      stage: string;
      level:string;
      imageUrl:string;
      payfiId:string;
      onboardingLevel:string;
      onboardingRoute:string;
      onBoardingStage:string;
      approvedAt:string;
    }) => ({
      payfiId: payfiId || '**********',
      name: firstName ? `${firstName} ${lastName}` : '**********',
      type: 'Orders',
      image: imageUrl || null,
      quantity: orders,
      currency: 'NGN',
      email: email || '**********',
      date: createdAt ? moment(new Date(createdAt)).format('MMM DD, YYYY') : '**********',
      time: createdAt ? moment(new Date(createdAt)).format('hh:mm a') : '**********',
      status: status ? 'Active' : 'Inactive',
      lastLoginAt: lastLoginAt || createdAt ? dateFormatter(lastLoginAt || createdAt, 'MMM DD, YYYY, hh:mm a', ' ') : '**********', // moment(new Date(lastLoginAt)).format('MMM DD, YYYY'),
      lastLoginAtTime: dateFormatter(lastLoginAt || createdAt, 'hh:mm a', ' '), // moment(new Date(lastLoginAt)).format('hh:mm a'),
      source: payfiProduct?.name || '**********',
      ...rest,
      onboardingRoute: {
        web: 'Web App',
        mobile: 'Mobile App',
        SDK: 'SDK',
      }[onboardingRoute] || '**********',
      level: `${getOnboardigStage(onboardingLevel || 0, payfiProduct?.name?.toUpperCase?.() === 'NYSC')}`,
      dateApproved: dateFormatter(rest?.approvedAt, 'MMM DD, YYYY', ' ')?.trim?.() || '**********',
      dateDeleted: dateFormatter(createdAt, 'MMM DD, YYYY', ' ')?.trim?.() || '**********',
    }),
  );
  return out;
};

export const cleanUpMerchants = (raw: any) => {
  if (!raw?.length) {
    return [];
  }
  interface allMerchant {
    name: string;
    email: string;
    contactEmail: string;
    phone: string;
    id: string;
    logo: string;
    createdAt: string;
    isApproved: string;
    contactPhone: string;
    payfiId: string;
    declinedAt: string
    approvedAt: string
    lastDeactivatedAt: string
    status: string
    category: any
  }
  const out = raw?.map(
    ({
      name,
      createdAt,
      logo,
      isApproved,
      payfiId,
      id,
      email,
      phone,
      declinedAt,
      approvedAt,
      lastDeactivatedAt,
      status,
      category,
      ...rest
    }: allMerchant) => ({
      ...rest,
      id,
      payfiId,
      name: addEllipsis(name || '*******', 20),
      type: 'Orders',
      image: validateUrl(logo),
      currency: 'NGN',
      date: moment(new Date(Number(createdAt))).format('MMM DD, YYYY'),
      time: moment(new Date(Number(createdAt))).format('hh:mm a'),
      dateRegistered: dateFormatter(createdAt && new Date(Number(createdAt)), 'MMM DD, YYYY', '************'),
      dateApproved: dateFormatter(approvedAt && new Date(Number(approvedAt)), 'MMM DD, YYYY', '************'),
      dateDeclined: dateFormatter(declinedAt && new Date(Number(declinedAt)), 'MMM DD, YYYY', '************'),
      dateDeactivated: dateFormatter(lastDeactivatedAt && new Date(Number(lastDeactivatedAt)), 'MMM DD, YYYY', '************'),
      status: isApproved ? 'Active' : 'Inactive',
      merchantStatus: status,
      contactEmail: email || '************',
      contactPhone: phone || '************',
      category: addEllipsis(category?.name || '************', 20),
    }),
  );

  return out;
};

export const cleanUpAdminCustomerDetailsTransaction = (raw: any) => {
  if (!raw?.length) {
    return [];
  }
  interface allTransactions {
    merchant:{
      name:string;
    };
    order:{
      orderItems:any;
      orderNumber: string;
    }
    loanStatus:{
      name:string;
    }
    id: string;
    txRef: string;
    createdAt: string;
    status: string;
    amount:any;
    fees:any;
    dueDate:any;
    payfiId:string;
    message:string;
    type:string;
    description:string;
  }
  const out = raw?.map(
    ({
      merchant,
      createdAt,
      txRef,
      order,
      id,
      status,
      amount,
      fees,
      dueDate,
      loanStatus,
      payfiId,
      description,
      message,
      type,
      ...rest
    }: allTransactions) => ({
      ...rest,
      id,
      payfiId: payfiId || '**********',
      merchant: merchant?.name || '**********',
      transactionType: type || '**********',
      txRef: txRef || '**********',
      currency: 'NGN',
      transactionDate: createdAt ? moment(createdAt).format('MMM DD, YYYY, hh:mm a') : '************',
      dateDisbutsed: createdAt ? moment(createdAt).format('MMM DD, YYYY, hh:mm a') : '************',
      createdAt: createdAt ? moment(createdAt).format('MMM DD, YYYY, hh:mm a') : '************',
      amount: amount || 'N 0',
      item: order?.orderNumber?.split(',')?.join(', ') || '**********',
      status: handleStatus(status),
      processFee: formatCash(fees || 0, 'N '),
      date: dueDate ? moment(dueDate).format('MMM DD, YYYY, hh:mm a') : '**********',
      loanStatus: loanStatus?.name,
      message: message
      || '**********',
      description: description || '**********',
    }),
  );
  return out;
};

export const cleanUpAdminCustomerDetailsLoan = (raw: any) => {
  if (!raw?.length) {
    return [];
  }
  interface allLoans {
    merchant:{
      name:string;
    };
    order:{
      items: string;
      orderItems:any;
    }
    loanStatus:{
      name:string;
    }
    id: string;
    txRef: string;
    createdAt: string;
    status: string;
    amount:any;
    fees:any;
    dueDate:any;
    payfiId:string;
    outstandingAmount:any;
    loanChannel:string;
  }
  const out = raw?.map(
    ({
      merchant,
      createdAt,
      txRef,
      order,
      id,
      amount,
      fees,
      dueDate,
      loanStatus,
      payfiId,
      outstandingAmount,
      loanChannel,
      ...rest
    }: allLoans) => ({
      ...rest,
      id,
      payfiId: payfiId || '**********',
      merchant: merchant?.name || '**********',
      txRef: txRef || '**********',
      currency: 'NGN',
      transactionDate: createdAt ? moment(createdAt).format('MMM DD, YYYY, hh:mm a') : '************',
      dateDisbutsed: createdAt ? moment(createdAt).format('MMM DD, YYYY, hh:mm a') : '************',
      createdAt: createdAt ? moment(createdAt).format('MMM DD, YYYY, hh:mm a') : '************',
      amount: amount || 'N 0',
      item: order?.items || '**********',
      status: loanStatus?.name,
      processFee: formatCash(fees || 0, 'N '),
      date: dueDate ? moment(dueDate).format('MMM DD, YYYY, hh:mm a') : '**********',
      loanStatus: loanStatus?.name,
      outstandingAmount: formatCash(outstandingAmount || 0, 'N '),
      loanChannel: capitalizeEverFirstLetter(loanChannel?.split('_').join(' ') || '**********'),
    }),
  );
  return out;
};

export const cleanUpAdminCustomerDetailsRepayment = (raw: any) => {
  if (!raw?.length) {
    return [];
  }
  interface allRepayments {
    merchant:{
      name:string;
    };
    user:{
      email:string;
        lastName:string;
        firstName:string;
        phone:string;
        id:string;
    }
    order:{
      orderItems:any;
    }
    loan:{
      tenor:string;
        paymentChannel:string;
        user:{
          email:string;
            lastName:string;
            firstName:string;
            phone:string;
            id:string;
        }
      loanStatus:{
        name:string;

      }
      transactions:{
        paymentMethod:string;
      }
    }
    transactions?: {
      id: string;
      paymentMethod: string;
      status: string;
      createdAt: string;
    }[]
    amountDue:any;
    originalAmount:any;
    loanId:string;
    id: string;
    payDate: string;
    charges:any;
    dueDate:any;
    instalments:any;
    interest:any;
    isFullyPaid:boolean;
  }
  const out = raw?.map(
    ({
      merchant,
      order,
      id,
      loanId,
      originalAmount,
      amountDue,
      dueDate,
      loan,
      transactions,
      // payDate,
      interest,
      instalments,
      isFullyPaid,
      ...rest
    }: allRepayments) => {
      const customerName = `${loan?.user?.firstName || '**********'} ${loan?.user?.lastName || ''}`;
      return ({
        ...rest,
        id,
        merchant: merchant?.name || '**********',
        type: 'Orders',
        loanId: loanId || '**********',
        customer: customerName,
        email: loan?.user?.email || '**********',
        phone: loan?.user?.phone || '**********',
        currency: 'NGN',
        originalAmount: formatCash(originalAmount || 0, 'N ') || 'N 0',
        amountDue: formatCash(amountDue || 0, 'N ') || 'N 0',
        item: order?.orderItems?.length ? order?.orderItems?.map((list) => `${list?.orderName},`) : '**********',
        interest: interest || 0,
        defaultCharges: formatCash((originalAmount && amountDue) ? amountDue - originalAmount : 0, 'N '),
        instalments: instalments ? `${instalments} Instalments` : '**********',
        repaymentdueDate: dueDate ? moment(dueDate).format('MMM DD, YYYY') : '**********',
        tenor: loan?.tenor,
        status: getRepaymentStatus(dueDate, isFullyPaid),
        paymentChannel: loan?.paymentChannel,
        paymentMethod: transactions?.[0]?.paymentMethod || '**********',
        payDate: transactions?.[0]?.createdAt ? moment(transactions?.[0]?.createdAt).format('MMM DD, YYYY, hh:mm a') : '**********',
      });
    },
  );
  return out;
};

export const cleanUpAdminAllSettlements = (raw: any) => {
  if (!raw?.length) {
    return [];
  }
  interface allAdminSettlements {
    merchant:{
      name:string;
      logo:string;
    };
    totalAmount: any;
    id: string;
    logo: string;
    createdAt: string;
    status: string;
    settledOn:string;
    totalInterest:any;
    totalSettled:any;
    type:string;
    txRef:string;
    amount:any;
    vendorFees:any;
  }
  const out = raw?.map(
    ({
      merchant,
      createdAt,
      settledOn,
      id,
      totalAmount,
      status,
      totalInterest,
      vendorFees,
      type,
      amount,
      ...rest
    }: allAdminSettlements) => ({
      ...rest,
      id,
      name: addEllipsis(merchant?.name || '**********', 20),
      type: type || '**********',
      image: validateUrl(merchant?.logo),
      currency: 'NGN',
      settledOn: settledOn ? moment(new Date(Number(settledOn))).format('MMM DD, YYYY, hh:mm a') : '**********',
      time: moment(new Date(Number(createdAt))).format('hh:mm a'),
      createdAt: createdAt ? dateFormatter(createdAt && new Date(Number(createdAt)), 'MMM DD, YYYY') : '************',
      status,
      totalAmount: formatCash(totalAmount || 0, 'N '),
      totalInterest: formatCash(totalInterest || 0, 'N '),
      totalSettled: formatCash((totalAmount || 0) - (vendorFees || 0) || 0, 'N '),
      amount: amount || '**********',

    }),

  );

  return out;
};
export const cleanUpAdminAllSettlementsTransaction = (raw: any) => {
  if (!raw?.length) {
    return [];
  }
  interface allAdminSettlementsTransaction {
    merchant:{
      name:string;
      logo:string;
    };
    totalAmount: any;
    id: string;
    logo: string;
    createdAt: string;
    status: string;
    paidAt:string;
    totalInterest:any;
    totalSettled:any;
    type:string;
    txRef:string;
    amount:any;
    vendorFees:any;
    amountPaid:any;
    channel:string;
    user: any;
  }
  const out = raw?.map(
    ({
      merchant,
      createdAt,
      paidAt,
      id,
      totalAmount,
      status,
      totalInterest,
      vendorFees,
      type,
      amount,
      amountPaid,
      channel,
      ...rest
    }: allAdminSettlementsTransaction) => ({
      ...rest,
      id,
      name: merchant?.name,
      customer: `${rest?.user?.firstName || '**********'} ${rest?.user?.lastName || ''}`,
      type: type || '**********',
      image: validateUrl(merchant?.logo),
      currency: 'NGN',
      paidAt: paidAt ? moment(new Date(Number(paidAt))).format('MMM DD, YYYY, hh:mm a') : '**********',
      time: moment(new Date(Number(createdAt))).format('hh:mm a'),
      createdAt: createdAt ? moment(new Date(Number(createdAt))).format('MMM DD, YYYY') : '************',
      status,
      totalAmount: formatCash(totalAmount || 0, 'N '),
      totalInterest: formatCash(totalInterest || 0, 'N '),
      totalSettled: formatCash((amountPaid || amount || 0) - (vendorFees || 0) || 0, 'N '),
      amount: amount || '**********',
      channel: channel || '**********',

    }),

  );

  return out;
};

const getDocInfo = (docArr) => {
  if (!docArr?.length) {
    return {
      approvedDoc: 'No Approved Doc(s)',
      rejectedDoc: 'No Rejected Doc(s)',
      submittedDoc: 'No Submitted Doc(s)',
      pendingDoc: 'No Pending Doc(s)',
    };
  }
  const DocObj = {
    approvedDoc: 0,
    rejectedDoc: 0,
    submittedDoc: 0,
    pendingDoc: 0,
  };

  docArr.forEach(({ status }: { status: string }) => {
    if (Number(status) === -1) {
      // rejected
      DocObj.rejectedDoc += 1;
    } else if (Number(status) === 1) {
      // rejected
      DocObj.approvedDoc += 1;
    } else {
      DocObj.pendingDoc += 1;
    }
    DocObj.submittedDoc += 1;
  });

  return {
    approvedDoc: `${DocObj?.approvedDoc || 'No'} Approved Doc(s)`,
    rejectedDoc: `${DocObj?.rejectedDoc || 'No'} Rejected Doc(s)`,
    submittedDoc: `${DocObj?.submittedDoc || 'No'} Submitted Doc(s)`,
    pendingDoc: `${DocObj?.pendingDoc || 'No'} Pending Doc(s)`,
  };
};

export const cleanUpAdminKYC = (raw: any) => {
  if (!raw?.length) {
    return [];
  }
  interface allMerchant {
    name: string;
    email: string;
    phone: string;
    id: string;
    logo: string;
    createdAt: string;
    isApproved: string;
    documents: any;
  }
  const out = raw?.map(
    ({
      name,
      createdAt,
      logo,
      isApproved,
      id,
      email,
      documents,
      ...rest
    }: allMerchant) => ({
      ...rest,
      id,
      name,
      image: validateUrl(logo),
      currency: 'NGN',
      date: moment(new Date(Number(createdAt))).format('MMM DD, YYYY'),
      time: moment(new Date(Number(createdAt))).format('hh:mm a'),
      status: isApproved ? 'Active' : 'Inactive',
      contactEmail: email || 'N/A',
      ...getDocInfo(documents),
    }),
  );

  return out;
};

export const cleanUpLoans = (raw: any) => {
  if (!raw?.length) {
    return [];
  }
  interface allLoans {
    amount: any;
    id: string;
    currency: string;
    createdAt: string;
    user: any;
    loanStatus: any;
    paymentChannel: string;
    order: any;
    merchant:any;
    interest:any;
    payfiId:string;
    tenor:any;
    nextRepaymentDate:string;
    outstandingAmount: number;
    loanChannel?: string;
  }
  const out = raw?.map(({
    createdAt, merchant, interest, amount, paymentChannel, payfiId, tenor, nextRepaymentDate, ...rest
  }: allLoans) => ({
    payfiId: payfiId || '**********',
    currency: 'NGN',
    dateDisbursed: createdAt ? moment(createdAt).format('MMM DD, YYYY, hh:mm a') : '**********',
    time: moment(createdAt).format('hh:mm a'),
    status: rest?.loanStatus?.name?.split('_')?.join(' ') || '**********',
    amount,
    tenor: tenor || '**********',
    customer: `${rest?.user?.firstName || ''} ${rest?.user?.lastName || ''}`.trim() || '**********',
    email: rest?.user?.email || 'N/A',
    merchant: merchant?.name || rest?.loanChannel?.split?.('_').join?.(' ') || '**********',
    paymentChannel,
    interest,
    nextRepaymentDate: nextRepaymentDate ? moment(nextRepaymentDate).format('MMM DD, YYYY') : '**********',
    name: `${rest?.user?.firstName} ${rest?.user?.lastName}` || 'N/A',
    purchaseAmount: rest?.order?.amount ? formatCash(rest?.order?.amount, 'N ') : 'N/A',
    item: rest?.order?.items || '**********',
    ...rest,
    outstandingAmount: rest?.outstandingAmount ? formatCash(rest?.outstandingAmount, 'N ') : 'N/A',
  }));
  return out;
};

export const cleanUpRefundLoans = (raw: any) => {
  if (!raw?.length) {
    return [];
  }
  interface allLoans {
    amount: any;
    id: string;
    currency: string;
    createdAt: string;
    user: any;
    loanStatus: any;
    paymentChannel: string;
    order: any;
    merchant:any;
    interest:any;
    payfiId:string;
    tenor:any;
    nextRepaymentDate:string;
    amountPaid: number;
    outstandingAmount: number;
    dateOfRefund:string;
  }
  const out = raw?.map(({
    createdAt, merchant, interest, amount, paymentChannel, payfiId, tenor, nextRepaymentDate, ...rest
  }: allLoans) => ({
    payfiId: payfiId || '**********',
    currency: 'NGN',
    time: moment(createdAt).format('hh:mm a'),
    amount,
    tenor: tenor || '**********',
    customer: `${rest?.user?.firstName || ''} ${rest?.user?.lastName || ''}`.trim() || '**********',
    email: rest?.user?.email || 'N/A',
    merchant: merchant?.name,
    paymentChannel,
    interest,
    nextRepaymentDate: nextRepaymentDate ? moment(nextRepaymentDate).format('MMM DD, YYYY') : '**********',
    name: `${rest?.user?.firstName} ${rest?.user?.lastName}` || 'N/A',
    purchaseAmount: rest?.order?.amount ? formatCash(rest?.order?.amount, 'N ') : 'N/A',
    item: rest?.order?.items || '**********',
    ...rest,
    refundAmount: rest?.amountPaid ? formatCash(rest?.amountPaid, 'N ') : 'N/A',
    isRefund: true,
    status: rest?.loanStatus?.name === 'PENDING_REFUND' ? 'pending' : 'successful',
    dateOfRefund: createdAt ? moment(createdAt).format('MMM DD, YYYY') : '**********',
    amountPaid: rest?.amountPaid ? formatCash(rest?.amountPaid, 'N ') : 'N/A',
  }));
  return out;
};
export const cleanUpBankStatement = (raw: any) => {
  if (!raw?.length) {
    return [];
  }
  interface bankStatements {
    id:string;
    amount:number;
    type:any;
    narration:string;
    date:string;
  }
  const out = raw?.map(({
    type, id, narration, amount, date, ...rest
  }: bankStatements) => ({
    id: id || '**********',
    amount: formatCash((amount / 100) || 0, '₦ '),
    type: type?.toUpperCase?.() || 'N/A',
    narration: narration || '**********',
    date: date ? moment(date).format('YYYY-MM-DD') : '**********',
    ...rest,
  }));
  return out;
};
export const cleanUpSettlements = (raw: any) => {
  if (!raw?.length) {
    return [];
  }
  const out = raw?.map(
    ({
      createdAt,
      settlementReference,
      status,
      merchant,
      totalInterest,
      vendorFees,
      totalFees,
      totalAmount,
      totalSettled,
      settledOn,
      ...rest
    }: {
      date: string;
      createdAt: string;
      settlementReference: any;
      status: null | string;
      merchant: {
        name: string;
        email: string;
      };
      totalInterest: number;
      vendorFees: number;
      totalFees: number;
      totalAmount: number;
      amountToBeSettled: number;
      settledOn: string;
      totalSettled:any;

    }) => ({
      currency: 'NGN',
      ...rest,
      createdAt,
      status: status || 'N/A',
      settlementReference: settlementReference || 'N/A',
      totalInterest: formatCash(totalInterest || 0, 'N'),
      date: dateFormatter(new Date(Number(createdAt)), 'MMM DD, YYYY', 'N/A'),
      time: dateFormatter(new Date(Number(createdAt)), 'hh:mm a', ''),
      dateTime: dateFormatter(new Date(Number(createdAt)), 'MMM DD, YYYY hh:mm a', 'N/A'),
      merchantName: merchant?.name || '',
      merchantEmail: merchant?.email || '',
      fees: vendorFees || totalFees || 0,
      vendorFees: formatCash(vendorFees || 0, 'N'),
      totalFees: formatCash(totalFees || 0, 'N'),
      amount: totalAmount,
      amountToBeSettled: formatCash((totalAmount - vendorFees) || 0, 'N'),
      totalAmount: formatCash(totalAmount || 0, 'N'),
      totalSettled: formatCash(totalSettled || 0, 'N'),
      settledOn: settledOn ? dateFormatter(new Date(Number(settledOn)), 'MMM DD, YYYY hh:mm a', '**********') : '**********',

    }),
  );

  return out;
};

export const cleanUpComment = (raw: any) => {
  if (!raw?.length) {
    return [];
  }
  const out = raw?.map(
    ({
      comment,
      action,
      admin,
      createdAt,
      ...rest
    }: {
     comment:string;
      admin: {
        firstName: string;
        lastName:string;
        email: string;
      };
      email:any;
      action:string;
      createdAt: string
    }) => ({
      ...rest,
      admin: `${admin?.firstName} ${admin?.lastName}`,
      email: admin?.email,
      action,
      comment: `${comment}`,
      createdAtDate: createdAt ? dateFormatter(createdAt, 'MMM DD, YYYY hh:mm a', '**********') : '**********',
    }),
  );

  return out;
};

export const cleanUpSDKActivity = (raw: any) => {
  if (!raw?.length) {
    return [];
  }
  const out = raw?.map(
    ({
      sessionMeta,
      payfiId,
      merchant,
      createdAt,
      partner,
      user,
      narration,
      amount,
      channel,
      ...rest
    }: {
     comment:string;
     disbursementStatus: string;
      merchant: {
        name: string;
        logo: string;
      };
      user:{
        id:string;
        firstName:string;
        lastName:string;
        email:string;
        payfiProduct:any;
      }
      userActivity: {
        action: string;
      }
      disbursementMessage: string;
      sessionMeta: any;
      payfiId:string;
      createdAt: string
      status: string;
      partner: any;
      sdkorderItems: { orderName: string}[]
      stage:string;
      narration:string;
      amount:any;
      channel:string;
    }) => ({
      ...rest,
      merchant: merchant?.name,
      logo: merchant?.logo,
      customer: `${`${user?.firstName || '*****'} ${user?.lastName || '*****'}`}`,
      email: user?.email,
      userId: user?.id,
      payfiId,
      sessionMeta,
      partnerName: partner?.name || '**********',
      createdAtDate: createdAt ? dateFormatter(createdAt, 'MMM DD, YYYY hh:mm a', '**********') : '**********',
      narration: narration || '**********',
      amount: amount || '**********',
      channel: channel || '**********',
      stage: rest?.userActivity?.[0]?.screen || '**********',
      disbursementMessage: rest?.disbursementMessage?.trim?.() || '**********',
      status: rest?.status || 'N/A',
    }),
  );
  return out;
};

export const cleanUpAllUserSDKActivity = (raw: any) => {
  if (!raw?.length) {
    return [];
  }
  const out = raw?.map?.(
    ({
      partner,
      screen,
      ...rest
    }: {
      screen:string;
      partner:{
        name:string;
      }
      user:{
        id:string;
        firstName:string;
        lastName:string;
        email:string;
        payfiProduct:any;
      }
      sdkActivity: {
        id:string;
        status:string;
        merchant: {
          name: string;
          logo: string;
        };
        sessionMeta: any;
        payfiId:string;
        createdAt: string
        partner: any;
        sdkorderItems: { orderName: string}[]
        stage:string;
        narration:string;
        amount:any;
        channel:string;
        reference:string;
        disbursementMessage:string;
      }

    }) => {
      const sdkActivity: any = rest?.sdkActivity || {};
      const user: any = rest?.user || {};
      const {
        id,
        sessionMeta,
        payfiId,
        merchant,
        createdAt,
        sdkorderItems,
        narration,
        amount,
        channel,
        reference,
        status,
        disbursementMessage,
      } = sdkActivity;
      return ({
        ...rest,
        sdkActivityId: id,
        merchant: merchant?.name,
        logo: merchant?.logo,
        customer: `${`${user?.firstName || '*****'} ${user?.lastName || '*****'}`}`,
        email: user?.email,
        userId: user?.id,
        payfiId,
        sessionMeta,
        partnerName: partner?.name || '**********',
        item: sdkorderItems?.length ? sdkorderItems?.length > 1 ? `${sdkorderItems[0]?.orderName}...` : sdkorderItems[0]?.orderName : '**********',
        createdAtDate: createdAt ? dateFormatter(createdAt, 'MMM DD, YYYY hh:mm a', '**********') : '**********',
        stage: capitalizeEverFirstLetter(screen) || '**********',
        narration: narration || '**********',
        amount: amount || '**********',
        channel: channel || '**********',
        reference,
        status: status || '**********',
        disbursementMessage: disbursementMessage || '**********',

      });
    },
  );
  return out;
};

export const cleanUpPushNotification = (raw: any) => {
  if (!raw?.length) {
    return [];
  }
  const out = raw?.map(
    ({
      id,
      status,
      createdAt,
      message,
      subject,
      requestedByAdmin,
      targetUser,
      scheduleTime,
      ...rest
    }: {
      scheduleTime:string;
      message:string;
      id:string;
      createdAt:string;
      status:string;
      subject:string;
      requestedByAdmin:{
        firstName:string;
        lastName:string;
      }
      targetUser:{
        name:string;
        id:string;
      }
    }) => ({
      ...rest,
      id,
      subject: subject || '**********',
      message: message || '**********',
      pushNotificationStatus: status || '**********',
      sender: `${`${requestedByAdmin?.firstName || '*****'} ${requestedByAdmin?.lastName || '*****'}`}`,
      createdAtDate: createdAt ? moment(createdAt)?.format('MMM DD, YYYY hh:mm a') : '**********',
      targetUser: targetUser?.name || '**********',
      scheduleTime: scheduleTime ? moment(scheduleTime)?.format('MMM DD, YYYY hh:mm a') : '**********',
    }),
  );

  return out;
};

export const cleanUpCheckEligibility = (raw: any) => {
  if (!raw?.length) {
    return [];
  }
  const out = raw?.map(
    ({
      id,
      requestedAmount,
      createdAt,
      provider,
      requestedByAdmin,
      User,
      status,
      eligibleAmount,
      ...rest
    }: {
      provider:string;
      id:string;
      createdAt:string;
      User:{
        firstName:string;
        lastName:string;
        email:string;
      };
      requestedByAdmin:{
        firstName:string;
        lastName:string;
        email:string;
      };
      requestedAmount: any;
      status:string;
      eligibleAmount:any;

    }) => ({
      ...rest,
      id,
      user: `${`${User?.firstName || '*****'} ${User?.lastName || '*****'}`}`,
      userEmail: User?.email,
      amount: formatCash(requestedAmount || 0, 'N'),
      provider: provider || '**********',
      email: requestedByAdmin?.email || '**********',
      name: `${`${requestedByAdmin?.firstName || '*****'} ${requestedByAdmin?.lastName || '*****'}`}`,
      eligibilityStatus: status || '**********',
      eligibleAmount: formatCash(eligibleAmount || 0, 'N'),
      eligibilityDate: createdAt ? moment(parseInt(createdAt))?.format('MMM DD, YYYY hh:mm a') : '**********',
    }),
  );

  return out;
};

export const cleanUpOrder = (raw: any) => {
  if (!raw?.length) {
    return [];
  }
  const out = raw?.map(
    ({
      orderNumber,
      Item,
      user,
      loan,
      status,
      createdAt,
      amount,
      ...rest
    }: {
      orderNumber:string;
      Item:string;
      user: {
        firstName: string;
        lastName:string;
        email: string;
      };
      loan:{
        loanChannel:string;
      },
      status:{
        name:string;
      },
      email:any;
      action:string;
      createdAt:string;
      amount:string;
    }) => ({
      ...rest,
      user: `${user?.firstName} ${user?.lastName}`,
      email: user?.email,
      orderNumber,
      status: status?.name,
      Item,
      loan: loan?.loanChannel,
      amount,
      createdAt: createdAt ? dateFormatter(new Date(createdAt), 'MMM DD, YYYY hh:mm a', '**********') : '**********',
    }),
  );

  return out;
};

const handleStatus = (status: string) => {
  if (!status) return 'FAILED';
  const activeStatuses = ['paid', 'success', 'active', 'successful'];
  const pendingStatus = ['pending', 'initiated'];
  if (activeStatuses?.includes(status?.toLowerCase?.())) {
    return 'SUCCESSFUL';
  }
  if (pendingStatus?.includes(status?.toLowerCase?.())) {
    return 'PENDING';
  }
  return 'FAILED';
};

export const cleanUpTransactions = (raw: any) => {
  if (!raw?.length) {
    return [];
  }
  const out = raw?.map(
    ({
      createdAt,
      txRef,
      firstName,
      lastName,
      amount,
      email,
      ...rest
    }: {
      email: string;
      createdAt: string;
      txRef: any;
      firstName: any;
      lastName: any;
      amount: any;
      status: null | string;
      fees: number;
    }) => ({
      date: moment(createdAt).format('MMM DD, YYYY'),
      time: moment(createdAt).format('hh:mm a'),
      currency: 'NGN',
      ...rest,
      createdAt,
      customer: `${firstName} ${lastName}`,
      email: email || 'N/A',
      trxAmount: amount,
      txRef: txRef || 'N/A',
      charges: formatCash(rest?.fees || 0, 'N'),
      status: handleStatus(rest?.status),
      dateTime: moment(createdAt).format('MMM DD, YYYY hh:mm a'),
    }),
  );

  return out;
};

export const cleanUpOrders = (raw: any) => {
  if (!raw?.length) {
    return [];
  }
  const out = raw?.map(
    ({
      createdAt,
      orderNumber,
      amount,
      status,
      user,
      loan,
      ...rest
    }: {
      orderNumber: string;
      createdAt: string;
      status: any;
      loan: any;
      user: any;
      amount: number;
    }) => ({
      date: moment(createdAt).format('MMM DD, YYYY'),
      time: moment(createdAt).format('hh:mm a'),
      ...rest,
      createdAt,
      customer: `${user?.firstName || ''} ${user?.lastName || ''}`,
      email: user?.email || 'N/A',
      amount,
      status: handleStatus(status?.name),
      dateTime: moment(createdAt).format('MMM DD, YYYY hh:mm a'),
      channel: loan?.loanChannel || 'N/A',
      orderNumber,
      item: 'N/A',
    }),
  );

  return out;
};

export const cleanUpPaymentLinkHistories = (raw: any) => {
  if (!raw?.length) {
    return [];
  }
  const out = raw?.map(
    ({
      createdAt,
      firstName,
      lastName,
      email,
      ...rest
    }: {
      email: string;
      createdAt: string;
      firstName: any;
      lastName: any;
      amount: any;
    }) => ({
      date: moment(createdAt).format('MMM DD, YYYY'),
      time: moment(createdAt).format('hh:mm a'),
      ...rest,
      createdAt,
      name: `${firstName || ''} ${lastName || ''}`?.trim?.(),
      email: email || 'N/A',
      dateTime: moment(createdAt).format('MMM DD, YYYY hh:mm a'),
    }),
  );

  return out;
};

// const getItemName = (orderItems: any) => {
//   if (!orderItems?.length) return null;
//   let itemName = '';

//   orderItems.forEach((item: any, i) => {
//     if (item?.orderName) {
//       if (i === orderItems.length - 1) {
//         itemName += `${item?.orderName}`;
//       } else {
//         itemName += `${item.orderName}, `;
//       }
//     }
//   });
//   // if item name is greater than 50 characters, truncate and add elipsis
//   if (itemName.length > 50) {
//     itemName = `${itemName.substring(0, 40)}...`;
//   }
//   return itemName;
// };

export const cleanUpAdminTransactions = (raw: any) => {
  if (!raw?.length) {
    return [];
  }
  interface allTransaction {
    email: string;
    createdAt: string;
    txRef: any;
    firstName: any;
    lastName: any;
    amount: any;
    status: null | string;
    type?: string;
    user?: any;
    merchant?: any;
    paymentMethod?: string;
    paymentRef?: string;
    order?: any;
    equityContribution?:string;
    channel?:string;
    message?:string;
    transactionType?:string;
    fees: number;
    loan: any;
  }
  const out = raw?.map(
    ({
      createdAt,
      txRef,
      amount,
      status,
      paymentRef,
      merchant,
      channel,
      ...rest
    }: allTransaction) => ({
      date: moment(new Date(createdAt)).format('MMM DD, YYYY'),
      time: moment(new Date(createdAt)).format('hh:mm a'),
      dateTime: moment(new Date(createdAt)).format('MMM DD, YYYY hh:mm a'),
      currency: 'NGN',
      ...rest,
      createdAt,
      customer: `${rest?.user?.firstName || '*****'} ${rest?.user?.lastName || '*****'}`,
      email: rest?.user?.email || '**********',
      trxAmount: formatCash(amount),
      txRef: txRef || paymentRef || '**********',
      status: handleStatus(status),
      merchant: merchant?.name || '**********',
      merchantEmail: merchant?.email || '**********',
      transactionType: rest?.type || '**********',
      channel: capitalizeOnlyFirstLetter(channel) || '**********',
      message: rest?.message || '**********',
      paymentMethod: rest?.paymentMethod?.trim?.() || '**********',
      amount,
      fees: formatCash((Number(rest?.fees) || 0), 'N '),
    }),
  );
  return out;
};

export const cleanUpAdminRewards = (raw: any, pageSize: number = 0) => {
  if (!raw?.length) {
    return [];
  }
  interface allRewards {
    status: null | string;
    user?: any;
    pointIssued:number;
    pointRedeemed:number;
    expiryDate:string;
    point:number;
    dateEnrolled:string;
    level:number;
  }
  const out = raw?.map(
    ({

      status,
      ...rest
    }: allRewards, index: number) => ({
      ...rest,
      expiryDate: moment(new Date(rest?.expiryDate)).format('MMM DD, YYYY'),
      dateEnrolled: moment(new Date(rest?.dateEnrolled)).format('MMM DD, YYYY'),
      customer: `${rest?.user?.firstName || '*****'} ${rest?.user?.lastName || '*****'}`,
      email: rest?.user?.email || '**********',
      status: status || '**********',
      point: rest?.point || '0',
      level: rest?.level || '0',
      pointIssued: rest?.pointIssued || '0',
      pointRedeemed: rest?.pointRedeemed || '0',
      rank: index + 1 + pageSize,
    }),
  );
  return out;
};

export const cleanUpAdminLeaderboard = (raw: any, pageSize: number = 0) => {
  if (!raw?.length) {
    return [];
  }
  interface allLeaderboard {
    status: null | string;
    user?: any;
    pointIssued:number;
    pointRedeemed:number;
    expiryDate:string;
    point:number;
    dateEnrolled:string;
    level:number;
  }
  const out = raw?.map(
    ({

      status,
      ...rest
    }: allLeaderboard, index: number) => ({
      ...rest,
      expiryDate: moment(new Date(rest?.expiryDate)).format('MMM DD, YYYY'),
      dateEnrolled: moment(new Date(rest?.dateEnrolled)).format('MMM DD, YYYY'),
      customer: `${rest?.user?.firstName || '*****'} ${rest?.user?.lastName || '*****'}`,
      email: rest?.user?.email || '**********',
      status: status || '**********',
      point: rest?.point || '0',
      level: rest?.level || '0',
      pointIssued: rest?.pointIssued || '0',
      pointRedeemed: rest?.pointRedeemed || '0',
      rank: index + 1 + pageSize,
    }),
  );
  return out;
};

export const cleanUpAdminRewardHistory = (raw: any) => {
  if (!raw?.length) {
    return [];
  }
  interface allRewards {
    user?: any;
    createdAt:string;
    point:number;
    meta:any;
  }
  const out = raw?.map(
    ({
      createdAt,
      meta,
      ...rest
    }: allRewards) => ({
      ...rest,
      date: createdAt ? dateFormatter(new Date(Number(createdAt)), 'MMM DD, YYYY hh:mm a', '**********') : '**********',
      customer: `${rest?.user?.firstName || '*****'} ${rest?.user?.lastName || '*****'}`,
      email: rest?.user?.email || '**********',
      point: rest?.point || '**********',
      name: meta?.payoutDetails?.serviceType || '**********',
      image: airtimeImages[meta?.payoutDetails?.serviceType.toLowerCase()],

    }),
  );
  return out;
};

export const cleanUpAdminEarningsRewardHistory = (raw: any) => {
  if (!raw?.length) {
    return [];
  }
  interface allEarningRewards {
    user?: any;
    createdAt:string;
    point:number;
    meta:any;
    rewardType:string;
  }
  const out = raw?.map(
    ({
      createdAt,
      meta,
      ...rest
    }: allEarningRewards) => ({
      ...rest,
      date: createdAt ? dateFormatter(new Date(Number(createdAt)), 'MMM DD, YYYY hh:mm a', '**********') : '**********',
      point: rest?.point || '**********',
      name: meta?.description || '**********',
      image: meta?.iconImage || NewLevelUnlockedImage,
      customer: `${rest?.user?.firstName || '*****'} ${rest?.user?.lastName || '*****'}`,
      email: rest?.user?.email || '**********',

    }),
  );
  return out;
};

export const cleanUpAdminBillsPayment = (raw: any) => {
  if (!raw?.length) {
    return [];
  }
  interface allBillsPayment {
    email: string;
    createdAt: string;
    txRef: any;
    firstName: any;
    lastName: any;
    amount: any;
    status: null | string;
    payout_reference: any;
    user?: any;
    paymentMethod?: string;
    message?:string;
    transaction: any;
    narration: string;
  }
  const out = raw?.map(
    ({
      createdAt,
      amount,
      status,
      payout_reference,
      transaction,
      narration,
      ...rest
    }: allBillsPayment) => ({
      date: dateFormatter(createdAt && new Date(Number(createdAt)), 'MMM DD, YYYY', '************'),
      currency: 'NGN',
      ...rest,
      customer: `${rest?.user?.firstName || '*****'} ${rest?.user?.lastName || '*****'}`,
      amount: formatCash((Number(amount) || 0), 'N '),
      txRef: payout_reference || '**********',
      status: handleStatus(status) || '**********',
      message: narration || '**********',
      paymentMethod: transaction?.paymentMethod?.trim?.() || '**********',
      description: transaction?.description || '**********',
    }),
  );

  return out;
};

const handleVoucherStatus = (dueDate: string, isFullyPaid: boolean) => {
  if (isFullyPaid) return 'Paid';
  // if due date is less than 2 days ago, return defaulting
  const now = new Date();
  const twoDaysAgo = new Date(now);
  twoDaysAgo.setDate(now.getDate() - 2);
  const dueDateObj = new Date(dueDate);
  if (dueDateObj <= twoDaysAgo) {
    return 'Defaulting';
  }
  // if it is greater than 2 days ago and less than or equal today return due
  if (dueDateObj > twoDaysAgo && dueDateObj <= now) {
    return 'Due';
  }
  // if it is greater than today return not due
  return 'Active';
};

export const cleanUpAdminLoanVoucherCodes = (raw: any) => {
  if (!raw?.length) {
    return [];
  }
  interface allLoanVouchers {
    status: null | string;
    fees: number;
    loan: {
      amount: string;
      createdAt: string;
      currency: string;
      dueDate: string;
      loanChannel: string;
      loanSchedules: [
        {
          amountDue: any;
          originalAmount: any;
          isFullyPaid: boolean;
          dueDate: string;
        }
      ]
      user: {
        firstName: string;
        lastName: string;
      }
    };
  }
  const out = raw?.map(
    ({
      loan,
      ...rest
    }: allLoanVouchers) => ({
      due_date: moment(new Date(loan?.dueDate)).format('MMM DD, YYYY'),
      dateAllocated: moment(new Date(loan?.createdAt)).format('MMM DD, YYYY'),
      defaultCharges: formatCash((loan?.loanSchedules[0]?.originalAmount && loan?.loanSchedules[0]?.amountDue) ? loan?.loanSchedules[0]?.amountDue - loan?.loanSchedules[0]?.originalAmount : 0, 'N '),
      currency: 'NGN',
      ...rest,
      customer: loan?.user?.firstName && loan?.user?.lastName ? `${loan?.user?.firstName} ${loan?.user?.lastName}` : 'User deleted',
      status: handleVoucherStatus(loan?.loanSchedules[0]?.dueDate, loan?.loanSchedules[0]?.isFullyPaid),
    }),
  );

  return out;
};

export const cleanUpAdminAllStaff = (raw: any) => {
  if (!raw?.length) {
    return [];
  }
  interface allAdminStaff {
    email: string;
    firstName: any;
    lastName: any;
    status: null | string;
    department:string;
    phone:string;
    role:{
      display_name:string;
      name:string;
      id:string;
    }

  }
  const out = raw?.map(
    ({
      email,
      firstName,
      lastName,
      role,
      phone,
      status,
      department,
      ...rest
    }: allAdminStaff) => ({
      ...rest,
      name: `${firstName || '*****'} ${lastName || '*****'}`,
      email: email || '**********',
      status: status || '**********',
      role: role?.display_name || role?.name || '**********',
      phone: phone || '**********',
      department: department || '**********',
      dataRoleId: role?.id,
      firstName,
      lastName,
    }),
  );

  return out;
};
export const cleanUpAdminDisbursementPartner = (raw: any) => {
  if (!raw?.length) {
    return [];
  }
  interface allDisbursementPartners {
    customerEmail: string;
    transactionDate: string;
    description:string;
    loanAmount:any;
    status:string;
    transactionReference:string;
    route:string;
    apiResponse:any;
    createdAt:string;
  }
  const out = raw?.map(
    ({
      customerEmail,
      description,
      loanAmount,
      status,
      route,
      transactionReference,
      apiResponse,
      ...rest
    }: allDisbursementPartners) => ({
      currency: 'NGN',
      customerEmail: customerEmail || '**********',
      description: description || '**********',
      status: status || '**********',
      transactionReference: transactionReference || '**********',
      partner: route || '**********',
      apiResponse: apiResponse?.description || '**********',
      ...rest,
      loanAmount: formatCash((Number(loanAmount) || 0), 'N '),
      transactionDate: rest?.createdAt ? moment(Number(rest?.createdAt)).format('MMM DD, YYYY hh:mm a') : '**********',
    }),
  );

  return out;
};

export const getOrdinalForNumber = (num: number) => {
  const s = ['th', 'st', 'nd', 'rd'];
  const v = num % 100;
  return num + (s[(v - 20) % 10] || s[v] || s[0]);
};

export const cleanUpLoanSchedules = (raw: any) => {
  if (!raw?.length) {
    return [];
  }
  // order by date
  let out = raw?.sort((a, b) => {
    const aDate = new Date(a.dueDate);
    const bDate = new Date(b.dueDate);
    return aDate.getTime() - bDate.getTime();
  });
  out = out?.map(
    ({
      dueDate,
      amountDue,
      isFullyPaid,
    }: {
      dueDate: string;
      amountDue: number;
      isFullyPaid: boolean;
    }, n: number) => ({
      name: `${getOrdinalNumber(n + 1)} Settlement`,
      dueDate: dateFormatter(dueDate, 'MMM DD, YYYY', 'N/A'),
      amountDue: formatCash(amountDue, 'N'),
      isFullyPaid,
      status: getRepaymentStatus(dueDate, isFullyPaid),
    }),
  );

  return out;
};

// const splitCamelCaseToWord = (str: string)
// => str.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase());

export const sanitizeFilter = (formValues: any) => {
  let out: any = {};
  const convert = {
    userEmail: 'email',
    userPhone: 'phone',
  };

  Object.keys(formValues)?.forEach((k) => {
    if (['userEmail', 'userPhone'].includes(k)) {
      if (out?.user) {
        out.user[convert[k]] = formValues[k];
      } else {
        out = {
          ...out,
          user: {
            [convert[k]]: formValues[k],
          },
        };
      }
    } else if (['fromDate', 'toDate'].includes(k)) {
      out[k] = formValues[k]
        ? new Date(formValues[k]).toISOString()
        : formValues[k];
    } else if (['isApproved'].includes(k)) {
      out[k] = formValues[k] === 'true';
    } else if (['minAmount', 'maxAmount', 'tenor'].includes(k)) {
      // eslint-disable-next-line radix
      out[k] = formValues[k] ? parseInt(formValues[k]) : null;
    } else {
      out[k] = formValues[k];
    }
  });
  return out;
};

export const cleanUpAudits = (raw: any) => {
  if (!raw?.length) {
    return [];
  }
  const out = raw?.map(
    ({
      createdAt,
      admin,
      body,
      reason,
      ...rest
    }: {
      createdAt: string;
      admin: any;
      body: string;
      reason: string;
    }) => ({
      ...rest,
      date: moment(createdAt).format('MMM DD, YYYY'),
      time: moment(createdAt).format('hh:mm a'),
      dateTime: moment(createdAt).format('MMM DD, YYYY hh:mm a'),
      userName: `${admin?.firstName} ${admin?.lastName}`,
      userEmail: admin?.email,
      description: body,
      reason,
      role: admin?.role?.name,
    }),
  );

  return out;
};

export const cleanUpSettlementTransactions = (raw: any) => {
  if (!raw?.length) {
    return [];
  }
  const out = raw?.map(
    ({
      createdAt,
      txRef,
      status,
      ...rest
    }: {
      email: string;
      createdAt: string;
      txRef: any;
      amount: any;
      status: string;
      fees: string;
    }) => ({
      currency: 'NGN',
      ...rest,
      createdAt,
      txRef: txRef || 'N/A',
      status: status === 'paid' || status === 'success' ? 'Successful' : status,
      date: moment(new Date(createdAt)).format('MMM DD, YYYY'),
      time: moment(new Date(createdAt)).format('hh:mm a'),
      dateTime: moment(new Date(createdAt)).format('MMM DD, YYYY hh:mm a'),
      fees: rest?.fees || 0,
    }),
  );

  return out;
};

export const parseJSON = (str: string) => {
  try {
    return JSON.parse(str);
  } catch (e) {
    return {};
  }
};

export const cleanUpPaymentLinks = (raw: any) => {
  if (!raw?.length) {
    return [];
  }
  const out = raw?.map(
    ({
      configurations,
      createdAt,
      status,
      name,
      title,
      images,
      ...rest
    }: {
      configurations: string;
      createdAt: string;
      status: any;
      rest: any;
      name: string;
      title:string;
      images:{
        url
      }
    }) => ({
      currency: 'NGN',
      ...rest,
      link: `${process.env.REACT_APP_PAYFI_WEBSITE_URL}/pay/${name}`,
      status: status ? 'ACTIVE' : 'Deactivated',
      amount: parseJSON(configurations)?.amount || 0,
      date: moment(new Date(createdAt)).format('MMM DD, YYYY'),
      time: moment(new Date(createdAt)).format('hh:mm a'),
      dateTime: moment(new Date(createdAt)).format('MMM DD, YYYY hh:mm a'),
      type: 'Single Charge',
      title,
      name,
      image: images[0]?.url || 'https://publicpayfiimages.s3.amazonaws.com/randomAsset/dummyTableImage_imageBck_haw7f8.jpg',
    }),
  );

  return out;
};

export const cleanUpSingleTransaction = (raw: any) => {
  if (!raw) {
    return null;
  }
  const {
    createdAt,
    txRef,
    amount,
    paidAt,
    firstName,
    lastName,
    ...rest
  }: {
    email: string;
    createdAt: string;
    txRef: any;
    paidAt: any;
    amount: any;
    firstName: string;
    lastName: string;
    fees: number;
    shippingAddress?: string;
    productDetails?: any;
  } = raw;
  const name = `${firstName || ''} ${lastName || ''}`;
  const out = {
    customerName: name?.trim() || 'N/A',
    paidDate: moment(new Date(Number(paidAt))).format('MMM DD, YYYY'),
    paidTime: moment(new Date(Number(paidAt))).format('hh:mm a'),
    createdDate: moment(new Date(createdAt)).format('Do MMM, YYYY'),
    createdTime: moment(new Date(createdAt)).format('hh:mm a'),
    ...rest,
    trxAmount: formatCash(amount, '₦'),
    txRef: txRef || 'N/A',
    fees: formatCash(rest?.fees || 0, '₦'),
    amount: formatCash(amount + (rest?.fees || 0), '₦'),
    shippingAddress: rest?.shippingAddress,
    productDetails: rest?.productDetails,
  };
  return out;
};

export const cleanUpSingleOrder = (raw: any) => {
  if (!raw) {
    return null;
  }
  const {
    createdAt,
    orderNumber,
    amount,
    user,
    ...rest
  }: {
    email: string;
    createdAt: string;
    amount: any;
    orderNumber: string;
    user: any;
    fees: number;
    shippingAddress?: string;
    productDetails?: any;
    orderItems: any;
  } = raw;
  const name = `${user?.firstName || ''} ${user?.lastName || ''}`;
  const out = {
    customerName: name?.trim() || 'N/A',
    email: user?.email || 'N/A',
    phone: user?.phone || 'N/A',
    paidDate: moment(new Date(Number(createdAt))).format('MMM DD, YYYY'),
    paidTime: moment(new Date(Number(createdAt))).format('hh:mm a'),
    createdDate: moment(new Date(createdAt)).format('Do MMM, YYYY'),
    createdTime: moment(new Date(createdAt)).format('hh:mm a'),
    ...rest,
    trxAmount: formatCash(amount, '₦'),
    orderNumber: orderNumber || 'N/A',
    amount: formatCash(amount + (rest?.fees || 0), '₦'),
    shippingAddress: null,
    productDetails: null,
  };
  return out;
};

const getPayoutResponseMesage = (payout: any, type: string, message: string, status: string) => {
  if (status === 'success' && type === 'payout') {
    return message;
  }
  if (payout?.response_message) {
    return payout?.response_message;
  }
  if (payout?.status !== 'success' && type === 'payout') {
    return payout?.apiResponse?.message || payout?.apiResponse?.response_content?.response_description || payout?.apiResponse?.response_content;
  }
};

export const cleanUpAdminSingleTransaction = (raw: any) => {
  if (!raw?.amount) {
    return null;
  }

  const {
    createdAt,
    txRef,
    amount,
    status,
    merchant,
    fees,
    type,
    message,
    gatewayResponse,
    // user: {
    //   firstName, lastName, email, phone, status: userStatus, imageUrl, address, id,
    // },
    user,
    order,
    orderItem,
    paymentRef,
    ...rest
  }: {
    email: string;
    createdAt: string;
    txRef: any;
    paidAt: any;
    amount: any;
    status: string;
    fees: any;
    type: string;
    paymentMethod: string;
    message: string;
    gatewayResponse: string;
    item:string;
    paymentRef: string;
    user: {
      firstName: string;
      lastName: string;
      email: string;
      phone: string;
      status: any;
      imageUrl:string;
      id:string;
      address:{
        street:string;
        city:string;
      }
    };
    order:{
      orderItems:{
        orderName:string;
        amount:string;
        orderCategory:string;
      };
      status:{
        name:string;
      };
      address :{
        location:string;
        street:string;
        addressType:string;
      }
  };
    merchant: {
      id: string;
      name: string;
    };
    payout?: {
      apiResponse: any
      apiRequest: any
    }
    orderItem: any;
  } = raw;
  const fullName = user?.firstName ? `${user?.firstName} ${user?.lastName}` : '**********';
  const out = {
    paidDate: moment(new Date(Number(createdAt))).format('MMM DD, YYYY'),
    paidTime: moment(new Date(Number(createdAt))).format('hh:mm a'),
    createdDate: moment(new Date(createdAt)).format('MMM DD, YYYY hh:mm a'),
    createdTime: moment(new Date(createdAt)).format('hh:mm a'),
    captureDate: moment(new Date(createdAt)).format('MMM DD, YYYY hh:mm a'),
    captureTime: moment(new Date(createdAt)).format('hh:mm a'),
    ...rest,
    currency: 'NGN',
    trxAmount: amount,
    userId: user?.id,
    txRef: txRef || paymentRef || '**********',
    fees: formatCash(fees || 0, '₦'),
    total: formatCash(amount, '₦'),
    subTotal: formatCash(amount - (fees || 0), '₦'),
    name: fullName?.trim() || '**********',
    merchantName: merchant?.name || '**********',
    id: merchant?.id,
    email: user?.email || '**********',
    status: status === 'success' ? 'successful' : status,
    phone: user?.phone || '**********',
    firstName: user?.firstName || '**********',
    lastName: user?.lastName || '**********',
    imageUrl: user?.imageUrl,
    street: user?.address?.street || '**********',
    city: user?.address?.city || '**********',
    item: orderItem?.orderName || '**********',
    userStatus: user?.status === 1 ? 'ACTIVE' : 'INACTIVE',
    type,
    responseMessage: getPayoutResponseMesage(rest?.payout, type, message, status) || message || gatewayResponse,
    orderItems: order?.orderItems,
    orderStatus: order?.status,
    orderAddress: order?.address,
    destinationBank: rest?.payout?.apiResponse?.destination_institution_name || '**********',
    beneficiaryName: rest?.payout?.apiResponse?.account_name || '**********',
    beneficiaryAccount: rest?.payout?.apiResponse?.account_number || '**********',
    meterNumber: rest?.payout?.apiResponse?.data?.rawOutput?.meterNumber,
    receiverID: rest?.payout?.apiResponse?.data?.rawOutput?.meterNumber === undefined
      ? rest?.payout?.apiRequest?.account_number || rest?.payout?.apiRequest?.smartcard_number || rest?.payout?.apiRequest?.phone
      : rest?.payout?.apiResponse?.data?.rawOutput?.meterNumber,
    billerName: rest?.payout?.apiRequest?.service_type || rest?.payout?.apiRequest?.plan ? rest?.payout?.apiRequest?.service_type.toUpperCase() || rest?.payout?.apiRequest?.plan.toUpperCase() : '**********',
    billType:
    rest?.payout?.apiResponse?.data?.transactionMessage?.includes('Cable') ? 'Cable TV'
      : rest?.payout?.apiResponse?.data?.transactionMessage?.includes('Airtime') ? 'Airtime'
        : rest?.payout?.apiResponse?.data?.transactionMessage?.includes('Data') ? 'Data'
          : rest?.payout?.apiRequest?.service_type?.includes('electric') ? 'Electricity'
            : rest?.payout?.apiRequest?.action?.includes('WALLET_FUNDING') ? 'Betting' : '**********',
    senderName: message?.split('- ')[1] || '**********',
    senderAccount: '**********',
    senderBank: '**********',

  };

  return out;
};

export const cleanUpAdminSingleDisbursementPartner = (raw: any) => {
  if (!raw) {
    return null;
  }
  const {

    transactionDate,
    transactionReference,
    loanAmount,
    route,
    apiResponse,
    apiRequest,
    ...rest
  }: {
    transactionDate:string;
    transactionReference:string,
    route:string,
    loanAmount:any,
    apiRequest:{
      accountNumber:string;
      accountName:string;
    }
    apiResponse:{
      data:any;
      description:string;
      resp:any;
      payload:any;
      responseStatus:string;
    }
  } = raw;
  const out = {
    ...rest,
    apiResponseAccountName: apiResponse?.data?.accT_NAME || apiResponse?.resp?.data?.accT_NAME || apiResponse?.payload?.accountName,
    apiResponseAccountNumber: apiResponse?.data?.accT_NUM || apiResponse?.resp?.data?.accT_NUM || apiResponse?.payload?.accountNumber,
    apiResponseDescription: apiResponse?.description,
    apiRequestName: apiRequest?.accountName,
    apiRequestNumber: apiRequest?.accountNumber,
    apiResponse,
    apiRequest,
    route: route || '**********',
    transactionReference: transactionReference || '**********',
    transactionDate: transactionDate ? moment(new Date(parseInt(transactionDate))).format('MMM DD, YYYY hh:mm a') : '**********',
    loanAmount: formatCash(loanAmount),
  };

  return out;
};

export const cleanUpAdminSingleUserActivity = (raw: any) => {
  if (!raw) {
    return null;
  }
  const {

    user,
    sdkActivity,
    ...rest
  }: {
    user: {
      firstName: string;
      lastName: string;
      email: string;
      phone: string;
      status: any;
      imageUrl:string;
      promise:any;
    };
    sdkActivity:{
      sdkorderItems:any;
    }
  } = raw;
  const out = {
    ...rest,
    name: `${user?.firstName || '*****'} ${user?.lastName || '*****'}`,
    email: user?.email || '**********',
    phone: user?.phone || '**********',
    status: user?.status,
    imageUrl: user?.imageUrl,
    promise: user?.promise,
    sdkorderItems: sdkActivity?.sdkorderItems,

  };

  return out;
};

export const cleanUpAdminSingleSdkActivityById = (raw: any) => {
  if (!raw) {
    return null;
  }
  const {
    user,
    ...rest
  }: {
    user: {
      firstName: string;
      lastName: string;
      email: string;
      phone: string;
      status: any;
      imageUrl:string;
      promise:any;
      id: string;
    };
    sdkorderItems:any;
    userActivity:any;
    partner :any;
  } = raw;
  const out = {
    ...rest,
    name: `${user?.firstName || '*****'} ${user?.lastName || '*****'}`,
    email: user?.email || '**********',
    phone: user?.phone || '**********',
    status: user?.status,
    userStatus: user?.status === 1 ? 'ACTIVE' : 'INACTIVE',
    imageUrl: user?.imageUrl,
    promise: user?.promise,
    sdkorderItems: rest?.sdkorderItems,
    userActivities: rest?.userActivity,
    userId: user?.id,
    disbursmentPartner: rest?.partner?.name,
  };

  return out;
};

export const cleanUpAdminSingleLoanRecord = (raw: any) => {
  if (!raw?.id) {
    return null;
  }

  const {
    id,
    createdAt,
    nextRepaymentDate,
    amount,
    loanSchedules,
    loanStatus,
    outstandingAmount,
    fees,
    interest,
    interestPaid,
    user,
    tenor,
    merchant,
    ...rest
  }: {
    id: string;
    createdAt: string;
    nextRepaymentDate: any;
    amount: any;
    loanStatus: any;
    fees: any;
    interest: string;
    loanSchedules: any;
    interestPaid: number;
    tenor:string;
    payfiId: string;
    merchant:{
      name:string;
    }
    user: any;
    order: any;
    outstandingAmount: any;
  } = raw;
  const name = user?.firstName ? `${user?.firstName} ${user?.lastName}` : null;
  const startDate = loanSchedules?.[0]?.createdAt;
  const endDate = loanSchedules?.length
    ? loanSchedules?.[(loanSchedules?.length || 0) - 1]?.dueDate
    : null;
  const totalLoanScheduleAmount = loanSchedules?.reduce((acc, cur) => acc + cur?.amountDue, 0);
  const out = {
    id,
    imageUrl: user?.imageUrl,
    promise: formatCash(user?.promise || 0, '₦'),
    createdDate: moment(new Date(createdAt)).format('MMM DD, YYYY'),
    createdTime: moment(new Date(createdAt)).format('hh:mm a'),
    captureDate: moment(new Date(createdAt)).format('MMM DD, YYYY'),
    captureTime: moment(new Date(createdAt)).format('hh:mm a'),
    nextPaymentDueDate: nextRepaymentDate ? moment(nextRepaymentDate).format('MMM DD, YYYY') : '**********',
    ...rest,
    tenor: `${tenor} Months`,
    loanSchedules,
    currency: 'NGN',
    trxAmount: formatCash(amount || 0, '₦'),
    refNo: id || 'N/A',
    fees: formatCash(interestPaid || 0, '₦'),
    total: formatCash(amount - (fees || 0), '₦'),
    subTotal: formatCash(amount, '₦'),
    name: name?.trim() || 'N/A',
    email: user?.email,
    status: loanStatus?.name,
    orderItems: rest?.order?.orderItems,
    userId: user?.id,
    phone: user?.phone,
    firstName: user?.firstName,
    lastName: user?.lastName,
    userStatus: user?.userStatus === 1 ? 'ACTIVE' : 'INACTIVE',
    interest: `${interest}%`,
    amountDue: outstandingAmount,
    paymentCard: `**** ${user?.activeCard?.last4digits}`,
    merchant: merchant?.name || '**********',
    startDate: startDate
      ? moment(new Date(startDate)).format('MMM DD YYYY')
      : 'N/A',
    endDate: endDate
      ? moment(new Date(endDate)).format('MMM DD, YYYY')
      : 'N/A',
    orderAmount: rest?.order?.amount ? formatCash(rest?.order?.amount, '₦') : 'N/A',
    loanAmount: formatCash(totalLoanScheduleAmount, '₦'),
  };
  return out;
};

export const getColor = (name) => {
  const letters = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
  ];
  const firstLetter = name[0];
  const pos = letters.indexOf(firstLetter) % 3;
  const availableColors = ['#FA5B7C', '#00C48C', '#5941A9'];

  return availableColors[Math.abs(pos)];
};

export const isAdmin = () => process.env.REACT_APP_TYPE === 'ADMIN';

export const getOrdinalNumber = (number) => {
  const suffixes = ['th', 'st', 'nd', 'rd'];
  const index = number % 100 > 3 && number % 100 < 21 ? 0 : number % 10;
  const suffix = suffixes[index];
  return number + suffix;
};

export const checkExpiredDate = (date) => {
  const today = new Date();
  const expiryDate = new Date(date);
  return expiryDate < today;
};

export const processDocStatus = (status) => {
  switch (status) {
  case '-1':
    return 'rejected';
  case '1':
    return 'approved';
  default:
    return 'pending';
  }
};

export const convertUrlToHTTPS = (url) => {
  if (!url) return url;
  const httpsUrl = url.replace('http://', 'https://');
  return httpsUrl;
};

export const generateRandomCustomString = () => {
  const nanoid = customAlphabet(urlAlphabet, 10);
  return nanoid();
};

const nameTag = {
  cacDoc: 'CAC_DOCUMENT',
  certificateOfIncorporation: 'CERTIFICATE_OF_INCORPORATION_DOCUMENT',
  memorandum: 'MEMORANDUM_DOCUMENT',
  proofOfAddress: 'PROOF_OF_ADDRESS_DOCUMENT',
  identityDocument: 'OWNER_IDENTITY_DOCUMENT',
};

export const getDoc = (
  name:
    | 'cacDoc'
    | 'certificateOfIncorporation'
    | 'memorandum'
    | 'proofOfAddress'
    | 'identityDocument',
  arr: any,
) => {
  if (arr?.length) {
    const found = arr?.find(
      (ele: { documentType: string }) => ele?.documentType === nameTag[name],
    );
    return {
      file: {
        name: found?.documentType,
        url: found?.uri,
        docKey: found?.docKey,
        fileName: found?.fileName,
      },
    };
  }
};
export const mailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const compareobject = (obj1, obj2) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const eachKey in obj1) {
    if (obj1[eachKey] !== obj2[eachKey]) {
      return false;
    }
  }
  return true;
};
export const setItemsToLocalStorage = (name, data) => {
  localStorage.setItem(name, JSON.stringify(data));
};
export const getItemsToLocalStorage = (name) => {
  const actualData = JSON.parse(localStorage.getItem(name));
  return actualData;
};

export const compareStringify = (obj1, obj2) => {
  try {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  } catch (error) {
    return false;
  }
};

export const addHttpsToLinks = (link:any) => {
  const hasHttps = link.substring(0, 8);
  const hasHttp = link.substring(0, 7);
  if (!link) {
    return '';
  }
  if (hasHttps === 'https://' || hasHttp === 'http://') {
    return link;
  }

  if (link && (hasHttps !== 'https://' || hasHttp !== 'http://')) {
    return `https://${link}`;
  }
};

export const dateisDue = (dateRaw: string) => {
  const date = new Date(dateRaw);
  // check if date is valid
  if (date?.toString?.() === 'Invalid Date') {
    return false;
  }
  if (date.getTime() < new Date().getTime()) {
    return true;
  }
};

export const isLoanScheduleDueForDebit = (loanSchedule: any, loan: any) : string | undefined => {
  const dueDate = loanSchedule?.dueDate;
  const isDue = dateisDue(dueDate);
  const isFullyPaid = loanSchedule?.isFullyPaid;
  if (isDue && !isFullyPaid && ['APPROVED', 'ONGOING'].includes(loan?.status?.toUpperCase?.()) && ['PAYFI', 'CDL'].includes(loan?.paymentChannel?.toUpperCase?.())) {
    return loanSchedule?.id;
  }
  return undefined;
};

export const useImperativeQuery = (query) => {
  const { refetch } = useQuery(query, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    skip: true,
  });

  const imperativelyCallQuery = (variables) => refetch(variables);

  return imperativelyCallQuery;
};

export const addEllipsis = (str: string, maxLength: number = 40) => {
  if (str?.length > maxLength) {
    return `${str.substring(0, maxLength)}...`;
  }
  return str;
};

export const getAge = (date) => {
  const today = new Date();
  const birthDate = new Date(date);
  const age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    return age - 1;
  }
  return age;
};

export const validateDateOfBirth = (dateOfBirth: string) => {
  // must be a valid date
  if (typeof dateOfBirth !== 'string') return false;
  const date = new Date(dateOfBirth);
  if (date?.toString?.() === 'Invalid Date') {
    return false;
  }
  // must be at least 18 years old
  const age = getAge(date);
  if (age < 21) {
    return false;
  }
  return true;
};

export const cleanColumnArray = (columns: any) => columns?.map?.((ele) => {
  if (Array.isArray(ele)) {
    return ele?.join('__');
  } return ele;
});

export const documentNameTag: any = {
  cacDoc: 'CAC_DOCUMENT',
  certificateOfIncorporation: 'CERTIFICATE_OF_INCORPORATION',
  memorandumAndArticlesOfAssociation: 'MEMORANDUM_AND_ARTICLES_OF_ASSOCIATION',
  SCUMLCertificate: 'SCUML_CERTIFICATE',
  CACStatusReport: 'CAC_STATUS_REPORT',
  certificateOfBusinessName: 'CERTIFICATE_OF_BUSINESS_NAME',
  proofOfAddress: 'PROOF_OF_ADDRESS_DOCUMENT',
};

export const businessDocuments = (businessType: string, kycDocs) => {
  const IncorporatedDocumentTypes = [
    {
      name: 'certificateOfIncorporation',
      label: 'Certificate Of Incorporation',
      filename: documentNameTag.certificateOfIncorporation,
      fileInp: kycDocs?.certificateOfIncorporation,
    },
    {
      name: 'memorandumAndArticlesOfAssociation',
      label: 'Memorandum And Articles Of Association',
      filename: documentNameTag.memorandumAndArticlesOfAssociation,
      fileInp: kycDocs?.memorandumAndArticlesOfAssociation,
    },
    {
      name: 'SCUMLCertificate',
      label: 'SCUML Certificate',
      filename: documentNameTag.SCUMLCertificate,
      fileInp: kycDocs?.SCUMLCertificate,
      isOptional: true,
    },
    {
      name: 'CACStatusReport',
      label: 'CAC Status Report',
      filename: documentNameTag.CACStatusReport,
      fileInp: kycDocs?.CACStatusReport,
      isOptional: true,
    },
  ];

  const registeredBusinessDocumentTypes = [
    {
      name: 'certificateOfBusinessName',
      label: 'Certificate Of BusinessName',
      filename: documentNameTag.certificateOfBusinessName,
      fileInp: kycDocs?.certificateOfBusinessName,
    },
    {
      name: 'CACStatusReport',
      label: 'CAC Status Report',
      filename: documentNameTag.CACStatusReport,
      fileInp: kycDocs?.CACStatusReport,
      isOptional: true,
    },
    {
      name: 'SCUMLCertificate',
      label: 'SCUML Certificate',
      filename: documentNameTag.SCUMLCertificate,
      fileInp: kycDocs?.SCUMLCertificate,
      isOptional: true,
    },
  ];

  if (businessType?.toLowerCase?.() === 'incorporated') {
    return IncorporatedDocumentTypes;
  } if (businessType?.toLowerCase?.() === 'registered') {
    return registeredBusinessDocumentTypes;
  }

  return [];
};

export const getOtherDocuments = (kycDocs) => {
  const allowed = [
    'CAC_DOCUMENT', 'CERTIFICATE_OF_INCORPORATION', 'MEMORANDUM_AND_ARTICLES_OF_ASSOCIATION', 'SCUML_CERTIFICATE', 'CAC_STATUS_REPORT', 'CERTIFICATE_OF_BUSINESS_NAME',
  ];
  const others = kycDocs?.filter?.((each) => (!allowed.includes(each?.documentType?.toUpperCase?.())));
  return others;
};

export const getDocByName = (
  name: string,
  arr: any,
) => {
  if (arr?.length) {
    const found = arr?.find(
      (ele: { documentType: string }) => ele?.documentType === documentNameTag[name],
    );
    return {
      file: {
        name: found?.documentType,
        url: found?.uri,
        docKey: found?.docKey,
        fileName: found?.fileName,
      },
      ...found,
    };
  }
};

export const documentIsValid = (businessType: string, kycDocs) => {
  const records = businessDocuments(businessType, kycDocs);
  if (!records?.length) return false;
  const isValid = records.every((ele) => {
    if (ele.isOptional) return true;
    return ele?.fileInp?.file?.name;
  });
  return isValid;
};
export const MaskCharacter = (str, mask, n = 1) => {
  const getStr = str.toString();
  return (`${getStr}`).slice(0, 4) + (`${getStr}`).slice(4, -n).replace(/./g, mask) + (`${getStr}`).slice(-n);
};
export const capitalizeFirstLetter = (string) => string?.charAt(1)?.toUpperCase() + string?.substring(2)?.toLowerCase();
export const capitalizeEverFirstLetter = (string) => string?.replace(/(^\w|\s\w)(\S*)/g, (_, m1, m2) => m1.toUpperCase() + m2.toLowerCase());
export const addStyleToEvenElements = (n, className) => {
  switch (n % 2 !== 0) {
  case true:
    return className;
  default:
    return '';
  }
};
export const SplitName = (data?:string, position?:any) => {
  if (!position) {
    return data?.substring(0, data?.indexOf(' '));
  }
  if (position) {
    return data?.substring(data?.indexOf(' ') + position);
  }
};

// remove +234 or 234 from phone number
export const removeCountryCode = (phone: string) => {
  if (phone?.startsWith('+234')) {
    return phone?.replace('+234', '');
  }
  if (phone?.startsWith('234')) {
    return phone?.replace('234', '');
  }
  if (phone?.startsWith('0')) {
    return phone?.replace('0', '');
  }
  return phone;
};

export const debounce = (func, wait) => {
  let timeout;
  return (...args) => {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

export const getUserActivityStatusAndStyle = (name, activityStatus) => {
  let status = 'Not available';
  let statusStyle = 'style-not-available';
  if (name?.toLowerCase?.() === 'launch sdk') {
    status = 'Completed';
    statusStyle = 'style-completed';
  } else if (activityStatus?.toLowerCase?.() === 'success') {
    status = 'Completed';
    statusStyle = 'style-completed';
  } else if (activityStatus?.toLowerCase?.() === 'failing') {
    status = 'Failed';
    statusStyle = 'style-failed';
  }

  return {
    status,
    statusStyle,
  };
};

export const convertSpacesToHyphen = (str) => str?.trim?.().replace(/\s+/g, '-');
export const covertArrayToString = (str = []) => {
  const getText = str?.map((list:any) => (list));
  return getText?.toString();
};

export const adminSideBarPermissionsAndRoutes = [
  {
    link: '/dashboard',
    name: 'Overview',
    permission: 'can-get-all-admin-overview',
  },
  {
    link: '/customers?page=1',
    name: 'Customers',
    permission: ['can-view-all-customers', 'can-view-all-customer-tab', 'can-view-approved-customers', 'can-view-prospective-customers', 'can-view-priority-customers', 'can-view-declined-customers', 'can-view-deleted-customers'],
  },
  {
    link: '/admin-merchants?page=1',
    name: 'Merchants',
    permission: ['can-get-all-merchants', 'can-view-approved-merchants', 'can-view-awaiting-merchants', 'can-view-prospective-merchants', 'can-view-deactivated-merchants', 'can-view-declined-merchants'],
  },
  {
    link: '/transactions?page=1',
    name: 'Transactions',
    permission: ['can-view-all-transactions', 'can-view-all-transaction-tab', 'can-view-pending-transaction', 'can-view-successful-transaction', 'can-view-failed-transaction', 'can-view-all-wallet-transaction', 'can-view-wallet-pending-transaction', 'can-view-successful-wallet-transaction', 'can-view-failed-wallet-transaction'],
  },
  {
    link: '/rnpl/bolt?page=1',
    name: 'RNPL',
    permission: ['can-get-loan-voucher-codes', 'can-view-all-allocated-vouchers', 'can-view-all-active-vouchers', 'can-view-all-paid-vouchers', 'can-view-all-due-vouchers', 'can-view-all-defaulting-vouchers', 'can-view-all-uber-allocated-vouchers', 'can-view-active-uber-allocated-vouchers', 'can-view-paid-uber-allocated-vouchers', 'can-view-all-due-allocated-vouchers', 'can-view-all-defaulting-allocated-vouchers'],
  },
  {
    link: '/bills-payment?page=1',
    name: 'Bills Payment',
    permission: ['can-get-bills-payment', 'can-view-all-bills-payment', 'can-view-pending-bills-payment', 'can-view-successful-bills-payment', 'can-view-faild-bills-payment'],
  },
  {
    link: '/loans?page=1',
    name: 'Loans',
    permission: ['can-view-all-loans', 'can-view-all-loans-tab', 'can-view-pending-loans', 'can-view-disbursed-loans', 'can-view-declined-loans', 'can-view-awaiting-approval-loans'],
  },
  {
    link: '/refund-loans?page=1',
    name: 'Refund Loans',
    permission: ['can-view-refund-loans', 'can-view-all-refunds-loan', 'can-view-pending-refund-loan', 'can-view-completed-refund-loan'],
  },
  {
    link: '/repayments?page=1',
    name: 'Repayments',
    permission: ['can-get-repayments', 'can-view-all-repayments', 'can-view-due-repayments', 'can-view-upcoming-repayments', 'can-view-defaulting-repayments', 'can-view-paid-repayments'],
  },
  {
    link: '/admin-settlement?page=1',
    name: 'Settlements',
    permission: ['can-get-settlements', 'can-view-all-settlement', 'can-view-pending-settlement', 'can-view-settled-settlement', 'can-view-failed-settlement'],
  },
  {
    link: '/push-notification',
    name: 'Push Notifications',
    permission: 'can-get-notification-requests',
  },
  {
    link: '/sdk-activities',
    name: 'SDK Activities',
    permission: 'can-get-sdk-activities',
  },
  {
    link: '/check-eligibility',
    name: 'Check Eligibilty',
    permission: 'can-get-eligibility-request',
  },
  {
    link: '/disbursement?page=1',
    name: 'Disbursements',
    permission: ['can-get-disbursement-partner-activities', 'can-view-all-disbursements', 'can-view-renmoney-disbursement', 'can-view-cdl-disbursement', 'can-view-fcmb-disbursement', 'can-view-payfi-disbursement'],
  },
  {
    link: '/wallets?page=1',
    name: 'Wallets',
  },
  {
    link: '/audit-logs',
    name: 'Audit Logs',
    permission: 'can-get-audit-trails',
  },
  {
    link: '/configurations',
    name: 'Configuration',
    permission: 'can-get-repayment-config',
  },
  {
    link: '/settings',
    name: 'Settings',
  },
];

export const getRouteAdminCanGoBasedOnPermissions = (permissions: any) => {
  const adminPermissions = permissions?.map?.((each) => each?.name);
  if (!adminPermissions?.length) return '/settings';

  const allowedRoutes = adminSideBarPermissionsAndRoutes?.filter?.((each:any) => {
    const permissionIsArray = Array?.isArray(each?.permission);
    if (permissionIsArray) {
      return each?.permission?.find((list:any) => adminPermissions?.includes?.(list));
    }
    return adminPermissions?.includes?.(each?.permission);

    // if (each?.permission) return adminPermissions?.includes?.(each?.permission);
    // // console.log('retrun active route', permissionWithArray(each?.permission, adminPermissions));
    // return true;
  });

  return allowedRoutes?.[0]?.link || '/settings';
};

export const cleanSelectOverviewWithPermission = (permissions, customerFilterPermission) => {
  const newArray = [];
  customerFilterPermission?.map((listKey:any) => {
    const letsFindIfPermissionExist = permissions?.find((permissionKey:any) => permissionKey?.name === listKey?.permission);
    if (letsFindIfPermissionExist) {
      newArray?.push({ ...letsFindIfPermissionExist, defaultSelect: listKey?.defaultSelect });
    }
  });
  return newArray[0]?.defaultSelect || 'total';
};

export const getSelectOverviewPageWithPermission = (type, permissions) => {
  const adminPermissions = permissions;

  if (type === 'customers') {
    const customerFilterPermission = [
      { permission: 'can-filter-total-customer', defaultSelect: 'total' },
      { permission: 'can-filter-approved-customer', defaultSelect: 'approved' },
      { permission: 'can-filter-prospective-customer', defaultSelect: 'prospective' },
      { permission: 'can-filter-customers-declined', defaultSelect: 'declined' },
      { permission: 'can-filter-customers-deleted', defaultSelect: 'deleted' },
    ];
    return cleanSelectOverviewWithPermission(adminPermissions, customerFilterPermission);
  }
  if (type === 'merchants') {
    const customerFilterPermission = [
      { permission: 'can-filter-total-merchant', defaultSelect: 'total' },
      { permission: 'can-filter-approved-merchant', defaultSelect: 'approved' },
      { permission: 'can-filter-prospective-merchant', defaultSelect: 'prospective' },
      { permission: 'can-filter-awaiting-approval-merchant', defaultSelect: 'awaiting_approval' },
      { permission: 'can-filter-merchant-declined', defaultSelect: 'declined' },
      { permission: 'can-filter-merchants-deactivated', defaultSelect: 'inactive' },
    ];
    return cleanSelectOverviewWithPermission(adminPermissions, customerFilterPermission);
  }
  if (type === 'loans') {
    const customerFilterPermission = [
      { permission: 'can-filter-total-loan-disbursed', defaultSelect: 'total_disbursed' },
      { permission: 'can-filter-total-loan', defaultSelect: 'total' },
      { permission: 'can-filter-pending-loan-request', defaultSelect: 'pending' },
      { permission: 'can-filter-declined-loans', defaultSelect: 'declined' },
    ];
    return cleanSelectOverviewWithPermission(adminPermissions, customerFilterPermission);
  }
  if (type === 'loanCount') {
    const customerFilterPermission = [
      { permission: 'can-filter-total-loan-disbursed', defaultSelect: 'total_disbursed' },
      { permission: 'can-filter-total-loan-volume', defaultSelect: 'total' },
      { permission: 'can-filter-pending-loan-request', defaultSelect: 'pending' },
      { permission: 'can-filter-declined-loan', defaultSelect: 'declined' },
    ];
    return cleanSelectOverviewWithPermission(adminPermissions, customerFilterPermission);
  }
  if (type === 'transactions') {
    const customerFilterPermission = [
      { permission: 'can-filter-successful-transactions', defaultSelect: 'success' },
      { permission: 'can-filter-total-transactions', defaultSelect: 'total' },
      { permission: 'can-filter-failed-transactions', defaultSelect: 'failed' },
      { permission: 'can-filter-pending-transactions', defaultSelect: 'pending' },
    ];
    return cleanSelectOverviewWithPermission(adminPermissions, customerFilterPermission);
  }
  if (type === 'settlement') {
    const customerFilterPermission = [
      { permission: 'can-filter-pending-settlement', defaultSelect: 'pending' },
      { permission: 'can-filter-total-settlement', defaultSelect: 'total' },
      { permission: 'can-filter-settled-settlement', defaultSelect: 'settled' },
      { permission: 'can-filter-failed-settlement', defaultSelect: 'failed' },
    ];
    return cleanSelectOverviewWithPermission(adminPermissions, customerFilterPermission);
  }
};

export const adminHasPermission = (permissions, permission) => {
  const adminPermissions = permissions?.map?.((each) => each?.name);
  if (!adminPermissions?.length) return true;
  return adminPermissions?.includes?.(permission);
};

export const convertCsvFileToJSON = (file: any) => new Promise((resolve) => {
  // using papa parse to convert csv file to json
  Papa.parse(file, {
    header: true,
    complete: (results) => {
      resolve(results?.data);
    },
  });
});

export const processVoucherCodes = async (file: any) => {
  const fileData: any = await convertCsvFileToJSON(file);
  let voucherKeyToUse = '';
  Object.keys(fileData?.[0])?.forEach((key) => {
    if (key?.toLowerCase?.()?.includes?.('vouchers')) {
      voucherKeyToUse = key;
    }
  });
  if (!voucherKeyToUse) {
    return {
      error:
        'Invalid csv file format. File should contain the column "vouchers"',
    };
  }

  // check for duplicate vouchers
  const voucherDuplicateMap = {};
  let duplicateCounts = 0;
  const voucherRecords = [];

  for (let index = 0; index < fileData.length; index++) {
    const obj = fileData[index];
    const voucherCode = obj?.[voucherKeyToUse]?.trim?.();
    if (voucherCode) {
      if (voucherDuplicateMap[voucherCode]) {
        voucherDuplicateMap[voucherCode] += 1;
        duplicateCounts += 1;
      } else {
        voucherDuplicateMap[voucherCode] = 1;
        voucherRecords.push(voucherCode);
      }
    }
  }
  if (!voucherRecords?.length) {
    return {
      error: 'No valid voucher code found in the file',
    };
  }
  return {
    result: {
      duplicate: duplicateCounts,
      new: voucherRecords?.length,
      vouchers: voucherRecords,
    },
  };
};

export const addPhoneCountryCode = (phone:string) => {
  const prefixCode = phone?.substr(0, 4);
  const appendCode = phone?.substr(1, phone?.length);

  if (prefixCode === '+234') {
    return phone;
  }
  return phone ? `+234${appendCode}` : '';
};

export const phoneNumberValidator = (str) => {
  if (str.match(/^\+?[0-9]\d{1,20}$/)) {
    return true;
  }
  return false;
};

export const getMonthPluralization = (value) => {
  let loanTenorStringAttach;
  if (value < 1) {
    loanTenorStringAttach = '';
  } else if (value < 2) {
    loanTenorStringAttach = 'month';
  } else {
    loanTenorStringAttach = 'months';
  }
  return loanTenorStringAttach;
};

export const returnNumber = (num: string | number, fallBack) => {
  const result = Number.isNaN(Number(num)) ? fallBack : Number(num);
  return result;
};
