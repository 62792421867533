import { useMutation } from '@apollo/client';
import { FunctionComponent, useEffect, useState } from 'react';
import useForm from '../../customHooks/useForm';
import useToast from '../../customHooks/useToast';
import {
  CREATE_PAYMENT_LINK, UPDATE_PAYMENT_LINK,
} from '../../graphql/mutations';

import TextInput from '../../components/input';
import ModalFormComp from '../../components/ModalFormComp';
import SHowMoreIcon from '../../svgs/SHowMoreIcon';
import ShowLessIcon from '../../svgs/ShowLessIcon';
import InputCreator from '../../components/InputCreator';
import { generateRandomCustomString } from '../../utils/helpers';
import GetPaymentNameStatus from '../../components/GetPaymentNameStatus';
import EditTitleIcon from '../../svgs/EditTitleIcon';
// import StyledButton from '../../components/StyledButton';

export interface IAddPaymentLinks {
  closeModal?: any;
  refetch?: any;
  triggerSuccessModal?: any;
  payload?: any;
}

const AddPaymentLinks: FunctionComponent<IAddPaymentLinks> = ({
  closeModal,
  refetch,
  triggerSuccessModal,
  payload,
}) => {
  const Toast = useToast();
  const {
    formValues, handleChange, setError, checkAllRequired, resetForm,
  } = useForm(
    {
      customUrl: '',
      title: '',
      description: '',
      amount: '',
      successMessage: '',
    },
    {},
    Toast,
  );
  const [loading, setLoading] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [inputs, setInputs] = useState([
    {
      name: 'input1',
      value: '',
    },
  ]);

  const [createPaymentLink] = useMutation<
  { createMerchantPaymentLink: any },
  any
>(CREATE_PAYMENT_LINK);

  const [editPaymentLink] = useMutation<
{ updateMerchantPaymentLink: any },
any
>(UPDATE_PAYMENT_LINK);

  useEffect(() => {
    if (payload) {
      resetForm({
        customUrl: payload.name,
        title: payload.title,
        description: payload.description,
        amount: payload?.oldAmount,
        successMessage: payload.successMessage,
      });

      setInputs(payload?.inputsArray?.map((item: any, i: number) => ({
        name: `input${i + 1}`,
        value: item.name,
      })));
    }
  }, [payload]);

  const getInputs = () => {
    const inputArr = [];
    const pattern = /^[a-zA-Z0-9_$%&()\s]*$/;
    const namesValidate = [];
    // eslint-disable-next-line no-unreachable-loop
    for (let i = 0; i < inputs.length; i++) {
    // validate input
      if (inputs[i].value.length > 0) {
        if (!pattern.test(inputs[i].value)) {
          Toast.error(`Input ${inputs[i].value} is invalid. Inputs should be alphanumeric. Characters )_$%(& are allowed.`);
          return;
        }
        if (namesValidate.includes(inputs[i]?.value?.toLowerCase?.())) {
          Toast.error(`Input ${inputs[i].value} is invalid. Inputs should be unique.`);
          return;
        }
        inputArr.push({ name: inputs[i].value });
        namesValidate.push(inputs[i]?.value?.toLowerCase?.());
      }
    } return inputArr;
  };

  const removeWhiteSpace = (value: string) => value.replace(/\s/g, '-')?.toLowerCase?.();

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    if (!checkAllRequired(['successMessage', 'customUrl'])) {
      setLoading(false);
      Toast.error('Please fill all required fields');
      return;
    }
    const inputArr = getInputs();
    if (!inputArr) {
      setLoading(false);
      return;
    }
    if (Number(formValues?.amount) < 1) {
      setLoading(false);
      Toast.error('Amount should be greater than 0');
      return;
    }

    try {
      const payloadObj = payload ? {
        name: formValues?.customUrl
          ? removeWhiteSpace(formValues?.customUrl) : generateRandomCustomString(),
        input: {
          title: formValues?.title,
          description: formValues?.description,
          configurations: JSON.stringify({
            amount: formValues?.amount,
            successMessage: formValues?.successMessage,
            inputsArray: inputArr,
            showAmount: false,
          }),
        },
      } : {
        name: formValues?.customUrl
          ? removeWhiteSpace(formValues?.customUrl) : generateRandomCustomString(),
        title: formValues?.title,
        description: formValues?.description,
        configurations: JSON.stringify({
          amount: formValues?.amount,
          successMessage: formValues?.successMessage,
          inputsArray: inputArr,
          showAmount: false,
        }),
      };

      const func = payload ? editPaymentLink : createPaymentLink;

      // eslint-disable-next-line no-unreachable
      const resp = await func({
        variables: payloadObj,
      });
      const resField = payload ? 'updateMerchantPaymentLink' : 'createMerchantPaymentLink';
      setLoading(false);
      if (resp?.data?.[resField]) {
        // set success message
        Toast.success('Payment Link created successfully');
        resetForm();
        refetch?.();
        triggerSuccessModal?.(resp?.data?.[resField]);
        closeModal();
      } else {
        Toast.error(
          resp?.data?.[resField]?.message || 'Operation failed',
        );
      }
    } catch (e) {
      setLoading(false);
      if (e && e.graphQLErrors && e.graphQLErrors.length > 0) {
        Toast.error(e.graphQLErrors[0].message);
      } else {
        Toast.error('Something went wrong');
      }
    }
  };

  return (
    <ModalFormComp
      titleIcon={<EditTitleIcon />}
      title={`${payload ? 'Edit Payment Link' : 'Create New Payment Link'}`}
      closeModal={closeModal}
      btnAction={handleSubmit}
      btnDisabled={loading}
      btnName={payload ? (loading ? 'Saving...' : 'Save') : (loading ? 'Creating...' : 'Create')}
      hasCancel
    >
      <form onSubmit={handleSubmit} className="add-new-link">
        <h6 style={{
          textAlign: 'center', fontSize: '12px', color: '#667085', fontFamily: 'Inter_Regular', letterSpacing: '-0.2%',
        }}
        >
          Enter details to create a payment link you can share to your customers
        </h6>
        <TextInput
          name="title"
          value={formValues?.title}
          customOnChange={handleChange}
          label="Payment Link Name"
          width="100%"
          required
        />
        <TextInput
          label="Amount"
          name="amount"
          value={formValues?.amount}
          customOnChange={handleChange}
          width="100%"
          required
          type="cash"
        />
        <TextInput
          label="Description"
          name="description"
          value={formValues?.description}
          customOnChange={handleChange}
          width="100%"
          required
          rows={4}
          multiline
        />
        {showMore && (
          <div>
            <TextInput
              disabled={!!payload}
              name="customUrl"
              value={removeWhiteSpace(formValues?.customUrl || '')}
              customOnChange={handleChange}
              label="Custom Url (optional)"
              width="100%"
              prefix={`${process.env.REACT_APP_PAYFI_WEBSITE_URL}/pay/`}
              placeholder="Add your url"
              CustomEndAdornment={payload ? undefined : (
                <GetPaymentNameStatus
                  input={removeWhiteSpace(formValues?.customUrl)}
                />
              )}
            />
            <TextInput
              name="successMessage"
              value={formValues?.successMessage}
              customOnChange={handleChange}
              label="Success message (optional)"
              width="100%"
            />
            <InputCreator inputs={inputs} setInputs={setInputs} />
          </div>
        )}
        <div onClick={() => setShowMore((prev) => !prev)} className="show-more">
          <span>{showMore ? 'Show less' : 'Show more'}</span>
          {showMore ? <ShowLessIcon color="#98A2B3" /> : <SHowMoreIcon color="#98A2B3" />}
        </div>
        {/* <div className="payment-link-save-div">
          <span>
            <StyledButton
              name="Cancel"
              disabled={loading}
              onClick={closeModal}
            />
          </span>
          <StyledButton
            name={payload ? (loading ? 'Saving...' : 'Save') : (loading ? 'Creating...' : 'Create')}
            disabled={loading}
          />
        </div> */}
      </form>
    </ModalFormComp>
  );
};

export default AddPaymentLinks;
