import React from 'react';

interface Props {
  color: string;
  width?:string;
  height?:string;
}

const CheckIcon = ({ color, width, height }: Props) => (
  <svg
    width={width || '15'}
    height={height || '12'}
    viewBox="0 0 15 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.99997 8.586L1.70697 5.293L0.292969 6.707L4.99997 11.414L14.707 1.707L13.293 0.292999L4.99997 8.586Z"
      fill={color || 'white'}
    />
  </svg>
);

export default CheckIcon;
