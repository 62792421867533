/* eslint-disable eol-last */
/* eslint-disable object-curly-spacing */
/* eslint-disable indent */
export const numberWithCommas = (x) => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const formatCash = (numInp = 0, currencyIcon = null, showDecimals = true) => {
    // if it is a not a number return number
    if (Number.isNaN(Number(numInp))) {
        return numInp;
    }
    const num = showDecimals ? Number(numInp || 0).toFixed(2) : Math.floor(Number(numInp || 0));
    const str = num.toString().replace(/,/g, '');
    // eslint-disable-next-line no-param-reassign
    const currency = `${currencyIcon || ''}`;
    return currency + numberWithCommas(str);
};

export const cleanUp = (data, arr = []) => {
    if (!arr.length || !data) return data;
    const res = data.map((raw) => {
        const { createdAt, amount } = raw;
        const ele = {...raw };
        if (arr.includes('date') && createdAt) {
            ele.createdAt = new Date(createdAt).toDateString();
        }
        if (arr.includes('amount') && amount) {
            ele.amount = formatCash(amount);
        }
        return ele;
    });
    return res;
};

export const getClassStyle = (content, key) => {
    const allowedArr = [
        'enabled',
        'active',
        'deactivate',
        'completed',
        'successful',
        'settled',
        'failed',
        'pending',
        'success',
        'captured',
        'credit',
        'type',
        'cancelled',
        'approved',
        'charges',
        'fees',
        'commission',
        'vendorFees',
        'comment',
        'transactionType',
        'settlementStatus',
        'defaultCharges',
        'message',
    ];

    if (['amount', 'trxAmount'].includes(key)) return 'amount-style';
    if (['comment'].includes(key)) return 'comment-style';
    if (['message'].includes(key)) return 'message-style';
    if (['defaultCharges'].includes(key)) return 'defaultCharges-style';
    if (['settlementStatus'].includes(key)) return 'settlementStatus-style';
    if (['transactionType'].includes(key)) return 'transactionType-style';
    if (['charges', 'fees', 'commission', 'vendorFees'].includes(key)) return 'amount-charges-style';
    const classMap = `${key?.toLowerCase?.()}-${
    allowedArr.includes(content[key])
      ? content[key]?.toLowerCase?.()
      : content[key]?.toLowerCase?.() || !!content[key]
  }`;

    const classObjRef = {
        'isapproved-true': 'active-status',
        'isapproved-false': 'inactive-status',
        'status-n/a': 'inactive-status',
        'status-inactive': 'inactive-status',
        'status-true': 'active-status',
        'status-active': 'active-status',
        'status-approved': 'active-status',
        'status-settled': 'active-status',
        'status-success': 'active-status',
        'status-successful': 'active-status',
        'status-paid': 'active-status',
        'status-repayment_ongoing': 'card-status',
        'status-ongoing': 'card-status',
        'status-captured': 'active-status',
        'status-false': 'inactive-status',
        'status-cancelled': 'inactive-status',
        'quantity-false': 'out-of-stock',
        'transactiontype-credit': 'credit-status',
        'statusname-payment_pending': 'pending-status',
        'status-pending': 'pending-status',
        'status-not due': 'not-due-status',
        'status-defaulting': 'defaulting-status',
        'status-due': 'due-status',
        'status-failed': 'inactive-status',
        'status-awaiting_approval': 'pending-approval-status',
        'status-awaiting approval': 'pending-approval-status',
        'status-paad_pending': 'pending-status',
        'status-refunded': 'deactivated-status',
        'status-pending_refund': 'pending-approval-status',
        'status-paad pending': 'inactive-status',
        'status-pending refund': 'pending-status',
        'statusname-intransit': 'transit-status',
        'statusname-delivered': 'active-status',
        'statusname-processing': 'pending-status',
        'statusname-cancelled': 'inactive-status',
        'statusname-paid': 'active-status',
        'status-refund complete': 'active-status',
        'statusname-complete': 'active-status',
        'statusname-completed': 'active-status',
        'statusname-user_cancelled': 'inactive-status',
        'statusname-pending_delivery': 'transit-status',
        'enabled-default': 'active-status',
        'enabled-inactive': 'inactive-status',
        'type-bnpl': 'credit-status',
        'type-credit': 'credit-status',
        'type-loan': 'credit-status',
        'type-instant': 'pending-status',
        'type-card': 'card-status',
        'type-card activation': 'card-status',
        'pushnotificationstatus-in-progress': 'in-progress',
        'pushnotificationstatus-completed': 'completed',
        'pushnotificationstatus-initiated': 'initiated',
        'eligibilitystatus-processing': 'processing',
        'eligibilitystatus-completed': 'completed',
        'eligibilitystatus-pending': 'pending',
        'eligibilitystatus-failed': 'failed',
        'status-frozen': 'failed',
        'status-deactivated': 'deactivated-status',
    };

    return classObjRef[classMap] || '';
};