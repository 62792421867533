import React from 'react';
import RegisterCircleDecoration from '../../svgs/RegisterCircleDecoration';
import PayfiLogo from './PayfiLogo';

const LoginContComp = () => (
  <div className="col-md-6 col-12 login-form-view-cover-row-cont">
    <div className="login-form-view-cover-row-cont-logo">
      <PayfiLogo />
    </div>
    <div className="login-form-view-cover-row-cont-title">
      More
      {' '}
      <span className="login-form-view-cover-row-cont-title-span">
        <span className="login-form-view-cover-row-cont-title-span-circle"><RegisterCircleDecoration /></span>
        Sales
      </span>
      , Every
      <br />
      day,
      {' '}
      <span>All year.</span>
    </div>
    <div className="login-form-view-cover-row-cont-subtitle">
      Grow your business by offering
      <br />
      customers to pay in up to 6 instalments.

    </div>
    <div className="login-form-view-cover-row-cont-avater-div">
      <div className="login-form-view-cover-row-cont-avater-div-span">
        <img src="https://publicpayfiimages.s3.amazonaws.com/randomAsset/loginMerchant1_iteroarprludgoifjdaj.png" alt="" style={{ backgroundColor: '#fff' }} />
      </div>
      <div className="login-form-view-cover-row-cont-avater-div-span">
        <img src="https://publicpayfiimages.s3.amazonaws.com/randomAsset/loginMerchant2_mxl82bgrmujtislswixx.png" alt="" style={{ backgroundColor: '#DDDEDD' }} />
      </div>
      <div className="login-form-view-cover-row-cont-avater-div-span">
        <img src="https://publicpayfiimages.s3.amazonaws.com/randomAsset/loginmerchant3_ykbgfirdqmbceliyiohg.webp" alt="" style={{ backgroundColor: '#FFFFFF' }} />
      </div>
      <div className="login-form-view-cover-row-cont-avater-div-span">
        <img src="https://payfi-assets.s3.amazonaws.com/download.jpeg" alt="" style={{ backgroundColor: '#BEA887' }} />
      </div>
      <div className="login-form-view-cover-row-cont-avater-div-span">
        <img src="https://publicpayfiimages.s3.amazonaws.com/randomAsset/loginMerchant5_DREAMS-modified_vzaebb.png" alt="" style={{ backgroundColor: '#FFF' }} />
      </div>
      <span>Join 1,000+ merchants</span>
    </div>
  </div>
);
export default LoginContComp;
