import React from 'react';
import useToast from '../../customHooks/useToast';
import AddNew from '../../svgs/AddNew';
import RemoveOld from '../../svgs/RemoveOld';
import './InputCreator.scss';

type Props = {
  inputs: any;
  setInputs: any;
}
const maxInput = 4;

const InputCreator = ({ inputs, setInputs }: Props) => {
  const Toast = useToast();

  const addNewInput = () => {
    if (inputs.length < maxInput) {
      setInputs([...inputs, { name: `input${(inputs?.length || 0) + 1}`, value: '' }]);
    } else {
      Toast.error(`You can only add ${maxInput} inputs`);
    }
  };
  const removeInput = () => {
    if (inputs.length > 1) {
      // remove last input
      setInputs((prev) => prev.slice(0, prev.length - 1));
    }
  };

  const handleChange = (e: any) => {
    const { value, name } = e.target;
    setInputs((prev: any) => {
      const index = prev.findIndex((input) => input.name === name);
      const newInputs = [...prev];
      newInputs[index].value = value;
      return newInputs;
    });
  };

  return (
    <div className="input-creator">
      <span className="input-label">
        Additional information (Optional)
      </span>
      {
        inputs?.map?.(({ name, value }, index) => (
          <input
            key={index}
            name={name}
            value={value}
            className="input-tags"
            onChange={handleChange}
          />
        ))
      }
      <div className="flexed-between">
        {inputs?.length < 4 && (
          <div onClick={addNewInput} className="add pointer">
            <AddNew />
          </div>
        )}
        {inputs?.length > 1 && (
        <div onClick={removeInput} className="remove pointer">
          <RemoveOld />
        </div>
        )}
      </div>
    </div>
  );
};

export default InputCreator;
