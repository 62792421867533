// import { FormControl } from '@material-ui/core';
import { FunctionComponent } from 'react';
import { Route, useHistory } from 'react-router-dom';
import DashboardWrap from '../../components/DashboardWrap';
import NavRouter from '../../components/NavRouter';
import MerchantBusinessProfile from './MerchantBusinessProfile';
import Profile from './MerchantProfile';
import APIWebhooks from './APIWebhooks';

import './Settings.scss';
import SettlementAccount from './SettlementAccount';
import Teams from './Teams';
import PrefrenceView from './Prefrence';
// import SettlementAccount from './SettlementAccount';

export interface ISettings {
  label?: string;
}

const Settings: FunctionComponent<ISettings> = () => {
  const { location, push } = useHistory();

  const data = [
    {
      link: '/settings/profile',
      name: 'Profile',
    },
    {
      link: '/settings/business-profile',
      name: 'Business Profile',
    },
    {
      link: '/settings/settlement-account',
      name: 'Settlement Account',
    },
    {
      link: '/settings/teams',
      name: 'Team',
    },
    {
      link: '/settings/webhooks',
      name: 'API & Webhooks',
    },
    // {
    //   link: '/settings/preference',
    //   name: 'Preferences',
    // },
  ];
  const pathname = location?.pathname;

  if (pathname === '/settings') {
    push('/settings/profile');
  }

  return (
    <DashboardWrap label="Settings" className="dashboard-wrap-children-without-padding">
      <NavRouter data={data} />
      <div className="settings-pages">
        <Route component={Profile} path="/settings/profile" />
        <Route component={MerchantBusinessProfile} path="/settings/business-profile" />
        <Route component={SettlementAccount} path="/settings/settlement-account" />
        <Route component={APIWebhooks} path="/settings/webhooks" />
        <Route component={Teams} path="/settings/teams" />
        <Route component={PrefrenceView} path="/settings/preference" />
      </div>
    </DashboardWrap>
  );
};

export default Settings;
