import React from 'react';

const DeactivateLinkIcon = () => (
  <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="4" y="4" width="48" height="48" rx="24" fill="#FEF0C7" />
    <path d="M27.9988 24.0002V28.0002M27.9988 32.0002H28.0088M26.2888 18.8602L17.8188 33.0002C17.6442 33.3026 17.5518 33.6455 17.5508 33.9947C17.5498 34.3439 17.6403 34.6873 17.8132 34.9907C17.9862 35.2941 18.2355 35.547 18.5365 35.7241C18.8375 35.9012 19.1796 35.9964 19.5288 36.0002H36.4688C36.818 35.9964 37.1601 35.9012 37.4611 35.7241C37.7621 35.547 38.0114 35.2941 38.1844 34.9907C38.3573 34.6873 38.4478 34.3439 38.4468 33.9947C38.4458 33.6455 38.3534 33.3026 38.1788 33.0002L29.7088 18.8602C29.5305 18.5663 29.2795 18.3233 28.98 18.1547C28.6805 17.9861 28.3425 17.8975 27.9988 17.8975C27.6551 17.8975 27.3171 17.9861 27.0176 18.1547C26.7181 18.3233 26.4671 18.5663 26.2888 18.8602Z" stroke="#DC6803" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <rect x="4" y="4" width="48" height="48" rx="24" stroke="#FFFAEB" strokeWidth="8" />
  </svg>
);

export default DeactivateLinkIcon;
