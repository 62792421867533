import React from 'react';

interface IPlusIcon{
  color?:string;
}
const PlusIcon = ({ color }:IPlusIcon) => (
  <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9 5.0009H1" stroke={color || 'white'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M5.00187 9V1" stroke={color || 'white'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>

);
export default PlusIcon;
