import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  concat,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';

//   const httpLink = new HttpLink({ uri: "http://localhost:5000/" });

const httpLink = createUploadLink({
  uri: process.env.REACT_APP_BASE_URL || 'https://dev.payfi.ng/',
});

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  operation.setContext({
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}` || null,
    },
  });

  return forward(operation);
});

const logoutLink = onError(({ networkError, graphQLErrors }: any) => {
  if (networkError && networkError.statusCode === 401) {
    // TODO: Log the user out
  }
  return graphQLErrors;
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  // @ts-ignore
  link: concat(authMiddleware, httpLink, logoutLink),
});

export default client;
