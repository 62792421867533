import React from 'react';

const PendingSettlementIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" rx="12" fill="#F9FAFB" />
    <path d="M11.9997 18.6673C15.6816 18.6673 18.6663 15.6825 18.6663 12.0007C18.6663 8.31875 15.6816 5.33398 11.9997 5.33398C8.31778 5.33398 5.33301 8.31875 5.33301 12.0007C5.33301 15.6825 8.31778 18.6673 11.9997 18.6673Z" stroke="#523B9F" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12 8V12L14.6667 13.3333" stroke="#523B9F" strokeLinecap="round" strokeLinejoin="round" />
  </svg>

);

export default PendingSettlementIcon;
