import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import useToast from '../../customHooks/useToast';
import { DELETE_PAYMENT_LINK } from '../../graphql/mutations';
import ErrorLinkIcon from '../../svgs/ErrorLinkIcon';
// import Buttons from '../Buttons';
import RoundedCancleIcon from '../ModalFormComp/RoundedCancleIcon';
import ModalWrapComp from '../ModalWrapComp';
import StyledButton from '../StyledButton';
import './PaymentLinkSmallModal.scss';

type Props = {
  closeModal: any;
  payload: any;
}

const DeleteType = ({
  closeModal, payload,
}: Props) => {
  const Toast = useToast();
  const [deleteLink] = useMutation<
{ deleteMerchantPaymentLink: any },
any
>(DELETE_PAYMENT_LINK);

  const [loading, setLoading] = useState(false);
  const { push } = useHistory();

  const handleDelete = async () => {
    setLoading(true);
    try {
      const resp = await deleteLink({
        variables: {
          name: payload?.name,
        },
      });

      if (resp?.data?.deleteMerchantPaymentLink?.status === 200) {
        Toast.success('You have successfully deleted this payment link and it will not show on your portal again');
        setLoading(false);
        push('/payment-links?page=1');
        return;
      }
      Toast.error('Error deleting link');
    } catch (error) {
      Toast.error('Error deleting link');
    }
    setLoading(false);
  };

  return (
    <ModalWrapComp closeModal={closeModal}>
      <div>
        <RoundedCancleIcon closeModal={closeModal} />
        <div className="success-payment-link">
          <div style={{ textAlign: 'center' }}>
            {' '}
            <ErrorLinkIcon />
          </div>
          <div className="title">

            <h6>{payload?.title}</h6>
          </div>
          <span className="description">
            Are you sure you want to delete
            <strong>{` ${payload?.title} `}</strong>
            ? This action cannot be undone.
          </span>
          <div className="twin-buttons-cover">
            {/* <Buttons
              name="Go back"
              className="cancel-type"
              onClick={closeModal}
              disabled={loading}
            /> */}
            <span style={{ marginRight: '20px' }}>
              {' '}
              <StyledButton name="Cancel" border="1px solid #DCD8EC" borderRadius="4px" color="#667085" backgroundColor="white" width="75px" onClick={closeModal} disabled={loading} fontSize="13px" height="40px" />
            </span>
            <StyledButton name={loading ? 'Loading...' : 'Delete Link'} border="1px solid #D92D20" borderRadius="4px" color="white" backgroundColor="#D92D20" width="102px" onClick={handleDelete} disabled={loading} fontSize="13px" height="40px" />
            {/* <Buttons
              name={loading ? 'Loading...' : 'Delete Link'}
              className="save-type bg-red"
              type="submit"
              btnStyle="btn-md"
              onClick={handleDelete}
              disabled={loading}
            /> */}
          </div>
        </div>
      </div>
    </ModalWrapComp>
  );
};

export default DeleteType;
