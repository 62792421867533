/* eslint-disable max-len */
/* eslint-disable react/jsx-no-bind */
import {
  Fragment, FunctionComponent, useEffect, useRef, useState,
} from 'react';
import { getClassStyle } from '../../utils/tableHelpers';
// import formatBodyTag from '../../utils/formatBody';
import Checkbox from '../CheckboxNew';
import ImageComp from '../InitialsImage';
import ViewLink from '../../svgs/ViewLink';
import MerchantDropdown from '../MerchantDropdown';
import ListIcon from '../../svgs/ListIcon';

import adminFormatBodyTag from '../../utils/adminFormatBody';
import EyeIcon from '../../svgs/EyeIcon';
import CheckIcon from '../../svgs/CheckIcon';
import FreezeIcon from '../../svgs/FreezeIcon';
import RemovePointIcon from '../../svgs/RemovePointIcon';
// import RolesAndPermissionGuard from '../RolesAndPermissionGuard';
// import ErrorPermissionModalComp from '../ErrorPermissionModalComp';
// import ApproveLoanModal from '../../admin_screens/AdminLoans/ApproveLoanModal';
// import DeclineLoanModal from '../../admin_screens/AdminLoans/DeclineLoanModal';
// import HorizontalListIcon from '../../svgs/HorizontalListIcon';

export interface ITableBody {
  content: any;
  clickable: any;
  handleClick: any;
  allowKeyArr: any;
  dominantName: any;
  imageToShow: any;
  hideDominantImage: any;
  action: any;
  checked?: any;
  handleChecked?: any;
  viewLinkFunc?: any;
  handleSelectDropdown?:any;
  dropdownData?:any;
  actionList?:any;
  viewLinkText?:any;
  btnObj?: any;
  formatLabels?:any;
  allowStatusName?:boolean;
  preventDoubleLinkTrigger?:boolean;
  hasActiveAndInactiveStatus?:boolean;
  dropDownIcon?:any;
  dropdownActiveDetails?:any;
  dropdownDeActiveDetails?:any;
  handleDropdown?:any;
  dropdownWidth?:string;
  rewardList?:any
}

const TableBody: FunctionComponent<ITableBody> = ({
  rewardList,
  content,
  clickable,
  handleClick,
  allowKeyArr,
  dominantName,
  imageToShow,
  hideDominantImage,
  action,
  checked,
  handleChecked,
  viewLinkFunc,
  handleSelectDropdown,
  dropdownData,
  actionList,
  viewLinkText,
  btnObj,
  allowStatusName,
  preventDoubleLinkTrigger,
  hasActiveAndInactiveStatus,
  dropDownIcon,
  dropdownActiveDetails,
  dropdownDeActiveDetails,
  handleDropdown,
  dropdownWidth,
}) => {
  const singleRec = (key_val: string, index?:any) => (
    <p
      className={`${
        [
          'status',
          'enabled',
          'quantity',
          'statusName',
          'settlementStatus',
          'transactionType',
          'type',
          'amount',
          'trxAmount',
          'charges',
          'fees',
          'commission',
          'vendorFees',
          'comment',
          'defaultCharges',
          'message',
          'pushNotificationStatus',
          'eligibilityStatus',
        ].includes(key_val)
          ? getClassStyle(content, key_val)
          : ''
      } `}
    >
      {typeof content[key_val] === 'object'
        ? key_val === 'image' ? <ImageComp content={content} index={index} hasType="small-type" /> : ''
        : (
      // eslint-disable-next-line react/jsx-no-useless-fragment
          <>
            {
              key_val === 'image' ? (
                <span>
                  <img className="admin-small-image-table" src={content[key_val] || 'https://publicpayfiimages.s3.amazonaws.com/randomAsset/dummyTableImage_imageBck_haw7f8.jpg'} alt="I" style={{ width: '40px', height: '40px', borderRadius: '4px' }} />
                </span>
              ) : adminFormatBodyTag(content, key_val, allowStatusName)
            }

          </>
        )}
    </p>
  );

  const doubleRec = (ele: [string, string] | any[]) => (
    <div className="double-cell">
      {singleRec(ele[0])}
      {singleRec(ele[1])}
    </div>
  );
  const hasBottomRec = (ele:any) => (
    <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
      <span>{singleRec(ele[0])}</span>
      <div style={{ marginLeft: '14px' }}>
        <div style={{
          width: '200px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis',
        }}
        >
          {singleRec(ele[1])}
        </div>
        <div style={{
          marginTop: '5px', marginBottom: '10px', width: '200px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis',
        }}
        >
          {ele[2] === 'link' ? <a href={`${content[ele[2]]}`} style={{ color: '#81899E' }}>{singleRec(ele[2])}</a> : content[ele[2]]}
        </div>
      </div>
    </div>
  );

  const [dropdownState, setdropdownState] = useState({
    status: false,
    id: '',
  });

  const handleShowDropdown = (id:any) => {
    if (dropdownState?.status) {
      setdropdownState({
        status: false,
        id: '',
      });
    } else {
      setdropdownState({
        id,
        status: true,

      });
    }
  };
  const handleAndClearDropdowState = (title:any, details:any) => {
    handleSelectDropdown(title, details);
    setdropdownState({
      status: false,
      id: '',
    });
  };

  const toggleRef = useRef<HTMLDivElement>(null);
  const [toggleDropdown, setToggleDropdown] = useState(false);

  const handleHideDropdown = (e) => {
    if (toggleRef?.current?.contains(e.target)) {
      return;
    }

    setToggleDropdown(false);
  };

  useEffect(() => {
    window.addEventListener('click', handleHideDropdown);
    return () => {
      window.removeEventListener('click', handleHideDropdown);
    };
  }, []);

  const handleOpenModal = (id:any) => {
    console.log(id);
    setToggleDropdown(!toggleDropdown);
  };

  return (
    <tbody>
      <tr className={action ? 'action-tr' : ''} style={{ position: 'relative' }}>
        {checked && (
          <td className="checkbox-cover">
            <div className="table-checkbox">
              <Checkbox
                onChange={({ target: { checked: checkedStatus } }) => handleChecked(content?.id, checkedStatus)}
                id={content?.id}
                name={`check-name-${content?.id}`}
                value={checked.includes(content?.id)}
                loading={false}
                onClick={() => {}}
              />
            </div>
          </td>
        )}
        {allowKeyArr.map((ele: any, index: number): any => {
          const key = ele;
          const isDouble = Array.isArray(ele);

          const ImageNameColumn = () => (
            <td
              onClick={() => {
                if (window.getSelection?.()?.toString()) {
                  return;
                }
                handleClick?.(content);
              }}
              style={{ cursor: clickable ? 'pointer' : '' }}
              className="flexed-row"
              key={index}
            >
              {!hideDominantImage && (
              <img
                className="table-img"
                src={
                  content[imageToShow]
                      || 'https://publicpayfiimages.s3.amazonaws.com/randomAsset/dummyTableImage_imageBck_haw7f8.jpg'
                }
                alt="img url"
              />
              )}
              <p>
                {typeof content[key] === 'object'
                  ? ''
                  : adminFormatBodyTag(content, key)}
              </p>
            </td>
          );
          const NormalColumns = () => (
            <td
              onClick={() => {
                if (window.getSelection?.()?.toString()) {
                  return;
                }
                handleClick?.(content);
              }}
              style={{ cursor: clickable ? 'pointer' : '' }}
              className=""
              key={index}
            >
              {!isDouble ? singleRec(ele, index) : isDouble && ele?.includes('hasBottomValue') ? hasBottomRec(ele) : doubleRec(ele)}

            </td>
          );

          if (imageToShow && dominantName && key === dominantName) {
            return (
              <Fragment key={index}>
                <ImageNameColumn />
              </Fragment>
            );
          }

          if (['img', 'imgUrl', 'image', 'photo'].includes(key)) {
            return (
              <Fragment key={index}>
                <td
                  onClick={() => {
                    handleClick?.(content);
                  }}
                  style={{ cursor: clickable ? 'pointer' : '' }}
                  className=""
                  key={index}
                >
                  <ImageComp content={content} key={key} index={key} />
                </td>
              </Fragment>
            );
          }

          if (['link'].includes(key)) {
            return (
              <Fragment key={index}>
                <td
                  onClick={preventDoubleLinkTrigger ? null : () => {
                    // prevent click during highlighting
                    if (window.getSelection?.()?.toString()) {
                      return;
                    }
                    handleClick?.(content);
                  }}
                  style={{ cursor: clickable ? 'pointer' : '' }}
                  className="link-view"
                  key={index}
                >
                  <ViewLink />
                  <span onClick={() => viewLinkFunc(content, key)}>
                    {viewLinkText || 'View Link'}
                  </span>
                </td>
              </Fragment>
            );
          }

          if (['btnType'].includes(key)) {
            return (
              <Fragment key={index}>
                <td
                  style={{ cursor: clickable ? 'pointer' : '' }}
                  className="btn-view"
                  key={index}
                >
                  {btnObj?.showOn(content) && (
                    <span className={btnObj?.shouldBeDisabled(content) ? 'disabled-row' : ''} onClick={() => btnObj?.cb(content)}>
                      {btnObj?.text?.(content)}
                    </span>
                  )}
                </td>
              </Fragment>
            );
          }

          return (
            <Fragment key={index}>
              <NormalColumns />
            </Fragment>
          );
        })}
        {action && (
          <td className="action-key">
            <span onClick={() => action.fxn(content)}>{action?.name}</span>
          </td>
        )}
        {
          actionList && (
          <td>
            <span onClick={() => handleShowDropdown(content?.id)} style={{ cursor: 'pointer' }}>
              {
                dropDownIcon || <ListIcon />
              }

            </span>
            {
              dropdownState?.status && dropdownState?.id === content?.id && (
              <div style={{
                position: 'absolute', background: 'white', right: '44px', width: dropdownWidth || '96px', zIndex: 1,
              }}
              >
                {' '}
                <MerchantDropdown dropdownActiveDetails={dropdownActiveDetails} hasActiveAndInactiveStatus={hasActiveAndInactiveStatus} data={dropdownData} details={content} handleDropdown={handleAndClearDropdowState} fontSize="13px" dropdownDeActiveDetails={dropdownDeActiveDetails} />
              </div>
              )
            }

          </td>
          )
        }
        {
          rewardList && (
          <td style={{ position: 'relative' }}>
            <span ref={toggleRef} onClick={() => handleOpenModal(content?.id)} style={{ cursor: 'pointer' }}>
              {
                dropDownIcon || <ListIcon />
              }

            </span>
            {
              toggleDropdown && (
              <div style={{
                position: 'absolute', background: 'white', right: '44px', width: dropdownWidth || '96px', zIndex: 1,
              }}
              >
                {' '}
                <MerchantDropdown hasClassName="loan-dropdown-details-list" data={content?.status === 'frozen' ? [{ title: 'Unfreeze Points', color: '#101828', icon: <CheckIcon color="#121212" width="12px" height="12px" /> }, { title: 'Remove Points', color: '#D92D20', icon: <RemovePointIcon /> }] : content?.point === '0' ? [{ title: 'Freeze Points', color: '#101828', icon: <FreezeIcon /> }] : [{ title: 'Freeze Points', color: '#101828', icon: <FreezeIcon /> }, { title: 'Remove Points', color: '#D92D20', icon: <RemovePointIcon /> }]} details={content} handleDropdown={handleAndClearDropdowState} fontSize="13px" />
              </div>
              )
            }

          </td>
          )
        }
        {
          content?.loanStatus?.name === 'AWAITING_APPROVAL' && (content?.loanChannel === 'zedvance' || content?.loanChannel === 'fewchore')
          && (
          <td style={{ position: 'relative' }}>
            <span ref={toggleRef} onClick={() => handleOpenModal(content?.id)} style={{ cursor: 'pointer' }}>
              { dropDownIcon }
            </span>
            {
              toggleDropdown && (
              <div style={{
                position: 'absolute', background: 'white', right: '20px', width: '170px', zIndex: 1000, fontSize: '13px',
              }}
              >
                <MerchantDropdown data={dropdownData} handleDropdown={(dropdownTitle) => handleDropdown(dropdownTitle, content)} hasClassName="loan-dropdown-details-list" />
              </div>
              )
            }
          </td>
          )
        }
        {
          content?.loanStatus?.name === 'PENDING_REFUND' && content?.isRefund
            && (
              <td style={{ position: 'relative' }}>
                <span ref={toggleRef} onClick={() => handleOpenModal(content?.id)} style={{ cursor: 'pointer' }}>
                  { dropDownIcon }
                </span>
                {
                  toggleDropdown && (
                  <div style={{
                    position: 'absolute', background: 'white', right: '20px', width: '170px', zIndex: 1000, fontSize: '13px',
                  }}
                  >
                    <MerchantDropdown data={dropdownData} handleDropdown={(dropdownTitle) => handleDropdown(dropdownTitle, content)} hasClassName="loan-dropdown-details-list" />
                  </div>
                  )
                }
              </td>
            )
        }
        {
          content?.loanStatus?.name === 'REFUND_COMPLETE' && content?.isRefund
            && (
              (
                <td style={{ position: 'relative' }}>
                  <span ref={toggleRef} onClick={() => handleOpenModal(content?.id)} style={{ cursor: 'pointer' }}>
                    { dropDownIcon }
                  </span>
                  {
                    toggleDropdown && (
                    <div style={{
                      position: 'absolute', background: 'white', right: '20px', width: '170px', zIndex: 1000, fontSize: '13px',
                    }}
                    >
                      <MerchantDropdown data={[{ title: 'View Details', color: '#05603A', icon: <EyeIcon width="10px" /> }]} handleDropdown={(dropdownTitle) => handleDropdown(dropdownTitle, content)} hasClassName="loan-dropdown-details-list" />
                    </div>
                    )
                  }
                </td>
              )
            )
        }
        {

          content?.status === 'PENDING'
            && (
              (
                <td style={{ position: 'relative' }}>
                  <span ref={toggleRef} onClick={() => handleOpenModal(content?.id)} style={{ cursor: 'pointer' }}>
                    { dropDownIcon }
                  </span>
                  {
                    toggleDropdown && (
                    <div style={{
                      position: 'absolute', background: 'white', right: '20px', width: '170px', zIndex: 1000, fontSize: '13px',
                    }}
                    >
                      <MerchantDropdown data={dropdownData} handleDropdown={(dropdownTitle) => handleDropdown(dropdownTitle, content)} hasClassName="loan-dropdown-details-list" />
                    </div>
                    )
                  }
                </td>
              )
            )
        }
      </tr>
    </tbody>
  );
};

export default TableBody;
