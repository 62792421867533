/* eslint-disable no-template-curly-in-string */
/* eslint-disable no-unsafe-optional-chaining */
import { FunctionComponent } from 'react';
import { useLocation } from 'react-router-dom';
import LinkComp from './LinkComp';
import './NavRouter.scss';

export interface INavRouter {
  label?: string;
  data?:any;
  className?: string;
  tabStyle?:string;
  activeSubTab?:boolean;
}

const NavRouter: FunctionComponent<INavRouter> = ({
  data,
  tabStyle,
}) => {
  const { pathname } = useLocation();
  return (
    <div className={tabStyle || 'settings-nav'}>
      {data?.map((props, index) => (

        <div key={index} className={`active-nav-cover ${pathname === props?.link || props?.activeRoute?.includes(pathname) ? 'settings-active-nav-cover' : 'settings-nav-cover'}`}>
          <LinkComp key={index} name={props?.name} link={props?.link} />
        </div>

      ))}

    </div>
  );
};

export default NavRouter;
