import React from 'react';

const ShareLinkIcon = () => (
  <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="4" y="4" width="48" height="48" rx="24" fill="#F4EBFF" />
    <mask id="mask0_599_26263" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="19" y="18" width="18" height="20">
      <path d="M34 32.12C33.24 32.12 32.56 32.42 32.04 32.89L24.91 28.74C24.96 28.51 25 28.28 25 28.04C25 27.8 24.96 27.57 24.91 27.34L31.96 23.23C32.5 23.73 33.21 24.04 34 24.04C35.66 24.04 37 22.7 37 21.04C37 19.38 35.66 18.04 34 18.04C32.34 18.04 31 19.38 31 21.04C31 21.28 31.04 21.51 31.09 21.74L24.04 25.85C23.5 25.35 22.79 25.04 22 25.04C20.34 25.04 19 26.38 19 28.04C19 29.7 20.34 31.04 22 31.04C22.79 31.04 23.5 30.73 24.04 30.23L31.16 34.39C31.11 34.6 31.08 34.82 31.08 35.04C31.08 36.65 32.39 37.96 34 37.96C35.61 37.96 36.92 36.65 36.92 35.04C36.92 33.43 35.61 32.12 34 32.12Z" fill="black" />
    </mask>
    <g mask="url(#mask0_599_26263)">
      <rect x="16" y="16" width="24" height="24" fill="#5941A9" />
    </g>
    <rect x="4" y="4" width="48" height="48" rx="24" stroke="#F9F5FF" strokeWidth="8" />
  </svg>
);

export default ShareLinkIcon;
