import { FunctionComponent, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  useGlobalDispatch,
  useGlobalState,
} from '../../contexts';
import DashboardIcon from '../../svgs/DashboardIcon';
import DisputesIcon from '../../svgs/DisputesIcon';
// import DropIcon from '../../svgs/DropIcon';
import SettlementIcon from '../../svgs/SettlementIcon';
import TransactionIcon from '../../svgs/TransactionIcon';
import LogsIcon from '../../svgs/LogsIcon';
import SettingsIcon from '../../svgs/SettingsIcon';
import LinkComp from './LinkComp';
import './MerchantSideBar.scss';
import { getNameInitials, setItemsToLocalStorage } from '../../utils/helpers';
import HomeIcon from '../../svgs/HomeIcon';
import LinkIcon from '../../svgs/LinkIcon';

export interface ISideBar {
  activate?: boolean;
  dashboard?: boolean;
  settlements?: boolean;
  transaction?: boolean;
  disputes?: boolean;
  disabled?: boolean;
  closeDropDownAndSideBar?: () => void;
}

const MerchantSideBar: FunctionComponent<ISideBar> = ({
  disabled, closeDropDownAndSideBar,
}) => {
  const { profile }:any = useGlobalState();
  const { clearUser } = useGlobalDispatch();
  const name = profile?.merchant?.name || '';
  const initials = getNameInitials(name);
  const { push } = useHistory();
  const { pathname } = useLocation();
  // const isApproved = !!profile?.merchant?.isApproved;
  const Logout = () => {
    clearUser();
    // setShowSideBar(false);
    closeDropDownAndSideBar?.();
    push('/');
  };
  useEffect(() => {
    switch (pathname) {
    case '/dashboard':
      setItemsToLocalStorage('nav-title', 'Dashboard');
      break;
    case '/home':
      setItemsToLocalStorage('nav-title', 'Get Started');
      break;
    default:
      break;
    }
  }, []);
  const TopList = [
    {
      disabled,
      icon: HomeIcon,
      name: 'Getting Started',
      link: '/home',
    },
    {
      link: '/dashboard',
      icon: DashboardIcon,
      name: 'Dashboard',
    },
  ];
  const BusinessList = [
    {
      disabled,
      icon: TransactionIcon,
      link: '/orders?page=1',
      name: 'Orders',
    },
    {
      disabled,
      icon: DisputesIcon,
      link: '/customers?page=1',
      name: 'Customers',
    },
    {
      disabled,
      icon: SettlementIcon,
      link: '/settlements?page=1',
      name: 'Settlements',
    },
  ];
  const PaymentList = [
    {
      disabled,
      icon: LinkIcon,
      link: '/payment-links',
      name: 'Payment Links',
    },
  ];

  const LastList = [
    {
      disabled,
      icon: SettingsIcon,
      link: '/settings',
      name: 'Settings',
    },
    {
      disabled,
      icon: LogsIcon,
      onClick: Logout,
      link: '/',
      name: 'Logout',
      shouldNotBeActive: true,
    },
  ];
  return (
    <div className="merchant-onboarding-side-nav">
      <div className="side-bar-body">
        <div className="merchant-container">
          <div className="merchant-container-logo">
            {profile?.merchant?.logo
              ? <img alt="logo" src={profile?.merchant?.logo} />
              : (
                <div className="merchant-container-logo-inner">
                  {initials}
                </div>
              )}
          </div>
          <div className="merchant-container-name">
            <div className="merchant-container-name-title">{name}</div>
            <div className="merchant-container-name-id">
              ID:
              {' '}
              {profile?.merchant?.payfiId}
            </div>
          </div>
        </div>
        <div className="side-bar-list-cover">
          <div className="sidebar-top">
            <ul>
              {
                TopList?.map((list, i) => (
                  <LinkComp
                    key={i}
                    disabled={list?.disabled}
                    Icon={list?.icon}
                    name={list?.name}
                    link={list?.link}
                    closeDropDownAndSideBar={closeDropDownAndSideBar}
                  />
                ))
              }
            </ul>

            <h2 className="your-bus">BUSINESS</h2>

            <ul className="">
              {
                BusinessList?.map((list, i) => (
                  <LinkComp
                    key={i}
                    disabled={list?.disabled}
                    Icon={list?.icon}
                    name={list?.name}
                    link={list?.link}
                    closeDropDownAndSideBar={closeDropDownAndSideBar}
                  />
                ))
              }
            </ul>
            <h2 className="your-bus ">PAYMENTS</h2>
            <ul>
              {
                PaymentList?.map((list, i) => (
                  <LinkComp
                    key={i}
                    disabled={list?.disabled}
                    Icon={list?.icon}
                    name={list?.name}
                    link={list?.link}
                    closeDropDownAndSideBar={closeDropDownAndSideBar}
                  />
                ))
              }
            </ul>

          </div>

          <div className="logout-and-settings">
            <hr />
            <ul className="">
              {
                LastList?.map((list, i) => (
                  <LinkComp
                    key={i}
                    disabled={list?.disabled}
                    Icon={list?.icon}
                    name={list?.name}
                    link={list?.link}
                    closeDropDownAndSideBar={closeDropDownAndSideBar}
                    shouldNotBeActive={list?.shouldNotBeActive}
                    onClick={list?.onClick}
                  />
                ))
              }

            </ul>
          </div>
        </div>

      </div>
    </div>
  );
};

export default MerchantSideBar;
