// import React, { useState } from 'react';
import './SelectSearch.scss';
import SelectSearchComp from 'react-select';
import SelectSearchDropSvg from './SelectSearchDropSvg';

export const getValue = (options, checkValue) => {
  if (!options || !checkValue) {
    return null;
  }
  const obj = options.filter((option) => option.value === checkValue);
  return obj;
};

export const getOptions = (arr) => {
  if (!arr) return [];

  const options = arr.map(({ name, id }) => ({ label: name, value: id }));
  return options;
};

const SelectSearch = ({
  options,
  label,
  name,
  onChange,
  classes,
  value,
  placeholder,
  hideLabel,
  required,
  disabled,
  inputWrapperClass,
}: any) => {
  // const [isClicked, setIsClicked] = useState(false);
  const handleChange = (e: any) => {
    onChange({ target: { value: e.value, name } });
  };
  const valueResult = getValue(options, value);
  // zIndex: isClicked ? '' : '2'
  return (
    <div
      style={{ opacity: disabled ? '1' : '1', height: '50px' }}
      className={`${valueResult ? 'select-search-with-value' : 'select-wrap'} select-custom-styles`}
    // onMouseEnter={() => setIsClicked(true)}
    >
      {!hideLabel && (
        <span className="label-name">
          {label}
        </span>
      )}
      <div className={`select-search ${classes || ''} `}>
        <span>{placeholder}</span>
        <div className={`select-input-wrapper ${inputWrapperClass || ''} `}>

          <div className="drop-down-select-search">
            <SelectSearchDropSvg disabled={disabled} />
          </div>
          <SelectSearchComp
            placeholder={placeholder}
            onChange={handleChange}
            className={`basic-single ${placeholder && valueResult && 'input_select_with_value'}`}
            value={valueResult}
            options={options}
            required={required}
            isDisabled={disabled}

          />

        </div>

      </div>
    </div>
  );
};

export default SelectSearch;
