import React from 'react';

const HomeIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M13.4351 5.06082C13.9098 5.44259 14.1884 6.01693 14.1945 6.62604L14.1887 11.942C14.1887 13.3859 13.0182 14.5565 11.5742 14.5565H10.4148C9.71591 14.5565 9.14844 13.9916 9.14525 13.2927V10.8927C9.14525 10.707 8.99472 10.5565 8.80902 10.5565H7.06989C6.9797 10.5565 6.89329 10.5927 6.83007 10.657C6.76684 10.7213 6.7321 10.8083 6.73366 10.8985V11.8144C6.73366 12.1026 6.50007 12.3362 6.21192 12.3362C5.92377 12.3362 5.69018 12.1026 5.69018 11.8144V10.8985C5.69018 10.531 5.83615 10.1786 6.09599 9.9188C6.35582 9.65897 6.70823 9.51299 7.07569 9.51299H8.81482C9.57775 9.51619 10.1945 10.1356 10.1945 10.8985V13.2927C10.1945 13.4144 10.2932 13.513 10.4148 13.513H11.6032C12.4677 13.513 13.1684 12.8122 13.1684 11.9478V6.63763C13.1642 6.34346 13.028 6.06677 12.7974 5.88401L8.79163 2.6898C8.2865 2.27394 7.55763 2.27394 7.0525 2.6898L5.76554 3.65212C5.52702 3.82341 5.1948 3.76891 5.02351 3.53038C4.85223 3.29186 4.90673 2.95964 5.14525 2.78836L6.41482 1.86662C7.29509 1.15904 8.54904 1.15904 9.42931 1.86662L13.4351 5.06082ZM4.28149 13.5072H6.94815V13.5188C7.2363 13.5188 7.46989 13.7524 7.46989 14.0405C7.46989 14.3287 7.2363 14.5623 6.94815 14.5623H4.28149C2.83886 14.5591 1.67018 13.3904 1.66699 11.9478V6.63763C1.67622 6.02401 1.96424 5.44796 2.4496 5.07241L2.93076 4.71299C3.15734 4.60618 3.42785 4.67547 3.57518 4.87805C3.72252 5.08063 3.70509 5.35933 3.53366 5.54198L3.06409 5.8898C2.8416 6.07465 2.71217 6.34837 2.71047 6.63763V11.942C2.71682 12.806 3.41741 13.504 4.28149 13.5072Z" fill="#5941A9" />
  </svg>

);

export default HomeIcon;
