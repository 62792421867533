import React from 'react';

const Prev = ({ disabled }) => (
  <div className="prev">
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillOpacity={disabled ? '0.32' : '0.9'} fillRule="evenodd" clipRule="evenodd" d="M12.7816 4.86673C12.9937 5.07952 12.9952 5.42447 12.7801 5.63726L9.60018 8.79052L9.544 8.83911C9.32906 9.00098 9.02183 8.98406 8.8267 8.78835C8.721 8.68232 8.66705 8.54288 8.66705 8.40417C8.66705 8.26474 8.721 8.12458 8.82816 8.01855L12.0081 4.86456L12.0643 4.81597C12.2792 4.6541 12.5864 4.67102 12.7816 4.86673ZM12.8291 14.4195C12.9932 14.6331 12.9757 14.9392 12.7813 15.1342C12.5684 15.347 12.2221 15.3478 12.0078 15.135L7.2197 10.3855L7.17056 10.3298C7.09606 10.2329 7.05859 10.1166 7.05859 9.99984C7.05859 9.86113 7.11254 9.72169 7.21825 9.61567C7.43112 9.40216 7.77739 9.40143 7.99172 9.61421L12.7798 14.3637L12.8291 14.4195Z" fill="#523B9F" />
      <rect x="0.5" y="0.5" width="19" height="19" rx="3.5" stroke="#F2F4F7" />
    </svg>
  </div>
);

export default Prev;
