import React from 'react';

interface IListIcon{
  color?:string;
  onClick?:any;
  ref?:any;
}
const ListIcon = ({ color, onClick, ref }:IListIcon) => (
  <svg ref={ref} className="list-icon" width="11" height="17" viewBox="0 0 11 17" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ cursor: 'pointer' }} onClick={onClick}>
    <circle cx="5.5" cy="13.5" r="1.5" transform="rotate(-90 5.5 13.5)" fill={color || '#475467'} />
    <circle cx="5.5" cy="8.5" r="1.5" transform="rotate(-90 5.5 8.5)" fill={color || '#475467'} />
    <circle cx="5.5" cy="3.5" r="1.5" transform="rotate(-90 5.5 3.5)" fill={color || '#475467'} />
  </svg>

);
export default ListIcon;
