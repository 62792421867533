/* eslint-disable max-len */
import React, { useCallback, useEffect, useState } from 'react';
import ComingSoonOverlay from '../../components/ComingSoonOverlay';
import ComingsoonCont from '../../components/ComingSoonOverlay/ComingsoonModalCont';
import DashboardWrap from '../../components/DashboardWrap';
import { useGlobalState } from '../../contexts';
import CameraIcon from '../../svgs/CameraIcon';
import DemoIcon from '../../svgs/DemoIcon';
import ReadDocs from '../../svgs/ReadDocs';
import TourIcon from '../../svgs/TourIcon';
import './GetStarted.scss';
import GetStartedCard from './GetStartedCard';
import ProgressCard from './ProgressCard';

const GetStarted = () => {
  const { profile, step }:any = useGlobalState();
  const [percent, setPercent] = useState(0);
  const [showComingSoon, setshowComingSoon] = useState(false);
  const getPercentage = useCallback(() => {
    if (!step) return 0;
    const stepDone = step > 5 ? 5 : step;
    const percentage = Math.ceil((stepDone / 5) * 100);
    setPercent(percentage);
  }, [step]);

  useEffect(() => {
    getPercentage();
  }, [step]);
  const handleOpenModal = () => {
    setshowComingSoon(true);
  };
  const GetStartDetailsFirst = [{
    title: 'Upload Business Logo', subTitle: profile?.merchant?.logo ? 'Put an image on Payfi that helps you and your team expressly identify the business 🎉' : 'Put an image on Payfi that helps you and your team expressly identify the business 🎉', icon: <CameraIcon />, linkPage: profile?.merchant?.logo ? 'Update logo' : 'Upload a Picture', hasUpload: true,
  },
  {
    title: 'Try the Demo', subTitle: 'Are you a developer in a fintech or are you interested in understanding how our APIs work?', icon: <DemoIcon />, linkPage: 'Try Demo', hasModal: true,
  },
  ];

  const GetStartDetailsSecond = [{
    title: 'Take a tour',
    subTitle: 'We’ve created a complete walk through Payfi click the button below to start 🎉',
    icon: <TourIcon />,
    linkPage: 'Start Tour',
    hasModal: true,
  },
  {
    title: 'View Documentation', subTitle: 'Are you a developer in a fintech or are you interested in understanding how our APIs work?', icon: <ReadDocs />, linkPage: 'Read the Docs', link: 'https://payfi.readme.io/docs',
  },
  ];

  return (
    <DashboardWrap className="dashboard-wrap-children-without-padding">
      <div className="get-started-cover">
        <div className="get-started-cover-title">
          Welcome to Payfi,
          {' '}
          {profile?.firstName}
        </div>
        <div className="get-started-cover-subtitle">Hoping to help you get things rolling as quick as possible</div>
        <div className="get-started-cover-contents">
          {
            !profile?.merchant?.isApproved && <ProgressCard progress={percent} isApproved={profile?.merchant?.isApproved} documentSentForActivation={profile?.merchant?.documentSentForActivation} />
          }

          <div className="get-started-cover-contents-card-list row">
            {
              GetStartDetailsFirst?.map((list:any) => (
                <div className="col-md-6 col-12 get-started-cover-contents-card-list-col">
                  <GetStartedCard title={list?.title} subtitle={list?.subTitle} icon={list?.icon} linkPage={list?.linkPage} hasUpload={list?.hasUpload} handleModal={handleOpenModal} link={list?.link} hasModal={list?.hasModal} />
                </div>
              ))
            }
            <div className="explore-title-div">
              Explore, Integrate Payfi
            </div>
            {
              GetStartDetailsSecond?.map((list:any) => (
                <div className="col-md-6 col-12 get-started-cover-contents-card-list-col">
                  <GetStartedCard title={list?.title} subtitle={list?.subTitle} icon={list?.icon} linkPage={list?.linkPage} handleModal={handleOpenModal} hasModal={list?.hasModal} link={list?.link} />
                </div>
              ))
            }

          </div>
          {
            showComingSoon && (
            <ComingSoonOverlay onClick={() => setshowComingSoon(false)}>

              <ComingsoonCont onClick={() => setshowComingSoon(false)} />
            </ComingSoonOverlay>
            )
          }

        </div>

      </div>

    </DashboardWrap>

  );
};
export default GetStarted;
