import React from 'react';
import './LabelValueComp.scss';
import { getClassStyle } from '../../utils/tableHelpers';
import UseCustomResizer from '../CustomResizer';
import { capitalizeOnlyFirstLetter } from '../../utils/helpers';

type Props = {
  label: string;
  value: any;
  listStyle?: boolean;
  status?: string;
}

const LabelValueComp = ({
  label, value, listStyle, status,
}: Props) => {
  const { isMobile } = UseCustomResizer({ width: 600 });
  const checkIfValueIsArray = Array?.isArray(value);
  return (
    <div className={isMobile ? 'mobile-label-value-comp' : 'label-value-comp status-styles'}>
      {listStyle && <small className="bullet-style" />}
      {
        value && label ? (
          <div className="info-cover">
            <span>{capitalizeOnlyFirstLetter(label)}</span>
            <strong>
              <span>
                {' '}
                {
                  checkIfValueIsArray && value?.map((list, index:number) => {
                    const indexCount = index + 1;
                    return value?.length !== indexCount ? <strong>{`${list},`}</strong> : <strong>{list}</strong>;
                  })
                }

              </span>
            </strong>
            {
              !checkIfValueIsArray && <strong>{value}</strong>
            }

          </div>
        ) : ''
      }
      {/* <div className="info-cover">
        <span>{label}</span>
        <strong>{value}</strong>
      </div> */}

      {status && <span className={`status-span ${getClassStyle({ status }, 'status')}`}>{status}</span>}
    </div>
  );
};

export default LabelValueComp;
