import React from 'react';
import './LoanScheduleForMobile.scss';

interface ILoanScheduleForMobile{
  label?:string;
  value?:string;
  status?:any;
  key?:number;
}
const LoanScheduleForMobile = ({
  label, value, status, key,
}:ILoanScheduleForMobile) => (
  <div key={key} className="loan-schedule-for-mobile-cover">
    <div className="circle" />
    <div className="contents">
      <div>{label}</div>
      <div>{value}</div>
    </div>
    <div className={`${status} default`}>{status}</div>

  </div>
);
export default LoanScheduleForMobile;
