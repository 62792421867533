import { FormControl, InputLabel, makeStyles } from '@material-ui/core';
import { FunctionComponent } from 'react';
import Select from '@material-ui/core/Select';

import './select.scss';

export interface ISelectInput {
  label?: string;
  width?: number | string;
  options?: Array<{ value: any; text: string; isSelected?: boolean }>;
  attached?: boolean;
  disabled?: boolean;
  onChange?: (data: any) => void;
  name?: string;
  value?: string;
  customOnChange?: (data: { target: { value: any; name: any } }) => void;
  required?: boolean;
  marginBottom?: string;
  marginTop?:string;
  onBlur?: () => void;
}

const SelectInput: FunctionComponent<ISelectInput> = ({
  label,
  width,
  options,
  attached,
  onChange,
  disabled,
  name,
  value: valInp,
  customOnChange,
  required,
  marginBottom,
  marginTop,
  onBlur,
}) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      color: 'blue',
      border: 'solid 1px #E4E4E4',
      borderRadius: 5,
      borderTopLeftRadius: attached ? 0 : 5,
      borderBottomLeftRadius: attached ? 0 : 5,
      borderLeftWidth: attached ? 0 : 1,
      backgroundColor: '#FFFFFF',
    },
    formControl: {
      minWidth: 120,
      width: '100%',
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }));
  const classes = useStyles();

  const handleChange = (event: any) => {
    // const name = event.target.name;
    const { value } = event.target;
    if (onChange) onChange({ value });
    customOnChange?.({ target: { value, name } });
  };

  return (
    <div className="select d-flex flex-grow-1">
      <FormControl
        required={required}
        variant="filled"
        className={classes.formControl}
        style={{ marginBottom: marginBottom || '20px' }}
      >
        <InputLabel
          htmlFor={`filled-age-native-simple-${name}`}
          shrink={!!valInp}
          style={{
            fontFamily: 'Inter_Regular',
            color: '#A6AAB4',
            fontSize: '13px',
            borderLeft: 'none',
            paddingLeft: '16px',

          }}
        >
          {label}
        </InputLabel>
        <Select
          required={required}
          native
          onBlur={onBlur}
          style={{
            width: width || '27.3rem',
            margin: ' auto 0',
            color: '#A6AAB4',
            paddingLeft: '15px',
            fontSize: '12px',
            marginTop: marginTop || '5px',

          }}
          onChange={handleChange}
          name={name}
          value={valInp}
          className={classes.root}
          disabled={disabled}
          SelectDisplayProps={{
            color: 'red',
            style: {
              backgroundColor: 'red',
              fontFamily: 'Inter_Regular',
              fontSize: '13px',
            },
          }}
          inputProps={{
            name: label,
            id: `filled-age-native-simple-${name}`,
            style: {
              backgroundColor: 'transparent',
              fontFamily: 'Inter_Regular',
              fontSize: '13px',

            },
          }}
        >
          <option aria-label="None" value="" />
          {options ? (
            options?.map?.((option: any, index: number) => (
              <option key={index} value={option.value}>
                {option.text}
              </option>
            ))
          ) : (
            <>
              <option value={10}>Nigeria</option>
              <option value={20}>Twenty</option>
              <option value={30}>Thirty</option>
            </>
          )}
        </Select>
      </FormControl>
    </div>
  );
};
export default SelectInput;
