import React from 'react';

import ModalFormComp from '../../ModalFormComp';
import Buttons from '../../Buttons';

import './SuccessModal.scss';

interface ISuccessModal{
  closeModal?:any;
  Icon?: any;
  title: string;
  subTitle?: string;
}
const SuccessModal = ({
  Icon, title, subTitle, closeModal,
}:ISuccessModal) => (
  <ModalFormComp removeTitle modalType="success_confirm_modal" closeModal={closeModal} cancleDivWidth="100%">
    <div className="success_icon_wrapper">
      <Icon />
    </div>
    <div className="success_modal_title">{title}</div>
    <div className="success_modal_sub_title">
      {subTitle}
    </div>
    <div className="button-cover-div">
      <Buttons
        name="Okay"
        className="save-type"
        type="submit"
        btnStyle="btn-md"
        onClick={closeModal}
      />
    </div>
  </ModalFormComp>
);
export default SuccessModal;
