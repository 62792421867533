import React, { useMemo } from 'react';
import SelectSearch from '../../../components/SelectSearch';
import { getMonthPluralization } from '../../../utils/helpers';
import TextInput from '../../../components/input';
import StyledButton from '../../../components/StyledButton';
import AppDatePicker from '../../../components/DatePicker';
import useToast from '../../../customHooks/useToast';

type Props = {
  formValues: any;
  handleChange: any;
  closeModal: any;
  scheduleDate: any;
  setScheduleDate: any;
  merchantId: string;
  setMerchantDetails: any;
  loading: boolean;
  setShowNext: any;
  userName: string;
  merchants: any;
}

const MerchantAndLoanConfigForm = ({
  formValues,
  handleChange,
  closeModal,
  scheduleDate,
  setScheduleDate,
  merchantId,
  setMerchantDetails,
  loading,
  setShowNext,
  userName,
  merchants,
}: Props) => {
  const Toast = useToast();

  const merchantNamesAndIds = useMemo(() => merchants.map((merchant) => ({
    value: merchant.id,
    label: merchant.name,
  })).sort((a, b) => a.label.localeCompare(b.label)), [merchants]);

  const handleProceedToNextScreen = async () => {
    if (!formValues?.downPaymentPercentage
      || !formValues?.loanTenor || !scheduleDate || !merchantId) {
      Toast.error('all fields are required.');
      return;
    }
    if (Number(formValues?.downPaymentPercentage) < 0
    || Number(formValues?.downPaymentPercentage) > 90) {
      Toast.error('down-payment percentage should range from 0 to 90, inclusive.');
      return;
    }
    if (Number(formValues?.loanTenor) <= 0 || Number(formValues?.loanTenor) > 8) {
      Toast.error('loan tenor should range from 1 to 8, inclusive.');
      return;
    }
    setShowNext(true);
  };

  return (
    <div className="row">
      <TextInput disabled name="userName" value={userName} label="User Name" customOnChange={handleChange} width="100%" />

      <TextInput
        name="downPaymentPercentage"
        value={formValues?.downPaymentPercentage}
        label="Down-payment Percentage"
        customOnChange={handleChange}
        width="100%"
        type="percent"
      />
      <div style={{ marginBottom: '20px' }}>
        <AppDatePicker
          value={scheduleDate}
          placeholder="Date Disbursed"
          onChange={(e:any) => setScheduleDate(e)}
          hasIcon
          className={scheduleDate ? 'has-date-field' : 'empty-date-field'}
          dateFormat="yyyy-MM-dd"
        />
      </div>
      <TextInput
        type="number-custom"
        numberComponentProps={{
          suffix: ` ${getMonthPluralization(formValues?.loanTenor)}`,
        }}
        name="loanTenor"
        value={formValues?.loanTenor}
        label="Loan Tenor"
        customOnChange={handleChange}
        width="100%"
      />
      <div style={{ marginBottom: '82px' }}>
        <SelectSearch
          label=""
          options={merchantNamesAndIds}
          placeholder={loading ? 'Merchant Loading...' : 'Merchant'}
          name="Merchant"
          value={merchantId}
          classes={false}
          hideLabel
          onChange={(e) => setMerchantDetails(e?.target?.value)}
          disabled={loading || !merchantNamesAndIds?.length}
        />
      </div>
      <div className="create_loan_btn_cover">
        <div>
          <StyledButton name="Cancel" width="60px" backgroundColor="white" color="#5941A9" border="1px solid #E6E7EC" fontSize="12px" borderRadius="4px" height="35px" onClick={closeModal} />
        </div>
        <div>
          <StyledButton
            name="Proceed"
            width="90px"
            backgroundColor="#5941A9"
            color="white"
            border="none"
            fontSize="12px"
            borderRadius="4px"
            height="35px"
            onClick={handleProceedToNextScreen}
            disabled={false}
          />
        </div>
      </div>
    </div>
  );
};

export default MerchantAndLoanConfigForm;
