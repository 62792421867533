import { FormControl } from '@material-ui/core';
import { FunctionComponent } from 'react';

import './BusinessContact.scss';
import BusinessOwnerForm from './BusinessOwnerForm';

export interface IBusinessContact {
  label?: string;
}

const BusinessContact: FunctionComponent<IBusinessContact> = () => (
  <FormControl className="business-owner-form">
    <div className="business-owner-form-title">
      Business Owner Details
    </div>
    <div className="business-owner-form-subtitle">
      Please tell us about your business
    </div>
    <div className="business-owner-form-cover">
      <BusinessOwnerForm />
    </div>
  </FormControl>
);

export default BusinessContact;
