import React from 'react';

const EmptySettlementIcon = () => (
  <svg width="172" height="126" viewBox="0 0 172 126" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M85.577 123.154C119.585 123.154 147.154 95.5851 147.154 61.577C147.154 27.569 119.585 0 85.577 0C51.569 0 24 27.569 24 61.577C24 95.5851 51.569 123.154 85.577 123.154Z" fill="url(#paint0_linear_655_34901)" />
    <g filter="url(#filter0_d_655_34901)">
      <path d="M46.8539 29H161.745C162.873 29 163.956 29.4483 164.754 30.2463C165.552 31.0443 166 32.1267 166 33.2552V54.5313C166 55.6599 165.552 56.7422 164.754 57.5402C163.956 58.3383 162.873 58.7866 161.745 58.7866H46.8539C45.7253 58.7866 44.643 58.3383 43.845 57.5402C43.0469 56.7422 42.5986 55.6599 42.5986 54.5313V33.2552C42.5986 32.1267 43.0469 31.0443 43.845 30.2463C44.643 29.4483 45.7253 29 46.8539 29V29Z" fill="white" />
    </g>
    <path d="M109.829 35.793H87.7034C86.2934 35.793 85.1504 36.936 85.1504 38.346C85.1504 39.7559 86.2934 40.899 87.7034 40.899H109.829C111.239 40.899 112.382 39.7559 112.382 38.346C112.382 36.936 111.239 35.793 109.829 35.793Z" fill="#E2DAFF" />
    <path d="M125.147 46.8555H87.7034C86.2934 46.8555 85.1504 47.9985 85.1504 49.4085C85.1504 50.8185 86.2934 51.9615 87.7034 51.9615H125.147C126.557 51.9615 127.7 50.8185 127.7 49.4085C127.7 47.9985 126.557 46.8555 125.147 46.8555Z" fill="#F6F5FF" />
    <path d="M68.5552 51.9717C73.0201 51.9717 76.6397 48.3522 76.6397 43.8872C76.6397 39.4223 73.0201 35.8027 68.5552 35.8027C64.0903 35.8027 60.4707 39.4223 60.4707 43.8872C60.4707 48.3522 64.0903 51.9717 68.5552 51.9717Z" fill="#523B9F" />
    <path fillRule="evenodd" clipRule="evenodd" d="M67.1595 39.5781H70.4698C71.8484 39.5781 72.6742 40.4154 72.6779 41.8133V45.9238C72.6779 47.3218 71.8559 48.159 70.4735 48.159H69.248C69.0909 48.1415 68.9719 48.0068 68.9719 47.8465C68.9719 47.6862 69.0909 47.5515 69.248 47.5339H70.4698C71.5234 47.5339 72.0539 46.9922 72.0539 45.9238V41.8133C72.0539 40.745 71.5234 40.2032 70.4698 40.2032H67.1595C66.1058 40.2032 65.5716 40.745 65.5716 41.8133V45.9238C65.5716 46.9922 66.1058 47.5339 67.1595 47.5339C67.3166 47.5515 67.4356 47.6862 67.4356 47.8465C67.4356 48.0068 67.3166 48.1415 67.1595 48.159C65.7808 48.159 64.9551 47.3218 64.9551 45.9238V41.8133C64.9551 40.4116 65.7808 39.5781 67.1595 39.5781ZM67.2678 42.3664H68.4447C68.6019 42.3489 68.7208 42.2142 68.7208 42.0539C68.7208 41.8936 68.6019 41.7589 68.4447 41.7413H67.2678C67.1107 41.7589 66.9917 41.8936 66.9917 42.0539C66.9917 42.2142 67.1107 42.3489 67.2678 42.3664ZM70.3577 44.1811H67.2678H67.2641C67.1069 44.1636 66.988 44.0289 66.988 43.8686C66.988 43.7083 67.1069 43.5736 67.2641 43.556H70.3577C70.4759 43.5428 70.5912 43.5993 70.6544 43.7015C70.7176 43.8037 70.7176 43.9335 70.6544 44.0357C70.5912 44.1378 70.4759 44.1943 70.3577 44.1811ZM70.3577 45.9958H67.2678H67.2641C67.0949 45.9958 66.9577 45.8567 66.9577 45.6852C66.9577 45.5136 67.0949 45.3745 67.2641 45.3745H70.3577C70.5269 45.3745 70.6641 45.5136 70.6641 45.6852C70.6641 45.8567 70.5269 45.9958 70.3577 45.9958Z" fill="white" />
    <g opacity="0.7">
      <g filter="url(#filter1_d_655_34901)">
        <path d="M10.2552 65.584H125.146C126.275 65.584 127.357 66.0323 128.155 66.8303C128.953 67.6283 129.401 68.7107 129.401 69.8392V91.1153C129.401 92.2439 128.953 93.3262 128.155 94.1242C127.357 94.9222 126.275 95.3706 125.146 95.3706H10.2552C9.12667 95.3706 8.04434 94.9222 7.24633 94.1242C6.44832 93.3262 6 92.2439 6 91.1153V69.8392C6 68.7107 6.44832 67.6283 7.24633 66.8303C8.04434 66.0323 9.12667 65.584 10.2552 65.584V65.584Z" fill="white" />
      </g>
      <path d="M73.2327 72.3848H51.1067C49.6967 72.3848 48.5537 73.5278 48.5537 74.9378C48.5537 76.3477 49.6967 77.4908 51.1067 77.4908H73.2327C74.6427 77.4908 75.7857 76.3477 75.7857 74.9378C75.7857 73.5278 74.6427 72.3848 73.2327 72.3848Z" fill="#E2DAFF" />
      <path d="M88.5507 83.4473H51.1067C49.6967 83.4473 48.5537 84.5903 48.5537 86.0003C48.5537 87.4102 49.6967 88.5533 51.1067 88.5533H88.5507C89.9607 88.5533 91.1037 87.4102 91.1037 86.0003C91.1037 84.5903 89.9607 83.4473 88.5507 83.4473Z" fill="#F6F5FF" />
      <path d="M20.8941 88.544C25.359 88.544 28.9785 84.9244 28.9785 80.4595C28.9785 75.9945 25.359 72.375 20.8941 72.375C16.4291 72.375 12.8096 75.9945 12.8096 80.4595C12.8096 84.9244 16.4291 88.544 20.8941 88.544Z" fill="#523B9F" />
      <path fillRule="evenodd" clipRule="evenodd" d="M18.7581 76.6465H22.0684C23.4471 76.6465 24.2728 77.4837 24.2765 78.8817V82.9922C24.2765 84.3901 23.4546 85.2274 22.0721 85.2274H20.8467C20.6895 85.2098 20.5705 85.0752 20.5705 84.9148C20.5705 84.7545 20.6895 84.6199 20.8467 84.6023H22.0684C23.122 84.6023 23.6526 84.0605 23.6526 82.9922V78.8817C23.6526 77.8133 23.122 77.2716 22.0684 77.2716H18.7581C17.7045 77.2716 17.1702 77.8133 17.1702 78.8817V82.9922C17.1702 84.0605 17.7045 84.6023 18.7581 84.6023C18.9153 84.6199 19.0342 84.7545 19.0342 84.9148C19.0342 85.0752 18.9153 85.2098 18.7581 85.2274C17.3794 85.2274 16.5537 84.3901 16.5537 82.9922V78.8817C16.5537 77.4799 17.3794 76.6465 18.7581 76.6465ZM18.8664 79.4348H20.0434C20.2005 79.4172 20.3195 79.2826 20.3195 79.1223C20.3195 78.9619 20.2005 78.8273 20.0434 78.8097H18.8664C18.7093 78.8273 18.5903 78.9619 18.5903 79.1223C18.5903 79.2826 18.7093 79.4172 18.8664 79.4348ZM21.9563 81.2495H18.8664H18.8627C18.7055 81.2319 18.5866 81.0973 18.5866 80.9369C18.5866 80.7766 18.7055 80.642 18.8627 80.6244H21.9563C22.0745 80.6112 22.1898 80.6677 22.253 80.7699C22.3162 80.872 22.3162 81.0018 22.253 81.104C22.1898 81.2062 22.0745 81.2627 21.9563 81.2495ZM21.9563 83.0642H18.8664H18.8627C18.6935 83.0642 18.5563 82.9251 18.5563 82.7535C18.5563 82.5819 18.6935 82.4429 18.8627 82.4429H21.9563C22.1255 82.4429 22.2627 82.5819 22.2627 82.7535C22.2627 82.9251 22.1255 83.0642 21.9563 83.0642Z" fill="white" />
    </g>
    <defs>
      <filter id="filter0_d_655_34901" x="36.5986" y="26" width="135.401" height="41.7871" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="3" />
        <feGaussianBlur stdDeviation="3" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_655_34901" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_655_34901" result="shape" />
      </filter>
      <filter id="filter1_d_655_34901" x="0" y="62.584" width="135.401" height="41.7871" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="3" />
        <feGaussianBlur stdDeviation="3" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_655_34901" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_655_34901" result="shape" />
      </filter>
      <linearGradient id="paint0_linear_655_34901" x1="85.577" y1="0" x2="85.577" y2="123.154" gradientUnits="userSpaceOnUse">
        <stop stopColor="#DCD8EC" stopOpacity="0.12" />
        <stop offset="1" stopColor="#DFE0EB" stopOpacity="0.4" />
      </linearGradient>
    </defs>
  </svg>

);

export default EmptySettlementIcon;
