import { gql } from '@apollo/client';

export const GET_PROFILE = gql`
  query getAdminUser {
    getAdminUser {
      id
      firstName
      lastName
      email
      imageUrl
      phone
      role {
        name
        permissions {
          id
          name
        }
      }
    }
  }
`;

export const GET_ALL_TRANSACTIONS = gql`
  query getAllTransactionsAdmin(
    $limit: Int
    $offset: Int
    $search: String
    $filters: FiltersType
    $statusFilter: String
    $paymentMethod: String
  ) {
    getAllTransactionsAdmin(
      limit: $limit,
      offset: $offset,
      search: $search,
      filters: $filters,
      statusFilter: $statusFilter,
      paymentMethod: $paymentMethod
    ) {
      count
      transactions {
        id
        status
        narration
        type
        narration
        description
        amount
        createdAt
        txRef
        paidAt
        paymentMethod
        paymentRef
        settlementStatus
        channel
        fees
        message
        user {
          firstName
          lastName
          email
        }
        merchant {
          id
          name
          email
        }
        order {
          orderNumber
        }
        loan {
          id
          loanSchedules {
            dueDate
            amountDue
          }
        }
      }
    }
  }
`;

export const GET_ALL_REWARD_HISTORY = gql`
query GetRewardHistoryAdmin($limit: Int, $offset: Int, $filters: RewardFilterInput, $search: String, $typeFilter: String,$userId: ID) {
  getRewardHistoryAdmin(limit: $limit, offset: $offset, filters: $filters, search: $search, typeFilter: $typeFilter, userId: $userId) {
    data {
      count
      rows {
        id
        userId
        rewardId
        user {
          firstName
          lastName
          email
        }
        type
        point
        rewardType
        createdAt
        meta
        rewardType
      }
    }
  }
}
`;

export const GET_ALL_REWARDS = gql`
query GetRewardsAdmin($limit: Int, $offset: Int, $filters: RewardFilterInput, $statusFilter: String, $search: String, $sortOrder: String, $sortField: String) {
  getRewardsAdmin(limit: $limit, offset: $offset, filters: $filters, statusFilter: $statusFilter, search: $search, sortOrder: $sortOrder, sortField: $sortField) {
    status
    data {
      count
      rows {
        id
        userId
        level
        point
        pointIssued
        pointRedeemed
        dateEnrolled
        expiryDate
        status
        createdAt
        updatedAt
        user {
          firstName
          lastName
          email
        }
      }
    }
  }
}
`;

export const GET_DISBURSEMENT_PARTNER = gql`
query GetDisbursementPartnerActivities($limit: Int, $offset: Int, $search: String, $partnerFIlter: String, $filters: FiltersType, $sdkActivityId: String) {
  getDisbursementPartnerActivities(limit: $limit, offset: $offset, search: $search, partnerFIlter: $partnerFIlter, filters: $filters, sdkActivityId: $sdkActivityId) {
    count
    disbursementPartnerActivities {
      createdAt
      customerEmail
      loanAmount
      transactionReference
      transactionDate
      status
      route
      id
      description
      apiResponse
      apiRequest
      sdkActivityId
    }
  }
}
`;

export const GET_DISBURSEMENT_PARTNER_DETAILS = gql`
query GetDIsbursementPartnerActivityById($getDIsbursementPartnerActivityByIdId: String) {
  getDIsbursementPartnerActivityById(id: $getDIsbursementPartnerActivityByIdId) {
    id
    transactionReference
    customerEmail
    loanAmount
    transactionDate
    route
    apiResponse
    apiRequest
    createdAt
    updatedAt
    query
    status
    description
    sdkActivityId
  }
}
`;

export const GET_AUDIT_LOGS = gql`
  query getAuditTrails($offset: Int, $limit: Int, $filters: FiltersType, $search: String) {
    getAuditTrails(limit: $limit, offset: $offset, filters: $filters, search: $search) {
      count
      auditTrails {
        id
        tags
        requestId
        adminActor
        description
        actor
        action
        body
        response
        device
        ip
        url
        method
        createdAt
        updatedAt
        reason
         admin {
          email
          firstName
          lastName
          role {
            name
          }
        }
        topic
      }
    }
  }
`;

export const GET_CATEGORIES = gql`
 query getWebsiteCategories($filter: String){
  getWebsiteCategories(filter: $filter) {
    id
    name
    parentId
    description
    banner
    thumbnail
    order
    status
    createdAt
    updatedAt
  }
}
`;

export const GET_ALL_MERCHANTS_NEW = gql`
  query getAllMerchants(
    $offset: Int
    $limit: Int
    $filters: FiltersType
    $statusFilter: String
    $search: String
  ) {
    getAllMerchants(limit: $limit, offset: $offset, filters: $filters, statusFilter: $statusFilter, search: $search) {
      merchants {
        id
        logo
        payfiId
        name
        email
        status
        createdAt
        lastActivatedAt
        lastDeactivatedAt
        approvedAt
        declinedAt
        isApproved
        phone
        category {
          id
          name
        }
      }
      count
    }
  }
`;
export const GET_ALL_ADMIN_SETTLEMENT = gql`
  query getAdminSettlements(
    $offset: Int
    $limit: Int
    $filters: FiltersType
    $statusFilter: String
    $search: String
  ) {
    getAdminSettlements(limit: $limit, offset: $offset, filters: $filters, statusFilter: $statusFilter, search: $search) {
      settlements {
        totalSettled
        totalAmount
        merchant {
          logo
          name
        }
        batchNumber
        id
        status
        settlementReference
        createdAt
        totalFees
        settledOn
        totalInterest
        vendorFees
      }
      count
    }
  }
`;
export const GET_MERCHANTS_SETTLEMENT = gql`
query getMerchantSettlements($id: ID!  $offset: Int
  $limit: Int) {
  getMerchantSettlements (id: $id, offset: $offset, limit: $limit) {
    count
    settlements {
      status
      vendorFees
      settledOn
      totalInterest
      settlementReference
      totalFees
      totalSettled
      totalAmount
    }
  }
}
`;

export const GET_MERCHANTS_COMMENT = gql`
query getMerchantComments($id: ID!  $offset: Int
  $limit: Int, $search: String, $filters: FiltersType) {
    getMerchantComments (id: $id, offset: $offset, limit: $limit, search: $search, filters: $filters) {
    count
    data {
      id
      comment
      merchantId
      action
      date: createdAt
      admin {
        firstName
        lastName
        email
      }

    }
  }
}
`;

export const GET_USERS_COMMENT = gql`
query CComments($id: ID!  $offset: Int
  $limit: Int, $search: String, $filters: FiltersType) {
    getUserComments (id: $id, offset: $offset, limit: $limit, search: $search, filters: $filters) {
    count
    data {
      admin {
        firstName
        lastName
        email
      }
      comment
      action
      id
      adminId
      createdAt
    }
  }
}
`;

export const GET_SDK_ACTIVITIES = gql`
query getSdkActivitiesAdmin($userId: String, $limit: Int, $offset: Int, $search: String, $filters: FiltersType){
  getSdkActivitiesAdmin(userId: $userId, limit: $limit, offset: $offset, search: $search, filters: $filters) {
    status
    message,
    count
    activities {
      id
      amount
      transactionId
      callbackUrl
      isOtpVerified
      isPinVerified
      merchantId
      reference
      merchant {
        name
        logo
      }
      partner {
        name
      }
      sdkorderItems {
        orderName
      }
      vendorMerchantId
      status
      tenor
      createdAt
      updatedAt
      transactionId
      tenor
      partnerId
      payfiId
      disbursementStatus
      disbursementMessage
      retryCount
      channel
      sessionMeta {
        message
        sessionId
        statusCode
        highestOffer
        accountNumber
        statusCode
        reference
        isOtpVerified
        offers {
          tenor
          totalRepaymentAmount
          loanAmount
          installment
          nextDueDate
          requestedAmount
          repaymentSchedule {
            dueDate
            amountDue
            moneyPayMonth
            installmentIndex
          }
        }
      }
      userActivity {
        id
        action
        screen
      }
      user {
        firstName
        lastName
        email
        id
        phone
      }
    }
  }
}
`;

export const GET_SDK_ACTIVITY_BY_ID = gql`
query getSdkActivityByIdAdmin($id: ID!){
  getSdkActivityByIdAdmin(id: $id) {
    status
    message,
    activity {
      id
      amount
      transactionId
      callbackUrl
      isOtpVerified
      isPinVerified
      merchantId
      reference
      merchant {
        name
        logo
      }
      partner {
        name
      }
      sdkorderItems {
        orderName
        amount
      }
      vendorMerchantId
      status
      tenor
      createdAt
      updatedAt
      transactionId
      tenor
      partnerId
      payfiId
      disbursementStatus
      disbursementMessage
      retryCount
      channel
      sessionMeta {
        message
        sessionId
        statusCode
        highestOffer
        accountNumber
        statusCode
        reference
        isOtpVerified
        offers {
          tenor
          totalRepaymentAmount
          loanAmount
          installment
          nextDueDate
          requestedAmount
          repaymentSchedule {
            dueDate
            amountDue
            moneyPayMonth
            installmentIndex
          }
        }
      }
      userActivity {
        id
        type
        action
        screen
        createdAt
      }
      user {
        firstName
        lastName
        email
        id
        phone
        imageUrl
        promise
        status
        onboardingChannel
      }
      disbursementPartnerActivities {
        createdAt
        customerEmail
        loanAmount
        transactionReference
        transactionDate
        status
        route
        id
        description
        apiResponse
        apiRequest
        sdkActivityId
      }
    }
  }
}
`;

export const GET_ALL_SDK_ACTIVITIES = gql`
query GetUserActivities($limit: Int, $offset: Int, $search: String, $filters: FiltersType) {
  getUserActivities(limit: $limit, offset: $offset, search: $search, filters: $filters) {
    count
    rows {
      id
      partner {
        name
        shortDesc
        status
      }
      user {
        firstName
        lastName
        email
      }
      screen
      sdkActivity {
        id
        createdAt
        merchant {
          logo
          name
        }
        amount
        sdkorderItems {
          orderName
        }
        user {
          email
          lastName
          firstName
          stage
          onBoardingStage
        }
        partner {
          name
        }
        channel
        status
        disbursementMessage
        narration
        reference
      }
    }
  }
}
`;

export const GET_PUSH_NOTIFICATION = gql`
query GetNotificationRequests($limit: Int, $offset: Int, $search: String, $filters: FiltersType) {
  getNotificationRequests(limit: $limit, offset: $offset, search: $search, filters: $filters) {
    count
    rows {
      id
      targetUser {
        name
        id
      }
      type
      subject
      message
      status
      requestedByAdmin {
        firstName
        lastName
      }
      dateCompleted
      scheduleTime
      createdAt
    }
  }
}
`;

export const GET_USER_ELIGIBILITY = gql`
query GetEligibilityRequest($filters: UserEligibilityFiltersType, $offset: Int, $limit: Int, $search: String) {
  getEligibilityRequest(filters: $filters, offset: $offset, limit: $limit, search: $search) {
    count
    rows {
      createdAt
      dateCompleted
      dateRequested
      id
      provider
      reference
      requestedAmount
      status
      requestedByAdmin {
        firstName
        lastName
        email
      }
    }
  }
}
`;

export const GET_USER_ELIGIBILITY_BY_ID = gql`
query User($limit: Int, $offset: Int, $filters: FiltersType, $search: String, $getEligibilityRequestByIdId: String) {
  getEligibilityRequestById(limit: $limit, offset: $offset, filters: $filters, search: $search, id: $getEligibilityRequestByIdId) {
    rows {
      reference
      provider
      createdAt
      requestedAmount
      eligibleAmount
      status
      userId
      User {
        email
        firstName
        lastName
      }
    }
    count
  }

}
`;

export const GET_GROUP_USERS = gql`
query GetUserGroups($filters: UserGroupFilter) {
  getUserGroups(filters: $filters) {
    name
    id
  }
}
`;

export const GET_MERCHANTS_ORDER = gql`
query getMerchantOrdersById($id: ID!  $offset: Int
  $limit: Int, $search: String, $filters: FiltersType) {
    getMerchantOrdersById (id: $id, offset: $offset, limit: $limit, search: $search, filters: $filters) {
      count
      rows {
        orderNumber
        amount
        createdAt
        status {
          name
        }
        user {
          firstName
          lastName
          email
        }
        loan {
          loanChannel
     }
    }
  }
}
`;

export const GET_ALL_MERCHANTS = gql`
  query merchantsWithFilter(
    $offset: Int
    $limit: Int
    $filter: MerchantFilter
  ) {
    merchantsWithFilter(limit: $limit, offset: $offset, filter: $filter) {
      merchants {
        name
        email
        id
        phone
        isApproved
        createdAt
        logo
        name
        contactEmail
        contactPhone
        isApproved
        payfiId
        createdAt
        documents {
          status
          id
        }
      }
      count
    }
  }
`;

export const GET_ALL_SETTLEMENTS = gql`
  query getAllSettlements($offset: Int, $limit: Int, $merchantId: String, $filter: String) {
    getAllSettlements(offset: $offset, limit: $limit, merchantId: $merchantId, filter: $filter) {
      count
      settlements {
        id
        status
        merchantId
        batchNumber
        totalAmount
        totalSettled
        totalFees
        reason
        totalInterest
        vendorFees
        settlementReference
        date
        createdAt
        merchant {
          name
          bank
          email
          bankDetails {
            accountName
            accountNumber
            bank {
              name
            }
          }
      }
      }
    }
  }
`;

export const GET_DISBUSEMENT_PARTNERS = gql`
  query getDisbursementPartners($offset: Int, $limit: Int){
  getDisbursementPartners(offset: $offset, limit: $limit) {
  count
    disbursementPartners {
          id
    name
    isApproved
    email
    logo
    twitter
    facebook
    instagram
    country
    maxApprovalPeriod
    minApprovalPeriod
    requestPath
    statusPath
    status
    website
    shortDesc
    baseDomain
    }
  }
}
`;

export const GET_PAYFI_PRODUCTS = gql`
  query getPayfiProducts($offset: Int, $limit: Int){
    getPayfiProducts(offset: $offset, limit: $limit) {
      count
      payfiProducts {
        id
        name
        disbursementPartnerId
        status
      }
    }
}
`;

export const GET_PENDING_SETTLEMENTS = gql`
query getPendingSettlement($offset: Int, $limit: Int, $filter: String) {
  getPendingSettlement(offset: $offset, limit: $limit, filter: $filter) {
    count
    settlements {
      id
      status
      merchantId
      batchNumber
      totalAmount
      totalSettled
      totalFees
      reason
      settlementReference
      date
      createdAt
      totalInterest
      vendorFees
      merchant {
        name
        bank
        email
        bankDetails {
          accountName
          accountNumber
          bank {
            name
          }
        }
      }
    }
  }
}
`;

export const GET_ALL_LOANS = gql`
  query loans($offset: Int, $limit: Int, $filter: LoanFilter) {
    loans(offset: $offset, limit: $limit, filter: $filter) {
      count
      loans {
        payfiId
        tenor
        id
        amount
        paymentChannel
        loanStatus {
          name
        }
        user {
          email
          firstName
          lastName
        }
        createdAt
        order {
          amount
        }
      }
    }
  }
`;

export const GET_ALL_ADMIN_LOANS = gql`
  query getAllLoans ($offset: Int, $limit: Int, $filters: FiltersType, $statusFilter: String, $search: String) {
    getAllLoans(offset: $offset, limit: $limit, filters: $filters, statusFilter: $statusFilter, search: $search) {
      count
      loans {
        payfiId
        tenor
        loanChannel
        outstandingAmount
        dueDate
        id
        amount
        paymentChannel
        interest
        nextRepaymentDate
        amountPaid
        dateOfRefund
        loanStatus {
          name
        }
        user {
          email
          firstName
          lastName
        }
        merchant {
          name
        }
        createdAt
        order {
          amount
          items
          orderItems {
            amount
            orderName
          }
        }
      }
    }
  }
`;
export const GET_ADMIN_CUSTOMER_BANK_STATEMENT = gql`
  query BankStatements ($offset: Int, $limit: Int, $filter: String, $userId: ID!) {
    bankStatements(offset: $offset, limit: $limit, filter: $filter, userId: $userId) {
      count
      reference
      income
      incomeWeight
      incomeType
      payDay
      statements {
        id
        type
        date
        narration
        amount
      }
    }
  }
`;

export const GET_ALL_CUSTOMERS = gql`
  query users($offset: Int, $limit: Int, $filters: FiltersType, $search: String, $statusFilter: String) {
    users(limit: $limit, offset: $offset, filters: $filters, statusFilter: $statusFilter, search: $search) {
      users {
        id
        firstName
        lastName
        email
        payfiId
        phone
        createdAt
        status
        bvn
        imageUrl
        payfiProduct {
          name
        }
        priorityUser {
          userId
        }
        lastLoginAt
        onboardingChannel
        onboardingRoute
        onboardingLevel
        onBoardingStage
        stage
        approvedAt
      }
      count
    }
  }
`;

export const GET_ADMIN_CUSTOMER_TRANSACTIONS = gql`
query getAllTransactionsAdmin($search: String, $limit: Int, $offset: Int, $filters: FiltersType, $statusFilter: String, $userId: String, $paymentMethod: String) {
  getAllTransactionsAdmin  (userId: $userId, offset: $offset, limit: $limit, search: $search, filters: $filters, statusFilter: $statusFilter,paymentMethod:$paymentMethod) {
    count
    transactions {
      id
      txRef
      description
      narration
      type
      message
      paymentMethod
      amount
      currency
      fees
      createdAt
      status
      merchant {
        name
      }
      channel
      order {
        orderNumber
        orderItems {
          orderName
        }
      }

    }
  }
}
`;

export const GET_ADMIN_CUSTOMER_DETAILS_LOAN = gql`
query getAllLoans($search: String, $limit: Int, $offset: Int, $filters: FiltersType, $statusFilter: String, $userId: String) {
  getAllLoans  (userId: $userId, offset: $offset, limit: $limit, search: $search, filters: $filters, statusFilter: $statusFilter) {
    count
    loans {
      id
      amount
      userId
      tenor
      loanChannel
      nextRepaymentDate
      dueDate
      createdAt
      payfiId
      outstandingAmount
      user {
        firstName
        lastName
      }
      order {
        amount
      }
      loanStatus {
        name
      }
      merchant {
        name
      }

    }
  }
}
`;

export const GET_ADMIN_CUSTOMER_DETAILS_REPAYMENT = gql`
query getRepayments($search: String, $limit: Int, $offset: Int, $filters: FiltersType, $statusFilter: String, $userId: String) {
  getRepayments  (userId: $userId, offset: $offset, limit: $limit, search: $search, filters: $filters, statusFilter: $statusFilter) {
    count
    repayments {
      id
      isFullyPaid
      dueDate
      amountDue
      originalAmount
      payDate
      loanId
      transactions {
        paymentMethod
      }
      loan {
        userId
        id
        amount
        tenor
        paymentChannel
        user {
          firstName
          lastName
          email
          phone
          imageUrl
          id
      }
        merchant {
          name
        }
        loanStatus {
          name
        }
      }
      transactions {
        id
        paymentMethod
        status
        createdAt
      }
    }
  }
}
`;

export const GET_ADMIN_ALL_REPAYMENT = gql`
query getRepayments($search: String, $limit: Int, $offset: Int, $filters: FiltersType, $statusFilter: String, $userId: String) {
  getRepayments  (userId: $userId, offset: $offset, limit: $limit, search: $search, filters: $filters, statusFilter: $statusFilter) {
    count
    repayments {
      id
      isFullyPaid
      dueDate
      amountDue
      originalAmount
      payDate
      loanId
      transactions {
        paymentMethod
      }
      loan {
        userId
        id
        amount
        tenor
        paymentChannel
        merchant {
          name
        }
        loanStatus {
          name
        }
        user {
          firstName
          lastName
          email
          phone
          imageUrl
      }
      }
      transactions {
        id
        paymentMethod
        status
        createdAt
      }
    }
  }
}
`;

export const GET_PAYFI_PRODUCT = gql`
 query PayfiProducts {
   getPayfiProducts {
     payfiProducts {
       name
       status
       id
    }
  }
}
`;

export const GET_ADMIN_TRANSACTION_BY_ID = gql`
  query getTransactionById($id: ID!) {
    getTransactionById(id: $id) {
      txRef
      amount
      createdAt
      type
      status
      fees
      paymentMethod
      settlementStatus
      message
      gatewayResponse
      paymentRef
      user {
        firstName
        lastName
        email
        status
        phone
        imageUrl
        id
        onboardingChannel
        address {
          street
          city
        }
      }
      merchant {
        name
        id
      }
      order{
        orderItems {
            id
            orderName
            orderCategory
            amount
            options
            quantity
          }
          status {
            name
          }
      }
      loan {
        id
        loanSchedules {
          dueDate
          amountDue
        }
      }
      payout {
        apiResponse
        apiRequest
        response_message
      }
    }
  }
`;

export const GET_ADMIN_USER_ACTIVITIES_BY_ID = gql`
query GetUserActivityById($getUserActivityByIdId: String!) {
  getUserActivityById(id: $getUserActivityByIdId) {
    id
    user {
      firstName
      lastName
      email
      phone
      promise
      status
      imageUrl
    }
    sdkActivity {
      sdkorderItems {
        orderName
        amount
      }
    }
  }
}
`;

export const GET_ADMIN_OVERVIEW = gql`
  query adminOverview($fromDate: String, $toDate: String) {
    adminOverview(fromDate: $fromDate, toDate: $toDate) {
      totalLoans
      totalTransactions
      totalOutStandingLoan
      averageTransactionValue
    }
  }
`;

export const GET_ALL_ADMIN_OVERVIEW = gql`
  query allAdminOverview($input: AllAdminOverviewInput) {
    allAdminOverview(input: $input) {
      status
      message
      data {
        from
        to
        customers {
          value
          selectedFilter
          percentChange
          rateChange
        }
        merchants {
          value
          selectedFilter
          percentChange
          rateChange
        }
        loans {
          value
          selectedFilter
          percentChange
          rateChange
        }
        transactions {
          value
          selectedFilter
          percentChange
          rateChange
        }
        settlement {
          value
          selectedFilter
          percentChange
          rateChange
        }
        loanCount {
          value
          selectedFilter
          percentChange
          rateChange
        }
      }
    }
  }
`;

export const GET_SINGLE_ADMIN_OVERVIEW = gql`
  query singleAdminOverview($input: SingleAdminOverviewInput) {
   singleAdminOverview(input: $input) {
      status
      message
      data {
        from
        to
        data {
          value
          selectedFilter
          percentChange
          rateChange
        }
      }
    }
  }
`;

export const GET_ADMIN_LOAN_BY_ID = gql`
  query getLoan($id: String!) {
    getLoan(id: $id) {
      id
      loanStatusId
      amount
      merchant {
        name
      }
      order {
        orderItems {
          orderName
          amount
          options
          quantity
        }
      }
      fufilledByAdmin {
        id
        firstName
        lastName
        email
        imageUrl
        phone
        status
        department
      }
      manuallyCreatedByAdmin {
        id
        firstName
        lastName
        email
        imageUrl
        phone
        status
        department
      }
      user {
        firstName
        lastName
        email
        phone
        status
        imageUrl
        promise
        id
        onboardingChannel
        activeCard {
          last4digits
        }
      }
      interestPaid
      loanChannel
      paymentChannel
      dueDate
      currency
      tenor
      interest
      loanStatus {
        name
      }
      nextRepaymentDate
      loanSchedules {
        id
        amountDue
        dueDate
        isFullyPaid
        createdAt
      }
      interest
      outstandingAmount
      createdAt
      payfiId
      order {
        amount
        orderNumber
      }
    }
  }
`;

export const GET_ADMIN_REPAYMENT_BY_ID = gql`
  query getRepaymentById($id: String!) {
    getRepaymentById(id: $id) {
      id
      isFullyPaid
      dueDate
      amountDue
      originalAmount
     transactions {
        id
        status
        amount
            }
      loan {
        id
        loanStatusId
        amount
        merchant {
          name
        }
        order {
          orderItems {
            orderName
            amount
            options
            quantity
          }
        }
        user {
          firstName
          lastName
          email
          phone
          status
          imageUrl
          promise
          id
          onboardingChannel
          activeCard {
            last4digits
          }
        }
        interestPaid
        loanChannel
        paymentChannel
        dueDate
        currency
        tenor
        interest
        loanStatus {
          name
        }
        nextRepaymentDate
        loanSchedules {
          id
          amountDue
          dueDate
          isFullyPaid
        }
        interest
        outstandingAmount
        createdAt
        payfiId
        order {
          amount
          orderNumber
        }
      }
    }
  }
`;

export const GET_ADMIN_MERCHANT_BY_ID = gql`
  query getMerchant($id: ID!) {
    merchant(id: $id) {
      facebook
      instagram
      twitter
      street
      state
      city
      longDesc
      businessType
      website
      name
      institutionType
      logo
      instantTransactionsAmount
      loanTransactionsAmount
      pendingLoansAmount
      paidLoansAmount
      country
      phone
      status
      email
      createdAt
      isApproved
      documentSentForActivation
      sdk_start_screen
      businessOwner {
        country
        state
        city
        street
        dateOfBirth
        merchantUser {
          firstName
          lastName
          email
          phone
        }
      }
      transactions {
        txRef
        amount
        createdAt
        paymentMethod
        status
        fees
        user {
          firstName
          lastName
          email
          status
          phone
        }
        merchant {
          name
          id

        }
      }
      loans {
        id
        loanStatusId
        amount

        user {
          firstName
          lastName
          email
          phone
          activeCard {
            last4digits
          }
        }
        loanChannel
        dueDate
        currency
        tenor
        interest
        loanStatus {
          name
        }
        nextRepaymentDate
        interest
        outstandingAmount
        createdAt
      }
    }
  }
`;

export const GET_LOAN_MANDATE_STATUS = gql`
query GetMandateDetails($loanId: String) {
  getMandateDetails(loanId: $loanId) {
    data {
      status
    }
  }
}
`;

export const GET_OFFLINE_STORE_STATUS = gql`
query getMerchantUserDetail{
  getMerchantUserDetail{
  data {
    id
    merchant {
      id
      hasOfflineStore
    }


      }
  }
}
`;

export const GET_ADMIN_CUSTOMER_BY_ID = gql`
  query getCustomer($id: String!) {
    getUserById(id: $id) {
      instantTransactionsAmount
      loanTransactionsAmount
      pendingLoansAmount
      paidLoansAmount
      id
      primaryAddressId
      defaultAddressId
      createdAt
      dob
      firstName
      lastName
      otherName
      email
      stateOfOrigin
      bvn
      pin
      accountNumber
      creditScore
      imageUrl
      isBVNVerified
      isEmailVerified
      phone
      state
      idCard
      promise
      country
      status
      address {
        receiverName
      }
      defaultShippingAddress {
        city
      }
      activeCardId
      payDay
      isMobileUser
      payfiId
      activeCard {
        id
      }
      transactions {
        txRef
        amount
        createdAt
        paymentMethod
        status
        fees
        user {
          firstName
          lastName
          email
          status
          phone
        }
        merchant {
          name
          id
        }
      }
      loans {
        id
        loanStatusId
        amount

        user {
          firstName
          lastName
          email
          phone
          activeCard {
            last4digits
          }
        }
        loanChannel
        dueDate
        currency
        tenor
        interest
        loanStatus {
          name
        }
        nextRepaymentDate
        interest
        outstandingAmount
        createdAt
      }
      payfiProduct {
        id
        name
        disbursementPartnerId
        status
      }
    }
  }
`;

export const GET_ADMIN_CUSTOMER_BY_ID_V2 = gql`
  query getCustomer($id: String!) {
    getUserById(id: $id) {
    firstName
    email
    lastName
    otherName
     bvn
    maritalStatus
    phone
    gender
    createdAt
    imageUrl
    dob
    status
    eligibilityStatus
    promise
    isDeleted
    payfiProduct {
      id
      name
      disbursementPartnerId
      status
    }
    bankDetail {
      accountName
      accountNumber
      bank {
        name
      }
    }
    primaryAddress {
      city
      state
      street
      lga
      houseNo
      location
    }
    employmentdetail {
      id
      employmentStatus
      employerName
      monthlyIncome
      employerPhonenumber
      employerAddress
      employerPayDay
      oldDeploymentState
      newDeploymentState
      callUpNumber
      oldStatusCode
      newStatusCode
      batchYear
      batch
      startDate
      lga
      employmentEmail
    }
    nextofkin {
      relationship
      firstName
      otherName
      lastName
      phoneNumber
      email
      address
    }
  documents {
      id
      documentType
      issueDate
      status
      frontImage
      backImage
      expireDate
      documentId
      reason
      uri
      docKey
      provider
    }
    }
  }
`;

export const GET_ADMIN_CUSTOMER_BY_ID_V3 = gql`
  query getUserByIdAdmin($id: String!) {
    getUserByIdAdmin(id: $id) {
    id
    firstName
    email
    lastName
    otherName
     bvn
    maritalStatus
    phone
    gender
    createdAt
    imageUrl
    dob
    status
    eligibilityStatus
    promise
    isDeleted
    approvedAt
    isDeclined
    isBVNVerified
    onBoardingStage
    defaultProvider
    offer
    onboardingChannel
    activeCardId
    phone2
    cards {
      cardNo
      last4digits
      type
      status
      bank
      brand
      firstName
      lastName
    }
    priorityUser {
      userId
    }
    payfiProduct {
      id
      name
      disbursementPartnerId
      status
    }
    bankDetail {
      accountName
      accountNumber
      bank {
        name
      }
    }
    primaryAddress {
      city
      state
      street
      lga
      houseNo
      location
      verificationStatus
    }
    employmentdetail {
      id
      employmentStatus
      employerName
      monthlyIncome
      employerPhonenumber
      employerAddress
      employerPayDay
      oldDeploymentState
      newDeploymentState
      callUpNumber
      oldStatusCode
      newStatusCode
      batchYear
      batch
      startDate
      lga
      employmentEmail
      employerState: state
      businessName
      businessAddress
      averageMonthlyIncome
      businessWebsite
      haveWebsite
      socialMedia
      socialMediaHandle
      isBusinessRegistered
      cacDocument
      sector
      industry
    }
    nextofkin {
      relationship
      firstName
      otherName
      lastName
      phoneNumber
      email
      address
    }
    userBankAccounts {
      otherAccounts {
        bankName
        accountNumber
        accountName
      }
      verifiedAccounts {
        bankName
        accountNumber
        accountName
      }
    }
    priorityUser {
      userId
    }
  documents {
      id
      documentType
      issueDate
      status
      frontImage
      backImage
      expireDate
      documentId
      reason
      uri
      docKey
      file_ext
      updatedAt
      provider
      meta {
        contentType
        file_size
      }
    }
    reward {
      id
      level
      point
      pointIssued
      pointRedeemed
      dateEnrolled
      expiryDate
      status
      meta
      level_meta
      createdAt
    }
    }
  }
`;

export const GET_ADMIN_KYC_BY_ID = gql`
  query getKyc($id: String!) {
    getKyc(id: $id) {
      id
      email
      sdk_config
      phone
      name
      institutionType
      shortDesc
      longDesc
      businessType
      contactName
      contactEmail
      logo
      contactPhone
      country
      address
      city
      state
      street
      website
      facebook
      twitter
      instagram
      bank
      accountNumber
      accountName
      status
      documentSentForActivation
      registrationNumber
      tinNumber
      sdk_start_screen
      category {
        name
      }
      bankDetail {
        name
      }
      bankDetails {
        accountName
        accountNumber
        status
        bank {
          name
          cbnCode
          nipCode
        }
      }
      documents {
        id
        documentType
        frontImage
        backImage
        uri
        reason
        issueDate
        status
        docKey
        fileName
        documentId
        description
        meta {
          file_size
          contentType
        }
      }
      businessOwner {
        id
        merchantUserId
        country
        state
        city
        street
        dateOfBirth
        identityDocumentId
        identityDocument {
          id
          documentType
          frontImage
          backImage
          uri
          reason
          issueDate
          status
          docKey
          documentId
          description
        }
        merchantUser {
          firstName
          lastName
          email
          phone
        }
      }
    }
  }
`;

export const GET_BVN_DETAILS = gql`
  query getBVNDetailsWithBvn($userId: ID!) {
    getBVNDetailsWithBvn(userId: $userId) {
      firstName
      middleName
      lastName
      gender
      dateOfBirth
      phoneNo
      bvn
    }
  }
`;

export const ADMIN_VIEW_DOCUMENT = gql`
  query viewDocumentUsingID($id: ID!) {
    viewDocumentUsingID(id: $id) {
      status
      message
      url
    }
  }
`;

export const REPAYMENT_CONFIG = gql`
  query getRepaymentConfig {
    getRepaymentConfig {
      status
      message
      data {
        defaultPercentage
        gracePeriod
      }
    }
  }
`;

export const REPUSH_PROVIDUS_TRANSACTION = gql`
query RepushProvidusTransaction($settlementId: String, $sessionId: String) {
  repushProvidusTransaction(settlementId: $settlementId, sessionId: $sessionId) {
    statusText
    status
    data {
      responseMessage
      requestSuccessful
      responseCode
    }
  }
}
`;

export const GET_SMS_CONFIG = gql`
query getSMSConfig {
  getSMSConfigDetails {
    data {
      name
      type
      value
    }
  }
}
`;

export const GET_ALL_ROLES = gql`
query GetAllRoles {
  getAllRoles {
    message
    roles {
      created_by
      display_name
      id
      is_active
      name
      userCount
      permissions {
        created_by
        group
        id
        name
      }
    }
  }
}
`;

export const GET_VIRTUAL_ACCOUNT_PROVIDER_CONFIG = gql`
query getVirtualAccountProviderConfig {
  getVirtualAccountProviderConfig {
    status
    message
    data {
      id
      name
      value
      type
      createdAt
      updatedAt
    }
  }
}
`;

export const GET_PAYOUT_PROVIDER_CONFIG = gql`
query getPayoutProviderConfig {
  getPayoutProviderConfig {
    status
    message
    data {
      id
      name
      value
      type
      createdAt
      updatedAt
    }
  }
}
`;

export const GET_ALL_PERMISSIONS = gql`
query Permissions($roleId: ID, $type: String) {
  getAllPermissions(roleId: $roleId, type: $type) {
    permissions {
      created_by
      group
      id
      name
      hasPermission
    }
    status
  }
}
`;

export const GET_ALL_STAFF = gql`
query getStaff($limit: Int, $offset: Int, $search: String, $statusFilter: String, $roleId: String) {
  getStaffs(limit: $limit, offset: $offset, search: $search, statusFilter: $statusFilter, roleId: $roleId) {
    status
    message
    data {
      count
      rows {
        id
        firstName
        lastName
        email
        phone
        status
        role {
          display_name
          name
          id
        }
      }
    }
  }
}
`;

export const GET_PAYMENT_PROVIDER_CONFIG = gql`
query GetPaymentProviderConfig {
  getPaymentProviderConfig {
    status
    message
    data {
      id
      name
      value
      type
      createdAt
      updatedAt
    }
  }
}
`;

export const GET_STATEMENT_PROVIDER_CONFIG = gql`
query GetStatementProviderConfig {
  getStatementProviderConfig {
    status
    message
    data {
      id
      name
      value
      type
      createdAt
      updatedAt
    }
  }
}
`;

export const GET_ZEROBITS_DISBURSEMENT_PARTNER_CONFIG = gql`
query getZerobitsDisbursementPartnerConfig {
  getZerobitsDisbursementPartnerConfig {
    status
    message
    data {
      id
      name
      value
      type
      createdAt
      updatedAt
    }
  }
}
`;

export const GET_SDK_DEFAULT_CONFIGURATION = gql`
  query getSDKDefaultConfiguration {
    getSDKDefaultConfiguration {
      status
      message
      data {
        id
        name
        value
        type
        meta
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_LOAN_VOUCHER_CODES = gql`
  query GetLoanVoucherCodes($type: String, $limit: Int, $offset: Int, $filters: FiltersType, $search: String, $statusFilter: String, $userId: String) {
  getLoanVoucherCodes(type: $type, limit: $limit, offset: $offset, filters: $filters, search: $search, statusFilter: $statusFilter, userId: $userId) {
    data {
      count
      rows {
        amount
        id
        loan {
          amount
          createdAt
          currency
          dueDate
          interest
          id
          interestPaid
          loanChannel
          userId
          tenor
          paymentChannel
          payfiId
          outstandingAmount
          nextRepaymentDate
          loanStatusId
          user {
            email
            firstName
            gender
            id
            status
            stage
            state
            payDay
            payfiId
            lastName
            lastLoginAt
            isMobileUser
            isEmailVerified
            isDeleted
            isDeclined
            isBVNVerified
          }
          loanSchedules {
            amountDue
            dueDate
            id
            isFullyPaid
            loanId
            originalAmount
            payDate
            loan {
              userId
              tenor
              paymentChannel
              payfiId
              outstandingAmount
              nextRepaymentDate
              loanStatusId
              loanStatus {
                name
                id
              }
              amount
              approvedByUser {
                email
                gender
                bvn
              }
              currency
              dueDate
              id
              interest
              interestPaid
              loanChannel
            }
          }
        }
        loanId
        status
        userId
        voucherCode
      }
      unallocatedCount
    }
    message
    status
  }
}
`;

export const GET_BILLS_PAYMENT = gql`
  query GetBillsPayment($limit: Int, $offset: Int, $filters: FiltersType, $search: String, $statusFilter: String) {
  getBillsPayment(limit: $limit, offset: $offset, filters: $filters, search: $search, statusFilter: $statusFilter) {
    data {
      count
      rows {
        amount
        id
        createdAt
        provider
        narration
        apiResponse
        payout_reference
        status
        transaction {
          paymentMethod
          description
        }
        user {
          firstName
          lastName
          email
        }
      }
    }
    message
    status
  }
}
`;
export const GET_ALL_ADMIN_REFUNDS = gql`
query GetAllRefundLoans($limit: Int, $offset: Int, $filters: FiltersType, $search: String, $statusFilter: String, $userId: String) {
  getAllRefundLoans(limit: $limit, offset: $offset, filters: $filters, search: $search, statusFilter: $statusFilter, userId: $userId) {
    count
    loans {
      payfiId
      id
      user {
        firstName
        lastName
        paidLoansAmount
        email
      }
      merchant {
        name
      }
      order {
        items
      }
      createdAt
      amountPaid
      amount
      loanStatus {
        name
      }
    }
  }
}
`;

export const GET_RNPL_CONFIG = gql`
  query getRNPLVoucherConfig{
    getRNPLVoucherConfig {
      status
      message
      config
    }
  }
`;

export const GET_EXTERNAL_SERVICE_BALANCE = gql`
query GetExternalServiceBalances {
  getExternalServiceBalances {
    status
    message
    data
  }
}
`;

export const GET_MERCHANT_AND_LOAN_CONFIG = gql`
  query getMerchantsAndLoanConfig{
  getMerchantsAndLoanConfig {
    status
    message
    data {
      merchants {
        id
        name
        sdk_config
      }
      config
    }

  }
}
`;

export const GET_REWARD_OVERVIEW = gql`
query Data($fromDate: String, $toDate: String) {
  getRewardOverview(fromDate: $fromDate, toDate: $toDate) {
    data {
      from
      to
      timeAgo
      totalUsers {
        value
        percentChange
        rateChange
        users
      }
      totalPointsUnclaimed {
        value
        percentChange
        rateChange
        users
      }
      totalPointsIssued {
        value
        percentChange
        rateChange
        users
      }
      totalPointsRedeemed {
        value
        percentChange
        rateChange
        users
      }
    }
  }
}
`;

export const GET_VIRTUAL_ACCOUNT_BY_USER_ID = gql`
query getVirtualAccountByUserId($userId: String){
  getVirtualAccountByUserId(userId: $userId) {
    status
    message
    data {
      account
      accountName
      bankName
      balance
      createdAt
      status
      isPnd
    }
  }
}
`;
