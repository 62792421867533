import { matchPath } from 'react-router';
import { FunctionComponent, useCallback, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useGlobalState, useGlobalDispatch } from './contexts';

import DashboardWrap from './components/DashboardWrap';
// import GifLoader from './components/Loading/GifLoader';
import Dots from './components/Loading/Dots';
import { AdminRouteName } from './admin_screens/CONSTANTS';

export interface IAuthComp {
  children?: any;
}

const globalDispatch = useGlobalDispatch;
const globalState = useGlobalState;
const isAdmin = process.env.REACT_APP_TYPE === 'ADMIN';

const AuthComp: FunctionComponent<IAuthComp> = ({ children }) => {
  const { get_profile } = globalDispatch();
  const { profile, profileLoading } = globalState();
  const { push } = useHistory();
  const { pathname } = useLocation();

  const shouldGetProfile = ![
    '/',
    '/register',
    '/verify-email',
    '/forgot-password',
    '/password-reset',
    '/email-verify-successful',
  ].includes(pathname);

  const IsPathNotEqual = useCallback((pathname) => {
    if (isAdmin) {
      const routePaths = Object.values(AdminRouteName);
      const match = matchPath(pathname, {
        path: routePaths,
        exact: true,
        strict: false,
      });
      if (!routePaths.includes(match.path) || match.path === '*') {
        return push(`/${AdminRouteName.notFoundRoute}`);
      }
    }
  }, [isAdmin]);

  useEffect(() => {
    IsPathNotEqual(pathname);
  }, [IsPathNotEqual]);

  useEffect(() => {
    if (localStorage.token && shouldGetProfile) {
      get_profile();
    }
  }, [localStorage.token, shouldGetProfile]);

  useEffect(() => {
    if (!localStorage.token && !profileLoading && shouldGetProfile) {
      push('/');
    }
  }, [localStorage.token, profileLoading, pathname, shouldGetProfile]);

  useEffect(() => {
    if (profile?.email && !profile?.isEmailVerified) {
      push('/verify-email');
    }
  }, [profile, pathname]);
  // const shouldHavePermissions = isAdmin && !userPermissions?.length;
  if ((profileLoading && shouldGetProfile)) {
    return (
      <DashboardWrap disabled>
        <div className="general-loader">
          <Dots />
        </div>
      </DashboardWrap>
    );
  }

  return <div>{children}</div>;
};

export default AuthComp;
