import React from 'react';

const FilterRemoveSelectedIcon = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.68278 5.31641L5.31543 8.68376" stroke="#81899E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8.68364 8.68532L5.31348 5.31445" stroke="#81899E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <circle cx="7" cy="7" r="6" stroke="#81899E" />
  </svg>
);

export default FilterRemoveSelectedIcon;
