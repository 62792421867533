import { Parser } from 'json2csv';
import moment from 'moment';

type IGeneratePDF = {
  payload: any,
  title?: string,
  selectedHeaders: any,
  cb: () => void,
  formatHeaders?: any
};

const download = (data: any, fileName: string) => {
  // Creating a Blob for having a csv file format
  // and passing the data with type
  const blob = new Blob([data], { type: 'text/csv' });

  // Creating an object for downloading url
  const url = window.URL.createObjectURL(blob);

  // Creating an anchor(a) tag of HTML
  const a = document.createElement('a');

  // Passing the blob downloading url
  a.setAttribute('href', url);

  // Setting the anchor tag attribute for downloading
  // and passing the download file name
  a.setAttribute('download', fileName);

  // Performing a download with click
  a.click();
};

const generateCSV = ({
  payload, selectedHeaders, cb = () => {}, formatHeaders, title,
}: IGeneratePDF) => {
  const fields = selectedHeaders.map((headerRaw) => {
    const header = Array.isArray(headerRaw) ? (headerRaw[0] || headerRaw[1]) : headerRaw;
    return {
      label: (formatHeaders ? (formatHeaders[header] || header) : header)?.toUpperCase?.(),
      value: headerRaw,
    };
  });

  const json2csvParser = new Parser({ fields });
  const csv = json2csvParser.parse(payload);
  const fileName = `${title?.toLowerCase?.()}_${moment(new Date()).format('YYYY_MM_DD_HHMMSS')}`;
  download(csv, fileName);
  cb();
};

export default generateCSV;
