import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { useGlobalDispatch, useGlobalState } from '../../../contexts';
import AvaterSectionDetails from '../MerchantProfile/AvaterSectionDetails';
import useForm from '../../../customHooks/useForm';
import useToast from '../../../customHooks/useToast';
import {
  compareobject, getLocalGovt, getPhoneNum, getStates, mailRegex,
} from '../../../utils/helpers';
import './MerchantBusinessProfileCover.scss';
import CountrySelectOption from '../../../components/CountrySelectOption';

import TextInput from '../../../components/input';
import { UPLOAD_BUSINESS_LOGO, UPDATE_MERCHANT } from '../../../graphql/mutations';
import StyledButton from '../../../components/StyledButton';
import SelectSearch from '../../../components/SelectSearch';

const MerchantBusinessProfile = () => {
  const Toast = useToast();
  const { profile }:any = useGlobalState();
  const [updateBusinessDetailsLogo] = useMutation<
  { updateMerchantLogo: any },
  any
>(UPLOAD_BUSINESS_LOGO);
  const {
    formValues,
    handleChange,
    resetForm,
    checkAllRequired,
    validateInputs,
  } = useForm(
    {
      name: '',
      supportEmail: '',
      email: '',
      phone: '',
      street: '',
      city: '',
      state: '',
      facebook: '',
      twitter: '',
      instagram: '',
      website: '',
    },
    {
      phone: {
        pattern: /^[6-9]\d{9}$/,
        errorMessage: 'Put a valid nigerian phonenumber. Format 7052648321',
        smallFormatter: (rec) => `${Number(rec)}`,
      },
      email: {
        pattern: mailRegex, // regex for email validation
        errorMessage: 'Enter a valid support Email',
      },
    },
    Toast,
  );

  useEffect(() => {
    if (profile?.merchant) {
      const thisMerchant = profile?.merchant;

      resetForm({
        name: thisMerchant?.name,
        supportEmail: thisMerchant?.supportEmail,
        email: thisMerchant?.email,
        phone: getPhoneNum(thisMerchant?.contactPhone),
        street: thisMerchant?.street,
        city: thisMerchant?.city,
        state: thisMerchant?.state,
        website: thisMerchant?.website,
        facebook: thisMerchant?.facebook,
        twitter: thisMerchant?.twitter,
        instagram: thisMerchant?.instagram,
      });
    }
  }, [profile]);
  const [imageLoading, setImageLoading] = useState(false);
  const handleFile = async (file) => {
    setImageLoading(true);
    try {
      const payload = { file, resourceType: 'image' };
      const resp = await updateBusinessDetailsLogo({
        variables: payload,
      });

      if (resp?.data?.updateMerchantLogo?.status === 200) {
        Toast.success('Successful');
        await get_profile_in_app();
      } else {
        Toast.error(resp?.data?.updateMerchantLogo?.message);
      }
    } catch (e) {
      if (e && e.graphQLErrors && e.graphQLErrors.length > 0) {
        Toast.error(e.graphQLErrors[0].message);
      } else {
        Toast.error('Something went wrong');
      }
    }
    setImageLoading(false);
  };

  const [updateBusinessProfile] = useMutation<
  { createSaving: any; updateMerchant: any },
  any
>(UPDATE_MERCHANT);
  const [loading, setLoading] = useState(false);
  const { get_profile, get_profile_in_app } = useGlobalDispatch();

  const handleSubmit = async () => {
    setLoading(true);
    if (!checkAllRequired() || !validateInputs()) {
      setLoading(false);
      Toast.error('Please complete all fields');
      return;
    }
    try {
      const payload: { email: string } = {
        ...formValues,
        contactPhone: `+234${Number(formValues.phone)}`,
      };
      const resp = await updateBusinessProfile({ variables: { input: payload } });

      setLoading(false);
      if (resp?.data?.updateMerchant?.status === 200) {
        Toast.success('Merchant information saved successfully');
        await get_profile();
        setShowBusSaveButton(false);
        setShowSocSaveButton(false);
      } else {
        Toast.error(resp?.data?.updateMerchant?.message);
      }
    } catch (e) {
      setLoading(false);
      if (e && e.graphQLErrors && e.graphQLErrors.length > 0) {
        Toast.error(e.graphQLErrors[0].message);
      } else {
        Toast.error('Something went wrong');
      }
    }
  };

  const [showBusSaveButton, setShowBusSaveButton] = useState(false);
  const [showSocSaveButton, setShowSocSaveButton] = useState(false);

  const handleBusBlur = () => {
    if (profile?.merchant) {
      const thisMerchant = profile?.merchant;
      const currentBusRec = {
        name: thisMerchant?.name,
        supportEmail: thisMerchant?.supportEmail,
        email: thisMerchant?.email,
        phone: getPhoneNum(thisMerchant?.contactPhone),
        street: thisMerchant?.street,
        city: thisMerchant?.city,
        state: thisMerchant?.state,
      };
      const changingBusRec = {
        name: formValues?.name,
        supportEmail: formValues?.supportEmail,
        email: formValues?.email,
        phone: getPhoneNum(formValues?.phone),
        street: formValues?.street,
        city: formValues?.city,
        state: formValues?.state,
      };

      const isEqual = compareobject(currentBusRec, changingBusRec);
      if (!isEqual) {
        // show save button
        setShowBusSaveButton(true);
      } else {
        // hide save button
        setShowBusSaveButton(false);
      }
    }
  };

  const handleSocBlur = () => {
    if (profile?.merchant) {
      const thisMerchant = profile?.merchant;
      const currentSocRec = {
        website: thisMerchant?.website,
        facebook: thisMerchant?.facebook,
        twitter: thisMerchant?.twitter,
        instagram: thisMerchant?.instagram,
      };
      const changingBusRec = {
        website: formValues?.website,
        facebook: formValues?.facebook,
        twitter: formValues?.twitter,
        instagram: formValues?.instagram,
      };

      const isEqual = compareobject(currentSocRec, changingBusRec);
      if (!isEqual) {
        // show save button
        setShowSocSaveButton(true);
      } else {
        // hide save button
        setShowSocSaveButton(false);
      }
    }
  };
  const [getStatesData, setgetStatesData] = useState([]);
  useEffect(() => {
    const stateList = [];
    getStates()?.map((list) => {
      const data = {
        value: list?.value,
        text: list?.text,
        label: list?.text,
      };
      return stateList.push(data);
    });
    setgetStatesData(stateList);
  }, []);
  const [getCityData, setgetCityData] = useState([]);
  useEffect(() => {
    const cityList = [];
    if (formValues?.state) {
      getLocalGovt(formValues?.state)?.map((list) => {
        const data = {
          value: list?.value,
          text: list?.text,
          label: list?.text,
        };
        return cityList.push(data);
      });
      setgetCityData(cityList);
    }
  }, [formValues?.state]);

  return (
    <div className="merchant-business-profile-cover container col-lg-10 col-md-12 m-0 p-0">
      <div className="merchant-business-profile-cover-avater-cover">
        <AvaterSectionDetails
          title="Business Logo"
          subTitle="Select your business Logo"
          logo={profile?.merchant?.logo}
          handleUploadFile={handleFile}
          loading={imageLoading}
        />
      </div>
      {/* form and business content row start */}
      <div className="row m-0 p-0 merchant-business-profile-cover-row">
        {/* Business profile section start */}
        <div className="business-profile-col-left col-md-6 col-12 p-0 m-0">
          <div className="business-profile-col-left-title">Business Profile</div>
          <div className="business-profile-col-left-subtitle">
            Make adjustments to your business
            information and save them.

          </div>
          {/* <div className="business-profile-col-left-edit-profile">
            <span>Edit Profile</span>
          </div> */}
        </div>
        <div className="business-profile-col-right col-md-6 col-12 p-0 m-0">
          <div className="row m-0 p-0 business-profile-col-right-row">
            <div className="col-md-12 col-12 m-0 p-0">
              <TextInput
                customOnChange={handleChange}
                name="name"
                value={formValues.name}
                label="Business Name"
                width="100%"
                onBlur={handleBusBlur}
              />
            </div>
            <div className="col-md-6 col-12 m-0 p-0 business-profile-col-right-form-left-div">
              <TextInput
                customOnChange={handleChange}
                name="email"
                value={formValues.email}
                label="Business Email"
                width="100%"
                onBlur={handleBusBlur}
                disabled
              />
            </div>
            <div className="col-md-6 col-12 m-0 p-0 business-profile-form-right-div">
              <TextInput
                customOnChange={handleChange}
                name="supportEmail"
                value={formValues.supportEmail}
                label="Support Email"
                width="100%"
                onBlur={handleBusBlur}
              />
            </div>

            <div className="col-4 business-profile-col-right-form-left-div p-0 m-0">
              <CountrySelectOption />
            </div>
            <div className="col-8 business-profile-form-right-div p-0 m-0">
              <TextInput
                name="phone"
                value={formValues?.phone}
                customOnChange={handleChange}
                label="Phone Number"
                width="100%"
                onBlur={handleBusBlur}
                viewOnly
              />
            </div>
            <div className="col-md-12 col-12 m-0 p-0">
              <TextInput
                customOnChange={handleChange}
                name="street"
                value={formValues.street}
                label="street Name"
                width="100%"
                onBlur={handleBusBlur}
              />
            </div>
            <div className="col-md-6 col-12 m-0 p-0 business-profile-col-right-form-left-div">
              <div style={{ marginBottom: '20px' }}>
                <SelectSearch
                  label=""
                  options={getStatesData}
                  placeholder="State"
                  name="state"
                  value={formValues.state}
                  required={false}
                  classes={false}
                  hideLabel
                  onChange={handleChange}
                />
              </div>

            </div>
            <div className="col-md-6 col-12 m-0 p-0 business-profile-form-right-div">
              <div style={{ marginBottom: '20px' }}>
                <SelectSearch
                  label=""
                  options={getCityData}
                  placeholder="City"
                  name="city"
                  value={formValues.city}
                  required={false}
                  classes={false}
                  hideLabel
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
          {showBusSaveButton && (
          <div className="settings-btn-cover">
            <StyledButton
              disabled={loading}
              onClick={handleSubmit}
              color="white"
              backgroundColor="#523B9F"
              fontSize="13px"
              name={loading ? 'Saving...' : 'Save Changes'}
              width="120px"
              height="40px"
              borderRadius="4px"
              border="none"
            />
          </div>
          )}
        </div>
      </div>
      <div className="row m-0 p-0 merchant-social-media-profile-cover-row">
        {/* Merchant social media profile section start */}
        <div className="merchant-social-media-profile-col-left col-md-6 col-12 p-0 m-0">
          <div className="merchant-social-media-profile-col-left-title">Social Media Details</div>
          <div className="merchant-social-media-profile-col-left-subtitle">
            Add and update your media links.

          </div>
          {/* <div className="merchant-social-media-profile-col-left-edit-profile">
            <span>Edit Profile</span>
          </div> */}
        </div>
        <div className="merchant-social-media-profile-col-right col-md-6 col-12 p-0 m-0">
          <div className="col-12 m-0 p-0">
            <TextInput
              name="website"
              value={formValues?.website}
              customOnChange={handleChange}
              label="Website URL"
              width="100%"
              onBlur={handleSocBlur}
            />
          </div>
          <div className="col-12 m-0 p-0">
            <TextInput
              label="facebook"
              name="facebook"
              value={formValues?.facebook}
              customOnChange={handleChange}
              width="100%"
              onBlur={handleSocBlur}
            />
          </div>
          <div className="col-12 m-0 p-0">
            <TextInput
              label="twitter"
              name="twitter"
              value={formValues?.twitter}
              customOnChange={handleChange}
              width="100%"
              onBlur={handleSocBlur}
            />
          </div>
          <div className="col-12 m-0 p-0">
            <TextInput
              name="instagram"
              value={formValues?.instagram}
              customOnChange={handleChange}
              label="instagram"
              width="100%"
              onBlur={handleSocBlur}
            />
          </div>
          {showSocSaveButton && (
          <div className="settings-btn-cover">
            <StyledButton
              disabled={loading}
              onClick={handleSubmit}
              color="white"
              backgroundColor="#523B9F"
              fontSize="13px"
              name={loading ? 'Saving...' : 'Save Changes'}
              width="120px"
              height="40px"
              borderRadius="4px"
              border="none"
            />
          </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default MerchantBusinessProfile;
