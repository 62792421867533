import React, { forwardRef } from 'react';
import DatePicker from 'react-datepicker';
import CalenderIcon from '../../svgs/CalenderIcon';
import 'react-datepicker/dist/react-datepicker.css';

type Props = {
  name?: string;
  value: any;
  disabled?: boolean;
  onChange?: any;
  placeholder?: string;
  ref?: any;
  onClick?: any;
  minDate?: string;
  maxDate?: string;
}

const DateInput = ({
  name, onChange, value: val, placeholder, minDate, maxDate,
}: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleChange = (output: string, e) => {
    e?.persist();
    e?.stopPropagation?.();
    onChange({
      target: {
        name,
        value: output,
      },
    });
  };
  const CustomComp = forwardRef(({ onClick }: any, ref: React.LegacyRef<HTMLDivElement>) => (
    <div
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      ref={ref}
      className="date-input"
    >
      {val ? <span className="value-span">{val}</span> : <span className="filter-input-placeholder">{placeholder || 'Select Date'}</span>}
      <CalenderIcon />
    </div>
  ));

  return (
    <div className="date-picker-cover">
      <DatePicker
        onChange={handleChange}
        selected={new Date(val || Date.now())}
        dateFormat="yyyy-MM-dd"
        onMonthChange={() => {
          // console.log(e, r);
        }}
        maxDate={new Date(maxDate)}
        minDate={new Date(minDate)}
        customInput={(
          <CustomComp />
        )}
      />
    </div>

  );
};

export default DateInput;
