import moment from 'moment';
import { FunctionComponent, useEffect, useState } from 'react';
import DashboardDateComp from '../../components/DashboardDateComp';
import { GET_OVERVIEW_TRANSACTION } from '../../graphql/queries';
import useCustomQuery from '../../network/useCustomQuery';
import DecreaseIcon from '../../svgs/DecreaseIcon';
import IncreaseIcon from '../../svgs/IncreaseIcon';

import { formatCash, numberWithCommas } from '../../utils/tableHelpers';
import LineGraph from './LineGraph';
import TransactionLabels from './TransactionLabels';

const TransactionVolume: FunctionComponent = () => {
  const [getOverviewTransactions,
    getOverviewTransactionLoading,
    overviewTransaction] = useCustomQuery({
    query: GET_OVERVIEW_TRANSACTION,
    onError: () => {},
    onData: () => {},
  });
  const daysInMonth = moment().daysInMonth();

  const [selected, setSelected] = useState(daysInMonth);
  const handleSelect = (arg: any) => {
    setSelected(Number(arg));
    getOverviewTransactions({
      variables: {
        timePeriod: Number(arg),
      },
    });
  };
  useEffect(() => {
    getOverviewTransactions({ variables: { timePeriod: selected } });
  }, []);

  const payload = overviewTransaction?.getMerchantTransactionsOverview?.transactionDetail;
  const percentVal = payload?.percentageChangeInTransactionVol;
  const percentageVal = Math.abs(Number(percentVal) || 0)?.toFixed(2);
  return (
    <div className="trx-card overview-card">
      <div className="volume-graph">
        <div className="graph-title">
          <h5 className="card-overview-title graph-inner-title">{getOverviewTransactionLoading ? 'Loading...' : 'Total Transaction Volume'}</h5>
          <DashboardDateComp selected={selected} handleSelect={handleSelect} />
        </div>
        <div className="trx-vol-rec">
          <strong>{payload?.totalTransactionVol || 0}</strong>
          {payload?.transactionVolChangeStatus && (
          <div className="flexed-row">
            {payload?.transactionVolChangeStatus === 'increased' ? <IncreaseIcon /> : <DecreaseIcon />}
            <span style={payload?.transactionVolChangeStatus === 'increased' ? {} : { color: '#912018' }}>{`${percentageVal}%`}</span>
          </div>
          )}
        </div>
        <LineGraph trxArr={overviewTransaction?.getMerchantTransactionsOverview?.trxRecords} />
      </div>
      <div className="trx-info">
        <div className="info-num">
          <TransactionLabels
            header="No of Transactions"
            footer="These are the total number of transactions you’ve completed on Payfi"
            value={
              numberWithCommas(payload?.noOfTransactionsAllTime || 0)
            }
          />
        </div>
        <div className="info-num">
          <TransactionLabels
            header="Average Transaction Value"
            footer="This is the average total amount per transaction of your customers overtime"
            value={formatCash(payload?.avgtransactionVolAllTime || 0, '₦ ') as string}
          />
        </div>
      </div>
    </div>
  );
};

export default TransactionVolume;
/*

*/
