import { FunctionComponent, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import DashboardWrap from '../../components/DashboardWrap';
import LinkBackComponent from '../../components/LinkBackComponent';
import { GET_CUSTOMER_BY_ID } from '../../graphql/queries';
import useCustomQuery from '../../network/useCustomQuery';
import { cleanSingleMerchantCustomerRecord } from '../../utils/helpers';
import BlacklistCustomer from './BlacklistCustomer';
import './CustomerDetail.scss';
import CustomerInfo from './CustomerInfo';
import CustomerTransactions from './CustomerTransactions';

const CustomerDetail: FunctionComponent = () => {
  const { customerId } = useParams<{ customerId: string }>();

  const [getCustomer, loading, customer] = useCustomQuery({
    query: GET_CUSTOMER_BY_ID,
    onError: () => {},
    onData: () => {},
  });

  useEffect(() => {
    if (customerId) {
      getCustomer({ variables: { id: customerId } });
    }
  }, [customerId]);

  const payload = customer?.getSingleMerchantCustomerById
  && cleanSingleMerchantCustomerRecord(customer?.getSingleMerchantCustomerById);

  return (
    <DashboardWrap>
      <div className="single-customer-detail">
        <header>
          <div className="title-cov">
            <LinkBackComponent
              link="/customers"
              text="Customers"
            />
            <div className="trx-title status-styles">
              <h5>
                {loading ? '...' : payload?.name?.toLowerCase?.()}
              </h5>
              <span className={`${payload?.customerStatus?.toLowerCase?.()}-status`}>
                {payload?.customerStatus}
              </span>
            </div>
          </div>
        </header>
        <section>
          <CustomerInfo customer={payload} />
          <BlacklistCustomer />
        </section>
        <CustomerTransactions customerId={customerId} />
      </div>
    </DashboardWrap>
  );
};

export default CustomerDetail;
