import { FunctionComponent } from 'react';
import {
  BrowserRouter, Route, Switch,
} from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';
import { AdminGlobalProvider } from '../contexts/AdminGlobalContext';

import { AdminRouteName } from './CONSTANTS';

import Login from './login/Login';
import Transactions from './transactions';
import Dashboard from './dashboard';
import TransactionDetail from './transactionDetail';
// import Customers from './customers';
import AuthComp from '../AuthComp';
// import VerifyEmail from './VerifyEmail';
import SettlementTransactions from './settlements/SettlementTransactions';
// import ResetPassword from './ResetPassword/ResetPassword';
import PasswordChange from './ResetPassword/PasswordChange';
import AuditTrails from './Audit';
import Merchants from './merchants';
// import Loans from './loans';
// import KYC from './kyc';
// import LoanDetail from './loanDetail';
import MerchantDetails from './merchantDetail';
import CustomerDetail from './customerDetail';
// import KYCDetail from './kycDetail';
import IdealTimer from '../utils/idealTimer';
import SettlementsRecords from './settlement';
import AdminCustomers from './AdminCustomers';
import AdminLoans from './AdminLoans';
import AdminRepayments from './AdminRepayment';
import AdminLoanDetails from './AdminLoanDetails';
import AdminRepaymentDetails from './AdminRepaymentDetails';
import Configurations from './Configurations';
import Settings from './Settings';
import SdkActivities from './SdkActivities';
import SDKActivitiesDetail from './SdkActivitiesDetails';
import PushNotification from './PushNotification';
import PushNotificationUserGroup from './PushNotificationUserGroup';
import DisbursementPartners from './disbursementPartners';
import DisbursementDetails from './disbursementDetail';
import CheckEligibility from './CheckEligibility';
import CheckEligibilityDetails from './CheckEligibilityDetails';
import RolesDetails from './Settings/RolesDetails';
import AssignRolesAndPermission from './Settings/RolesAndPermission/AssignRolesAndPermission';
import NotAllowed from './NotAllowed';
import RNPL from './RNPL';
import BillsPayment from './BillsPayment';
import NotFound from './NotFound404';
import RefundLoans from './RefundLoans';
import Reawards from './Rewards';
import RedemptionHistory from './Rewards/RedemptionHistory';
import Leaderboard from './Rewards/Leaderboard';
import Wallets from './Wallets';
// import BusinessDocument from './kycDetail/BusinessDocument';
// import KYC from './merchantDetail/KYC';

const Router: FunctionComponent = () => (
  <ToastProvider>
    <BrowserRouter>
      <AdminGlobalProvider>
        <IdealTimer />
        <AuthComp>
          <div className="">
            <Switch>
              <Route exact path={AdminRouteName.loginRoute} component={Login} />
              <Route exact path={AdminRouteName.dashboardRoute} component={Dashboard} />
              <Route path={AdminRouteName.passwordResetRoute} component={PasswordChange} />
              <Route
                path={AdminRouteName.billsPaymentRoute}
                component={BillsPayment}
              />
              <Route
                path={AdminRouteName.transactionDetailsRoute}
                component={TransactionDetail}
              />
              <Route
                path={AdminRouteName.transactionRoute}
                component={Transactions}
              />
              <Route
                path={AdminRouteName.rnplRoute}
                component={RNPL}
              />
              <Route
                path={AdminRouteName.rewardRoute}
                component={Reawards}
              />
              <Route
                path={AdminRouteName.redemptionHistory}
                component={RedemptionHistory}
              />
              <Route
                path={AdminRouteName.rewardLeaderboard}
                component={Leaderboard}
              />
              <Route
                path={AdminRouteName.disbursementDetailsRoute}
                component={DisbursementDetails}
              />
              <Route path={AdminRouteName.disbursementRoute} component={DisbursementPartners} />
              <Route
                path={AdminRouteName.checkEligibilityDetailsRoute}
                component={CheckEligibilityDetails}
              />
              <Route
                path={AdminRouteName.checkEligibilityRoute}
                component={CheckEligibility}
              />
              <Route
                path={AdminRouteName.refundLoansRoute}
                component={RefundLoans}
              />
              <Route
                path={AdminRouteName.walletsRoute}
                component={Wallets}
              />
              <Route
                path={AdminRouteName.notAllowedRoute}
                component={NotAllowed}
              />
              <Route
                path={AdminRouteName.adminSettlementDetailsRoute}
                component={SettlementTransactions}
              />
              <Route path={AdminRouteName.adminSettlementRoute} component={SettlementsRecords} />
              <Route path={AdminRouteName.auditLogsRoute} component={AuditTrails} />
              <Route
                path={AdminRouteName.customerDetailsRoute}
                component={CustomerDetail}
              />
              <Route path={AdminRouteName.customersRoute} component={AdminCustomers} />
              <Route
                path={AdminRouteName.adminMerchantDetailsRoute}
                component={MerchantDetails}
              />
              <Route path={AdminRouteName.adminMerchantsRoute} component={Merchants} />
              {/* <Route
                path="/kyc"
                component={() => (
                  <Switch>
                    <Route exact path="/kyc" component={KYC} />
                    <Route path="/kyc/:merchantId" component={KYCDetail} />
                  </Switch>
                )}
              /> */}
              <Route
                path={AdminRouteName.sdkActivitiesDetailsRoute}
                component={SDKActivitiesDetail}
              />
              <Route path={AdminRouteName.sdkActivitiesRoute} component={SdkActivities} />
              <Route
                path={AdminRouteName.pushNotificationUserGroupRoute}
                component={PushNotificationUserGroup}
              />
              <Route path={AdminRouteName.pushNotificationRoute} component={PushNotification} />
              <Route path={AdminRouteName.loanDetailsRoute} component={AdminLoanDetails} />
              <Route path={AdminRouteName.loansRoute} component={AdminLoans} />
              <Route
                path={AdminRouteName.repaymentDetailsRoute}
                component={AdminRepaymentDetails}
              />
              <Route path={AdminRouteName.repaymentsRoute} component={AdminRepayments} />
              <Route path={AdminRouteName.configurationsRoute} component={Configurations} />
              <Route path={AdminRouteName.settingsRoleDetailsRoute} component={RolesDetails} />
              <Route
                path={AdminRouteName.settingsAssignPermissionRoute}
                component={AssignRolesAndPermission}
              />
              <Route path={AdminRouteName.settingsRoute} component={Settings} />
              <Route
                path={AdminRouteName.notFoundRoute}
                component={NotFound}
              />
            </Switch>
          </div>
        </AuthComp>
      </AdminGlobalProvider>
    </BrowserRouter>
  </ToastProvider>
);

export default Router;
