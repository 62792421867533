import React from 'react';

interface ISHowMoreIcon{
color?:any;
}
const SHowMoreIcon = ({ color }:ISHowMoreIcon) => (
  <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M0.440641 0.565641C0.611495 0.394786 0.888505 0.394786 1.05936 0.565641L6 5.50628L10.9406 0.565641C11.1115 0.394786 11.3885 0.394786 11.5594 0.565641C11.7302 0.736495 11.7302 1.0135 11.5594 1.18436L6.30936 6.43436C6.1385 6.60521 5.8615 6.60521 5.69064 6.43436L0.440641 1.18436C0.269786 1.0135 0.269786 0.736495 0.440641 0.565641Z" fill={color || '#1A1A1A'} />
  </svg>

);

export default SHowMoreIcon;
