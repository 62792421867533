import React from 'react';

interface ICloseButtonIcon{
  color?:string;
}
const CloseButtonIcon = ({ color }:ICloseButtonIcon) => (
  <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.18376 4.81641L4.81641 8.18376" stroke={color || '#FCFAFF'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8.18266 8.18532L4.8125 4.81445" stroke={color || '#FCFAFF'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <circle cx="6.5" cy="6.5" r="6" stroke={color || '#FCFAFF'} />
  </svg>

);
export default CloseButtonIcon;
