import { useMutation } from '@apollo/client';
import { FunctionComponent, useEffect, useState } from 'react';
import useForm from '../../../customHooks/useForm';
// import './AddNewAccount.scss';
import useToast from '../../../customHooks/useToast';
import {
  UPDATE_BANK_DETAILS,
  BANK_VERIFICATION,
} from '../../../graphql/mutations';
import { GET_BANKS } from '../../../graphql/queries';
import useCustomQuery from '../../../network/useCustomQuery';
import CheckboxNew from '../../../components/CheckboxNew';
import TextInput from '../../../components/input';
import ModalFormComp from '../../../components/ModalFormComp';
import SelectSearch from '../../../components/SelectSearch';

export interface IBank {
  accountName: string;
  accountNumber: string;
  bankId: string;
  alias: string;
  id: string;
  isPrimary: string;
}

export interface IEditNewAccount {
  label?: string;
  closeModal?: any;
  refetch?: any;
  payload?: IBank;
}

const EditNewAccount: FunctionComponent<IEditNewAccount> = ({
  closeModal,
  refetch,
  payload,
}) => {
  const Toast = useToast();
  const {
    formValues, handleChange, setError, checkAllRequired, resetForm,
  } = useForm(
    {
      accountName: '',
      accountNumber: '',
      bankId: '',
      alias: '',
      isDefault: '',
    },
    {},
    Toast,
  );
  const [loading, setLoading] = useState(false);
  const [shouldResolveAccount, setShouldResolveAccount] = useState(false);

  const [getBanks, getBanksLoading, banks] = useCustomQuery({
    query: GET_BANKS,
    onError: () => {},
    onData: () => {},
  });
  const [accountNameLoading, setAccountNameLoading] = useState(false);
  const [getAccountName] = useMutation<
    { createSaving: any; getTransBen: any },
    any
  >(BANK_VERIFICATION);

  useEffect(() => {
    getBanks();
  }, []);
  // convert string to sentence case
  const bankList = banks?.banks?.map?.((data: { name: string; nipCode: string }) => ({
    label: data.name,
    text: data.name,
    value: data.nipCode,
    ...data,
  })) || [];

  const [editAccountDetails] = useMutation<
    { createSaving: any; updateMerchantBankDetail: any },
    any
  >(UPDATE_BANK_DETAILS);

  useEffect(() => {
    if (payload?.accountNumber) {
      resetForm({
        accountName: payload?.accountName,
        accountNumber: payload?.accountNumber,
        bankId: payload?.bankId,
        alias: payload?.alias,
        isDefault: !!payload?.isPrimary,
      });
    }
  }, [payload]);

  const handleSubmit = async () => {
    setLoading(true);

    try {
      setError('');
      if (!checkAllRequired(['isDefault'])) {
        setLoading(false);
        return;
      }
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { ...needful } = formValues;
      const resp = await editAccountDetails({
        variables: { ...needful, id: payload?.id, setAsPrimary: formValues.isDefault },
      });
      setLoading(false);
      if (resp?.data?.updateMerchantBankDetail?.status !== 200) {
        throw new Error(
          resp?.data?.updateMerchantBankDetail?.message || 'Operation failed',
        );
      }

      Toast.success('Account Updated successfully');
      refetch();
      closeModal();
    } catch (e) {
      setLoading(false);
      if (e && e.graphQLErrors && e.graphQLErrors.length > 0) {
        Toast.error(e.graphQLErrors[0].message);
      } else {
        Toast.error(e.message || 'Operation failed');
      }
    }
  };

  const resolveAccountDetails = async (
    accountNumber: string,
    bankCode: string,
  ) => {
    try {
      setShouldResolveAccount(false);
      setAccountNameLoading(true);
      const payload: {
        transferType: string;
        accountNo: string;
        bankCode: string;
      } = {
        accountNo: accountNumber,
        bankCode,
        transferType: bankCode === '090110' ? 'intra' : 'inter',
      };
      const resp = await getAccountName({ variables: payload });

      setAccountNameLoading(false);
      if (resp?.data?.getTransBen?.name) {
        // set success message
        Toast.success('Account Name Resolved');
        resetForm({
          accountName: resp?.data?.getTransBen?.name,
        });
      } else {
        Toast.error('Account name could not be resolved');
        handleChange({ target: { value: '', name: 'accountName' } });
      }
    } catch (e) {
      setAccountNameLoading(false);
      handleChange({ target: { value: '', name: 'accountName' } });
    }
  };

  useEffect(() => {
    if (
      !Number.isNaN(Number(formValues?.accountNumber))
      && formValues?.accountNumber?.length === 10
      && formValues?.bankId?.length >= 6
      && shouldResolveAccount
      && !accountNameLoading
    ) {
      resolveAccountDetails(formValues?.accountNumber, formValues?.bankId);
    }
  }, [
    formValues?.accountNumber,
    formValues?.bankId,
    bankList,
    shouldResolveAccount,
  ]);

  const isDefault = !!payload?.isPrimary;

  return (
    <ModalFormComp
      title={`Edit Bank Details ${isDefault ? '(Default)' : ''}`}
      btnAction={handleSubmit}
      btnLoading={loading || getBanksLoading}
      btnName="Save"
      closeModal={closeModal}
    >
      <div className="">
        <div style={{ marginBottom: '20px' }}>
          <SelectSearch
            onChange={(e) => {
              setShouldResolveAccount(true);
              handleChange(e);
            }}
            placeholder="Bank name"
            name="bankId"
            value={formValues?.bankId}
            options={bankList}
            required={false}
            label={false}
            classes={false}
            hideLabel={false}
          />
        </div>
        <TextInput
          name="accountNumber"
          value={formValues?.accountNumber}
          customOnChange={(e) => {
            setShouldResolveAccount(true);
            handleChange(e);
          }}
          label="Account Number"
          width="100%"
        />
        <TextInput
          label={
            accountNameLoading ? 'Resolving Account Name...' : 'Account Name'
          }
          name="accountName"
          value={formValues?.accountName}
          customOnChange={handleChange}
          width="100%"
          disabled
        />
        <TextInput
          label="Alias"
          name="alias"
          value={formValues?.alias}
          customOnChange={handleChange}
          width="100%"
        />
        <CheckboxNew
          id="status"
          onChange={(e) => {
            handleChange({
              target: {
                name: 'isDefault',
                value: e.target.checked,
              },
            });
          }}
          name="isDefault"
          value={!!formValues?.isDefault}
          loading={loading}
          onClick={() => {}}
        >
          Set As Default Bank
        </CheckboxNew>
      </div>
    </ModalFormComp>
  );
};

export default EditNewAccount;
