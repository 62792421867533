import React from 'react';

const LightBulb = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.5113 8.15617C13.5113 5.50145 11.2123 3.38363 8.49685 3.68485C6.43135 3.91379 4.7641 5.58582 4.53882 7.6516C4.33998 9.47523 5.23379 11.1042 6.6451 11.9786C6.89569 12.1339 7.05179 12.4025 7.05179 12.6972C7.05179 13.1121 7.05179 14.2956 7.05179 14.9064C7.05179 15.9938 7.93323 16.8749 9.02054 16.8749C10.1079 16.8749 10.9893 15.9935 10.9893 14.9062V12.6865C10.9893 12.3926 11.1451 12.124 11.3943 11.9679C12.6647 11.1731 13.5113 9.7652 13.5113 8.15617Z" fill="#F9AC2F" />
    <path d="M9.02078 17.0153C7.85753 17.0153 6.9114 16.0692 6.9114 14.906V12.6967C6.9114 12.4537 6.78428 12.2299 6.57109 12.0977C5.0349 11.1462 4.20268 9.43677 4.399 7.63592C4.63159 5.5018 6.34862 3.78111 8.48134 3.54458C9.82206 3.3958 11.1091 3.80502 12.1058 4.69742C13.0885 5.57717 13.6521 6.83774 13.6521 8.15596C13.6521 9.76217 12.8362 11.2317 11.4693 12.087C11.257 12.22 11.1302 12.4442 11.1302 12.6863V14.906C11.1302 16.0692 10.184 17.0153 9.02078 17.0153ZM9.0174 3.7963C8.85006 3.7963 8.68187 3.80558 8.512 3.82442C6.5095 4.04661 4.89709 5.66239 4.67856 7.66658C4.49406 9.35858 5.27593 10.9648 6.71931 11.8586C7.01575 12.0426 7.19265 12.3559 7.19265 12.6967V14.906C7.19265 15.914 8.01278 16.7341 9.02078 16.7341C10.0288 16.7341 10.8489 15.914 10.8489 14.906V12.6863C10.8489 12.3466 11.025 12.0333 11.32 11.8485C12.6042 11.045 13.3709 9.66458 13.3709 8.15596C13.3709 6.91761 12.8413 5.73355 11.9182 4.90696C11.1079 4.18189 10.0932 3.7963 9.0174 3.7963Z" fill="#212A41" />
    <path d="M13.5113 8.15617C13.5113 5.50145 11.2123 3.38363 8.49685 3.68485C6.43135 3.91379 4.7641 5.58582 4.53882 7.6516C4.33998 9.47523 5.23379 11.1042 6.6451 11.9786C6.89569 12.1339 7.05179 12.4025 7.05179 12.6972C7.05179 13.1121 7.05179 14.2956 7.05179 14.9064C7.05179 15.9938 7.93323 16.8749 9.02054 16.8749C10.1079 16.8749 10.9893 15.9935 10.9893 14.9062V12.6865C10.9893 12.3926 11.1451 12.124 11.3943 11.9679C12.6647 11.1731 13.5113 9.7652 13.5113 8.15617Z" fill="#F9AC2F" />
    <path d="M9.02078 17.0153C7.85753 17.0153 6.9114 16.0692 6.9114 14.906V12.6967C6.9114 12.4537 6.78428 12.2299 6.57109 12.0977C5.0349 11.1462 4.20268 9.43677 4.399 7.63592C4.63159 5.5018 6.34862 3.78111 8.48134 3.54458C9.82206 3.3958 11.1091 3.80502 12.1058 4.69742C13.0885 5.57717 13.6521 6.83774 13.6521 8.15596C13.6521 9.76217 12.8362 11.2317 11.4693 12.087C11.257 12.22 11.1302 12.4442 11.1302 12.6863V14.906C11.1302 16.0692 10.184 17.0153 9.02078 17.0153ZM9.0174 3.7963C8.85006 3.7963 8.68187 3.80558 8.512 3.82442C6.5095 4.04661 4.89709 5.66239 4.67856 7.66658C4.49406 9.35858 5.27593 10.9648 6.71931 11.8586C7.01575 12.0426 7.19265 12.3559 7.19265 12.6967V14.906C7.19265 15.914 8.01278 16.7341 9.02078 16.7341C10.0288 16.7341 10.8489 15.914 10.8489 14.906V12.6863C10.8489 12.3466 11.025 12.0333 11.32 11.8485C12.6042 11.045 13.3709 9.66458 13.3709 8.15596C13.3709 6.91761 12.8413 5.73355 11.9182 4.90696C11.1079 4.18189 10.0932 3.7963 9.0174 3.7963Z" fill="#212A41" />
    <path d="M8.73929 14.906C8.73929 14.2951 8.73929 13.1116 8.73929 12.6967C8.73929 12.402 8.58291 12.1334 8.3326 11.9782C6.92129 11.1037 6.02748 9.47475 6.22632 7.65112C6.43894 5.70066 7.93773 4.10287 9.84291 3.73753C9.41204 3.65681 8.96148 3.63291 8.49685 3.68437C6.43135 3.91331 4.7641 5.58534 4.53882 7.65112C4.33998 9.47475 5.23379 11.1037 6.6451 11.9782C6.89569 12.1334 7.05179 12.402 7.05179 12.6967C7.05179 13.1116 7.05179 14.2951 7.05179 14.906C7.05179 15.9933 7.93323 16.8744 9.02054 16.8744C9.32288 16.8744 9.60835 16.8041 9.86429 16.6823C9.19998 16.3662 8.73929 15.6907 8.73929 14.906Z" fill="#DB8E11" />
    <path d="M9.02078 17.0152C7.85753 17.0152 6.9114 16.0691 6.9114 14.9059V12.6967C6.9114 12.4537 6.78428 12.2295 6.57109 12.0976C5.0349 11.1461 4.20268 9.43669 4.399 7.63584C4.63159 5.50172 6.34862 3.78103 8.48134 3.5445C8.93837 3.49387 9.40496 3.51215 9.86903 3.59934C9.9354 3.61172 9.9835 3.66965 9.98378 3.73715C9.98406 3.80465 9.93596 3.86287 9.86959 3.87553C8.01165 4.23159 6.57081 5.79056 6.36634 7.66622C6.18184 9.35822 6.96371 10.9644 8.40709 11.8582C8.70353 12.0422 8.88043 12.3555 8.88043 12.6964V14.9059C8.88043 15.6059 9.2905 16.2536 9.92528 16.5554C9.97421 16.5787 10.0054 16.6282 10.0054 16.6825C10.0054 16.7368 9.97421 16.7863 9.92528 16.8097C9.6384 16.9461 9.33381 17.0152 9.02078 17.0152ZM8.98731 3.79818C8.82812 3.79818 8.6695 3.8069 8.51228 3.82434C6.50978 4.04653 4.89737 5.66231 4.67884 7.6665C4.49434 9.3585 5.27621 10.9647 6.71959 11.8585C7.01603 12.0425 7.19293 12.3558 7.19293 12.6967V14.9059C7.19293 15.9139 8.01306 16.734 9.02106 16.734C9.1974 16.734 9.37037 16.7087 9.53856 16.6583C8.95975 16.2705 8.59918 15.6115 8.59918 14.9062V12.6969C8.59918 12.4539 8.47206 12.2298 8.25887 12.0979C6.72268 11.1464 5.89046 9.43697 6.08678 7.63612C6.27746 5.88787 7.47587 4.39837 9.09868 3.79959C9.06156 3.79847 9.02443 3.79818 8.98731 3.79818Z" fill="#212A41" />
    <path d="M9.021 16.875C7.93368 16.875 7.05225 15.9936 7.05225 14.9062V12.9375H10.9897V14.9062C10.9897 15.9936 10.1083 16.875 9.021 16.875Z" fill="#E0E0E0" />
    <path d="M9.021 17.0156C7.85775 17.0156 6.91162 16.0695 6.91162 14.9062V12.9375C6.91162 12.8599 6.97462 12.7969 7.05225 12.7969H10.9897C11.0674 12.7969 11.1304 12.8599 11.1304 12.9375V14.9062C11.1304 16.0695 10.1842 17.0156 9.021 17.0156ZM7.19287 13.0781V14.9062C7.19287 15.9143 8.013 16.7344 9.021 16.7344C10.029 16.7344 10.8491 15.9143 10.8491 14.9062V13.0781H7.19287Z" fill="#212A41" />
    <path d="M8.73975 14.9062V12.9375H7.05225V14.9062C7.05225 15.9936 7.93368 16.875 9.021 16.875C9.32334 16.875 9.60881 16.8047 9.86475 16.6829C9.20043 16.3668 8.73975 15.6912 8.73975 14.9062Z" fill="#C2C2C2" />
    <path d="M9.021 17.0156C7.85775 17.0156 6.91162 16.0695 6.91162 14.9062V12.9375C6.91162 12.8599 6.97462 12.7969 7.05225 12.7969H8.73975C8.81737 12.7969 8.88037 12.8599 8.88037 12.9375V14.9062C8.88037 15.6063 9.29043 16.254 9.92522 16.5561C9.97415 16.5794 10.0054 16.6289 10.0054 16.6832C10.0054 16.7375 9.97415 16.787 9.92522 16.8103C9.63862 16.9464 9.33403 17.0156 9.021 17.0156ZM7.19287 13.0781V14.9062C7.19287 15.9143 8.013 16.7344 9.021 16.7344C9.19734 16.7344 9.37031 16.7091 9.5385 16.6587C8.95968 16.2709 8.59912 15.6116 8.59912 14.9062V13.0781H7.19287Z" fill="#212A41" />
    <path d="M8.55721 16.3744C8.54175 16.3744 8.52628 16.3718 8.51081 16.3665C7.89093 16.1494 7.47412 15.5624 7.47412 14.9062V13.5C7.47412 13.4224 7.53712 13.3594 7.61475 13.3594C7.69237 13.3594 7.75537 13.4224 7.75537 13.5V14.9062C7.75537 15.4432 8.09625 15.9235 8.60362 16.1013C8.67703 16.1269 8.71556 16.207 8.68996 16.2804C8.66943 16.3381 8.61515 16.3744 8.55721 16.3744Z" fill="#E0E0E0" />
    <path d="M9.43359 1.40625V2.53125C9.43359 2.6865 9.30759 2.8125 9.15234 2.8125H8.87109C8.71584 2.8125 8.58984 2.6865 8.58984 2.53125V1.40625C8.58984 1.251 8.71584 1.125 8.87109 1.125H9.15234C9.30759 1.125 9.43359 1.251 9.43359 1.40625Z" fill="#B7C0D7" />
    <path d="M9.15234 2.95312H8.87109C8.6385 2.95312 8.44922 2.76384 8.44922 2.53125V1.40625C8.44922 1.17366 8.6385 0.984375 8.87109 0.984375H9.15234C9.38494 0.984375 9.57422 1.17366 9.57422 1.40625V2.53125C9.57422 2.76384 9.38494 2.95312 9.15234 2.95312ZM8.87109 1.26562C8.79375 1.26562 8.73047 1.32862 8.73047 1.40625V2.53125C8.73047 2.60888 8.79375 2.67188 8.87109 2.67188H9.15234C9.22969 2.67188 9.29297 2.60888 9.29297 2.53125V1.40625C9.29297 1.32862 9.22969 1.26562 9.15234 1.26562H8.87109Z" fill="#212A41" />
    <path d="M4.59953 4.47731L3.80416 3.68194C3.69419 3.57197 3.69419 3.39394 3.80416 3.28425L4.003 3.08541C4.11297 2.97544 4.291 2.97544 4.40069 3.08541L5.19606 3.88078C5.30603 3.99075 5.30603 4.16878 5.19606 4.27847L4.99722 4.47731C4.88753 4.587 4.7095 4.587 4.59953 4.47731Z" fill="#B7C0D7" />
    <path d="M4.79852 4.7002C4.68602 4.7002 4.57998 4.65633 4.50011 4.57673L3.70445 3.78136C3.53992 3.61683 3.53992 3.34908 3.70445 3.18455L3.9033 2.9857C4.06783 2.82145 4.33502 2.82089 4.50011 2.9857L5.29548 3.78136C5.37508 3.86095 5.41895 3.96698 5.41895 4.07977C5.41895 4.19227 5.37508 4.2983 5.29548 4.37817L5.09664 4.57702C5.01733 4.65633 4.9113 4.7002 4.79852 4.7002ZM4.20198 3.14348C4.1657 3.14348 4.1297 3.15727 4.10242 3.18455L3.90358 3.38339C3.84873 3.43823 3.84873 3.52739 3.90358 3.58252L4.69923 4.37789C4.75408 4.43273 4.84323 4.43273 4.89808 4.37789L5.09692 4.17905C5.12364 4.15233 5.13798 4.11717 5.13798 4.07948C5.13798 4.0418 5.12336 4.00664 5.09692 3.9802L4.30155 3.18455C4.27398 3.15727 4.23798 3.14348 4.20198 3.14348Z" fill="#212A41" />
    <path d="M14.2283 3.68194L13.4329 4.47731C13.3229 4.58728 13.1449 4.58728 13.0352 4.47731L12.8364 4.27847C12.7264 4.1685 12.7264 3.99047 12.8364 3.88078L13.6318 3.08541C13.7417 2.97544 13.9198 2.97544 14.0294 3.08541L14.2283 3.28425C14.3383 3.39394 14.3383 3.57225 14.2283 3.68194Z" fill="#B7C0D7" />
    <path d="M13.234 4.7002C13.1215 4.7002 13.0155 4.65633 12.9356 4.57673L12.7367 4.37789C12.6572 4.2983 12.6133 4.19227 12.6133 4.07948C12.6133 3.9667 12.6572 3.86067 12.7367 3.78108L13.5321 2.9857C13.6964 2.82117 13.9644 2.82117 14.1287 2.9857L14.3275 3.18455C14.492 3.34908 14.492 3.61683 14.3275 3.78136L13.5321 4.57673C13.4525 4.65633 13.3465 4.7002 13.234 4.7002ZM13.8305 3.14348C13.7945 3.14348 13.7585 3.15698 13.731 3.18455L12.9356 3.9802C12.9089 4.00664 12.8945 4.04208 12.8945 4.07977C12.8945 4.11717 12.9092 4.15261 12.9356 4.17933L13.1344 4.37817C13.1893 4.43302 13.2784 4.43302 13.3333 4.37817L14.1287 3.5828C14.1835 3.52795 14.1835 3.4388 14.1287 3.38367L13.9298 3.18483C13.9025 3.15727 13.8665 3.14348 13.8305 3.14348Z" fill="#212A41" />
    <path d="M15.7617 8.57812H14.6367C14.4815 8.57812 14.3555 8.45213 14.3555 8.29688V8.01562C14.3555 7.86038 14.4815 7.73438 14.6367 7.73438H15.7617C15.917 7.73438 16.043 7.86038 16.043 8.01562V8.29688C16.043 8.45213 15.917 8.57812 15.7617 8.57812Z" fill="#B7C0D7" />
    <path d="M15.7617 8.71875H14.6367C14.4041 8.71875 14.2148 8.52947 14.2148 8.29688V8.01562C14.2148 7.78303 14.4041 7.59375 14.6367 7.59375H15.7617C15.9943 7.59375 16.1836 7.78303 16.1836 8.01562V8.29688C16.1836 8.52947 15.9943 8.71875 15.7617 8.71875ZM14.6367 7.875C14.5594 7.875 14.4961 7.938 14.4961 8.01562V8.29688C14.4961 8.3745 14.5594 8.4375 14.6367 8.4375H15.7617C15.8391 8.4375 15.9023 8.3745 15.9023 8.29688V8.01562C15.9023 7.938 15.8391 7.875 15.7617 7.875H14.6367Z" fill="#212A41" />
    <path d="M3.38672 8.57812H2.26172C2.10647 8.57812 1.98047 8.45213 1.98047 8.29688V8.01562C1.98047 7.86038 2.10647 7.73438 2.26172 7.73438H3.38672C3.54197 7.73438 3.66797 7.86038 3.66797 8.01562V8.29688C3.66797 8.45213 3.54197 8.57812 3.38672 8.57812Z" fill="#B7C0D7" />
    <path d="M3.38672 8.71875H2.26172C2.02913 8.71875 1.83984 8.52947 1.83984 8.29688V8.01562C1.83984 7.78303 2.02913 7.59375 2.26172 7.59375H3.38672C3.61931 7.59375 3.80859 7.78303 3.80859 8.01562V8.29688C3.80859 8.52947 3.61931 8.71875 3.38672 8.71875ZM2.26172 7.875C2.18438 7.875 2.12109 7.938 2.12109 8.01562V8.29688C2.12109 8.3745 2.18438 8.4375 2.26172 8.4375H3.38672C3.46406 8.4375 3.52734 8.3745 3.52734 8.29688V8.01562C3.52734 7.938 3.46406 7.875 3.38672 7.875H2.26172Z" fill="#212A41" />
    <path d="M11.271 15.3281H6.771C6.69337 15.3281 6.63037 15.2651 6.63037 15.1875C6.63037 15.1099 6.69337 15.0469 6.771 15.0469H11.271C11.3486 15.0469 11.4116 15.1099 11.4116 15.1875C11.4116 15.2651 11.3489 15.3281 11.271 15.3281Z" fill="#212A41" />
    <path d="M11.0967 11.6318C11.05 11.6318 11.0041 11.6084 10.9774 11.5657C10.9363 11.4999 10.9563 11.4133 11.0218 11.3719C12.1407 10.6719 12.8083 9.46982 12.8083 8.15638C12.8083 7.07779 12.3471 6.04616 11.543 5.32645C11.4851 5.2747 11.48 5.18582 11.5317 5.12788C11.5843 5.06995 11.6732 5.06517 11.7303 5.11692C12.594 5.89007 13.0896 6.99792 13.0896 8.15638C13.0896 9.5677 12.3724 10.8586 11.1715 11.6104C11.1481 11.625 11.1223 11.6318 11.0967 11.6318Z" fill="#DB8E11" />
  </svg>

);

export default LightBulb;
