import {
  FunctionComponent, useEffect, useMemo, useState,
} from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import './PaymentLinkDetail.scss';
import { useHistory, useParams } from 'react-router-dom';
import DashboardWrap from '../../components/DashboardWrap';
import { GET_PAYMENT_DETAILS } from '../../graphql/queries';
import useCustomQuery from '../../network/useCustomQuery';
import AddPaymentLinks from '../PaymentLinks/AddNewLink';
import CopyIcon from '../../svgs/CopyIcon';
import { parseJSON } from '../../utils/helpers';
import { formatCash } from '../../utils/tableHelpers';
import useToast from '../../customHooks/useToast';
// import Buttons from '../../components/Buttons';
import ActivateType from '../../components/PaymentLinkSmallModal/ActivateType';
import DeactivateType from '../../components/PaymentLinkSmallModal/DeativateType';
import DeactivateTypeAndComment from '../../components/PaymentLinkSmallModal/DeativateTypeAndComment';
import PaymentLinkSmallModal from '../../components/PaymentLinkSmallModal';
import DeleteType from '../../components/PaymentLinkSmallModal/DeleteType';
import LinkBackComponent from '../../components/LinkBackComponent';
import PaymentInfo from './PaymentInfo';
// import MoreDetails from './MoreDetails';
import LinkDeleteIcon from '../../svgs/LinkDeleteIcon';
import LinkEditIcon from '../../svgs/LinkEditIcon';
import Buttons from '../../components/Buttons';
import PaymentHistory from './PaymentHistory';
import PaymentLinkImageCarousel from './PaymentImageCarousel';
import StyledButton from '../../components/StyledButton';
import DeactivatePaymentLinkIcon from '../../svgs/DeactivatePaymentLinkIcon';
import ActivatePaymentLinkIcon from '../../svgs/ActivatePaymentLinkIcon';
import EmptyPaymentLinkIMageIcon from '../../svgs/EmptyPaymentLinkIMageIcon';

const PaymentLinkDetail: FunctionComponent = () => {
  const Toast = useToast();
  const { paymentLinkName } = useParams<{ paymentLinkName: string }>();
  const { push } = useHistory();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [getPaymentDetails, loading, payment] = useCustomQuery({
    query: GET_PAYMENT_DETAILS,
    onError: () => { },
    onData: () => { },
  });

  const [showActivate, setShowActivate] = useState(false);
  const [showSuccess, setShowSuccess] = useState<boolean | string>(false);
  const [showDeactivate, setShowDeactivate] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDeactivateAndComment, setShowDeactivateAndComment] = useState(false);

  const getSinglePaymentLinkApi = () => {
    getPaymentDetails({
      variables: {
        name: paymentLinkName,
      },
    });
  };

  useEffect(() => {
    getSinglePaymentLinkApi();
  }, []);

  const detail = useMemo(() => {
    if (!payment?.getPaymentLink) {
      return payment?.getPaymentLink;
    }
    const payConfigObj = parseJSON(payment?.getPaymentLink?.configurations) || {};
    const rec = { ...payment?.getPaymentLink, type: 'Single Charge', ...payConfigObj };
    rec.oldAmount = rec.amount;
    rec.amount = formatCash(rec.amount, '₦');

    rec.createdAt = new Date(rec.createdAt).toDateString();

    return rec;
  }, [payment]);

  const [showModal, setShowModal] = useState(false);

  const link = `${process.env.REACT_APP_PAYFI_WEBSITE_URL}/pay/${detail?.name}`;

  const CustomTextComp = () => (
    <div className="copy-link">
      <p>{link}</p>
      <CopyToClipboard onCopy={() => Toast.success('Copied to clipboard')} text={link}><div className="icon"><CopyIcon /></div></CopyToClipboard>
    </div>
  );

  const StatusComp = () => (
    <div className="flexed-center">
      {
        !detail?.status && (
        <div className={`status-comp ${detail?.status ? 'active-sty' : 'inactive-sty'}`}>
          {/* <div className="status-circle" /> */}

          <span>Inactive</span>

        </div>
        )
      }

    </div>
  );

  const ActionButtons = () => (
    <div className="action-buttons">
      <Buttons
        name="Edit Link"
        className="btn-md bg-white text-grey"
        Icon={LinkEditIcon}
        onClick={() => push(`/edit-payment-link/${paymentLinkName}`)}
      />
      <Buttons
        name="Delete Link"
        className="btn-md bg-white text-red"
        Icon={LinkDeleteIcon}
        onClick={() => setShowDeleteModal(true)}
      />
      {
        detail?.status ? (
          <StyledButton
            name={<span className="deactivate_text">Deactivate</span>}
            backgroundColor="white"
            color="rgba(240, 68, 56, 1)"
            border="1px solid rgba(242, 244, 247, 1)"
            padding="10px"
            fontSize="12px"
            icon={<DeactivatePaymentLinkIcon />}
            onClick={() => setShowDeactivate(true)}

          />
        ) : (
          <StyledButton
            name={<span className="activate_text">Activate</span>}
            backgroundColor="white"
            color="rgba(18, 183, 106, 1)"
            border="1px solid rgba(242, 244, 247, 1)"
            padding="10px"
            fontSize="12px"
            icon={<ActivatePaymentLinkIcon />}
            onClick={() => setShowActivate(true)}

          />
        )
      }

    </div>
  );

  return (
    <div className="payment-link-details-wrap payment-links">
      <DashboardWrap>
        <div className="single-payment-link-detail">
          <header>
            <div className="title-cov">
              <LinkBackComponent
                link="/payment-links"
                text="Payment Links"
              />
              <div className="payment-title flexed-between">
                <div className="sect-1 flexed-row">
                  <h3>
                    {detail?.title}
                  </h3>
                  <CustomTextComp />
                  <StatusComp />
                </div>
                <ActionButtons />
              </div>
            </div>
          </header>
          <section>
            <PaymentInfo payment={detail} />
            {/* <MoreDetails
              onClick={() => (detail?.status ? setShowDeactivate(true) : setShowActivate(true))}
              payment={detail}
            /> */}
            <div className="payment_link_image_list_cover">
              {
                !detail?.images?.length
                  ? (
                    <div className="payment_link_image_empty_cover">
                      <EmptyPaymentLinkIMageIcon />
                      <div className="payment_link_image_empty_content">There are no images for this product</div>
                    </div>
                  )
                  : <PaymentLinkImageCarousel images={detail?.images} />
              }

            </div>
          </section>
        </div>
        {paymentLinkName && <PaymentHistory paymentLinkName={paymentLinkName} />}
        {showModal && (
        <AddPaymentLinks
          closeModal={() => setShowModal(false)}
          refetch={getSinglePaymentLinkApi}
          payload={detail}
        />
        )}
        { showSuccess && (
        <PaymentLinkSmallModal
          closeModal={() => setShowSuccess(false)}
          payload={detail}
          activateType={showSuccess === 'activate'}
        />
        )}
        {showActivate && (
        <ActivateType
          closeModal={() => setShowActivate(false)}
          payload={detail}
          onActivateClick={() => {
            setShowSuccess('activate');
            setShowActivate(false);
            getSinglePaymentLinkApi();
          }}
        />
        )}
        {showDeactivate && (
        <DeactivateType
          closeModal={() => setShowDeactivate(false)}
          payload={detail}
          onDeactivateClick={() => {
            setShowDeactivateAndComment(true);
            setShowDeactivate(false);
          }}
        />
        )}
        {showDeleteModal && (
        <DeleteType
          closeModal={() => setShowDeleteModal(false)}
          payload={detail}
        />
        )}
        {showDeactivateAndComment && (
        <DeactivateTypeAndComment
          closeModal={() => setShowDeactivateAndComment(false)}
          payload={detail}
          refetch={getSinglePaymentLinkApi}
        />
        )}
      </DashboardWrap>
    </div>
  );
};

export default PaymentLinkDetail;
