import React from 'react';

const RefreshIcon = () => (
  <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_6090_32850)">
      <path d="M11.5 2.5V5.5H8.5" stroke="#12B76A" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M0.5 10.5V7.5H3.5" stroke="#12B76A" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M1.755 4.99992C2.00858 4.28331 2.43957 3.64262 3.00773 3.13762C3.5759 2.63263 4.26273 2.2798 5.00414 2.11204C5.74555 1.94429 6.51738 1.96709 7.2476 2.1783C7.97781 2.38952 8.64263 2.78227 9.18 3.31992L11.5 5.49992M0.5 7.49992L2.82 9.67992C3.35737 10.2176 4.02219 10.6103 4.7524 10.8215C5.48262 11.0327 6.25445 11.0555 6.99586 10.8878C7.73727 10.72 8.4241 10.3672 8.99227 9.86221C9.56043 9.35721 9.99142 8.71652 10.245 7.99992" stroke="#12B76A" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_6090_32850">
        <rect width="12" height="12" fill="white" transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </svg>

);
export default RefreshIcon;
