import { useMutation } from '@apollo/client';
import { FunctionComponent, useEffect, useState } from 'react';
import {
  useGlobalState,
} from '../../../contexts';
import useForm from '../../../customHooks/useForm';
// import './AddTeams.scss';
import useToast from '../../../customHooks/useToast';
import { ADD_USER } from '../../../graphql/mutations';
import { GET_ROLES } from '../../../graphql/queries';
import useCustomQuery from '../../../network/useCustomQuery';
import TextInput from '../../../components/input';
import ModalFormComp from '../../../components/ModalFormComp';
import SelectInput from '../../../components/select';

export interface IAddTeams {
  label?: string;
  closeModal?: any;
  refetch?: any;
  payload?: any;
  isUpdateType?: any;
}

const AddTeams: FunctionComponent<IAddTeams> = ({
  closeModal,
  refetch,
}) => {
  const Toast = useToast();
  const {
    formValues,
    handleChange,
    setError,
    checkAllRequired,
    validateInputs,
    resetForm,
  } = useForm(
    {
      email: '',
      name: '',
      roleId: '',
    },
    {
      phone: {
        pattern: /^[6-9]\d{9}$/,
        errorMessage: 'Put a valid Nigerian phonenumber. Format 7052648321',
        smallFormatter: (rec) => `${Number(rec)}`,
      },
    },
    Toast,
  );
  const [loading, setLoading] = useState(false);
  const { profile } = useGlobalState();
  const [addNewTeam] = useMutation<
    { createSaving: any; createMerchantUser: any },
    any
  >(ADD_USER);
  const isMerchantAdmin = profile?.role?.name === 'merchant-admin';

  const handleSubmit = async () => {
    setLoading(true);
    setError('');
    if (!checkAllRequired() || !validateInputs()) {
      setLoading(false);
      return;
    }

    try {
      const splittedName = formValues.name.split(' ');
      const firstName = splittedName[0];
      const lastName = splittedName[1] || 'N/A';
      const payload = {
        firstName,
        lastName,
        email: formValues.email,
        roleId: formValues.roleId,
        phone: '000000',
      };
      const resp = await addNewTeam({
        variables: payload,
      });

      setLoading(false);
      if (resp?.data?.createMerchantUser) {
        // set success message
        Toast.success('User Added successfully');
        resetForm({});
        refetch();
        closeModal();
      } else {
        Toast.error(
          resp?.data?.createMerchantUser?.message || 'Operation failed',
        );
      }
    } catch (e) {
      setLoading(false);
      if (e && e.graphQLErrors && e.graphQLErrors.length > 0) {
        Toast.error(e.graphQLErrors[0].message);
      } else {
        Toast.error('Something went wrong');
      }
    }
  };

  const [getRoles, getRolesLoading, roles] = useCustomQuery({
    query: GET_ROLES,
    onError: () => {},
    onData: () => {},
  });

  useEffect(() => {
    // setStep(5);
    if (isMerchantAdmin) getRoles();
  }, []);

  const roleList = roles?.roles?.map?.((data: { name: string; id: string }) => ({
    text: data.name,
    value: data.id,
  }));

  const getAllRequired = () => !!(
    formValues?.email
      && formValues?.name
  );

  return (
    <ModalFormComp
      title="Add Team Member"
      subTitle="Input the details of the new team member"
      btnAction={handleSubmit}
      btnLoading={loading || getRolesLoading}
      btnDisabled={!getAllRequired()}
      btnName={
        getRolesLoading && isMerchantAdmin
          ? 'Fetching Roles...'
          : loading
            ? 'Saving...'
            : 'Add Member'
      }
      closeModal={closeModal}
      hasCancel
    >
      <div className="">
        <TextInput
          label="Team Member’s Name"
          name="name"
          value={formValues?.name}
          customOnChange={handleChange}
          width="100%"
        />
        <TextInput
          label="Team Member’s Email"
          name="email"
          value={formValues?.email}
          customOnChange={handleChange}
          width="100%"
        />
        {/* <TextInput
        type="date"
        label='Date of Birth'
        name='dob'
        value={formValues?.dob}
        customOnChange={handleChange}
        width='100%'
        defaultValue={yearsAgo}
      /> */}

        <SelectInput
          label="Role"
          width="100%"
          options={roleList}
          name="roleId"
          value={formValues?.roleId}
          customOnChange={handleChange}
        />

      </div>
    </ModalFormComp>
  );
};

export default AddTeams;
