import { gql } from '@apollo/client';

export const ADMIN_LOGIN_MUTATION = gql`
  mutation adminLogin($data: String!) {
    adminLogin(data: $data) {
      status
      message
      token
      user {
        id
        email
        phone
        firstName
        lastName
        imageUrl
        role {
          name
          permissions {
            id
            name
          }
        }
      }
    }
  }
`;

export const ADMIN_APPROVE_DOCUMENT = gql`
  mutation approveDocument($id: ID!) {
    approveDocument(id: $id) {
      status
      message
    }
  }
`;

export const ADMIN_REJECT_DOCUMENT = gql`
  mutation rejectDocument($id: ID!, $message: String!) {
    rejectDocument(id: $id, message: $message) {
      status
      message
    }
  }
`;

export const ADMIN_APPROVE_NYSC_CUSTOMER = gql`
  mutation approveNyscCustomer($userId: ID!) {
    approveNyscCustomer(userId: $userId) {
      status
      message
    }
  }
`;

export const ADMIN_REJECT_NYSC_CUSTOMER = gql`
  mutation rejectNyscCustomer($userId: ID!, $reason: String!) {
    rejectNyscCustomer(userId: $userId, reason: $reason) {
      status
      message
    }
  }
`;

export const ADMIN_MODIFY_MERCHANT = gql`
  mutation disableEnableMerchant($id: ID!) {
    disableEnableMerchant(id: $id) {
      isApproved
    }
  }
`;

export const ADMIN_ACTIVATE_MERCHANT = gql`
  mutation activateMerchant($id: ID!, $comment: String!) {
    activateMerchant(id: $id, comment: $comment) {
      name
      email
    }
  }
`;

export const ADMIN_ACTIVATE_CUSTOMER = gql`
  mutation activateUser($id: ID!, $comment: String!) {
    activateUser(id: $id, comment: $comment) {
      message
      status
    }
  }
`;

export const ADMIN_DECLINE_MERCHANT = gql`
  mutation declineMerchant($id: ID!, $comment: String!) {
    declineMerchant(id: $id, comment: $comment) {
      status
      message
    }
  }
`;

export const ADMIN_DECLINE_CUSTOMER = gql`
  mutation DeclineUser($id: ID!, $comment: String!) {
    declineUser(id: $id, comment: $comment) {
      status
      message
    }
  }
`;

export const ADMIN_DEACTIVATE_MERCHANT = gql`
  mutation deactivateMerchant($id: ID!, $comment: String!) {
    deactivateMerchant(id: $id, comment: $comment) {
      name
      email
    }
  }
`;

export const ADMIN_DEACTIVATE_CUSTOMER = gql`
  mutation DeactivateUser($id: ID!, $comment: String!) {
    deactivateUser(id: $id, comment: $comment) {
      message
      status
    }
  }
`;

export const ADMIN_APPROVE_MERCHANT = gql`
  mutation approveMerchant($id: ID!) {
    approveMerchant(id: $id) {
      status
      message
    }
  }
`;
export const ADMIN_APPROVE_CUSTOMER = gql`
  mutation ApproveUser($id: ID!) {
    approveUser(id: $id) {
      status
      message
    }
  }
`;

export const ADMIN_REFUND_LOAN = gql`
  mutation ProcessRefund($loanId: ID!) {
    processRefund(loanId: $loanId) {
      message
      status
    }
  }
`;
export const ADMIN_COMPLETE_LOAN_REFUND = gql`
  mutation completeRefund($loanId: ID!) {
    completeRefund(loanId: $loanId) {
      message
      status
    }
  }
`;

export const ADMIN_REQUERY_BILLS_PAYMENT_TRANSACTION = gql`
  mutation RequeryBillsPaymentTransaction(
    $requeryBillsPaymentTransactionId: ID!
  ) {
    requeryBillsPaymentTransaction(id: $requeryBillsPaymentTransactionId) {
      message
      status
    }
  }
`;

export const ADMIN_REQUERY_PAYOUT_TRANSACTION = gql`
  mutation RequeryPayoutTransaction($id: ID!) {
    requeryPayoutTransaction(id: $id) {
      message
      status
    }
  }
`;

export const MODIFY_USER_PRIORITY = gql`
  mutation modifyPriorityUser($id: ID!, $type: String) {
    modifyPriorityUser(id: $id, type: $type) {
      message
      status
    }
  }
`;

export const MODIFY_DEFAULT_PROVIDER = gql`
  mutation modifyDefaultProvider($id: ID!, $provider: String) {
    modifyDefaultProvider(id: $id, provider: $provider) {
      status
      message
    }
  }
`;

export const CHECK_USER_ELIGIBILITY = gql`
  mutation CheckEligibility($userId: String!, $amount: Int, $provider: String) {
    checkEligibilityByUserId(
      userId: $userId
      amount: $amount
      provider: $provider
    ) {
      status
      title
      message
      traceId
      offers {
        productId
        deficit
        requestedAmount
        upfrontPayment
        offerAmount
        interestRate
        recommendedRepaymentDates
        installmentAmount
        nextDueDate
        totalRepaymentAmount
        loanAmount
        interestPaid
        repaymentSchedule {
          amountDue
          dueDate
          installmentIndex
          moneyPayMonth
        }
        tenor
        installment
        processingFee
      }
    }
  }
`;

export const ADMIN_ACTIVATE_DEACTIVATE_MERCHANT_OFFLINE_STORE = gql`
  mutation activateDeactivateOfflineStore($id: ID!) {
    activateDeactivateOfflineStore(id: $id) {
      status
      message
      merchant {
        hasOfflineStore
      }
    }
  }
`;

export const ADMIN_MODIFY_CUSTOMER = gql`
  mutation disableEnableUser($id: ID!) {
    disableEnableUser(id: $id) {
      status
      user {
        status
      }
    }
  }
`;

export const ADMIN_ENABLE_CUSTOMER_ELIGIBILITY = gql`
  mutation enableUserEligibility($id: ID!) {
    enableUserEligibility(id: $id) {
      status
      message
    }
  }
`;

export const ADMIN_DISABLE_CUSTOMER_ELIGIBILITY = gql`
  mutation disableUserEligibility($id: ID!) {
    disableUserEligibility(id: $id) {
      status
      message
    }
  }
`;

export const ADMIN_UPDATE_PAYFI_PRODUCT_FOR_USER = gql`
  mutation addPayfiProductToUser($userId: String!, $productId: String!) {
    addPayfiProductToUser(userId: $userId, productId: $productId) {
      status
      message
    }
  }
`;

export const ADMIN_UPDATE_SETTLEMENT = gql`
  mutation updateSettlementStatus($id: ID, $status: SettlementStatus) {
    updateSettlementStatus(id: $id, status: $status) {
      id
      status
      merchantId
      batchNumber
      totalAmount
      totalSettled
      totalFees
      reason
      settlementReference
      date
      createdAt
    }
  }
`;

export const DELETE_MOBILE_CUSTOMER = gql`
  mutation removeUser($id: ID!, $reason: String!) {
    removeUser(id: $id, reason: $reason) {
      status
      message
    }
  }
`;

export const CANCEL_LOAN = gql`
  mutation cancelLoan($loanId: ID!) {
    cancelLoan(loanId: $loanId) {
      status
      message
    }
  }
`;

export const PROCESS_SINGLE_SCHEDULE = gql`
  mutation processSingleLoanSchedule($loanScheduleId: ID!) {
    processSingleLoanSchedule(loanScheduleId: $loanScheduleId) {
      status
      message
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation adminForgotPassword($email: String!) {
    adminForgotPassword(email: $email) {
      message
      status
    }
  }
`;

export const ADMIN_CHANGE_PASSWORD = gql`
  mutation adminChangePassword($data: String!) {
    adminChangePassword(data: $data) {
      message
      status
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation adminResetPassword($data: String!) {
    adminResetPassword(data: $data) {
      message
      status
    }
  }
`;

export const ADMIN_UPLOAD_DOCUMENT = gql`
  mutation adminUploadUserDocument(
    $name: String!
    $userId: String!
    $file: Upload
    $resourceType: ResourceType
    $provider: String
  ) {
    adminUploadUserDocument(
      name: $name
      userId: $userId
      file: $file
      resourceType: $resourceType
      provider: $provider
    ) {
      id
      status
      imageUrl
      message
    }
  }
`; // adminMerchantUpdateDocument

export const MANUAL_REPAYMENT_UPDATE = gql`
  mutation manualRepaymentUpdate($id: ID!, $file: Upload) {
    manualRepaymentUpdate(id: $id, file: $file) {
      status
      message
    }
  }
`;

export const ADMIN_MERCHANT_UPLOAD_DOCUMENT = gql`
  mutation adminMerchantUpdateDocument(
    $name: String!
    $merchantId: String!
    $file: Upload!
  ) {
    adminMerchantUpdateDocument(
      name: $name
      merchantId: $merchantId
      file: $file
    ) {
      status
      message
      data {
        docKey
        uri
        fileName
        id
      }
    }
  }
`;

export const ADMIN_PROFILE_DETAILS = gql`
  mutation AdminUserUpdate($input: AdminUserUpdateInput!) {
    adminUserUpdate(input: $input) {
      status
      message
    }
  }
`;

export const RETRY_LOAN = gql`
  mutation retryRenmoneyLoan($loanId: ID!, $userId: String) {
    retryRenmoneyLoan(loanId: $loanId, userId: $userId) {
      status
      message
    }
  }
`;

export const REPAYMENT_CONFIG_UPDATE = gql`
  mutation createUpdateRepaymentConfig($input: RepaymentConfigInput!) {
    createUpdateRepaymentConfig(input: $input) {
      status
      message
    }
  }
`;

export const SEND_NOTIFICATION = gql`
  mutation pushNotificationToUser(
    $userId: String!
    $message: String!
    $action: String
    $type: String
    $title: String!
  ) {
    pushNotificationToUser(
      userId: $userId
      message: $message
      action: $action
      type: $type
      title: $title
    ) {
      status
      message
    }
  }
`;

export const CREATE_PUSH_NOTIFICATION = gql`
  mutation CreateNotificationRequest($data: NotificationRequestInput) {
    createNotificationRequest(data: $data) {
      message
      status
    }
  }
`;

export const CHECK_ALL_ELIGIBILITY = gql`
  mutation CheckAllEligibility(
    $reference: String
    $amount: Int
    $provider: String
  ) {
    checkAllEligibility(
      reference: $reference
      amount: $amount
      provider: $provider
    ) {
      message
      status
    }
  }
`;

export const EDIT_PUSH_NOTIFICATION = gql`
  mutation EditNotificationRequest(
    $editNotificationRequestId: ID
    $data: EditNotificationRequestInput
  ) {
    editNotificationRequest(id: $editNotificationRequestId, data: $data) {
      status
      message
    }
  }
`;

export const CREATE_GROUP_USERS = gql`
  mutation CreateUserGroup($input: NewUserGroupInput!) {
    createUserGroup(input: $input) {
      status
      message
    }
  }
`;

export const UPDATE_MERCHANT_SDK_START_SCREEN = gql`
  mutation updateMerchantSDKStartScreen(
    $merchantId: String!
    $screenType: String!
  ) {
    updateMerchantSDKStartScreen(
      merchantId: $merchantId
      screenType: $screenType
    ) {
      message
      status
    }
  }
`;

export const PROVIDE_USER_ELIGIBILITY = gql`
  mutation ProvideUserELigibility(
    $provideUserELigibilityId: ID!
    $promise: Int!
  ) {
    provideUserELigibility(id: $provideUserELigibilityId, promise: $promise) {
      message
      status
    }
  }
`;

export const VERIFY_CUSTOMER_BVN = gql`
  mutation verifyCustomerBVNAdmin($userId: ID!, $reason: String!) {
    verifyCustomerBVNAdmin(userId: $userId, reason: $reason) {
      message
      status
    }
  }
`;

export const UNLINK_CUSTOMER_ACCOUNT = gql`
  mutation unlinkCustomerConnectedBankAccountAdmin(
    $userId: ID!
    $reason: String!
  ) {
    unlinkCustomerConnectedBankAccountAdmin(userId: $userId, reason: $reason) {
      message
      status
    }
  }
`;

export const ADMIN_UPDATE_SMS_CONFIG = gql`
  mutation AdminUpdateSMSConfig($service: String!) {
    adminUpdateSMSConfig(service: $service) {
      message
      status
    }
  }
`;

export const ADMIN_CHECK_ALL_ELIGIBILITY = gql`
  mutation CheckAllEligibility($amount: Int, $provider: String) {
    checkAllEligibility(amount: $amount, provider: $provider) {
      message
      status
    }
  }
`;

export const ADMIN_CREATE_ROLES = gql`
  mutation CreateRole($name: String!, $displayName: String!) {
    createRole(name: $name, display_name: $displayName) {
      message
      status
    }
  }
`;

export const ADMIN_EDIT_ROLES = gql`
  mutation UpdateRole($input: RoleUpdateInput, $updateRoleRoleId2: ID!) {
    updateRole(input: $input, roleId: $updateRoleRoleId2) {
      message
      status
    }
  }
`;

export const ADMIN_CREATE_PERMISSION = gql`
  mutation CreatePermission($name: String!, $group: String!) {
    createPermission(name: $name, group: $group) {
      message
      status
    }
  }
`;

export const ADMIN_DELETE_PERMISSION = gql`
  mutation DeletePermission($permissionId: ID!) {
    deletePermission(permissionId: $permissionId) {
      status
      message
    }
  }
`;

export const MODIFY_VIRTUAL_ACCOUNT_PROVIDER_CONFIG = gql`
  mutation ModifyVirtualAccountProviderConfig($provider: String) {
    modifyVirtualAccountProviderConfig(provider: $provider) {
      status
      message
      data {
        id
        name
        value
        type
        createdAt
        updatedAt
      }
    }
  }
`;

export const MODIFY_PAYOUT_PROVIDER_CONFIG = gql`
  mutation ModifyPayoutProviderConfig($provider: String) {
    modifyPayoutProviderConfig(provider: $provider) {
      status
      message
      data {
        id
        name
        value
        type
        createdAt
        updatedAt
      }
    }
  }
`;
export const MODIFY_VIRTUAL_ACCOUNT_PND_STATUS = gql`
mutation ModifyVirtualAccountPndStatus($userId: String!, $adminId: String!, $reason: String) {
  modifyVirtualAccountPndStatus(userId: $userId, adminId: $adminId, reason: $reason) {
    status
    reason
    message
    adminId
    data {
      pndResponse
    }
  }
}
`;

export const MODIFY_ROLE_PERMISSIONS = gql`
  mutation ModifyRolePermissions(
    $roleId: ID!
    $permissionIds: [ID]!
    $deletedPermissionIds: [ID]!
  ) {
    modifyRolePermissions(
      roleId: $roleId
      permissionIds: $permissionIds
      deletedPermissionIds: $deletedPermissionIds
    ) {
      message
      status
    }
  }
`;

export const INVITE_NEW_STAFF = gql`
  mutation InviteNewStaff($input: NewStaffInput!) {
    inviteNewStaff(input: $input) {
      message
      status
    }
  }
`;

export const EDIT_STAFF = gql`
  mutation EditStaff($adminId: String!, $input: UpdateStaffInput!) {
    editStaff(adminId: $adminId, input: $input) {
      message
      status
    }
  }
`;

export const ACTIVATE_AND_DEACTIVATE_STAFF = gql`
  mutation ModifyAdminStatus($adminId: String!, $status: String!) {
    modifyAdminStatus(adminId: $adminId, status: $status) {
      message
    }
  }
`;

export const UPDATE_ZEROBITS_DISBURSEMENT_PARTNER_CONFIG = gql`
  mutation updateZerobitsDisbursementPartnerConfig($partner: String!) {
    updateZerobitsDisbursementPartnerConfig(partner: $partner) {
      status
      message
      data {
        value
        name
        id
      }
    }
  }
`;

export const UPDATE_PAYMENT_PROVIDER_CONFIG = gql`
  mutation UpdatePaymentConfig($paymentProvider: String!) {
    updatePaymentConfig(paymentProvider: $paymentProvider) {
      status
      message
      data {
        value
        name
        id
      }
    }
  }
`;
export const UPDATE_FINANCIAL_PROVIDER_CONFIG = gql`
  mutation UpdateFinancialProviderConfig($provider: String) {
    updateFinancialProviderConfig(provider: $provider) {
      status
      message
      data {
        name
        id
        value
        updatedAt
        type
        meta
        createdAt
      }
    }
  }
`;

export const UPDATE_MERCHANT_SDK_CONFIGURATION = gql`
  mutation UpdateMerchantSDKConfiguration(
    $id: String!
    $type: String!
    $value: String!
  ) {
    updateMerchantSDKConfiguration(id: $id, type: $type, value: $value) {
      data {
        sdk_config
      }
      message
      status
    }
  }
`;

export const UPDATE_DEFAULT_SDK_CONFIGURATION = gql`
  mutation updateDefaultSDKConfiguration($type: String!, $value: Float!) {
    updateDefaultSDKConfiguration(type: $type, value: $value) {
      status
      message
      data {
        id
        name
        value
        type
        meta
        createdAt
        updatedAt
      }
    }
  }
`;

export const APPROVE_LOAN_PENDING_AUTHORIZATION = gql`
  mutation ApproveLoanPendingAuthorization($id: ID!, $approve: Boolean) {
    approveLoanPendingAuthorization(id: $id, approve: $approve) {
      message
      status
    }
  }
`;

export const FREEZE_REWARD = gql`
  mutation FreezeReward($rewardId: ID!) {
    freezeReward(rewardId: $rewardId) {
      status
      message
    }
  }
`;

export const DELETE_REWARD = gql`
  mutation RemoveRewardPoints($rewardId: ID!) {
    removeRewardPoints(rewardId: $rewardId) {
      status
      message
    }
  }
`;

export const UNFREEZE_REWARD = gql`
  mutation UnfreezeReward($rewardId: ID!) {
    unfreezeReward(rewardId: $rewardId) {
      status
      message
    }
  }
`;

export const FULFIL_LOAN = gql`
  mutation FufilLoan($id: ID!) {
    fufilLoan(id: $id) {
      message
      status
    }
  }
`;

export const MANUAL_LOAN_CREATE = gql`
  mutation CreateManualLoan($input: ManualCreateLoan) {
    createManualLoan(input: $input) {
      status
      message
      data {
        upfrontPaid
        interestRate
        installmentAmount
        nextDueDate
        totalRepaymentAmount
        loanAmount
        interestPaid
        tenor
        installment
        processingFee
        insurancePremium
        repaymentSchedule {
          dueDate
          amountDue
          installmentIndex
        }
      }
    }
  }
`;

export const PREVIEW_MANUAL_LOAN_CREATE = gql`
  mutation previewManualLoanCreate($input: ManualCreateLoan) {
    previewManualLoanCreate(input: $input) {
      status
      message
      data {
        upfrontPaid
        interestRate
        installmentAmount
        nextDueDate
        totalRepaymentAmount
        loanAmount
        interestPaid
        tenor
        installment
        processingFee
        insurancePremium
        repaymentSchedule {
          dueDate
          amountDue
          installmentIndex
        }
      }
    }
  }
`;

export const VERIFY_CDL_LOAN_PROCESS = gql`
  mutation verifyCDlLoanProcess($id: String!) {
    verifyCDlLoanProcess(id: $id) {
      status
      message
      data
    }
  }
`;
