import React from 'react';

const SuccessIcon = () => (
  <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.1" d="M40 80C62.0914 80 80 62.0914 80 40C80 17.9086 62.0914 0 40 0C17.9086 0 0 17.9086 0 40C0 62.0914 17.9086 80 40 80Z" fill="#5941A9" />
    <path opacity="0.2" d="M40.0006 74.2853C58.936 74.2853 74.2863 58.9351 74.2863 39.9996C74.2863 21.0641 58.936 5.71387 40.0006 5.71387C21.0651 5.71387 5.71484 21.0641 5.71484 39.9996C5.71484 58.9351 21.0651 74.2853 40.0006 74.2853Z" fill="#5941A9" />
    <path d="M39.9992 68.5716C55.7787 68.5716 68.5706 55.7797 68.5706 40.0001C68.5706 24.2206 55.7787 11.4287 39.9992 11.4287C24.2196 11.4287 11.4277 24.2206 11.4277 40.0001C11.4277 55.7797 24.2196 68.5716 39.9992 68.5716Z" fill="#5941A9" />
    <g clipPath="url(#clip0_599_26305)">
      <path d="M37.7708 46.4036C37.403 46.4036 37.0351 46.2643 36.753 45.9821L31.278 40.5071C30.7172 39.9464 30.7172 39.0357 31.278 38.475C31.8387 37.9143 32.7494 37.9143 33.3101 38.475L37.7708 42.9357L46.6887 34.0143C47.2494 33.4536 48.1601 33.4536 48.7208 34.0143C49.2815 34.575 49.2815 35.4857 48.7208 36.0464L38.7851 45.9821C38.5065 46.2607 38.1387 46.4036 37.7708 46.4036Z" fill="white" />
    </g>
    <defs>
      <clipPath id="clip0_599_26305">
        <rect width="18.2857" height="18.2857" fill="white" transform="translate(30.8574 30.8574)" />
      </clipPath>
    </defs>
  </svg>

);

export default SuccessIcon;
