/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import './NameAndSwitch.scss';

interface INameAndSwitch{
  name: string;
  label: string;
  checked: boolean;
  onClick: any;
}

const NameAndSwitch = ({
  name, label, checked, onClick,
}: INameAndSwitch) => (
  <div className="name-and-switch-cover">
    <div className="name-and-switch-cover-title">{label}</div>
    <div className="name-and-switch-cover-switch">
      <div onClick={() => onClick(name)} className={`switch ${checked ? 'checked' : ''}`}>
        {/* <input checked={checked} type="checkbox" /> */}
        <span className="slider round" />
      </div>
    </div>
  </div>
);

export default NameAndSwitch;
