import React from 'react';

const EmptyTransactionIcon = () => (
  <svg width="118" height="142" viewBox="0 0 118 142" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M58.735 132.735C91.1735 132.735 117.47 106.438 117.47 73.9997C117.47 41.5612 91.1735 15.2646 58.735 15.2646C26.2966 15.2646 0 41.5612 0 73.9997C0 106.438 26.2966 132.735 58.735 132.735Z" fill="url(#paint0_linear_598_46855)" />
    <g filter="url(#filter0_d_598_46855)">
      <path d="M92.4107 48.9395H25.0612C22.8986 48.9395 21.1455 50.6926 21.1455 52.8551V135.084C21.1455 137.247 22.8986 139 25.0612 139H92.4107C94.5732 139 96.3263 137.247 96.3263 135.084V52.8551C96.3263 50.6926 94.5732 48.9395 92.4107 48.9395Z" fill="white" />
    </g>
    <path d="M50.9052 60.6865H30.5437C29.2462 60.6865 28.1943 61.7384 28.1943 63.0359C28.1943 64.3335 29.2462 65.3853 30.5437 65.3853H50.9052C52.2028 65.3853 53.2546 64.3335 53.2546 63.0359C53.2546 61.7384 52.2028 60.6865 50.9052 60.6865Z" fill="#DCD8EC" />
    <path d="M65.0016 70.8672H30.5437C29.2462 70.8672 28.1943 71.9191 28.1943 73.2166C28.1943 74.5141 29.2462 75.566 30.5437 75.566H65.0016C66.2992 75.566 67.351 74.5141 67.351 73.2166C67.351 71.9191 66.2992 70.8672 65.0016 70.8672Z" fill="#F2F4F7" />
    <path d="M50.9052 81.8311H30.5437C29.2462 81.8311 28.1943 82.8829 28.1943 84.1805C28.1943 85.478 29.2462 86.5299 30.5437 86.5299H50.9052C52.2028 86.5299 53.2546 85.478 53.2546 84.1805C53.2546 82.8829 52.2028 81.8311 50.9052 81.8311Z" fill="#DCD8EC" />
    <path d="M65.0016 92.0117H30.5437C29.2462 92.0117 28.1943 93.0636 28.1943 94.3611C28.1943 95.6587 29.2462 96.7105 30.5437 96.7105H65.0016C66.2992 96.7105 67.351 95.6587 67.351 94.3611C67.351 93.0636 66.2992 92.0117 65.0016 92.0117Z" fill="#F2F4F7" />
    <path d="M50.9052 102.975H30.5437C29.2462 102.975 28.1943 104.026 28.1943 105.324C28.1943 106.622 29.2462 107.673 30.5437 107.673H50.9052C52.2028 107.673 53.2546 106.622 53.2546 105.324C53.2546 104.026 52.2028 102.975 50.9052 102.975Z" fill="#DCD8EC" />
    <path d="M65.0016 113.155H30.5437C29.2462 113.155 28.1943 114.207 28.1943 115.505C28.1943 116.802 29.2462 117.854 30.5437 117.854H65.0016C66.2992 117.854 67.351 116.802 67.351 115.505C67.351 114.207 66.2992 113.155 65.0016 113.155Z" fill="#F2F4F7" />
    <g filter="url(#filter1_d_598_46855)">
      <path d="M92.4097 9H25.0602C22.8976 9 21.1445 10.7531 21.1445 12.9157V36.4097C21.1445 38.5722 22.8976 40.3253 25.0602 40.3253H92.4097C94.5723 40.3253 96.3254 38.5722 96.3254 36.4097V12.9157C96.3254 10.7531 94.5723 9 92.4097 9Z" fill="#523B9F" />
    </g>
    <path d="M50.9033 17.6143H30.5418C29.2442 17.6143 28.1924 18.6661 28.1924 19.9637C28.1924 21.2612 29.2442 22.3131 30.5418 22.3131H50.9033C52.2008 22.3131 53.2527 21.2612 53.2527 19.9637C53.2527 18.6661 52.2008 17.6143 50.9033 17.6143Z" fill="#DCD8EC" />
    <path d="M64.9997 27.7949H30.5418C29.2442 27.7949 28.1924 28.8468 28.1924 30.1443C28.1924 31.4419 29.2442 32.4937 30.5418 32.4937H64.9997C66.2972 32.4937 67.3491 31.4419 67.3491 30.1443C67.3491 28.8468 66.2972 27.7949 64.9997 27.7949Z" fill="white" />
    <defs>
      <filter id="filter0_d_598_46855" x="15.1455" y="39.9395" width="87.1807" height="102.061" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="-3" />
        <feGaussianBlur stdDeviation="3" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.788235 0 0 0 0 0.803922 0 0 0 0 0.85098 0 0 0 0.349 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_598_46855" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_598_46855" result="shape" />
      </filter>
      <filter id="filter1_d_598_46855" x="15.1445" y="0" width="87.1807" height="43.3252" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="-3" />
        <feGaussianBlur stdDeviation="3" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.788235 0 0 0 0 0.803922 0 0 0 0 0.85098 0 0 0 0.349 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_598_46855" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_598_46855" result="shape" />
      </filter>
      <linearGradient id="paint0_linear_598_46855" x1="58.735" y1="15.2646" x2="58.735" y2="132.735" gradientUnits="userSpaceOnUse">
        <stop stopColor="#DCD8EC" stopOpacity="0.12" />
        <stop offset="1" stopColor="#DFE0EB" stopOpacity="0.4" />
      </linearGradient>
    </defs>
  </svg>

);

export default EmptyTransactionIcon;
