import { CircularProgress, FormControl } from '@material-ui/core';
import { FunctionComponent, useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import './ResetPassword.scss';
import { useMutation } from '@apollo/client';
import TextInput from '../../components/input';
import { CHANGE_PASSWORD } from '../../graphql/mutations';
import useToast from '../../customHooks/useToast';
import LightShield from '../../svgs/LightShield';
import useForm from '../../customHooks/useForm';
import useGetQueryParams from '../../customHooks/useGetQueryParams';
import PasswordShield from '../../svgs/PasswordShield';

export interface ILogin {
  label?: string;
}

export interface IPasswordChangeRequest {
  newPassword: string;
  resetToken: string;
}

export interface IPasswordChangeResponse {
  status: string;
  message: string;
  token: string;
}

const PasswordChange: FunctionComponent<ILogin> = () => {
  const { push } = useHistory();
  const { getQuery } = useGetQueryParams();
  const resetToken = getQuery('resetToken');
  const [changePassword] = useMutation<
    { merchantResetPassword: any; changePasswordInfo: any },
    any
  >(CHANGE_PASSWORD);
  const [loading, setLoading] = useState(false);
  const Toast = useToast();
  // regex pattern for password
  // (lower case, upper case, number, special character, minimum 8 characters)
  const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/;
  const errorMessage = 'Password must contain at least 8 characters, 1 uppercase, 1 lowercase, 1 number and 1 special character';
  const {
    formValues, handleChange, checkAllRequired, validateInputs,
  } = useForm({
    newPassword: '',
    confirmPassword: '',
  }, {
    newPassword: {
      pattern,
      errorMessage,
    },
    confirmPassword: {
      pattern,
      errorMessage,
    },
  }, Toast);

  const handleOnSubmit: (arg: any) => void = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      if (!checkAllRequired()) {
        setLoading(false);
        Toast.error('Please complete all fields');
        return;
      }
      if (!validateInputs()) {
        setLoading(false);
        return;
      }
      if (formValues?.newPassword !== formValues?.confirmPassword) {
        setLoading(false);
        Toast.error('Passwords must match');
        return;
      }
      const payload: IPasswordChangeRequest = {
        resetToken,
        newPassword: formValues?.newPassword,
      };

      const resp = await changePassword({ variables: payload });
      setLoading(false);
      if (
        resp?.data?.merchantResetPassword
        && resp?.data?.merchantResetPassword?.status === 200
      ) {
        Toast.success('Password successfully changed. Login here');
        push('/');
      } else {
        Toast.error(
          resp?.data?.merchantResetPassword?.message
            || 'Something went wrong. Try again.',
        );
      }
    } catch (e) {
      setLoading(false);
      if (e && e.graphQLErrors && e.graphQLErrors.length > 0) {
        Toast.error(e.graphQLErrors[0].message);
      } else {
        Toast.error('Something went wrong');
      }
    }
  };

  useEffect(() => {
    if (!resetToken) {
      Toast.error('Invalid reset password link');
      push('/');
    }
  }, [resetToken]);

  return (
    <div className="password-reset">
      <form
        onSubmit={handleOnSubmit}
        className="password-reset-view app-max-width"
      >
        <div className="password-reset-view-cover container col-lg-4 col-md-6 col-12">
          <div className="password-reset-view-cover-logo">
            <div className="password-reset-view-cover-logo-div">
              <LightShield />
            </div>
          </div>
          <div className="password-reset-view-cover-title">
            Create new password
          </div>
          <div className="password-reset-view-cover-subtitle">
            <div>
              Create your new password for your Payfi account to ease
              login into your account.
            </div>
          </div>

          <FormControl className="password-reset-view-cover-form">
            <TextInput
              label="New Password (8+ characters)"
              isSecure
              width="100%"
              customOnChange={handleChange}
              name="newPassword"
              value={formValues.newPassword}
              preIcon={<PasswordShield />}
              paddingLeft="44px"
            />
            <TextInput
              label="Confirm Password (8+ characters)"
              isSecure
              width="100%"
              customOnChange={handleChange}
              name="confirmPassword"
              value={formValues.confirmPassword}
              preIcon={<PasswordShield />}
              paddingLeft="44px"
            />
            <button className="form-btn p-3 mb-2 bg-primary text-white rounded">
              {loading ? (
                <CircularProgress color="inherit" size={15} />
              ) : (
                'Change Password'
              )}
            </button>
          </FormControl>

          <div className="password-reset-view-cover-account">
            {'Remember your password? '}
            <Link to="/" className="password-reset-view-cover-account-link">Login</Link>
          </div>
        </div>
      </form>
    </div>
  );
};

export default PasswordChange;
