import {
  FunctionComponent, useEffect,
} from 'react';
import { useParams } from 'react-router';
import { GET_ORDER_BY_ID } from '../../graphql/queries';
import DashboardWrap from '../../components/DashboardWrap';
import LinkBackComponent from '../../components/LinkBackComponent';
import './OrderDetail.scss';
import { cleanUpSingleOrder, cleanUpLoanSchedules } from '../../utils/helpers';
import useCustomQuery from '../../network/useCustomQuery';
import LoanSchedules from './LoanSchedules';
import OrderInfo from './OrderInfo';
import ProductCard from './ProductCard';
import ShippingComp from './ShippingComp';
import ItemsComp from '../../components/ItemsComp';

const OrderDetail: FunctionComponent = () => {
  const { orderId } = useParams<{ orderId: string }>();

  const [getOrder, loading, order] = useCustomQuery({
    query: GET_ORDER_BY_ID,
    onError: () => {},
    onData: () => {},
  });

  useEffect(() => {
    if (orderId) {
      getOrder({ variables: { id: orderId } });
    }
  }, [orderId]);

  const payload = order?.getOrderById
    && cleanUpSingleOrder(order?.getOrderById);
  const loanSchedules = cleanUpLoanSchedules(
    order?.getOrderById?.loan?.loanSchedules,
  );

  return (
    <DashboardWrap>
      <div className="single-order-detail">
        <header>
          <div className="title-cov">
            <LinkBackComponent
              link="/orders"
              text="Orders"
            />
            <div className="order-title">
              <h3>
                Order Ref : :
              </h3>
              <h5>
                {loading ? '...' : payload?.orderNumber}
              </h5>
            </div>
          </div>
        </header>
        <section>
          <OrderInfo order={payload} />
          <ProductCard product={payload?.productDetails} />
        </section>
        <section>
          <LoanSchedules loanSchedules={loanSchedules} />
          <ShippingComp address={payload?.shippingAddress} />
        </section>
        <section>
          {
            payload?.orderItems?.length !== 0 && (
            <div style={{ marginTop: '32px', width: '100%' }}>
              <ItemsComp orderItems={payload?.orderItems || []} width="800px" />
            </div>
            )
          }
        </section>
      </div>
    </DashboardWrap>
  );
};

export default OrderDetail;
