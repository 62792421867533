import { FunctionComponent, useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import Next from '../../svgs/Next';
import Prev from '../../svgs/Prev';
import UseCustomResizer from '../CustomResizer';
import './Pagination.scss';

interface IPagination {
  count: number;
  pageSize: number;
  setPage: any;
  page: number;
}

const PaginationComp: FunctionComponent<IPagination> = ({
  count,
  setPage,
  pageSize,
  page,
}) => {
  const { isMobile } = UseCustomResizer({ width: 560 });
  const handlePageClick = (data) => {
    const { selected } = data;
    setPage(selected + 1);
  };
  const totalPages = Math.ceil(count / pageSize);
  // const pageRangeDisplayed = totalPages > 3 ? 3 : totalPages;
  const prevDisabled = page <= 1;
  const nextDisabled = page >= totalPages;
  // const from = pageSize * (page - 1) + 1;
  // const to = pageSize * page;
  const [localPage, setLocalPage] = useState(page);
  const [pageRange, setPageRange] = useState(0);
  const handleChange = (e) => {
    const { value } = e.target;
    if (value > totalPages) {
      setLocalPage(totalPages);
    } else {
      setLocalPage(value);
    }
  };

  const handleGoToPage = () => {
    if (page !== localPage) {
      setPage((localPage < 1) || !localPage ? 1 : localPage);
    }
  };
  useEffect(() => {
    if (isMobile) {
      setPageRange(0);
    } else {
      setPageRange(2);
    }
  }, [isMobile]);

  return (
    <div className="pagination-comp">
      <div className="page-count">
        <strong className="page-num">
          {page}
        </strong>
        <span className="page-total">
          {`of ${totalPages} pages`}
        </span>
      </div>
      <ReactPaginate
        previousLabel={Prev({ disabled: prevDisabled })}
        nextLabel={Next({ disabled: nextDisabled })}
        breakLabel="..."
        breakClassName="break-me"
        pageCount={totalPages}
        marginPagesDisplayed={pageRange}
        forcePage={page - 1}
        pageRangeDisplayed={1}
        onPageChange={handlePageClick}
        containerClassName="pagination"
        activeClassName="active"
        pageClassName="each-page"
      />
      <div className="go-to-page">
        <span onClick={handleGoToPage}>Go to page</span>
        <input type="number" onChange={handleChange} value={localPage} />
      </div>
    </div>
  );
};

export default PaginationComp;
