import {
  FunctionComponent, useEffect, useState,
} from 'react';
import './Orders.scss';
// import moment from 'moment';
import { useHistory } from 'react-router-dom';
import DashboardWrap from '../../components/DashboardWrap';
import { GET_MERCHANTS_ORDERS } from '../../graphql/queries';
import Table from '../../components/Table';
import { cleanUpOrders } from '../../utils/helpers';
import useCustomQuery from '../../network/useCustomQuery';
import useGetQueryParams from '../../customHooks/useGetQueryParams';
import DashboardHeader from '../../components/DashboardHeader';
import EmptyRecord from '../../components/EmptyRecord';
import useToast from '../../customHooks/useToast';
import generatePDF from '../../utils/generatePdf';
import generateCSV from '../../utils/generateCSV';
import useFilterMethods from '../../customHooks/useFilterMethods';

const limit = 14;
const Orders: FunctionComponent = () => {
  const Toast = useToast();
  const [getOrders, loading, rawData] = useCustomQuery({
    query: GET_MERCHANTS_ORDERS,
    onError: () => {},
    onData: () => {},
  });

  const [data, setData] = useState(null);
  const { getQuery, getFilterQueries } = useGetQueryParams();

  const search = getQuery('search') || '';
  const page = Number(getQuery('page'));
  const { push } = useHistory();
  const setPage = (pageNum) => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('page', pageNum);
    push({
      search: searchParams.toString(),
    });
  };
  if (!page) {
    push(`/orders?page=1&search=${search}`);
  }

  const onSearchChange = (e) => {
    push(`/orders?page=1&search=${encodeURIComponent(e.target.value)}`);
  };

  useEffect(() => {
    if (page) {
      const result = getFilterQueries();
      getOrders({
        variables: {
          offset: (page - 1) * limit,
          limit,
          search,
          filters: {
            from: result.from,
            to: result.to,
            status: result.status,
            minAmount: result.min ? Number(result.min) : 0,
            maxAmount: result.max ? Number(result.max) : 0,
          },
        },
      });
    }
  }, [page, search]);

  const onFilterSubmit = (result) => {
    getOrders({
      variables: {
        offset: 0,
        limit,
        search,
        filters: {
          from: result.from,
          to: result.to,
          status: result.status,
          minAmount: result.min ? Number(result.min) : 0,
          maxAmount: result.max ? Number(result.max) : 0,
        },
      },
    });
  };

  const {
    filterObj,
  } = useFilterMethods({
    allowedFilters: ['dateRange', 'status', 'amountRange'],
    defaultUrl: '/orders?page=1',
    cb: onFilterSubmit,
    Toast,
    customFilterInformation: {
      status: {
        label: 'Status',
        options: [
          {
            label: 'Paid',
            value: 'PAID',
          },
          {
            label: 'Pending',
            value: 'PENDING',
          },
          {
            label: 'Cancelled',
            value: 'CANCELLED',
          },
          {
            label: 'System Cancelled',
            value: 'SYSTEM_CANCELLED',
          },
          {
            label: 'User Cancelled',
            value: 'USER_CANCELLED',
          },
        ],
      },
    },
  });

  useEffect(() => {
    if (rawData?.getMerchantOrders?.rows) {
      setData(rawData);
    }
  }, [rawData]);

  const [getOrdersExportRecord] = useCustomQuery({
    query: GET_MERCHANTS_ORDERS,
    onError: () => {},
    onData: () => {},
  });
  const handleExport = async (type: string) => {
    try {
      const result = getFilterQueries();
      const output = await getOrdersExportRecord({
        variables: {
          offset: 0,
          limit: 2000,
          cursor: search,
          filters: {
            from: result.from,
            to: result.to,
            status: result.status,
            channel: result.channel,
            minAmount: result.min ? Number(result.min) : 0,
            maxAmount: result.max ? Number(result.max) : 0,
          },
        },
      });
      if (output?.data?.getMerchantOrders?.rows) {
        const rec = cleanUpOrders(output?.data?.getMerchantOrders?.rows);
        const func = type === 'pdf' ? generatePDF : generateCSV;
        func({
          payload: rec,
          title: 'ORDERS',
          selectedHeaders: [
            'S/N',
            'orderNumber',
            'item',
            'amount',
            'channel',
            'status',
            'customer',
            'email',
            'dateTime',
          ],
          formatHeaders: {
            date: 'Date',
            orderNumber: 'Order No',
            email: 'Email Address',
          },
          cb: () => Toast.success('Exported successfully'),
        });
      }
    } catch (error) {
      Toast.error('Error while exporting');
    }
  };

  return (
    <div className="orders-page">
      <DashboardWrap>
        <DashboardHeader
          title="Orders"
          smallSubTitle={`(${data?.getMerchantOrders?.totalCount || 0})`}
          searchPlaceholder="Search by email address, phone number, customer name and reference"
          filterObj={filterObj}
          exportObj={{
            handleExport,
          }}
          search={search}
          onSearchChange={onSearchChange}
          searchLoading={loading}
        />
        {data?.getMerchantOrders?.count ? (
          <Table
            refreshing={loading}
            page={page}
            setPage={setPage}
            pageSize={limit}
            minHeight="calc(100vh - 280px)"
            count={data?.getMerchantOrders.count}
            contents={cleanUpOrders(data?.getMerchantOrders.rows)}
            reference="id"
            hideDominantImage
            clickable
            handleClick={(data) => push(`/orders/${data?.id}`)}
            allowKeyArr={[
              'orderNumber',
              'item',
              'amount',
              'channel',
              'status',
              'customer',
              'email',
              ['date', 'time'],
            ]}
            mobileAllowKeyArr={
              [
                'status',
                ['orderNumber', 'date', 'time'],
                ['amount', 'channel'],

              ]
            }
            formatLabels={{
              date: 'Date',
              orderNumber: 'Order No',
              email: 'Email Address',
            }}
          />
        ) : (
          <EmptyRecord
            height="calc(100vh - 240px)"
            title={loading ? 'Fetching orders...' : 'No orders yet'}
            main="All orders from your customers through Payfi will appear here"
            // footer="Receive Payments"
            loading={loading}
          />
        )}
      </DashboardWrap>
    </div>
  );
};

export default Orders;
