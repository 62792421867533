import React from 'react';

const ClockIcon = () => (
  <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M40.0003 73.3332C58.4098 73.3332 73.3337 58.4093 73.3337 39.9998C73.3337 21.5903 58.4098 6.6665 40.0003 6.6665C21.5908 6.6665 6.66699 21.5903 6.66699 39.9998C6.66699 58.4093 21.5908 73.3332 40.0003 73.3332Z" stroke="#5941A9" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M40 23V43L53.3333 49.6667" stroke="url(#paint0_linear_3785_30192)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <defs>
      <linearGradient id="paint0_linear_3785_30192" x1="46.6667" y1="23" x2="13.5" y2="36" gradientUnits="userSpaceOnUse">
        <stop stopColor="#5941A9" />
        <stop offset="1" stopColor="#5941A9" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>

);

export default ClockIcon;
