import React from 'react';

const ShareCopyIcon = () => (
  <svg width="61" height="60" viewBox="0 0 61 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" width="60" height="60" rx="30" fill="#F2F4F7" />
    <path d="M37.0619 25.7146H28.4905C27.307 25.7146 26.3477 26.674 26.3477 27.8575V36.4289C26.3477 37.6124 27.307 38.5717 28.4905 38.5717H37.0619C38.2454 38.5717 39.2048 37.6124 39.2048 36.4289V27.8575C39.2048 26.674 38.2454 25.7146 37.0619 25.7146Z" stroke="#101828" strokeWidth="1.60714" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M34.9187 25.7147V23.5718C34.9187 23.0035 34.6929 22.4584 34.291 22.0566C33.8892 21.6547 33.3441 21.429 32.7758 21.429H24.2044C23.6361 21.429 23.091 21.6547 22.6892 22.0566C22.2873 22.4584 22.0615 23.0035 22.0615 23.5718V32.1432C22.0615 32.7116 22.2873 33.2566 22.6892 33.6585C23.091 34.0603 23.6361 34.2861 24.2044 34.2861H26.3472" stroke="#101828" strokeWidth="1.60714" strokeLinecap="round" strokeLinejoin="round" />
  </svg>

);
export default ShareCopyIcon;
