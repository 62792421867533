import React from 'react';

interface IRightArrow{
  width?:string;
  height?:string;
}
const RightArrow = ({ width, height }:IRightArrow) => (
  <svg
    width={width || '6'}
    height={height || '12'}
    viewBox="0 0 6 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.75 10.5L5.25 6L0.75 1.5"
      stroke="#667E93"
      strokeWidth="1.16667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default RightArrow;
