import React from 'react';

const TotalSalesIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" rx="12" fill="#F9FAFB" />
    <path d="M14.2493 14.2668L12.4379 12.2507L16.4188 8.67404C16.6197 8.49347 16.6397 8.18756 16.4633 7.99122C16.2869 7.79488 15.9806 7.78208 15.7796 7.96266L11.7987 11.5393L9.98739 9.52324C9.87106 9.39377 9.69184 9.33881 9.51908 9.38089C9.34681 9.42254 9.20888 9.55417 9.15901 9.72326L7.43676 15.5569C7.38924 15.7179 7.427 15.8888 7.53693 16.0112C7.64686 16.1336 7.81278 16.1893 7.9779 16.1593L11.7012 15.4804C11.9648 15.433 12.1454 15.1808 12.1035 14.9184C12.0608 14.656 11.8125 14.4822 11.5479 14.5305L8.58458 15.0699L9.83783 10.8215L13.521 14.921C13.6975 15.1174 14.0037 15.1302 14.2047 14.9496C14.4057 14.769 14.4257 14.4631 14.2493 14.2668Z" fill="#523B9F" />
  </svg>

);

export default TotalSalesIcon;
