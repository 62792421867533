import React from 'react';
import TrxSuccessIcon from '../../svgs/TrxSuccessIcon';
import LabelValueComp from '../../components/LabelValueComp';
import TrxFailedIcon from '../../svgs/TrxFailedIcon';
import TrxPendingIcon from '../../svgs/TrxPendingIcon';

type Props = {
  order: any
}

const OrderInfo = ({ order }: Props) => {
  const records = {
    'Customer Name': order?.customerName,
    Tenure: `${order?.loan?.tenor} months`,
    'Order No': order?.orderNumber,
    'Phone Number': order?.phone,
    'Email Address': order?.email,
    'Capture Date': order?.createdDate,
  };
  const status = order?.status?.name?.toLowerCase?.();
  return (
    <div className="order-info">
      <div className="order-info-header">
        {status === 'paid' ? <TrxSuccessIcon /> : ['cancelled', 'system_cancelled', 'user_cancelled'].includes(status) ? <TrxFailedIcon /> : <TrxPendingIcon />}
        <h5>{order?.amount}</h5>
      </div>
      <aside>
        {Object.keys(records).map((key, index) => (
          <LabelValueComp key={index} label={key} value={records[key]} />
        ))}
      </aside>
    </div>
  );
};

export default OrderInfo;
