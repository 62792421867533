import React from 'react';

const EmptyRecord = () => (
  <svg width="144" height="186" viewBox="0 0 144 186" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="72" cy="72.4585" r="71.5" fill="#F1F1F1" />
    <rect x="43.9505" y="71.0012" width="70" height="98" rx="7" transform="rotate(2.5 43.9505 71.0012)" fill="#EEEEEE" stroke="#999999" strokeWidth="2" />
    <rect x="2.33591" y="65.1391" width="74" height="102" rx="9" transform="rotate(-7.5 2.33591 65.1391)" fill="#FAFAFA" stroke="#999999" strokeWidth="2" />
    <path d="M4.5021 73.9316C3.92539 69.5511 7.00897 65.5325 11.3894 64.9558L66.9104 57.6463C71.2908 57.0696 75.3094 60.1532 75.8861 64.5337L77.844 79.4053L6.45999 88.8032L4.5021 73.9316Z" fill="#EEEEEE" />
    <rect x="90.3662" y="59.6016" width="3" height="10" rx="1.5" transform="rotate(82.5 90.3662 59.6016)" fill="#999999" />
    <rect x="62.9696" y="43.0356" width="3" height="10" rx="1.5" transform="rotate(-7.5 62.9696 43.0356)" fill="#999999" />
    <rect x="80.5472" y="45.7646" width="3" height="10" rx="1.5" transform="rotate(37.5 80.5472 45.7646)" fill="#999999" />
    <rect x="13.9222" y="91.8555" width="58" height="4" rx="2" transform="rotate(-7.5 13.9222 91.8555)" fill="#DCDCDC" />
    <rect x="16.5327" y="111.684" width="58" height="4" rx="2" transform="rotate(-7.5 16.5327 111.684)" fill="#DCDCDC" />
    <rect x="19.1432" y="131.513" width="58" height="4" rx="2" transform="rotate(-7.5 19.1432 131.513)" fill="#DCDCDC" />
    <rect x="14.9664" y="99.7871" width="21" height="4" rx="2" transform="rotate(-7.5 14.9664 99.7871)" fill="#EEEEEE" />
    <rect x="17.577" y="119.616" width="21" height="4" rx="2" transform="rotate(-7.5 17.577 119.616)" fill="#EEEEEE" />
    <rect x="20.1875" y="139.445" width="21" height="4" rx="2" transform="rotate(-7.5 20.1875 139.445)" fill="#EEEEEE" />
  </svg>

);

export default EmptyRecord;
