import { gql } from '@apollo/client';

// get industries and categories
export const GET_INDUSTRIES = gql`
  query getIndustries {
    getIndustries {
      title
      categories {
        title
      }
    }
  }
`;

// use to populate select forms for business profile
export const GET_BUSINESS_PROFILE_DATA = gql`
  query getBusinessData {
    getBusinessProfileData {
      businessTypes {
        name
        value
      }
      registrationTypes {
        name
        code
        ownershipTypes {
          name
          code
        }
      }
      industries {
        name
        description
        code
        categories {
          code
          description
          registrationRequired
          name
        }
      }
      categories {
        id
        name
      }
    }
  }
`;

// example transfer_type: "inter", accountNo: "0087611190", bank: "999221"
export const ACCOUNT_LOOKUP = gql`
  query gettransben(
    $transfer_type: String
    $accountNo: String
    $bankCode: String
  ) {
    getTransBen(
      transfer_type: $transfer_type
      accountNo: $accountNo
      bankCode: $bankCode
    ) {
      name
      clientId
      bvn
      account {
        id
        number
      }
      status
      currency
      bank
    }
  }
`;

export const GET_BANK_CODES = gql`
  query getBankCodes {
    getBankCodes {
      name
      code
    }
  }
`;

export const GET_MERCHANTS_ORDERS = gql`
query getMerchantOrders($offset: Int, $limit: Int, $search: String, $filters: FiltersType) {
    getMerchantOrders (offset: $offset, limit: $limit, search: $search, filters: $filters) {
      count
      rows {
        id
        orderNumber
        createdAt
        amount
        status {
          name
        }
        user {
          firstName
          lastName
          email
        }
        loan {
          loanChannel
     }
    }
  }
}
`;

export const GET_TRANSACTIONS = gql`
  query getTransactions($offset: Int, $limit: Int, $cursor: String, $filters: FiltersType) {
    getMerchantTransactions(offset: $offset, limit: $limit, cursor: $cursor, filters: $filters) {
      totalCount
      data {
        id
        status
        narration
        type
        narration
        description
        amount
        createdAt
        orderRef
        txRef
        orderAmount
        paidAt
        channel
        firstName
        lastName
        payfiId
        channel
        email
      }
    }
  }
`;

export const GET_TRANSACTION_BY_ID = gql`
  query getMerchantTransactionById($id: ID!) {
    getMerchantTransactionById(id: $id) {
      loanSchedules {
        amountDue
        isFullyPaid
        dueDate
      }
      data {
        id
        status
        narration
        type
        narration
        description
        amount
        createdAt
        txRef
        paidAt
        currency
        firstName
        orderAmount
        lastName
        payfiId
        orderRef
        channel
        ipAddress
        payfiId
        tenor
        paymentMethod
        orderNumber
        orderRef
        orderAmount
        cardBin
        last4
        bank
        phone
        email
      }
    }
  }
`;

export const GET_ORDER_BY_ID = gql`
  query getOrderById($id: ID!) {
    getOrderById(id: $id) {
      status {
          name
      }
      orderNumber
      id
      createdAt
      amount
      user {
        firstName
        lastName
        email
        phone
      }
      loan {
        loanChannel
        tenor
        loanStatus {
          name
        }
        loanSchedules {
          amountDue
          isFullyPaid
          dueDate
        }
      }
      orderItems {
        orderName
        amount
        options
        quantity
      }
    }
  }
`;

export const GET_PROFILE = gql`
  query getMerchantUserDetail {
    getMerchantUserDetail {
      data {
        category {
          name
          id
        }
        state
        firstName
        lastName
        dob
        email
        bvn
        phone
        idCard
        isEmailVerified
        imageUrl
        role {
          id
          name
        }
        merchant {
          isPending
          isApproved
          onboardingStage
          callback
          webhook
          publicKey
          secretKey
          logo
          name
          longDesc
          shortDesc
          registrationType
          incorporatedBusinessName
          institutionType
          businessType
          accountName
          accountNumber
          bank
          website
          street
          country
          supportEmail
          contactPhone
          contactEmail
          twitter
          facebook
          instagram
          city
          state
          email
          tinNumber
          registrationNumber
          documentSentForActivation
          payfiId
          documents {
            uri
            documentType
            docKey
            description
            fileName
            uri
          }
          bankDetails {
            id
            currency
            accountName
            accountNumber
            status
            swiftCode
            alias
            bank {
              name
              nipCode
            }
          }
          businessOwner {
            id
            merchantUserId
            country
            state
            city
            street
            dateOfBirth
            identityDocumentId
            merchantUser {
              firstName
              lastName
              email
              phone
            }
            identityDocument {
              documentType
              documentId
              docKey
              description
              fileName
              uri
            }
          }
        }
      }
    }
  }
`;

export const GET_BANK_DETAILS = gql`
  query bankDetails {
    bankDetails {
      bankDetails {
        id
        currency
        accountName
        accountNumber
        status
        swiftCode
        alias
        bank {
          name
          nipCode
        }
      }
    }
  }
`;

export const GET_BANKS = gql`
  query banks {
    banks {
      id
      name
      nipCode
      cbnCode
    }
  }
`;

export const RESET_KEYS = gql`
  query resetMechantApiKeys {
    resetMechantApiKeys {
      message
      status
      merchant {
        publicKey
        secretKey
      }
    }
  }
`;

export const GET_USERS = gql`
  query merchantUsers {
    merchantUsers {
      data {
        id
        firstName
        lastName
        email
        role
        phone
        createdAt
        lastLogin
      }
    }
  }
`;

export const GET_ROLES = gql`
  query roles {
    roles {
      id
      name
    }
  }
`;

export const GET_SETTLEMENTS = gql`
  query getSettlement($limit: Int!, $offset: Int!, $search: String, $filters: FiltersType) {
    getSettlementsByMerchantId(limit: $limit, offset: $offset, search: $search, filters: $filters) {
      settlements {
        id
        status
        merchantId
        batchNumber
        totalFees
        totalAmount
        totalSettled
        totalFees
        vendorFees
        reason
        settlementReference
        date
        createdAt
        settledOn
      }
      count
      totalFees
      totalAmount
      totalSettled
      pendingSettlement
    }
  }
`;

export const GET_SETTLEMENT_TRANSACTIONS = gql`
  query getTransactionsBySettlementId($id: String!, $limit: Int!, $offset: Int!, $search: String, $filters: FiltersType) {
    getTransactionsBySettlementId(id: $id, limit: $limit, offset: $offset, search: $search, filters: $filters) {
      count
      transactions {
        id
        status
        amount
        txRef
        description
        narration
        amount
        currency
        createdAt
        cardLast4
        paidAt
        ipAddress
        fees
        amountPaid
        channel
        merchant {
          name
          logo
        }
        user {
          firstName
          lastName
        }
      }
      count
    }
  }
`;

export const GET_SETTLEMENT_BY_ID = gql`
  query getSettlementById($id: ID!) {
    getSettlementById(id: $id) {
      id
      status
      merchantId
      batchNumber
      totalAmount
      totalSettled
      totalFees
      vendorFees
      reason
      settlementReference
      date
      createdAt
    }
  }
`;

export const GET_OVERVIEW = gql`
  query getMerchantOverview ($timePeriod: Int) {
    getMerchantOverview (timePeriod: $timePeriod) {
      customers {
        email
        orders
        amount
        firstName
        lastName
      }
      loans {
        threeMonth
        sixMonth
        twelveMonth
      }
      transactionBalances {
        totalRevenue
        totalPaidSettlement
        totalUnsettledBalance
      }
    }
  }
`;

export const GET_OVERVIEW_TRANSACTION = gql`
  query getMerchantTransactionsOverview ($timePeriod: Int) {
    getMerchantTransactionsOverview (timePeriod: $timePeriod) {
      trxRecords {
        trxCount
         dateCreated
      }
      transactionDetail {
        noOfTransactionsAllTime
        avgtransactionVolAllTime
        totalTransactionVol
        percentageChangeInTransactionVol
        transactionVolChangeStatus
      }
    }
  }
`;

export const GET_CUSTOMERS = gql`
  query getCustomers($limit: Int!, $offset: Int!, $search: String) {
    getCustomers(limit: $limit, offset: $offset, search: $search) {
      data {
        phone
        firstName
        lastName
        customerRef
        createdOn
        status
        email
        transactionAmount
        customerId
      }
      totalCount
    }
  }
`;

export const GET_MERCHANT_CUSTOMERS = gql`
  query getCustomers($limit: Int!, $offset: Int!, $search: String, $filters: FiltersType) {
    getMerchantCustomers(limit: $limit, offset: $offset, search: $search, filters: $filters) {
      data {
        phone
        firstName
        lastName
        payfiId
        createdAt
        status
        email
        transactionAmount
        id
      }
      totalCount
    }
  }
`;

export const GET_CUSTOMER_TRANSACTIONS = gql`
  query getMerchantTransactionsByCustomerId($id: ID!, $limit: Int, $offset: Int, $search: String, $filters: FiltersType) {
    getMerchantTransactionsByCustomerId(id: $id, limit: $limit, offset: $offset, search: $search, filters: $filters) {
      data {
        id
        status
        fees
        amount
        createdAt
        txRef
        paidAt
        channel
        paymentRef
        firstName
        lastName
        email
      }
      totalCount
    }
  }
`;

export const GET_CUSTOMER_BY_ID = gql`
  query getSingleMerchantCustomerById($id: ID!) {
    getSingleMerchantCustomerById(id: $id) {
        id
        phone
        firstName
        lastName
        payfiId
        createdAt
        status
        email
        transactionAmount
        transactionCount
    }
  }
`;

export const SEND_SUBMIT_NOTIF = gql`
  query sendMerchantEmailNotification {
    sendMerchantEmailNotification {
      status
      message
    }
  }
`;

export const GET_PAYMENT_LINKS = gql`
  query getMerchantPaymentLinks($limit: Int!, $offset: Int!, $search: String, $filters: FiltersType) {
  getMerchantPaymentLinks(limit: $limit, offset: $offset, search: $search, filters: $filters) {
    count
    paymentLinks {
      id
      name
      title
      description
      configurations
      isPublic
      merchantId
      createdAt
      status
      images {
        url
      }
    }
  }
}
`;

export const VERIFY_URL_NAME = gql`
 query checkIfPaymentLinkNameExist($name: String!) {
  checkIfPaymentLinkNameExist(name: $name)
}
`;

export const GET_PAYMENT_DETAILS = gql`
  query getPaymentLink($name: String!){
  getPaymentLink(name: $name) {
    id
    name
    title
    description
    configurations
    isPublic
    merchantId
    createdAt
    status
    reason
    images {
      url
      id
    }
  }
}
`;

export const GET_PAYMENT_HISTORY = gql`
 query getPaymentlinkHistory($name: String!, $limit: Int!, $offset: Int!, $search: String) {
  getPaymentlinkHistory(name: $name, limit: $limit, offset: $offset, search: $search) {
    count
    rows {
      id
      paymentLinkName
      firstName
      lastName
      amount
      status
      email
      phone
      additionalInformation
      createdAt
    }
  }
}
`;
