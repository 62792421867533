import React from 'react';
import './Dropdown.scss';

interface Props {
  options?: { label: string; value: string }[];
  onClick?: (i: any) => void;
  className?: string;
}

const Dropdown = (props: Props) => (
  <ul className={`dropdown-ul ${props?.className || ''}`}>
    {props?.options?.map((rec, index) => (
      <li
        className="dropdown-list"
        onClick={() => props?.onClick?.(rec)}
        key={index}
      >
        {rec?.label}
      </li>
    ))}
  </ul>
);

export default Dropdown;
