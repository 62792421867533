import React from 'react';

const AddNew = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M7.33 2H16.66C20.06 2 22 3.92 22 7.33V16.67C22 20.06 20.07 22 16.67 22H7.33C3.92 22 2 20.06 2 16.67V7.33C2 3.92 3.92 2 7.33 2ZM12.8201 12.8304H15.6601C16.1201 12.8204 16.4901 12.4504 16.4901 11.9904C16.4901 11.5304 16.1201 11.1604 15.6601 11.1604H12.8201V8.34042C12.8201 7.88042 12.4501 7.51042 11.9901 7.51042C11.5301 7.51042 11.1601 7.88042 11.1601 8.34042V11.1604H8.33008C8.11008 11.1604 7.90008 11.2504 7.74008 11.4004C7.59008 11.5604 7.50008 11.7694 7.50008 11.9904C7.50008 12.4504 7.87008 12.8204 8.33008 12.8304H11.1601V15.6604C11.1601 16.1204 11.5301 16.4904 11.9901 16.4904C12.4501 16.4904 12.8201 16.1204 12.8201 15.6604V12.8304Z" fill="#121212" />
  </svg>

);

export default AddNew;
