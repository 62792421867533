import React from 'react';

const FilterSelectTickIcon = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3 6.66629L5.66742 9.33258L11 4" stroke="#523B9F" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>

);

export default FilterSelectTickIcon;
