import React, { useEffect } from 'react';
import SearchIconBroken from '../../svgs/SearchIconBroken';
import { debounce } from '../../utils/helpers';
import Loading from '../Loading';
import './SearchBox.scss';

type Props = {
  placeholder: string;
  value: string;
  handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  name: string;
  loading?: boolean;
  disabled?:boolean
}

// convert url encoded string to string
const urlDecode = (str: string) => decodeURIComponent(str);

const SearchBox = ({
  name, value, placeholder, handleChange = () => {}, loading,
  disabled,
}: Props) => {
  const [searchValue, setSearchValue] = React.useState(value);

  useEffect(() => {
    setSearchValue(value);
  }, [value]);

  const customHandleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
    // handleChange(e);
    // debounce handle change
    const debouncedHandleChange = debounce(handleChange, 500);
    debouncedHandleChange(e);
  };
  return (
    <div className="search-box">
      <SearchIconBroken />
      <input
        type="text"
        placeholder={placeholder || 'Search...'}
        name={name}
        value={urlDecode(searchValue || '')}
        onChange={customHandleChange}
        disabled={disabled}
        style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
      />
      {loading && <Loading /> }
    </div>
  );
};

export default SearchBox;
