import React from 'react';

interface IShowLessIcon{
  color?:any;
}
const ShowLessIcon = ({ color }:IShowLessIcon) => (
  <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M0.440641 6.43436C0.611495 6.60521 0.888505 6.60521 1.05936 6.43436L6 1.49372L10.9406 6.43436C11.1115 6.60521 11.3885 6.60521 11.5594 6.43436C11.7302 6.2635 11.7302 5.9865 11.5594 5.81564L6.30936 0.565641C6.1385 0.394786 5.8615 0.394786 5.69064 0.565641L0.440641 5.81564C0.269786 5.9865 0.269786 6.2635 0.440641 6.43436Z" fill={color || '#1A1A1A'} />
  </svg>

);

export default ShowLessIcon;
