import { TextField } from '@material-ui/core';
import React, { forwardRef } from 'react';
import NumberFormat from 'react-number-format';

type Props = {
  name?: string;
  label?: string;
  value?: string | number;
  placeholder?: string;
  onChange?: any;
}

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const NumberFormatCustom = forwardRef<any, CustomProps>(
  (props, ref) => {
    const { onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        isNumericString
        prefix="₦ "
      />
    );
  },
);

const AmountInput = ({
  name,
  value,
  placeholder,
  onChange,
}: Props) => (
  <div className="amount-input">
    <TextField
      name={name}
      value={value}
      onChange={onChange}
      variant="outlined"
      style={{
        width: '100%',
        padding: 0,
      }}
      InputProps={{
        inputComponent: NumberFormatCustom as any,
        placeholder,
        style: {
          backgroundColor: 'white',
          fontFamily: 'Inter_Regular',
          fontWeight: 400,
          fontSize: '12px',
          height: '32px',
          padding: 0,
          margin: 0,
          color: !value ? '#98A2B3' : '#667085',
        },
      }}
    />
  </div>
);

export default AmountInput;
