// import { FormControl } from '@material-ui/core';
import { FunctionComponent } from 'react';
import {
  useGlobalState,
} from '../../contexts';
import DashboardWrap from '../../components/DashboardWrap';
import StepsCard from '../../components/StepsCard';
import BankInfo from './BankInfo';
import BusinessContact from './BusinessContact';
import BusInfo from './BusInfo';
import DocumentUpload from './DocumentUpload';

import './Onboarding.scss';
import SendForActivation from './SendForActivation';

export interface IOnboarding {
  label?: string;
}

const Onboarding: FunctionComponent<IOnboarding> = () => {
  const { pos, profile }:any = useGlobalState();
  function renderStep() {
    switch (pos) {
    case 1:
      return <BusInfo />;
    case 2:
      return <BusinessContact />;
    case 3:
      return <DocumentUpload />;
    case 4:
      return <BankInfo />;
    case 5:
      return <BankInfo />;
    default:
      return <BusInfo />;
    }
  }
  const stepNumber = profile?.merchant?.onboardingStage || 1;
  return (
    <DashboardWrap className="dashboard-wrap-children-without-padding">
      <div className="merchant-onboarding-cover row m-0 p-0">
        <div className="col-12 merchant-onboarding-cover-header m-0 p-0">
          <div className="col-12 merchant-onboarding-cover-header-step p-0">
            <StepsCard />
          </div>
          {stepNumber >= 5 && <SendForActivation />}
        </div>
        <div className="col-12 m-0 p-0 merchant-onboarding-cover-content">
          <div
            style={{ paddingBottom: '3em' }}
            className=""
          >
            <div className="">{renderStep()}</div>
          </div>
        </div>
      </div>
    </DashboardWrap>
  );
};

export default Onboarding;
