import React, { useEffect } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import useToast from '../../customHooks/useToast';
// import useToast from '../../customHooks/useToast';
import CreateSuccessIcon from '../../svgs/CreateSuccessIcon';
import ShareCopyIcon from '../../svgs/ShareCopyIcon';
import ShareLinkIcon from '../../svgs/ShareLinkIcon';
import Buttons from '../Buttons';
import RoundedCancleIcon from '../ModalFormComp/RoundedCancleIcon';
import ModalWrapComp from '../ModalWrapComp';
import './PaymentLinkSmallModal.scss';

type IProps = {
  closeModal: any;
  payload: any;
  activateType?: boolean;
  activateShareScreen?:boolean,
  type?:string;
  handleCloseEditModal?:any;
  handleEditBtn?:any
}

const PaymentLinkSmallModal = ({
  closeModal, payload, activateType, activateShareScreen, type, handleCloseEditModal, handleEditBtn,
}: IProps) => {
  // const Toast = useToast();
  const text = `${process.env.REACT_APP_PAYFI_WEBSITE_URL}/pay/${payload?.name}`;
  const Toast = useToast();
  const encodedLink = encodeURI(text);
  const encodedTitle = encodeURIComponent(payload?.title);
  const shareMessage = encodeURIComponent(`Purchase (${payload?.title}) from me using Payfi's Buy Now, Pay Later. Pay here (${text})`);

  const [showShareScreen, setShowShareScreen] = React.useState(false);
  const SocialLinks = [
    {
      shareUrl: `${process.env.REACT_APP_PAYFI_WEBSITE_URL}/pay/${payload?.name}`,
      name: 'Copy',
      icon: <ShareCopyIcon />,
      isAction: true,
    },
    {
      name: 'WhatsApp',
      iconImg: 'https://publicpayfiimages.s3.amazonaws.com/randomAsset/whatsAppIcon_Image_saosal.png',
      shareUrl: `https://api.whatsapp.com/send?text=${shareMessage}`,
    },
    {
      name: 'Twitter',
      iconImg: 'https://publicpayfiimages.s3.amazonaws.com/randomAsset/twitterIcon_Mask_Group-2_fnjgc6.png',
      shareUrl: `https://twitter.com/intent/tweet?text=${shareMessage}`,
    },
    {
      name: 'Facebook',
      iconImg: 'https://publicpayfiimages.s3.amazonaws.com/randomAsset/facebookIcon_Mask_Group-1_p31a76.png',
      shareUrl: `https://www.facebook.com/sharer/sharer.php?u=${encodedLink}&quote=${shareMessage}`,
    },
    {
      name: 'Gmail',
      iconImg: 'https://publicpayfiimages.s3.amazonaws.com/randomAsset/gmailIcon_emailIcon_xubxmy.svg',
      shareUrl: `mailto:?subject=${encodedTitle}&body=${shareMessage}`,
    },
  ];

  const message = activateType ? 'has been activated. Share payment link to accept payments from customers.'
    : (
      <div className="create_payment_link_message">
        has been activated. Share payment link to accept payments from customers.
        <br />
        <div className="create_payment_link_message" style={{ marginTop: '10px' }}>
          To upload more images of your product, kindly click on
          {' '}
          <span style={{ color: '#523B9F' }}>‘Edit Link’</span>
          {' '}
          button.
        </div>
      </div>
    );

  useEffect(() => {
    if (activateShareScreen) {
      setShowShareScreen(true);
    }
  }, [activateShareScreen]);

  return (
    <ModalWrapComp closeModal={closeModal}>
      <div className="">
        <RoundedCancleIcon closeModal={closeModal} />
        {showShareScreen ? (
          <div className="success-payment-link">
            <div style={{ textAlign: 'center' }}>
              <ShareLinkIcon />
            </div>
            <div className="title">

              <h6>Share</h6>
            </div>
            <div className="social-links">
              {SocialLinks.map((item, index) => (item?.isAction ? (
                <div className="link">

                  <CopyToClipboard onCopy={() => Toast.success('Copied to clipboard')} text={item?.shareUrl}>
                    <div className="icon_cover" style={{ cursor: 'pointer' }}>
                      {' '}
                      {item?.icon}
                    </div>
                  </CopyToClipboard>

                  <small>{item.name}</small>
                </div>
              )
                : (
                  <a href={item.shareUrl} className="link" target="_blank" key={index} rel="noreferrer">
                    <img src={item.iconImg} alt={item.name} />
                    <small>{item.name}</small>
                  </a>
                )
              ))}
            </div>
          </div>
        ) : (
          <div className="success-payment-link">
            <div style={{ textAlign: 'center' }}>
              {' '}
              <CreateSuccessIcon />
            </div>
            <div className="title">

              <h6>Payment Link created</h6>
            </div>
            <span className="description">
              Your payment link
              <strong>{` ${payload?.title} `}</strong>
              {message}
            </span>
            <div className="twin-buttons-cover">
              <Buttons
                name="Edit Link"
                className="edit-type"
                onClick={type ? handleCloseEditModal : () => handleEditBtn(payload?.name)}
              />
              <Buttons
                name="Share Link"
                className="save-type"
                type="submit"
                btnStyle="btn-md"
                onClick={() => setShowShareScreen(true)}
              />
            </div>
          </div>
        )}
      </div>
    </ModalWrapComp>
  );
};

export default PaymentLinkSmallModal;
