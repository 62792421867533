import React, { useEffect, useState } from 'react';
import ProgressBar from 'react-animated-progress-bar';
import { Link } from 'react-router-dom';
import ArrowRight from '../../svgs/ArrowRight';
// import { getItemsToLocalStorage } from '../../utils/helpers';

interface IProgressBar{
  progress?:number | string;
  isApproved?:boolean;
  documentSentForActivation?:any;
}
const ProgressCard = (props:IProgressBar) => {
  const [verificationDetails, setVerificationDetails] = useState('Start Verification');
  const [progressCardTitle, setProgressCardTitle] = useState('Start Your Business Verification');
  const [progressCardSubTitle, setProgressCardSubTitle] = useState('');
  const [isPendingApprove, setIsPendingApprove] = useState(false);
  const [progressColor, setProgressColor] = useState({
    trackColor: '#DCD8EC',
    fillColor: '#412F7F',
  });
  const progress = props?.progress;
  // const hasSend = getItemsToLocalStorage('hasSend');

  useEffect(() => {
    if (progress === 100) {
      if (props?.documentSentForActivation) {
        setProgressCardTitle('Account Pending Approval');
        setProgressCardSubTitle('Your application is currently being reviewed and you will be contacted within 1-2 business days on your application status');
        setIsPendingApprove(true);
        setProgressColor({
          trackColor: '#F8C203',
          fillColor: '#F8C203',
        });
      } else {
        setVerificationDetails('Verification Completed');
        setProgressCardTitle('Continue Business Verification');
        setProgressCardSubTitle(`You are ${progress}% done, get back in to gain access to all our services.`);
      }
    } else if (progress < 100 || progress !== 0) {
      setVerificationDetails('Continue verification');
      setProgressCardTitle('Continue Business Verification');
      setProgressCardSubTitle(`You are ${progress}% done, get back in to gain access to all our services.`);
    } else {
      setVerificationDetails('Start Verification');
      setProgressCardTitle('Start Your Business Verification');
      setProgressCardSubTitle(`You are ${progress}% done, get back in to gain access to all our services.`);
    }
  }, [progress]);
  const handleApproveStatus = () => {
    // setProgressCardTitle('Account Pending Approval');
    // eslint-disable-next-line max-len
    // setProgressCardSubTitle('Your application is currently being reviewed and you will be contacted within 1-2 business days on your application status');
    // setIsPendingApprove(true);
    // setProgressColor({
    //   trackColor: '#F8C203',
    //   fillColor: '#F8C203',
    // });
  };
  return (
    <div className="get-started-cover-contents-progress-card">
      <div className="get-started-cover-contents-progress-card-content">
        <div className="progress-card-title">{progressCardTitle}</div>

        <div className="col-md-10 col-12">
          <ProgressBar
            width="100%"
            height="7px"
            rect
            fontColor="gray"
            rectPadding="0px"
            rectBorderRadius="20px"
            percentage={progress}
            trackPathColor={progressColor?.trackColor}
            bgColor={progressColor?.trackColor}
            trackBorderColor={progressColor?.trackColor}
            defColor={{
              fair: progressColor?.fillColor,
              good: progressColor?.fillColor,
              excellent: progressColor?.fillColor,
              poor: progressColor?.fillColor,
            }}
          />
        </div>

        <div className="progress-card-subtitle">
          {progressCardSubTitle}
        </div>
      </div>
      {
        !isPendingApprove && (
        <div className="get-started-cover-contents-progress-card-button">
          {
            progress === 100 && props?.documentSentForActivation ? <span className="get-started-cover-contents-progress-card-button-link" onClick={handleApproveStatus}>{verificationDetails}</span> : <Link to="/onboarding" className="get-started-cover-contents-progress-card-button-link">{verificationDetails}</Link>

          }

          <ArrowRight />
        </div>
        )
      }

    </div>
  );
};
export default ProgressCard;
