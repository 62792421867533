import { FunctionComponent, useEffect, useState } from 'react';
import { FormControl } from '@material-ui/core';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
// import useForm from '../../customHooks/useForm';
// import OtpComp from '../../components/OtpComp';
import OtpInput from 'react-otp-input';
import SaveButton from '../../components/SaveButton';
import {
  useGlobalDispatch,
  useGlobalState,
} from '../../contexts';
import useToast from '../../customHooks/useToast';
import { RESEND_EMAIL, VERIFY_EMAIL_MERCHANT } from '../../graphql/mutations';

import VerifyEmailIcon from '../../svgs/VerifyEmailIcon';
import './VerifyEmailStyle.scss';
import { getItemsToLocalStorage } from '../../utils/helpers';

export interface IVerifyEmail {
  label?: string;
}

const VerifyEmail: FunctionComponent<IVerifyEmail> = () => {
  const { push } = useHistory();
  const Toast = useToast();

  const { profile } = useGlobalState();
  const { setEmailVerifiedTrue } = useGlobalDispatch();
  const getEmailFromStorage = getItemsToLocalStorage('verifyEmail');
  const [otp, setOtp] = useState('');
  // console.log(otp);
  // const {
  //   resetForm,
  //   formValues, handleChange, setError, checkAllRequired,
  // } = useForm({
  //   code: '',
  // });
  const [verifyEmail, { loading: verifyLoading }] = useMutation<
    { createSaving: any; merchantValidateEmail: any },
    { code: string }
  >(VERIFY_EMAIL_MERCHANT);

  const [resendEmail, { loading: resendLoading }] = useMutation<
    { createSaving: any; merchantResendEmailInvite: any },
    { code: string }
  >(RESEND_EMAIL);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (profile?.isEmailVerified) {
      push('/email-verify-successful');
    }
  }, [profile]);
  const handleChange = (otp) => {
    setOtp(otp);
  };

  const handleOnSubmit = async () => {
    setLoading(true);
    if (!otp) {
      setLoading(false);
      Toast.error('Please complete all fields');
      return;
    }

    try {
      // const payload: { code: string } = formValues;
      const payload = {
        code: otp,
      };

      const resp = await verifyEmail({ variables: payload });

      setLoading(false);
      if (resp) {
        const isSuccess = resp?.data?.merchantValidateEmail?.status === 200;

        if (isSuccess) {
          Toast.success('Email verified successfully');
          setEmailVerifiedTrue();
          push('/email-verify-successful');
        } else {
          Toast.error(resp?.data?.merchantValidateEmail?.message);
        }
        setOtp('');
      }
    } catch (e) {
      setLoading(false);
      if (e && e.graphQLErrors && e.graphQLErrors.length > 0) {
        Toast.error(e.graphQLErrors[0].message);
      } else {
        // setError('Something went wrong');
        Toast.error('Something went wrong');
      }
      setOtp('');
    }
  };

  const handleResend = async () => {
    if (loading || resendLoading) return;
    setLoading(true);

    try {
      const resp = await resendEmail();

      setLoading(false);
      if (resp?.data?.merchantResendEmailInvite?.status === 200) {
        Toast.success(
          `Verification code resent successfully to ${profile?.email ? profile?.email : getEmailFromStorage}`,
        );
      }
    } catch (e) {
      setLoading(false);
      if (e && e.graphQLErrors && e.graphQLErrors.length > 0) {
        Toast.error(e.graphQLErrors[0].message);
      } else {
        Toast.error('Something went wrong');
      }
    }
  };

  return (
    <div className="verify-email">
      <form
        className="verify-email-view app-max-width"
        onSubmit={handleOnSubmit}
      >
        <div className="verify-email-view-div container col-lg-4 col-md-5 col-12">
          <div className="verify-email-view-div-icon"><VerifyEmailIcon /></div>
          <div className="verify-email-view-div-title">Please Verify Account</div>
          <div className="verify-email-view-div-subtitle">
            Enter the four digit code we sent to
            {' '}
            <span>{profile?.email ? profile?.email : getEmailFromStorage}</span>
            to verify your new Payfi account.
          </div>
          <FormControl className="d-flex flex-column verify-email-view-div-form">
            <div className="verify-email-view-div-otp">
              <OtpInput
                value={otp}
                onChange={handleChange}
                numInputs={4}
                separator={<span style={{ marginRight: '15px' }} />}
                isInputNum
              />
            </div>
            {/* <OtpComp
              handleChange={handleChange}
              value={formValues.code}
              name="code"
            /> */}
            <div className="verify-email-view-div-form-button">
              <div className="verify-email-view-div-form-button-inner">
                <SaveButton
                  disabled={loading || verifyLoading}
                  name={verifyLoading ? 'Verifying...' : 'Verify'}
                  onClick={handleOnSubmit}
                />
              </div>
            </div>
          </FormControl>
          <div className="verify-email-view-div-resend">
            Didn’t get any mail?
            {' '}
            <span onClick={handleResend}>
              {resendLoading ? 'Resending...' : 'Resend Mail'}
            </span>
          </div>
        </div>
      </form>
    </div>
  );
};

export default VerifyEmail;
