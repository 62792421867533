import { FunctionComponent } from 'react';
import Webhooks from './Webhooks';
import API from './API';

export interface IAPIWebhooks {
  label?: string;
}

const APIWebhooks: FunctionComponent<IAPIWebhooks> = () => (
  <div className="webhook-view-cover container col-lg-10 col-md-12 m-0 p-0">
    <API />
    <Webhooks />
  </div>
);

export default APIWebhooks;
