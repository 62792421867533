import React from 'react';
import moment from 'moment';
import { useMutation } from '@apollo/client';
import { formatCash } from '../../../utils/tableHelpers';
import StyledButton from '../../../components/StyledButton';
import useToast from '../../../customHooks/useToast';
import { MANUAL_LOAN_CREATE } from '../../../graphql/admin_mutations';

type Props = {
  manualLoanPreviewDetails: any;
  loading: boolean;
  setShowPreview: any;
  setLoading: any;
  formValues: any;
  refetch: any;
  setShowSuccessModal: any;
  merchantName: string;
  merchantId: string;
  customerDetails: any;
  scheduleDate: any;
}

const PreviewLoan = ({
  manualLoanPreviewDetails,
  loading,
  setShowPreview,
  customerDetails,
  formValues,
  merchantName,
  setLoading,
  scheduleDate,
  merchantId,
  setShowSuccessModal,
  refetch,
}: Props) => {
  const Toast = useToast();
  const previewDetails = [
    { id: 1, key: 'User Name', value: `${customerDetails?.firstName} ${customerDetails?.lastName}` },
    { id: 2, key: 'Loan Amount', value: `₦${formatCash(manualLoanPreviewDetails?.loanAmount)}` },
    { id: 3, key: 'Purchase Amount', value: `₦${formatCash(formValues?.productAmount)}` },
    { id: 4, key: 'Loan Tenor', value: `${manualLoanPreviewDetails?.tenor}months` },
    { id: 5, key: 'Interest Rate', value: `${Number(manualLoanPreviewDetails?.interestRate) * 100}%` },
    { id: 6, key: 'Merchant', value: merchantName },
    { id: 7, key: 'Product Name', value: formValues?.productName },
    { id: 8, key: 'Processing Fee', value: `₦${formatCash(manualLoanPreviewDetails?.processingFee)}` },
    { id: 9, key: 'Insurance Premium', value: `₦${formatCash(manualLoanPreviewDetails?.insurancePremium)}` },
    { id: 10, key: 'Down-payment', value: `₦${formatCash(manualLoanPreviewDetails?.upfrontPaid)}` },
  ];

  const [createManualLoan] = useMutation<
  { createManualLoan : any },
  any
  >(MANUAL_LOAN_CREATE);

  const handleCreateManualLoan = async () => {
    setLoading(true);
    let input;
    try {
      input = {
        downpaymentPercentage: parseFloat(formValues?.downPaymentPercentage),
        merchantId,
        tenor: Number(formValues?.loanTenor),
        userId: customerDetails?.id,
        purchaseAmount: Number(formValues?.productAmount),
        orderName: formValues?.productName,
        dateDisbursed: scheduleDate,
      };
      const resp = await createManualLoan({
        variables: { input },
      });
      const { status, message } = resp?.data?.createManualLoan;
      if (status === 200) {
        Toast.success(message);
        setLoading(false);
        setShowSuccessModal(true);
        refetch();
      } else {
        Toast.error(message);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      if (e && e.graphQLErrors && e.graphQLErrors.length > 0) {
        Toast.error(e.graphQLErrors[0].message);
      } else {
        Toast.error('Something went wrong');
      }
    }
  };

  return (
    <div className="row">
      <div style={{ height: '400px', overflow: 'auto' }}>
        {previewDetails?.map((data) => (
          <div
            key={data.id}
            style={{
              display: 'flex', alignItems: 'center', justifyContent: 'space-between', fontSize: '14px', color: '#7575A3', borderBottom: '1px solid #F5F5F5', marginTop: '20px',
            }}
          >
            <p>{data.key}</p>
            <p style={{ color: '#121212', fontWeight: '600' }}>{data.value}</p>
          </div>
        ))}
        {
          manualLoanPreviewDetails?.repaymentSchedule.map(
            (repayment: any, index: number) => (
              <div
                style={{
                  display: 'flex', alignItems: 'center', justifyContent: 'space-between', fontSize: '14px', color: '#7575A3', borderBottom: '1px solid #F5F5F5', marginTop: '20px',
                }}
                key={repayment.installmentIndex || index + 1}
              >
                <p>
                  {`Amount due on ${moment(repayment.dueDate).format(
                    'DD/MM/YYYY',
                  )}`}
                </p>
                <p style={{ color: '#121212', fontWeight: '600' }}>
                  ₦
                  {formatCash(repayment?.amountDue)}
                </p>
              </div>
            ),
          )
        }
      </div>
      <div className="create_loan_btn_cover" style={{ marginTop: '40px' }}>
        <div>
          <StyledButton name="Back" width="60px" backgroundColor="white" color="#5941A9" border="1px solid #E6E7EC" fontSize="12px" borderRadius="4px" height="35px" onClick={() => setShowPreview(false)} />
        </div>
        <div>
          <StyledButton name={loading ? 'Processing...' : 'Create Loan'} width="90px" backgroundColor="#5941A9" color="white" border="none" fontSize="12px" borderRadius="4px" height="35px" onClick={handleCreateManualLoan} disabled={loading} />
        </div>
      </div>
    </div>
  );
};

export default PreviewLoan;
