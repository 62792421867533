/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable max-len */
import React, { useState } from 'react';
import ListIcon from '../../svgs/ListIcon';
import MerchantDropdown from '../MerchantDropdown';
import './SpecialMobileTable.scss';

interface ISpecialMobileTable{
  content?:any;
  allowKeyArr?:any
  handleSelectDropdown?:any;
  dropdownData?:any
}
const SpecialMobileTable = ({
  content, allowKeyArr, handleSelectDropdown, dropdownData,
}:ISpecialMobileTable) => allowKeyArr?.map((list: any, index: number) => {
  const text = content?.[list];
  const isLogo = text?.includes('image');

  const checkIfEleIsArray = Array?.isArray(list);
  const [dropdownState, setdropdownState] = useState({
    status: false,
    id: '',
  });
  const handleAndClearDropdowState = (title:any, details:any) => {
    handleSelectDropdown(title, details);
    setdropdownState({
      status: false,
      id: '',
    });
  };

  const handleShowDropdown = (id:any) => {
    if (dropdownState?.status) {
      setdropdownState({
        status: false,
        id: '',
      });
    } else {
      setdropdownState({
        id,
        status: true,

      });
    }
  };

  return (
    <div className="special_mobile_cover" key={index}>

      <div>
        {isLogo && (
        <div className="mobile_view_image_div">
          <img
            src={content?.[list]}
            alt="logo"
            style={{
              width: '32px', height: '32px', borderRadius: '4px',
            }}
          />
        </div>
        )}
        {checkIfEleIsArray
          && list?.map((values: any, indexData: any) => (
            <div>
              {
                values !== 'link' ? (
                  <div key={indexData} className={`mobile_view_double_rec ${content?.[values]}`}>
                    {content?.[values]}
                  </div>
                ) : (
                  <div key={indexData} className={`mobile_view_double_rec ${content?.[values]}`} style={{ textDecoration: 'underline', fontSize: '12px', color: 'rgba(129, 137, 158, 1)' }}>
                    {content?.[values]}
                  </div>
                )

              }
              {
                values === 'listIcon' && (
                  <span onClick={() => handleShowDropdown(content?.id)} style={{ cursor: 'pointer', position: 'absolute', right: '0%' }}>
                    <ListIcon />
                  </span>
                )
              }
            </div>
          ))}
      </div>

      {/* <div className="">
        <span onClick={() => handleShowDropdown(content?.id)} style={{ cursor: 'pointer' }}>
          <ListIcon />
        </span>
        {
          dropdownState?.status && dropdownState?.id === content?.id && (
            <div style={{
              position: 'absolute', background: 'white', right: '10%', width: '96px', zIndex: 1,
            }}
            >
              {' '}
              <MerchantDropdown data={dropdownData} details={content} handleDropdown={handleAndClearDropdowState} fontSize="9px" />
            </div>
          )
        }

      </div> */}
      {
        dropdownState?.status && dropdownState?.id === content?.id && (
        <div style={{
          position: 'absolute', background: 'white', right: 'auto', width: '96px', zIndex: 1,
        }}
        >
          {' '}
          <MerchantDropdown data={dropdownData} details={content} handleDropdown={handleAndClearDropdowState} fontSize="9px" />
        </div>
        )
      }

    </div>
  );
});
export default SpecialMobileTable;
