/* eslint-disable max-len */
import React from 'react';
import UseCustomResizer from '../../components/CustomResizer';
import LabelValueComp from '../../components/LabelValueComp';
import LoanScheduleForMobile from './LoanScheduleForMobile';

type Props = {
  loanSchedules: {
    dueDate: string;
     amountDue: string;
      status: string;
       name: string
      }[]
}

const LoanSchedules = ({ loanSchedules }: Props) => {
  // split loanSchedules sinto two halves
  const midNumber = Math.ceil(loanSchedules.length / 2);
  const half1 = loanSchedules.slice(0, midNumber);
  const half2 = loanSchedules.slice(midNumber);
  const { isMobile } = UseCustomResizer({ width: 600 });
  return (
    <div className="loan-schedules">
      <div className="card-title">
        <h5>
          Payments
        </h5>
      </div>
      {loanSchedules?.length ? (
        <div className="loan-schedule-card">
          <div className="halves half-1">
            {half1.map((loanSchedule, index) => (
              isMobile ? <LoanScheduleForMobile key={index} label={loanSchedule.name} value={loanSchedule.amountDue} status={loanSchedule.status} /> : (
                <LabelValueComp
                  key={index}
                  label={loanSchedule.name}
                  value={loanSchedule.amountDue}
                  listStyle
                  status={loanSchedule.status}
                />
              )
            ))}
          </div>
          <div className="halves half-2">
            {half2.map((loanSchedule, index) => (
              isMobile ? <LoanScheduleForMobile key={index} label={loanSchedule.name} value={loanSchedule.amountDue} status={loanSchedule.status} /> : (
                <LabelValueComp
                  key={index}
                  label={loanSchedule.name}
                  value={loanSchedule.amountDue}
                  listStyle
                  status={loanSchedule.status}
                />
              )
            ))}
          </div>
        </div>
      )
        : (
          <div className="empty-text">
            <span>Loan payment breakdown is not available for this transaction</span>
          </div>
        )}
    </div>
  );
};

export default LoanSchedules;
