import React from 'react';
import './AvaterImage.scss';

interface AvaterDetails{
    alt?:any;
    image?:any;
    width?:any;
    height?:any;
}
const AvaterImage = (props: AvaterDetails) => (
  <div
    className="avater-cover"
    style={{
      width: props?.width ? props?.width : '34px',
      height: props?.height ? props?.height : '34px',
      backgroundColor: props?.alt ? '#523B9F' : undefined,
      borderRadius: '50%',
    }}
  >
    {
      props?.image ? <img src={props?.image} alt={props?.alt} /> : (
        <strong>
          {props?.alt}
        </strong>
      )
    }
  </div>
);

export default AvaterImage;
