import React from 'react';
import { ApolloProvider } from '@apollo/client';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import './custom.scss';
import MerchantRouter from './screens/route';
import AdminRouter from './admin_screens/route';
import client from './config/graphql';
import ErrorBoundary from './components/ErrorBoundary';

const Router = process.env.REACT_APP_TYPE === 'ADMIN' ? AdminRouter : MerchantRouter;

const App = () => (
  <ErrorBoundary>
    <ApolloProvider client={client}>
      <div className="">
        <Router />
      </div>
    </ApolloProvider>
  </ErrorBoundary>
);

export default App;
