/* eslint-disable max-len */
import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import useGetQueryParams from './useGetQueryParams';

type Props = {
  allowedFilters: string[];
  defaultUrl: string;
  options?: any;
  cb?: (filters: IFilterValues) => void;
  Toast?: any;
  customFilterInformation?: any;
  pageAndSearchIncluded?: boolean;
}
type IFilterValues = {
  from?: string;
  to?: string;
  fromDate?: string;
  toDate?: string;
  min?: number | string;
  max?: number | string;
  minCharge?: number | string;
  maxCharge?: number | string;
  status?: string[];
  channel?: string[];
  type?: string[];
  category?:string[];
  product?: string[];
  tenor?: string[];
  partner?:string[];
  stage?:string[];
  paymentType?:string[];
  paymentMethod?:string[];
  groupUsers?:string[]
  appType?: string[]
};

const sanitizeFilters = (filters: IFilterValues) => {
  const result = { };
  Object.keys(filters).forEach((key) => {
    result[key] = ['min', 'max', 'minCharge', 'maxCharge'].includes(key) ? Number(filters[key] || 0) : filters[key];
  });
  return result;
};

const removeInvalidFieldsFromFIlter = (result, allowedFilters) => {
  const newResult = {};
  Object.keys(result).forEach((key) => {
    if (allowedFilters.includes(key)) {
      newResult[key] = result[key];
    }
  });
  return newResult;
};

const useFilterMethods = ({
  allowedFilters: inpAllowedFilters,
  defaultUrl,
  options = {},
  cb,
  Toast,
  customFilterInformation: customFilterInfoInp = {},
  pageAndSearchIncluded = true,
}: Props) => {
  const [customFilterInformation, setCustomFilterInformation] = useState(customFilterInfoInp);
  const [allowedFilters, setAllowedFilters] = useState(inpAllowedFilters);
  const { push } = useHistory();
  const {
    getQuery,
    updateOrAddSearchParams,
    search: searchQuery,
    getSearchParams,
  } = useGetQueryParams(pageAndSearchIncluded);

  const includeAmount = allowedFilters.includes('amountRange');
  const includeDate = allowedFilters.includes('dateRange');
  const includeAlternativeDate = allowedFilters.includes('alternativeDateRange');
  const includeStatus = allowedFilters.includes('status');
  const includeProduct = allowedFilters.includes('product');
  const includeChannel = allowedFilters.includes('channel');
  const includeTenor = allowedFilters.includes('tenor');
  const includeCharges = allowedFilters.includes('charges');
  const includeType = allowedFilters.includes('type');
  const includeCategory = allowedFilters.includes('category');
  const includePartner = allowedFilters?.includes('partner');
  const includeStage = allowedFilters?.includes('stage');
  const includePaymentType = allowedFilters?.includes('paymentType');
  const includePaymentMethod = allowedFilters?.includes('paymentMethod');
  const includeGroupUsers = allowedFilters?.includes('groupUsers');
  const includeAppType = allowedFilters?.includes('appType');
  const defaultFilter: IFilterValues = {
  };
  if (includeDate) {
    defaultFilter.from = '';
    defaultFilter.to = '';
  }
  if (includeAlternativeDate) {
    defaultFilter.fromDate = '';
    defaultFilter.toDate = '';
  }
  if (includeAmount) {
    defaultFilter.min = 0;
    defaultFilter.max = 0;
  }
  if (includeStatus) {
    defaultFilter.status = [];
  }
  if (includeTenor) {
    defaultFilter.tenor = [];
  }
  if (includeProduct) {
    defaultFilter.product = [];
  }
  if (includeChannel) {
    defaultFilter.channel = [];
  }
  if (includeCategory) {
    defaultFilter.category = [];
  }
  if (includeCharges) {
    defaultFilter.minCharge = 0;
    defaultFilter.maxCharge = 0;
  }
  if (includeType) {
    defaultFilter.type = [];
  }
  if (includePartner) {
    defaultFilter.partner = [];
  }
  if (includeStage) {
    defaultFilter.stage = [];
  }
  if (includePaymentType) {
    defaultFilter.paymentType = [];
  }
  if (includePaymentMethod) {
    defaultFilter.paymentMethod = [];
  }
  if (includeGroupUsers) {
    defaultFilter.groupUsers = [];
  }
  if (includeAppType) {
    defaultFilter.appType = [];
  }

  const [filters, setFilters] = useState(defaultFilter);

  const handleFilterChange = (name: string, value: any) => {
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  useEffect(() => {
    const result = {
      from: getSearchParams('from') || '',
      to: getSearchParams('to') || '',
      fromDate: getSearchParams('fromDate') || '',
      toDate: getSearchParams('toDate') || '',
      min: getSearchParams('min') || '',
      max: getSearchParams('max') || '',
      status: getSearchParams('status') || [],
      product: getSearchParams('product') || [],
      channel: getSearchParams('channel') || [],
      category: getSearchParams('category') || [],
      tenor: getSearchParams('tenor') || [],
      type: getSearchParams('type') || [],
      partner: getSearchParams('partner') || [],
      stage: getSearchParams('stage') || [],
      minCharge: getSearchParams('minCharge') || '',
      maxCharge: getSearchParams('maxCharge') || '',
      paymentType: getSearchParams('paymentType') || '',
      paymentMethod: getSearchParams('paymentMethod') || '',
      groupUsers: getSearchParams('groupUsers') || [],
      appType: getSearchParams('appType') || [],
    };
    setFilters(removeInvalidFieldsFromFIlter(result, Object.keys(defaultFilter)));
  }, [searchQuery]);

  const allFilters = useMemo(() => (
    {
      dateRange: {
        type: 'dateRange',
        from: filters?.from,
        to: filters?.to,
        setFrom: (res) => handleFilterChange('from', res),
        setTo: (res) => handleFilterChange('to', res),
        setFilters: (res) => setFilters(res),
        ...(customFilterInformation?.dateRange || {}),
      },
      alternativeDateRange: {
        type: 'alternativeDateRange',
        fromDate: filters?.fromDate,
        toDate: filters?.toDate,
        setFromDate: (res) => handleFilterChange('fromDate', res),
        setToDate: (res) => handleFilterChange('toDate', res),
        setFilters: (res) => setFilters(res),
        ...(customFilterInformation?.alternativeDateRange || {}),
      },
      status: {
        type: 'selectBox',
        selectedOptions: filters?.status || [],
        onChange: (opt) => handleFilterChange('status', opt),
        label: 'Status',
        placeholder: 'Select Status',
        options: options?.statusOptions || [
          { label: 'Successful', value: 'success' },
          { label: 'Pending', value: 'pending' },
          { label: 'Failed', value: 'failed' },
        ],
        ...(customFilterInformation?.status || {}),
      },
      product: {
        type: 'selectBox',
        selectedOptions: filters?.product || [],
        onChange: (opt) => handleFilterChange('product', opt),
        label: 'Product',
        placeholder: 'Select Product',
        options: [],
        ...(customFilterInformation?.product || {}),
      },
      paymentType: {
        type: 'selectBox',
        selectedOptions: filters?.paymentType || [],
        onChange: (opt) => handleFilterChange('paymentType', opt),
        label: 'Payment Type',
        placeholder: 'Select Payment Type',
        options: [
          { label: 'BNPL', value: 'bnpl' },
          { label: 'Credit', value: 'CREDIT' }],
        ...(customFilterInformation?.paymentType || {}),
      },
      paymentMethod: {
        type: 'selectBox',
        selectedOptions: filters?.paymentMethod || [],
        onChange: (opt) => handleFilterChange('paymentMethod', opt),
        label: 'Payment Method',
        placeholder: 'Select Payment Method',
        options: [
          { label: 'BNPL', value: 'bnpl' },
          { label: 'Card', value: 'Card' },
          { label: 'One time', value: 'one time' },
        ],
        ...(customFilterInformation?.paymentMethod || {}),
      },
      partner: {
        type: 'selectBox',
        selectedOptions: filters?.partner || [],
        onChange: (opt) => handleFilterChange('partner', opt),
        label: 'Partner',
        placeholder: 'Select Partner',
        options: options?.statusOptions || [
          { label: 'PAYFI', value: 'PAYFI' },
          { label: 'FCMB', value: 'FCMB' },
          { label: 'CDL', value: 'CDL' },
          { label: 'Ren Money', value: 'ren money' },
        ],
        ...(customFilterInformation?.partner || {}),
      },
      stage: {
        type: 'selectBox',
        selectedOptions: filters?.stage || [],
        onChange: (opt) => handleFilterChange('stage', opt),
        label: 'Stage',
        placeholder: 'Select Stage',
        options: [],
        ...(customFilterInformation?.stage || {}),
      },
      channel: {
        type: 'selectBox',
        selectedOptions: filters?.channel || [],
        onChange: (opt) => handleFilterChange('channel', opt),
        label: 'Channel',
        placeholder: 'Select Channel',
        options: options?.statusOptions || [
          { label: 'SDK', value: 'sdk' },
          { label: 'Card', value: 'card' },
          { label: 'POS', value: 'POS' },
        ],
        ...(customFilterInformation?.channel || {}),
      },
      tenor: {
        type: 'selectBox',
        selectedOptions: filters?.tenor || [],
        onChange: (opt) => handleFilterChange('tenor', opt),
        label: 'Tenure',
        placeholder: 'Select Tenure',
        options: options?.statusOptions || [
          { label: '1 Month', value: 1 },
          { label: '2 Months', value: 2 },
          { label: '3 Months', value: 3 },
          { label: '4 Months', value: 4 },
          { label: '5 Months', value: 5 },
          { label: '6 Months', value: 6 },
        ],
        ...(customFilterInformation?.tenor || {}),
      },
      category: {
        type: 'selectBox',
        selectedOptions: filters?.category || [],
        onChange: (opt) => handleFilterChange('category', opt),
        label: 'Category',
        placeholder: 'Select Category',
        options: [],
        ...(customFilterInformation?.category || {}),
      },
      groupUsers: {
        type: 'selectBox',
        selectedOptions: filters?.groupUsers || [],
        onChange: (opt) => handleFilterChange('groupUsers', opt),
        label: 'Target Users',
        placeholder: 'Select Target Users',
        options: [],
        ...(customFilterInformation?.groupUsers || {}),
      },
      type: {
        type: 'selectBox',
        selectedOptions: filters?.type || [],
        onChange: (opt) => handleFilterChange('type', opt),
        label: 'Page Type',
        placeholder: 'Select Page Type',
        options: options?.statusOptions || [
          { label: 'Single Charge', value: 'Single Charge' },
        ],
        ...(customFilterInformation?.type || {}),
      },
      amountRange: {
        type: 'amountRange',
        min: filters?.min,
        max: filters?.max,
        setMin: (res) => handleFilterChange('min', res),
        setMax: (res) => handleFilterChange('max', res),
        ...(customFilterInformation?.amountRange || {}),
      },
      charges: {
        type: 'amountRange',
        min: filters?.minCharge,
        max: filters?.maxCharge,
        label: 'Charge Amount',
        setMin: (res) => handleFilterChange('minCharge', res),
        setMax: (res) => handleFilterChange('maxCharge', res),
        ...(customFilterInformation?.charges || {}),
      },
      appType: {
        type: 'selectBox',
        selectedOptions: filters?.appType || [],
        onChange: (opt) => handleFilterChange('appType', opt),
        label: 'Payfi App type',
        placeholder: 'Select App type',
        options: [],
        ...(customFilterInformation?.appType || {}),
      },
    }
  ), [filters, options, customFilterInformation]);

  const filterArray = useMemo(() => {
    const arr = [];
    (allowedFilters).forEach((filter) => {
      if (allFilters[filter]) {
        arr.push(allFilters[filter]);
      }
    });
    return arr;
  }, [allowedFilters, filters, options, customFilterInformation]);

  const validateFilters = () => {
    try {
      const sanitizedFilter: any = sanitizeFilters(filters);
      if (includeAmount) {
        if (sanitizedFilter.min > sanitizedFilter.max) {
          throw new Error('Min amount should be less than max amount');
        }
        if (sanitizedFilter.min < 0 || sanitizedFilter.max < 0) {
          throw new Error('Amount should be greater than 0');
        }
        if (sanitizedFilter.min && !sanitizedFilter.max) {
          throw new Error('Please enter max amount');
        }
        if (!sanitizedFilter.min && sanitizedFilter.max) {
          throw new Error('Please enter min amount');
        }
      }
      if (includeDate) {
        if (new Date(sanitizedFilter.from).getTime() > new Date(sanitizedFilter.to).getTime()) {
          throw new Error('From date should be less than To date');
        }
        if (sanitizedFilter?.from && !sanitizedFilter?.to) {
          throw new Error('Please select To date');
        }
        if (sanitizedFilter?.to && !sanitizedFilter?.from) {
          throw new Error('Please select From date');
        }
      }
      if (includeAlternativeDate) {
        if (new Date(sanitizedFilter.fromDate).getTime() > new Date(sanitizedFilter.toDate).getTime()) {
          throw new Error('From date should be less than To date');
        }
        if (sanitizedFilter?.fromDate && !sanitizedFilter?.toDate) {
          throw new Error('Please select To date');
        }
        if (sanitizedFilter?.toDate && !sanitizedFilter?.fromDate) {
          throw new Error('Please select From date');
        }
      }
      if (includeCharges) {
        if (sanitizedFilter.minCharge > sanitizedFilter.maxCharge) {
          throw new Error('Min charge amount should be less than max amount');
        }
        if (sanitizedFilter.minCharge < 0 || sanitizedFilter.maxCharge < 0) {
          throw new Error('Charge amount should be greater than 0');
        }
        if (sanitizedFilter.minCharge && !sanitizedFilter.maxCharge) {
          throw new Error('Please enter max charge amount');
        }
        if (!sanitizedFilter.minCharge && sanitizedFilter.maxCharge) {
          throw new Error('Please enter min charge amount');
        }
      }
      return true;
    } catch (e) {
      Toast?.error?.(e.message);
      return false;
    }
  };

  const filterObj = {
    resetFilters: () => {
      setFilters(defaultFilter);
      cb?.(defaultFilter);
      push(defaultUrl);
    },
    filters: filterArray,
    submitFilters: (onClose) => {
      // closseFilter
      const isSuccess = validateFilters();
      if (isSuccess) {
        updateOrAddSearchParams(filters);
        cb?.(filters);
        onClose?.();
      }
    },
  };

  return {
    getQuery,
    updateOrAddSearchParams,
    search: searchQuery,
    getSearchParams,
    filterObj,
    filters,
    setAllowedFilters,
    allowedFilters,
    setCustomFilterInformation,
    customFilterInformation,
    allFiltersData: allFilters,
  };
};

export default useFilterMethods;
