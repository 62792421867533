import React from 'react';

const ActivatePaymentLinkIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.1628 7.71859C14.4408 7.71859 14.6668 7.49258 14.6668 7.21458V5.50192C14.6668 2.92792 13.0602 1.33325 10.5022 1.33325H5.50216C2.92816 1.33325 1.3335 2.92792 1.3335 5.50392V10.5039C1.3335 13.0719 2.92816 14.6666 5.50216 14.6666H10.5042C13.0722 14.6666 14.6668 13.0719 14.6648 10.5019C14.6648 10.2206 14.4362 9.99192 14.1548 9.99192C13.8728 9.99192 13.6448 10.2206 13.6448 10.5019C13.6448 12.5246 12.5308 13.6446 10.5022 13.6446H5.50216C3.4735 13.6446 2.3535 12.5246 2.3535 10.5019V5.50192C2.3535 3.47325 3.4735 2.35325 5.50416 2.35325H10.5042C12.5335 2.35325 13.6468 3.46658 13.6468 5.50192V7.19659V7.19792C13.6475 7.47925 13.8755 7.70659 14.1568 7.70659V7.71859H14.1628Z" fill="#12B76A" />
    <path d="M5 7.94155L7.00056 9.88309L11 6" stroke="#12B76A" strokeLinecap="round" strokeLinejoin="round" />
  </svg>

);
export default ActivatePaymentLinkIcon;
