import React from 'react';

interface ICheckStateIcon{
    checkState?:boolean;
    handleClick?:any;
}
const CheckStateIcon = ({ checkState, handleClick }:ICheckStateIcon) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={handleClick} style={{ cursor: 'pointer' }}>
    <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" fill="white" />
    <path d="M10.8114 5.20393L6.8989 9.11739L5.18856 7.40613C4.91665 7.13422 4.47584 7.13422 4.20393 7.40613C3.93202 7.67804 3.93202 8.11884 4.20393 8.39075L6.40613 10.5939C6.53698 10.7238 6.7133 10.7971 6.8989 10.7971C7.08265 10.7971 7.2599 10.7238 7.39075 10.5939L11.7961 6.18856C12.068 5.91665 12.068 5.47584 11.7961 5.20393C11.5242 4.93202 11.0833 4.93202 10.8114 5.20393Z" fill={checkState ? '#523B9F' : '#D0D5DD'} />
    <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" stroke={checkState ? '#523B9F' : '#D0D5DD'} />
  </svg>

);
export default CheckStateIcon;
