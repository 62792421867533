import React from 'react';

const PasswordShield = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M7.98938 15.2372C7.98938 15.2372 13.9303 13.4832 13.9303 8.64787C13.9303 3.8118 14.1456 3.43427 13.6693 2.96916C13.1923 2.50405 8.76839 1 7.98938 1C7.21037 1 2.78642 2.50405 2.31018 2.96916C1.83318 3.43427 2.04845 3.8118 2.04845 8.64787C2.04845 13.4832 7.98938 15.2372 7.98938 15.2372Z" stroke="#98A2B3" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M5.97656 7.88936L7.44166 9.32017L10.4601 6.37549" stroke="#98A2B3" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>

);
export default PasswordShield;
