import React from 'react';

const TrxPendingIcon = () => (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y="0.5" width="24" height="24" rx="12" fill="#FFFAEB" />
    <path fillRule="evenodd" clipRule="evenodd" d="M11 7.91484C11 7.40985 11.4098 7 11.9148 7C12.4198 7 12.8297 7.40985 12.8297 7.91484V13.3063C12.8297 13.8113 12.4198 14.2211 11.9148 14.2211C11.4098 14.2211 11 13.8113 11 13.3063V7.91484ZM11.9152 16.2608H11.9274C12.4324 16.2608 12.8422 16.6707 12.8422 17.1757C12.8422 17.6807 12.4324 18.0905 11.9274 18.0905C11.4224 18.0905 11.0064 17.6807 11.0064 17.1757C11.0064 16.6707 11.4102 16.2608 11.9152 16.2608Z" fill="#F79009" />
  </svg>
);

export default TrxPendingIcon;
