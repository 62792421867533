/* eslint-disable max-len */
import React from 'react';
import SummaryDetailsCover from '../SummaryDetailsCover';
import { formatCash } from '../../utils/tableHelpers';
import './ItemsComp.scss';
import EmptyImageIcon from '../../svgs/EmptyImageIcon';

interface IItemsComp{
  orderItems:any;
  status?:string;
  width?:string;
}
const ItemsComp = ({ orderItems, width }:IItemsComp) => (
  <SummaryDetailsCover width="100%" maxWidth={width}>
    <div className="item-comp-cover">
      <div className="items-comp-title">
        Items
      </div>
      <div className="row item-comp-body">
        {orderItems?.length ? (
          orderItems?.map((list, index) => (
            <div className="item-comp-details-cover" key={index}>
              <div className="img-wrap">
                { list?.options?.image ? <img src={list?.options?.image} alt="" /> : <EmptyImageIcon />}
              </div>
              <aside>
                <div className="flexed-between mb-1 align-items-start">
                  <div className="item-comp-details-title">
                    {list?.orderName}
                  </div>

                  <div className="item-comp-details-value">
                    {formatCash(list?.amount || 0, 'N')}
                  </div>
                </div>
                <div className="flexed-between">
                  <div className="flexed-row flex-grow-1">
                    {
                      !!list?.options?.options?.length && (
                        <div className="item-comp-details-options-cover">
                          {
                            list?.options?.options?.map((option:any) => (
                              <small className="item-comp-details-options">
                                {option[option?.attr]}
                              </small>
                            ))
                          }
                        </div>
                      )
                    }
                  </div>
                  <span>
                    Qty:
                    {' '}
                    {list?.quantity || 1}
                  </span>
                </div>
              </aside>

            </div>
          ))
        )
          : <span className="no-items"> No Items available</span>}
      </div>
    </div>

  </SummaryDetailsCover>

);

export default ItemsComp;
