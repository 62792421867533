import { FunctionComponent } from 'react';
import { useGlobalState } from '../../contexts';
import AppBarDropDown from '../../svgs/AppBarDropDown';
import AppBarNotificationIcon from '../../svgs/AppBarNotificationIcon';
import MobileMenuIcon from '../../svgs/MobileMenuIcon';

import { getItemsToLocalStorage, getNameInitials } from '../../utils/helpers';
import AvatarImage from '../AvaterImage';

import './TopNav.scss';

export interface ITopNav {
  label?: string;
  dropdownObj?: any;
}

const MerchantTopNav: FunctionComponent<ITopNav> = ({ dropdownObj }) => {
  // const [search, setSearch] = useState('');

  const { profile, showTopDropdown }:any = useGlobalState();
  const { email, firstName, lastName } = profile;
  const initials = getNameInitials(email);
  const mobileTitleBar = getItemsToLocalStorage('nav-title');

  const handleToggleMenu = () => {
    dropdownObj?.setShowSideBar(true);
  };
  const handleTopDropDown = () => {
    if (showTopDropdown) {
      dropdownObj?.setShowTopDropdown(false);
    } else {
      dropdownObj?.setShowTopDropdown(true);
    }
  };
  const avatar = profile?.imageUrl || '';
  return (
    <div className="merchant-navbar-cover d-flex">
      <div className="merchant-navbar-cover-menu">
        <span onClick={handleToggleMenu}>
          <MobileMenuIcon />
        </span>

      </div>
      <div className="merchant-navbar-cover-mobile-title">{mobileTitleBar}</div>
      <div className="merchant-navbar-cover-details d-flex">
        <div className="merchant-navbar-cover-details-avatar" onClick={handleTopDropDown}>
          <span>
            { avatar
              ? <AvatarImage alt={initials} image={avatar} />
              : (
                <div className="avatar-initials">
                  {getNameInitials(profile?.firstName)}
                </div>
              )}
          </span>
          <div className="merchant-navbar-cover-details-avatar-names">
            <div className="merchant-navbar-cover-details-avatar-names-title">{profile?.role?.name}</div>
            <div className="merchant-navbar-cover-details-avatar-names-subtitle">
              {`${firstName} ${lastName}`}
              <span><AppBarDropDown /></span>
            </div>
          </div>
        </div>
        <div className="merchant-navbar-cover-details-icon">
          <AppBarNotificationIcon />
        </div>

      </div>
    </div>
  );
};
export default MerchantTopNav;
