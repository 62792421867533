import React from 'react';
import './CheckboxNew.scss';
import CheckIcon from './CheckIconNew';
// import Loading from '../Loading';
type Props = {
  id?: string;
  children?: any;
  name: string;
  onClick?: (arg?: any) => void;
  onChange?: (arg?: any) => void;
  value: boolean;
  loading?: boolean;
  backgroundColor?:string;
  iconColor?:string;
  height?:string;
};
const CheckboxNew = ({
  id,
  children,
  name,
  onClick,
  onChange,
  value,
  loading,
  backgroundColor,
  iconColor,
  height,
}: Props) => (
  <div className="checkbox-new" style={{ height }}>
    <input
      id={id}
      name={name}
      checked={value}
      onChange={onChange}
      type="checkbox"
      disabled={loading}
    />
    <label onClick={() => !loading && onClick && onClick()} htmlFor={id}>
      {loading ? (
        <div />
      ) : (
        <div className="checkIcon" style={{ backgroundColor }}>
          <div className="checked flexed-center">
            <CheckIcon color={iconColor} />
          </div>
        </div>
      )}
      <span>{children}</span>
    </label>
  </div>
);

export default CheckboxNew;
