import React from 'react';

const TransactionIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M2.89813 5.84136C2.89813 6.11593 2.67013 6.33951 2.39013 6.33951C2.11013 6.33951 1.88279 6.11593 1.88279 5.84136V4.60187C1.88279 3.16888 3.07146 2.00196 4.53213 2.00065L5.61813 2H5.61879C5.89879 2 6.12613 2.22292 6.12613 2.49815C6.12613 2.77272 5.89946 2.99564 5.61879 2.99564L4.53346 2.9963C3.63146 2.9976 2.89813 3.71737 2.89813 4.60187V5.84136ZM10.4009 2.99545H11.4629C12.3675 2.99545 13.1035 3.71717 13.1035 4.60429V5.84182C13.1035 6.11573 13.3309 6.33931 13.6109 6.33931C13.8909 6.33931 14.1182 6.11573 14.1182 5.84182V4.60429C14.1182 3.16803 12.9275 2.00046 11.4629 2.00046H10.4009C10.1209 2.00046 9.89353 2.22273 9.89353 2.49795C9.89353 2.77252 10.1209 2.99545 10.4009 2.99545ZM14.8263 8.02746H1.17433C0.894992 8.02746 0.666992 8.25038 0.666992 8.5256C0.666992 8.80017 0.894992 9.0231 1.17433 9.0231H1.88233V11.3975C1.88233 12.8311 3.07166 13.998 4.53299 13.9993L5.61833 14C5.89899 14 6.12566 13.7771 6.12633 13.5019C6.12633 13.2273 5.89899 13.0044 5.61899 13.0044L4.53366 13.0037C3.63166 13.0024 2.89766 12.2826 2.89766 11.3975V9.0231H13.1037V11.3955C13.1037 12.2833 12.3677 13.0044 11.463 13.0044H10.401C10.121 13.0044 9.89366 13.2273 9.89366 13.5019C9.89366 13.7771 10.121 14 10.401 14H11.463C12.927 14 14.1183 12.8324 14.1183 11.3955V9.0231H14.8263C15.1063 9.0231 15.3337 8.80017 15.3337 8.5256C15.3337 8.25038 15.1063 8.02746 14.8263 8.02746ZM6.76153 6.33951H9.23953C9.52019 6.33951 9.74753 6.11593 9.74753 5.84136C9.74753 5.56679 9.52019 5.34387 9.23953 5.34387H6.76153C6.48153 5.34387 6.25419 5.56679 6.25419 5.84136C6.25419 6.11593 6.48153 6.33951 6.76153 6.33951ZM6.76173 10.516H9.23973C9.51973 10.516 9.74706 10.7389 9.74706 11.0135C9.74706 11.2887 9.51973 11.511 9.23973 11.511H6.76173C6.48106 11.511 6.25373 11.2887 6.25373 11.0135C6.25373 10.7389 6.48106 10.516 6.76173 10.516Z" fill="#475467" />
  </svg>

);

export default TransactionIcon;
