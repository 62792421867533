import React from 'react';

const DeactivateRolesIcon = () => (
  <svg width="10" height="13" viewBox="0 0 10 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.77191 5.2334C7.77191 5.2334 7.56645 8.6009 7.44726 10.0194C7.39051 10.6969 7.0738 11.0939 6.55505 11.1064C5.56786 11.1299 4.57953 11.1314 3.59272 11.1039C3.09364 11.0904 2.78224 10.6884 2.72661 10.0229C2.60667 8.5919 2.40234 5.2334 2.40234 5.2334" stroke="#FF5959" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8.29545 3.61914H1.87891" stroke="#FF5959" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M7.05907 3.6195C6.76205 3.6195 6.50626 3.342 6.44799 2.9575L6.35605 2.3495C6.29929 2.069 6.10707 1.875 5.88799 1.875H4.28632C4.06723 1.875 3.87502 2.069 3.81826 2.3495L3.72632 2.9575C3.66805 3.342 3.41226 3.6195 3.11523 3.6195" stroke="#FF5959" strokeLinecap="round" strokeLinejoin="round" />
  </svg>

);
export default DeactivateRolesIcon;
