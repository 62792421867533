import { FunctionComponent } from 'react';
import { useGlobalDispatch, useGlobalState } from '../../contexts';

import './Steps.scss';

export interface ISteps {
  stepNumber?: number;
}

const Steps: FunctionComponent<ISteps> = ({
  stepNumber,
}) => {
  const { setPos }:any = useGlobalDispatch();
  const processChangeStep = (currentStep: number) => {
    setPos(currentStep);
  };

  const stepsArray = ['Business Profile', 'Owner\'s Details', 'Business Documents', 'Bank Information'];
  const stepObject = (stepInView: number) => {
    const stepNum = stepNumber < 1 ? 1 : stepNumber;
    let stepState = '';

    if (stepInView < stepNum) {
      stepState = 'completed';
    } else if (stepInView === stepNum) {
      stepState = 'current';
    } else {
      stepState = 'disabled';
    }

    const stepColors = {
      completed: '#00C48C',
      current: '#523B9F',
      disabled: '#98A2B3',
    };
    const stateObj: {[key: string]: any} = {
      completed: {
        borderStyle: `1px solid ${stepColors.completed}`,
        innerBackgroundColor: stepColors.completed,
        showCompleted: true,
        textColor: stepColors.completed,
        onClick: () => processChangeStep(stepInView),
      },
      current: {
        borderStyle: `1px solid ${stepColors.current}`,
        innerBackgroundColor: 'transparent',
        showCompleted: false,
        textColor: stepColors.current,
        onClick: () => processChangeStep(stepInView),
      },
      disabled: {
        borderStyle: `1px solid ${stepColors.disabled}`,
        innerBackgroundColor: 'transparent',
        showCompleted: false,
        textColor: stepColors.disabled,
        onClick: () => {},
      },
    };
    return stateObj[stepState];
  };

  return (
    <div className="onboarding-steps row m-0 p-0">
      {
        stepsArray?.map((name, index) => {
          const stepInfo = stepObject(index + 1);
          const { pos }:any = useGlobalState();
          const stepsList = index + 1;
          return (
            <div className="onboarding-step col-md col m-0 p-0">
              <div
                className="circle"
                style={{
                  backgroundColor: 'transparent',
                }}
              >
                <div
                  onClick={stepInfo?.onClick}
                  className="inner-circle"
                  style={{
                    border: stepInfo?.borderStyle,
                    backgroundColor: stepInfo?.innerBackgroundColor,
                  }}
                >
                  {stepInfo?.showCompleted ? (
                    <svg
                      width="10"
                      height="7"
                      viewBox="0 0 15 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.707 8.293L1.414 5L0 6.414L4.707 11.121L14.414 1.414L13 0L4.707 8.293Z"
                        fill="white"
                      />
                    </svg>
                  ) : (
                    <span style={{ color: stepInfo?.textColor }}>{index + 1}</span>
                  )}
                </div>
              </div>
              <p
                onClick={stepInfo?.onClick}
                style={{ color: stepInfo?.textColor }}
                className={pos !== stepsList && 'mobile-step-name-tag'}
              >
                {name}
              </p>
            </div>
          );
        })
      }
    </div>
  );
};

export default Steps;
