import React from 'react';

const DeactivateStaff = () => (
  <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M4.13417 9.08673C2.545 9.08673 1.1875 8.84631 1.1875 7.88381C1.1875 6.92132 2.53625 6.0459 4.13417 6.0459C5.72333 6.0459 7.08083 6.91298 7.08083 7.87507C7.08083 8.83715 5.73208 9.08673 4.13417 9.08673Z" stroke="#FF5959" strokeWidth="0.625" strokeLinecap="round" strokeLinejoin="round" />
    <path fillRule="evenodd" clipRule="evenodd" d="M4.13486 4.67301C5.17777 4.67301 6.02361 3.82717 6.02361 2.78426C6.02361 1.74092 5.17777 0.895508 4.13486 0.895508C3.09194 0.895508 2.24611 1.74092 2.24611 2.78426C2.24236 3.82342 3.08236 4.66926 4.12152 4.67301H4.13486Z" stroke="#FF5959" strokeWidth="0.625" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8.82331 4.22201H7.11914" stroke="#FF5959" strokeWidth="0.625" strokeLinecap="round" strokeLinejoin="round" />
  </svg>

);
export default DeactivateStaff;
