import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import useForm from '../../customHooks/useForm';
import useToast from '../../customHooks/useToast';
import { DEACTIVATE_PAYMENT_LINK } from '../../graphql/mutations';
import ErrorLinkIcon from '../../svgs/ErrorLinkIcon';
import SuccessIcon from '../../svgs/SuccessIcon';
import Buttons from '../Buttons';
import RoundedCancleIcon from '../ModalFormComp/RoundedCancleIcon';
import ModalWrapComp from '../ModalWrapComp';
import './PaymentLinkSmallModal.scss';

type Props = {
  closeModal: any;
  payload: any;
  refetch: any;
}

const DeactivateTypeAndComment = ({ closeModal, payload, refetch }: Props) => {
  const { formValues, handleChange } = useForm({
    reason: '',
  });
  const Toast = useToast();
  const [deactivateLink] = useMutation<
{ deactivateMerchantPaymentLink: any },
any
>(DEACTIVATE_PAYMENT_LINK);

  const [loading, setLoading] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const handleDeactivate = async () => {
    setLoading(true);
    try {
      if (!formValues?.reason) {
        Toast.error('Please enter a reason for deactivating the link');
        setLoading(false);
        return;
      }
      const resp = await deactivateLink({
        variables: {
          name: payload?.name,
          reason: formValues?.reason,
        },
      });

      if (resp?.data?.deactivateMerchantPaymentLink?.id) {
        Toast.success('Link deactivated successfully');
        refetch();
        setLoading(false);
        setShowSuccessMessage(true);
        return;
      }
      Toast.error('Error deactivating link');
    } catch (error) {
      Toast.error('Error deactivating link');
    }
    setLoading(false);
  };

  return (
    <ModalWrapComp closeModal={closeModal}>
      <div>
        <RoundedCancleIcon closeModal={closeModal} />
        <div className="success-payment-link">
          {showSuccessMessage
            ? (
              <>
                <div className="success-icon">
                  <SuccessIcon />
                </div>
                <h6>Message Saved!</h6>
                <span className="reason">
                  Your reason to deactivate this payment link has been saved
                  successfully and will show when users click on the link.
                </span>
              </>
            )
            : (
              <>
                <div style={{ textAlign: 'center', marginBottom: '7px' }}>
                  {' '}
                  <ErrorLinkIcon />
                </div>
                <div className="title">

                  <span>Tell us why you are deactivating this link</span>
                </div>
                <textarea
                  onChange={handleChange}
                  name="reason"
                  value={formValues.reason}
                  placeholder="Enter your comment"
                  className="comment-input"
                />
                <div className="twin-buttons-cover deactivate-type mt-2">
                  <Buttons
                    name="Cancel"
                    className="cancel-type"
                    onClick={closeModal}
                    disabled={loading}
                  />
                  <Buttons
                    name={loading ? 'Loading...' : 'Save'}
                    className="save-type"
                    type="submit"
                    btnStyle="btn-md"
                    onClick={handleDeactivate}
                    disabled={loading}
                  />
                </div>
              </>
            )}
        </div>
      </div>
    </ModalWrapComp>
  );
};

export default DeactivateTypeAndComment;
