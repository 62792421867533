import React from 'react';

const TrxSuccessIcon = () => (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y="0.5" width="24" height="24" rx="12" fill="#DFF8E7" fillOpacity="0.5" />
    <path d="M6 12.5595L9.6677 16.119L17 9" stroke="#4CAF6E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>

);

export default TrxSuccessIcon;
