import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import useGetQueryParams from '../../customHooks/useGetQueryParams';
import useCustomQuery from '../../network/useCustomQuery';
import { GET_CUSTOMER_TRANSACTIONS } from '../../graphql/queries';
import { cleanUpTransactions } from '../../utils/helpers';
import Table from '../../components/Table';
import DashboardHeader from '../../components/DashboardHeader';
import EmptyRecord from '../../components/EmptyRecord';
import generatePDF from '../../utils/generatePdf';
import generateCSV from '../../utils/generateCSV';
import useToast from '../../customHooks/useToast';
import useFilterMethods from '../../customHooks/useFilterMethods';

type Props = {
  customerId
}
const limit = 10;
const CustomerTransactions = ({ customerId }: Props) => {
  const [getTransactions, loading, rawData] = useCustomQuery({
    query: GET_CUSTOMER_TRANSACTIONS,
    onError: () => {},
    onData: () => {},
  });
  const Toast = useToast();

  const { getQuery, getFilterQueries } = useGetQueryParams();
  const search = getQuery('search') || '';
  const page = Number(getQuery('page'));
  const { push } = useHistory();

  const setPage = (pageNum) => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('page', pageNum);
    push({
      search: searchParams.toString(),
    });
  };

  if (!page) {
    push(`/transactions/${customerId}?page=1&search=${search}`);
  }

  const [data, setData] = useState(null);
  useEffect(() => {
    if (rawData?.getMerchantTransactionsByCustomerId?.data) {
      setData(rawData);
    }
  }, [rawData]);

  const onSearchChange = (e) => {
    push(`/customers/${customerId}?page=1&search=${encodeURIComponent(e.target.value)}`);
  };

  useEffect(() => {
    if (page) {
      const result = getFilterQueries();
      getTransactions({
        variables: {
          offset: (page - 1) * limit,
          limit,
          id: customerId,
          search,
          filters: {
            from: result.from,
            to: result.to,
            status: result.status,
            channel: result.channel,
            minAmount: result.min ? Number(result.min) : 0,
            maxAmount: result.max ? Number(result.max) : 0,
          },
        },
      });
    }
  }, [page, customerId, search]);

  const [getExportRecord] = useCustomQuery({
    query: GET_CUSTOMER_TRANSACTIONS,
    onError: () => {},
    onData: () => {},
  });

  const handleExport = async (type: string) => {
    try {
      const result = getFilterQueries();
      const output = await getExportRecord({
        variables: {
          offset: 0,
          limit: 2000,
          search,
          id: customerId,
          filters: {
            from: result.from,
            to: result.to,
            status: result.status,
            channel: result.channel,
            minAmount: result.min ? Number(result.min) : 0,
            maxAmount: result.max ? Number(result.max) : 0,
          },
        },
      });
      if (output?.data?.getMerchantTransactionsByCustomerId?.data) {
        const rec = cleanUpTransactions(output?.data?.getMerchantTransactionsByCustomerId?.data);
        const func = type === 'pdf' ? generatePDF : generateCSV;
        func({
          payload: rec,
          title: 'CUSTOMER-TRANSACTIONS',
          selectedHeaders: [
            'S/N',
            'trxAmount',
            'charges',
            'dateTime',
            'status',
            'channel',
            'txRef',
          ],
          formatHeaders: {
            dateTime: 'Date Purchased',
            txRef: 'Reference No',
            trxAmount: 'Amount',
            charges: 'Fees',
          },
          cb: () => Toast.success('Exported successfully'),
        });
      }
    } catch (error) {
      Toast.error('Error while exporting');
    }
  };

  const onFilterSubmit = (result) => {
    getTransactions({
      variables: {
        offset: 0,
        limit,
        id: customerId,
        search,
        filters: {
          from: result.from,
          to: result.to,
          status: result.status,
          channel: result.channel,
          minAmount: result.min ? Number(result.min) : 0,
          maxAmount: result.max ? Number(result.max) : 0,
        },
      },
    });
  };

  const {
    filterObj,
  } = useFilterMethods({
    allowedFilters: ['dateRange', 'status', 'amountRange'],
    defaultUrl: `/customers/${customerId}?page=1&search=`,
    cb: onFilterSubmit,
    Toast,
  });

  return (
    <div className="customer-trx">
      <DashboardHeader
        title="Transactions"
        smallSubTitle={`(${data?.getMerchantTransactionsByCustomerId?.totalCount || 0})`}
        searchPlaceholder="Look for a transaction"
        filterObj={filterObj}
        exportObj={{
          handleExport,
        }}
        search={search}
        onSearchChange={onSearchChange}
        searchLoading={loading}
      />
      {data?.getMerchantTransactionsByCustomerId?.totalCount ? (
        <Table
          refreshing={loading}
          page={page}
          setPage={setPage}
          pageSize={limit}
          count={data?.getMerchantTransactionsByCustomerId.totalCount}
          contents={cleanUpTransactions(data?.getMerchantTransactionsByCustomerId.data)}
          reference="id"
          hideDominantImage
          clickable
          handleClick={(data) => push(`/transactions/${data?.id}`)}
          allowKeyArr={[
            'trxAmount',
            'charges',
            ['date', 'time'],
            'status',
            'channel',
            'txRef',
          ]}
          formatLabels={{
            date: 'Date Purchased',
            txRef: 'Reference No',
            trxAmount: 'Amount',
            charges: 'Fees',
          }}
          mobileAllowKeyArr={[
            'status',
            ['txRef', 'date', 'time'],
            ['trxAmount', 'charges'],
          ]}
        />
      ) : (
        <EmptyRecord
          height="calc(100vh - 550px)"
          title="No transactions yet"
          main="All transactions from customers on your Payfi will appear here"
          footer="Receive Payments"
        />
      )}
    </div>
  );
};

export default CustomerTransactions;
