import React from 'react';
import AmountInput from './AmountInput';

type Props = {
  min: number | string;
  max: number | string;
  setMin: any;
  setMax: any;
  label?: string;
}

const FilterAmount = (props: Props) => {
  const handleChange = ({ target: { name, value } }) => {
    if (name === 'min') {
      props.setMin(value);
    } else {
      props.setMax(value);
    }
  };
  return (
    <div className="filter-amount-input">
      <span className="filter-input-label">{props?.label || 'Amount'}</span>
      <div className="twin-input">
        <AmountInput
          name="min"
          label="Min"
          value={props.min}
          placeholder="₦ 0.00"
          onChange={handleChange}
        />
        <div className="delimiter" />
        <AmountInput
          name="max"
          label="Max"
          value={props.max}
          placeholder="₦ 0.00"
          onChange={handleChange}
        />
      </div>
    </div>
  );
};

export default FilterAmount;
