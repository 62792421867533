import { FunctionComponent, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import useToast from '../../../customHooks/useToast';
import { SAVE_BUSINESS_DOCUMENT } from '../../../graphql/mutations';

import {
  useGlobalDispatch,
  useGlobalState,
} from '../../../contexts';
import UploadInputKYC from '../../../components/UploadInputKYC';

import './DocUpload.scss';
import TextInput from '../../../components/input';
import useForm from '../../../customHooks/useForm';
import StyledButton from '../../../components/StyledButton';
import {
  businessDocuments, getDocByName, documentIsValid,
} from '../../../utils/helpers';

export interface IDocumentUpload {
  label?: string;
}

const DocumentUpload: FunctionComponent<IDocumentUpload> = () => {
  const { profile }:any = useGlobalState();
  const disableAllForm = profile?.merchant?.documentSentForActivation;
  const { setStep, get_profile_in_app }:any = useGlobalDispatch();
  const [kycDocs, setKycDocs] = useState<any>({
    cacDoc: null,
    certificateOfIncorporation: null,
    proofOfAddress: null,
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [docsLoading, setDocsLoading] = useState(false);
  const Toast = useToast();
  const {
    formValues,
    handleChange,
    resetForm,
  } = useForm({
    registrationNumber: '',
    tinNumber: '',
  });
  const businessType = profile?.merchant?.businessType?.toLowerCase?.();
  const checkValidity = () => {
    const docsvalid = documentIsValid(businessType, kycDocs);
    return docsvalid && formValues?.registrationNumber;
  };

  const [saveDocuments] = useMutation<
    { createSaving: any; saveBusinessDocumentInformation: any },
    any
  >(SAVE_BUSINESS_DOCUMENT);

  useEffect(() => {
    const docs = profile?.merchant?.documents;
    if (profile?.merchant) {
      resetForm({
        registrationNumber: profile?.merchant?.registrationNumber,
        tinNumber: profile?.merchant?.tinNumber,
      });
    }
    if (docs) {
      const merchantDocs = {
        SCUMLCertificate: getDocByName('SCUMLCertificate', docs),
        certificateOfIncorporation: getDocByName('certificateOfIncorporation', docs),
        memorandumAndArticlesOfAssociation: getDocByName('memorandumAndArticlesOfAssociation', docs),
        CACStatusReport: getDocByName('CACStatusReport', docs),
        certificateOfBusinessName: getDocByName('certificateOfBusinessName', docs),
      };
      setKycDocs((prev: any) => ({
        ...prev,
        ...merchantDocs,
      }));
    }
  }, [profile]);

  const handleSubmit = async () => {
    const isValid = checkValidity();
    if (!isValid) {
      Toast.error('Please upload all required documents and information');
    }
    setDocsLoading(true);
    try {
      const resp = await saveDocuments({
        variables: {
          input: {
            tinNumber: formValues.tinNumber,
            registrationNumber: formValues.registrationNumber,
          },
        },
      });

      if (resp?.data?.saveBusinessDocumentInformation?.status === 200) {
        // set success message
        Toast.success('Successful');
        get_profile_in_app();
        setStep(4);
      } else {
        Toast.error(resp?.data?.saveBusinessDocumentInformation?.message);
      }
    } catch (e) {
      setDocsLoading(false);
      if (e && e.graphQLErrors && e.graphQLErrors.length > 0) {
        Toast.error(e.graphQLErrors[0].message);
      } else {
        Toast.error('Something went wrong');
      }
    }
    setDocsLoading(false);
  };

  const handlePrev = () => {
    setStep(2);
  };

  return (
    <div className="document-view-cover">
      <div className="document-view-cover-title">Supply Business Documents</div>
      <div className="document-view-cover-subtitle">These are documents pertaining to your business</div>
      <div className="row m-0 p-0 document-view-cover-form">
        <div className="col-md-6 col-12 m-0 p-0 document-view-cover-form-profile-col-left">
          <TextInput
            customOnChange={handleChange}
            name="registrationNumber"
            value={formValues.registrationNumber}
            label="Business Name Number"
            width="100%"
            disabled={disableAllForm}
          />
        </div>
        <div className="col-md-6 col-12 m-0 p-0 document-view-cover-form-profile-col-right">
          <TextInput
            customOnChange={handleChange}
            name="tinNumber"
            value={formValues.tinNumber}
            label="Tax Identification Number"
            width="100%"
            disabled={disableAllForm}
          />
        </div>
        <div className="flexed-row identity-docs-title-cover">
          <strong className="identity-docs-title">Identifaction Documents</strong>
          <div className="identity-docs-subtitle">(JPEG, PNG, PDF. Max file size: 2mb)</div>
        </div>
        {businessDocuments?.(profile?.merchant?.businessType, kycDocs)?.map((docRec, index) => (
          <div key={index} className={`col-md-6 col-12 m-0 p-0 document-view-cover-form-profile-col-${index % 2 === 0 ? 'left' : 'right'}`}>
            <UploadInputKYC
              name={docRec.name}
              label={docRec.label}
              filename={docRec.filename}
              fileInp={docRec?.fileInp}
              isOptional={docRec.isOptional}
              disabled={disableAllForm}
            />
          </div>
        ))}
        <div className="col-12 p-0 m-0 document-view-cover-form-button-div">
          <span>
            <StyledButton
              onClick={handlePrev}
              color="#523B9F"
              backgroundColor="white"
              fontSize="13px"
              name="Previous"
              width="130px"
              height="40px"
              borderRadius="4px"
              border="1px solid #DCD8EC"
            />
          </span>
          <StyledButton
            disabled={disableAllForm || !checkValidity()}
            onClick={handleSubmit}
            color="white"
            backgroundColor="#523B9F"
            fontSize="13px"
            name={docsLoading ? 'Saving...' : 'Save & Proceed'}
            width="130px"
            height="40px"
            borderRadius="4px"
            border="none"
          />
        </div>
      </div>
    </div>
  );
};

export default DocumentUpload;
