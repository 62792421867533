import React from 'react';

const FileIcon = () => (
  <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M8.16138 0.496589L11.5284 4.03765C11.6175 4.13161 11.6673 4.25645 11.6673 4.38665V10.6291C11.6673 12.3037 10.3321 13.6668 8.68976 13.6668H3.38067C1.64331 13.6668 0.333984 12.3607 0.333984 10.6291V8.94721C0.333984 8.66935 0.557301 8.44384 0.83246 8.44384C1.10762 8.44384 1.33093 8.66935 1.33093 8.94721V10.6291C1.33093 11.8057 2.19296 12.6601 3.38067 12.6601H8.68976C9.78242 12.6601 10.6704 11.7486 10.6704 10.6291V4.58867L8.12549 1.91274V2.79063C8.12549 3.46447 8.66849 4.01349 9.33578 4.01483C9.61094 4.0155 9.83359 4.24101 9.83293 4.51954C9.83226 4.79673 9.60961 5.02157 9.33445 5.02157H9.33379C8.11817 5.01889 7.12853 4.01818 7.12853 2.79063V1.3483H3.38067C2.2694 1.3483 1.33093 2.26376 1.33093 3.34769V6.21087C1.33093 6.48874 1.10762 6.71425 0.83246 6.71425C0.557301 6.71425 0.333984 6.48874 0.333984 6.21087V3.34769C0.333984 1.71811 1.72905 0.34155 3.38067 0.34155H7.58846C7.59485 0.340936 7.60068 0.339198 7.60639 0.337493C7.61317 0.335471 7.6198 0.333496 7.62701 0.333496C7.63479 0.333496 7.64174 0.33558 7.64867 0.337657C7.65418 0.339309 7.65967 0.340956 7.66556 0.34155H7.80181C7.93739 0.34155 8.067 0.397257 8.16138 0.496589ZM5.38031 6.0146C5.38031 5.73674 5.60362 5.51123 5.87878 5.51123C6.15394 5.51123 6.37726 5.73674 6.37726 6.0146V7.15625H7.5078C7.78296 7.15625 8.00627 7.38176 8.00627 7.65962C8.00627 7.93749 7.78296 8.163 7.5078 8.163H6.37726V9.30398C6.37726 9.58184 6.15394 9.80735 5.87878 9.80735C5.60362 9.80735 5.38031 9.58184 5.38031 9.30398V8.163H4.25043C3.97527 8.163 3.75195 7.93749 3.75195 7.65962C3.75195 7.38176 3.97527 7.15625 4.25043 7.15625H5.38031V6.0146Z" fill="#98A2B3" />
  </svg>

);

export default FileIcon;
