import { FunctionComponent, useEffect, useState } from 'react';
import './Customers.scss';
import { useHistory } from 'react-router-dom';
import DashboardWrap from '../../components/DashboardWrap';
import { GET_MERCHANT_CUSTOMERS } from '../../graphql/queries';
import Table from '../../components/Table';
import { cleanUpCustomersOverview } from '../../utils/helpers';
import useCustomQuery from '../../network/useCustomQuery';
import useGetQueryParams from '../../customHooks/useGetQueryParams';
import DashboardHeader from '../../components/DashboardHeader';
import EmptyCustomerIcon from '../../svgs/EmptyCustomerIcon';
import EmptyRecord from '../../components/EmptyRecord';
import useToast from '../../customHooks/useToast';
import generatePDF from '../../utils/generatePdf';
import generateCSV from '../../utils/generateCSV';
import useFilterMethods from '../../customHooks/useFilterMethods';

const limit = 15;

const Customers: FunctionComponent = () => {
  const Toast = useToast();
  const [getCustomers, getCustomersLoading, rawData] = useCustomQuery({
    query: GET_MERCHANT_CUSTOMERS,
    onError: () => {},
    onData: () => {},
  });

  const { getQuery, getFilterQueries } = useGetQueryParams();
  const search = getQuery('search') || '';
  const page = Number(getQuery('page'));
  const { push } = useHistory();
  const setPage = (pageNum) => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('page', pageNum);
    push({
      search: searchParams.toString(),
    });
  };
  if (!page) {
    push(`/customers?page=1&search=${search}`);
  }

  const [customers, setCustomers] = useState(null);
  useEffect(() => {
    if (rawData?.getMerchantCustomers?.data) {
      setCustomers(rawData);
    }
  }, [rawData]);

  const onSearchChange = (e) => {
    push(`/customers?page=1&search=${encodeURIComponent(e.target.value)}`);
  };

  useEffect(() => {
    const result = getFilterQueries();
    if (page) {
      getCustomers({
        variables: {
          offset: (page - 1) * limit,
          limit,
          search,
          filters: {
            from: result.from,
            to: result.to,
            status: result.status,
            minAmount: result.min ? Number(result.min) : 0,
            maxAmount: result.max ? Number(result.max) : 0,
          },
        },
      });
    }
  }, [page, search]);

  const [getExportRecord] = useCustomQuery({
    query: GET_MERCHANT_CUSTOMERS,
    onError: () => {},
    onData: () => {},
  });

  const handleExport = async (type: string) => {
    try {
      const result = getFilterQueries();
      const output = await getExportRecord({
        variables: {
          offset: 0,
          limit: 2000,
          search,
          filters: {
            from: result.from,
            to: result.to,
            status: result.status,
            minAmount: result.min ? Number(result.min) : 0,
            maxAmount: result.max ? Number(result.max) : 0,
          },
        },
      });
      if (output?.data?.getMerchantCustomers?.data) {
        const rec = cleanUpCustomersOverview(output?.data?.getMerchantCustomers?.data);
        const func = type === 'pdf' ? generatePDF : generateCSV;
        func({
          payload: rec,
          title: 'CUSTOMERS',
          selectedHeaders: [
            'S/N',
            'name',
            'email',
            'transactionAmount',
            'customerRef',
            'phone',
            'dateTime',
            'status',
          ],
          formatHeaders: {
            dateTime: 'Created On',
            phone: 'Phone No',
            customerRef: 'Reference',
            email: 'Email Address',
            transactionAmount: 'Total Transaction',
          },
          cb: () => Toast.success('Exported successfully'),
        });
      }
    } catch (error) {
      Toast.error('Error while exporting');
    }
  };

  const onFilterSubmit = (result) => {
    getCustomers({
      variables: {
        offset: 0,
        limit,
        search,
        filters: {
          from: result.from,
          to: result.to,
          status: result.status,
          minAmount: result.min ? Number(result.min) : 0,
          maxAmount: result.max ? Number(result.max) : 0,
        },
      },
    });
  };

  const {
    filterObj,
  } = useFilterMethods({
    allowedFilters: ['dateRange', 'status', 'amountRange'],
    defaultUrl: '/customers?page=1',
    cb: onFilterSubmit,
    Toast,
    customFilterInformation: {
      dateRange: {
        label: 'Date joined',
      },
      status: {
        label: 'Customer Status',
        options: [{
          label: 'Active',
          value: 1,
        },
        {
          label: 'Inactive',
          value: 0,
        },
        ],
      },
      amountRange: {
        label: 'Total transaction amount range',
      },
    },
  });

  return (
    <div className="customers-page">
      <DashboardWrap>
        <DashboardHeader
          title="Customers"
          smallSubTitle={`(${customers?.getMerchantCustomers?.totalCount || 0})`}
          searchPlaceholder="Search by email, phone number, name and reference"
          filterObj={filterObj}
          exportObj={{
            handleExport,
          }}
          search={search}
          onSearchChange={onSearchChange}
          searchLoading={getCustomersLoading}
        />
        {customers?.getMerchantCustomers?.totalCount ? (
          <Table
            page={page}
            pageSize={limit}
            setPage={setPage}
            refreshing={getCustomersLoading}
            count={customers?.getMerchantCustomers?.totalCount}
            contents={cleanUpCustomersOverview(customers?.getMerchantCustomers?.data)}
            reference="customerId"
            imageToShow="image"
            clickable
            minHeight="calc(100vh - 280px)"
            handleClick={(data) => push(`/customers/${data?.customerId}?page=1`)}
            allowKeyArr={[
              'name',
              'email',
              'transactionAmount',
              'customerRef',
              'phone',
              ['date', 'time'],
              'status',
            ]}
            mobileAllowKeyArr={[
              'status',
              ['name',
                'email'],
              [
                'transactionAmount',
                'customerRef',
              ],
            ]}
            formatLabels={{
              date: 'Created On',
              phone: 'Phone No',
              customerRef: 'Reference',
              email: 'Email Address',
              transactionAmount: 'Total Transaction',
            }}
            emptyCompObj={{
              title: 'You have no customer yet',
              text1:
            'This is were you’ll see the customer and the transactions they paid for.',
            }}
          />
        ) : (
          <EmptyRecord
            height="calc(100vh - 240px)"
            title="No Customers Yet"
            main="The details of your customers shopping through Payfi will appear here"
            // footer="Add Customer"
            Icon={EmptyCustomerIcon}
            loading={getCustomersLoading}
          />
        )}
      </DashboardWrap>
    </div>
  );
};

export default Customers;
