import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import useToast from '../../customHooks/useToast';
import { ACTIVATE_PAYMENT_LINK } from '../../graphql/mutations';
import CreateSuccessIcon from '../../svgs/CreateSuccessIcon';
import Buttons from '../Buttons';
import RoundedCancleIcon from '../ModalFormComp/RoundedCancleIcon';
import ModalWrapComp from '../ModalWrapComp';
import './PaymentLinkSmallModal.scss';

type Props = {
  closeModal: any;
  payload: any;
  onActivateClick: any;
  refetch?: any;
}

const ActivateType = ({
  closeModal, payload, onActivateClick,
}: Props) => {
  const Toast = useToast();
  const [activateLink] = useMutation<
{ activateMerchantPaymentLink: any },
any
>(ACTIVATE_PAYMENT_LINK);

  const [loading, setLoading] = useState(false);

  const handleActivate = async () => {
    setLoading(true);
    try {
      const resp = await activateLink({
        variables: {
          name: payload?.name,
        },
      });

      if (resp?.data?.activateMerchantPaymentLink?.id) {
        Toast.success('Link activated successfully');
        setLoading(false);
        onActivateClick?.();
        closeModal();
        return;
      }
      Toast.error('Error activating link');
    } catch (error) {
      Toast.error('Error activating link');
    }
    setLoading(false);
  };

  return (
    <ModalWrapComp closeModal={closeModal}>

      <div>
        <RoundedCancleIcon closeModal={closeModal} />
        <div className="success-payment-link">
          <div style={{ textAlign: 'center' }}>
            {' '}
            <CreateSuccessIcon />
          </div>
          <div className="title">

            <h6>{payload?.title}</h6>
          </div>
          <span className="description">
            Your payment link
            {' '}
            <strong>{` ${payload?.title} `}</strong>
            {' '}
            has been activated.
            You will be able to edit this link and republish changes.

            {/* Are you sure you want to activate your payment link
            <strong>{` ${payload?.title} `}</strong>
            ? Activate payment link to accept payments from customers. */}
          </span>
          <div className="twin-buttons-cover">
            <Buttons
              name="Cancel"
              className="cancel-type"
              onClick={closeModal}
              disabled={loading}
            />
            <Buttons
              name={loading ? 'Loading...' : 'Activate Link'}
              className="save-type"
              type="submit"
              btnStyle="btn-md"
              onClick={handleActivate}
              disabled={loading}
            />
          </div>
        </div>
      </div>
    </ModalWrapComp>
  );
};

export default ActivateType;
