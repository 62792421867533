import { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import DashboardWrap from '../../components/DashboardWrap';
import CustomerCard from './CustomerCard';
import './Overview.scss';
import TransactionVolume from './TransactionVolume';
import { GET_OVERVIEW } from '../../graphql/queries';
import useCustomQuery from '../../network/useCustomQuery';
import DashboardHeader from '../../components/DashboardHeader';
import { useGlobalState } from '../../contexts';
import ChevronRightIcon from '../../svgs/ChevronRightIcon';
import OtherBalances from './OtherBalances';
import RightArrow from '../../svgs/RightArrow';

const Dashboard: FunctionComponent = () => {
  const [getOverview, getOverviewLoading, overview] = useCustomQuery({
    query: GET_OVERVIEW,
    onError: () => {},
    onData: () => {},
  });
  const { profile }:any = useGlobalState();
  const { push } = useHistory();

  return (
    <div className="overview-contain">
      <DashboardWrap>
        <DashboardHeader
          title={`Hello, ${profile?.firstName}`}
          smallSubTitle=""
          subtitle="Here’s an overview of your Payfi account"
          specialClass="add-flex-direction"
          isDashboardChildren
        >
          {
            !profile?.merchant?.isApproved && (
            <div onClick={() => push('/home')} className="activate-acct pointer">
              <span>Activate account</span>
              <ChevronRightIcon />
            </div>
            )
          }

        </DashboardHeader>
        <div className="overview-merchant">
          <TransactionVolume />
          <div className="balance-cover">
            <OtherBalances
              getOverview={getOverview}
              payload={overview?.getMerchantOverview?.transactionBalances}
              loading={getOverviewLoading}
            />
            <div className="customer-card overview-card">
              <div className="overview-title">
                <div className="overview-title-span-title"> Top Customers</div>
                <div className="overview-title-span-view-more">
                  View All
                  {' '}
                  <RightArrow width="10px" height="9px" />
                </div>
              </div>
              <CustomerCard
                loading={getOverviewLoading}
                payload={overview?.getMerchantOverview?.customers?.slice(0, 4)}
              />
            </div>
          </div>
        </div>
      </DashboardWrap>
    </div>
  );
};

export default Dashboard;
