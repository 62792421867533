const pdfMake = require('pdfmake/build/pdfmake');
const moment = require('moment');
const pdfFonts = require('pdfmake/build/vfs_fonts');

type IGeneratePDF = {
  payload: any,
  title: string,
  selectedHeaders: any,
  type?:string;
  cb: () => void,
  formatHeaders?: any
}
const generatePDF = ({
  payload, title, selectedHeaders, type, cb = () => {}, formatHeaders,
}: IGeneratePDF) => {
  pdfMake.vfs = pdfFonts.pdfMake.vfs;
  // const printer = new Printer(fonts);
  if (!payload.length) return;
  const mockBody = [];

  const headers = selectedHeaders.map((headerRaw) => {
    const header = Array.isArray(headerRaw) ? headerRaw[0] : headerRaw;
    return ({
      text: (formatHeaders ? (formatHeaders[header] || header) : header)?.toUpperCase?.(),
      style: 'tableHeader',
      width: headerRaw === 'S/N' ? 300 : 'auto',
    });
  });
  mockBody.push(headers);
  payload.forEach((data, index) => {
    mockBody.push(selectedHeaders.map((key) => {
      const value = Array.isArray(key) ? `${data[key[0]] || ''} ${data[key[1]] || ''}` : data[key];
      return ({
        text: key === 'S/N' ? index + 1 : value,
        style: 'tableCell',
        width: key === 'S/N' ? 300 : 'auto',
      });
    }));
  });
  // const date = moment(new Date()).format('MMMM YYYY DD')?.toUpperCase?.();
  const docDefinition = {
    pageSize: 'A1', // use a larger page size
    pageOrientation: 'landscape',
    content: [
      {
        text: `EXPORT RECORDS FOR ${title}`,
        style: 'header',
      },
      {
        layout: 'customeLayout',
        alignment: 'center',
        table: {
          headerRows: 1,
          alignment: 'center',
          body: mockBody,
        },
      },
    ],
    styles: {
      tableHeader: {
        fontSize: 12,
        bold: false,
        alignment: 'left',
        margin: [16, 16, 32, 16],
        fillColor: '#F9FAFB',
        color: '#98A2B3',
        width: 'auto',
      },
      tableCell: {
        fontSize: 12,
        bold: false,
        alignment: 'left',
        margin: [16, 16, 32, 16],
        fillColor: '#FFF',
        color: '#475467',
        width: '*',
      },
      header: {
        color: '#475467',
        fontSize: 15,
        bold: true,
        alignment: 'left',
        margin: [0, 32, 32, 32],
      },
    },
  };

  const fileName = `${title?.toLowerCase?.()}_${moment(new Date()).format('YYYY_MM_DD_HHMMSS')}`;

  pdfMake.tableLayouts = {
    customeLayout: {
      hLineWidth() {
        return 1;
      },
      vLineWidth(i, node) {
        if (i === 0 || i === node.table.body?.[0].length) {
          return 1;
        }
        return 0;
      },
      vLineColor() {
        return '#F2F4F7';
      },
      hLineColor() {
        return '#F2F4F7';
      },
      paddingLeft(i) {
        return i === 0 ? 0 : 8;
      },
      paddingRight(i, node) {
        return (i === node.table.widths.length - 1) ? 0 : 8;
      },
    },
  };
  if (type === 'open') {
    const win = window.open('', '_blank');
    pdfMake.createPdf(docDefinition).open(payload, win);
    return;
  }

  pdfMake.createPdf(docDefinition).download(fileName, cb);
};

export default generatePDF;
