import React from 'react';

const SuccessCheckIcon = () => (
  <svg width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M33 1.88098C15.8393 1.88098 1.88098 15.8393 1.88098 33C1.88098 50.1607 15.8393 64.119 33 64.119C50.1607 64.119 64.119 50.1607 64.119 33C64.119 15.8393 50.1607 1.88098 33 1.88098Z" fill="#5941A9" fillOpacity="0.05" stroke="white" strokeWidth="2.23804" />
    <path d="M48.2062 26.6432L31.956 42.8929C31.4685 43.3804 30.8286 43.6258 30.1886 43.6258C29.5486 43.6258 28.9087 43.3804 28.4212 42.8929L20.2963 34.768C19.3185 33.7907 19.3185 32.2105 20.2963 31.2332C21.2737 30.2554 22.8534 30.2554 23.8312 31.2332L30.1886 37.5906L44.6713 23.1083C45.6487 22.1306 47.2284 22.1306 48.2062 23.1083C49.1835 24.0857 49.1835 25.6654 48.2062 26.6432Z" fill="#5941A9" />
  </svg>

);
export default SuccessCheckIcon;
