import React from 'react';

const ProductView = ({ color }: {color?: string}) => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M13.1628 6.71834C13.4408 6.71834 13.6668 6.49234 13.6668 6.21434V4.50167C13.6668 1.92767 12.0602 0.333008 9.50216 0.333008H4.50216C1.92816 0.333008 0.333496 1.92767 0.333496 4.50367V9.50367C0.333496 12.0717 1.92816 13.6663 4.50216 13.6663H9.50416C12.0722 13.6663 13.6668 12.0717 13.6648 9.50167C13.6648 9.22034 13.4362 8.99167 13.1548 8.99167C12.8728 8.99167 12.6448 9.22034 12.6448 9.50167C12.6448 11.5243 11.5308 12.6443 9.50216 12.6443H4.50216C2.4735 12.6443 1.3535 11.5243 1.3535 9.50167V4.50167C1.3535 2.47301 2.4735 1.35301 4.50416 1.35301H9.50416C11.5335 1.35301 12.6468 2.46634 12.6468 4.50167V6.19634V6.19767C12.6475 6.47901 12.8755 6.70634 13.1568 6.70634V6.71834H13.1628ZM5.54336 7.76328L5.08736 8.21927C4.88403 8.41261 4.8727 8.73328 5.06203 8.94061L5.0767 8.95127C5.27136 9.14594 5.5847 9.15128 5.78536 8.96327L6.23936 8.50928C6.44536 8.31728 6.4567 7.99461 6.2647 7.78794C6.07203 7.58261 5.74936 7.57061 5.54336 7.76328ZM9.09523 8.86854C8.89923 9.06387 8.58256 9.06787 8.3819 8.87654L8.35656 8.85187L5.1699 5.66587C4.97523 5.45921 4.97123 5.13787 5.16123 4.92721C5.3559 4.72787 5.67456 4.72387 5.8739 4.91854C5.8759 4.91987 5.87723 4.92121 5.87923 4.9232L5.87923 4.92321L7.12256 6.16721L8.2539 5.03521C8.45723 4.84121 8.7779 4.84321 8.97923 5.03987C9.01523 5.07587 9.0459 5.11654 9.06923 5.16187C9.18456 5.36254 9.15123 5.61454 8.9879 5.77854L7.86123 6.90454L9.08656 8.13054C9.29323 8.32587 9.30123 8.65187 9.10523 8.85787C9.10371 8.86015 9.10176 8.862 9.09975 8.8639C9.09822 8.86534 9.09666 8.86682 9.09523 8.86854Z" fill={color || 'white'} />
  </svg>

);

export default ProductView;
