import { FunctionComponent } from 'react';
import { cleanUpCustomersOverview } from '../../utils/helpers';
import Table from '../../components/Table';
import EmptyCustomerIcon from '../../svgs/EmptyCustomerIcon';
import EmptyRecord from '../../components/EmptyRecord';
// import './Overview.scss';
interface Props {
  payload?: any;
  loading?: boolean;
}

const CustomerCard: FunctionComponent<Props> = ({ payload, loading }) => (
  payload ? (
    <Table
      loading={loading}
      count={false}
      contents={cleanUpCustomersOverview(payload)}
      reference="id"
      hideDominantImage
      allowKeyArr={[
        'image',
        ['name', 'email'],
        ['quantity', 'type'],
        ['amount', 'amountName'],
      ]}
      mobileAllowKeyArr={[
        'image',
        ['name', 'email'],
        ['amount', 'quantity', 'type'],

      ]}
      minHeight="10vh"
      formatLabels={{
        date: 'SETTLED ON',
        settlementReference: 'REFERENCE NO',
        totalAmount: 'SALES',
        totalFees: 'FEES',
        totalSettled: 'SETTLED',
      }}
      hideHeaders
      emptyMinHeight="230px"
      emptyCompObj={{
        title: 'You have no customers yet',
      }}
    />
  ) : (
    <EmptyRecord
      height="300px"
      title="No Customers Yet"
      main="Customers are a great idea to increase your revenue. Try creating one"
      footer="Add Customer"
      Icon={EmptyCustomerIcon}
      noIcon
    />
  )
);

export default CustomerCard;
