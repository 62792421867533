import React from 'react';

const SettlementIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M5.42525 1.3335H10.5689C12.7112 1.3335 13.9942 2.63445 14 4.80664V11.1937C14 13.3659 12.7228 14.6668 10.5747 14.6668H8.67054C8.42633 14.6395 8.24149 14.4303 8.24149 14.1812C8.24149 13.9321 8.42633 13.7228 8.67054 13.6955H10.5689C12.2061 13.6955 13.0305 12.8537 13.0305 11.1937V4.80664C13.0305 3.14659 12.2061 2.3048 10.5689 2.3048H5.42525C3.7881 2.3048 2.95791 3.14659 2.95791 4.80664V11.1937C2.95791 12.8537 3.7881 13.6955 5.42525 13.6955C5.66946 13.7228 5.8543 13.9321 5.8543 14.1812C5.8543 14.4303 5.66946 14.6395 5.42525 14.6668C3.28302 14.6668 2 13.3659 2 11.1937V4.80664C2 2.62857 3.28302 1.3335 5.42525 1.3335ZM5.59361 5.66609H7.42235C7.66656 5.63879 7.8514 5.42956 7.8514 5.18044C7.8514 4.93132 7.66656 4.72209 7.42235 4.69479H5.59361C5.34941 4.72209 5.16457 4.93132 5.16457 5.18044C5.16457 5.42956 5.34941 5.63879 5.59361 5.66609ZM10.3948 8.48581H5.59361H5.58781C5.3436 8.45851 5.15876 8.24928 5.15876 8.00016C5.15876 7.75104 5.3436 7.54181 5.58781 7.51451H10.3948C10.5785 7.49397 10.7576 7.58179 10.8558 7.74054C10.954 7.8993 10.954 8.10103 10.8558 8.25978C10.7576 8.41854 10.5785 8.50635 10.3948 8.48581ZM10.3948 11.3055H5.59361H5.58781C5.32489 11.3055 5.11176 11.0894 5.11176 10.8228C5.11176 10.5562 5.32489 10.3401 5.58781 10.3401H10.3948C10.6577 10.3401 10.8708 10.5562 10.8708 10.8228C10.8708 11.0894 10.6577 11.3055 10.3948 11.3055Z" fill="#475467" />
  </svg>

);

export default SettlementIcon;
