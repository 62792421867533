import React from 'react';
import { getColor, getNameInitials } from '../../utils/helpers';
import './InitialsImage.scss';

type Props = {
  content: any;
  index: string;
  hasType?:any;
}

const InitialsImage = ({ content, index, hasType }: Props) => {
  if (content[index]) {
    return <img className="table-img" src={content[index]} alt="img url" />;
  }
  const name = content?.name ? getNameInitials(content?.name) : 'N/A';
  const color = getColor(name);

  return (
    <div
      style={{
        backgroundColor: color,
      }}
      className={hasType ? 'small-type' : 'alt-img-name'}
    >
      <strong>{name}</strong>
    </div>
  );
};

export default InitialsImage;
