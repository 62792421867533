import React from 'react';

const EmptyIllustration = () => (
  <svg width="152" height="131" viewBox="0 0 152 131" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="76" cy="58" r="52" fill="#E4E7EC" />
    <circle cx="21" cy="25" r="5" fill="#F2F4F7" />
    <circle cx="18" cy="109" r="7" fill="#F2F4F7" />
    <circle cx="145" cy="41" r="7" fill="#F2F4F7" />
    <circle cx="134" cy="14" r="4" fill="#F2F4F7" />
    <g filter="url(#filter0_dd_252_30952)">
      <path d="M76 36.0629L80.536 34.1629L76.0006 36.0951V90.1898L117.796 72.6754V18.5566L118 18.4712L117.796 18.3809V18.29L117.692 18.3346L76.3298 0L34 18.0314L34.2004 18.1175L34 72.0166L76 90.1576L76 36.0629Z" fill="#F9FAFB" />
      <path d="M76 36.0626V90.1573L34 72.0163L34.2004 18.1172L76 36.0626Z" fill="url(#paint0_linear_252_30952)" />
      <path d="M76 36.0951V90.1898L117.796 72.6754V18.29L76 36.0951Z" fill="url(#paint1_linear_252_30952)" />
      <path d="M76 36.0629L118 18.4712L76.3298 0L34 18.0314L76 36.0629Z" fill="#F2F4F7" />
      <path d="M49.832 11.2878L91.6718 29.4991L92.0519 42.7703L103.758 37.94L103.404 24.585L60.3266 6.81738L49.832 11.2878Z" fill="#E4E7EC" />
    </g>
    <g filter="url(#filter1_b_252_30952)">
      <path d="M52 92C52 78.7452 62.7452 68 76 68C89.2548 68 100 78.7452 100 92C100 105.255 89.2548 116 76 116C62.7452 116 52 105.255 52 92Z" fill="#344054" fillOpacity="0.4" />
      <path d="M76 91V97M73 94H79M86 99C86 99.5304 85.7893 100.039 85.4142 100.414C85.0391 100.789 84.5304 101 84 101H68C67.4696 101 66.9609 100.789 66.5858 100.414C66.2107 100.039 66 99.5304 66 99V85C66 84.4696 66.2107 83.9609 66.5858 83.5858C66.9609 83.2107 67.4696 83 68 83H73L75 86H84C84.5304 86 85.0391 86.2107 85.4142 86.5858C85.7893 86.9609 86 87.4696 86 88V99Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <filter id="filter0_dd_252_30952" x="14" y="0" width="124" height="130.189" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feMorphology radius="4" operator="erode" in="SourceAlpha" result="effect1_dropShadow_252_30952" />
        <feOffset dy="8" />
        <feGaussianBlur stdDeviation="4" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.04 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_252_30952" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feMorphology radius="4" operator="erode" in="SourceAlpha" result="effect2_dropShadow_252_30952" />
        <feOffset dy="20" />
        <feGaussianBlur stdDeviation="12" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.1 0" />
        <feBlend mode="normal" in2="effect1_dropShadow_252_30952" result="effect2_dropShadow_252_30952" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_252_30952" result="shape" />
      </filter>
      <filter id="filter1_b_252_30952" x="44" y="60" width="64" height="64" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImage" stdDeviation="4" />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_252_30952" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_252_30952" result="shape" />
      </filter>
      <linearGradient id="paint0_linear_252_30952" x1="34" y1="72.2882" x2="50.587" y2="27.3662" gradientUnits="userSpaceOnUse">
        <stop stopColor="#DEE1E8" />
        <stop offset="1" stopColor="#EFF1F5" />
      </linearGradient>
      <linearGradient id="paint1_linear_252_30952" x1="75.9994" y1="37.1539" x2="123.358" y2="46.5826" gradientUnits="userSpaceOnUse">
        <stop stopColor="#E4E7EC" />
        <stop offset="1" stopColor="#F2F4F7" />
      </linearGradient>
    </defs>
  </svg>

);

export default EmptyIllustration;
